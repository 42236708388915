<td><img src="{{getPreviewUrl icon}}"></td>
<!-- if ARC Blood only: https://3sidedcube.atlassian.net/browse/ARCBLOOD-677 -->
{{#ifEqual systemId 9}}
	{{#ifEqual appId 7}}
		<td>
			<dl>
				{{#if dateFrom}}
					<dt>{{t "editor.inspector.properties.badge.dateFrom.title"}}</dt>
					<dd class="mb-10">{{dateFrom}}</dd>
				{{/if}}
				{{#if dateUntil}}
					<dt>{{t "editor.inspector.properties.badge.dateUntil.title"}}</dt>
					<dd>{{dateUntil}}</dd>
				{{/if}}
			</dl>
		</td>
	{{/ifEqual}}
{{/ifEqual}}

<td>
	<dl>
		{{#key_value title.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value completion.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value shareMessage.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value how.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/badges/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
