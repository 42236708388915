<td>
	<dl>
		{{name}}
	</dl>
</td>
<td>
	<dl>
		{{type}}
	</dl>
</td>
<td>
	<dl>
		<a href="{{url}}" target="_blank">Link</a>
	</dl>
</td>
<td>
	<dl>
		{{facilityId}}
	</dl>
</td>
<td>
	<dl>
		{{division}}
	</dl>
</td>
<td>
	<dl>
		{{region}}
	</dl>
</td>
<td>
	<dl>
		{{#each location.coordinates}}
			<li style="list-style-type:none;">
				{{this}}
			</li>
		{{/each}}
	</dl>
</td>
<td>
	<dl>
		{{#if address.line1}}{{address.line1}},<br />{{/if}}
		{{#if address.locality}}{{address.locality}},{{/if}}
		{{#if address.state}}{{address.state}},<br />{{/if}}
		{{#if address.country}}{{address.country}}<br />{{/if}}
		{{#if address.postalCodes}}{{address.postalCode}}{{/if}}
	</dl>
</td>
<td>
	<dl>
		<b>Phone:</b><br />
		<ul>
			{{#each contact.phone}}
				<li>
					{{this}}
				</li>
			{{/each}}
		</ul>

		<br />
		<b>Fax: </b>{{contact.fax}}
	</dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/facilities/edit/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
