var StormQL = require('models/stormql'),
	StandaloneStormObject = require('editor/standalone-storm-object')

module.exports = Backbone.View.extend({
	className: 'categoriesView',
	template: require('./categories-view-template'),

	/** @override */
	events: {
		'click .js-delete': 'deleteCategory'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.app = App.appList.get(options.appId)
		this.checkForDeleteConditions = options.checkForDeleteConditions

		var requests = []

		this.model = StandaloneStormObject.fromClassName('R4b_Category')

		// Levels
		this.categories = new StormQL(null, {app: this.app})
		this.categories.comparator = "order"
		requests.push(this.categories.fetchOnce({data: {class: 'R4b_Category'}}))

		var localePromise = this.app.localeList.fetchOnce()
		requests.push(localePromise)
		Promise.all(requests)
		.then(this.render.bind(this))
	},

	getRenderData: function() {
		App.stopLoad()
		return {
			categories: this.categories.toJSON(),
			locales: this.app.localeList.toJSON(),
			appId: this.appId
		}
	},

	deleteCategory: function(e) {
		var id = parseInt($(e.currentTarget).attr('data-id'), 10)
		var model = this.categories.get(id)

		var callback = function() {
			this.categories.fetch({data: {class: 'R4b_Category'}}).then(this.render.bind(this))
		}

		App.startLoad()
		this.checkForDeleteConditions(id).then(function(resp) {
			App.stopLoad()

			if (resp.length > 0) {
				var text = resp.map(function(item) {
					return $.t('r4b.categories.level') + " " + item.level + ", " + item.name.content.eng
				}).join("\n")

				swal($.t('r4b.categories.deleteError'), $.t('r4b.categories.deleteErrorInfo') + '\n' + text, 'error')
			} else {
				swal({
					title: $.t('editor.inspector.areYouSure'),
					text: $.t('editor.inspector.confirmDelete'),
					showCancelButton: true
				}, function(didConfirm) {
					if (didConfirm) {
						model.requestLock(function() {
							App.startLoad()
							model.destroy().then(callback.bind(this))
						}.bind(this))
					}
				}.bind(this))
			}
		}.bind(this))
	}
})
