var UserSearch = require('auth/auth-search'),
	User       = require('users/user'),
	UserExtra  = require('users/user-extra'),
	GroupList  = require('users/group-list'),
	RoleList   = require('permissions/role-list')

/**
 * Exports {@link UserCreateView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends UserCreateView.prototype */{
	/** @override */
	template: require('./user-create-view-template'),
	/** @override */
	className: 'storm-modal modal fade create-modal',

	/** @override */
	events: {
		'click .stage1-button': 'findUser',
		'click .stage2-button': 'createUser',
		'click .stage2-gdpc-button': 'updateGDPCData',
		'click .stage3-button': 'assignRole',
		'click .select-all-apps': 'selectAllApps',

		'submit .stage1': 'findUser',
		'submit .stage2': 'createUser',
		'submit .stage2-gdpc': 'updateGDPCData',
		'submit .stage3': 'assignRole',

		'input #new-username': 'usernameChange'
	},

	/**
	 * @constructs UserCreateView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function() {
		this.search = new UserSearch()

		/** @private {GroupList} */
		this.groupList_ = new GroupList()
		this.groupList_.fetch().then(this.outputGroups_.bind(this))

		/** @private {RoleList} */
		this.roleList_ = new RoleList()
		this.roleList_.fetch()
	},

	/** @override */
	getRenderData: function() {
		// Group apps by society.
		var societyApps = []
		App.appList.forEach(function(app) {
			var societyId = app.get('societyId')

			if (!societyApps[societyId]) {
				societyApps[societyId] = []
			}

			societyApps[societyId].push(app.toJSON())
		})

		var societies = []
		_.each(societyApps, function(val, key) {
			if (!societyApps[key]) {
				return
			}

			var societyModel = App.societiesList.get(key)
			if (!societyModel) {
				return
			}

			var society = societyModel.toJSON()
			society.apps = societyApps[key]
			societies.push(society)
		})

		// Sort societies by description.
		societies.sort(function(a, b) {
			return a.description.localeCompare(b.description)
		})

		return {
			societies: societies
		}
	},

	outputGroups_: function() {
		this.groupList_.each(function(group) {
			$('#new-role-group').append('<option value="' + group.id + '">' + group.get('name') + '</option>')
		})
	},

	/**
	 * Checks if user account already exists.
	 * @returns {boolean} false to stop form submission.
	 */
	findUser: function() {
		var email = $('#new-email').val()

		if (!email) {
			return false
		}

		App.startLoad()
		this.$('.stage1 input, .stage1 button').prop('disabled', true)
		this.user = new User({email: email})

		var params = {
			type: 'users',
			field: 'email',
			value: email
		}

		this.search.once('sync', this.matchUser, this)
		this.search.fetch({data: $.param(params)})

		// Stop native form submission.
		return false
	},

	/**
	 * Matches up ID of new account or move to next stage of form.
	 */
	matchUser: function() {
		this.$('.stage1').hide()

		var user,
			email = this.user.get('email').toLowerCase()

		this.search.each(function(result) {
			if (result.get('email').toLowerCase() === email) {
				user = result
			}
		})

		if (user !== undefined) {
			// User exists - update model
			this.user.set(this.search.at(0))
			this.createUserComplete()
		} else {
			this.$('.stage2').show()
		}

		App.stopLoad()
	},

	/**
	 * Checks if desired username is available.
	 */
	usernameChange: function() {
		var username = $('#new-username').val()

		// Check username is valid
		if (!username.match(/^[a-zA-Z0-9-_\.]{1,100}$/)) {
			this.usernameError()
			return
		}

		this.usernameLoading()

		var params = {
			type: 'users',
			field: 'username',
			value: username
		}

		this.user.set('username', username)
		this.search.once('sync', this.usernameSearchComplete, this)
		this.search.fetch({data: $.param(params)})
	},

	/**
	 * Gives feedback on username availability.
	 */
	usernameSearchComplete: function() {
		var user
		var username = this.user.get('username').toLowerCase()

		this.search.each(function(result) {
			if (result.get('username').toLowerCase() === username) {
				user = result
			}
		})

		if (user !== undefined) {
			this.usernameError()
		} else {
			this.usernameSuccess()
		}
	},

	usernameSuccess: function() {
		this.$('.stage2-button').prop('disabled', false)
		this.$('.username-status').attr('class', 'username-status username-success icon-check')
	},

	usernameError: function() {
		this.$('.stage2-button').prop('disabled', true)
		this.$('.username-status').attr('class', 'username-status username-error icon-remove')
	},

	usernameLoading: function() {
		this.$('.stage2-button').prop('disabled', true)
		this.$('.username-status').attr('class', 'username-status icon-spinner icon-spin')
	},

	createUser: function() {
		var firstName = this.$('#new-firstname').val(),
			lastName  = this.$('#new-lastname').val()

		if (!firstName || !lastName) {
			return false
		}

		App.startLoad()

		this.$('.stage2 input, .stage2 button').prop('disabled', true)

		this.user.once('sync', this.createUserComplete, this)
		this.user.save({
			firstName: firstName,
			lastName: lastName
		})

		// Stop native form submission.
		return false
	},

	createUserComplete: function() {
		App.stopLoad()

		this.$('.stage2').hide()

		if (App.system.id === 3) {
			this.$('.stage2-gdpc').show()
		} else {
			this.$('.stage3').show()
		}
	},

	selectAllApps: function(e) {
		e.preventDefault()
		$('.app-assign-checkbox').prop('checked', true)
	},

	updateGDPCData: function() {
		var jobTitle = this.$('#new-job-title').val()

		if (!jobTitle) {
			return false
		}

		App.startLoad()
		this.$('.stage2-gdpc input, .stage2-gdpc button').prop('disabled', true)
		var extra = new UserExtra({id: this.user.id})

		extra.save({jobTitle: jobTitle})
			.then(this.updateGDPCDataComplete.bind(this))

		// Stop native form submission.
		return false
	},

	updateGDPCDataComplete: function() {
		App.stopLoad()

		this.$('.stage2-gdpc').hide()
		this.$('.stage3').show()
	},

	assignRole: function() {
		var groupId = Number($('#new-role-group').val()),
			group   = this.groupList_.get(groupId),
			roles   = group.get('roles')

		// Assign master role(s) as well.
		// TODO remove this once API has updated to do this automatically.
		var masterRoleIds = roles.map(function(id) {
			var role = this.roleList_.get(id)

			return role.get('masterId')
		}, this)

		masterRoleIds = _.uniq(masterRoleIds)
		roles = roles.concat(masterRoleIds)

		// Add any system-specific required roles
		if (App.system.id === 3) {
			// All GDPC users require a bug role
			roles.push(39)
			roles.push(8)
		}

		App.startLoad()
		this.$('.stage3 select, .stage3 button').prop('disabled', true)

		this.user.once('sync', this.assignApps, this)
		this.user.addRoles(roles)

		// Stop native form submission.
		return false
	},

	assignApps: function() {
		var apps = this.$('.app-assign-checkbox:checked').map(function() {
			return {id: Number(this.value)}
		}).get()

		if (apps.length === 0) {
			return this.complete()
		}

		var appModel = new Backbone.Model()
		appModel.set('id', 0)
		appModel.url = App.apiRoot + 'users/' + this.user.id + '/apps'

		appModel.once('sync', this.complete, this)
		appModel.save('apps', apps)
	},

	complete: function() {
		App.stopLoad()

		this.$('.stage3').hide()
		this.$('.complete').show()

		this.trigger('create')
	}
})
