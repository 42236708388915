window.env = require('env.js')

// Check browser compatibility.
if (!$.support.cors) {
	document.location = '/unsupported-browser.html'
}

if (document.location.host.indexOf('dev') === -1 && document.location.host.indexOf('localhost') === -1) {
	Raven.config(window.env.raven,
		{
			release: window.env.version,
			ignoreErrors: [
				'This action requires a valid auth token' // Unauthenticated error
			]
		}
	).install()
}

Backbone.DeepModel.keyPathSeparator = '..'

ES6Promise.polyfill()

require('application')
require('lib/view-helper')
require('lib/view-partials')
require('lib/backbone-extends')

var i18next       = require('i18next'),
	i18nextJquery = require('jquery-i18next'),
	i18nextXHR    = require('i18next-xhr-backend')

var Systems         = require('lib/systems'),
	ApplicationView = require('application-view'),
	AppList         = require('editor/app-list'),
	User            = require('users/user'),
	RoleList        = require('permissions/role-list'),
	AgreementList   = require('user-agreement/agreement-list'),
	/** @type {StormGlobals} */
	globals         = require('globals')

var isDebug = document.location.hostname === 'localhost'

var appList = new AppList(),
	user    = new User({id: 'me'})

var clientLangImport = {
	en: {translation: require('lib/locales/en/translation.json')},
	ar: {translation: require('lib/locales/ar/translation.json')},
	es: {translation: require('lib/locales/es/translation.json')},
	fr: {translation: require('lib/locales/fr/translation.json')},
	id: {translation: require('lib/locales/id/translation.json')},
	my: {translation: require('lib/locales/my/translation.json')},
	ru: {translation: require('lib/locales/ru/translation.json')},
	vi: {translation: require('lib/locales/vi/translation.json')},
	zh: {translation: require('lib/locales/zh/translation.json')}
}

window.Storm = new ApplicationView({
	appList: appList,
	user: user
})

if (!window.UNIT_TEST) {
	/**
	 * The main Storm API subdomain is set automatically based on the accessing
	 * domain, when accessing from *.3sidedcube.com. Frontend subdomains
	 * typically map 1:1 to *.cubeapis, with some exceptions:
	 * <ul>
	 *     <li>'-staging' is stripped from subdomains, to allow for pre-release
	 *     frontends targeting the live API.</li>
	 *     <li>- Versions of the format '-v1-23' are stripped, to allow for
	 *     fixed-version hosts to target the live API.</li>
	 * </ul>
	 */

	var subdomain = document.location.host
		.replace('.3sidedcube.com', '')
		.replace('-staging', '')
		.replace(/-v\d+(-\d+)?/, '')

	// Set API root based on accessing subdomain
	if (document.location.host.match('3sidedcube.com')) {
		App.apiRoot = App.apiRoot.replace('storm', subdomain)
	}

	// Inject Google Analytics on GDPC domain only
	if (document.location.host === 'gdpc.3sidedcube.com') {
		console.warn('Injecting analytics')
		require('lib/gpdc-analytics')
	}

	// Initialize XDomain proxy URLs.
	var proxyPath = '/proxy.html'

	var slaves = {
		'https://auth.cubeapis.com': proxyPath
	}

	// Custom override for Storm Corp.
	if (document.location.host.match('stormcorp.co')) {
		App.apiRoot = App.apiRoot.replace('storm.cubeapis.com', 'api.stormcorp.co')
		slaves['https://api.stormcorp.co'] = proxyPath
	} else {
		slaves[document.location.protocol + App.apiRoot.replace(/\.cubeapis\.com\/.*\//, '.cubeapis.com')] = proxyPath
	}

	if (!isDebug) {
		xdomain.slaves(slaves)
	}

	var SocietiesList = require('models/societies-list'),
		ACL           = require('permissions/acl'),
		UserRoleList  = require('permissions/user-role-list'),
		Session       = require('auth/session'),
		Classes       = require('editor/classes'),
		AuthSearch    = require('auth/auth-search')

	var start = function() {
		$('#container').html(window.Storm.render().el)
		Backbone.history.start({
			pushState: true
		})
	}

	$(function() {
		App.startLoad()

		// Detect IE10.
		/* eslint-disable */
		if (/*@cc_on!@*/false && document.documentMode === 10) {
			document.documentElement.className += ' ie10'
		}
		/* eslint-enable */

		// Catch all link clicks, send through the Backbone router instead
		$(document).on('click', 'a[href]:not([data-bypass])', function(evt) {
			var href = {prop: $(this).prop('href'), attr: $(this).attr('href')},
				root = location.protocol + '//' + location.host

			// Treat links with empty paths as equivalent to void(0).
			if (href.attr === '') {
				return evt.preventDefault()
			}

			if (href.prop.slice(0, root.length) === root) {
				evt.preventDefault()
				Backbone.history.navigate(href.attr, true)
			}
		})

		App.developerMode = localStorage.getItem('developerMode') !== null

		// Set up auth session.
		var sessionData = JSON.parse(localStorage.getItem('auth')) || {},
			session     = new Session(sessionData)

		globals.setSession(session)
		App.session = session

		if (App.session.getTimeRemaining() <= 0) {
			App.session.clearSession()
		}

		// Set up localisation
		var i18nInit = new Promise(function(resolve, reject) {
			i18next
				.use(i18nextXHR)
				.init({
					lng: 'en',
					resources: clientLangImport
				}, function(err, localise) {
					if (err) {
						reject(err)
					} else {
						resolve(localise)
					}
				})

			i18nextJquery.init(i18next, $, {
				tName: 't',
				handleName: 'i18n'
			})
		})

		i18nInit.then(function() {
			// Cache CMS language
			App.clientLang = $.i18n.language.split('-')[0]

			// Load API details.
			var jqXHR = $.ajax({
				url: App.apiRoot,
				global: false
			})

			jqXHR.fail(apiConnectionError)
			return Promise.resolve(jqXHR)
		}).then(function(apiDetails) {
			App.system = apiDetails
			App.system.apiCode = App.system.name
			delete App.system.name
			App.il8next = i18next
			// Global error handler for 401's
			$(document).ajaxError(ajaxErrorHandler)
			$.ajaxSetup({
				contentType: 'application/json; charset=UTF-8',
				dataType: 'json'
			})

			App.initialize()

			// Check auth token validity
			var verification = App.session.verify()

			verification.catch(redirectToLogin)
			return verification
		}).then(function() {
			// Cache new session data.
			localStorage.setItem('auth', JSON.stringify(App.session))

			// Fetch Storm system details.
			var apiCode = App.system.apiCode

			var params = {
				type: 'system',
				field: 'code',
				value: apiCode
			}

			var authSearch = new AuthSearch(),
				jqXHR      = authSearch.fetch({data: $.param(params)})

			jqXHR.fail(apiConnectionError)
			return Promise.resolve(jqXHR)
		}).then(function(systems) {
			var system = systems

			if (systems instanceof Array) {
				system = systems[0]
			}

			if (system) {
				return system
			}

			App.stopLoad()
			swal('Fatal error', 'System could not be found in the auth API based on its API code. Please contact a developer.', 'error')
			return Promise.reject()
		}).then(function(system) {
			App.system.name = system.name
			App.system.id = system.id
			App.system.api = system.api

			globals.setSystem(system)

			document.title = App.system.name + ' : Project Storm'

			// Set page title from dictionary
			var overrides = Systems[App.system.apiCode] || {}

			// Add beta styling if we're on the beta frontend.
			if (document.location.pathname.indexOf('/beta') > -1) {
				overrides.styling = 'beta'
			}

			if (overrides.styling) {
				document.body.classList.add(overrides.styling)
			}

			// Fetch list of systems the user can access
			App.userRoles = new UserRoleList()

			var jqXHR = App.userRoles.fetch()

			jqXHR.fail(apiConnectionError)
			return Promise.resolve(jqXHR)
		}).then(function() {
			// Check the user has a role on this system before
			// trying to access the ACL.
			var roles = App.userRoles.where({systemId: App.system.id})

			if (!roles.length) {
				requestSystemAccess()
				return Promise.reject()
			}

			var requests = []

			// Fetch authenticating user's information
			var acl = new ACL()

			App.acl = acl
			requests.push(acl.fetch())
			globals.setAcl(acl)

			// Fetch user's assigned list of apps
			App.appList = appList
			requests.push(appList.fetch())
			globals.setAppList(appList)

			// Fetch list of societies
			App.societiesList = new SocietiesList()
			requests.push(App.societiesList.fetch())

			// Fetch valid Page/PageCollection subclasses.
			requests.push(App.getSubclasses('Page'))
			requests.push(App.getSubclasses('PageCollection'))

			// Fetch initial list of classes.
			App.classes = new Classes()
			App.classes.noAuth = true
			requests.push(App.classes.fetch())

			// Fetch user details.
			requests.push(user.fetch())

			// Wait for all the above requests to complete.
			var requestsPromise = Promise.all(requests)

			requestsPromise.then(
				function() {
					if (acl.hasAccess('User Agreement') && App.system.id === 3) {
						var currentApp = App.getCurrentApp()
						var society = App.societiesList.get(currentApp.get('societyId'))

						App.agreementList = new AgreementList(null, {societyId: society.id})
						App.agreementList.once('sync', this.agreementsFetched, this)
						App.agreementList.fetch()
					}
				}
			)

			requestsPromise.catch(apiConnectionError)
			return requestsPromise
		}).then(start, function(err) {
			console.warn('Failed to start Storm', err)
		})

		// Change nav bar colour if running on development API
		if (App.apiVersion === 'test' || App.apiRoot.indexOf('dev') > -1) {
			$('body').addClass('development')
		}

		function apiConnectionError() {
			App.stopLoad()
			swal($.t('error.oops'), $.t('error.connect'), 'error')
		}

		function redirectToLogin() {
			localStorage.removeItem('auth')

			// Show activation/password reset pages without auth
			if (document.location.href.match(/\/#?(activation|password)/)) {
				return Backbone.history.start({
					pushState: true
				})
			}

			// Cache the route the user was attempting to access, to be
			// redirected to later. Don't set the initial route as
			// anything that affects the login. Loops.
			if (document.location.href.match(/\/#?(login|logout)/) === null) {
				App.initialRoute = document.location.pathname
			}

			Backbone.history.start({
				pushState: true,
				silent: true
			})

			App.stopLoad()
			App.router.navigate('login', {replace: true})
			App.router.login()
		}
	})

	window.onbeforeunload = function() {
		// Prevent leaving the app whilst saves are in progress
		if (App.saving) {
			return $.t('error.exitWarning')
		}

		// Don't allow the user to exit with an active lock
		if (Storm.view && Storm.view.views && Storm.view.views.canvas && Storm.view.views.canvas.model) {
			var page = Storm.view.views.canvas.model

			if (page.hasLock()) {
				// Perform synchronous DELETE call to the token
				page.unlock(true)
			}
		}
	}

	// Alert user on uncaught (bad) errors.
	// Only do it on live - not local dev.
	if (document.location.host.indexOf('dev') === -1 && !isDebug) {
		window.onerror = function(msg) {
			swal($.t('error.oops'), $.t('error.generic') + '\nError message: ' + msg, 'error')
			return true
		}
	}
}

// Global error handler for AJAX errors.
function ajaxErrorHandler(e, xhr, settings) {
	switch (xhr.status) {
		case 401:
		case 419:
			App.showLoginOverlay()
			break

		case 400:
			if (!(settings.suppressErrors instanceof Array && settings.suppressErrors.indexOf(400) > -1)) {
				try {
					var response = JSON.parse(xhr.responseText)
					if (response['client error'].data === "App is currently locked for editing") {
						// The current app is locked for editing by the API
						swal($.t('error.oops'), $.t('error.locked'), 'info')
						break
					}
				} catch (e) {
					// Do nothing, probably just an odd response from the API, proceed to show save failed error...
				}
				swal($.t('error.oops'), $.t('error.saveFailed'), 'error')
			}
			break

		case 403:
			swal($.t('error.oops'), "No Permission", 'error')
			break

		case 500:
			var reason

			try {
				var resp = JSON.parse(xhr.responseText)

				reason = resp['server error'].data
			} catch (err) {
				reason = 'Unknown error'
			}

			swal($.t('error.oops'), $.t('error.generic') + '\n' + reason, 'error')
			break
	}
}

// The user has no roles on this system - try to add one.
function requestSystemAccess() {
	App.stopLoad()

	// Check if the user is able to add roles to their account.
	var authRole = App.userRoles.get(2) || App.userRoles.get(3)

	if (!authRole) {
		swal({
			title: $.t('error.oops'),
			text: $.t('error.noAccess'),
			type: 'error'
		}, exit)

		return
	}

	swal({
		title: $.t('error.oops'),
		text: $.t('error.noAccessJoin'),
		type: 'info',
		showCancelButton: 'true',
		cancelButtonText: 'No',
		confirmButtonText: 'Yes'
	}, function(confirmation) {
		if (!confirmation) {
			return exit()
		}

		// Fetch list of roles for this system, assign the first. User can go
		// in and change this later.
		var systemRoles = new RoleList()

		systemRoles.fetch().then(function() {
			var role = systemRoles.first().toJSON()

			user.addRoles([role.id]).then(function() {
				document.location.reload()
			})
		})
	})
}

// Trash auth token and redirect to login.
function exit() {
	localStorage.removeItem('auth')
	document.location = '/'
}
