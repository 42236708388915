module.exports = Backbone.Collection.extend({
	url: function() {
		return App.apiRoot + 'societies/ga'
	},
	parse: function(response) {
		var parsed = []
		$.each(response, function(key, value) {
			var keySplit = key.split('-')
			parsed.push({
				societyId: parseInt(keySplit[1], 10),
				appId: parseInt(keySplit[2], 10),
				downloads: value
			})
		})
		return parsed
	}
})
