<div class="row">
	<div class="col-xs-12">
		<h3>{{t "gdpcConsole.nationalSocieties.title"}}</h3>
	</div>
	<div class="col-xs-12">
		<p>
			{{t "gdpcConsole.nationalSocieties.contactGDPC"}}
		</p>
	</div>
</div>
