var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view')

/**
 * Exports {@link BadgeEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends BadgeEditView.prototype */{
	/** @override */
	className: 'c-categories',
	/** @override */
	template: require('./category-edit-view-template'),

	/** @override */
	events: {
		'click .js-save': 'save'
	},

	/**
	 * @constructs BadgeEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var localePromise = this.app_.localeList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('R4b_Category')

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, localePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.systemId = App.system.id
		data.appId = this.app_.id
		data.locales = this.app_.localeList.toJSON()

		return data
	},

	save: function(e) {
		e.preventDefault()
		App.startLoad()
		var values = {name: {}, description: {}}
		this.$('.js-text-input').each(function() {
			var code = $(this).data('code')
			var prop = $(this).data('prop')
			values[prop][code] = this.value
		})

		var order = parseInt($('.js-order-input').val(), 10)

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + this.app_.id + '/atlas_admin', {trigger: true})
		}.bind(this))

		this.model.set('name..content', values.name)
		this.model.set('description..content', values.description)
		this.model.set('order', order)
		this.model.save(null, {appId: this.app_.id})
	}
})
