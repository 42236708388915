<form id="toplevelForm" class="container-fluid form-horizontal">
	<fieldset class="edit-form">
		<div class="row">
			<h3>
				{{t "whatnow.attribution.attribution"}}:
				{{#each countryCodes}} {{#ifEqual code ../countryCode}} {{description}} - {{code}} {{/ifEqual}} {{/each}}
			</h3>
		</div>
		<div class="row">
			<div class="pull-right">
				<a href="javascript:history.back()" style="text-decoration:none;">
					<button class="button red wide" type="button">{{t "common.cancel"}}</button>
				</a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
		<hr>

		<div class="row">
			<!-- Left side -->
			<div class="col-sm-7">
			<!-- URL -->
			<div class="col-sm-12">
				<div class="form-group">
					<legend for="attribution-url" data-i18n="whatnow.attribution.url"></legend>
					<p class="help-block" data-i18n="whatnow.attribution.urlHelp"></p>
					<input type="url" id="attribution-url" class="form-control" required value="{{attributionUrl}}" placeholder="http://www.linktomore.info">
				</div>
			</div>

			<!-- Soc name -->
			<div class="col-sm-12">
				<div class="form-group">
					<legend data-i18n="whatnow.attribution.socName"></legend>
					<p class="help-block" data-i18n="whatnow.attribution.socNameHelp"></p>
				</div>

				{{#each translations}}
					<div data-code="{{@key}}" class="form-group">
						<div class="input-group">
							<span class="input-group-addon">{{getWhatnowLocaleName @key ../languages}}</span>
							<input type="text" class="soc-input form-control" data-code="{{@key}}" value="{{name}}">
						</div>
					</div>
					{{else}}
						<p data-i18n="whatnow.edit.addALang"></p>
				{{/each}}
			</div>

			<!-- Attribution Message -->
			<div class="col-sm-12">
				<div class="form-group">
					<legend data-i18n="whatnow.attribution.message"></legend>
					<p class="help-block" data-i18n="whatnow.attribution.messageHelp"></p>
				</div>

				{{#each translations}}
				<div data-code="{{@key}}" class="form-group">
					<div class="input-group">
						<span class="input-group-addon">{{getWhatnowLocaleName @key ../languages}}</span>
						<textarea class="form-control message-input" data-code="{{@key}}" id="attribution-message">{{attributionMessage}}</textarea>
					</div>
				</div>
				{{else}}
					<p data-i18n="whatnow.edit.addALang"></p>
				{{/each}}
			</div>
		</div>

		<div class="col-sm-1"></div>

		<!-- Right side -->
		<div class="col-sm-4">
			<legend data-i18n="whatnow.edit.languages"></legend>
			<p class="help-block" data-i18n="whatnow.edit.languageshelp"></p>
			{{#each translations}}
				<div class="row">
					<p class="col-sm-3">{{getWhatnowLocaleName @key ../languages}}</p>
					<button type="button" class="btn btn-default btn-xs delete-language" data-id={{@key}}><i class="icon-trash"></i></button>
				</div>
				{{else}}
					<p data-i18n="whatnow.edit.addALang"></p>
			{{/each}}
			<hr>
			<div class="row">
				<div class="col-sm-5">
					<select id="whatnow-langselect" class="form-control">
						{{#each languages}}
							<option value="{{iso2}}" {{enabledLangKey iso2 ../translations}}>{{getWhatnowLocaleName iso2 ../languages}} ({{iso2}})</option>
						{{/each}}
					</select>
				</div>
				<button class="button btn btn-primary add-language-button"><i class="icon icon-plus"></i> {{t "common.add"}}</button>
			</div>
			<hr>
			<!-- Image upload -->
				<legend data-i18n="whatnow.attribution.image"></legend>
				<div class="col-sm-10">
					<button id="attribution-image" type="button" class="button btn btn-primary add-whatnow-button"><i class="icon icon-cloud-upload"></i> {{t "common.upload"}}</button>
					<button id="attribution-image-remove" type="button" class="button btn btn-primary add-whatnow-button" {{#unless attributionImageUrl}} disabled {{/unless}}>
						<i class="icon icon-remove-sign"></i> {{t "editor.inspector.properties.image.removeButton"}}</button>
					<input id="file-upload" class="file-upload" type="file" name="image" accept="image/gif, image/jpeg, image/jpg, image/png"><!-- this is hidden! used for native file selection.
																																																				triggered by the button above-->
					<p class="help-block" data-i18n="whatnow.attribution.imageHelp"></p>
					<progress id="#progress-bar" class="hidden" max="100"></progress>
					<div id="img-preview-container" {{#unless attributionImageUrl}} style="display:none;" {{/unless}}>
						<p data-i18n="common.preview"></p>
						<img id="img-preview img-responsive" alt="Image preview..." src="{{attributionImageUrl}}" style="max-width: 300px;">
					</div>
				</div>
			</div>

		</div>
	</fieldset>
</form>
