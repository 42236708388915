<div class="empty-table-text">{{t "hazards.alerts.noAlerts"}}</div>
<div class="pagination"></div>
<table class="alerts-table">
	<thead>
		<tr>
			<th>{{t "push.type"}}</th>
			<th>{{t "hazards.alerts.alert"}}</th>
			<th>{{t "agreement.status"}}</th>
			<th class="tools-header">{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
<div class="pagination"></div>
