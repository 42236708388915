<div class="row app-create">
	<div class="col-xs-12">

		<form class="form-horizontal">
			<legend>{{t "appCreate.details"}}</legend>

			<div class="form-group">
				<label class="control-label col-sm-3"
							 for="app-society">{{t "mediaLibrary.society"}}</label>
				<div class="col-sm-9">
					<select id="app-society" class="input-xlarge form-control" style="width: 100%">
						{{#each societyList}}
							<option value="{{id}}">{{name}}</option>
						{{/each}}
					</select>
					<p class="help-block">
						<a href="/organization/new"
							 data-i18n="appCreate.createNewOrganization"></a>
					</p>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-3"
							 for="app-template">{{t "appCreate.copyFrom"}}</label>
				<div class="col-sm-9">
					<select id="app-template" class="input-xlarge form-control" style="width: 100%">
						<option value="-1">{{t "appCreate.emptyApp"}}</option>
						{{{appSelect}}}
					</select>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-3"
							 for="app-language">{{t "gdpcConsole.devPools.language"}}</label>
				<div class="col-sm-9">
					<select id="app-language" class="input-xlarge form-control" multiple="multiple" style="width: 100%">
						{{#each languages}}
							<option value="{{id}}">{{name.native}} ({{toUpperCase code.iso3}})</option>
						{{/each}}
					</select>
				</div>
			</div>

			<div class="form-group">
				<label id="name-label" class="control-label col-sm-3" style="display:none;">{{t "metadata.name"}}</label>
				<div id="app-langs" class="col-sm-9">
					<!-- App langs here... -->
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-3"
							 for="app-type">{{t "push.type"}}</label>
				<div class="col-sm-9">
					<select id="app-type" class="input-xlarge form-control" style="width: 100%">
						{{#each appTypes}}
							<option value="{{id}}">{{name}}</option>
						{{/each}}
					</select>
				</div>
			</div>

			<div class="well">
				<button type="submit"
								class="button green save-button">{{t "common.save"}}</button>
			</div>
		</form>
	</div>
</div>
