<div class="container">

	<div class="row">
		<div class="col-sm-6">
			<h3 data-i18n="sponsorship.edit.sponsor"></h3>
		</div>
		<div class="col-sm-6 actions-col">
			<button class="button red wide goBack" type="button">{{t "common.cancel"}}</button>
			<button class="button green wide save" type="submit">{{t "common.save"}}</button>
		</div>
	</div>

	<div class="row">
		<div class="span12">

			<form class="form-horizontal">
				<fieldset>

					<div class="form-group">
						<label class="control-label">{{t "sponsorship.edit.icon"}}</label>
						<div class="controls">
							<button type="button" class="button choose-image-button" id="icon-select" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
							<img id="iconImg" src="{{getPreviewUrl icon.src.x1}}">
							<p class="help-block">{{t "sponsorship.edit.iconHelp"}}</p>
						</div>
					</div>

					<div class="form-group">
						<label class="control-label">{{t "sponsorship.edit.feature"}}</label>
						<div class="controls">
							<button type="button" class="button choose-image-button" id="image-select" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
							<img id="featureImg" src="{{getPreviewUrl feature.src.x1}}">
							<p class="help-block">{{t "sponsorship.edit.featureHelp"}}</p>
						</div>
					</div>

					<div class="form-group">
						<label class="control-label">{{t "sponsorship.edit.tags"}}</label>
						<div class="image-tags controls input-group">
							<input type="text" id="add-tag-input" class="form-control">
							<span class="input-group-btn">
								<button class="btn btn-default add-tag-button">{{t "common.add"}}</button>
							</span>
						</div>
						<div class="image-tags">
							<p class="help-block">{{t "sponsorship.edit.tagsHelp"}}</p>
							<ul class="tag-list" id="tags">
								{{#each tags}}
									<li>{{this}}<a href class="tag-remove-button" data-id={{@key}}>&times;</a></li>
								{{/each}}
							</ul>
						</div>
					</div>

					<div class="form-group">
						<label class="control-label">{{t "sponsorship.edit.analytics"}}</label>
						<input type="text" id="analytics-input" class="input-xxlarge form-control" placeholder="{{t 'sponsorship.edit.analyticsHelp'}}" value="{{analytics}}">
					</div>

					<div class="form-group">
						<label class="control-label">{{t "sponsorship.edit.text"}}</label>
						<div class="controls">
							{{#each @languages}}
								<div class="input-prepend">
									<span class="add-on">{{code}}</span>
									<input type="text" data-code="{{code}}" placeholder="{{t 'sponsorship.edit.textHelp'}}" class="text-input input-xxlarge form-control" value="{{text.content}}">
								</div>
							{{/each}}
						</div>
					</div>

					<div class="form-group">
						<label class="control-label">{{t "sponsorship.edit.description"}}</label>
						<div class="controls">
							{{#each @languages}}
								<div class="input-prepend">
									<span class="add-on">{{code}}</span>
									<input type="text" data-code="{{code}}" placeholder="{{t 'sponsorship.edit.descriptionHelp'}}" class="description-input input-xxlarge form-control">
								</div>
							{{/each}}
						</div>
					</div>

					{{#ifExists header}}
						<div class="form-group">
							<label class="control-label">{{t "sponsorship.edit.header"}}</label>
							<div class="controls">
								{{#each @languages}}
									<div class="input-prepend">
										<span class="add-on">{{code}}</span>
										<input type="text" data-code="{{code}}" placeholder="{{t 'sponsorship.edit.headerHelp'}}" class="header-input input-xxlarge form-control">
									</div>
								{{/each}}
							</div>
						</div>
					{{/ifExists}}

					<div class="form-group">
						<label class="control-label">{{t "sponsorship.edit.link"}}</label>
						<div class="controls">
							<div class="link-picker well">

							</div>
						</div>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</div>
