var LanguageList = require('editor/language-list'),
	ManageSectionView = require('manage/manage-section-view'),
	CountryList = require('editor/countries-list')

module.exports = ManageSectionView.extend({
	template: require('./organization-view-template'),

	events: {
		'click .add-language-button': 'addLanguage',
		'click .save-button': 'save'
	},

	getPageTitle: function() {
		return $.t('organization.title')
	},

	initialize: function(options) {
		App.startLoad()

		this.appId = options.appId

		// Get society for the currently selected app.
		var app = App.appList.get(this.appId),
			societyId = app.get('societyId')

		this.model = App.societiesList.get(societyId)

		this.languageList = new LanguageList()
		var langFetch = this.languageList.fetch()

		this.societyLanguageList = new LanguageList(null, {societyId: societyId})
		var societyLangFetch = this.societyLanguageList.fetch()

		this.countries = new CountryList()
		var countryFetch = this.countries.fetch()

		$.when(langFetch, societyLangFetch, countryFetch).then(this.ready.bind(this))
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		data.languages = this.languageList.toJSON()
		data.countries = this.countries.toJSON()
		return data
	},

	afterRender: function() {
		// Populate current society language assignments.
		if (this.societyLanguageList.length > 0) {
			this.$('.no-languages').hide()
		}

		this.societyLanguageList.forEach(function(lang) {
			this.$('.assigned-languages').append('<li>' + lang.get('name').native + ' (' + lang.get('code').iso3.toUpperCase() + ')</li>')
		}, this)

		if (!App.acl.hasWritePermission('Admin', 'Organization')) {
			this.$('.form-actions').remove()
			this.$('#organization-language').remove()
			this.$('.add-language-button').remove()

			this.$('input, select').attr('disabled', true)
		}
		this.$('.select2').select2()
	},

	ready: function() {
		App.stopLoad()
		this.render()
	},

	addLanguage: function() {
		var id = Number(this.$('#organization-language').val()),
			lang = this.languageList.get(id),
			self = this

		if (!lang) {
			return
		}

		if (!confirm($.t('organization.confirmLanguageAssignment'))) {
			return
		}

		App.startLoad()

		var data = [lang.toJSON()]

		this.societyLanguageList.save(null, {
			data: JSON.stringify(data)
		}).then(function() {
			App.stopLoad()
			self.render()
		})

		this.societyLanguageList.add(lang)
	},

	save: function() {
		App.startLoad()
		var localeId = parseInt(this.$('#organization-locale').val(), 10)

		this.model.set('name', this.$('#organization-name').val())
		this.model.set('description', this.$('#organization-description').val())
		this.model.set('countryId', localeId)
		this.model.set('locale', '-') // Some reason the network request goes iffy without this.. TODO check with backend
		this.model.once('sync', App.stopLoad)
		this.model.save()
	}
})
