{
	"error": {
		"noAccess": "Anda tidak memiliki akses ke sistem ini. Silakan hubungi administrator",
		"noAccessJoin": "Anda saat ini tidak memiliki akses ke sistem ini. Apakah Anda ingin bergabung?",
		"connect": "Tidak dapat terhubung ke server API.",
		"noPermission": "Anda tidak memiliki izin untuk melakukan tindakan itu.",
		"saveFailed": "Gagal menyimpan. Silakan segarkan halaman.",
		"noApps": "Anda tidak memiliki akses ke aplikasi apa pun",
		"lockedBy": "Objek ini saat ini dikunci oleh",
		"oops": "Ups!",
		"rootPage": "Halaman akar tidak dapat ditemukan.",
		"cantPaste": "Jenis objek ini tidak dapat ditempelkan di sini.",
		"generic": "Sebuah kesalahan telah terjadi.",
		"exitWarning": "Data aplikasi masih disimpan. Jika Anda meninggalkan halaman sekarang data mungkin hilang.",
		"allFields": "Harap isi semua bidang.",
		"locked": "Mengedit di aplikasi ini saat ini terkunci"
	},
	"common": {
		"filter": "Filter",
		"linkTypes": {
			"email": "Email",
			"uri": "URI",
			"internal": "Intern",
			"emergency": "Keadaan darurat",
			"timer": "Timer",
			"call": "Panggilan",
			"sms": "SMS",
			"share": "Bagikan",
			"native": "Asli",
			"appLink": "Inter-app",
			"external": "Luar"
		},
		"linkDescriptions": {
			"email": "Tautan untuk mengirim email ke alamat yang disebutkan.",
			"call": "Tautan untuk menghubungi nomor telepon dari perangkat pengguna.",
			"appLink": "Tautan ke laman di aplikasi lain.",
			"timer": "Tautan yang memulai penghitung waktu mundur pada perangkat.",
			"sms": "Tautan untuk mengirim pesan SMS dari perangkat pengguna.",
			"uri": "Tautan ke laman web atau aplikasi eksternal. Akan menyebabkan pengguna meninggalkan aplikasi.",
			"native": "Tautan ke bagian fungsi asli dalam aplikasi.",
			"internal": "Tautan ke halaman berbeda dalam aplikasi.",
			"external": "Tautan ke halaman web eksternal. Akan terbuka di dalam aplikasi.",
			"share": "Tautan untuk membagikan aplikasi melalui dialog 'bagikan' asli di perangkat.",
			"emergency": "Tautan untuk menghubungi nomor telepon darurat yang ditentukan dalam pengaturan pengguna."
		},
		"pageTags": {
			"info": "Info",
			"prepare": "Mempersiapkan",
			"learn": "Belajar",
			"other": "Lain",
			"emergency": "Keadaan darurat",
			"tests": "Tes"
		},
		"upload": "Unggah",
		"language": "Bahasa",
		"next": "Berikutnya",
		"submit": "Menyerahkan",
		"done": "Selesai",
		"sections": "Bagian",
		"email": "Alamat email",
		"save": "Menyimpan",
		"finish": "Selesai",
		"view": "Melihat",
		"publish": "Menerbitkan",
		"close": "Dekat",
		"back": "Kembali",
		"tag": "Menandai",
		"settings": "Pengaturan",
		"tools": "Alat-alat",
		"clickToBrowseMap": "Klik untuk menelusuri peta",
		"preview": "Preview",
		"add": "Menambahkan",
		"edit": "Edit",
		"description": "Deskripsi",
		"search": "Pencarian",
		"longLoad": "Mengambil sejumlah besar data, ini bisa memakan waktu hingga satu menit.",
		"page": "Halaman",
		"cancel": "Membatalkan",
		"closeThisMessage": "Tutup pesan ini",
		"pleaseWait": "Mohon tunggu",
		"position": "Posisi",
		"warning": "PERINGATAN",
		"username": "Nama pengguna",
		"approve": "Menyetujui",
		"reject": "Menolak",
		"approved": "Disetujui"
	},
	"login": {
		"title": "Login",
		"inputs": {
			"password": "Kata sandi"
		},
		"error": {
			"title": "Oopsie!",
			"emailNotFound": "Alamat email tidak ditemukan.",
			"body": "Anda memasukkan nama pengguna atau kata sandi yang salah.",
			"linkExpired": "Tautan setel ulang kata sandi ini telah kedaluwarsa."
		},
		"forgot": {
			"title": "Terkadang kita semua lupa!",
			"body": "Masukkan alamat email yang tertaut ke akun Anda."
		},
		"success": {
			"loginBody": "Anda berhasil masuk, tetapi tidak ada tempat untuk pergi! Anda berada di auth.3sidedcube.com. Silakan pergi ke Storm CMS organisasi Anda.",
			"body": "Anda harus segera menerima email dengan instruksi untuk mereset kata sandi Anda.",
			"title": "Keberhasilan!"
		},
		"links": {
			"forgot": "Saya lupa...",
			"return": "Kembali untuk masuk"
		},
		"reset": {
			"success": "Kata sandi Anda telah diatur, Anda sekarang dapat menutup jendela ini.",
			"confirm": "Memastikan",
			"submit": "Menyerahkan",
			"newPassword": "Kata sandi baru"
		}
	},
	"localisations": {
		"title": "Pelokalan",
		"string": "Utas",
		"key": "Kunci",
		"addNew": "Tambah pelokalan baru",
		"addMultiple": "Tambah beberapa pelokalan",
		"addMultipleInstructions": "Tambah atau perbarui pelokalan dalam ruah. Gunakan CSV untuk pemformatan",
		"uploadCsv": "Unggah CSV",
		"copyFromAnotherApp": "Salin dari aplikasi lain",
		"confirmCopy": "Anda yakin ingin menyalin pelokalan dari aplikasi yang dipilih?",
		"overwriteExisting": "Timpa pelokalan yang ada",
		"overwriteWarning": "Semua terjemahan yang telah Anda lakukan akan ditimpa.",
		"all": "<b>All</b> pelokalan",
		"errors": {
			"csvTooManyColumns": "Terlalu banyak header dalam kolom.",
			"csvInvalidHeader": "Header tidak berlaku.",
			"csvInvalidKeyOnLine": "Kunci pelokalan tidak berlaku pada baris",
			"csvInvalidColumnCountOnLine": "Jumlah kolom tidak berlaku pada baris",
			"expected": "Akan datang",
			"got": "Didapatkan",
			"invalidKey": "Kunci pelokalan tidak valid. Kunci harus dimulai dengan garis bawah dan hanya berisi huruf besar dan garis bawah. Kunci harus berisi setidaknya dua bagian (bit yang dipisahkan oleh garis bawah)."
		}
	},
	"appSetup": {
		"expandAll": "Perluas semua",
		"addCsv": "Tambahkan CSV",
		"translationError": "Gagal mengunduh file terjemahan. Harap periksa aplikasi dan organisasi setidaknya memiliki satu bahasa yang diaktifkan dan dapat dipublikasikan.",
		"deleted": "Dihapus",
		"multiContainer": "Aplikasi penampung",
		"appStatus": "Status aplikasi",
		"multiHelp": "Apakah ini bagian aplikasi dari multi aplikasi?",
		"startPage": "Halaman awal",
		"appName": "Nama aplikasi",
		"languages": "Bahasa aplikasi",
		"translation": "Terjemahan",
		"activeapp": "Aktif",
		"properties": "Properties",
		"live": "Hidup",
		"value": "Nilai",
		"next": "Berikutnya",
		"key": "Kunci",
		"title": "Pengaturan Aplikasi",
		"bundleIdentifiers": "SDK Bundle Identifiers",
		"publishable": "Dapat dipublikasikan ke:",
		"addMoreLanguages": "Hanya bahasa yang ditetapkan untuk organisasi ini tersedia di sini. Tetapkan lebih banyak di bagian Admin.",
		"dev": "Dev",
		"active": "Status",
		"multiContainerHelp": "Apakah aplikasi ini aplikasi kontainer?",
		"publish": "Publikasikan Status",
		"locale": "Lokal aplikasi",
		"individualHelp": "Aplikasi ini adalah aplikasi individual",
		"inactiveHelp": "Aplikasi ini hanya tersedia dalam mode pengembang",
		"wip": "Sedang berlangsung",
		"prev": "Sebelumnya",
		"multiapp": "Aplikasi multi",
		"released": "Dirilis",
		"regional": "Regional",
		"activeHelp": "Aplikasi ini aktif",
		"deactivated": "Nonaktif",
		"multi": "Aplikasi multi",
		"default": "Default",
		"localisation": "Lokalisasi aplikasi",
		"regionalHelp": "Apakah aplikasi ini mewakili banyak negara?",
		"type": "Mengetik",
		"individualapp": "Individu",
		"publishableLanguages": "Bahasa yang dapat dipublikasikan"
	},
	"metadata": {
		"addScreenshot": "Tambahkan Tangkapan Layar",
		"appIconHint": "Harus berukuran 1024 x 1024 tanpa transparansi. Harus dalam format JPG atau PNG, dengan resolusi minimal sekurang-kurangnya 72 DPI, dan dalam ruang warna RGB. Tidak dapat mengandung lapisan atau pojok-pojok yang dibulatkan",
		"appPreviewVideo": "Video pratinjau aplikasi",
		"appPreviewVideoHint": "Pratinjau video aplikasi harus dalam format M4V, MP4, atau MOV dan tidak boleh melebihi 500 MB",
		"appStoreTitle": "Judul toko aplikasi",
		"delete": "Hapus",
		"emailHint": "Sediakan alamat email untuk dihubungi oleh App store atau pengguna",
		"featureGraphic": "Grafik fitur",
		"featureGraphicHint": "Google Play store mengharuskan aplikasi untuk memiliki grafik fitur atau 'banner' - dimensinya harus 1024w x 500h. Banner ini dapat berupa gambar terpisah atau gambar latar untuk video promo (jika tersedia)",
		"languages": "Bahasa",
		"nameOnDevice": "Nama aplikasi pada perangkat",
		"phoneNumberHint": "Harus terdapat kode panggilan negara",
		"platformSpecific": "Metadata spesifik platform",
		"price": "Harga",
		"rating": "Peringkat",
		"screenshotsHint": "Termasuk tangkapan layar untuk setiap ukuran layar. Tangkapan layar harus dalam format JPG atau PNG, dan dalam ruang warna RGB",
		"shared": "Metadata lintas platform",
		"shortAppDescription": "Deskripsi singkat aplikasi",
		"supportUrlHint": "URL dengan informasi dukungan untuk aplikasi Anda. URL ini akan tampak pada App Store",
		"websiteHint": "URL dengan informasi pemasaran tentang aplikasi Anda. URL ini akan tampak pada App Store",
		"launchDate": "Tanggal peluncuran",
		"website": "URL pemasaran",
		"privacyPolicyUrl": "URL kebijakan privasi",
		"phoneNumber": "Nomor telepon",
		"iconSize": "Ikon asli harus berukuran 1024x1024.",
		"appStore": "Detail App Store",
		"features": "fitur",
		"copyright": "hak cipta",
		"supportUrl": "URL dukungan",
		"appIcon": "Ikon aplikasi",
		"description": "Deskripsi",
		"primaryCategory": "Kategori utama",
		"secondaryCategory": "Kategori sekunder",
		"title": "Metadata",
		"name": "Nama aplikasi",
		"keywords": "Kata kunci",
		"noBuildPlatforms": "Tidak ada platform build untuk aplikasi ini"
	},
	"plugins": {
		"title": "Plugin",
		"availablePlugins": "Plugin Tersedia"
	},
	"editor": {
		"inspector": {
			"swapItem": "Sapu item",
			"properties": {
				"progressMessage": "Pesan kemajuan",
				"annotation": {
					"description": "Nomor langkah",
					"title": "Anotasi"
				},
				"title": {
					"title": "Judul",
					"description": "Daftar judul item"
				},
				"name": {
					"description": "Nama sistem internal",
					"title": "Nama"
				},
				"hint": {
					"description": "Instruksi untuk pengguna",
					"title": "Petunjuk"
				},
				"image": {
					"removeButton": "Hapus gambar",
					"chooseButton": "Pilih gambar",
					"title": "Gambar"
				},
				"video": {
					"addLanguage": "Tambahkan bahasa",
					"loop": "Loop",
					"chooseButton": "Pilih video",
					"attributes": "Atribut",
					"title": "Video"
				},
				"link": {
					"linkDestination": "Tautan Tujuan",
					"type": "Mengetik",
					"linkTitle": "Judul Tautan",
					"destination": "Tujuan",
					"body": "Teks isi",
					"recipients": "Penerima (satu per baris)",
					"testLink": "Uji tautan",
					"title": "Link",
					"duration": "Durasi pengatur waktu (menit)"
				},
				"images": {
					"add": "Menambahkan gambar"
				},
				"animation": {
					"delay": "Penundaan bingkai (detik)",
					"chooseButton": "Pilih animasi"
				},
				"embeddedLinks": {
					"title": "Tautan yang disematkan",
					"add": "Tambahkan tautan"
				},
				"embeddedMedia": {
					"title": "Media tersemat"
				},
				"header": {
					"description": "Muncul di atas grup item",
					"title": "Header"
				},
				"footer": {
					"description": "Muncul di bawah grup item",
					"title": "Footer"
				},
				"badge": {
					"completion": {
						"description": "Ditampilkan kepada pengguna saat penyelesaian kuis",
						"title": "Teks penyelesaian"
					},
					"how": {
						"description": "Teks yang menjelaskan cara meraih lencana ini",
						"title": "Cara menghasilkan"
					},
					"title": "Lencana",
					"icon": "Ikon"
				},
				"units": {
					"title": "Unit",
					"description": "detik / meter dll."
				},
				"range": {
					"length": "Panjangnya",
					"title": "Jarak",
					"start": "Mulai",
					"increment": "Kenaikan"
				},
				"completionText": {
					"title": "Teks penyelesaian",
					"description": "Ditampilkan kepada pengguna saat menjawab dengan benar"
				},
				"failureText": {
					"description": "Ditampilkan kepada pengguna saat menjawab salah",
					"title": "Teks kegagalan"
				},
				"winThreshold": {
					"description": "Persentase jawaban yang benar yang diperlukan untuk lulus kuis",
					"title": "Menangkan ambang (%)"
				},
				"winMessage": {
					"description": "Ditampilkan kepada pengguna saat lulus tes",
					"title": "Menangkan pesan"
				},
				"loseMessage": {
					"description": "Ditampilkan kepada pengguna saat gagal tes",
					"title": "Kalah pesan"
				},
				"shareMessage": {
					"description": "Teks untuk dibagikan dengan lencana di jejaring sosial",
					"title": "Bagikan pesan"
				},
				"placeholderImage": "Gambar placeholder",
				"confirmGridTypeChange": "Apakah Anda yakin ingin mengubah jenis grid? Ini akan mengubah semua item grid.",
				"loseRelatedLinks": "Kalah tautan terkait",
				"winRelatedLinks": "Menangkan tautan terkait",
				"relatedLinks": "Tautan yang berhubungan",
				"answer": "Menjawab",
				"overlay": "Teks overlay",
				"button": "Tombol",
				"correctAnswer": "Jawaban yang benar",
				"delay": "Delay (s)",
				"standard": "Standar",
				"quizzes": "Kuis",
				"selectionLimit": "Batas pilihan",
				"textContent": "Konten teks",
				"columns": "Kolom",
				"quiz": "Ulangan",
				"initialPosition": "Posisi awal",
				"caption": "Caption",
				"gridType": "Jenis jaringan",
				"volatile": "Volatile (jangan bertahan)"
			},
			"paste": "Pasta",
			"addBulletItem": "Tambahkan butir peluru",
			"areYouSure": "Apakah kamu yakin?",
			"moveUp": "Pindah ke atas",
			"copySuccess": "Objek disalin ke papan klip",
			"confirmApproval": "Anda yakin ingin menyetujui ini?",
			"copy": "Salinan",
			"addAnother": "Tambahkan yang lain",
			"confirmPageDeleteWarning": "PERINGATAN: Anda menghapus objek halaman. Semua konten halaman akan dihapus. Apakah anda yakin ingin melanjutkan?",
			"confirmDelete": "Anda yakin ingin menghapus elemen ini?",
			"sponsorship": "Pilih Sponsor",
			"rootPageDelete": "Ini adalah halaman root dari aplikasi. Itu tidak bisa dihapus.",
			"changeType": "Ubah jenis",
			"confirmPageDelete": "Konfirmasikan Penghapusan Halaman",
			"title": "Edit Properti",
			"copiedItem": "Item yang disalin",
			"moveDown": "Turun",
			"newType": "Tipe baru"
		},
		"objectPreviews": {
			"TextListItem": "Gunakan item ini untuk teks yang mengawali, biasanya di bagian atas halaman",
			"TitleListItem": "Gunakan item ini untuk teks satu baris",
			"DescriptionListItem": {
				"title": "Item ini memiliki judul dan deksripsi",
				"description": "Gunakan item ini jika Anda ingin menyampaikan banyak hal"
			},
			"StandardListItem": {
				"title": "Selalu gunakan item ini",
				"description": "Item ini memiliki judul, deskripsi dan ikon. Anda dapat tidak mengisinya -- misalnya ikon"
			},
			"ToggleableListItem": {
				"title": "Gunakan ini jika ada yang ingin disembunyikan",
				"description": "Teks ini dapat ditampilkan dan disembunyikan oleh pengguna. Sesuai jika Anda memiliki banyak detail -- mungkin seperti FAQ"
			},
			"OrderedListItem": {
				"title": "Daftar urut",
				"description": "Gunakan ini jika Anda ingin daftar yang bernomor"
			},
			"UnorderedListItem": {
				"title": "Daftar tak urut",
				"description": "Gunakan ini jika Anda ingin daftar hanya memiliki poin tanpa nomor"
			},
			"CheckableListItem": "Gunakan ini untuk daftar periksa",
			"ButtonListItem": "Gunakan ini jika Anda ingin menggunakan tombol",
			"HeaderListItem": {
				"title": "Gunakan ini di bagian atas halaman",
				"description": "Dapat memiliki judul dan deskripsi lebih panjang"
			},
			"LogoListItem": "Gunakan ini jika Anda ingin menyertakan gambar, keterangan, dan tautan misalnya untuk logo",
			"SpotlightListItem": {
				"first": "Berputar",
				"second": "Sorot"
			},
			"ChunkyListItemView": {
				"title": "Seperti item daftar standar -- dengan lebih banyak bantalan",
				"description": "Ini memiliki judul, deskripsi dan ikon. Anda dapat membiarkannya kosong -- misalnya ikon"
			},
			"ListItemView": "Item ini hanya memiliki judul",
			"SponsorshipListItem": "Ini digunakan untuk menampilkan sponsor"
		},
		"pageList": {
			"filter": "Filter halaman",
			"allPages": "Semua halaman",
			"title": "Halaman"
		},
		"lastSaved": "Terakhir disimpan:",
		"newFromTemplate": "Baru dari template",
		"pageTag": "Tag halaman",
		"never": "tak pernah",
		"title": "Editor",
		"addLink": "Tambahkan tautan",
		"addApp": "Tambahkan aplikasi",
		"pageType": "Jenis halaman",
		"pageName": "Nama halaman",
		"addPage": "Tambah halaman",
		"createWithoutTemplate": "Buat halaman baru tanpa template",
		"pageStyle": "Gaya halaman",
		"savesQueued": "menghemat antrean",
		"confirmRelock": "Objek ini sudah dikunci oleh Anda. Apakah Anda ingin menguncinya lagi? Kunci Anda sebelumnya akan dicabut.",
		"changedYourMind": "Berubah pikiran?",
		"addObject": "Tambahkan objek di sini",
		"saving": "Penghematan...",
		"addQuiz": "Tambahkan kuis",
		"confirmRequestNewLock": "Kunci Anda untuk halaman ini telah dicabut. Apakah Anda ingin meminta kunci baru?",
		"pageNameError": "Silakan masukkan nama halaman"
	},
	"mediaLibrary": {
		"chooseExisting": "Pilih yang tersedia",
		"upload": {
			"instructions": "Klik di sini atau seret file untuk mulai",
			"cropType": "Tipe pemotongan",
			"noCrop": "Tanpa pemotongan",
			"square": "Kotak",
			"squareDesc": "Gunakan pemotongan ini untuk ikon dan gambar kuis. Gambar kuis harus <b>640(w)</b> x <b>640(h)</b> piksel",
			"errors": {
				"tooManyFiles": "Pilih satu file"
			},
			"requiresTransparency": "Perlu latar transparan",
			"headerDesc": "Gunakan potongan ini untuk gambar header besar yang muncul di bagian atas halaman. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>568 (h)</b> piksel.",
			"invalidTag": "Tag tidak valid. Tag tidak boleh berisi simbol wildcard (*).",
			"gdpcSpotlightDesc": "Gunakan crop ini untuk header di aplikasi First Aid, lampu sorot, ujung ke ujung, tampilan slide dan gambar video. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>320 (h)</b> piksel.",
			"agreementText": "Semua konten yang diunggah ke sistem ini tunduk pada ketentuan",
			"spotlightDesc": "Gunakan tanaman ini untuk lampu sorot, ujung ke ujung, tampilan slide dan gambar video. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>320 (h)</b> piksel.",
			"filesizeError": "Gagal mengunggah. File terlalu besar. Ukuran file maksimum yang diizinkan adalah",
			"newTag": "Tag baru",
			"selectType": "Silakan pilih jenis gambar yang Anda unggah",
			"imageDesc": "Gambar <b>640 (w)</b> dengan <b>320 (h)</b> piksel direkomendasikan untuk gambar tepi ke tepi, gambar slideshow dan gambar video. Gambar kuis harus <b>640 (w)</b> dengan <b>640 (h)</b> piksel.",
			"title": "Unggah media",
			"genericError": "Pengunggahan file gagal. Kode kesalahan:",
			"tags": "Tag",
			"formatError": "Gagal mengunggah. Format file tidak valid. Format gambar yang diizinkan: .jpeg, .png, .gif Format video yang diizinkan: .mp4, .m4v",
			"gdpcHeaderDesc": "Gunakan potongan ini untuk gambar header besar di aplikasi Bahaya. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>568 (h)</b> piksel."
		},
		"refine": "Sempurnakan pustaka",
		"image": "Gambar",
		"section": "Bagian",
		"general": "Umum",
		"delete": "Menghapus",
		"title": "Perpustakaan Media",
		"loadMore": "Muat lebih",
		"additionalTags": "Tag tambahan",
		"commas": "Pisahkan dengan koma",
		"society": "Masyarakat",
		"filterByTags": "Filter berdasarkan tag",
		"confirmTagRemoval": "Anda yakin ingin menghapus tag ini?",
		"dragToReorder": "Seret untuk menyusun ulang",
		"icon": "Ikon",
		"animation": "Animasi",
		"clearFilters": "Hapus filter",
		"editMedia": "Edit media",
		"video": "Video",
		"type": "Jenis media"
	},
	"viewPicker": {
		"TextListItem": "Teks ujung ke ujung antarkelompok",
		"TitleListItem": "Teks satu baris",
		"StandardListItem": "Judul, tautan dan ikon",
		"DescriptionListItem": "Judul dan deskripsi teks multi-baris",
		"ToggleableListItem": "Judul yang dapat diklik yang menunjukkan konten teks lebih panjang",
		"OrderedListItem": "Judul dan kontek teks multibaris, dengan anotasi atau nomor langkah",
		"UnorderedListItem": "Item daftar poin dengan judul dan deskripsi",
		"CheckableListItem": "Judul dan kotak periksa. Kondisi dapat bertahan di antara pengguna aplikasi",
		"ButtonListItem": "Judul dan tombol",
		"ImageListItem": "Satu gambar, ujung ke ujung",
		"HeaderListItem": "Gambar besar dengan judul timpa dan deskripsi",
		"VideoListItem": "Objek video yang mendukung banyak sumber",
		"AnimatedImageListItem": "Seri gambar ditayangkan sebagai slideshow, memberikan animasi",
		"SpotlightImageListItem": "Satu atau lebih gambar sorot, berisi gambar, keterangan dan tautan",
		"LogoListItem": "Digunakan untuk menampilkan brand perusahaan",
		"SliderQuizItem": "Pertanyaan pilihan slider gambar",
		"TextQuizItem": "Pertanyaan pilihan teks",
		"ImageQuizItem": "Pertanyaan pilihan gambar",
		"AreaQuizItem": "Pertanyaan pilihan daerah",
		"CollectionListItem": "Tampilan 'Kumpulkan Semua' untuk aplikasi, lencana dan tautan",
		"title": "Objek",
		"text": "Teks",
		"filter": "Filter objek",
		"spotlight": "Menyoroti",
		"multiLineObjects": "Objek multi-garis",
		"leadInText": "Timbul teks",
		"dragAndDrop": "Tarik dan lepaskan objek untuk menambahkannya ke halaman",
		"interactiveObjects": "Objek interaktif",
		"mediaObjects": "Objek media",
		"hiddenText": "Teks tersembunyi",
		"basicObjects": "Objek dasar",
		"subText": "Sub-teks",
		"sponsorshipObjects": "Objek sponsor",
		"SponsorshipLogoListItem": "Digunakan untuk menampilkan branding sponsor",
		"SponsorshipListItem": "Item sponsor sederhana"
	},
	"users": {
		"removeUserConfirm": "Anda yakin ingin menghapus pengguna ini?",
		"failedToRemove": "Gagal menghapus pengguna",
		"apps": "Aplikasi",
		"name": "Nama",
		"firstName": "Nama depan",
		"lastActive": "Terakhir aktif",
		"organisation": "Organisasi",
		"role": "Peran",
		"error": "Tidak dapat membuat pengguna. Periksa nama pengguna dan email belum terdaftar.",
		"usernameHelp": "Nama pengguna hanya boleh berisi huruf, angka, setrip bawah, tanda hubung, dan titik.",
		"success": "Keberhasilan! Pengguna baru akan menerima email dengan instruksi untuk mengaktifkan akun mereka.",
		"roles": "Peran",
		"createUser": "Buat pengguna",
		"lastName": "nama keluarga",
		"list": "Daftar pengguna",
		"complete": "Pengguna berhasil dibuat",
		"title": "Pengguna"
	},
	"userApps": {
		"title": "Aplikasi pengguna"
	},
	"push": {
		"messageDescription": "Pesan notifikasi push",
		"categories": {
			"region": "Daerah",
			"future": "Masa depan",
			"standard": "Standar",
			"custom": "Adat"
		},
		"custom": {
			"area": "Daerah target",
			"areaHelp": "Klik peta untuk menentukan daerah untuk notifikasi ini"
		},
		"error": {
			"length": "Pesan notifikasi push terlalu panjang",
			"messageEmpty": "Masukkan pesan",
			"missingRegion": "Pilih daerah"
		},
		"what": "Apa yang ingin Anda kirim?",
		"when": "Kapan Anda ingin mengirimnya?",
		"addToQueue": "Tambahkan ke antrian",
		"types": {
			"default": "Default"
		},
		"priorities": {
			"high": "Tinggi",
			"low": "Rendah"
		},
		"category": "Kategori",
		"approve": "Menyetujui",
		"notes": "Catatan",
		"editPush": "Edit pemberitahuan push",
		"showAllApps": "Tampilkan semua aplikasi",
		"noNotifications": "Tidak ada pemberitahuan",
		"pushDetails": "Detail pemberitahuan",
		"numberCompleted": "Lengkap",
		"numberSent": "Total",
		"noPageSpecified": "Tidak ada halaman yang ditentukan",
		"existing": "Pemberitahuan push yang ada",
		"reject": "Menolak",
		"createdBy": "Dibuat oleh",
		"priority": "Prioritas",
		"type": "Mengetik",
		"saveAndSend": "Simpan dan kirim",
		"message": "Pesan",
		"sent": "Dorong terkirim",
		"sending": "Mengirim",
		"payload": "Muatan",
		"assignments": "Tugas"
	},
	"hazards": {
		"title": "Bahaya",
		"disasters": {
			"categories": {
				"tornado": "Angin topan",
				"wildfire": "Kebakaran",
				"hurricane": "Badai",
				"flood": "Banjir",
				"heatwave": "Heatwave",
				"wind": "Angin",
				"coastal": "Pesisir",
				"tsunami": "Tsunami",
				"nuclear": "Nuklir",
				"earthquake": "Gempa bumi",
				"winter": "Musim dingin",
				"volcano": "Gunung berapi",
				"storm": "Badai"
			},
			"tutorial": {
				"body": "- Diaktifkan artinya halaman akan muncul di editor konten untuk Anda mulai mengerjakannya. <br> - Dev berarti halaman akan muncul di build pengembangan untuk aplikasi ini. <br> - Live berarti halaman akan muncul di app store build untuk aplikasi ini.",
				"heading": "Apa arti dari berbagai negara?"
			},
			"saveSuccess": "Konfigurasi aplikasi disimpan.",
			"configure": "Konfigurasikan",
			"disable": "Nonaktifkan",
			"whatNow": "Halaman 'Apa sekarang'",
			"titleStr": "Bencana",
			"pleaseSpecifyPages": "Silakan tentukan halaman untuk setiap jenis bencana yang dipilih.",
			"alertSettings": "Pengaturan tanda",
			"rootPage": "Halaman root",
			"enable": "Memungkinkan",
			"saveError": "Gagal menyimpan konfigurasi aplikasi.",
			"code": "Kode",
			"enabled": "Diaktifkan",
			"saveClose": "Simpan dan tutup",
			"addCategory": "Tambahkan kategori",
			"page": "Halaman",
			"order": "Memesan",
			"event": "Peristiwa",
			"active": "Acara Aktif",
			"default": "Default",
			"icon": "Ikon",
			"categoriesStr": "Kategori",
			"title": "Bencana"
		},
		"alerts": {
			"times": {
				"day": "Hari",
				"allTime": "Sepanjang waktu",
				"month": "Bulan",
				"week": "Minggu",
				"hour": "Jam"
			},
			"custom": {
				"types": {
					"announcementDesc": "Pengumuman digunakan untuk membuat peringatan untuk acara yang tidak disediakan dari penyedia peringatan lainnya.",
					"pinDesc": "Halaman yang di-pin digunakan untuk menampilkan halaman-halaman penting yang sensitif terhadap waktu dan lokasi.",
					"announcement": "Pengumuman",
					"pin": "Halaman yang dipasang pin"
				},
				"errors": {
					"pleaseSelectRegion": "Silakan pilih wilayah untuk peringatan ini.",
					"pleaseSelectPage": "Silakan pilih halaman yang akan disematkan.",
					"pleaseProvideAreaDescription": "Harap berikan deskripsi area untuk pengumuman ini.",
					"effectiveWrong": "Tanggal efektif tidak boleh setelah tanggal kedaluwarsa",
					"pleaseProvideMessage": "Harap berikan pesan untuk pengumuman ini.",
					"pleaseProvideHeadline": "Harap berikan judul untuk pengumuman ini.",
					"pleaseProvideEvent": "Harap berikan acara untuk pengumuman ini."
				},
				"onsetHelp": "Tanggal bahaya akan terjadi.",
				"area": "Area peringatan",
				"expiryHelp": "Tanggal peringatan ini akan dihapus.",
				"updateMap": "Perbarui peta",
				"areaHelp": "Peringatan akan dikirim ke semua pengguna yang memantau lokasi di area yang dipilih.",
				"polygon": "Tambahkan data poligon khusus di sini.",
				"notify": "Memberitahu",
				"expiry": "Tanggal kadaluarsa",
				"message": "Pesan yang dikirim dengan peringatan ini.",
				"pinPage": "Halaman yang akan ditampilkan.",
				"announcementPage": "Halaman tersebut ditampilkan di bagian \"apa sekarang\".",
				"effectiveHelp": "Tanggal peringatan ini akan efektif dari.",
				"drawRegion": "Gambar di sekitar area peringatan ini harus dikirim",
				"cap_send": "Hasilkan peringatan CAP",
				"cap_create": "TOPI",
				"sendNotification": "Kirim pemberitahuan push ke pengguna ini.",
				"pushes": "Dorong",
				"alertQueued": "Lansiran diantrekan untuk dikirim.",
				"severityHelp": "Seberapa penting peringatan ini?",
				"languageHelp": "Bahasa yang ditulis dalam tanda.",
				"showPolyData": "Tampilkan data poligon",
				"effective": "Tanggal berlaku",
				"severity": "Kerasnya",
				"onset": "Tanggal mulai"
			},
			"inTheLast": "Terakhir",
			"testAlertDesc": "Peringatan tes ini akan dikirim ke semua pengguna yang memantau lokasi di Antartika.",
			"effective": "Efektif",
			"allAlerts": "Semua peringatan",
			"show": "Menunjukkan",
			"testAlert": "Buat tanda uji",
			"issuedBy": "dikeluarkan oleh",
			"alertActive": "Lansiran aktif",
			"alert": "Waspada",
			"title": "Lansiran",
			"createNewAlert": "Buat peringatan baru",
			"pin": "Pin",
			"expired": "Kadaluarsa",
			"specifySinglePolygon": "Beberapa poligon tidak didukung untuk peringatan. Tolong gambar satu poligon tunggal.",
			"noAlerts": "Tidak ada lansiran",
			"pinned": "Pinned"
		},
		"generalNotifications": {
			"customMapData": {
				"fisrtFoundNotLast": "Koordinat pertama ditemukan dalam koordinat, hanya koordinat pertama dan terakhir yang harus sama",
				"firstAndLast": "Koordinat pertama dan terakhir harus sama",
				"moreCoords": "Harap berikan lebih dari tiga koordinat",
				"placeholder": "Klik pada peta di atas atau tambahkan minimal tiga koordinat di sini untuk membuat poligon. yaitu [bujur, lintang], [bujur, lintang], [bujur, lintang]",
				"notFound": "Tidak ditemukan data Poligon, harap tambahkan minimal tiga koordinat. yaitu [bujur, lintang], [bujur, lintang], [bujur, lintang]"
			},
			"dropdown": {
				"update": "Memperbarui",
				"expected": "Diharapkan",
				"minor": "Minor",
				"unlikely": "Tidak sepertinya",
				"future": "Masa depan",
				"other": "Lain",
				"possible": "Mungkin",
				"fire": "Api",
				"likely": "Mungkin",
				"rescue": "Menyelamatkan",
				"security": "Keamanan",
				"unknown": "Tidak dikenal",
				"actual": "Sebenarnya",
				"error": "Kesalahan",
				"past": "Lalu",
				"private": "Pribadi",
				"transport": "Mengangkut",
				"immediate": "Segera",
				"health": "Kesehatan",
				"safety": "Keamanan",
				"draft": "Konsep",
				"restricted": "Terbatas",
				"observed": "Diamati",
				"moderate": "Moderat",
				"severe": "Parah",
				"extreme": "Ekstrim",
				"system": "Sistem",
				"public": "Publik",
				"test": "Uji",
				"cancel": "Membatalkan",
				"alert": "Waspada"
			},
			"urgency": "Urgensi",
			"createNotification": "Buat Notifikasi",
			"certainty": "Kepastian",
			"status": "Status",
			"type": "Mengetik",
			"category": "Kategori",
			"area_description": "Deskripsi daerah",
			"headline": "Judul",
			"severity": "Kerasnya",
			"title": "Pemberitahuan Umum",
			"event": "Peristiwa",
			"scope": "Cakupan"
		},
		"alertZones": {
			"addAlertZone": "Tambahkan Alert Zone",
			"events": "Acara",
			"editAlertZone": "Edit Alert Zone",
			"type": "Mengetik",
			"name": "Nama",
			"blacklist": "Daftar hitam",
			"area": "Daerah",
			"areaSelectModalTitle": "Gambar di sekitar area yang berlaku zona ini",
			"whitelist": "Whitelist",
			"title": "Zona Peringatan"
		},
		"feeds": {
			"tutorial": {
				"steps": "<ol><li> Pastikan Jenis dan kategori Bencana yang dipilih diaktifkan untuk aplikasi ini. Ini akan menentukan feed dan acara mana yang dapat diaktifkan di bawah ini. </li><li> Pilih feed dan jenis acara yang ingin Anda sumbangkan untuk aplikasi ini. </li></ol>",
				"heading": "Menggunakan Feed",
				"body": "Beberapa jenis peringatan dapat berasal dari beberapa sumber peringatan. Bagian ini memungkinkan Anda untuk mengaktifkan atau menonaktifkan feed dan jenis peristiwa tertentu dari masing-masing feed untuk aplikasi ini."
			},
			"delete": {
				"confirm": "Apakah kamu yakin?",
				"yes": "Ya, hapus",
				"events": "Semua acara yang diaktifkan akan dinonaktifkan untuk umpan ini."
			},
			"title": "Feed",
			"feed": "Makan",
			"languages": "Bahasa yang tersedia",
			"enable": "Memungkinkan",
			"type": "Mengetik",
			"configure": "Konfigurasikan",
			"code": "Kode",
			"events": "Acara",
			"scope": "Cakupan",
			"disable": "Nonaktifkan"
		}
	},
	"appCreate": {
		"createNewOrganization": "Buat organisasi baru",
		"appCopy": {
			"appReady": "Aplikasi baru Anda sekarang siap",
			"couldNotFind": "Tidak dapat menemukan pekerjaan salin aplikasi yang diminta",
			"newAppCopying": "Aplikasi baru Anda saat ini sedang disalin"
		},
		"copyFrom": "Salin konten dari",
		"title": "Buat aplikasi baru",
		"details": "Detail aplikasi",
		"emptyApp": "Aplikasi kosong"
	},
	"manageApps": {
		"title": "Kelola aplikasi",
		"yourNextApp": "Aplikasi Anda selanjutnya",
		"easilySetUpYourNextApp": "Buat aplikasi selanjutnya dengan mudah"
	},
	"dashboard": {
		"title": "Dashboard"
	},
	"manage": {
		"title": "Kelola"
	},
	"nav": {
		"items": {
			"appSetup": {
				"title": "Pengaturan Aplikasi",
				"description": "Konfigurasikan nama aplikasi, ikon, pelokalan teks, dan info App Store"
			},
			"content": {
				"title": "Konten",
				"description": "Edit konten dalam aplikasi"
			},
			"publish": {
				"title": "Menerbitkan",
				"description": "Publikasikan perubahan ke dev atau live build"
			},
			"stats": {
				"title": "Statistik",
				"description": "Lihat bagaimana kinerja aplikasi"
			},
			"userAgreement": {
				"description": "Lihat dan tandatangani Perjanjian Program Aplikasi Universal",
				"title": "Perjanjian pengguna"
			},
			"users": {
				"title": "Pengguna",
				"description": "Kelola semua pengguna"
			},
			"stories": {
				"description": "Kelola cerita",
				"title": "Cerita"
			},
			"levels": {
				"description": "Kelola tingkat kemajuan aplikasi",
				"title": "Tingkat"
			},
			"badges": {
				"description": "Kelola lencana yang diberikan untuk menyelesaikan kegiatan dalam aplikasi",
				"title": "Lencana"
			},
			"push": {
				"title": "Pemberitahuan push",
				"description": "Kelola dan kirim pemberitahuan push ke pengguna"
			},
			"sprint": {
				"title": "Papan status",
				"description": "Lihat status perkembangan sprint saat ini"
			},
			"bugs": {
				"title": "Pelaporan Bug",
				"description": "Laporkan masalah dengan editor atau pembuatan aplikasi"
			},
			"gdpcConsole": {
				"description": "Lihat status National Society dan Dev Pool",
				"title": "Konsol NS"
			},
			"hazards": {
				"description": "Prioritas, manajemen bencana, peringatan dan moderasi",
				"title": "Bencana dan Peringatan"
			},
			"decisionTree": {
				"description": "Lihat dan edit pohon keputusan untuk aplikasi",
				"title": "Pohon keputusan"
			},
			"admin": {
				"description": "Lihat statistik, edit pengguna dan detail organisasi",
				"title": "Admin"
			}
		},
		"logout": "Keluar",
		"navigate": "Menavigasi"
	},
	"publish": {
		"history": "Sejarah",
		"success": "Terbitkan sukses.",
		"warning2": "Harap teliti semua perubahan sebelum melanjutkan dan konfirmasikan pilihan Anda di bagian bawah halaman.",
		"comment": "Komentar",
		"title": "Menerbitkan",
		"publishing": "Penerbitan...",
		"toApps": "Publikasikan ke aplikasi",
		"fail": "Publikasikan gagal.",
		"warning1": "Anda telah memilih halaman di bawah ini untuk dipublikasikan ke aplikasi iOS dan Android melalui pembaruan delta.",
		"noChanges": "Tidak ada perubahan",
		"auditTrail": "Jejak audit",
		"details": "Detail",
		"test": "Uji",
		"live": "Hidup",
		"error": "Silakan pilih satu atau beberapa halaman untuk diterbitkan.",
		"startTime": "Waktu mulai",
		"publishToDev": "Publikasikan ke dev",
		"publishConfirm": "Saya mengonfirmasi bahwa saya telah meninjau semua perubahan yang dipilih dan memiliki izin untuk mendorongnya secara langsung",
		"lastModified": "Terakhir diubah",
		"publishType": "Publikasikan jenis",
		"time": "Waktu",
		"progress3": "Sepertinya ini memakan waktu cukup lama. Anda dapat kembali lagi nanti.",
		"pleaseConfirm": "Silakan periksa kotak centang untuk mengonfirmasi bahwa Anda telah meninjau semua perubahan.",
		"selectAll": "Pilih Semua",
		"user": "Pengguna",
		"endTime": "Akhir waktu",
		"detail": "Detail",
		"publishToLive": "Publikasikan untuk hidup",
		"progress1": "Perubahan yang Anda pilih sekarang sedang diterbitkan.",
		"objectType": "Jenis objek",
		"progress2": "Ini mungkin membutuhkan waktu beberapa saat. Jangan keluar dari halaman ini.",
		"publishLandmark": "Landmark Publish?"
	},
	"publishHistory": {
		"title": "Publikasikan riwayat",
		"previousPublishes": "Publikasi sebelumnya"
	},
	"agreement": {
		"signedBy": "Tertanda oleh",
		"date": "Tanggal",
		"sign": "Tanda",
		"daysRemaining": "__count__ hari tersisa",
		"email": "Alamat email",
		"notSigned": "Tidak ditandai",
		"checkToAgree": "Harap centang kotak untuk mengonfirmasi bahwa Anda telah membaca dan menyetujui persyaratan.",
		"hazardsTitle": "Perjanjian Program Aplikasi Bahaya Universal",
		"daysRemaining_plural": "__count__ hari tersisa",
		"title": "Perjanjian Program Aplikasi Universal",
		"status": "Status",
		"signed": "Tertanda",
		"details": "Detail perjanjian",
		"viewThe": "Lihat",
		"signAgreement": "Menandatangani perjanjian",
		"iAgree": "Saya menyetujui persyaratan yang diberikan dalam",
		"fullName": "Nama lengkap",
		"nationalSociety": "Masyarakat nasional"
	},
	"roles": {
		"permissions": "Izin",
		"list": "Daftar peran",
		"title": "Peran pengguna"
	},
	"permissions": {
		"title": "Izin peran",
		"edit": "Edit izin",
		"none": "Tidak ada",
		"read": "Baca baca",
		"write": "Menulis",
		"delete": "Tulis / Hapus"
	},
	"apps": {
		"app": "Aplikasi",
		"subtitle": "Tetapkan aplikasi ke pengguna",
		"title": "Tetapkan aplikasi",
		"assign": "Menetapkan",
		"assignNew": "Tetapkan aplikasi baru"
	},
	"stories": {
		"created": "Dibuat",
		"list": "Daftar kisah",
		"content": "Konten",
		"editStory": "Edit cerita",
		"story": "Cerita",
		"title": "Cerita",
		"name": "Nama",
		"subtitle": "Subtitle"
	},
	"badges": {
		"title": "Lencana",
		"list": "Daftar lencana",
		"editBadge": "Edit lencana"
	},
	"crossBorders": {
		"link": {
			"destination": "Tujuan",
			"title": "Judul",
			"placeholder": "Judul tujuan"
		},
		"contact": {
			"title": "Judul",
			"editItem": "Edit Kontak",
			"number": "Nomor telepon"
		},
		"phrase": {
			"translation": "Terjemahan",
			"phrase": "Frasa",
			"editItem": "Edit Frase"
		},
		"country": {
			"code": "Kode negara",
			"description": "Negara tujuan",
			"name": "Nama negara"
		},
		"editItem": "Edit Negara Tujuan",
		"list": "Daftar tujuan",
		"title": "Lintas Perbatasan",
		"links": "Tautan",
		"phrases": "Frase",
		"contacts": "Kontak",
		"translations": "Terjemahan Frasa"
	},
	"levels": {
		"editLevel": "Sunting level",
		"list": "Daftar tingkat",
		"title": "Tingkat",
		"children": "Tingkat anak-anak",
		"details": "Detail level"
	},
	"bugs": {
		"status": {
			"deleted": "Dihapus",
			"postponed": "Ditunda",
			"byDesign": "Dengan Desain",
			"title": "Status",
			"duplicate": "Duplikat",
			"notReproducible": "Tidak Dapat Diproduksi ulang",
			"closed": "Tutup",
			"fixed": "Tetap",
			"wontFix": "Tidak akan memperbaiki",
			"open": "Buka"
		},
		"title": "Bug",
		"listPending": "Daftar laporan bug yang tertunda",
		"report": "Melaporkan",
		"device": "Versi perangkat / OS",
		"listClosed": "Daftar laporan bug yang ditutup",
		"screenshots": "Tangkapan layar",
		"loadingDetails": "Memuat detail bug ...",
		"loadingFiles": "Memuat file ...",
		"version": "Versi",
		"noFiles": "Tidak ada data",
		"noReports": "Tidak ada laporan",
		"expected": "Perilaku yang diharapkan",
		"noComments": "Tidak ada komentar",
		"comments": "Komentar",
		"bugReport": "Laporan bug",
		"closeIssue": "Tutup masalah",
		"actually": "Perilaku yang sebenarnya",
		"platformDetails": "Detail platform",
		"issueDetails": "Detail masalah",
		"addComment": "Tambahkan komentar",
		"steps": "Langkah-langkah untuk mereproduksi",
		"reportBug": "Laporkan bug",
		"reopenIssue": "Buka kembali masalah",
		"build": "Membangun",
		"listOpen": "Daftar laporan bug yang terbuka"
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"title": "Ikhtisar",
			"totalDownloads": "Total Unduhan",
			"contact": "Kontak",
			"goLive": "Hiduplah",
			"locale": "Lokal",
			"countries": "Negara-negara",
			"edit": "Edit Society",
			"app": "Nama aplikasi",
			"averageRating": "Penilaian rata-rata",
			"downloads": "Unduh",
			"newTotalDownloads": "Total unduhan baru",
			"newIosDownloads": "Unduhan iOS baru",
			"oldIosDownloads": "Unduhan iOS lama",
			"country": "Negara",
			"stage": "Tahap",
			"oldTotalDownloads": "Total unduhan lama",
			"newAndroidDownloads": "Unduhan Android baru",
			"oldAndroidDownloads": "Unduhan Android lama",
			"pool": "Kolam",
			"details": "Detail Masyarakat"
		},
		"devPools": {
			"noApps": "Tidak ada aplikasi di kolam dev ini",
			"responsibility": "Tanggung jawab",
			"expected": "Diharapkan",
			"language": "Bahasa",
			"requiredBy": "Diperlukan oleh",
			"estimatedDate": "Perkiraan tanggal aplikasi saat ini",
			"editPool": "Edit dev pool",
			"title": "Status Dev Pool",
			"completed": "Lengkap",
			"stages": "Tahapan"
		}
	},
	"organization": {
		"title": "Organisasi",
		"details": "Detail organisasi",
		"noLanguages": "Tidak ada bahasa yang ditugaskan",
		"confirmLanguageAssignment": "Anda yakin ingin menetapkan bahasa ini? Tugas bahasa tidak dapat dihapus."
	},
	"mailingList": {
		"list": "Daftar yang tersedia",
		"title": "Daftar surat",
		"dateFrom": "Dari",
		"export": "Ekspor",
		"dateTo": "Untuk"
	},
	"moderation": {
		"image": {
			"title": "Citra moderasi",
			"location": "Lokasi"
		},
		"title": "Moderasi"
	},
	"posts": {
		"title": "Kiriman"
	},
	"achievements": {
		"badge": "Lencana",
		"completion": "Penyelesaian",
		"image": "Gambar",
		"title": "Prestasi",
		"name": "Nama",
		"edit": "Edit Prestasi"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"singular": "Jumlah dibangkitkan",
				"amount": "Jumlah"
			}
		},
		"plural": "Pemicu"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"empty": "Tidak ada Emoodjis sampai sedang",
		"title": "Moderasi Emoodji"
	},
	"whatnow": {
		"attribution": {
			"urlHelp": "URL yang tertaut ke informasi lebih lanjut tentang Penyedia.",
			"image": "Gambar Pengaitan",
			"url": "URL Pengaitan",
			"socName": "Atribusi Nama Masyarakat",
			"message": "Pesan Pengaitan",
			"attribution": "Atribusi",
			"messageHelp": "Pesan yang sesuai dengan atribusi.",
			"socNameHelp": "Nama Perhimpunan yang akan dikaitkan dengan apa yang terjadi.",
			"imageHelp": "Unggahan Gambar / Ikon."
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedap eiusmod incididunt ut labore et dolore magna aliqua. Untuk itu, Anda dapat meminimalkan penggunaan, dan Anda harus melakukan tindakan yang lebih baik dari itu."
			},
			"new": "Tambahkan bahasa baru",
			"editWhatnow": "Sunting Apa Sekarang",
			"forecast": "Ramalan cuaca",
			"warningHelp": "Bersiaplah untuk menanggapi",
			"watch": "Menonton",
			"midterm": "Prediksi jangka menengah",
			"selectLang": "Pilih bahasa",
			"warning": "PERINGATAN",
			"midtermHelp": "Mengurangi risiko (tindakan jangka panjang)",
			"recoverHelp": "Setelah",
			"translations": "Terjemahan",
			"moreinfo": "URL informasi",
			"descriptionHelp": "Deskripsi untuk Apa sekarang.",
			"duringHelp": "Apa yang harus dilakukan saat acara berlangsung.",
			"title": "Judul",
			"beforeHelp": "Cara mempersiapkan sebelum acara itu terjadi.",
			"moreinfohelp": "URL untuk informasi lebih lanjut tentang Apa sekarang.",
			"eventhelp": "Jenis acara untuk Apa sekarang.",
			"event": "Bahaya",
			"before": "Sebelum",
			"forecastHelp": "Menilai dan merencanakan (Tindakan jangka pendek)",
			"watchHelp": "Bersiaplah untuk menanggapi",
			"description": "Deskripsi",
			"immediateHelp": "Menanggapi",
			"addALang": "Tambahkan bahasa untuk memulai",
			"recover": "Memulihkan",
			"immediate": "Segera",
			"empty": "Silakan tambahkan bahasa untuk membuat Apa Sekarang",
			"titleHelp": "Judul untuk Apa sekarang.",
			"during": "Selama",
			"after": "Setelah",
			"languages": "Bahasa",
			"afterHelp": "Apa yang harus dilakukan setelah peristiwa itu terjadi.",
			"missing": "Konten hilang"
		},
		"description": "Deskripsi",
		"content-not-set": "Konten tidak disetel",
		"selectSoc": "Pilih sebuah masyarakat",
		"before": "Sebelum",
		"eventType": "Bahaya",
		"after": "Setelah",
		"during": "Selama",
		"title": "Judul",
		"whatnow": "Apa sekarang?",
		"empty": "Tidak ada entri apa sekarang, klik tambahkan untuk membuatnya"
	},
	"arpro": {
		"codeGroup": {
			"name": "Nama",
			"description": "Deskripsi",
			"codes": "Kode",
			"editItem": "Edit Kode Grup",
			"addCodes": "Masukkan kode di sini",
			"codeGroup": "Kelompok Kode"
		},
		"colours": {
			"name": "Nama",
			"description": "Deskripsi",
			"colours": "Warna"
		},
		"search": {
			"compressionStrength50": "Kekuatan Kompresi 50",
			"resilience75DynamicCompression": "Ketahanan 75 Dynamic Compression",
			"equivalentYoungsModulus": "Modulus Youngs yang Setara",
			"search": "Pencarian Produk",
			"compressionStrength25": "Kekuatan Kompresi 25",
			"mouldedDensity": "Molded Density",
			"tensileStrength": "Daya tarik",
			"tensileElongation": "Elongasi Tarik",
			"burningRate": "Tingkat Kebakaran",
			"compressionStrength75": "Kekuatan Kompresi 75"
		},
		"product": {
			"properties": {
				"weight": "Berat badan (mg)",
				"size": "Ukuran (mm)",
				"food": "Makanan disetujui",
				"bag": "Tas",
				"bulkLabel": "Jumlah besar",
				"moulded": "Kerapatan cetakan (g / l)",
				"bulk": "Bulk density (g / l)",
				"packaging": "Pengemasan"
			},
			"linked": "Memiliki kode akses",
			"colour": "Warna",
			"product": "Produk",
			"physicalProps": "Properti fisik",
			"rawData": "Data JSON mentah",
			"editItem": "Edit Produk",
			"noneLinked": "Tidak ada kode akses",
			"name": "Nama",
			"grade": "Kelas",
			"specifications": "Spesifikasi",
			"rawDataError": "Data Raw JSON - Data JSON tidak valid",
			"moulded": "Molded Density Range (g / l)",
			"notes": "Catatan",
			"access": "Kode Akses"
		},
		"arpro": "arpro",
		"noitems": "Tidak ada item untuk ditampilkan, klik tambahkan di atas."
	},
	"r4b": {
		"level": {
			"green": "hijau",
			"alpha": "Alfa",
			"selectBadge": "Silakan pilih lencana",
			"blue": "Biru",
			"levels": "Tingkat",
			"red": "Merah",
			"colour": "Warna",
			"title": "Tingkat"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "Bagian Visibility Logic"
				},
				"slider": {
					"startLabel": "Mulai Label",
					"interval": "Selang",
					"endLabel": "Label Akhir"
				},
				"question": {
					"answerHelp": "Masukkan jawaban",
					"responseHelp": "Masukkan tanggapan",
					"addResponse": "Tambahkan tanggapan",
					"title": "Pertanyaan dan jawaban",
					"textHelp": "Masukkan pertanyaan",
					"selectionHelp": "Pilih bagian untuk melanjutkan ke",
					"addAnotherResponse": "Tambahkan tanggapan lain"
				},
				"quote": {
					"title": "Kutipan",
					"citation": "Masukkan kutipan di sini"
				},
				"checkbox": {
					"title": "Kotak centang"
				},
				"textInput": {
					"multi": "Teks multi-baris?",
					"title": "Input Teks",
					"help": "Masukkan label input di sini"
				},
				"link": {
					"title": "Tautan"
				},
				"help": {
					"title": "Objek Bantuan"
				},
				"task": {
					"title": "Objek Tugas",
					"help": "Masukkan tujuan tugas"
				},
				"text": {
					"title": "Objek Teks"
				},
				"radio": {
					"title": "Tombol radio"
				},
				"list": {
					"add": "Tambahkan item daftar"
				},
				"addIdentifier": "Tambahkan pengenal",
				"break": "Istirahat halaman",
				"enterTitle": "Masukkan judul",
				"inputLabel": "Label Masukan Teks",
				"addSection": "Seret item ke sini untuk membuat bagian baru",
				"options": "Pilihan",
				"clickToEdit": "Klik untuk mengedit",
				"videoLink": "Tautkan ke video eksternal",
				"identifier": "Identifier",
				"section": "Bagian",
				"enterText": "Masukkan teks di sini"
			},
			"section": {
				"title": "BAGIAN",
				"add": "Tambahkan bagian di bawah ini",
				"settings": "Pengaturan bagian",
				"delete": "Hapus bagian"
			},
			"blockTypes": {
				"userInput": "BLOK PENGGUNA INPUT",
				"check": "MEMERIKSA",
				"textInput": "TEXT ENTRY",
				"text": "TEKS",
				"radio": "RADIO",
				"quote": "KUTIPAN",
				"slider": "SLIDER",
				"image": "GAMBAR",
				"video": "VIDEO",
				"qna": "Q & A",
				"link": "LINK",
				"help": "MEMBANTU",
				"task": "TUGAS",
				"select": "MEMILIH",
				"media": "BLOK MEDIA"
			},
			"title": "Modul",
			"mins": "menit",
			"module": "Modul",
			"person": "orang",
			"version": "Versi",
			"visibility": "Visibilitas",
			"unsaved": "Ada perubahan yang belum disimpan",
			"level": "Tingkat",
			"levels": "Tingkat",
			"people": "orang-orang",
			"saved": "Semua perubahan disimpan"
		},
		"assessments": {
			"assessment": "Penilaian",
			"pre": "Sebelum penilaian"
		},
		"people": "ORANG-ORANG",
		"inputBlocks": "BLOK PENGGUNA INPUT",
		"empty": "Tidak ada modul atau penilaian di sini, tambahkan satu ke tingkat ini untuk memulai.",
		"person": "ORANG",
		"questions": "Pertanyaan",
		"sections": "BAGIAN",
		"contentBlocks": "Blok Konten",
		"minutes": "MINS",
		"admin": "Dasbor Admin",
		"mediaBlocks": "BLOK MEDIA",
		"id": "Identitas unik",
		"copyModule": "Modul Duplikat",
		"copyConfirmation": "Anda yakin ingin menduplikasi modul ini?",
		"languages": "Bahasa",
		"title": "ATLAS",
		"languageVars": "Variasi Bahasa",
		"notTranslated": "Tidak diterjemahkan",
		"placeholder": "Placeholder",
		"delete": "Menghapus",
		"isBonus": "Bonus:",
		"setBonus": "Ditetapkan sebagai modul bonus:",
		"noTranslationHelp": "Edit bidang + simpan untuk memperbarui"
	},
	"rspb": {
		"factFile": {
			"name": "Nama",
			"funFacts": "Fakta menyenangkan",
			"features": "fitur",
			"addImages": "Pilih gambar",
			"show": "Menunjukkan",
			"addFact": "Masukkan fakta di sini",
			"addActivities": "Masukkan aktivitas di sini",
			"addFeatures": "Masukkan fitur di sini",
			"scientificName": "Nama ilmiah",
			"images": "Gambar",
			"description": "Deskripsi",
			"start": "Mulai",
			"factFile": "File Fakta",
			"editItem": "Edit File Fakta",
			"addTags": "Masukkan tag di sini",
			"tags": "Tag",
			"commonName": "Nama yang umum",
			"seen": "Terlihat",
			"activities": "Kegiatan"
		},
		"planItem": {
			"difficulty": {
				"hard": "Keras",
				"easy": "Mudah",
				"difficulty": "Pilih kesulitannya",
				"medium": "Medium"
			},
			"garden": {
				"medium": "Medium",
				"large": "Besar",
				"small": "Kecil",
				"selectType": "Pilih jenis taman",
				"public": "Publik",
				"balconyroof": "Balkon / Atap"
			},
			"tags": "Tag",
			"visible": "Terlihat dari",
			"months": "Bulan",
			"pageHelp": "Ini adalah halaman yang akan ditautkan oleh item paket Anda",
			"planItems": "Item Paket",
			"duration": "Durasi (detik)",
			"to": "Tanggal akhir",
			"regionHelp": "Klik untuk memilih wilayah untuk item paket ini",
			"image": "Gambar",
			"title": "Judul",
			"identifier": "Identifier",
			"solo": "Untuk keluarga?",
			"from": "tersedia dari",
			"leastImportant": "Paling tidak penting",
			"activityCode": "Kode aktivitas",
			"dates": "tanggal",
			"source": "Sumber",
			"type": "Mengetik",
			"weighting": "Penentuan bobot paket",
			"indefinite": "Tak terbatas?",
			"mostImportant": "Yang terpenting",
			"description": "Deskripsi",
			"region": "Wilayah",
			"weightingHelp": "Prioritas tempat item akan muncul di aplikasi"
		},
		"noitems": "Tidak ada item untuk ditampilkan, klik tambahkan di atas.",
		"rspb": "RSPB"
	},
	"facility": {
		"filter": {
			"select": "Pilih fasilitas",
			"title": "Jenis fasilitas filter:"
		},
		"edit": {
			"address": {
				"country": "Negara",
				"state": "Negara",
				"postalCode": "Kode Pos",
				"title": "Alamat",
				"locality": "Lokalitas",
				"line1": "Jalur 1"
			},
			"contactdetails": {
				"phone": "Nomor telepon",
				"add": "Tambahkan nomor",
				"fax": "Fax"
			},
			"name": "Nama",
			"contact": "Kontak",
			"location": "Lokasi",
			"region": "Wilayah",
			"url": "URL",
			"division": "Divisi",
			"type": "Mengetik",
			"add": "Tambahkan fasilitas",
			"facility": "Edit fasilitas",
			"facilityid": "FacilityId"
		},
		"title": "Fasilitas",
		"placeholder": "Obyek fasilitas",
		"list": "Daftar fasilitas"
	},
	"sponsorship": {
		"edit": {
			"link": "Link",
			"description": "Deskripsi",
			"tags": "Tag",
			"add": "Tambahkan Sponsor",
			"sponsor": "Edit Sponsor",
			"analytics": "Kode Analytics",
			"icon": "Ikon Gambar",
			"feature": "Unggulkan Gambar",
			"text": "Teks Sponsor Utama",
			"pagesLink": "Pilih halaman untuk ditautkan",
			"inputLink": "Masukkan URL untuk dinavigasi",
			"descriptionHelp": "Deskripsi sponsor.",
			"analyticsHelp": "Masukkan kode analytics sponsor Anda di sini.",
			"nativeLink": "Masukkan tautan ke bagian fungsi asli",
			"textHelp": "Teks ini digunakan sebagai judul untuk item sponsor.",
			"iconHelp": "Pilih gambar untuk digunakan sebagai ikon.",
			"featureHelp": "Pilih gambar yang akan digunakan sebagai gambar sponsor utama.",
			"tagsHelp": "Tag ini memungkinkan beberapa sponsor ditambahkan ke halaman."
		},
		"placeholder": "Objek sponsor",
		"title": "Sponsorship",
		"list": "Daftar Sponsor"
	},
	"journey": {
		"elapsed": "Berlalu",
		"title": "Judul",
		"active": "Aktif",
		"link": "Link",
		"stage": "Tahap",
		"pending": "Menunggu keputusan",
		"pageTitle": "Darah Journey",
		"edit": "Edit Blood Journey"
	},
	"frame": {
		"order": "Memesan",
		"name": "Nama",
		"edit": "Edit Frame",
		"src": "Src",
		"pageTitle": "Bingkai",
		"type": "Mengetik"
	},
	"swim": {
		"edit": "Edit Level Perenang",
		"pageTitle": "Level Berenang"
	}
}