<td><img style="max-width:150px;" src="{{getPreviewUrl icon.src.x1}}"></td>
<td><img style="max-width:150px;" src="{{getPreviewUrl feature.src.x1}}"></td>

<td>
	<dl>
		{{#key_value text.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dt>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value description.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dt>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{analytics}}
	</dl>
</td>
<td>
	<dl>
		{{#each tags}}
			<span class="list-tag">{{this}}</span>
		{{/each}}
	</dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/sponsors/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
