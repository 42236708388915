{{#each data.products}}
	<div class="product-inspector-item">
		<div class="product-select-items">
			<select class="product-selection input-block-level form-control form-control-margin" data-key={{@key}} data-id={{#if id}} {{id}} {{else}} '-1' {{/if}} data-cid={{../viewCid}}>
					<option>-</option>
				{{#each ../productList}}
					<option value="{{id}}" {{selectedSimpleMatches id ../value}}>{{getLocaleText name}}</option>
				{{/each}}
			</select>
			<button class="btn-link remove-product" data-key={{@key}} data-id={{#if id}} {{id}} {{else}} '-1' {{/if}}><i class="icon-remove"></i></button>
		</div>
	</div>
	<hr>
{{/each}}
