var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./facility-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId
		this.model = options.facility
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		data.location = data.location ? JSON.parse(data.location) : {}
		return data
	}
})
