var PushList             = require('./push-list'),
	PushListItemView     = require('./push-list-item-view'),
	PushEditorView       = require('./push-editor-view'),
	UsersList            = require('users/user-list'),
	AppList              = require('editor/app-list'),
	DashboardSectionView = require('dashboard/dashboard-section-view')

/**
 * Exports {@link PushView}.
 * @module
 */
module.exports = DashboardSectionView.extend(/** @lends PushView.prototype */{
	/** @override */
	className: 'push-view',

	/** @override */
	template: require('./push-view-template'),

	/** @override */
	events: {
		'click .add-button': 'addButtonClick_',
		'click .close-add-button': 'closeAddButtonClick_',
		'change .show-all-checkbox': 'showAllChangeHandler_',
		'click .sort-button': 'sortButtonClick_',
		'click .add-to-queue-button': 'addToQueueButtonClick_'
	},

	/**
	 * @constructs PushView
	 * @extends DashboardSectionView
	 * @override
	 */
	initialize: function(options) {
		if (!options.app) {
			throw new Error('No app specified')
		}

		/** @private {App} */
		this.app_ = options.app

		this.totalReadyCount = 1
		this.readyCount = 0
		this.listViews = []

		/** @private {?string} */
		this.comparator_ = null
		/** @private {PushEditorView} */
		this.pushEditorView_ = new PushEditorView(options)

		// Set URL params for fetching push list.
		this.showAll = options.showAll
		var data = (this.showAll) ? {} : {appId: this.app_.id}

		this.collection = new PushList()
		this.collection.once('sync', this.fetchUsers, this)
		this.collection.fetch({data: data})

		this.canApprove = App.acl.getPermission('Push') === 'Approve'

		// Fetch full app list if we're doing approvals, for society name
		if (this.canApprove) {
			this.appList = new AppList({id: 'all'})
			this.appList.once('sync', this.ready, this)
			this.appList.fetch()

			this.totalReadyCount = 2
		}
	},

	/** @override */
	getPageTitle: function() {
		return $.t('nav.items.push.title')
	},

	fetchUsers: function() {
		var userIDs = this.collection.map(function(item) {
			return item.get('userId')
		})

		this.userList = new UsersList({ids: _.uniq(userIDs)})
		this.userList.once('sync', this.ready, this)
		this.userList.fetch()
	},

	/** @override */
	getRenderData: function() {
		return {appId: this.app_.id}
	},

	/** @override */
	afterRender: function() {
		// Render out creation form.
		this.$('.push-edit-container').append(this.pushEditorView_.el)
		this.pushEditorView_.render()

		// Clear all previous list items.
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []

		// Render out all list items.
		this.collection.forEach(this.addItem, this)

		// Show 'No notifications' if appropriate.
		if (this.collection.length === 0) {
			this.$('.empty-table-text').show()
			this.$('table').hide()
		}

		if (App.acl.hasReadPermission('Push')) {
			this.$('.add-button').remove()
		}

		if (!this.canApprove) {
			this.$('.show-all-label').remove()
			this.$('.ns-name').remove()
		}

		if (this.showAll) {
			this.$('.show-all-checkbox').prop('checked', true)
		}

		// Remove sent count from ARC
		if (App.system.id === 9) {
			this.$('.push-count-col').remove()
		}
	},

	ready: function() {
		if (++this.readyCount === this.totalReadyCount) {
			App.stopLoad()

			// Match usernames to IDs
			this.collection.each(function(item) {
				var userId = item.get('userId')
				var user = this.userList.get(userId)
				var name

				if (user) {
					name = user.get('firstName') + ' ' + user.get('lastName')
				} else {
					name = '-'
				}

				item.set('user', name)

				if (this.canApprove) {
					// Set society ID from app ID
					var app = this.appList.get(item.get('appId'))

					if (app) {
						item.set('societyId', app.get('societyId'))
					}
				}
			}, this)

			this.render()
		}
	},

	addItem: function(item) {
		// Don't display automated notifications
		// if (item.get('category') === 'automated') {
		// 	return
		// }

		var view = this.addView(new PushListItemView({
			model: item,
			appId: this.app_.id
		}))
		this.$('.list-items').append(view.render().el)
	},

	/**
	 * Handles changes to the 'show all' checkbox, reloading the view with the
	 * new state.
	 * @param {MouseEvent} e Event object.
	 * @private
	 */
	showAllChangeHandler_: function(e) {
		var showAll = e.currentTarget.checked
		App.router.push(this.app_.id, showAll)
	},

	/**
	 * Handles click events to a sort comparator column header, re-sorting the
	 * collection and updating the view.
	 * @param {MouseEvent} e Event object.
	 * @private
	 */
	sortButtonClick_: function(e) {
		var comparator = $(e.currentTarget).data('property')
		if (comparator === this.comparator_) {
			this.reverse = !this.reverse
		} else {
			this.reverse = false
		}

		this.comparator_ = comparator
		this.collection.comparator = function(a, b) {
			var valueA = a.get(comparator),
				valueB = b.get(comparator)
			if (valueA && valueB) {
				if (typeof valueA === 'number') {
					if (this.reverse) {
						return valueA - valueB
					}

					return valueB - valueA
				}

				if (this.reverse) {
					return valueB.localeCompare(valueA)
				}

				return valueA.localeCompare(valueB)
			}
		}.bind(this)

		this.collection.sort()
		this.render()
	},

	/**
	 * Handles clicks to the 'add' button. Opens the add panel and swaps the
	 * 'add' and 'close' buttons.
	 * @private
	 */
	addButtonClick_: function() {
		this.toggleAddPanel_(true)
		this.$('.close-add-button').focus()
	},

	/**
	 * Handles clicks to the 'close' button. Closes the add panel and swaps the
	 * 'add' and 'close' buttons.
	 * @private
	 */
	closeAddButtonClick_: function() {
		this.toggleAddPanel_(false)
		this.$('.add-button').focus()
	},

	/**
	 * Toggles the open state of the add panel, opening or closing it and
	 * swapping the add/close buttons.
	 * @param {boolean} show True if the add panel should be shown (opened).
	 * @private
	 */
	toggleAddPanel_: function(show) {
		this.$('.add-button').toggleClass('hidden', show)
		this.$('.close-add-button').toggleClass('hidden', !show)
		this.$('.add-form').toggleClass('zero-height', !show)
	},

	/**
	 * Handles click events to the 'Add to queue' button, saving the new
	 * notification and reloading the view.
	 * @returns {boolean} {@code false} to stop default form action
	 *     (submission).
	 * @private
	 */
	addToQueueButtonClick_: function() {
		// Only show confirm dialog for standard pn (as this could affect a lot of people)
		if (this.$('#push-category').val() === 'standard') {
			swal({
				title: $.t('editor.inspector.areYouSure'),
				text: $.t('push.confirmStandard'),
				showCancelButton: true,
				confirmButtonText: $.t('push.addToQueue')
			}, function(didConfirm) {
				if (didConfirm) {
					this.savePushNotification()
				}
			}.bind(this))
		} else {
			this.savePushNotification()
		}
		return false
	},

	savePushNotification: function() {
		App.startLoad()
		$(".add-to-queue-button").prop("disabled", true)
		var appId = this.app_.id
		this.pushEditorView_.save().then(function() {
			App.stopLoad()
			App.router.push(appId, this.showAll)
		}, function(msg) {
			var oops  = $.t('error.oops'),
				error = 'error'

			if (typeof msg === 'string') {
				swal(oops, msg, error)
			} else {
				swal(oops, $.t('error.generic'), error)
			}
			$(".add-to-queue-button").prop("disabled", false)
			App.stopLoad()
		})
	},

	/** @override */
	beforeDestroy: function() {
		this.pushEditorView_.destroy()
	}
})
