var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view')

var SponsorListView = ListView.extend({
	template: require('./sponsor-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./sponsor-list-item-view'),

	afterInitialize: function(options) {
		this.appId = options.appId
	},

	getRenderData: function() {
		return {appId: this.appId}
	}
})

/**
 * Exports {@link SponsorView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends SponsorView.prototype */{
	/** @override */
	className: 'sponsoship',
	/** @override */
	template: require('./sponsor-view-template'),

	/**
	 * @constructs SponsorVew
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		this.app = App.appList.get(options.appId)

		this.views = {
			list: new SponsorListView({
				app: this.app,
				appId: this.app.id,
				fetchData: {data: {class: 'StormQLSponsorship'}}
			})
		}
	},

	/** @override */
	getPageTitle: function() {
		return $.t('sponsorship.title')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	}
})
