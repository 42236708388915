<div class="container">
	<div class="row">
		<div class="col-sm-6">
			<h3 data-i18n="facility.edit.facility"></h3>
		</div>
		<div class="col-sm-6 actions-col">
			<button class="button red wide goBack" type="button">{{t "common.cancel"}}</button>
			<button class="button green wide save" type="submit">{{t "common.save"}}</button>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<form class="form-horizontal">
				<fieldset>
					<div class="form-group">
						<h4><label>Details</label></h4><hr />
						<label class="control-label">{{t "facility.edit.name"}}</label>
						<div class="controls">
							<input type="text" id="name-input" class="input-xxlarge form-control" value="{{name}}">
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">{{t "facility.edit.type"}}</label>
						<div class="controls">
							<select class="input-xxlarge form-control" id="type-input">
								{{#each types}}
									<option value="{{this}}" {{#ifEqual this ../type}} selected {{/ifEqual}}>
										{{this}}
									</option>
								{{/each}}
							</select>
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">{{t "facility.edit.url"}}</label>
						<div class="controls">
							<input type="text" id="url-input" class="input-xxlarge form-control" value="{{url}}">
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">{{t "facility.edit.facilityid"}}</label>
						<div class="controls">
							<input type="text" id="facilityid-input" class="input-xxlarge form-control" value="{{facilityId}}">
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">{{t "facility.edit.division"}}</label>
						<div class="controls">
							<input type="text" id="division-input" class="input-xxlarge form-control" value="{{division}}">
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">{{t "facility.edit.region"}}</label>
						<div class="controls">
							<input type="text" id="region-input" class="input-xxlarge form-control" value="{{region}}">
						</div>
					</div>
					<div class="form-group location-group">
						<h4><label>Location</label></h4><hr />
						<label>Longitude</label> <input type="text" id="location-lng-input" class="input-xxlarge form-control" value="{{locationparsed.lng}}">
						<label>Latitude</label> <input type="text" id="location-lat-input" class="input-xxlarge form-control" value="{{locationparsed.lat}}">
					</div>

					<div class="form-group">
						<h4><label>{{t "facility.edit.address.title"}}</label></h4><hr />
						<label>{{t "facility.edit.address.line1"}}</label> <input type="text" id="address-line1-input" class="input-xxlarge form-control" value="{{address.line1}}">
						<label>{{t "facility.edit.address.locality"}}</label> <input type="text" id="address-locality-input" class="input-xxlarge form-control" value="{{address.locality}}">
						<label>{{t "facility.edit.address.state"}}</label> <input type="text" id="address-state-input" class="input-xxlarge form-control" value="{{address.state}}">
						<label>{{t "facility.edit.address.country"}}</label> <input type="text" id="address-country-input" class="input-xxlarge form-control" value="{{address.country}}">
						<label>{{t "facility.edit.address.postalCode"}}</label> <input type="text" id="address-postalcode-input" class="input-xxlarge form-control" value="{{address.postalCode}}">
					</div>
					<div class="form-group">
							<h4><label>{{t "facility.edit.contact"}}</label></h4><hr />
							<label>{{t "facility.edit.contactdetails.phone"}}</label>
							<div class="input-group">
								<input type="text" class="form-control form-control" id="contact-add-telephone" placeholder="{{t 'facility.edit.contactdetails.add'}}">
								<span class="input-group-btn">
									<button class="btn btn-default" id="contact-add-telephone-button" type="button">{{t "common.add"}}</button>
								</span>
							</div><!-- /input-group -->
								<ul id="contact-telephone-list"></ul>
							<label>{{t "facility.edit.contactdetails.fax"}}</label> <input type="text" id="contact-fax-input" class="input-xxlarge form-control" value="{{contact.fax}}">
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</div>
