var ListView = require('./list-view')

/**
 * Exports {@link PaginatedListView}.
 * @module
 */
module.exports = ListView.extend(/** @lends PaginatedListView.prototype */{
	/** @override */
	events: {
		'click .add': 'addModel',
		'click .page-link': 'changePage'
	},

	/**
	 * @constructs PaginatedListView
	 * @extends ListView
	 * @override
	 * @classdesc Behaves exactly as {@link ListView}, but supporting paginated
	 *     Storm endpoints. Paging UI is rendered automatically.
	 */
	initialize: function(options) {
		options = options || {}

		this.app = options.app
		this.fetchData = options.fetchData || {}

		this.listViews = []
		this.pageLimit = 10
		this.pages = 0
		this.removed = 0

		var ListConstructor = this.list

		this.collection = new ListConstructor(null, options)
		this.collection.on('add', this.addListItem, this)
		this.collection.on('destroy', this.objectRemoved, this)
		this.collection.on('create', this.objectCreated, this)
		this.collection.on('sync', this.ready, this)
		this.getPage(0)
	},

	getPage: function(page) {
		this.page = page

		// Offset the start index by the number of items we've removed.
		// Means that if you clear page 1 and go to page 2, you get the *new*
		// page 1.
		var start = this.page * this.pageLimit - this.removed

		// Don't try to fetch a negative start index.
		start = Math.max(start, 0)

		var end = start + (this.pageLimit - 1)

		this.clearList()
		this.removed = 0

		this.collection.fetch({
			headers: {Range: 'indices=' + start + '-' + end},
			data: this.fetchData
		})
	},

	ready: function(collection, response, options) {
		// Show pagination controls
		var range = options.xhr.getResponseHeader('Content-Range')

		if (range) {
			// Calculate number of pages
			var rangeMatch = range.match(/(\d+)-\d+.*\/(\d+)/)

			// Set new page number in case the page change has been offset
			// (items removed).
			this.page = Math.floor(rangeMatch[1] / this.pageLimit)
			this.total = Number(rangeMatch[2])
			this.generatePaginationControl()
		}

		if (this.total > 0) {
			this.$('table').show()
			this.$('.empty-table-text').hide()
		} else {
			this.$('table').hide()
			this.$('.empty-table-text').show()
		}

		this.trigger('ready')
		App.stopLoad()
	},

	generatePaginationControl: function() {
		var pages = this.pages = Math.ceil(this.total / this.pageLimit)

		// Generate control and buttons
		var pageControl = $('<ul class="pagination">')
		var prevButton = $('<li><a href class="page-link" data-page="prev"><i class="icon-chevron-left"></i></a></li>')
		var nextButton = $('<li><a href class="page-link" data-page="next"><i class="icon-chevron-right"></i></a></li>')

		pageControl.append(prevButton)

		var firstPage = Math.max(1, this.page - 3)
		var lastPage = Math.min(pages - 1, this.page + 3)

		// Always show first page index.
		pageControl.append('<li><a href class="page-link" data-page="0">1</a></li>')

		// Show dots if the full range isn't shown.
		if (firstPage > 1) {
			pageControl.append('<li><a>...</a></li>')
		}

		for (var i = firstPage; i < lastPage; i++) {
			pageControl.append('<li><a href class="page-link" data-page="' + i + '">' + (i + 1) + '</a></li>')
		}

		// Show dots if the full range isn't shown.
		if (lastPage < pages - 1) {
			pageControl.append('<li><a>...</a></li>')
		}

		// Always show last page index.
		pageControl.append('<li><a href class="page-link" data-page="' + (pages - 1) + '">' + pages + '</a></li>')

		pageControl.append(nextButton)

		// Show control on page, if multiple pages
		if (pages > 1) {
			this.$('.pagination').html(pageControl)
		} else {
			this.$('.pagination').html('')
		}

		this.updatePaginationButtons()
	},

	updatePaginationButtons: function() {
		// Update pagination control
		this.$('.pagination .active').removeClass('active')
		this.$('.pagination .disabled').removeClass('disabled')
		this.$('.pagination a[data-page=' + this.page + ']').parent().addClass('active')

		if (this.page === 0) {
			this.$('.pagination li:first-child').addClass('disabled')
		} else if (this.page === this.pages - 1) {
			this.$('.pagination li:last-child').addClass('disabled')
		}
	},

	changePage: function(e) {
		// Get requested page number
		var page = $(e.currentTarget).data('page')

		if (page === 'prev') {
			page = this.page - 1
		} else if (page === 'next') {
			page = this.page + 1
		}

		page = parseInt(page, 10)

		if (page < 0 || page >= this.pages) {
			return
		}

		App.startLoad()

		// Fetch items and render
		this.getPage(page)

		this.collection.once('sync', this.generatePaginationControl, this)
	},

	clearList: function() {
		this.listViews.forEach(function(item) {
			item.destroy()
		})

		this.listViews = []

		while (this.collection.length) {
			this.collection.remove(this.collection.first())
		}
	},

	objectCreated: function(model) {
		// New object has been created - update pagination if need be
		this.total++
		this.generatePaginationControl()

		// If we're on the first page, render it
		if (this.page === 0) {
			this.collection.push(model, {silent: true})
			this.listViews[this.listViews.length - 1].destroy()
			this.prependListItem(model)
		}
	},

	objectRemoved: function() {
		// Model removed - update pagination if needed
		this.total--
		this.removed++
		this.generatePaginationControl()
	},

	/**
	 * @override
	 * Override this so list view's after render is not called (calls collection.fetch without the required headers)
	 */
	afterRender: function() {}
})
