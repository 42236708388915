var LinkSelector          = require('./link-selector'),
	MediaLibrary          = require('media-library/media-library-view'),
	MediaSelectorView     = require('media-library/media-selector-view'),
	QuizSelector          = require('./quiz-selector'),
	GridViewInspector     = require('./grid-view-inspector'),
	ImageListItem         = require('./inspector-image-list-item-view'),
	LinkList              = require('./link-list-view'),
	StormObject           = require('editor/storm-object'),
	StormCollection       = require('editor/storm-collection'),
	AppStructure          = require('editor/page-list/app-structure'),
	StormQL               = require('models/stormql'),
	MultiVideoSelector    = require('./multi-video-selector'),
	VideoInspectorView    = require('./video-inspector-view'),
	CreateTemplateView    = require('editor/templates/template-create-view'),
	EmbeddedMediaSelector = require('./embedded-media-selector'),
	ViewPicker            = require('editor/view-picker'),
	ListItemPreviewView   = require('editor/canvas/list-item-preview-view'),
	utils                 = require('lib/utils'),
	APICompat             = require('lib/api-compat'),
	pageTags              = require('editor/page-tags'),
	SponsorshipSelector   = require('./inspector-sponsor-selector-view'),
	ProductSelector       = require('./inspector-product-selector-view')

var Inspector = Backbone.View.extend({
	template: require('./inspector-view-template'),

	className: 'inspector row',

	events: {
		'keypress input, textarea': 'inputChange',
		'keydown input, textarea': 'inputChange',
		'keyup input, textarea': 'inputChange',
		'paste input, textarea': 'inputChange',
		'change input, select': 'inputChange',

		'click .move-up-button': 'moveUp',
		'click .move-down-button': 'moveDown',
		'click .copy-button': 'copyObject',
		'click .image-select-button': 'selectImage',
		'click .tab-image-select-button': 'selectTabBarImage',
		'click .tab-placeholder-image-select-button': 'selectTabBarPlaceholderImage',
		'click .image-remove-button': 'removeImage',
		'click .tab-image-remove-button': 'removeTabImage',
		'click .tab-placeholder-image-remove-button': 'removeTabPlaceholderImage',
		'click .add-image-button': 'addImage',
		'click .add-sponsor-button': 'addSponsorView',
		'click .add-product-button': 'addProductView',
		'click .save-button': 'saveClick',
		'click .delete-button': 'deleteClick',
		'click .another-button': 'anotherClick',
		'click .choose-animation-button': 'selectAnimation',
		'click .add-bullet-button': 'addBulletItem',

		'change .animation-duration': 'animationDurationChange',
		'change .restrictions input': 'restrictionChange',
		'change .accessibility-label': 'accessibilityLabelChange',

		'change .video-attr-loopable': 'videoLoopChange',
		'change .animation-looped': 'animationLoopChange',
		'change .page-style': 'pageStyleChange',
		'change .tab-description': 'tabDescriptionChange',

		'click .make-template-button': 'makeTemplate',

		'click .inspector-mode button': 'inspectorModeButtonClick',
		'click .swap .picker-item': 'typeSwapItemClick'
	},

	whitelist: [
		'id',
		'tag'
	],

	initialize: function(options) {
		this.views = {}
		this.app = options.app

		var className       = this.model.get('class'),
			normalisedClass = APICompat.normaliseClassName(className)

		// Set the model as dirty when things change.
		this.listenTo(this.model, 'change', function() {
			this.model.needsSaving = true
		}, this)

		// Compare given model against expected class structure
		var classStructure = App.getClassStructure(className, this.model.get('pageId'))

		this.updateClassStructure(this.model.attributes, classStructure, this.model)

		// Update preview on image change (not handled by input change event)
		if (this.model.has('image') || this.model.has('icon')) {
			this.listenTo(this.model, 'change:image', this.updateImagePreview, this)
			this.listenTo(this.model, 'change:video', this.updateVideoPreview, this)
		}

		// TabPageDescriptors have their image property nested - monitor
		// changes on that
		if (this.model.has('tabBarItem')) {
			this.listenTo(this.model, 'change:tab', this.updateTabPreview, this)
		}

		// Initialise a link selector
		var link = this.model.get('link') ||
				this.model.get('button..link')

		if (normalisedClass === 'CertificateListItem') {
			this.views.certificateLinkSelector = new LinkSelector({
				link: this.model.get('certificateButton..link'),
				titleDisabled: true,
				appId: this.app.id
			})

			this.views.courseLinkSelector = new LinkSelector({
				link: this.model.get('courseButton..link'),
				titleDisabled: true,
				appId: this.app.id
			})

			this.listenTo(this.views.certificateLinkSelector, 'change', this.linkChange, this)
			this.listenTo(this.views.courseLinkSelector, 'change', this.linkChange, this)
		}

		if (link && this.model.get('class') !== "VideoListItemView") {
			// Link titles aren't needed on ListItem-level Link objects, except
			// LogoListItem
			var titleDisabled = true

			if (normalisedClass === 'LogoListItem') {
				titleDisabled = false
			}

			this.views.linkSelector = new LinkSelector({
				link: link,
				titleDisabled: titleDisabled,
				appId: this.app.id
			})

			this.listenTo(this.views.linkSelector, 'change', this.linkChange, this)
		}

		// If QuizProgressListItem, create a quiz selector
		if (this.model.get('quizzes') !== undefined) {
			this.views.quizSelector = new QuizSelector({
				collection: this.model.get('quizzes'),
				parent: this.model
			})
		}

		// If editing a GridPage, show view for editing GridView properties
		if (normalisedClass === 'GridPage') {
			this.views.gridViewInspector = new GridViewInspector({model: this.model.get('grid')})

			// Model needs saving when grid properties changed
			this.listenTo(this.views.gridViewInspector, 'change', function() {
				this.model.needsSaving = true
			}, this)

			// Parent model (this) needs saving once grid type changed
			this.listenTo(this.views.gridViewInspector, 'parentSave', function() {
				this.model.save()
				this.model.once('sync', App.stopLoad)
			}, this)
		}

		// If editing a QuizGrid, populate badge selector
		if (this.model.get('badgeId') !== undefined) {
			this.badgeList = new StormQL(null, {app: this.app})
			this.badgeList.once('sync', this.populateBadgeSelector, this)
			this.badgeList.fetch({data: {class: 'Badge'}})
		}

		// set default threshold value
		if (this.model.has('winThreshold') && this.model.get('winThreshold') === 0) {
			this.model.set('winThreshold', 80)
		}

		// If editing a VideoListItem, show video selector. Support legacy
		// class names as well.
		if (normalisedClass === 'VideoListItem') {
			if (this.model.has('videos')) {
				this.views.multiVideoSelector = new MultiVideoSelector({
					model: this.model.get('videos'),
					pageId: this.model.get('pageId')
				})
			} else {
				this.views.multiVideoSelector = new VideoInspectorView({
					model: this.model
				})
			}
		}

		if (this.model.has('audio')) {
			this.views.audioSelector = new MultiVideoSelector({
				model: this.model.get('audio'),
				pageId: this.model.get('pageId'),
				isAudio: true
			})
		}

		// Catch copy keyboard shortcuts
		$(document).keydown(function(e) {
			var selection = window.getSelection(),
				allowCopy = e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && selection.type !== 'Range'

			if ((e.ctrlKey || e.metaKey) && e.which === 67 && allowCopy) {
				e.preventDefault()
				this.copyObject()
				return false
			}
		}.bind(this))
	},

	getRenderData: function() {
		// Return model data with language key
		var data = this.model.toJSON()

		data.language = Storm.view.language
		data.pageTags = pageTags.get(App.system.id)
		data.classes = App.classes.toJSON()
		data.systemId = App.system.id
		data.appId = this.app.id
		return data
	},

	afterRender: function() {
		// Show the property edit view by default.
		this.setInspectorMode('properties')

		var className           = this.model.get('class'),
			normalisedClassName = APICompat.normaliseClassName(className)

		// Only show 'Add bullet item' button on List children, for Hazards
		// apps.
		var isHazards = this.app.isHazardsApp(),
			isList    = this.model.collection && this.model.collection.parent && this.model.collection.parent.get('class') === 'List'

		if (!isHazards || !isList) {
			this.$('.add-bullet-row').remove()
		}

		// For animation items, set delay value
		if (normalisedClassName === 'AnimationListItem') {
			var animation = this.model.get('animation'),
				frames

			if (animation) {
				frames = animation.get('frames')
			} else {
				// Support legacy image structure.
				frames = this.model.get('images')
			}

			if (frames) {
				var delay = 1
				var image = frames.at(0)

				if (image) {
					delay = image.get('delay') / 1000
				}

				this.$('.animation-duration').val(delay)
			}
		}

		// Populate link lists
		var pageId = this.model.get('pageId')

		var embeddedLinks = this.model.get('embeddedLinks')

		if (embeddedLinks) {
			this.views.embeddedLinks = new LinkList({
				collection: embeddedLinks,
				pageId: pageId
			})
			this.$('.embedded-link-list').html(this.views.embeddedLinks.render().el)
		}

		var winRelatedLinks = this.model.get('winRelatedLinks')

		if (winRelatedLinks) {
			this.views.winRelatedLinks = new LinkList({
				collection: winRelatedLinks,
				pageId: pageId
			})
			this.$('.win-link-list').html(this.views.winRelatedLinks.render().el)
		}

		var loseRelatedLinks = this.model.get('loseRelatedLinks')

		if (loseRelatedLinks) {
			this.views.loseRelatedLinks = new LinkList({
				collection: loseRelatedLinks,
				pageId: pageId
			})
			this.$('.lose-link-list').html(this.views.loseRelatedLinks.render().el)
		}

		// Populate embedded media list
		var embeddedMedia = this.model.get('embeddedMedia')

		if (embeddedMedia) {
			this.views.embeddedMedia = new EmbeddedMediaSelector({
				collection: embeddedMedia,
				pageId: pageId
			})
			this.$('.embedded-media-selector').html(this.views.embeddedMedia.render().el)
		}

		// Populate image list for spotlights
		if (className === 'SpotlightListItem') {
			this.renderImageListItems()

			// Update on change
			this.listenTo(this.model.get('spotlights'), 'add', this.addImageListItem)
			this.listenTo(this.model.get('spotlights'), 'remove', this.removeImageListItem)
			this.listenTo(this.model.get('spotlights'), 'reset', this.renderImageListItems)
		}

		if (className === 'SpotlightImageListItemView') {
			this.renderCompatImageListItems()

			// Update on change
			this.listenTo(this.model.get('images'), 'add', this.addImageListItem)
			this.listenTo(this.model.get('images'), 'remove', this.removeImageListItem)
			this.listenTo(this.model.get('images'), 'reset', this.renderImageListItems)
		}

		if (className === 'ProductListItemView') {
			this.renderProductListItems()

			// Update on change
			this.listenTo(this.model.get('products'), 'add', this.renderProductListItems)
			this.listenTo(this.model.get('products'), 'remove', this.removeProductListItem)
			this.listenTo(this.model.get('products'), 'reset', this.renderProductListItems)
		}

		// Populate app select for AppCollectionItems
		if (normalisedClassName === 'AppCollectionItem') {
			// App.appList.each(function(app) {
			// 	var ident = App.system.apiCode + '-' + app.get('societyId') + '-' + app.id
			// 	this.$('.app-identifier-select').append('<option value="' + ident + '" data-id="' + app.id + '">' + App.l(app.get('name')) + '</option>')
			// }, this)

			var optgroups = '',
				l         = utils.getBrowserLocaleText

			var societies = App.societiesList.sortBy(function(society) {
				return society.get('name')
			})

			societies.forEach(function(society) {
				var apps     = App.appList.where({societyId: society.id})

				var $optgroup = $('<optgroup>')
					.attr('label', society.get('name'))

				apps.forEach(function(app) {
					// Don't show template-type apps in the dropdown.
					if (app.isTemplateApp()) {
						return
					}
					var ident = App.system.apiCode + '-' + app.get('societyId') + '-' + app.id

					var $option = $('<option>')
						.val(ident)
						.text(l(app.get('name')))

					$optgroup.append($option)
				})

				if (apps.length) {
					optgroups += $optgroup.prop('outerHTML')
				}
			})

			this.$('.app-identifier-select').append(optgroups)

			// Set initial value
			this.$('.app-identifier-select').val(this.model.get('identifier'))
		}

		// Populate quiz select for QuizCollectionItems
		if (normalisedClassName === 'QuizCollectionItem') {
			var quizzes = this.app.pageList.where({class: 'QuizPage'})

			quizzes.forEach(function(quiz) {
				this.$('.quiz-select').append('<option value="cache://pages/' + quiz.id + '.json">' + App.l(quiz.get('title')) + '</option>')
			}, this)

			// Set initial value
			this.$('.quiz-select').val(this.model.get('quiz..destination'))
		}

		// Render Sponsorship selector
		if (className === 'SponsorshipListItem' || className === 'SponsorshipLogoListItem' || className === 'SponsorshipCollectionCell') {
			var sponsorSelector = new SponsorshipSelector({model: this.model, app: this.app})
			this.$('.sponsorship-selector').append(sponsorSelector.render().el)
		}

		// Render link selectors
		if (this.views.linkSelector) {
			this.$('.link-selector').html(this.views.linkSelector.render().el)
		}

		if (this.views.certificateLinkSelector) {
			this.$('.certificate-link-selector').html(this.views.certificateLinkSelector.render().el)
		}

		if (this.views.courseLinkSelector) {
			this.$('.course-link-selector').html(this.views.courseLinkSelector.render().el)
		}

		// Render quiz selector
		if (this.views.quizSelector) {
			this.$('.quiz-selector').html(this.views.quizSelector.render().el)
		}

		// Render GridView inspector
		if (this.views.gridViewInspector) {
			this.$('.grid-view-inspector').html(this.views.gridViewInspector.render().el)
		}

		// Render video selector
		if (this.views.videoInspector) {
			this.$('.video-inspector').html(this.views.videoInspector.render().el)

			// Populate video attributes checkboxes, if on BRC
			if (App.system.id === 10) {
				var attrs = this.model.get('attributes')

				if (attrs.indexOf('loopable') > -1) {
					this.$('.video-attr-loopable').prop('checked', true)
				}
			}
		}

		if (App.system.id !== 10) {
			this.$('.video-attr-loopable').parent().hide()
		}

		// Render multi-video selector
		if (this.views.multiVideoSelector) {
			this.$('.multi-video-selector').html(this.views.multiVideoSelector.render().el)
			this.$('.link-title').hide()
		}

		// Render audio selector
		if (this.views.audioSelector) {
			this.$('.audio-selector').html(this.views.audioSelector.render().el)
			this.$('.link-title').hide()
		}

		// Set tab destinations
		if (normalisedClassName === 'TabPageDescriptor') {
			var pages = this.app.pageList

			// Get array of all unique tag names
			var tags = _.filter(pages.pluck('tag'), function(elem, pos, list) {
				return list.indexOf(elem) === pos
			})

			_.each(tags, function(tag) {
				var taggedPages = pages.where({tag: tag})
				var options = ''

				_.each(taggedPages, function(page) {
					if (page.get('class') === 'NativePage') {
						options += '<option value="app://native/pages/' + page.get('name') + '">' + App.l(page.get('title')) + '</option>'
					} else {
						options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
					}
				})

				this.$('.tab-destination').append('<optgroup label="' + tag + '">' + options + '</optgroup>')
			}, this)

			// Set current value
			this.$('.tab-destination').val(this.model.get('src'))
		}

		// Set current tab bar item link value
		if (this.model.has('tabBarItem')) {
			this.$('.link-destination-internal-selector').val(this.model.get('src'))
		}

		// Set current restriction values
		var restrictions = this.model.get ? this.model.get('restrictions') : this.model.restrictions

		if (restrictions !== undefined) {
			// Hide reorder buttons if move restricted
			if (restrictions.indexOf('move') > -1) {
				this.$('.move-up-button, .move-down-button').hide()
			}

			// Disable inputs if edit restricted
			if (restrictions.indexOf('edit') > -1) {
				this.$('input, select, button').attr('disabled', true)
				this.$('.restrictions input, .controls button').attr('disabled', false)
			}

			// Only show restriction settings for developer-level accounts
			if (App.developerMode) {
				this.$('.restrictions').show()
			}

			_.each(restrictions, function(restriction) {
				this.$('.restrictions input[value=' + restriction + ']').prop('checked', true)
			}, this)
		}

		// Hide delete button if object or child object locked
		if (!this.canDelete(this.model)) {
			this.$('.delete-button').remove()
		}

		// Hide 'add another' button if add restricted
		var parent = this.model.collection ? this.model.collection.parent : null
		var parentRestrictions = parent ? parent.get('restrictions') : null

		if (parentRestrictions) {
			if (parentRestrictions.indexOf('add') > -1) {
				this.$('.another-button').hide()
			}
		}

		// Switch text direction to RTL for Arabic/Hebrew/Dhivehi
		if (Storm.view.language === 'ar' || Storm.view.language === 'he' || Storm.view.language === 'dv') {
			this.$el.addClass('rtl')
		}

		// Set current page tag
		if (this.model.has('tag')) {
			this.$('.page-tag-picker').val(this.model.get('tag'))
		}

		// Hide developer-only features
		if (!App.developerMode) {
			this.$('.developer-mode').hide()
		}

		// Set current page style value.
		var attributes = this.model.get('attributes'),
			$pageStyle = this.$('.page-style')

		if (attributes) {
			attributes.forEach(function(attribute) {
				if (attribute.indexOf('STYLE_') > -1) {
					$pageStyle.val(attribute)
				}
			})
		}

		// Disable page style changing off GDPC.
		if (App.system.id !== 3) {
			this.$('.page-style-inspector').hide()
		}

		// Remove sponsor button if not ARC
		if (App.system.id !== 9) {
			this.$('.add-sponsor-button').remove()
		}

		this.populateTypeSwap()
	},

	// Populate the type swap view with list item previews.
	populateTypeSwap: function() {
		// Fetch valid classes for this type.
		var parent = (this.model.collection && this.model.collection.parent) ? this.model.collection.parent : null

		if (!parent) {
			return
		}

		var classSpec = App.classes.get(parent.get('class'))

		if (classSpec) {
			var childType = classSpec.children || ''

			childType = childType.substring(1, childType.length - 1)

			if (!childType) {
				return
			}

			App.getSubclasses(childType).then(function(subclasses) {
				this.views.itemSwap = new ListItemPreviewView({
					model: this.model,
					subclasses: subclasses,
					draggable: false
				})
				this.$('.swap').append(this.views.itemSwap.render().el)
			}.bind(this))
		}
	},

	addImageListItem: function(image) {
		if (image.get('class') === 'SponsorshipSpotlightImage') {
			var sponsorSelector = new SponsorshipSelector({model: image, noSave: true, app: this.app})
			this.$('.image-list').append(sponsorSelector.render().el)
		} else {
			var imageListItem = new ImageListItem({model: image})
			this.$('.image-list').append(imageListItem.render().el)
		}
	},

	removeImageListItem: function() {
		// Item removed from collection - need to save to update ordering
		this.model.save()
		this.model.needsSaving = false
	},

	renderImageListItems: function() {
		var field = (this.model.get('class') === 'SpotlightListItem') ? 'spotlights' : 'images'
		this.$('.sponsorship-selector').empty()
		this.$('.image-list').empty()
		this.model.get(field).each(this.addImageListItem, this)
	},

	/**
	 * Outputs the image list preview for a legacy SpotlightImageListItemView
	 * object.
	 * @deprecated
	 */
	renderCompatImageListItems: function() {
		this.$('.image-list').empty()
		this.model.get('images').each(this.addImageListItem, this)
	},

	updateImagePreview: function() {
		var model = this.model

		// Update each image preview
		this.$('img').each(function() {
			var property = $(this).data('property') || 'image'
			var img = model.get(property)

			if (!img) {
				return
			}

			// .toJSON() required on new format, not on legacy.
			var data = img.toJSON ? img.toJSON() : img

			// Update preview after media library use.
			$(this).attr('src', utils.getImagePreviewUrl(data))
		})

		this.model.needsSaving = true
	},

	removeProductListItem: function() {
		// Item removed from collection - need to save to update ordering
		this.model.save()
		this.model.needsSaving = false
	},

	renderProductListItems: function() {
		if (!this.productList) {
			this.productList = new StormQL(null, {app: this.app})
			this.productList.once('sync', this.renderProductView, this)
			this.productList.fetch({
				data: {
					class: 'Product'
				}
			})
		} else {
			this.renderProductView()
		}
	},

	renderProductView: function() {
		this.$('.product-selector').empty()

		var productSelector = new ProductSelector({
			model: this.model,
			app: this.app,
			productList: this.productList
		})

		this.$('.product-selector').append(productSelector.render().el)
	},

	linkChange: function() {
		this.model.needsSaving = true
		this.model.needsStructureUpdate = true

		// Auto update preview when link title changes for LogoListItem
		// objects
		var listItemClass = this.model.get('class')

		if (listItemClass === 'LogoListItem') {
			this.model.trigger('change', this.model)
		}
	},

	updateTabPreview: function() {
		var tabBarItem = this.model.get('tabBarItem'),
			image,
			placeholderImage

		// Handle legacy image format
		if (tabBarItem.get('image').src !== undefined) {
			image = tabBarItem.get('image').src
		} else {
			image = tabBarItem.get('image').toJSON()
		}

		if (tabBarItem.get('placeholderImage').src !== undefined) {
			placeholderImage = tabBarItem.get('placeholderImage').src
		} else {
			placeholderImage = tabBarItem.get('placeholderImage').toJSON()
		}

		var imageURL       = utils.getImagePreviewUrl(image),
			placeholderURL = utils.getImagePreviewUrl(placeholderImage)

		this.$('.tab-image-preview').attr('src', imageURL)
		this.$('.tab-placeholder-preview').attr('src', placeholderURL)
		this.model.needsSaving = true
	},

	updateVideoPreview: function() {
		// When video selector used
		this.$('video').attr('src', utils.getRemoteUrl(this.model.get('link..destination')))

		// Set video duration (once metadata loaded)
		this.$('video').on('loadedmetadata', function() {
			var duration = this.$('video').prop('duration') * 1000

			this.model.set('duration', duration)
		}.bind(this))

		// Reset duration back to default
		this.$('.animation-duration').val(1)

		this.model.needsSaving = true
	},

	inputChange: function(e) {
		// Update model and preview on any input change
		var model = this.model
		var target = e.currentTarget

		// The object with properties being modified (e.g. an InternalLink)
		var property = $(target).data('property')

		// If there's no property set, it isn't directly affecting a model here
		if (!property) {
			return
		}

		var value = this.model.get(property)

		if (property === 'content') {
			value = model
			property = null
		}

		// Timeout ensures target.value has changed to its new value
		setTimeout(function() {
			var newValue

			if (target.type === 'checkbox') {
				newValue = target.checked
			} else {
				newValue = target.value
			}

			// set default threshold value
			if (property === 'winThreshold' && (newValue === 0 || newValue === "")) {
				newValue = 80
			}

			var className

			if (value) {
				className = value instanceof Backbone.Model ? value.get('class') : value.class
			}

			switch (className) {
				case 'Text':
					var key = property ? property + '..' : ''

					model.set(key + 'content..' + Storm.view.language, newValue)
					break

				default:
					model.set(property, newValue)

					if (property === 'src') {
						this.model.needsStructureUpdate = true
					}
			}

			this.model.needsSaving = true
			// Make sure any contenteditables are still editable
			$('.inline-editable').attr('contenteditable', true)
		}.bind(this))
	},

	moveUp: function() {
		this.model.moveUp()
	},

	moveDown: function() {
		this.model.moveDown()
	},

	showMediaSelect: function(model, mediaType) {
		this.views.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: model,
			mediaType: mediaType
		})

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	selectImage: function(e) {
		var property = $(e.currentTarget).data('property')
		var model = property ? this.model.get(property) : this.model.get('image')

		var propertyComponents = property.split('..')
		var mediaType = MediaLibrary.types.IMAGE

		if (propertyComponents[propertyComponents.length - 1] === 'icon') {
			mediaType = MediaLibrary.types.ICON
		}

		// Show media library
		this.showMediaSelect(model, mediaType)

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change change:image', this.model)
		}, this)
	},

	selectAnimation: function() {
		var model

		// Support legacy class structure.
		if (this.model.has('animation')) {
			model = this.model.get('animation').get('frames')
		} else {
			model = this.model.get('images')
		}

		this.showMediaSelect(model, MediaLibrary.types.ANIMATION)

		this.views.mediaLibrary.on('change', function() {
			this.model.needsSaving = true
			this.model.trigger('change change:animation', this.model)
		}, this)
	},

	selectTabBarImage: function() {
		var model = this.model.get('tabBarItem').get('image')

		this.showMediaSelect(model, MediaLibrary.types.IMAGE)

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change change:tab', this.model)
		}, this)
	},

	selectTabBarPlaceholderImage: function() {
		var model = this.model.get('tabBarItem').get('placeholderImage')

		this.showMediaSelect(model, MediaLibrary.types.IMAGE)

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change change:tab', this.model)
		}, this)
	},

	clearImageArray: function(collection) {
		var models = collection.models.slice(0)

		models.forEach(function(model) {
			model.destroy()
		})

		collection.models = []
	},

	/**
	 * Clears all URLs from a legacy ImageDescriptor model.
	 * @param {StormObject|Object} model The model to clear (a legacy Image
	 *     object).
	 * @deprecated
	 */
	clearImageObjectCompat: function(model) {
		var densities = ['0.75', '1', '1.5', '2'],
			src       = model.get ? model.get('src') : model.src

		densities.forEach(function(density) {
			src['x' + density] = ''
		})
	},

	removeImage: function(e) {
		var property = $(e.currentTarget).data('property')
		var model = this.model.get(property)

		// Handle legacy image format.
		if (model instanceof Backbone.Collection) {
			this.clearImageArray(model)
		} else {
			this.clearImageObjectCompat(model)
		}

		this.model.trigger('change change:image', this.model)
	},

	removeTabImage: function() {
		var model = this.model.get('tabBarItem').get('image')

		// Handle legacy image format.
		if (model instanceof Backbone.Collection) {
			this.clearImageArray(model)
		} else {
			this.clearImageObjectCompat(model)
		}

		this.model.trigger('change change:tab', this.model)
	},

	removeTabPlaceholderImage: function() {
		var model = this.model.get('tabBarItem').get('placeholderImage')

		// Handle legacy image format.
		if (model instanceof Backbone.Collection) {
			this.clearImageArray(model)
		} else {
			this.clearImageObjectCompat(model)
		}

		this.model.trigger('change change:tab', this.model)
	},

	/**
	 * Handles click events to the 'Add image' button for SpotlightListItem and
	 * legacy SpotlightImageListItemView objects. Creates a new Spotlight
	 * (/SpotlightImage) child object and displays the media library for image
	 * selection.
	 *
	 * @param  {event} e           event
	 * @param  {Boolean} sponsorship are we adding a sponsorship
	 */
	addImage: function(e, sponsorship) {
		if (!sponsorship) {
			sponsorship = false
		}
		// Create new image object
		var parentClassName = APICompat.unNormaliseClassName(this.model.get('class')),
			className

		switch (parentClassName) {
			case 'SpotlightListItem':
				className = 'Spotlight'
				break

			case 'SpotlightImageListItemView':
				className = sponsorship ? 'SponsorshipSpotlightImage' : 'SpotlightImage'
				break

			default:
				throw new Error('Invalid object class', this.model.get('class'))
		}

		var image = StormObject.fromClassName(className, this.model.get('pageId'))

		// Set default delay.
		image.set('delay', 5000)

		var images = this.model.get('spotlights') || this.model.get('images')

		images.add(image)

		// Support legacy image format (entire model, not 'images' key).
		var model = image.get('image') || image
		if (!sponsorship) {
			// Show media library
			this.views.mediaLibrary = new MediaSelectorView({
				app: Storm.view.app,
				model: model,
				mediaType: MediaLibrary.types.IMAGE
			})

			$('body').append(this.views.mediaLibrary.el)
			this.views.mediaLibrary.render().show()
		}
	},

	addSponsorView: function(e) {
		this.addImage(e, true)
	},

	addProductView: function() {
		var productSelector = StormObject.fromClassName('ProductSelector', this.model.get('pageId'))
		this.model.get('products').add(productSelector)
		this.model.trigger('change:products')
		this.model.needsSaving = true
	},

	saveClick: function() {
		Storm.view.views.canvas.setInspector(null)
	},

	deleteClick: function() {
		var isPage = App.subclasses.Page.indexOf(this.model.get('class')) > -1

		// Don't allow the start page to be deleted
		var rootId = this.app.get('objectId')

		if (this.model.id === rootId) {
			swal($.t('error.oops'), $.t('editor.inspector.rootPageDelete'), 'error')
			return
		}

		var swalOpts = {
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: $.t('mediaLibrary.delete'),
			confirmButtonColor: '#DD6B55'
		}

		if (isPage) {
			swalOpts.closeOnConfirm = false
		}

		swal(swalOpts, function() {
			// Show second confirmation for page deletions
			if (isPage) {
				swalOpts.title = $.t('editor.inspector.confirmPageDelete')
				swalOpts.text = $.t('editor.inspector.confirmPageDeleteWarning')
				swalOpts.closeOnConfirm = true

				swal(swalOpts, doDelete)
			} else {
				doDelete()
			}
		})

		var self = this

		function doDelete() {
			App.startLoad()

			var className = self.model.get('class')
			if (className === 'ProductListItemView') {
				self.model.trigger('stopTimeout')
			}

			// User confirmed deletion - do it.
			var toDestroy       = self.model,
				destroyedParent = null

			// If this object is the List's only child, destroy that instead
			if (self.model.collection && self.model.collection.length === 1 && self.model.collection.parent && APICompat.normaliseClassName(self.model.collection.parent.get('class')) === 'List') {
				toDestroy = self.model.collection.parent
			}

			// Reference to collection will be removed after model destruction
			// Save parent reference now
			if (!isPage && toDestroy.collection) {
				destroyedParent = toDestroy.collection.parent
			}

			toDestroy.destroy({wait: true})
			toDestroy.once('sync', function() {
				// Save parent back to server after object deletion to remove
				// references
				if (destroyedParent) {
					destroyedParent.save().then(App.stopLoad)
				}
			})

			// Close the inspector, but don't save any model changes.
			self.model.needsSaving = false
			self.model.reordered = false
			self.destroy()
		}
	},

	anotherClick: function() {
		var className = this.model.get('class')

		// Use regular add page form for page classes
		if (App.subclasses.Page.indexOf(className) > -1) {
			Storm.view.addPageButtonClick()
			$('.new-page-type').val(className)
			$('.new-page-tag').val(this.model.get('tag'))
			return
		}

		// Create now object of the same type.
		var pageId = this.model.get('pageId')
		var newView = StormObject.fromClassName(className, pageId)
		newView.editing = true

		this.model.collection.add(newView)
		this.model.collection.parent.save()

		// Show inspector for new element.
		Storm.view.views.canvas.setInspector(newView)
	},

	beforeDestroy: function() {
		$('.selected-area').hide()

		var pageList = this.app.pageList,
			parent   = pageList.get(this.model.get('pageId'))

		// Update app structure in page list
		if (this.model.needsStructureUpdate || this.model.has('link')) {
			// Clear existing page structure.
			if (parent.structure) {
				parent.structure.get('children').reset()
			} else {
				parent.structure = new AppStructure({
					objectId: parent.id,
					children: new Backbone.Collection()
				})
			}

			// Find all links on this page.
			var json      = JSON.stringify(parent),
				linkRegex = /cache:\/\/pages\/(\d+)\.json|app:\/\/native\/pages\/(\w+)/g,
				match

			while ((match = linkRegex.exec(json)) !== null) {
				var child

				if (match[1] !== undefined) {
					// Normal page link.
					var pageId = Number(match[1])

					child = pageList.get(pageId)
				} else {
					// Native page link.
					var pageName = match[2]

					child = pageList.findWhere({name: pageName})
				}

				// Page may not exist, and referencing structure on undefined
				// will error.
				child = child || {}

				var newStructure = {
					id: child.id,
					children: new Backbone.Collection()
				}

				parent.structure.get('children').add(child.structure || newStructure)
			}

			// Show/hide folder collapse toggle as appropriate
			var show = parent.structure.get('children')

			this.$('> .content .collapse-link').toggle(show)

			// Update app structure
			parent.trigger('change:structure', parent)
		}

		// Check parent object exists first. Option faux-model doesn't have a
		// page ID.
		if (parent && parent.get('class') === 'TabbedPageCollection') {
			// Update TabPageDescriptor page type fields.
			var pages = parent.get('pages')
			if (pages) {
				pages.forEach(function(pageDescriptor) {
					var link = pageDescriptor.get('src'),
						type,
						page

					if (link === '') {
						type = ''
					} else if (link.indexOf('app://native/') > -1) {
						type = 'NativePage'
					} else {
						var pageId = App.getIdFromCacheUrl(link)

						page = pageList.get(pageId)

						if (!page) {
							type = ''
						} else {
							type = page.get('class')
						}
					}
					pageDescriptor.set('type', type)
				})
			}
		}

		// Unbind keydown handler for CMD + C
		$(document).unbind('keydown')

		// Traverse up to find parent page.
		var page = this.model

		while (page && page.collection && page.id !== page.get('pageId')) {
			page = page.collection.parent
		}

		// Don't try to save anything if we don't have a lock token.
		if (this.model.get('pageId') && page && page.lock && page.lock.isLocked()) {
			// Save if model changed
			if (this.model.reordered) {
				// Need to save parent model to update ordering
				this.model.collection.parent.save()

				this.model.reordered = false
				this.model.needsSaving = false
			} else if (this.model.needsSaving) {
				// Save model
				this.model.save()
				this.model.needsSaving = false
			}
		}
	},

	accessibilityLabelChange: function(e) {
		var self = this
		setTimeout(function() {
			var val = $(e.currentTarget).val()
			var propertyType = $(e.currentTarget).data('accessibility')
			if (propertyType === 'animation') {
				self.model.set('accessibilityLabel..content..' + Storm.view.language, val)
			} else if (propertyType === 'image') {
				self.model.set('image..accessibilityLabel..content..' + Storm.view.language, val)
			}
			self.trigger('change')
		})

		e.stopPropagation()
	},

	animationDurationChange: function() {
		var delay = parseFloat(this.$('.animation-duration').val())

		if (delay < 0.1) {
			delay = 0.1
		}

		var animation = this.model.get('animation'),
			frames

		if (animation) {
			frames = animation.get('frames')
		} else {
			// Support legacy class structures.
			frames = this.model.get('images')
		}

		frames.forEach(function(frame) {
			frame.set('delay', delay * 1000)
		})

		this.model.needsSaving = true
	},

	// Add a UnorderedListItem directly below the current list item.
	addBulletItem: function() {
		var pageId = this.model.get('pageId')
		var newView = StormObject.fromClassName('UnorderedListItem', pageId)

		// Show inspector for new element.
		Storm.view.views.canvas.setInspector(newView)

		// Add directly below the current list item.
		var index = this.model.collection.indexOf(this.model) + 1

		this.model.collection.add(newView, {at: index})
		this.model.collection.parent.save()
	},

	canDelete: function(model) {
		// Check authenticating user has content delete permissions
		var hasPermission = App.acl.getPermission('Content') === 'Delete'

		// NativePage objects can never be deleted
		var isNativePage = model.get('class') === 'NativePage'

		// Check restriction array for 'delete' key
		var isRestricted = (model.get('restrictions') || []).indexOf('delete') > -1

		if (!hasPermission || isNativePage || isRestricted) {
			return false
		}

		// Check if the model has any locked objects as children
		var children = model.get('children') || []

		return children.every(function(child) {
			return this.canDelete(child)
		}, this)
	},

	restrictionChange: function(e) {
		var value = e.currentTarget.value
		var restrictions = this.model.get ? this.model.get('restrictions') : this.model.restrictions

		if (e.currentTarget.checked) {
			// Add restriction to restrictions list (unless already present)
			if (restrictions.indexOf(value) === -1) {
				restrictions.push(value)
			}
		} else {
			// Remove restriction from list (unless not present)
			var index = restrictions.indexOf(value)

			if (index > -1) {
				restrictions.splice(index, 1)
			}
		}

		this.model.needsSaving = true
	},

	updateClassStructure: function(model, structure, parent) {
		if (!structure.class) {
			// Object has no class - not part of the structure (e.g. language
			// object)
			return
		}

		// Actual model may contain subclasses of types in the structure
		// Get actual structure again if needed
		if (structure.class !== model.class) {
			structure = App.getClassStructure(model.class, model.pageId)
		}

		_.each(structure, function(value, key) {
			if (!(key in model) || model[key] === null) {
				// Store arrays as collections
				if (value instanceof Array) {
					if (StormObject.isPrimitiveArray(key, value)) {
						model[key] = []
					} else {
						var collection = StormCollection.fromArray(value)

						collection.parent = parent
						model[key] = collection
					}
				} else {
					model[key] = value
				}
			} else if (value instanceof Object && !(value instanceof Array)) {
				// Compare child object properties
				// Remove any empty key/null value pairs
				var keys = Object.keys(model[key])

				if (keys.length === 0 || keys.length === 1 && model[key][''] !== undefined) {
					model[key] = {}
				}

				if (model[key][''] !== undefined) {
					delete model[key]['']
				}

				this.updateClassStructure(model[key], value, parent)
			}

			var whitelist = this.whitelist

			// Remove any invalid keys from model
			_.each(model, function(value, key) {
				if (!(key in structure) && whitelist.indexOf(key) === -1) {
					delete model[key]
				}
			})
		}, this)
	},

	populateBadgeSelector: function() {
		var options = '<option value="">-</option>'

		this.badgeList.each(function(badge) {
			options += '<option value="' + badge.id + '">' + App.l(badge.get('title')) + '</option>'
		}, this)

		this.$('.badge-select').html(options)
		this.$('.badge-select').val(this.model.get('badgeId'))
	},

	copyObject: function() {
		App.clipboard.className = this.model.get('class')
		App.clipboard.payload = this.model.toJSON()

		App.showToast($.t('editor.inspector.copySuccess'))
	},

	videoLoopChange: function() {
		var loopable = this.$('.video-attr-loopable').prop('checked')
		var attrs = this.model.get('attributes')
		var index = attrs.indexOf('loopable')

		if (index === -1 && loopable) {
			attrs.push('loopable')
		} else if (index > -1 && !loopable) {
			attrs.splice(index, 1)
		}

		this.model.needsSaving = true
	},

	animationLoopChange: function() {
		var looped = this.$('.animation-looped').prop('checked')

		this.model.get('animation').set('looped', looped)
		this.model.needsSaving = true
	},

	pageStyleChange: function() {
		var newStyle = this.$('.page-style').val(),
			attrs    = this.model.get('attributes')

		// Remove all existing STYLE_* attributes
		for (var i = 0; i < attrs.length; i++) {
			if (attrs[i].indexOf('STYLE_') > -1) {
				attrs.splice(i, 1)
				i--
			}
		}

		if (newStyle !== '') {
			// Set new style
			attrs.push(newStyle)
		}

		this.model.trigger('change:style', this.model)
		this.model.needsSaving = true
	},

	tabDescriptionChange: function(e) {
		var text = $(e.currentTarget).val()

		this.model.get('tabBarItem').setTextContent(Storm.view.language, 'description', text)
	},

	// Toggle between object properties/type change.
	inspectorModeButtonClick: function(e) {
		var $button = $(e.currentTarget)

		this.$('.inspector-mode .active').removeClass('active')
		$button.addClass('active')
		this.setInspectorMode($button.val())
	},

	// Switch between 'properties' and 'swap' mode.
	setInspectorMode: function(mode) {
		this.$('.properties, .swap').addClass('hidden')
		this.$('.' + mode).removeClass('hidden')
	},

	// Handle a new type being selected and swap.
	typeSwapItemClick: function(e) {
		var type = $(e.currentTarget).data('class')

		this.swapType(type)
	},

	// Replace this item with a new object of a different type.
	swapType: function(newType) {
		App.startLoad()
		var model = this.model

		// Get lock token for this model.
		var options = model.setLockHeader({})

		// Fire DELETE request on old model.
		var destroy = Backbone.sync('delete', new Backbone.Model(), {
			url: model.url(),
			headers: options.headers
		})

		// Save parent collection to update ordering.
		var collection = model.collection,
			parent     = collection.parent,
			index      = collection.indexOf(model)

		collection.remove(model)

		var update = Backbone.sync('update', new Backbone.Model(), {
			url: parent.url(),
			data: JSON.stringify(parent),
			headers: options.headers
		})

		var newModel = StormObject.fromClassName(newType, model.get('pageId'))

		model.set('class', newModel.get('class'), {silent: true})
		model.unset('id', {silent: true})

		// Remove all attributes which don't exist in the new class.
		_.each(model.attributes, function(value, key) {
			if (newModel.has(key)) {
				newModel.unset(key)
			} else {
				delete model.attributes[key]
			}
		})

		// Set all non-matched keys on the existing model.
		model.set(newModel.attributes, {silent: true})

		// Strip all IDs from nested objects.
		this._stripIDs(model.attributes)

		// Reinsert updated model at correct position.
		collection.add(model, {at: index})

		// Save parent collection to save new model.
		$.when(destroy, update).then(function() {
			parent.save()

			// Close inspector.
			Storm.view.views.canvas.setInspector(null)
			App.stopLoad()
		})
	},

	makeTemplate: function() {
		this.views.templateInspector = new CreateTemplateView({json: this.getTemplateJSON()})
		this.$el.append(this.views.templateInspector.render().el)

		this.$('.make-template-button').remove()
	},

	getTemplateJSON: function() {
		var template = Storm.view.views.canvas.model.toJSON()

		this._templateStrip(template)
		return template
	},

	// Recursively strip all non-template keys from an object.
	// Template keys are 'children', 'restrictions', 'class' and 'grid'.
	_templateStrip: function(object) {
		_.each(object, function(value, key) {
			if (key === 'children') {
				value.forEach(this._templateStrip, this)
			} else if (key === 'grid') {
				value.children.forEach(this._templateStrip, this)
			} else if (key !== 'class') {
				// Keep restriction keys, but only when there are values
				var isRestrictions = key === 'restrictions' && value.length > 0

				if (!isRestrictions) {
					delete object[key]
				}
			}
		}, this)
	},

	_stripIDs: ViewPicker.prototype._stripIDs
})

module.exports = Inspector
