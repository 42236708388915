var StormView = require('lib/storm-view'),
	/** @type {StormGlobals} */
	globals   = require('globals')

/**
 * Exports {@link EditorSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends EditorSectionView.prototype */{
	/**
	 * @constructs EditorSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	loadData: function() {
		return Storm.app.pluginList.fetchOnce()
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('nav.items.content.title')
	},

	/** @override */
	getPages: function() {
		var acl = globals.getAcl()

		return [
			{
				name: $.t('editor.title'),
				url: 'pages',
				isVisible: function() {
					return acl.hasAccess('Content')
				},
				views: [
					require('./editor-view')
				]
			},
			{
				name: $.t('mediaLibrary.title'),
				url: 'media',
				isVisible: function() {
					return acl.hasAccess('Content')
				},
				views: [
					require('media-library/media-library-wrapper-view')
				]
			},
			{
				name: $.t('localisations.title'),
				url: 'localizations',
				isVisible: function() {
					return acl.hasAccess('Localisations')
				},
				views: [
					require('app-setup/localisation-view')
				]
			},
			{
				name: $.t('badges.title'),
				url: 'badges',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					var hasBadges     = app.pluginList.hasPluginByCode('badges'),
						hasPermission = acl.hasAccess('Badges')

					return hasBadges && hasPermission
				},
				views: [
					require('badges/badges-view'),
					require('badges/badge-edit-view')
				]
			},
			{
				name: $.t('crossBorders.title'),
				url: 'cross-borders',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					var hasPlugin = app.pluginList.hasPluginByCode('cross_borders'),
						hasPermission = acl.hasAccess('CrossBorder')

					return hasPlugin && hasPermission
				},
				views: [
					require('cross-borders/cross-borders-view'),
					require('cross-borders/cross-borders-edit-view')
				]
			},
			{
				name: 'Tips',
				url: 'tips',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Tips')
				},
				views: [
					require('mind-tips/tips-view'),
					require('mind-tips/tip-edit-view')
				]
			},
			{
				name: $.t('posts.title'),
				url: 'posts',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Posts')
				},
				views: [
					require('jg-posts/stormql-post-list-view')
				]
			},
			{
				name: $.t('achievements.title'),
				url: 'achievements',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Achievements')
				},
				views: [
					require('achievements/achievements-view'),
					require('achievements/achievement-edit-view')
				]
			},
			{
				name: $.t('nav.items.decisionTree.title'),
				url: 'decision-tree',
				isVisible: function() {
					var systemId = globals.getSystem().id

					return systemId === 8
				},
				views: [
					require('decision-tree/decision-tree-view')
				]
			},
			{
				name: $.t('arpro.codeGroup.codeGroup'),
				url: 'code-groups',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Code Groups')
				},
				views: [
					require('arpro/code-groups/code-group-view'),
					require('arpro/code-groups/code-group-edit-view')
				]
			},
			{
				name: $.t('arpro.colours.colours'),
				url: 'product-colours',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Colours')
				},
				views: [
					require('arpro/product-colour/product-colour-view')
				]
			},
			{
				name: $.t('arpro.search.search'),
				url: 'product-search',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Product Search')
				},
				views: [
					require('arpro/product-search/product-search-view'),
					require('arpro/product-search/product-search-edit-view')
				]
			},
			{
				name: $.t('arpro.product.product'),
				url: 'products',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPluginByCode('products')
				},
				views: [
					require('arpro/products/product-view'),
					require('arpro/products/product-edit-view')
				]
			},
			{
				name: $.t('products.products'),
				url: 'products-shop',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPluginByCode('shopProducts')
				},
				views: [
					require('products/products-view'),
					require('products/product-edit-view')
				]
			},
			{
				name: $.t('richStories.richStories'),
				url: 'rich-stories',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPluginByCode('richStories')
				},
				views: [
					require('rich-stories/rich-stories-view'),
					require('rich-stories/rich-stories-edit-view')
				]
			},
			{
				name: $.t('findCourses.findCourses'),
				url: 'find-courses',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPluginByCode('findCourse')
				},
				views: [
					require('find-courses/find-courses-view'),
					require('find-courses/find-courses-edit-view')
				]
			},
			{
				name: $.t('searchTerms.searchTerms'),
				url: 'search-terms',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPluginByCode('searchTerms')
				},
				views: [
					require('search-terms/search-terms-view'),
					require('search-terms/search-terms-edit-view')
				]
			},
			{
				name: $.t('r4b.title'),
				url: 'atlas_admin',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPluginByCode('r4b_levels')
				},
				views: [
					require('atlas/admin-view'),
					require('atlas/module-edit-view')
				]
			},
			{
				name: $.t('facility.title'),
				url: 'facilities/Red%20Cross%20Offices',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Facilities')
				},
				views: [
					require('facilities/facility-view')
				]
			},
			{
				name: $.t('sponsorship.title'),
				url: 'sponsors',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Sponsorship')
				},
				views: [
					require('sponsorship/sponsor-view')
				]
			},
			{
				name: $.t('stories.title'),
				url: 'stories',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Stories')
				},
				views: [
					require('blood-stories/stories-view')
				]
			},
			{
				name: $.t('journey.pageTitle'),
				url: 'journey',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Journey')
				},
				views: [
					require('journies/journey-view')
				]
			},
			{
				name: $.t('frame.pageTitle'),
				url: 'frames',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Frames')
				},
				views: [
					require('frames/frames-view')
				]
			},
			{
				name: $.t('swim.pageTitle'),
				url: 'swim-levels',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return false // Returning false until Swim levels is a thing again
				},
				views: [
					require('swim-levels/level-view')
				]
			},
			{
				name: $.t('rspb.factFile.factFile'),
				url: 'fact-files',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Fact File')
				},
				views: [
					require('rspb/fact-files/fact-file-view'),
					require('rspb/fact-files/fact-file-edit-view')
				]
			},
			{
				name: $.t('rspb.planItem.planItems'),
				url: 'plan-items',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Plan')
				},
				views: [
					require('rspb/plan-items/plan-item-view'),
					require('rspb/plan-items/plan-item-edit-view')
				]
			},
			{
				name: $.t('quizCompletion.title'),
				url: 'quiz-completion',
				isVisible: function() {
					var app = Storm.app

					if (!app) {
						return false
					}

					return app.pluginList.hasPlugin('Quiz Completion')
				},
				views: [
					require('quiz-completion/quiz-completion-view')
				]
			}
		]
	}
})
