<form class="col-xs-12">
	<div class="row">
		<div class="col-sm-6">
			<h3 data-i18n="journey.edit"></h3>
		</div>
		<div class="col-sm-6 actions-col">
			<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
			<button class="button green wide save" type="submit">{{t "common.save"}}</button>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-2">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.image.title"}}</label>
			</div>
			<div class="form-group" style="min-height:100px;">
				<img style="max-width:200px;" class="js-image" src="{{getPreviewUrl image}}">
			</div>
			<div class="form-group">
				<button type="button" class="button choose-image-button" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
			</div>
		</div>

		{{!-- <div class="col-xs-12 col-md-2">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.imageInactive.title"}}</label>
			</div>
			<div class="form-group" style="min-height:100px;">
				<img style="max-width:200px;" class="js-image-inactive" src="{{getPreviewUrl image_inactive}}">
			</div>
			<div class="form-group">
				<button type="button" class="button choose-image-inactive-button" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
			</div>
		</div> --}}
	</div>

	<div class="row">
		<div class="col-xs-6">
			<div class="form-group">
				<label class="control-label">{{t "journey.stage"}}</label>
			</div>
			<div class="form-group">
				<input type="number" class="stage-input form-control">
			</div>
		</div>

		<div class="col-xs-6">
			<div class="form-group">
				<label class="control-label">{{t "journey.title"}}</label>
			</div>
			{{#each locales}}
				<div class="form-group">
					<div class="input-group">
						<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
						<input type="text" class="title-input form-control" data-code="{{code}}" />
					</div>
				</div>
			{{/each}}
		</div>

	</div>

	<div class="row">
		<div class="col-sm-12">
			<div class="row">
				<div class="col-sm-6">
					<div class="form-group">
						<label class="control-label">{{t "journey.link"}}</label>
					</div>
					<div class="link-picker well">

					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group">
						<label class="control-label">{{t "journey.pending"}}</label>
					</div>
					{{#each locales}}
						<div class="form-group">
							<div class="input-group">
								<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
								<input type="text" class="pending-input form-control" data-code="{{code}}" />
							</div>
						</div>
					{{/each}}
					<div class="form-group">
						<label class="control-label">{{t "journey.active"}}</label>
					</div>
					{{#each locales}}
						<div class="form-group">
							<div class="input-group">
								<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
								<input type="text" class="active-input form-control" data-code="{{code}}" />
							</div>
						</div>
					{{/each}}
					<div class="form-group">
						<label class="control-label">{{t "journey.elapsed"}}</label>
					</div>
					{{#each locales}}
						<div class="form-group">
							<div class="input-group">
								<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
								<input type="text" class="elapsed-input form-control" data-code="{{code}}" />
							</div>
						</div>
					{{/each}}
				</div>
			</div>
		</div>
	</div>
</form>
