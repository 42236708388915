var EditorSectionView     = require('editor/editor-section-view')

module.exports = EditorSectionView.extend({
	template: require('./link-list-item-view-template'),
	// TODO need to update this to match badges view, if it changes.
	activeTabIndex: 4,

	initialize: function(options, data) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		data = data || null

		if (!options.app) {
			throw new Error('No app specified')
		}

		/** @private {App} */
		this.app_ = options.app

		this.parentId = options.parentId

		this.pageList = this.app_.pageList

		this.fetchData = options.fetchData || {}

		this.links		= options.links

		var pageListFetch = this.pageList.fetchOnce()

		Promise.all([pageListFetch])
			.then(App.stopLoad)
			.then(this.render.bind(this))
	},

	afterRender: function() {
		this.delegateEvents()
	},

	getRenderData: function() {
		var data = {}
		data.appId = this.app_.id
		data.parentId = this.fetchData.data.parentId
		data.pages = this.getPages()
		data.links = this.getTitleLinks()
		data.locales = this.app_.localeList.toJSON()
		return data
	},

	getPages: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		return pageListDropdownOptions
	},

	getTitleLinks: function() {
		var linkTitles = []
		for (var link in this.links) {
			if (link) {
				linkTitles.push(this.getLinkPreview(this.links[link]))
			}
		}
		return linkTitles
	},

	/**
	 * Constructs a preview for a Link object.
	 * @param {Object} link A Storm Link object.
	 * @returns {string} An HTML string with the preview content.
	 */
	getLinkPreview: function(link) {
		var url = link.destination
		if (!url) {
			return ''
		}

		var pageId = App.getIdFromCacheUrl(url),
			page   = this.pageList.get(pageId)
		if (!page) {
			return ''
		}
		return {pageTitle: '[' + page.get('tag') + '] ' + App.l(page.get('title')), id: link.id, title: link.title}
	},

	updateView: function(links) {
		this.links = links
		this.render()
	}

})
