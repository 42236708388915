<div class="row">
	<div class="col-sm-12">
		<table class="header-float">
			<thead>
				<tr>
					<th style="width:100px">{{t "sponsorship.edit.icon"}}</th>
					<th>{{t "sponsorship.edit.feature"}}</th>
					<th>{{t "sponsorship.edit.text"}}</th>
					<th>{{t "sponsorship.edit.description"}}</th>
					<th>{{t "sponsorship.edit.analytics"}}</th>
					<th>{{t "sponsorship.edit.tags"}}</th>
					<th>{{t "common.tools"}}</th>
				</tr>
			</thead>
			<tbody class="list-items"></tbody>
		</table>
	</div>
</div>
