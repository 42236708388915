<div class="events-box row no-select" data-name="{{model.name}}_{{getBrowserLocaleText model.friendlyName}}">
  <div class="col-xs-4 text-center event-name">
    <p>
      {{#if model.friendlyName}}
        {{getBrowserLocaleText model.friendlyName}}
      {{else}}
        {{model.name}}
      {{/if}}
    </p>
  </div>
  <div class="col-xs-8">
    <div class="event-actions">
      <label>{{#if model.enabled}}{{t "hazards.disasters.enabled"}}{{/if}}</label>
      <button class="button outlined event-open"><i class="icon-cogs"></i></button>
      <!-- On off switch -->
      <!-- <div class="onoffswitch">
          <input type="checkbox" name="onoff-{{selectorId}}" class="onoffswitch-checkbox" id="onoff-{{selectorId}}">
          <label class="onoffswitch-label onoffswitch-green" for="onoff-{{selectorId}}"></label>
      </div> -->
    </div>
    <div class="events-expanded-content">
      <div class="event-feeds storm-scrollbar">
        <ul>
          {{#each model.expandedFeeds}}
            <li>
              ({{code}}) {{name}}
              <div class="circle-checkbox">
                <input type="checkbox" class="feed-check" id="enable-{{../selectorId}}-{{code}}" name="" data-eventid="{{../model.id}}" data-feedid="{{id}}" data-feedcode="{{code}}" {{#if enabled}}checked{{/if}}/>
                <label for="enable-{{../selectorId}}-{{code}}"></label>
              </div>
            </li>
          {{/each}}
        </ul>
      </div>
      <div class="event-meta">
        <hr />
        <div class="form-inline">
          <div class="form-group m-t-5 m-b-5">
            <label>{{t "hazards.disasters.icon"}}</label>
            <button class="button circle update-icon" data-eventid="{{ model.id }}" style="{{#if model.meta}} {{#if model.meta.icon.src.x1}} background-image: url('{{getPreviewUrl model.meta.icon}}'); background-color: #fff; {{else}}text-shadow: none; {{/if}} {{else}} text-shadow: none; {{/if}}"><i class="icon-plus"></i></button>
          </div>
          <div class="form-group m-t-5 m-b-5" style="max-width:50%;">
            <label>{{t "hazards.disasters.page"}}</label>
            <select class="update-page" data-eventid="{{ model.id }}" id="page-{{selectorId}}" style="max-width:100%;">{{{pages}}}</select>
          </div>
          <div class="form-group m-t-5 m-b-5">
            <label class="checkbox-inline">
              <input class="update-default" type="checkbox" {{#if model.meta}} {{#if model.meta.default}} checked {{/if}} {{/if}} data-eventid="{{ model.id }}"> {{t "hazards.disasters.default"}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
