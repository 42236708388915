<div class="container-fluid">
	<div class="row">
		<div class="atlas-header col-xs-12">
			<img id="atlas-bear" width="100px" src="/images/atlas/bear-icon.png"></img>
			<h2>{{t "r4b.title"}} {{t "r4b.admin"}}</h2>
		</div>
	</div>
	<div class="row mb-20 js-categories">
	</div>
	<div class="row mb-20">
		<div class="col-md-12">
			<div class="level-header level-header-dark" style="cursor: inherit">
				<h3 class="mt-0 mb-0">{{t "r4b.level.levels"}}</h3>
				<div class="level-header-actions">
					<button class="add-level button button-link" style="display:none"><i class="icon icon-plus"></i> {{t
						"common.add"}} {{t "r4b.level.title"}}</button>
					<button class="close-edit-levels button button-link ml-10" style="display:none;"><i
							class="icon icon-remove"></i></button>
					<button class="edit-levels button button-link"><i class="icon icon-pencil"></i></button>
				</div>
			</div>
			<div class="levels" style="display:none">
				{{#each levelList}}
				<div class="level mb-10">
					<h4 class="level-name">{{#ifEqual @key 0}}{{t "r4b.assessments.pre"}}{{else}}{{t "r4b.level.title"}}
						{{identifier}}{{/ifEqual}}:</h4>
					<label class="control-label">{{t "r4b.level.colour"}}:</label>
					<span class="colour-visual" data-id={{id}} data-rgba="{{getRGBAString colour}}"
						style="{{getColourStyle colour}}"></span>
					{{t "r4b.level.red"}} <input data-id={{id}} data-colour="r" class="colour-pick" type="number" min="0"
						max="255" value={{colour.r}}></input>
					{{t "r4b.level.green"}} <input data-id={{id}} data-colour="g" class="colour-pick" type="number" min="0"
						max="255" value={{colour.g}}></input>
					{{t "r4b.level.blue"}} <input data-id={{id}} data-colour="b" class="colour-pick" type="number" min="0"
						max="255" value={{colour.b}}></input>
					{{t "r4b.level.alpha"}} <input data-id={{id}} data-colour="a" class="colour-pick" type="number" min="0"
						max="1" value={{colour.a}}></input>

					<div class="red-delete level-delete" data-selector="{{pageId}}" data-id="{{pageId}}"><i
							class="icon-trash"></i>
					</div>
					<b><code><i class="icon-lightbulb"></i> {{id}}</code></b>
				</div>
				{{/each}}
				<hr>
			</div>
		</div>
	</div>

	{{#each levelList}}
	<div class="level mb-20 {{#isInObject ../openLevels @key}}level-opened{{/isInObject}}" id="level-{{@key}}">
		<div class="col-xs-12 level-header" data-target="{{@key}}">
			<legend>
				<span class="colour-visual span-colour" data-id={{id}} data-rgba="{{getRGBAString colour}}"
					style="{{getColourStyle colour}}" data-level="{{@key}}">
					<div class="white"></div>
				</span>
				{{#ifEqual @key 0}}
				{{t "r4b.assessments.pre"}}
				{{else}}
				{{t "r4b.modules.level"}} {{@key}}
				{{/ifEqual}}
			</legend>

			<div class="level-header-actions {{#isNotInObject ../openLevels @key}}level-actions-hidden{{/isNotInObject}}"
				id="level-actions-{{@key}}">
				{{#if hasAssessment}}
				{{else}}
				<button class="add button button-link ml-10" data-class="R4b_Assessment" data-level="{{@key}}">
					<i class="icon icon-plus"></i>
					{{t "common.add"}} {{t "r4b.assessments.assessment"}}
				</button>
				{{/if}}
				<button class="add button button-link" data-class="R4b_Module" data-level="{{@key}}">
					<i class="icon icon-plus"></i> {{t "common.add"}}
					{{#ifEqual @key 0}}
					{{t "r4b.assessments.pre"}}
					{{else}}
					{{t "r4b.modules.level"}} {{@key}}
					{{/ifEqual}}
					{{t "r4b.modules.module"}}
				</button>
			</div>
		</div>

		<div class="level-details level-details-collapse"
			style="{{#isInObject ../openLevels @key}}display: block;{{/isInObject}}" id="collapse-{{@key}}">
			<div class="row">
				<div class="col-xs-12">
					<div class="modules-container">
						{{#each unitGroups}}
						{{#getAtlasStacked units}}
						<div class="stackedGroup" data-open="false" data-colour="{{getAtlasStackedColour ../colour}}">
							{{/getAtlasStacked}}
							{{#each units}}
							<div
								class="module-card-wrapper {{#ifGreaterThan level 4}}has-buttons{{/ifGreaterThan}} {{#ifEqual class 'R4b_Module'}}is-module{{/ifEqual}}">
								<a class="module-card"
									style="{{getColourStyle ../../colour}} {{getAtlasStackedStyle ../../colour ../units @index}}"
									data-index="{{@index}}" data-level="{{level}}" data-selector="{{pageId}}"
									href="apps/{{../../../appId}}/atlas_admin/{{id}}">
									<div class="module-card-content">
										{{#ifEqual class 'R4b_Assessment'}}
										<div class="assessment-content">
											<img id="atlas-bear" width="150px" height="150px" src="/images/atlas/bear-icon.png"></img>
											<div class="arrow_box">
												{{getAtlasAssessmentTitle this}}
											</div>
										</div>
										{{else}}
										<span class="shield">
											<div style="{{getColourStyle ../../colour}} {{getShieldMask image.src}}"></div>
										</span>
										{{/ifEqual}}
										<div class="tools">
											<div class="red-delete module-delete tooltip-right" data-selector="{{pageId}}"
												data-id="{{pageId}}" data-tooltip="{{t 'r4b.delete'}}"><i class="icon-trash"></i></div>
											{{#ifEqual class 'R4b_Module'}}
											{{#unless identifier}}
											<p>
												<!-- Todo: add warning for no identifier here -->
											</p>
											{{else if ../../../copyActive}}
											<div class="blue-settings module-copy tooltip-right" data-selector="{{pageId}}"
												data-id="{{pageId}}" data-tooltip="{{t 'r4b.copyModule'}}"><i class="icon-copy"></i></div>
											{{/unless}}
											{{/ifEqual}}
											<span class="atlas-module-id tooltip-right" data-tooltip="{{t 'r4b.id'}}">{{id}}</span>
										</div>
										<div class="module-title-row">
											{{#ifEqual class 'R4b_Module'}}
											<h4 class="title">
												{{getLocaleText name.content}}
											</h4>
											{{#if this.bonus}}
											<div>
												<label for="is-bonus">{{t 'r4b.isBonus'}}</label>
												<input type="checkbox" name="is-bonus" id="is-bonus" disabled checked />
											</div>
											{{/if}}
											{{/ifEqual}}
										</div>

										<div class="bottom-row" style="border-bottom: {{getRGBAString ../../colour}} 5px solid;">
											<h5 class="bottom-text">
												<b><i style="color:{{getRGBAString ../../colour}};" class="icon-time"></i> {{secondsToMinutes1dp
													time}}</b> {{t 'r4b.minutes'}}
											</h5>
											<h5 class="bottom-text">
												<b><i style="color:{{getRGBAString ../../colour}};" class="icon-list-ol"></i>
													{{contentBlockLength}}</b> {{t "r4b.sections"}}
											</h5>
											<h5 class="bottom-text">
												<b><i style="color:{{getRGBAString ../../colour}};" class="icon-group"></i> {{people}}</b>
												{{#ifEqual people 1}} {{t 'r4b.person'}} {{else}} {{t 'r4b.people'}} {{/ifEqual}}
											</h5>
										</div>
									</div>
								</a>

								{{#ifEqual class 'R4b_Module'}}
								{{#ifGreaterThan level 4}}
								<div class="module-card-buttons">
									<button type="button" class="button js-reorder-prev" data-level="{{level}}" data-id="{{id}}"
										data-order="{{order}}" data-level="{{level}}"><span class="left-arrow"></span> {{t
										'r4b.moveLeft'}}</button>
									<button type="button" class="button js-reorder-next" data-level="{{level}}" data-id="{{id}}"
										data-order="{{order}}" data-level="{{level}}">{{t 'r4b.moveRight'}} <span
											class="right-arrow"></span></button>
								</div>
								{{/ifGreaterThan}}
								{{/ifEqual}}
							</div>
							{{else}}
							<p class="help-block">
								{{t 'r4b.empty'}}
							</p>
							{{/each}}
							{{#getAtlasStacked units}}
							<div>
								<div class="blue-settings toggle-stacked"><i class="icon-arrow-left"></i></div>
							</div>
						</div>
						{{/getAtlasStacked}}
						{{/each}}
					</div>
				</div>
			</div>
		</div>
	</div>
	{{/each}}

</div>