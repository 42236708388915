<td class="story-image-cell"><img style="max-width:100px;" src="{{getPreviewUrl image}}"></td>
{{!-- <td class="story-image-cell"><img style="max-width:100px;" src="{{getPreviewUrl image_inactive}}"></td> --}}
<td>
	{{stage}}
</td>
<td>
	<dl>
		{{#key_value title.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	{{ prettyLink.pageTitle }}
</td>
<td>
	<dl>
		{{#key_value pending.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value active.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value elapsed.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/journey/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
