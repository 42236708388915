<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="stories.editStory"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

	<div class="col-xs-12">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.image.title"}}</label>
		</div>
		<div class="form-group">
			<img style="max-width:200px;" src="{{getPreviewUrl image}}">
		</div>
		<div class="form-group">
			<button type="button" class="button choose-image-button" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
		</div>
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "stories.name"}}</label>
			<input type="text" class="name-input form-control">
		</div>
	</div>

	<div class="col-sm-12">
		<div class="form-group">
			<label class="control-label">{{t "stories.title"}}</label>
		</div>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<textarea type="text" class="story-input form-control" data-code="{{code}}" rows="5"></textarea>
				</div>
			</div>
		{{/each}}
	</div>
</form>
