var EditorSectionView = require('editor/editor-section-view'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	StormQL = require('models/stormql'),
	AssessmentModuleList = require('./units'),
	utils = require('./atlas-utils'),
	CategoriesView = require('./categories-view')

module.exports = EditorSectionView.extend({
	className: 'admin',
	template: require('./admin-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .add': 'addModule',
		'click .module-delete': 'removeModule',
		'click .module-copy': 'copyModule',
		'click .module-card': 'openModule',
		'click .level-delete': 'removeLevel',
		'click .toggle-stacked': 'toggleStacked',
		'input .colour-pick': 'updateLevel',
		'click .add-level': 'addLevel',
		'click .edit-levels': 'toggleLevelsEdit',
		'click .close-edit-levels': 'toggleLevelsEdit',
		'click #atlas-bear': 'spinAtlas',
		'click .js-reorder-prev': 'reorderPrev',
		'click .js-reorder-next': 'reorderNext',
		'click .level-header': 'toggleLevelDetails'
	},

	initialize: function(options) {
		App.startLoad()
		this.app = App.appList.get(options.appId)
		this.appId = options.appId

		this.categoriesView = new CategoriesView({
			appId: this.appId,
			parent: this,
			checkForDeleteConditions: this.checkCategoryDelete
		})

		this.requests()
	},

	requests: function() {
		var requests = []

		// Levels
		this.levelList = new StormQL(null, {app: this.app})
		requests.push(this.levelList.fetch({data: {class: 'R4b_Level'}}))

		// Fetch units
		this.units = new AssessmentModuleList()

		// Styles
		this.stylesFetched = false
		this.styles = new StormQL(null, {app: this.app})
		requests.push(this.styles.fetch({data: {class: 'Style'}}))

		this.openLevels = {}

		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		App.stopLoad()
		this.stylesFetched = true
		this.render()
	},

	checkCategoryDelete: function(categoryId) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: App.apiRoot + '/objects/search/',

				type: 'POST',

				data: JSON.stringify({
					class: "R4b_Unit",
					appId: this.appId,
					inherit: true,
					type: "cms",
					excludeProperties: {
						R4b_Assessment: ["children"],
						R4b_Module: ["children"]
					}
				}),

				headers: App.session.getHeadersObject(),

				success: function(data) {
					resolve(data.filter(function(item) {
						return item.categoryId === categoryId
					}))
				},

				error: function(err) {
					reject(err)
				}
			})
		})
	},

	getPageTitle: function() {
		return $.t('r4b.title')
	},

	getLevelPromise: function(level) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: App.apiRoot + '/objects/search/',

				type: 'POST',

				data: JSON.stringify({
					class: "R4b_Unit",
					appId: this.appId,
					inherit: true,
					type: "cms",
					properties: {
						level: level
					},
					excludeProperties: {
						R4b_Assessment: ["children"],
						R4b_Module: ["children"]
					}
				}),

				headers: App.session.getHeadersObject(),

				success: function(data) {
					resolve(data)
				},

				error: function(err) {
					reject(err)
				}
			})
		}.bind(this))
	},

	spinAtlas: function() {
		$('#atlas-bear').addClass('spin-animation')
		setTimeout(function() {
			$('#atlas-bear').removeClass('spin-animation')
		}, 2000)
	},

	getRenderData: function() {
		var orderedData = this.levelList.toJSON()

		// reorder the modules based on their order property
		orderedData.forEach(function(e) {
			if (e.unitGroups) {
				e.unitGroups.sort(function(e1, e2) {
					return (e1.units[0].order - e2.units[0].order)
				})
			}
		})

		return {
			appId: this.appId,
			levels: this.levels,
			levelList: orderedData,
			openLevels: this.openLevels,
			levelCount: Handlebars.helpers.countNumOfProps(this.levels),
			copyActive: this.stylesFetched
		}
	},

	afterRender: function() {
		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}

		var self = this
		$('.colour-visual').each(function() {
			var id = $(this).attr('data-id')
			$(this).colorpicker({
				align: 'left',
				color: $(this).attr('data-rgba')
			}).on('hidePicker', function(e) {
				self.setUIColours(id, e.color.toRGB())
			})
		})

		$('.js-categories').html(this.categoriesView.render().el)
		this.categoriesView.delegateEvents()
	},

	// Set UI Colours when the picker has selected
	setUIColours: function(id, rgba) {
		$('.colour-pick[data-id=' + id + '][data-colour="r"]').val(rgba.r)
		$('.colour-pick[data-id=' + id + '][data-colour="g"]').val(rgba.g)
		$('.colour-pick[data-id=' + id + '][data-colour="b"]').val(rgba.b)
		$('.colour-pick[data-id=' + id + '][data-colour="a"]').val(rgba.a)
		this.setLevelValues(id)
	},

	/**
	 * Map modules and assessments into one array (this.levels) that is defined by levels
	 * @returns {undefined}
	 */
	mapToLevels: function() {
		this.levels = {}
		this.units.forEach(function(unit) {
			var level = unit.get('level')

			// Set grouping count
			var identifier = unit.get('identifier')

			// Add unit to the correct groups in each level
			if (this.levels[level]) {
				var identifierFound = false
				this.levels[level].forEach(function(group) {
					if (group.identifier === identifier) {
						group.units.push(unit.toJSON())
						identifierFound = true
					}
				})
				// If we couldn't find the right identifier then we need to create it.
				if (!identifierFound) {
					this.levels[level].push({
						identifier: identifier,
						units: [unit.toJSON()]
					})
				}
			} else {
				// If we couldn't find the right level then we need to create it.
				this.levels[level] = [{
					identifier: identifier,
					units: [unit.toJSON()]
				}]
			}
		}.bind(this))

		this.levelList.forEach(function(level, index) {
			level.set('unitGroups', this.levels[index])
			level.set('hasAssessment', this.hasAssessment(index + 1))
		}.bind(this))
	},

	/**
	 * Set the content block counts and language counts for the modules and assessments.
	 * @returns {undefined}
	 */
	setCounts: function() {
		this.levelList.forEach(function(level) {
			if (level.get('units')) {
				level.get('units').forEach(function(item) {
					item.set('contentBlockLength', item.get('children').length)
				})
			}
		})
	},

	/**
	 * Get the count for the number of questions in an assessment
	 * @param  {R4b_Assessment} assessment Assessment
	 * @returns {Integer} Number of questions
	 */
	getAssessmentQuestionCount: function(assessment) {
		var questionCount = 0
		if (assessment.children.length) {
			_.forEach(assessment.children, function(children) {
				_.forEach(children.children, function(question) {
					if (question.class === "R4b_QuestionAssessmentBlock" || question.class === "R4b_InputAssessmentBlock") {
						++questionCount
					}
				})
			})
		}
		return questionCount
	},

	/**
	 * Adds a module to the view and to the backend
	 * @param {event} e Event
	 */
	addModule: function(e) {
		e.stopPropagation()
		var className = $(e.currentTarget).attr('data-class')
		var levelNo = parseInt($(e.currentTarget).data('level'), 10)
		var newOrderNumber = $('.module-card[data-module="' + levelNo + '"]').length + 1

		var model = StandaloneStormObject.fromClassName(className)
		if (className === "R4b_Module" || (className === 'R4b_Assessment' && !this.hasAssessment(levelNo + 1))) {
			model.set('level', levelNo)
			model.set('order', newOrderNumber)
			App.startLoad()
			model.save(null, {appId: this.appId}).then(function() {
				this.requests()
			}.bind(this))
		}
	},

	/**
	 * Does a level have an assessment in it?
	 * @param  {number}  levelNo Number of level (Not Index)
	 * @returns {Boolean}         Returns True if it exists
	 */
	hasAssessment: function(levelNo) {
		var isAssessment = false
		// Get level fron index
		var level = this.levelList.at(levelNo - 1)
		if (level.get('unitGroups')) {
			level.get('unitGroups').forEach(function(unitGroup) {
				if (unitGroup.get('units')) {
					unitGroup.get('units').forEach(function(unit) {
						if (unit.get('class') === "R4b_Assessment") {
							isAssessment = true
						}
					})
				}
			})
		}
		return isAssessment
	},

	/**
	* Removes a module from the backend
	* @param {event} e Event
	 */
	removeModule: function(e) {
		e.preventDefault()
		e.stopPropagation()
		var id = $(e.currentTarget).data('id')
		var order = $(e.currentTarget).data('order')
		var level = $(e.currentTarget).data('level')
		var items = this.levelList.toJSON()
		var selectedLevel = this.levelList.at(level)

		this.removeModel(id, level, order, function() {
			// Remove module from dom.

			var promises = []
			App.startLoad()

			// Find that object in the list of units
			// Update the order for that one
			if (selectedLevel.get('unitGroups')) {
				selectedLevel.get('unitGroups').forEach(function(unitGroup) {
					if (unitGroup.get('units')) {
						unitGroup.get('units').forEach(function(unit) {
							var newOrderId = unit.get('order')
							var unitId = unit.get('id')
							var unitClass = unit.get('class')

							if (unitId !== id && unitClass !== 'R4b_Assessment') {
								if (order < unit.get('order')) {
									newOrderId = unit.get('order') - 1
									unit.set('order', newOrderId)
								}
							}
						})
					}
				})
			}

			// update the backend
			items.forEach(function(item) {
				if (item.identifier === level) {
					item.unitGroups.forEach(function(unit) {
						var newOrderId = unit.units[0].order
						var unitId = unit.units[0].id
						var unitClass = unit.units[0].class

						if (order < unit.units[0].order) {
							newOrderId = unit.units[0].order - 1
						}

						if (unitId !== id && unitClass !== 'R4b_Assessment') {
							// Update the backend
							var updateOrderModel = StandaloneStormObject.fromProperties({id: unit.units[0].id})
							promises.push(new Promise(function(resolve) {
								updateOrderModel.fetch().then(function() {
									updateOrderModel.set('order', newOrderId)
									updateOrderModel.requestLock(function() {
										updateOrderModel.save().then(function() {
											updateOrderModel.requestUnlock().then(resolve())
										})
									})
								})
							}))
						}
					})
				}
			})

			Promise.all(promises)
				.then(function() {
					App.startLoad()
					this.requests()
				}.bind(this))
		}.bind(this))
	},

	/**
	* Copies a module (strips ids and keeps identifier)
	* @param {event} e Event
	 */
	copyModule: function(e) {
		e.preventDefault()
		e.stopPropagation()
		var id = $(e.currentTarget).data('id')
		if (id) {
			swal({
				title: $.t('editor.inspector.areYouSure'),
				text: $.t('r4b.copyConfirmation'),
				showCancelButton: true
			}, function(didConfirm) {
				if (didConfirm) {
					App.startLoad()
					var model = StandaloneStormObject.fromProperties({id: id})
					model.fetch().then(function() {
						var copiedModule = utils.copyModel(model, true, this.styles, true)
						copiedModule.save(null, {appId: this.appId}).then(function() {
							// Re-create for each styled object...
							var saves = []
							utils.styledObjects.forEach(function(model) {
								var selector = model.style
								selector.get('selector').identifier = model.id
								saves.push(selector.save(null, {appId: this.appId}))
							}.bind(this))
							Promise.all(saves).then(this.requests.bind(this))
						}.bind(this))
					}.bind(this))
				}
			}.bind(this))
		}
	},

	openModule: function(e) {
		e.preventDefault()
		if ($(e.currentTarget).parent().parent().attr('data-open') === "false") {
			this.toggleStacked(e)
			return false
		}
		return true
	},

	/**
	 * Adds a module to the view and to the backend
	 * @param {event} e Event
	 */
	addLevel: function() {
		var model = StandaloneStormObject.fromClassName('R4b_Level')
		model.set('identifier', this.levelList.length)
		App.startLoad()
		model.save(null, {appId: this.appId}).then(function() {
			this.requests()
		}.bind(this))
	},

	/**
	* Updates a level
	* @param {event} e Event
	 */
	updateLevel: function(e) {
		var id = $(e.currentTarget).data('id')
		this.setLevelValues(id)
	},

	setLevelValues: function(id) {
		var model = this.levelList.get(id)
		// Colours
		var r = parseInt($('.colour-pick[data-id=' + id + '][data-colour="r"]').val(), 10),
			g = parseInt($('.colour-pick[data-id=' + id + '][data-colour="g"]').val(), 10),
			b = parseInt($('.colour-pick[data-id=' + id + '][data-colour="b"]').val(), 10),
			a = parseFloat($('.colour-pick[data-id=' + id + '][data-colour="a"]').val(), 10)

		if (model.get('colour..r') !== r || model.get('colour..r') !== g || model.get('colour..r') !== b || model.get('colour..r') !== a) {
			model.set('colour..r', r)
			model.set('colour..g', g)
			model.set('colour..b', b)
			model.set('colour..a', a)
			var level = $('.span-colour[data-id=' + id + ']').data('level')
			// Update span
			$('.colour-visual[data-id=' + id + ']').attr('style', Handlebars.helpers.getColourStyle(model.get('colour')))
			// Update Cards
			$('.module-card[data-level=' + level + ']').attr('style', Handlebars.helpers.getColourStyle(model.get('colour')))
			$('.module-card[data-level=' + level + '] i').attr('style', 'color:' + Handlebars.helpers.getRGBAString(model.get('colour')) + ';')
			$('.module-card[data-level=' + level + '] .bottom-row').attr('style', 'border-bottom:' + Handlebars.helpers.getRGBAString(model.get('colour')) + '5px solid;')

			// Save
			model.needsSaving = true
			if (!model.saving) {
				this.saveLevel(model)
			}
		}
	},

	// Save the level only if the level is not saving.. if it still needs saving during the request the save runs again.
	saveLevel: function(level) {
		level.unset('units') // This is only used on the UI in the handlebars template, remove it before save.
		level.unset('contentBlockLength') // This is only used on the UI in the handlebars template, remove it before save.
		level.unset('hasAssessment') // This is only used on the UI in the handlebars template, remove it before save.

		var self = this
		level.saving = true
		level.needsSaving = false
		level.requestLock(function() {
			level.save(null, {appId: this.appId}).then(function() {
				level.requestUnlock().then(function() {
					if (level.needsSaving) {
						// Change made during save, save again!
						self.saveLevel(level)
					} else {
						level.saving = false
					}
				})
			})
		})
	},

	// Remove a level
	removeLevel: function(e) {
		e.preventDefault()
		e.stopPropagation()
		var id = $(e.currentTarget).data('id')
		this.removeModel(id, this.requests.bind(this))
	},

	// Remove a model (Level or Module/Assessment)
	removeModel: function(id, level, order, callback) {
		if (id) {
			swal({
				title: $.t('editor.inspector.areYouSure'),
				text: $.t('editor.inspector.confirmDelete'),
				showCancelButton: true
			}, function(didConfirm) {
				if (didConfirm) {
					var model = StandaloneStormObject.fromProperties({id: id})
					model.fetch().then(function() {
						model.requestLock(function() {
							App.startLoad()
							model.destroy().then(callback)
						})
					})
				}
			})
		}
	},

	// Hide / Show the levels view
	toggleLevelsEdit: function() {
		var levels = $(".levels, .add-level, .close-edit-levels"),
			editButton = $(".edit-levels")

		if (levels.is(':visible')) {
			levels.hide(100)
			editButton.show()
		} else {
			levels.show(100)
			editButton.hide()
		}
	},

	toggleLevelDetails: function(e) {
		var toggleTarget = parseInt($(e.currentTarget).attr('data-target'), 10)
		var level = this.levelList.findWhere({identifier: toggleTarget})

		if (level && level.fetchedModules) {
			this.toggleLevelDetailsUi(toggleTarget)
		} else if (level) {
			App.startLoad()
			this.getLevelPromise(toggleTarget).then(function(units) {
				level.fetchedModules = true
				this.units.add(units)
				this.mapToLevels()
				this.setCounts()
				this.render()
				App.stopLoad()
				this.toggleLevelDetailsUi(toggleTarget)
			}.bind(this))
		}
	},

	toggleLevelDetailsUi: function(identifier) {
		this.openLevels[identifier] = !this.openLevels[identifier]

		$('#collapse-' + identifier).toggle()
		$('#level-' + identifier).toggleClass('level-opened')
		$('#level-actions-' + identifier).toggleClass('level-actions-hidden')
	},

	// Hide show a stacked group
	toggleStacked: function(e) {
		if ($(e.currentTarget).parent().parent().attr('data-open') === "true") {
			$(e.currentTarget).parent().parent().attr('data-open', "false")
			$(e.currentTarget).parent().parent().attr('style', '')
		} else {
			$(e.currentTarget).parent().parent().attr('data-open', "true")
			var colour = $(e.currentTarget).parent().parent().attr('data-colour')
			$(e.currentTarget).parent().parent().attr('style', colour)
		}
	},

	reorderPrev: function(e) {
		var currentOrderNumber = parseInt(e.currentTarget.dataset.order, 10)
		var currentId = parseInt(e.currentTarget.dataset.id, 10)
		var levelNo = parseInt(e.currentTarget.dataset.level, 10)
		var items = this.levelList.toJSON()
		var selectedLevel = this.levelList.at(levelNo)
		var promises = []
		App.startLoad()

		// Find that object in the list of units
		// Update the id for that one
		if (selectedLevel.get('unitGroups')) {
			selectedLevel.get('unitGroups').forEach(function(unitGroup) {
				if (unitGroup.get('units')) {
					unitGroup.get('units').forEach(function(unit) {
						var newOrderId = unit.get('order')

						if (unit.get('id') !== currentId && unit.get('order') === (currentOrderNumber - 1)) {
							newOrderId = unit.get('order') + 1
							unit.set('order', newOrderId)
						}

						if (unit.get('id') === currentId) {
							newOrderId = unit.get('order') - 1
							unit.set('order', newOrderId)
						}
					})
				}
			})
		}

		// update the backend
		items.forEach(function(item) {
			if (item.identifier === levelNo) {
				item.unitGroups.forEach(function(unit) {
					var newOrderId = unit.units[0].order

					if (unit.units[0].id !== currentId && unit.units[0].order === (currentOrderNumber - 1)) {
						newOrderId = unit.units[0].order + 1
					}

					if (unit.units[0].id === currentId) {
						newOrderId = unit.units[0].order - 1
					}

					// Update the backend
					var updateOrderModel = StandaloneStormObject.fromProperties({id: unit.units[0].id})
					promises.push(new Promise(function(resolve) {
						updateOrderModel.fetch().then(function() {
							updateOrderModel.set('order', newOrderId)
							updateOrderModel.requestLock(function() {
								updateOrderModel.save().then(function() {
									updateOrderModel.requestUnlock().then(resolve())
								})
							})
						})
					}))
				})
			}
		})

		Promise.all(promises)
			.then(function() {
				App.stopLoad()
				this.render()
			}.bind(this))
	},

	reorderNext: function(e) {
		var currentOrderNumber = parseInt(e.currentTarget.dataset.order, 10)
		var currentId = parseInt(e.currentTarget.dataset.id, 10)
		var levelNo = parseInt(e.currentTarget.dataset.level, 10)
		var items = this.levelList.toJSON()
		var selectedLevel = this.levelList.at(levelNo)
		var promises = []
		App.startLoad()

		// Find that object in the list of units
		// Update the id for that one
		if (selectedLevel.get('unitGroups')) {
			selectedLevel.get('unitGroups').forEach(function(unitGroup) {
				if (unitGroup.get('units')) {
					unitGroup.get('units').forEach(function(unit) {
						var newOrderId = unit.get('order')

						if (unit.get('id') !== currentId && unit.get('order') === (currentOrderNumber + 1)) {
							newOrderId = unit.get('order') - 1
							unit.set('order', newOrderId)
						}

						if (unit.get('id') === currentId) {
							newOrderId = unit.get('order') + 1
							unit.set('order', newOrderId)
						}
					})
				}
			})
		}

		// update the backend
		items.forEach(function(item) {
			if (item.identifier === levelNo) {
				item.unitGroups.forEach(function(unit) {
					var newOrderId = unit.units[0].order

					if (unit.units[0].id !== currentId && unit.units[0].order === (currentOrderNumber + 1)) {
						newOrderId = unit.units[0].order - 1
					}

					if (unit.units[0].id === currentId) {
						newOrderId = unit.units[0].order + 1
					}

					// Update the backend
					var updateOrderModel = StandaloneStormObject.fromProperties({id: unit.units[0].id})
					promises.push(new Promise(function(resolve) {
						updateOrderModel.fetch().then(function() {
							updateOrderModel.set('order', newOrderId)
							updateOrderModel.requestLock(function() {
								updateOrderModel.save().then(function() {
									updateOrderModel.requestUnlock().then(resolve())
								})
							})
						})
					}))
				})
			}
		})

		Promise.all(promises)
			.then(function() {
				App.stopLoad()
				this.render()
			}.bind(this))
	}
})
