var AppAudit                   = require('./app-audit'),
	AuditListItem              = require('./audit-list-item-view'),
	PageList                   = require('editor/page-list/page-list'),
	PublishSectionView         = require('./publish-section-view'),
	PublishHistoryList         = require('./publish-history-list'),
	PublishHistoryListItemView = require('./publish-history-list-item-view'),
	UsersList                  = require('users/user-list'),
	PluginList                 = require('editor/plugin-list')

var PUBLISH_TYPES = ['live', 'test']

/**
 * Exports {@link PublishView}.
 * @module
 */
module.exports = PublishSectionView.extend(/** @lends PublishView.prototype */{
	/** @override */
	template: require('./publish-view-template'),

	/** @override */
	events: {
		'click .publish-button-test': 'publishToTest',
		'click .publish-button-live': 'publishToLive',
		'click .select-all': 'selectAll',
		'click .publish-confirm': 'publishConfirm',
		'click .cancel': 'cancel',
		'change .publish-type': 'publishTypeChange',
		'change #toggle-published-to-test': 'togglePublishedToTestColumn',
		'change #toggle-auto-refresh': 'toggleAutoRefresh'
	},

	/** @override */
	getPageTitle: function() {
		return $.t('publish.title')
	},

	/**
	 * @constructs PublishView
	 * @extends PublishSectionView
	 * @override
	 */
	initialize: function(options) {
		App.startLoad()

		this.listViews = []
		this.readyCount = 0
		this._allSelected = {}

		this.appId = options.appId
		this.app = App.appList.get(options.appId)

		/** @private {?string} */
		this.publishType_ = null

		var fetches = []

		// Fetch page audit list
		this.model = new AppAudit({appId: this.appId})
		fetches.push(this.model.fetch())

		// Fetch history
		this.collection = new PublishHistoryList(null, {appId: this.appId})
		fetches.push(this.fetchHistoryAndRelatedUsers())

		// Fetch page list to cross reference page IDs
		this.pageList = new PageList(null, {appId: this.appId})
		fetches.push(this.pageList.fetch())

		// Fetch Plugin list to cross reference pluin codes.
		this.pluginList = new PluginList(null, {appId: this.appId})
		fetches.push(this.pluginList.fetch())

		// Set changed objects to empty collection
		this.changedObjects = new Backbone.Collection()

		Promise.all(fetches).then(this.ready.bind(this))
	},

	ready: function() {
		// Reload history every 20 seconds
		this.toggleAutoRefresh()
		this.setUpAudits()
		this.fetchChangedObjects()
	},

	refreshAudits: function() {
		if ($('.publish:checked').length === 0) {
			this.model.fetch().then(function() {
				if ($('.publish:checked').length === 0) {
					$('.published-in-test').empty()
					$('.pages-in-test').empty()
					$('.unpublished').empty()
					this.setUpAudits()
					this.fetchChangedObjects(true)
					if (this.audits) {
						this.createContentLists(this.audits)
					}
					this.updateHistoryLists()
					App.stopLoad()
				}
			}.bind(this))
		}
	},

	setUpAudits: function() {
		this.audits = []
		this.changedIds = []
		// For Each audit entry
		this.model.forEach(function(audit) {
			// Get Plugin type
			if (audit.get('plugin')) {
				var codeName = audit.get('plugin').codeName
				var plugin = this.pluginList.getByCodeName(codeName)

				// Return plugin name to either be the on found in apps plugins or Capitalised first letter of codeName
				var pluginName = plugin ? plugin.get('name') : codeName.charAt(0).toUpperCase() + codeName.slice(1)
				var className = plugin ? plugin.get('className') : undefined
				var labelProperty = plugin ? plugin.get('labelProperty') : undefined

				// Loop through logs
				var logs = {
					codeName: codeName,
					pluginName: pluginName,
					className: className,
					labelProperty: labelProperty,
					unpublished: [],
					publishToTest: []
				}

				audit.get('logs').forEach(function(log) {
					if (logs.codeName !== 'native') {
						this.changedIds.push(log.pageId)
					}
					// Note: log.in is a bit field, so using bitwise operators
					if (log.in & 1) {
						logs.unpublished.push(log)
					}

					if (log.in & 2) {
						logs.publishToTest.push(log)
					}
				}.bind(this))
				this.audits.push(logs)
			}
			this.audits.forEach(function(auditList) {
				auditList.unpublished.sort(this.sortByDate)
				auditList.publishToTest.sort(this.sortByDate)
			}.bind(this))
		}.bind(this))
	},

	sortByDate: function(a, b) {
		return new Date(b.timestamp) - new Date(a.timestamp)
	},

	fetchChangedObjects: function(noRender) {
		if (this.changedIds.length) {
			if (!noRender) {
				App.startLoad()
			}
			var data = {ids: this.changedIds}
			$.ajax({
				url: App.apiRoot + 'objects/search',
				type: 'POST',
				data: JSON.stringify(data),
				headers: App.session.getHeadersObject(),
				success: function(msg) {
					this.changedObjects = new Backbone.Collection(msg)
					if (!noRender) {
						this.render()
						App.stopLoad()
					}
				}.bind(this)
			})
		} else {
			this.render()
			App.stopLoad()
		}
	},

	fetchHistoryAndRelatedUsers: function() {
		var historyFetch = Promise.resolve(this.collection.fetch())
		return historyFetch.then(this.fetchUsers.bind(this))
	},

	togglePublishedToTestColumn: function(e) {
		var show = e.target.checked
		localStorage.setItem('showPublishedToTestColumn', show)

		var $publishChangesBoxViews = $('.publish-changes-box-view')

		$publishChangesBoxViews.removeClass('col-md-3 col-md-4')
		if (show) {
			$publishChangesBoxViews.addClass('col-md-3')
				.filter('.published-to-test').show()
		} else {
			$publishChangesBoxViews.addClass('col-md-4')
				.filter('.published-to-test').hide()
		}
	},

	// Reload history every 2 minutes
	toggleAutoRefresh: function() {
		if (App.system.id !== 9) {
			// Don't allow for ARC
			$('#label-auto-refresh').show()
		} else {
			return
		}
		if ($('#toggle-auto-refresh').is(':checked')) {
			this.updateHistoryInterval = setInterval(function() {
				if ($('.publish:checked').length === 0) {
					this.fetchHistoryAndRelatedUsers()
						.then(this.refreshAudits.bind(this))
				}
			}.bind(this), 1000 * 120)
		} else {
			clearInterval(this.updateHistoryInterval)
			this.updateHistoryInterval = null
		}
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.appId,
			audits: this.audits
		}
	},

	/** @override */
	afterRender: function() {
		var showPublishedToTestColumn = JSON.parse(localStorage.getItem('showPublishedToTestColumn') || false)
		$('#toggle-published-to-test').attr('checked', showPublishedToTestColumn).trigger('change')

		if (!App.acl.hasWritePermission('Publish')) {
			this.$('.publish-button').hide()
		}

		// Remove developer-only content.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}

		// Disallow live publishing on ARC apps 2/3.
		if (App.system.id === 9 && (this.appId === 2 || this.appId === 3)) {
			this.$('.publish-button-live').attr('disabled', true)
		}

		// Show auto refresh on anything but arc
		if (App.system.id !== 9) {
			$('#label-auto-refresh').show()
		}

		if (this.audits) {
			this.createContentLists(this.audits)
		}
		this.updateHistoryLists()
	},

	sortDateDesc: function(auditsToSort) {
		auditsToSort.sort(function(x, y) {
			var date1 = new Date(x.timestamp)
			var date2 = new Date(y.timestamp)
			return date2 - date1
		})
	},

	createContentLists: function(audits) {
		var $unpublished = $('#pages-unpublished')
		var $publishedToTest = $('#pages-in-test')

		audits.forEach(function(audit) {
			if (audit.codeName === 'pages') {
				audit.labelProperty = 'title'
			}

			if (audit.codeName !== 'native') {
				this.sortDateDesc(audit.unpublished)
				this.sortDateDesc(audit.publishToTest)

				// For each unpublished audit
				audit.unpublished.forEach(function(auditLog) {
					$('#' + audit.codeName + '-unpublished').append(this.getObjectAuditView(auditLog, audit, 'unpublished').render().el)
				}.bind(this))

				// For each published to test audit
				audit.publishToTest.forEach(function(auditLog) {
					$('#' + audit.codeName + '-in-test').append(this.getObjectAuditView(auditLog, audit, 'publishToTest').render().el)
				}.bind(this))
			} else {
				// For Native items (Doesn't need to be a model)
				// Unpublished
				if (audit.unpublished.length) {
					$('#' + audit.codeName + '-unpublished').append(this.getNativeAuditView(audit, 'unpublished').render().el)
				}

				// Published
				if (audit.publishToTest.length) {
					$('#' + audit.codeName + '-in-test').append(this.getNativeAuditView(audit, 'publishToTest').render().el)
				}
			}
		}.bind(this))

		$unpublished.siblings('.no-changes').toggle(
			!$unpublished.find('tr:not(.detail-row)').length
		)

		$publishedToTest.siblings('.no-changes').toggle(
			!$publishedToTest.find('tr:not(.detail-row)').length
		)
	},

	getObjectAuditView: function(page, audit, type) {
		var area
		if (type === 'unpublished') {
			area = 1
		} else {
			area = 2
		}
		var pageModel = new Backbone.Model(page)
		return new AuditListItem({
			model: pageModel,
			area: area,
			appId: this.appId,
			pageList: this.pageList,
			pluginClassName: audit.codeName,
			labelProperty: audit.labelProperty,
			changedObject: this.changedObjects.get(pageModel.get('pageId'))
		})
	},

	getNativeAuditView: function(audit, type) {
		var area
		if (type === 'unpublished') {
			area = 1
		} else {
			area = 2
		}
		return new AuditListItem({
			model: audit[type],
			codeName: audit.pluginName,
			area: area,
			appId: this.appId,
			pageList: this.pageList
		})
	},

	updateHistoryLists: function() {
		var $publishedToLive = $('#published-to-live')
		var $publishedToTest = $('#published-to-test')
		var $publishingToTest = $('#publishing-to-test')

		$publishedToLive.empty()
		$publishedToTest.empty()
		$publishingToTest.empty()

		var histories = this.collection.groupBy(function(item) {
			return item.get('type')
		})
		if (histories.test) {
			histories.test.forEach(function(publish) {
				var view = new PublishHistoryListItemView({
					model: publish,
					pageList: this.pageList,
					userList: this.userList,
					pluginList: this.pluginList
				})

				var $container = publish.get('status') < 3 ? $publishingToTest : $publishedToTest
				$container.append(view.render().el)
			}.bind(this))
		}

		if (histories.live) {
			histories.live.forEach(function(publish) {
				var view = new PublishHistoryListItemView({
					model: publish,
					pageList: this.pageList,
					userList: this.userList,
					pluginList: this.pluginList
				})

				$publishedToLive.append(view.render().el)
			}.bind(this))
		}
	},

	fetchUsers: function() {
		var userIDs = this.collection.map(function(item) {
			return item.get('userId')
		})

		this.userList = new UsersList({ids: _.uniq(userIDs)})
		return Promise.resolve(this.userList.fetch())
	},

	publishPreview: function(publishType) {
		var checkboxSection = publishType === 'test' ? '.unpublished' : '.published-in-test'
		var pages = this.$(checkboxSection + ' .publish:checked').map(function() {
			return parseInt(this.value, 10)
		}).get()

		if (!pages.length) {
			swal($.t('error.oops'), $.t('publish.error'), 'error')
			return
		}

		this.$('.add-form').toggleClass('zero-height', false)

		// Disable all other audit selection checkboxes.
		this.$('.audit-list-item .publish').prop('disabled', true)
	},

	selectAll: function(e) {
		var type = $(e.currentTarget).data('type')

		var checkboxSection = type === 'test' ? '.unpublished' : '.published-in-test'

		if (this._allSelected[type]) {
			this.$(checkboxSection + ' .publish').prop('checked', false)
		} else {
			this.$(checkboxSection + ' .publish').prop('checked', true)
		}

		this._allSelected[type] = !this._allSelected[type]
	},

	publishConfirm: function() {
		/* check user has confirmed to the disclaimer */
		var confirmation = this.$('.confirm-checkbox').prop('checked')
		var landmark = this.$('.confirm-landmark').prop('checked')

		if (!confirmation) {
			swal($.t('error.oops'), $.t('publish.pleaseConfirm'), 'error')
			return
		}

		this.$('.add-form').toggleClass('zero-height', true)

		this.$('.audit-view').hide()
		this.$('.loading-view').show()
		this.$('.loader').addClass('icon-spin')
		var comment = this.$('.comment').val()

		if (PUBLISH_TYPES.indexOf(this.publishType_) === -1) {
			throw new Error('Unknown publish type')
		}

		var checkboxSection = this.publishType_ === 'test' ? '.unpublished' : '.published-in-test'
		var pages = this.$(checkboxSection + ' .publish:checked').map(function() {
			return {
				id: Number(this.value)
			}
		}).get()

		// Dedupe page IDs
		pages = _.uniq(pages, 'id')

		var model = {
			pages: pages,
			comment: comment,
			type: this.publishType_,
			options: landmark ? 1 : 0
		}
		var self = this
		var appId = this.appId
		Backbone.sync('create', new Backbone.Model(), {
			url: App.apiRoot + 'apps/' + appId + '/publish',
			data: JSON.stringify(model),
			global: false,
			success: function() {
				App.stopLoad()
				App.showToast($.t('publish.success'))
				App.router.publish(appId)
				self.ready()
			},
			error: function(jqXHR) {
				App.stopLoad()

				var reason
				var is504 = false
				try {
					var resp = JSON.parse(jqXHR.responseText)
					var error = resp['server error'] || resp['client error']
					reason = error.data
					if (error.code === 504) {
						// Load balancer time out, just show message saying it's taking a long time
						is504 = true
					}
				} catch (err) {
					reason = 'Unknown error'
				}
				if (is504) {
					self.$('.too-long').show()
					self.$('.loading-view').hide()
				} else {
					swal($.t('error.oops'), $.t('publish.fail') + '\n\n' + reason, 'error')
					App.router.publish(appId)
				}
			}
		})
	},

	cancel: function() {
		// Show all audit trail items
		this.$('.add-form').toggleClass('zero-height', true)

		this.$('.comment').val('')
		this.$('.confirm-checkbox').attr('checked', false)

		// Re-enable all audit selection checkboxes.
		this.$('.audit-list-item .publish').attr('disabled', false)
	},

	publishToTest: function() {
		this.publishType_ = 'test'
		this.$('.confirm-landmark-label').hide()
		this.publishPreview(this.publishType_)
	},

	publishToLive: function() {
		this.publishType_ = 'live'
		this.$('.confirm-landmark-label').show()
		this.publishPreview(this.publishType_)
	},

	remove: function() {
		clearInterval(this.updateHistoryInterval)
		Backbone.View.prototype.remove.apply(this, arguments)
	}
})
