<td class="story-image-cell text-center">
	{{#if badge}}
		<h5><a href="/apps/{{appId}}/badges/{{badgeId}}">{{getLocaleText badge.title}}</a></h5>
		<img style="max-width:100px;" src="{{getPreviewUrl badge.icon}}" />
	{{/if}}
</td>
<td>
	{{ name }}
</td>
<td>
	{{ prettyLink.pageTitle }}
</td>
<td class="tools">
	<a href="/apps/{{appId}}/swim-levels/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
