{{#ifExists annotation}}
	<div class="annotation inline-editable" data-attribute="annotation" data-placeholder="1">{{annotation}}</div>
{{/ifExists}}

{{#ifExists image}}
	<span class="img-container" data-image="{{getPreviewUrl image}}">
		<img src="{{getPreviewUrl image}}">
	</span>
{{/ifExists}}

{{#ifExists icon}}
	{{#ifEqual class "CertificateListItem"}}
		<span class="img-container" data-image="{{getPreviewUrl icon}}">
			<img src="{{getPreviewUrl icon}}">
		</span>
	{{/ifEqual}}
{{/ifExists}}

{{#ifExists volatile}}
	<input type="checkbox">
{{/ifExists}}

<div class="text-container">
	{{#if title}}
		<div class="{{title.class}} inline-editable" data-attribute="title" data-placeholder="{{t "editor.inspector.properties.title.title"}}">{{{getLocaleTextHTML title shouldWarnOnTranslation}}}</div>
	{{/if}}

	{{#if description}}
		<div class="{{description.class}} inline-editable" data-attribute="description" data-placeholder="{{t "common.description"}}">{{{getLocaleTextHTML description shouldWarnOnTranslation}}}</div>
	{{/if}}

	{{#if button}}
		<span class="{{button.class}} button form-control inline-editable button-link" data-destination="{{button.link.class}}" data-url="{{ button.link.destination }}" data-attribute="button..title" data-placeholder="{{t "editor.inspector.properties.title.title"}}">{{{getLocaleTextHTML button.title shouldWarnOnTranslation}}}</span>
	{{/if}}

	{{#if certificateButton}}
		<span class="{{certificateButton.class}} certificate-button button form-control inline-editable button-link alt-red" data-destination="{{certificateButton.link.class}}" data-url="{{ certificateButton.link.destination }}" data-attribute="certificateButton..title" data-placeholder="{{t "editor.inspector.properties.title.title"}}">{{{getLocaleTextHTML certificateButton.title shouldWarnOnTranslation}}}</span>
	{{/if}}

	{{#if courseButton}}
		<span class="{{courseButton.class}} course-button button form-control inline-editable button-link outlined" data-destination="{{courseButton.link.class}}" data-url="{{ courseButton.link.destination }}" data-attribute="courseButton..title" data-placeholder="{{t "editor.inspector.properties.title.title"}}">{{{getLocaleTextHTML courseButton.title shouldWarnOnTranslation}}}</span>
	{{/if}}

	{{#ifEqual class "NativeListItem"}}
		<div class="text-center">{native content}</div>
	{{/ifEqual}}
</div>

{{#if link}}
	<span class="chevron"><i class="icon-chevron-right"></i></span>
{{/if}}

{{#if showLinkTitle}}
	<div class="link-title inline-editable" data-attribute="link..title" data-placeholder="{{t "editor.inspector.properties.title.title"}}">{{getLocaleText link.title}}</div>
{{/if}}

{{#ifExists videos}}
	<i class="icon-play-circle"></i>
{{/ifExists}}

{{#if progressMessage}}
	<div class="Text inline-editable" data-attribute="progressMessage" data-placeholder="{{t "editor.inspector.properties.progressMessage"}}">{{{getLocaleTextHTML progressMessage shouldWarnOnTranslation}}}</div>
	<span class="chevron"><i class="icon-chevron-right"></i></span>
{{/if}}

{{#ifExists quizzes}}
	<span>[Quizzes]</span>
{{/ifExists}}

{{#ifExists products}}
	<div class="product-container"></div>
{{/ifExists}}
