<div class="app-error" id="noItems" style="display:none;">{{t "common.noItems"}}</div>
<table>
	<thead>
		<tr>
			<th>{{t "common.page"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
