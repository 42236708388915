<div class="container-fluid">
	<div class="row">
		<div class="col-xs-6">
			<h3 data-i18n="translations.importExport.title" class="mt-12"></h3>
		</div>
		<div class="col-xs-6 actions-col">
			<a href="/apps/{{appId}}/settings/translations" class="button red wide is-link">{{t "common.back"}}</a>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<p data-i18n="translations.importExport.help" />
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12">
			<button class="button wide js-export">{{t "translations.importExport.export"}}</button>
			<button class="button wide js-import ml-10">{{t "translations.importExport.import"}}</button>
			<input type="file" class="js-import-input" name="files" accept=".csv" style="display: none">
		</div>
	</div>
	<div class="row">

		<div class="col-xs-12">
			{{#if isProcessing}}
				<p data-i18n="translations.importExport.processing" class="mt-12"/>
			{{/if}}
			{{#if isSuccess}}
				<p data-i18n="translations.importExport.success" class="mt-12"/>
			{{/if}}
			{{#if error}}
				<p data-i18n="translations.importExport.error" class="mt-12"/>
			{{/if}}
		</div>

	</div>
</div>
