<div class="logic-container" data="HEY">
	<div class="logic-type">
		<input class="logic-prefix" name="type-{{selectorId}}" id="AND-{{selectorId}}" type="radio" value="AND" {{#ifEqual
			parsed.prefix 'AND' }}checked {{/ifEqual}} /><label for="AND-{{selectorId}}">AND</label>
		<input class="logic-prefix" name="type-{{selectorId}}" id="OR-{{selectorId}}" type="radio" value="OR" {{#ifEqual
			parsed.prefix 'OR' }}checked {{/ifEqual}} /><label for="OR-{{selectorId}}">OR</label>
	</div>
	<div class="logic-builder">
		<label>IF</label>
		{{#each parsed.terms}}
		<div class="term parsed">
			<label>{{../parsed.prefix}}</label>
			<select class="form-control logic-module" data-selection="{{../selectorId}}" data-index="{{@index}}"
				data-id="{{id}}" data-parent-id="{{@root.parsed.id}}">
				<option value="-">Select Module</option>
				{{#each ../modules}}
				{{#if identifier}}
				<option value="{{identifierNoVersion}}" {{#ifEqual identifierNoVersion ../module}}selected{{/ifEqual}}>
					{{#ifEqual this.class "R4b_Assessment"}}
						{{getAtlasAssessmentTitle this}}:
					{{/ifEqual}} {{#if name.content}}
					{{getLocaleText name.content}} {{/if}} ({{identifier}}) - {{id}}</option>
				{{/if}}
				{{/each}}
			</select>
			<select class="form-control logic-block" data-selection="{{../selectorId}}" data-index="{{@index}}"
				data-id="{{id}}" data-parent-id="{{@root.parsed.id}}">
				<option value="-">Select Block</option>
				{{#each blocks}}
				<option value="{{identifier}}" data-type="{{type}}" {{#ifEqual identifier ../block}}selected{{/ifEqual}}>
					{{identifier}}</option>
				{{/each}}
			</select>
			<select class="form-control logic-operator" data-selection="{{../selectorId}}" data-index="{{@index}}"
				data-id="{{id}}" data-parent-id="{{@root.parsed.id}}">
				<!-- =|!=|<|>|<=|>=|~= -->
				<option value="=" {{#ifEqual operator "=" }}selected{{/ifEqual}}>equals</option>
				<option value="!=" {{#ifEqual operator "!=" }}selected{{/ifEqual}}>does not equal</option>
				<option value="<" {{#ifEqual operator "<" }}selected{{/ifEqual}}>less than</option>
				<option value=">" {{#ifEqual operator ">" }}selected{{/ifEqual}}>more than</option>
				<option value="<=" {{#ifEqual operator "<=" }}selected{{/ifEqual}}>more than and equal</option>
				<option value=">=" {{#ifEqual operator ">=" }}selected{{/ifEqual}}>less than and equal</option>
				<option value="~=" {{#ifEqual operator "~=" }}selected{{/ifEqual}}>approximately</option>
			</select>
			<input class="form-control logic-value" data-selection="{{../selectorId}}" data-index="{{@index}}"
				data-id="{{id}}" data-parent-id="{{@root.parsed.id}}" type="{{valueType}}" value="{{value}}" />
			<button class="button remove-term" data-index="{{@index}}" data-id="{{id}}" data-parent-id="{{@root.parsed.id}}"
				style="min-width:50px"><i class="icon-remove-sign" style="margin-right: 0px;"></i></button>
		</div>
		{{/each}}
		<div class="term new">
			<label>{{parsed.prefix}}</label>
			<select class="form-control logic-module-new" data-selection="{{../selectorId}}"
				data-parent-id="{{@root.parsed.id}}">
				<option value="-">Select Module</option>
				{{#each modules}}
				{{#if identifier}}
				<option value="{{identifierNoVersion}}" {{#ifEqual identifierNoVersion ../newTerm.module}}selected{{/ifEqual}}>
					{{#ifEqual this.class "R4b_Assessment"}}
						{{getAtlasAssessmentTitle this}}:
					{{/ifEqual}} {{#if name.content}}
					{{getLocaleText name.content}} {{/if}} ({{identifier}}) - {{id}}</option>
				{{/if}}
				{{/each}}
			</select>
			<select class="form-control logic-block-new" data-selection="{{../selectorId}}">
				<option value="-">Select Block</option>
				{{#each newTerm.blocks}}
				<option value="{{identifier}}" data-type="{{type}}" {{#ifEqual identifier
					../newTerm.block}}selected{{/ifEqual}}>{{identifier}}</option>
				{{/each}}
			</select>
			<select class="form-control logic-operator-new" data-selection="{{../selectorId}}">
				<!-- =|!=|<|>|<=|>=|~= -->
				<option value="=" {{#ifEqual newTerm.operator "=" }}selected{{/ifEqual}}>equals</option>
				<option value="!=" {{#ifEqual newTerm.operator "!=" }}selected{{/ifEqual}}>does not equal</option>
				<option value="<" {{#ifEqual newTerm.operator "<" }}selected{{/ifEqual}}>less than</option>
				<option value=">" {{#ifEqual newTerm.operator ">" }}selected{{/ifEqual}}>more than</option>
				<option value="<=" {{#ifEqual newTerm.operator "<=" }}selected{{/ifEqual}}>more than and equal</option>
				<option value=">=" {{#ifEqual newTerm.operator ">=" }}selected{{/ifEqual}}>less than and equal</option>
				<option value="~=" {{#ifEqual newTerm.operator "~=" }}selected{{/ifEqual}}>approximately</option>
			</select>
			<input class="form-control logic-value-new new-value" data-selection="{{selectorId}}" type="{{newTerm.valueType}}"
				value="{{newTerm.value}}" />

			{{#ifBorC parsed.prefix 'AND' 'OR'}}
			<button class="button add-new-child" style="min-width:60px" data-parent-id="{{@root.parsed.id}}">{{#ifEqual
				parsed.prefix 'AND'}}OR{{else ifEqual
				parsed.prefix 'OR'}}AND{{/ifEqual}}</button>
			{{/ifBorC}}

			<button class="button add-term" style="min-width:50px" data-parent-id="{{@root.parsed.id}}"><i
					class="icon-ok-sign" style="margin-right: 0px;"></i></button>
		</div>
	</div>
	{{#if parsed.children }}
	<div class="js-children logic-container-children"></div>
	{{/if}}
</div>