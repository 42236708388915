var sponsorPreviewViewTemplate = require('./sponsor-preview-view-template')
var sponsorBloodPreviewViewTemplate = require('./sponsor-blood-preview-view-template')

module.exports = Backbone.View.extend({
	events: {
		'click .js-button': 'linkClick'
	},

	initialize: function(model, sponsorList) {
		this.model = model
		this.sponsorList = sponsorList
		this.template = this.getTemplate()
	},

	getTemplate: function() {
		// If ARC blood, return a different preview
		if (Storm.app.id === 7 && App.system.id === 9) {
			return sponsorBloodPreviewViewTemplate
		}
		return sponsorPreviewViewTemplate
	},

	linkClick: function() {
		var sponsor = this.getSponsor()
		var link = sponsor.get('link')
		if (link && link.destination) {
			var isExternal = link.class === 'ExternalLink' ||
				link.class === 'UriLink'
			if (link.class === 'InternalLink') {
				var url = link.destination

				var internalPageLink = link.destination
				internalPageLink = internalPageLink.replace("cache://", "")
				internalPageLink = internalPageLink.replace(".json", "")
				url = '/apps/' + App.getCurrentApp().id + '/' + internalPageLink

				App.router.navigate(url, {trigger: true})
			} else if (isExternal) {
				window.open(link.destination, '_blank')
			}
		}
	},

	getRenderData: function() {
		if (this.model.get('sponsorship') && this.model.get('sponsorship').models) {
			var sponsor = this.getSponsor()
			if (sponsor) {
				return {
					sponsor: sponsor.toJSON()
				}
			} else if (this.model.get('sponsorship').models[0]) {
				return {
					sponsor: this.model.get('sponsorship').models[0].toJSON()
				}
			}
		}
		return
	},

	getSponsor: function() {
		var sponsorship = this.model.get('sponsorship')
		var sponsorshipObj = null
		if (sponsorship.models[0] && this.sponsorList) {
			var sponsorModel = sponsorship.models[0]
			switch (sponsorModel.get('type')) {
				case 'id':
					sponsorshipObj = this.sponsorList.get(sponsorModel.get('value'))
					break
			}
		}
		return sponsorshipObj
	}
})
