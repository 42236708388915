var productPreviewViewTemplate = require('./product-preview-view-template')

module.exports = Backbone.View.extend({
	tagName: 'div',
	/** @override */
	className: 'product-preview',
	template: productPreviewViewTemplate,
	initialize: function(model, options) {
		this.product = model
		this.productCount = options.productCount
		this.activeIndex = options.activeIndex
	},

	getRenderData: function() {
		var data = this.product
		if (data.toJSON) {
			data = data.toJSON()

			// Only show price if price text is not set
			if (data.priceText && Object.keys(data.priceText.content).length > 0) {
				delete data.price
			} else {
				delete data.priceText
			}
		}
		data.productCount = this.productCount
		data.activeIndex = this.activeIndex
		return data
	}
})
