const regex = /(\d+)\.(.+)\.(\d+)/m // REGEX for getting the individual parts of the identifier for a moudule

var EditorSectionView   = require('editor/editor-section-view'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary          = require('media-library/media-library-view'),
	MediaSelectorView     = require('media-library/media-selector-view'),
	ModuleBlock           = require('./module-block-view'),
	Section               = require('./section-view'),
	AddSectionView        = require('./add-section-view'),
	StormObject           = require('editor/storm-object'),
	LinkSelector          = require('editor/inspector/link-selector'),
	AssessmentModuleList  = require('./units'),
	StormQL               = require('models/stormql'),
	utils                 = require('./atlas-utils'),
	LogicView             = require('./section-logic-view')

module.exports = EditorSectionView.extend({
	className: 'moduleEdit',
	template: require('./module-edit-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .add-image': 'selectImage',
		'click .save': 'save',
		'click .add-section-below': 'addSectionBelow',
		'click .module-logic': 'clickModuleLogic',
		'click .delete-block': 'removeBlock',
		'click .remove-section': 'removeSection',
		'click .back-button': 'goBack',
		'click .visibility': 'toggleModuleLogic',
		'click .js-toggle-translations-checklist': 'toggleTranslationsChecklist',
		'click .detail': 'focusDetail',
		'click .style': 'styleUpdate',
		'input .js-category': 'updateCategory',
		'input .span-title': 'updateTitle',
		'input .time': 'updateTime',
		'input .people': 'updatePeople',
		'input #is-bonus': 'updateBonus',
		'input .js-update-translations': 'updateTranslations',
		'input .module-logic': 'updateModuleLogic',
		'input #module-identifier': 'updateIdentifier',
		'change #module-identifier': 'updateIdentifier',
		'input .js-pdf-intro': 'updatePdfIntro',
		'change .js-pdf-intro': 'updatePdfIntro',
		'change .main-language': 'mainLanguageChange',
		'mousemove .blocks': '_onMouseMove',
		'focus .time': 'updateTimeWidth',
		'focus .people': 'updatePeopleWidth',
		'blur .time': 'updateTimeWidth',
		'blur .people': 'updatePeopleWidth'
	},

	initialize: function(options) {
		App.startLoad()
		this.drake = null
		this.app = options.app
		this.appId = options.appId
		this.id = options.id
		this.childViews = []
		this.toDelete = []
		this.styleUpdating = false
		this.selectorId = Math.random().toString(16).substr(2)

		var requests = []

		// Fetch Languages
		this.localeList = Storm.app.localeList
		requests.push(this.localeList.fetch())

		// Fetch Model
		this.model = StandaloneStormObject.fromProperties({id: options.id})
		requests.push(this.model.fetch())

		// Styles
		this.styles = new StormQL(null, {app: this.app})
		requests.push(this.styles.fetch({data: {class: 'Style'}}))

		// Fetch units
		this.units = new AssessmentModuleList()
		this.listenTo(this.units, 'sync', this.renderLogicViews, this)

		// Fetch categories
		this.categories = new StormQL(null, {app: this.app})
		requests.push(this.categories.fetch({data: {class: 'R4b_Category'}}))

		// List of module block types
		var moduleBlocksListPromise = this.getBlockModules()
		requests.push(moduleBlocksListPromise)

		// List of assessment block types
		var assessmentBlocksListPromise = this.getBlockAssessments()
		requests.push(assessmentBlocksListPromise)

		this.needsSaving = false
		this.listenTo(this.model, 'change:image', this.updateImagePreview, this)
		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		App.stopLoad()
		this.language = 'en'
		if (this.localeList.at(0)) {
			this.language = this.localeList.at(0).get('code')
		}
		this.model.requestLock(function() {
			this.render()
		}.bind(this))
	},

	getPageTitle: function() {
		return $.t('r4b.title')
	},

	getRenderData: function() {
		var blocks = this.moduleBlocksList
		var isModule = true
		if (this.model.get('class') === 'R4b_Assessment') {
			blocks = this.assessmentBlocksList
			isModule = false
		}
		var identifierArr = this.getIdentifierArr()

		return {
			model: this.model.toJSON(),
			identifierArr: identifierArr,
			blocks: blocks,
			isModule: isModule,
			languages: this.localeList.toJSON(),
			language: this.language,
			appId: this.appId,
			categories: this.categories.toJSON()
		}
	},

	addSectionBelow: function(e) {
		// Get index
		var index = parseInt($(e.currentTarget).data('index'), 10)
		// Create a new section and add to collection...
		var newSectionName = this.model.get('class') === 'R4b_Assessment' ? 'R4b_AssessmentSection' : 'R4b_ModuleSection'
		var section = StormObject.fromClassName(newSectionName, this.model.get('pageId'))
		this.model.get('children').add(section, {at: index + 1})
		// Re-Render!
		this.renderBlocks()
	},

	mainLanguageChange: function() {
		this.language = $('.main-language').val()
		this.render()
	},

	updateTimeWidth: function() {
		if ($('#time').attr('style')) {
			$('#time').attr('style', '')
		} else {
			$('#time').attr('style', 'width:200px;')
		}
	},

	updatePeopleWidth: function() {
		if ($('#people').attr('style')) {
			$('#people').attr('style', '')
		} else {
			$('#people').attr('style', 'width:200px;')
		}
	},

	focusDetail: function(e) {
		var id = $(e.currentTarget).attr('id')
		var tagName = e.target.tagName
		if (tagName !== 'H4') {
			$('#' + id + ' .detail-content h4').focus()
		}
	},

	getStyleBySelectorId: function(identifier) {
		var style
		this.styles.forEach(function(styleModel) {
			var selector = styleModel.get('selector')
			if (selector.identifier === identifier) {
				// Matches!
				style = styleModel
			}
		})
		return style
	},

	// TODO: Abstact this out into own view along with the style view within the module block view templace on a text object...
	styleUpdate: function(e) {
		if (!this.styleUpdating) {
			this.styleUpdating = true
			// Get object id
			var objectId = $(e.currentTarget).attr('data-id')
			var needsLocking = false
			// If no object id do nothing for now.. (TODO: Do some thinking on how to get this to work on new objects...)
			if (objectId) {
				// Get style to add
				var styleType = $(e.currentTarget).data('style')
				if (styleType) {
					// Does a style object already exist?
					var style = this.getStyleBySelectorId(objectId)

					if (style) {
						// If so add it to the current one...
						needsLocking = true
						// Lock
					} else {
						// Else
						// Create new style object
						var styleSelector = StandaloneStormObject.fromClassName('StyleSelector')
						// Set selector and style
						styleSelector.set('identifier', objectId)
						styleSelector.set('type', 'id')
						style = StandaloneStormObject.fromClassName('Style')
						style.set('selector', styleSelector)
					}
					// Set style property
					var styleProperty = StandaloneStormObject.fromClassName('StyleProperty')
					var key, value
					var styleToRemove = style.get('properties').filter(function(prop) {
						return prop.get('value') === styleType
					})
					// Need to remove (if prop already exists) or add (if it doesn't)
					if (styleToRemove[0]) {
						// Get ID if it exists
						if (styleToRemove[0].get('id')) {
							// If it does lock parent and delete that property model
							style.requestLock(function() {
								styleToRemove[0].destroy().then(function() {
									// Completed
									style.requestUnlock()
									this.styleUpdating = false
									this.renderBlocks()
								}.bind(this))
							}.bind(this))
						} else {
							delete styleToRemove[0]
						}
					} else {
						switch (styleType) {
							case 'bold':
								key = 'font-weight'
								value = styleType
								break
							case 'italic':
								key = 'font-style'
								value = styleType
								break
							case 'underline':
								key = 'text-decoration'
								value = styleType
								break
						}
						styleProperty.set({
							key: key,
							value: value
						})
						if (style.get('pageId')) {
							styleProperty.set({pageId: style.get('pageId')})
						}
						style.get('properties').push(styleProperty)
						this.styles.add(style)
						//	Save? or add to stuff to be saved?
						if (needsLocking) {
							style.requestLock(function() {
								style.save(null, {appId: this.app.id}).then(function() {
									style.requestUnlock().then(function() {
										this.styleUpdating = false
										this.renderBlocks()
									}.bind(this))
								}.bind(this))
							}.bind(this))
						} else {
							style.save(null, {appId: this.app.id}).then(function() {
								this.styleUpdating = false
								this.renderBlocks()
							}.bind(this))
						}
					}
				}
			}
		}
	},

	getIdentifierArr: function() {
		var m
		var str = this.model.get('identifier')
		if ((m = regex.exec(str)) !== null) {
			// The result can be accessed through the `m`-variable.
			// m.forEach((match, groupIndex) => {
			// 	console.log(`Found match, group ${groupIndex}: ${match}`)
			// });
			return m
		}
	},

	afterRender: function() {
		var addSectionView = new AddSectionView()
		this.childViews.push(addSectionView)
		this.addSectionEl = addSectionView.render().el

		this.renderBlocks()
		// Only render logic view if not an assessment
		if (this.model.get('class') === 'R4b_Module') {
			this.renderModuleLogic()
		}

		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		} else {
			// Enable Identifiers if disabled
			$('.identifier').prop('disabled', false)
		}

		this.startDragAndDrop()
	},

	renderModuleLogic: function() {
		// Render Multi Link Selector
		var logic = new LogicView({
			model: this.model,
			appId: this.appId,
			selectorId: this.selectorId,
			parent: this,
			isModuleLogic: true,
			language: this.language
		})

		this.listenTo(logic, 'change', function() {
			this.parent.updateSaving()
		}, this)

		$('.module-logic-builder').html(logic.render().el)
	},

	/**
	 * Initialises the drag and drop plugin for dragging new items onto the
	 * page.
	 */
	startDragAndDrop: function() {
		this.stopDragAndDrop()

		var sideBarInputItems = $('.input-holder')[0],
			sideBarMediaItems = $('.input-holder')[1],
			blocks = $('.section-blocks').toArray(),
			newSection = $('.add-section')[0]

		this.drake = dragula([sideBarInputItems, sideBarMediaItems, blocks, newSection], {
			copy: function(el, source) {
				return source === sideBarInputItems || source === sideBarMediaItems
			},
			// copy: true,
			accepts: function(el, target) {
				return target !== sideBarInputItems && target !== sideBarMediaItems
			},
			isContainer: function(el) {
				return el.classList.contains('section-blocks')
			}
		})

		this.drake.on('drop', this.handleObjectDrop.bind(this))
		this.drake.on('dragend', this.renderBlocks.bind(this))
	},

	/**
	 * Tears down the drag and drop plugin for dragging new items onto the page.
	 */
	stopDragAndDrop: function() {
		if (this.drake) {
			this.drake.destroy()
			this.drake = null
		}
	},

	/**
	 * Handles a drop event from the drag and drop plugin. Inserts a new model
	 * on the current page to match the dropped template.
	 * @param {HTMLElement} el The DOM element being dropped.
	 * @param {HTMLElement} target The DOM element in which {@link el} is being
	 *     dropped.
	 * @param {HTMLElement} source The DOM element from which {@link el} has
	 *     been removed.
	 * @param {HTMLElement} sibling The DOM element which {@link el} has been
	 *     dropped before, or {@code null} if {@link el} has been dropped at
	 *     the end of the list.
	 */
	handleObjectDrop: function(el, target, source) {
		if (!target) {
			return
		}
		var isNewModel = false
		// Get section to go into..
		var targetIndex = target.getAttribute('data-index')
		var sourceIndex = source.getAttribute('data-index')
		var targetSelection = target.getAttribute('data-selection')

		// Create new object of the specified type.
		var className = $(el).data('classname')
		// Get sections
		var sections = this.model.get('children')
		var newModel

		// If a new block
		if (className) {
			isNewModel = true

			// Create new model and insert into right section
			var	pageId    = this.model.get('pageId')
			newModel  = StormObject.fromClassName(className, pageId)
			var section
				// Drop the new model into the right section
			if (targetIndex) {
				section = sections.at(targetIndex)
			} else if (target.classList.contains('add-section')) {
				// Create a new section...
				var newSectionName = this.model.get('class') === 'R4b_Assessment' ? 'R4b_AssessmentSection' : 'R4b_ModuleSection'
				section = StormObject.fromClassName(newSectionName, pageId)
				sections.add(section)
			}
			if (section) {
				section.get('children').add(newModel)
				// Get the view of the new model just added and render
				this.addBlock(newModel)
			}
		}

		// Get model just moved
		var model
		if (isNewModel) {
			sourceIndex = targetIndex
		} else {
			var modelId = $(el).attr('id')
			// Get the model from the source section...
			model = sections.at(sourceIndex).get('children').get(modelId)
		}

		$(el).addClass('moduleBlock')

		// Get the indicies of the model and it's new position
		if (targetIndex && sourceIndex) {
			var newBlockIndex = $.inArray(el, $('.section-blocks[data-index=' + targetIndex + '][data-selection=' + targetSelection + '] .moduleBlock').get())
			var currentBlockIndex = $(el).data('index')
			if (isNewModel) {
				model = newModel
				// New as in dragged from the sidebar
				currentBlockIndex = sections.at(sourceIndex).get('children').length - 1
			}

			if (currentBlockIndex === -1 || currentBlockIndex === undefined) {
				currentBlockIndex = newBlockIndex
			}
			// Move the model to the right place
			this.moveChildModel(currentBlockIndex, newBlockIndex, sourceIndex, targetIndex)
		}

		if (model) {
			model.trigger('change')
			if (className === "R4b_AnimatedMediaModuleBlock") {
				// save if the new block is R4b_AnimatedMediaModuleBlock
				// this will create the R4b_AnimatedMediaModuleBlock object and ID needed to add AnimationImages too.
				this.save()
			}
		}
		$(el).remove()
	},

	showMediaSelect: function(model, mediaType) {
		this.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: model,
			mediaType: mediaType
		})

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	selectImage: function(e) {
		var property = $(e.currentTarget).data('property')
		var model = property ? this.model.get(property) : this.model.get('image')

		var propertyComponents = property.split('..')
		var mediaType = MediaLibrary.types.IMAGE

		if (propertyComponents[propertyComponents.length - 1] === 'icon') {
			mediaType = MediaLibrary.types.ICON
		}

		// Show media library
		this.showMediaSelect(model, mediaType)

		this.mediaLibrary.on('change', function() {
			this.model.trigger('change change:image', this.model)
		}, this)
	},

	updateImagePreview: function() {
		var style = Handlebars.helpers.getBackgroundImageStyle(this.model.get('image').src, 'cover')
		$('.add-image').attr('style', style)
	},

	renderBlocks: function() {
		this.childViews = []
		this.sectionCount = 0
		this.sections = []
		$('.blocks').empty()
		if (this.model.get('children')) {
			this.model.get('children').forEach(function(childModel) {
				this.sectionCount++
				this.addBlock(childModel)
			}.bind(this))
		}
		this.renderChildViews()
		// Hide developer-only controls.
		if (!App.developerMode) {
			// Remove code Blocks
			this.$('code').remove()
		}
	},

	addBlock: function(childModel) {
		var selectorId = Math.random().toString(16).substr(2)
		var view
		if (childModel.get('class') === "R4b_AssessmentSection" || childModel.get('class') === "R4b_ModuleSection" || childModel.get('class') === "R4b_TaskModuleSection" || childModel.get('class') === "R4b_BcpModuleSection") {
			// Get index of model...
			var index = this.model.get('children').indexOf(childModel)
			view = new Section({
				model: childModel,
				appId: this.appId,
				parent: this,
				selectorId: selectorId,
				sectionNumber: this.sectionCount,
				language: this.language,
				index: index
			})
			this.sections.push(childModel)
		} else {
			view = new ModuleBlock({
				model: childModel,
				appId: this.appId,
				parent: this,
				selectorId: selectorId,
				language: this.language
			})
		}

		this.listenTo(childModel, 'change', this.updateSaving)
		this.childViews.push(view)
		return view
	},

	renderChildViews: function() {
		// Render each of the child views
		this.childViews.forEach(function(view, index) {
			this.renderChildView(view, index)
		}.bind(this))
		// Add the 'Add section here' view
		$('.blocks').append(this.addSectionEl)
		this.removeAllToDelete()
	},

	renderLogicViews: function() {
		// render logic for child views
		this.childViews.forEach(function(view) {
			var className = view.model.get('class')
			if (className === "R4b_AssessmentSection" || className === "R4b_ModuleSection" || className === "R4b_TaskModuleSection" || className === "R4b_BcpModuleSection") {
				view.renderLogic()
			}
		})

		// render logic for top module section
		// this resolves this bug: https://3sidedcube.atlassian.net/browse/GDPCRFB2-817
		this.renderModuleLogic()
	},

	renderChildView: function(view, index) {
		$('.blocks').append(view.render().el)
		$('.blocks').children().last().attr('data-index', index)
		var className = view.model.get('class')
		if (className === "R4b_AssessmentSection" || className === "R4b_ModuleSection" || className === "R4b_TaskModuleSection" || className === "R4b_BcpModuleSection") {
			view.toggleTaskContent()
			view.renderBlocks()
		} else if (view.model.get('class') === "R4b_LinkMediaModuleBlock") {
			// Move to Section view?
			var linkSelector = new LinkSelector({
				link: view.model.get('data'),
				titleDisabled: false,
				language: this.language,
				appId: this.appId
			})
			this.listenTo(linkSelector, 'change', function() {
				this.updateSaving()
			}, this)
			$('.link-selector[data-id=' + view.selectorId + ']').html(linkSelector.render().el)
		}
	},

	updateSaving: function() {
		this.needsSaving = true
		$('.save-status').text(Handlebars.helpers.t('r4b.modules.unsaved'))
		$('.save').attr('needsSaving', 'true')
	},

	getBlockModules: function() {
		return new Promise(
			function(resolve, reject) {
				$.ajax({
					url: App.apiRoot + 'classes/R4B_ModuleBlock/valid',
					type: 'GET',

					headers: App.session.getHeadersObject(),

					success: function(data) {
						this.moduleBlocksList = data
						resolve()
					}.bind(this),

					error: function(jqXHR) {
						reject(jqXHR)
					}
				})
			}.bind(this)
		)
	},

	getBlockAssessments: function() {
		return new Promise(
			function(resolve, reject) {
				$.ajax({
					url: App.apiRoot + 'classes/R4B_AssessmentBlock/valid',
					type: 'GET',

					headers: App.session.getHeadersObject(),

					success: function(data) {
						this.assessmentBlocksList = data
						resolve()
					}.bind(this),

					error: function(jqXHR) {
						reject(jqXHR)
					}
				})
			}.bind(this)
		)
	},

	/**
	 * [moveChildModel move a model between collections and to the right index]
	 * @param  {integer} originalBlockIndex original index of model in collection
	 * @param  {integer} newBlockIndex new index for the model in the collection to go to
	 * @param  {integer} sourceSectionIndex index for the source section (where the model is being moved from)
	 * @param  {integer} targetSectionIndex index for the target section (where the model is being moved to)
	 * @returns {undefined}
	 */
	moveChildModel: function(originalBlockIndex, newBlockIndex, sourceSectionIndex, targetSectionIndex) {
		var sections = this.model.get('children')
		var tempModel = sections.at(sourceSectionIndex).get('children').at(originalBlockIndex)
		var tempSourceCollection = sections.at(sourceSectionIndex).get('children')
		var tempTargetCollection = sections.at(targetSectionIndex).get('children')
		var newModel = tempModel.clone()
		if (!tempModel.isNew() && sourceSectionIndex !== targetSectionIndex) {
			this.stripIds(newModel)
			this.addToDelete(tempModel.get('id'), 'block')
		}
		if (sourceSectionIndex === targetSectionIndex || (tempModel.isNew() && sourceSectionIndex !== targetSectionIndex)) {
			// If we are just moving in the same section OR the model is new (with no id) and the sections are different
			// Remove it from the source
			tempSourceCollection.remove(tempModel)
		}

		// Add it to the target at the right index
		tempTargetCollection.add(newModel, {at: newBlockIndex})
	},

	stripIds: function(object) {
		utils.stripIds(object, false, this.styles, false)
	},

	save: function() {
		App.startLoad()
		var self = this

		// Save > Do Deletions > Do Parsing > Clean up
		this.model.save().then(function() {
			// Find any styles that need updating
			self.doDeletes()
		})
	},

	doDeletes: function() {
		var self = this
		// Delete all 'To deletes'
		if (self.toDelete.length) {
			var i = 0
			_.each(self.toDelete, function(object) {
				// Get model
				var model
				if (object.type === 'block') {
					model = self.getBlockModel(object.id)
				} else if (object.type === 'section') {
					model = self.getSectionModel(object.id)
				} else if (object.type === 'fullModel') { // The whole model has been pushed here instead of the ID
					model = object.id
				}
				if (model) {
					model.destroy().then(function() {
						i++
						// After deletion of all models stopload
						if (i === self.toDelete.length) {
							// Empty
							self.parseStyles()
						}
					})
				}
			})
		} else {
			self.parseStyles()
		}
	},

	parseStyles: function() {
		var self = this
		this.countFinished = 0
		this.model.fetch().then(function() {
			// Fetched model in parsing styling
			// Parse any styling objects and lock > save > unlock them.
			if (utils.styledObjects) {
				if (utils.styledObjects.length > 0) {
					// There's styled objects, utils.styledObjects
					utils.styledObjects.forEach(function(model) {
						var style = model.style
						var selector = style.get('selector')

						if (selector instanceof Backbone.Model) {
							selector.set('identifier', model.toJSON().id)
						} else {
							selector.identifier = model.toJSON().id
						}
						self.saveStyle(style)
					})
				} else {
					self.finishSave()
				}
			} else {
				self.finishSave()
			}
		})
	},

	saveStyle: function(style) {
		var self = this
		style.requestLock(function() {
			style.save().then(function() {
				style.requestUnlock().then(function() {
					self.countFinished++
					// We've finished everything. We can now finally finish. It's been hell.
					self.finishSave()
				})
			})
		})
	},

	finishSave: function() {
		// If we haven't finished out saving from before don't finish up...
		if (utils.styledObject) {
			if (this.countFinished !== utils.styledObjects.length) {
				return
			}
		}
		App.stopLoad()
		$('.save-status').text(Handlebars.helpers.t('r4b.modules.saved'))
		$('.save').attr('needsSaving', 'false')
		this.toDelete = []
		utils.styledObjects = []
		this.renderBlocks()
	},

	getBlockModel: function(id) {
		var returnModel
		this.model.get('children').forEach(function(model) {
			model.get('children').forEach(function(model) {
				if (model.get('id') === id) {
					returnModel = model
					return
				}
			})
			if (returnModel) {
				return
			}
		})
		return returnModel
	},

	getSectionModel: function(id) {
		var returnModel
		this.model.get('children').forEach(function(model) {
			if (model.get('id') === id) {
				returnModel = model
				return
			}
		})
		return returnModel
	},

	/**
	 * Clones a section, used by the section children in section-view.js
	 * @param  {model} model section model to clone
	 * @returns {model}      the cloned model
	 */
	cloneSection: function(model) {
		// Get section position
		var sectionPos = 0
		this.model.get('children').each(function(section, index) {
			if (model === section) {
				sectionPos = index
				return
			}
		})

		var newSection = utils.copyModel(model, false, this.styles, false)
		// Styled objects are now mapped here if there are any.. utils.styledObjects
		// These should be parsed and saved when the user saves...

		// Add to new section to collection
		this.model.get('children').add(newSection, {at: sectionPos})
		return newSection
	},

	updateTitle: function() {
		var value = $('.span-title').html()
		var text = this.model.get('name')
		text.content[this.language] = value
		this.updateSaving()
	},

	updateBonus: function() {
		var value = $('#is-bonus').is(':checked')
		this.model.set('bonus', value)
		this.updateSaving()
	},

	updateCategory: function() {
		var value = parseInt($('.js-category').val(), 10)
		if (value > -1) {
			this.model.set('categoryId', value)
		} else {
			this.model.set('categoryId', -1)
		}

		this.updateSaving()
	},

	updateTranslations: function() {
		var translatedLanguages = []

		$('.js-update-translations').each(function(index, translation) {
			if ($(translation).is(':checked')) {
				var value = $(translation).val()
				translatedLanguages.push(value)
			}
		})
		this.model.set('translations', translatedLanguages)
		this.updateSaving()
	},

	updateTime: function() {
		var value = parseInt($('.time').html(), 10)
		this.model.set('time', value * 60)
		this.updateSaving()
	},

	updatePeople: function() {
		var value = parseInt($('.people').html(), 10)
		this.model.set('people', value)
		this.updateSaving()
	},

	updateModuleLogic: function() {
		var value = $('.module-logic').html()
		this.model.set('logic', value)
		this.updateSaving()
	},

	clickModuleLogic: function(e) {
		e.preventDefault()
		e.stopPropagation()
	},

	toggleModuleLogic: function() {
		if (!this.units.fetched) {
			App.startLoad()
			this.units.fetch({
				type: 'POST',
				data: JSON.stringify({
					class: "R4b_Unit",
					appId: this.appId,
					inherit: true,
					type: "cms"
				})
			}).then(function() {
				App.stopLoad()
				this.toggleToolsUi()
			}.bind(this))
		} else {
			this.toggleToolsUi()
		}
	},

	toggleToolsUi: function() {
		$('.module-logic-builder').slideToggle()
		// Change class of open close icon
		$('.visibility i').toggleClass('icon-eye-open').toggleClass('icon-eye-close')
	},

	toggleTranslationsChecklist: function() {
		$('.js-translations-checklist').slideToggle()
		$('.js-toggle-translations-checklist').toggleClass('is-toggled')
	},

	updateIdentifier: function(e) {
		var formattedIdentifier = $(e.currentTarget).html().replace(/[.,\s]/g, '')
		$(e.currentTarget).html(formattedIdentifier)
		var previousIdentifer = this.model.get('identifier')
		var newIdentifer = this.model.get('level') + '.' + formattedIdentifier + '.' + this.model.get('version')

		if (previousIdentifer !== newIdentifer) {
			this.model.set('identifier', this.model.get('level') + '.' + formattedIdentifier + '.' + this.model.get('version'))
			this.updateSaving()
		}
	},

	updatePdfIntro: function(e) {
		var value = this.updateEditable(e.currentTarget)
		if (!this.model.get('pdf_intro')) {
			this.model.set('pdf_intro', App.getClassStructure('Text', this.model.get('pageId')))
		}
		this.model.set('pdf_intro..content..' + this.language, value)
		this.updateSaving()
	},

	updateEditable: function(element) {
		var caretPosition = this.getCaretPosition(element)
		var value = this.stripTags(element)
		$(element).html(value)
		this.setCaretPosition(element, caretPosition)
		return value
	},

	stripTags: function(element) {
		// inner Text does not work in firefox so...
		if (element.innerText) {
			return element.innerText
		} else if (element.html) {
			return element.html().replace(/<br>/g, '\n')
		}
		return ''
	},

	getCaretPosition: function(element) {
		// http://stackoverflow.com/questions/16736680/get-caret-position-in-contenteditable-div-including-tags
		var caretOffset = 0
		if (typeof window.getSelection !== "undefined") {
			var range = window.getSelection().getRangeAt(0)
			var preCaretRange = range.cloneRange()
			preCaretRange.selectNodeContents(element)
			preCaretRange.setEnd(range.endContainer, range.endOffset)
			caretOffset = preCaretRange.toString().length
		} else if (typeof document.selection !== "undefined" && document.selection.type !== "Control") {
			var textRange = document.selection.createRange()
			var preCaretTextRange = document.body.createTextRange()
			preCaretTextRange.moveToElementText(element)
			preCaretTextRange.setEndPoint("EndToEnd", textRange)
			caretOffset = preCaretTextRange.text.length
		}
		return caretOffset
	},

	setCaretPosition: function(editable, position) {
		// http://stackoverflow.com/questions/40632975/set-caret-position-in-a-content-editable-element
		var range = document.createRange()
		var sel = window.getSelection()
		try {
			range.setStart(editable.childNodes[0], position)
			range.collapse(true)
			sel.removeAllRanges()
			sel.addRange(range)
			editable.focus()
		} catch (error) {
			// Empty childnode or pasting in unrecognisable stuff..
			return
		}
	},

	removeBlock: function(e) {
		var id = parseInt($(e.currentTarget).attr('data-id'), 10)
		var index = parseInt($(e.currentTarget).attr('data-index'), 10)
		var sectionIndex = parseInt($(e.currentTarget).attr('data-sectionindex'), 10)
		var model = this.model.get('children').at(sectionIndex).get('children').at(index)
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				// this.addToDelete(id)
				if (id) {
					this.addToDelete(id, 'block')
					this.removeDomBlock(id)
				} else {
					this.model.get('children').at(sectionIndex).get('children').remove(model)
					this.renderBlocks()
				}
			}
			this.updateSaving()
		}.bind(this))
	},

	removeSection: function(e) {
		var id = parseInt($(e.currentTarget).attr('data-id'), 10)
		var index = parseInt($(e.currentTarget).attr('data-index'), 10)
		var model = this.model.get('children').at(index)

		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				if (id) {
					this.addToDelete(id, 'section')
					this.removeSectionBlock(id)
				} else {
					this.model.get('children').remove(model)
					this.renderBlocks()
				}
				this.updateSaving()
			}
		}.bind(this))
	},

	removeDomBlock: function(id) {
		$('.moduleBlock[id="' + id + '"]').remove()
	},

	removeSectionBlock: function(id) {
		$('.sectionBlock[id="' + id + '"]').remove()
	},

	removeAllToDelete: function() {
		this.toDelete.forEach(function(object) {
			if (object.type === 'section') {
				this.removeSectionBlock(object.id)
			} else if (object.type === 'block') {
				this.removeDomBlock(object.id)
			}
		}.bind(this))
	},

	addToDelete: function(id, type) {
		var exists = false
		this.toDelete.forEach(function(object) {
			if (object.id === id) {
				exists = true
			}
		})
		if (exists) {
			return
		}
		this.toDelete.push({id: id, type: type})
	},

	addSectionToDelete: function(id) {
		// Find model in collections...
		this.model.get('children').forEach(function(model) {
			if (model.get('id') === id) {
				this.toSectionDelete.push(model)
			}
		}.bind(this))
	},

	deleteBlock: function(model) {
		App.startLoad()
		model.destroy().then(function() {
			App.stopLoad()
		})
	},

	goBack: function() {
		this.drake.destroy()
		this.model.requestUnlock().then(function() {
			App.router.navigate('/apps/' + this.appId + '/atlas_admin', {trigger: true})
		}.bind(this))
	},

	// Scrolling for dragula https://github.com/bevacqua/dragula/issues/121: TODO Fix
	_onMouseMove: function(e) {
		this._pageY = e.pageY
		if (this.drake) {
			if (this.drake.dragging) {
				// scroll while drag
				var y = this._pageY
				var container = document.querySelector('body')
				var containerBottom = container.offsetTop + container.offsetHeight
				var containerTop = container.offsetTop

				if (containerBottom - y < 120) {
					this._scrollDown(container, y)
				} else if (containerTop + y < 120) {
					this._scrollUp(container, y)
				}
			}
		}
	},

	_scrollDown: function(container, pageY) {
		if (this.drake) {
			if (this.drake.dragging && pageY === this._pageY) {
				container.scrollTop += 5
				setTimeout(this._scrollDown.bind(this, container, pageY), 20)
			}
		}
	},

	_scrollUp: function(container, pageY) {
		if (this.drake) {
			if (this.drake.dragging && pageY === this._pageY) {
				container.scrollTop -= 5
				setTimeout(this._scrollUp.bind(this, container, pageY), 20)
			}
		}
	}
})
