{{#ifExists sponsor.feature}}
	<img src="{{getPreviewUrl sponsor.feature}}"/>
{{/ifExists}}
<div class="text-center">
	{{#ifExists sponsor.type}}
		{{#ifEqual sponsor.type 'tag'}}
			Sponsorship tag: {{sponsor.value}}
		{{/ifEqual}}
	{{/ifExists}}
</div>
