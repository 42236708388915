module.exports =
[{
	iso2: "aa",
	native: "Afaraf"
}, {
	iso2: "ab",
	native: "аҧсуа бызшәа, аҧсшәа"
}, {
	iso2: "ae",
	native: "avesta"
}, {
	iso2: "af",
	native: "Afrikaans"
}, {
	iso2: "ak",
	native: "Akan"
}, {
	iso2: "am",
	native: "አማርኛ"
}, {
	iso2: "an",
	native: "aragonés"
}, {
	iso2: "ar",
	native: "العربية"
}, {
	iso2: "as",
	native: "অসমীয়া"
}, {
	iso2: "av",
	native: "авар мацӀ, магӀарул мацӀ"
}, {
	iso2: "ay",
	native: "aymar aru"
}, {
	iso2: "az",
	native: "azərbaycan dili"
}, {
	iso2: "ba",
	native: "башҡорт теле"
}, {
	iso2: "be",
	native: "беларуская мова"
}, {
	iso2: "bg",
	native: "български език"
}, {
	iso2: "bh",
	native: "भोजपुरी"
}, {
	iso2: "bi",
	native: "Bislama"
}, {
	iso2: "bm",
	native: "bamanankan"
}, {
	iso2: "bn",
	native: "বাংলা"
}, {
	iso2: "bo",
	native: "བོད་ཡིག"
}, {
	iso2: "br",
	native: "brezhoneg"
}, {
	iso2: "bs",
	native: "bosanski jezik"
}, {
	iso2: "ca",
	native: "català"
}, {
	iso2: "ce",
	native: "нохчийн мотт"
}, {
	iso2: "ch",
	native: "Chamoru"
}, {
	iso2: "co",
	native: "corsu, lingua corsa"
}, {
	iso2: "cr",
	native: "ᓀᐦᐃᔭᐍᐏᐣ"
}, {
	iso2: "cs",
	native: "čeština, český jazyk"
}, {
	iso2: "cu",
	native: "ѩзыкъ словѣньскъ"
}, {
	iso2: "cv",
	native: "чӑваш чӗлхи"
}, {
	iso2: "cy",
	native: "Cymraeg"
}, {
	iso2: "da",
	native: "dansk"
}, {
	iso2: "de",
	native: "Deutsch"
}, {
	iso2: "dv",
	native: "ދިވެހި"
}, {
	iso2: "dz",
	native: "རྫོང་ཁ"
}, {
	iso2: "ee",
	native: "Eʋegbe"
}, {
	iso2: "el",
	native: "ελληνικά"
}, {
	iso2: "en",
	native: "English"
}, {
	iso2: "eo",
	native: "Esperanto"
}, {
	iso2: "es",
	native: "Español"
}, {
	iso2: "et",
	native: "eesti, eesti keel"
}, {
	iso2: "eu",
	native: "euskara, euskera"
}, {
	iso2: "fa",
	native: "فارسی"
}, {
	iso2: "ff",
	native: "Fulfulde, Pulaar, Pular"
}, {
	iso2: "fi",
	native: "suomi, suomen kieli"
}, {
	iso2: "fj",
	native: "vosa Vakaviti"
}, {
	iso2: "fo",
	native: "føroyskt"
}, {
	iso2: "fr",
	native: "français, langue française"
}, {
	iso2: "fy",
	native: "Frysk"
}, {
	iso2: "ga",
	native: "Gaeilge"
}, {
	iso2: "gd",
	native: "Gàidhlig"
}, {
	iso2: "gl",
	native: "galego"
}, {
	iso2: "gn",
	native: "Avañe'ẽ"
}, {
	iso2: "gu",
	native: "ગુજરાતી"
}, {
	iso2: "gv",
	native: "Gaelg, Gailck"
}, {
	iso2: "ha",
	native: "(Hausa) هَوُسَ"
}, {
	iso2: "he",
	native: "עברית"
}, {
	iso2: "hi",
	native: "हिन्दी, हिंदी"
}, {
	iso2: "ho",
	native: "Hiri Motu"
}, {
	iso2: "hr",
	native: "hrvatski jezik"
}, {
	iso2: "ht",
	native: "Kreyòl ayisyen"
}, {
	iso2: "hu",
	native: "magyar"
}, {
	iso2: "hy",
	native: "Հայերեն"
}, {
	iso2: "hz",
	native: "Otjiherero"
}, {
	iso2: "ia",
	native: "Interlingua"
}, {
	iso2: "id",
	native: "Bahasa Indonesia"
}, {
	iso2: "ie",
	native: "Originally called Occidental; then Interlingue after WWII"
}, {
	iso2: "ig",
	native: "Asụsụ Igbo"
}, {
	iso2: "ii",
	native: "ꆈꌠ꒿ Nuosuhxop"
}, {
	iso2: "ik",
	native: "Iñupiaq, Iñupiatun"
}, {
	iso2: "io",
	native: "Ido"
}, {
	iso2: "is",
	native: "Íslenska"
}, {
	iso2: "it",
	native: "Italiano"
}, {
	iso2: "iu",
	native: "ᐃᓄᒃᑎᑐᑦ"
}, {
	iso2: "ja",
	native: "日本語 (にほんご)"
}, {
	iso2: "jv",
	native: "ꦧꦱꦗꦮ, Basa Jawa"
}, {
	iso2: "ka",
	native: "ქართული"
}, {
	iso2: "kg",
	native: "Kikongo"
}, {
	iso2: "ki",
	native: "Gĩkũyũ"
}, {
	iso2: "kj",
	native: "Kuanyama"
}, {
	iso2: "kk",
	native: "қазақ тілі"
}, {
	iso2: "kl",
	native: "kalaallisut, kalaallit oqaasii"
}, {
	iso2: "km",
	native: "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ"
}, {
	iso2: "kn",
	native: "ಕನ್ನಡ"
}, {
	iso2: "ko",
	native: "한국어"
}, {
	iso2: "kr",
	native: "Kanuri"
}, {
	iso2: "ks",
	native: "कश्मीरी, كشميري‎"
}, {
	iso2: "ku",
	native: "Kurdî, كوردی‎"
}, {
	iso2: "kv",
	native: "коми кыв"
}, {
	iso2: "kw",
	native: "Kernewek"
}, {
	iso2: "ky",
	native: "Кыргызча, Кыргыз тили"
}, {
	iso2: "la",
	native: "latine, lingua latina"
}, {
	iso2: "lb",
	native: "Lëtzebuergesch"
}, {
	iso2: "lg",
	native: "Luganda"
}, {
	iso2: "li",
	native: "Limburgs"
}, {
	iso2: "ln",
	native: "Lingála"
}, {
	iso2: "lo",
	native: "ພາສາລາວ"
}, {
	iso2: "lt",
	native: "lietuvių kalba"
}, {
	iso2: "lu",
	native: "Tshiluba"
}, {
	iso2: "lv",
	native: "latviešu valoda"
}, {
	iso2: "mg",
	native: "fiteny malagasy"
}, {
	iso2: "mh",
	native: "Kajin M̧ajeļ"
}, {
	iso2: "mi",
	native: "te reo Māori"
}, {
	iso2: "mk",
	native: "македонски јазик"
}, {
	iso2: "ml",
	native: "മലയാളം"
}, {
	iso2: "mn",
	native: "Монгол хэл"
}, {
	iso2: "mr",
	native: "मराठी"
}, {
	iso2: "ms",
	native: "بهاس ملايو‎"
}, {
	iso2: "mt",
	native: "Malti"
}, {
	iso2: "my",
	native: "ဗမာစာ"
}, {
	iso2: "na",
	native: "Dorerin Naoero"
}, {
	iso2: "nb",
	native: "Norsk bokmål"
}, {
	iso2: "nd",
	native: "isiNdebele"
}, {
	iso2: "ne",
	native: "नेपाली"
}, {
	iso2: "ng",
	native: "Owambo"
}, {
	iso2: "nl",
	native: "Nederlands, Vlaams"
}, {
	iso2: "nn",
	native: "Norsk nynorsk"
}, {
	iso2: "no",
	native: "Norsk"
}, {
	iso2: "nr",
	native: "isiNdebele"
}, {
	iso2: "nv",
	native: "Diné bizaad"
}, {
	iso2: "ny",
	native: "chiCheŵa, chinyanja"
}, {
	iso2: "oc",
	native: "occitan, lenga d'òc"
}, {
	iso2: "oj",
	native: "ᐊᓂᔑᓈᐯᒧᐎᓐ"
}, {
	iso2: "om",
	native: "Afaan Oromoo"
}, {
	iso2: "or",
	native: "ଓଡ଼ିଆ"
}, {
	iso2: "os",
	native: "ирон æвзаг"
}, {
	iso2: "pa",
	native: "ਪੰਜਾਬੀ"
}, {
	iso2: "pi",
	native: "पाऴि"
}, {
	iso2: "pl",
	native: "język polski, polszczyzna"
}, {
	iso2: "ps",
	native: "پښتو"
}, {
	iso2: "pt",
	native: "Português"
}, {
	iso2: "qu",
	native: "Runa Simi, Kichwa"
}, {
	iso2: "rm",
	native: "rumantsch grischun"
}, {
	iso2: "rn",
	native: "Ikirundi"
}, {
	iso2: "ro",
	native: "Română"
}, {
	iso2: "ru",
	native: "Русский"
}, {
	iso2: "rw",
	native: "Ikinyarwanda"
}, {
	iso2: "sa",
	native: "संस्कृतम्"
}, {
	iso2: "sc",
	native: "sardu"
}, {
	iso2: "sd",
	native: "सिन्धी, سنڌي، سندھی‎"
}, {
	iso2: "se",
	native: "Davvisámegiella"
}, {
	iso2: "sg",
	native: "yângâ tî sängö"
}, {
	iso2: "si",
	native: "සිංහල"
}, {
	iso2: "sk",
	native: "slovenčina, slovenský jazyk"
}, {
	iso2: "sl",
	native: "slovenski jezik, slovenščina"
}, {
	iso2: "sm",
	native: "gagana fa'a Samoa"
}, {
	iso2: "sn",
	native: "chiShona"
}, {
	iso2: "so",
	native: "Soomaaliga, af Soomaali"
}, {
	iso2: "sq",
	native: "Shqip"
}, {
	iso2: "sr",
	native: "српски језик"
}, {
	iso2: "ss",
	native: "SiSwati"
}, {
	iso2: "st",
	native: "Sesotho"
}, {
	iso2: "su",
	native: "Basa Sunda"
}, {
	iso2: "sv",
	native: "svenska"
}, {
	iso2: "sw",
	native: "Kiswahili"
}, {
	iso2: "ta",
	native: "தமிழ்"
}, {
	iso2: "te",
	native: "తెలుగు"
}, {
	iso2: "tg",
	native: "тоҷикӣ, toçikī, تاجیکی‎"
}, {
	iso2: "th",
	native: "ไทย"
}, {
	iso2: "ti",
	native: "ትግርኛ"
}, {
	iso2: "tk",
	native: "Türkmen, Түркмен"
}, {
	iso2: "tl",
	native: "Wikang Tagalog"
}, {
	iso2: "tn",
	native: "Setswana"
}, {
	iso2: "to",
	native: "faka Tonga"
}, {
	iso2: "tr",
	native: "Türkçe"
}, {
	iso2: "ts",
	native: "Xitsonga"
}, {
	iso2: "tt",
	native: "татар теле, tatar tele"
}, {
	iso2: "tw",
	native: "Twi"
}, {
	iso2: "ty",
	native: "Reo Tahiti"
}, {
	iso2: "ug",
	native: "ئۇيغۇرچە "
}, {
	iso2: "uk",
	native: "Українська"
}, {
	iso2: "ur",
	native: "اردو"
}, {
	iso2: "uz",
	native: "Oʻzbek, Ўзбек, أۇزبېك‎"
}, {
	iso2: "ve",
	native: "Tshivenḓa"
}, {
	iso2: "vi",
	native: "Tiếng Việt"
}, {
	iso2: "vo",
	native: "Volapük"
}, {
	iso2: "wa",
	native: "walon"
}, {
	iso2: "wo",
	native: "Wollof"
}, {
	iso2: "xh",
	native: "isiXhosa"
}, {
	iso2: "yi",
	native: "ייִדיש"
}, {
	iso2: "yo",
	native: "Yorùbá"
}, {
	iso2: "za",
	native: "Saɯ cueŋƅ, Saw cuengh"
}, {
	iso2: "zh",
	native: "中文 (Zhōngwén), 汉语, 漢語"
}, {
	iso2: "zu",
	native: "isiZulu"
}]
