var LocaleList = require('editor/locale-list'),
	PluginList = require('editor/plugin-list'),
	PageList = require('editor/page-list/page-list'),
	EditorSectionView = require('editor/editor-section-view'),
	Translations = require('./translations')

	/** @type {StormGlobals} */
	// globals = require('globals')

module.exports = EditorSectionView.extend({
	className: 'localisationTable',
	template: require('./localisation-table-view-template'),

	events: {
		"change #type-selector": "typeSelected",
		"change #page-selector": "pageSelected",
		"change #plugin-selector": "pluginSelected",
		'click .download-json-button': 'downloadJSON',
		'click .download-csv-button': 'downloadCSV',
		'click .save': 'saveChanges',
		'change .check-lang-option': 'langFilterChange',
		'click td': 'checkRtl'
	},

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()
		this.type = 'all'
		this.appId = options.appId
		// Fetch translations
		this.model = new Translations({appId: this.appId})
		var tableFetch = this.model.fetch()

		// Fetch list of languages enabled for this app
		this.appLocales = new LocaleList(null, {
			appId: this.appId
		})
		var langFetch = this.appLocales.fetch()

		this.availablePlugins = new PluginList(null, {appId: this.appId})
		var availableFetch = this.availablePlugins.fetch()

		this.pageList = new PageList(null, {appId: this.appId})
		var pageFetch = this.pageList.fetch()

		Promise.all([langFetch, availableFetch, pageFetch, tableFetch]).then(this.ready.bind(this))
	},

	getPageTitle: function() {
		return $.t('appSetup.translation')
	},

	ready: function() {
		this.render()
		// Dirty way to make a copy.
		this.cachedTable = JSON.parse(JSON.stringify(this.model.toJSON()))
		// Store changes to the table here.
		this.changes = []
		// this.typeSelected()
		App.stopLoad()
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			languages: this.appLocales.toJSON(),
			plugins: this.availablePlugins.toJSON(),
			pages: this.getPageList(),
			classes: App.classes.toJSON(),
			appSelect: App.generateAppSelect(),
			type: this.type,
			systemId: App.system.id
		}
	},

	// After render filter table data based on filter type and then setup table.
	afterRender: function() {
		// Get Model data.
		this.filterData()

		if (this.translations) {
			this.setupTable()
		}
	},

	// Filter model data into a translation array
	filterData: function(pageId, pluginId) {
		this.translations = []
		var plugins = this.model.get('plugins')
		var native = this.model.get('native')
		var pages = this.model.get('pages')

		switch (this.type) {
			case 'all':
				this.translations.push(plugins, native, pages)
				break
			case 'plugins':
				var filteredByPlugin = []
				var intPluginId = parseInt(pluginId, 10)
				for (var i = 0; i < plugins.length; i++) {
					if (plugins[i].id === intPluginId) {
						filteredByPlugin.push(plugins[i])
					}

					if (!pluginId) {
						filteredByPlugin = plugins
					}
				}
				this.translations.push(filteredByPlugin)
				break
			case 'native':
				this.translations.push(native)
				break
			case 'pages':
				var filteredByPage = []
				var intPageId = parseInt(pageId, 10)
				for (var j = 0; j < pages.length; j++) {
					if (intPageId && pages[j].id === intPageId) {
						filteredByPage.push(pages[j])
					}

					if (!pageId) {
						filteredByPage = pages
					}
				}
				this.translations.push(filteredByPage)
				break
		}
	},

	isRtl: function(langCode) {
		return ["ar", "ara", "arc", "ae", "ave",
			"dv", "egy", "he", "heb", "nqo", "pal",
			"phn", "sam", "syc", "syr", "fa",
			"per", "fas", "ku", "kur"].includes(langCode)
	},

	checkRtl: function(event) {
		if (event.currentTarget.style.direction === 'rtl') {
			$('.handsontableInput').addClass('is-rtl')
		} else {
			$('.handsontableInput').removeClass('is-rtl')
		}
	},

	// Create Handsontable and supply it with column data and table data
	setupTable: function() {
		this.columns = []
		this.columnWidths = []
		this.activeLocales = []
		var appLocales = this.appLocales.toJSON()

		for (var i = 0; i < appLocales.length; i++) {
			var appLanguage = appLocales[i]
			var lastThree = appLanguage.code.substr(appLanguage.code.length - 3)
			var rtl = this.isRtl(lastThree)
			var header = appLanguage.language.name.native + ' (' + appLanguage.code.toUpperCase() + ')'
			if (rtl) {
				header += ' rtl'
			}
			if (this.localeActive(appLanguage)) {
				this.activeLocales.push(appLanguage.code)
				this.columns.push(header)
				this.columnWidths.push(300)
			}
		}

		// Add first colume as String / Code
		this.columnWidths.unshift(300)
		this.columns.unshift('Code')

		var container = document.getElementById('handsontable')
		this.tableData = this.getTableData()

		var self = this,
			searchResultNumber = -1
		// Empty value, turn background red if empty
		function emptyValueRenderer(instance, td, row, col, prop, value) {
			Handsontable.renderers.TextRenderer.apply(this, arguments)
			// If empty
			var headerValue = self.columns[col]
			if (headerValue && headerValue.substr(headerValue.length - 3) === 'rtl') {
				td.style.direction = 'rtl'
			}

			if (!value || value === '') {
				td.style.background = '#ff0000'
			}
		}

		Handsontable.renderers.registerRenderer('emptyValueRenderer', emptyValueRenderer)
		this.renderHandsontable(container, searchResultNumber)

		// Remove dev content
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	isLocaleActive: function(langCode) {
		return this.activeLocales.includes(langCode)
	},

	renderHandsontable: function(container, searchResultNumber) {
		if (container === null) {
			return
		}
		var self = this
		this.table = new Handsontable(container, {
			data: this.tableData,
			autoRowSize: true,
			rowHeaders: true,
			contextMenu: true,
			colWidths: this.columnWidths,
			startRows: 5,
			startCols: this.columns.length,
			colHeaders: this.columns,
			columnSorting: false,
			manualColumnResize: true,
			manualRowResize: false,
			search: true,

			afterSelection: function(row, col, row2, col2) {
				var meta = this.getCellMeta(row2, col2)

				if (meta.readOnly) {
					this.updateSettings({
						fillHandle: false
					})
				} else {
					this.updateSettings({
						fillHandle: true
					})
				}
			},

			cells: function(row, col) {
				var cellProperties = {}
				if (col === 0) {
					cellProperties.readOnly = true
				}
				cellProperties.renderer = "emptyValueRenderer" // uses lookup map
				return cellProperties
			},

			// After change of a cell
			afterChange: function(tableChanges) {
				if (tableChanges) {
						// Get code
					tableChanges.forEach(function(change) {
						var contentCode = this.table.getDataAtRow(change[0])[0]
						var langCode = this.activeLocales[change[1] - 1]
						var update = change[3]
						var codeExists = false
						for (var i = 0; i < this.changes.length; i++) {
							if (this.changes[i].code === contentCode) {
								// Loop through and find language code if it exists otherwise just push it.
								var langExists = false
								for (var j = 0; j < this.changes[i].languages.length; j++) {
									if (this.changes[i].languages[j].locale === langCode) {
										this.changes[i].languages[j].text = update
										langExists = true
									}
								}

								if (!langExists) {
									var newUpdate = {
										locale: langCode,
										text: update
									}
									this.changes[i].languages.push(newUpdate)
								}
								codeExists = true
							}
						}
								// If code did not get found.. create it and push inside the update. then push to changes.
						if (!codeExists) {
							var newCode = {
								code: contentCode,
								languages: [{
									locale: langCode,
									text: update
								}]
							}
							this.changes.push(newCode)
						}
					}.bind(this))
				}
			}.bind(this),

			afterInit: function() {
				var searchField = document.getElementById('search_field'),
					searchNextBtn = document.getElementById('search_next_button'),
					searchPrevBtn = document.getElementById('search_prev_button'),
					resultsNoText = document.getElementById('results-number'),
					currentNo = document.getElementById('current-number'),
					searchResults = document.getElementById('search-results'),
					prevSearch = null

				searchResults.style.display = 'none'

				function filterSearchResults(isNewSearchVal) {
									// reset the counter if a new search term is entered
					if (isNewSearchVal) {
						searchResultNumber = 0
					}
					var queryResult = self.table.search.query(searchField.value)

					if (searchField.value) {
											// disable next button if user is focused on the last result
						if (searchResultNumber >= (queryResult.length - 1)) {
							searchNextBtn.disabled = true
						} else {
							searchNextBtn.disabled = false
						}

											// disable previous button if the first result is selected
						if (searchResultNumber === 0) {
							searchPrevBtn.disabled = true
						} else {
							searchPrevBtn.disabled = false
						}

						if (queryResult.length > 0) {
							resultsNoText.innerText = queryResult.length
							currentNo.innerText = searchResultNumber + 1
							searchResults.style.display = 'block'
							self.table.selectCell(queryResult[searchResultNumber].row, queryResult[searchResultNumber].col, undefined, undefined, true, false)
						} else {
							searchResults.style.display = 'block'
							resultsNoText.innerText = queryResult.length
							currentNo.innerText = 0
							searchResultNumber = -1
						}
					} else {
											// reset counter and hide results text if no search term has been entered
						searchResults.style.display = 'none'
						searchResultNumber = -1
						self.table.selectCell(0, 0, undefined, undefined, true, false)
						searchNextBtn.disabled = true
						searchPrevBtn.disabled = true
					}
				}

				Handsontable.Dom.addEvent(searchNextBtn, 'click', function() {
					if (!searchNextBtn.disabled) {
						var isNewSearch = null
						if (prevSearch !== searchField.value) {
							isNewSearch = true
						} else {
							isNewSearch = false
						}

						searchResultNumber++
						filterSearchResults(isNewSearch)
						prevSearch = searchField.value
					}
				})

				Handsontable.Dom.addEvent(searchPrevBtn, 'click', function() {
					if (!searchPrevBtn.disabled) {
						var isNewSearch = null
						if (prevSearch !== searchField.value) {
							isNewSearch = true
						} else {
							isNewSearch = false
						}

						searchResultNumber -= 1
						filterSearchResults(isNewSearch)
						prevSearch = searchField.value
					}
				})
				Handsontable.Dom.addEvent(searchField, 'keyup', function(e) {
					var isNewSearch = null
					if (prevSearch !== searchField.value) {
						isNewSearch = true
					} else {
						isNewSearch = false
					}
					var queryResult = self.table.search.query(searchField.value)

					if (e.keyCode === 13) {
						if (searchResultNumber < (queryResult.length - 1)) {
							searchResultNumber++
						}
					}

					filterSearchResults(isNewSearch)
					prevSearch = searchField.value
				})
			}
		})
	},

	removeDuplicateCodes: function(originalArray) {
		var trimmedArray = []
		var values = []
		var value

		for (var i = 0; i < originalArray.length; i++) {
			value = originalArray[i][0]

			if (values.indexOf(value) === -1) {
				trimmedArray.push(originalArray[i])
				values.push(value)
			}
		}

		return trimmedArray
	},

	// Map translation data into a 2D array
	getTableData: function() {
		var dataStrings = []
		var self = this
		this.translations.forEach(function(type) {
			if (type) {
				type.forEach(function(item) {
					item.content.forEach(function(translation) {
						var toPush = [translation.code]
						for (var i = 0; i < translation.languages.length; i++) {
							if (self.isLocaleActive(translation.languages[i].locale)) {
								toPush.push(translation.languages[i].text)
							}
						}
						dataStrings.push(toPush)
					})
				})
			}
		})
		var filteredDataStrings = this.removeDuplicateCodes(dataStrings)

		return filteredDataStrings
	},

	// Map translation data into:
	// [{
	// 	code: 'xyz',
	// 	en: 'English Translation',
	// 	fr: 'French Translation',
	// 	etc...
	// }]
	getTableDataDownload: function() {
		var dataStrings = []
		var self = this
		this.translations.forEach(function(type) {
			if (type) {
				type.forEach(function(item) {
					item.content.forEach(function(translation) {
						var toPush = {}
						for (var i = 0; i < self.columns.length; i++) {
							if (i === 0) {
								toPush.code = translation.code
							} else {
								toPush[translation.languages[i - 1].locale] = translation.languages[i - 1].text
							}
						}
						dataStrings.push(toPush)
					})
				})
			}
		})

		return dataStrings
	},

	convertArrayOfObjectsToCSV: function(args) {
		var result, ctr, keys, columnDelimiter, lineDelimiter, data

		data = args.data || null
		data = typeof data !== 'object' ? JSON.parse(data) : data

		if (data == null || !data.length) {
			return null
		}

		columnDelimiter = args.columnDelimiter || ','
		lineDelimiter = args.lineDelimiter || '\n'

		keys = Object.keys(data[0])

		result = ''
		result += keys.join(columnDelimiter)
		result += lineDelimiter
		data.forEach(function(item) {
			ctr = 0
			keys.forEach(function(key) {
				var value = item[key].replace(/"/g, '""') // CHeck for any double quotes!
				if (ctr > 0) {
					result += columnDelimiter
				}
				if (key !== 'code') {
					result += '"' + value + '"'
				} else {
					result += '\'' + value + '\''
				}
				ctr++
			})
			result += lineDelimiter
		})

		return result
	},

	localeActive: function(locale) {
		if (locale.publishable && !locale.publishable.test && !locale.publishable.live) {
			return false
		}
		return $('input.check-lang-option[value="' + locale.code + '"]').is(':checked')
	},

	// On type change update table accordingly
	updateTable: function(pageId, pluginId) {
		App.startLoad()
		this.filterData(pageId, pluginId)
		this.table.destroy()
		this.setupTable()
		App.stopLoad()
	},

	pageSelected: function() {
		var pageId = $('#page-selector').val()
		this.updateTable(pageId)
	},

	pluginSelected: function() {
		var pluginId = $('#plugin-selector').val()
		this.updateTable(null, pluginId)
	},

	// Hiding bits till the filter endpoint is updated
	typeSelected: function() {
		this.type = $('#type-selector').val()
		switch (this.type) {
			case 'plugins':
				$('#plugins').show()
				$('#pages').hide()
				$('#table-spacer').hide()
				break
			case 'pages':
				$('#plugins').hide()
				$('#pages').show()
				$('#table-spacer').hide()
				break
			default:
				$('#plugins').hide()
				$('#pages').hide()
				$('#table-spacer').show()
				break
		}

		this.updateTable()
	},

	langFilterChange: function() {
		this.updateTable()
	},

	downloadJSON: function() {
		this.downloadTranslation('json')
	},

	downloadCSV: function() {
		this.downloadTranslation('csv')
	},

	downloadTranslation: function(format) {
		App.startLoad()

		var type = (format === 'json') ? 'application/json' : 'text/csv'

		Backbone.sync('fetch', new Backbone.Model(), {
			url: Storm.app.url() + '/translation?type=' + format,

			dataType: 'text',

			success: function(data) {
				window.URL = window.URL || window.webkitURL

				var file = new Blob([data], {type: type}),
					url = window.URL.createObjectURL(file),
					link = document.createElement('a')

				link.href = url
				link.download = 'translation'
				link.target = '_blank'

				link.click()

				App.stopLoad()
			},

			error: function() {
				App.stopLoad()
				swal($.t('error.oops'), $.t('appSetup.translationError'), 'error')
			}
		})
	},

	saveChanges: function() {
		App.startLoad()
		$.ajax({
			dataType: 'json',
			url: App.apiRoot + 'apps/' + this.appId + '/translations',
			type: 'POST',
			data: JSON.stringify(this.changes),
			headers: {
				"Authorization": App.session.get('token'),
				"Content-Type": 'application/json'
			},
			success: function() {
				App.stopLoad()
			}
		})
	},

	getPageList: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="' + page.id + '">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		return pageListDropdownOptions
	}
})
