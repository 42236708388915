var FALLBACK_LANG = 'en'

var utils = {
	// Get the text content for the specified language key.
	getTextForLang: function(value, lang) {
		if (value) {
			// Allow helper to use direct code maps, not wrapped in 'content'.
			var langMap = 'content' in value ? value.content : value

			if (!langMap) {
				return ''
			}

			var textContent = langMap[lang]

			// Fall back to first language in list if no language key specified.
			if (!lang) {
				var languages = Object.keys(langMap)

				textContent = langMap[languages[0]]
			}

			return textContent || ''
		}

		return ''
	},

	getTextWithFallback: function(value, lang) {
		var text     = utils.getTextForLang(value, lang),
			fallback = false

		// If no text for specified language, fallback to default first, then
		// first language.
		if (!text) {
			fallback = true
			text = utils.getTextForLang(value, FALLBACK_LANG) ||
				utils.getTextForLang(value)
		}

		return {
			fallback: fallback,
			text: text
		}
	},

	// Get text content in specified language.
	getLocaleText: function(value, lang) {
		return utils.getTextWithFallback(value, lang).text
	},

	getViewLocaleText: function(value) {
		var language

		if (Storm.view) {
			language = Storm.view.language
		}

		return utils.getLocaleText(value, language)
	},

	getViewLocaleTextHTML: function(value, shouldWarnOnTranslation) {
		if (shouldWarnOnTranslation === undefined) {
			shouldWarnOnTranslation = true
		}

		var data = utils.getTextWithFallback(value, Storm.view.language),
			html = data.text.replace(/\n/g, '<br>')

		// Mark this text as fallback for styling, if appropriate.
		if (data.fallback && html.length && shouldWarnOnTranslation) {
			html = '<span class="fallback">' + html + '</span>'
		}

		return html
	},

	getBrowserLocaleText: function(value) {
		var language = $.i18n.language.split('-')[0]

		return utils.getLocaleText(value, language)
	},

	// Get the remote URL for a bundle resource.
	getRemoteUrl: function(link) {
		var root = App.apiRoot.replace(App.apiVersion + '/', '')

		return root + 'files/' + link.replace('cache://content/', '')
	},

	// Get the remote URL for an Image object, array of Images, or image URL.
	getImagePreviewUrl: function(img) {
		if (!img) {
			return '//:0'
		}

		// Handle legacy Image objects (old class names).
		var className = 'ImageDescriptor',
			isImageDescriptor = img.class === className,
			isLegacyImage     = img && img.src && img.src.class === className

		if (isImageDescriptor) {
			return utils.getLegacyImagePreviewUrl(img)
		} else if (isLegacyImage) {
			return utils.getLegacyImagePreviewUrl(img.src)
		}

		// If we've got an array of sources, select the largest.
		if (img instanceof Array) {
			var singleImg = img[0]

			img.forEach(function(newImg) {
				if (newImg.dimensions) {
					if (newImg.dimensions.width > singleImg.dimensions.width) {
						singleImg = newImg
					}
				}
			})

			img = singleImg
		}

		var link = null

		if (img) {
			if (img.src) {
				link = img.src.destination

				// Don't reformat external URLs.
				if (img.src.class === 'ExternalLink') {
					return link
				}
			} else if (typeof img === 'string') {
				link = img
			} else {
				throw new Error('Invalid image object passed.')
			}
		}

		if (!link || link.substr(0, 4) === 'http') {
			return '//:0'
		}

		return utils.getRemoteUrl(link)
	},

	getNativeImagePreviewUrl: function(url) {
		var nameMatch = url.match(/app:\/\/native\/images\/(.*)/)

		if (!nameMatch) {
			return ''
		}

		var imageName = nameMatch[1]

		var disasterMatch = imageName.match(/^disaster-icon-(\w+)(?:\.png)?$/)

		if (disasterMatch) {
			return '/images/hazards/disaster-icons/' + disasterMatch[1] + '.png'
		}

		return ''
	},

	/**
	 * @param {Object} imageDescriptor Legacy ImageDescriptor object.
	 * @returns {string} Remote URL extracted from a legacy ImageDescriptor
	 *     object.
	 * @deprecated
	 */
	getLegacyImagePreviewUrl: function(imageDescriptor) {
		var cacheUrl = imageDescriptor.x2

		if (!cacheUrl || cacheUrl.substr(0, 4) === 'http') {
			return '//:0'
		}

		return utils.getRemoteUrl(cacheUrl)
	},

	/**
	 * @param  {integer} timestamp timestamp
	 * @returns {Date} Local Date of the user
	 */
	getLocalDate: function(timestamp) {
		return new Date(timestamp * 1000)
	},

	isStringEmpty: function(x) {
		return x === null || x === ''
	}
}

module.exports = utils
