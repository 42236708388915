var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view'),
	JourneyListItemView = require('./journey-list-item-view')

var pageList = null

var JourneyListView = ListView.extend({
	template: require('./journey-list-view-template'),
	list: require('models/stormql'),
	listItem: JourneyListItemView,
	afterInitialize: function() {
		this.delegateEvents()
		JourneyListItemView.pageList = pageList
	}
})

/**
 * Exports {@link JourniesView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends JourniesView.prototype */{
	/** @override */
	className: 'journies',
	/** @override */
	template: require('./journey-view-template'),

	/**
	 * @constructs JourniesView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		this.app = App.appList.get(options.appId)
		// Fetch enabled page list for this app.
		this.app.pageList.fetch()

		// Fetch the page list if there's a link selector in this class.
		pageList = this.app.pageList
		pageList.once('sync', this.loadViews, this)
	},

	loadViews: function() {
		this.views = {
			list: new JourneyListView({
				fetchData: {data: {class: 'StormQLBloodStage'}},
				app: this.app
			})
		}
		this.render()
	},

	/** @override */
	getPageTitle: function() {
		return $.t('journey.pageTitle')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	}
})
