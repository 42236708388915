var StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EditorSectionView = require('editor/editor-section-view'),
	utils = require('lib/utils')

/**
 * Exports {@link StoryEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends StoryEditView.prototype */{
	/** @override */
	className: 'stories',
	/** @override */
	template: require('./story-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage'
	},

	/**
	 * @constructs StoryEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var localePromise = this.app_.localeList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('StormQLStory')
		this.listenTo(this.model, 'change:story', this.updateStory, this)

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, localePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		if (!this.model.has('image')) {
			var image = App.getClassStructure('Image', 0)
			this.model.set('image', image)
		}
		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.app_.id
		data.locales = this.app_.localeList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()

		// Show current input values
		this.$('.name-input').val(this.model.get('name'))

		_.each(this.model.get('story..content'), function(val, key) {
			this.$('.story-input[data-code=' + key + ']').val(val)
		})
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/stories', {trigger: true})
		}.bind(this))

		// Get all new input values
		var name = this.$('.name-input').val(),
			stories = {}

		this.$('.story-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				stories[code] = value
			}
		})

		this.model.set('name', name)
		this.model.set('story..content', stories)

		this.model.save(null, {appId: this.app_.id})
		return false
	},

	chooseImage: function() {
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: this.model.get('image'),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:story', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateStory: function() {
		// Support legacy image format.
		var icon = this.model.get('image')

		if (!icon.src) {
			icon = icon.toJSON()
		}

		this.$('img').attr('src', utils.getImagePreviewUrl(icon))
	}

})
