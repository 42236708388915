<div class="app-error" id="noItems" style="display:none;">{{t "common.noItems"}}</div>
<table>
	<thead>
		<tr>
			<th style="width:80px">{{t "editor.inspector.properties.image.title"}}</th>
			<th>{{t "editor.inspector.properties.title.title"}}</th>
			<th>{{t "editor.inspector.properties.subtitle.title"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
