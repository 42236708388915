var StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EditorSectionView = require('editor/editor-section-view'),
	utils = require('lib/utils'),
	LinkSelector = require('editor/inspector/link-selector')

/**
 * Exports {@link JourneyEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends JourneyEditView.prototype */{
	/** @override */
	className: 'stories',
	/** @override */
	template: require('./journey-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseActiveImage',
		'click .choose-image-inactive-button': 'chooseInactiveImage'
	},

	/**
	 * @constructs JourneyEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var localePromise = this.app_.localeList.fetchOnce()
		var pageListPromise = Storm.app.pageList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('StormQLBloodStage')
		this.listenTo(this.model, 'change:journey', this.updateJourney, this)

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, localePromise, pageListPromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		if (!this.model.has('image')) {
			var image = App.getClassStructure('Image', 0)
			this.model.set('image', image)
		}

		if (!this.model.has('link')) {
			var link = App.getClassStructure('Link', 0)
			this.model.set('link', link)
		}
		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.app_.id
		data.locales = this.app_.localeList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()
		if (this.model.get('link')) {
			var linkPicker = new LinkSelector({
				link: this.model.get('link'),
				titleDisabled: true,
				pageList: Storm.app.pageList,
				appId: this.app_.id
			})
			// Show link picker
			$('.link-picker').html(linkPicker.render().el)
		}

		// Show current input values
		this.$('.stage-input').val(this.model.get('stage'))

		_.each(this.model.get('title..content'), function(val, key) {
			this.$('.title-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('pending..content'), function(val, key) {
			this.$('.pending-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('active..content'), function(val, key) {
			this.$('.active-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('elapsed..content'), function(val, key) {
			this.$('.elapsed-input[data-code=' + key + ']').val(val)
		})
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/journey', {trigger: true})
		}.bind(this))

		// Get all new input values
		var stage = this.$('.stage-input').val(),
			titles = {},
			pendings = {},
			actives = {},
			elapseds = {}

		this.$('.title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				titles[code] = value
			}
		})

		this.$('.pending-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				pendings[code] = value
			}
		})

		this.$('.elapsed-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				elapseds[code] = value
			}
		})

		this.$('.active-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				actives[code] = value
			}
		})

		this.model.set('stage', stage)
		this.model.set('title..content', titles)
		this.model.set('pending..content', pendings)
		this.model.set('active..content', actives)
		this.model.set('elapsed..content', elapseds)

		this.model.save(null, {appId: this.app_.id})
		return false
	},

	chooseActiveImage: function() {
		this.chooseImage('image')
	},

	chooseInactiveImage: function() {
		this.chooseImage('image_inactive')
	},

	chooseImage: function(modelName) {
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: this.model.get(modelName),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:journey', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateJourney: function() {
		// Support legacy image format.
		var image = this.model.get('image')

		if (!image.src) {
			image = image.toJSON()
		}

		this.$('.js-image').attr('src', utils.getImagePreviewUrl(image))
	}

})
