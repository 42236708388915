<div class="row">
	<div class="col-sm-12">
		<h3>
			{{t "whatnow.edit.editWhatnow"}}:
			{{#each countryCodes}} {{#ifEqual code ../countryCode}} {{description}} - {{code}} {{/ifEqual}} {{/each}}
		</h3>
	</div>
</div>
<div class="row">
	<div class="pull-right">
		<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
		<button class="button green wide save">{{t "common.save"}}</button>
	</div>
</div>
<hr>

<!-- Top data (Event type and more info url)-->
<div class="row">
	<div class="col-sm-8">
		<legend data-i18n="common.description"></legend>
		<div class="form-group row">
			<label class="control-label col-sm-2" for="whatnow-event" data-i18n="whatnow.edit.event"></label>
			<div class="col-sm-10">
				<select id="whatnow-event" class="form-control">
					{{#each whatnowDisasters}}
						{{#ifExistsInArray ../currentEvents this}}
							<option disabled>{{this}}</option>
						{{else}}
							<option value="{{this}}" {{findSelected ../this ../../data.eventType}}>{{this}}</option>
						{{/ifExistsInArray}}
					{{/each}}
					<p class="help-block" data-i18n="whatnow.edit.eventhelp"></p>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label class="control-label col-sm-2" for="whatnow-moreinfo" data-i18n="whatnow.edit.moreinfo"></label>
			<div class="col-sm-10">
				<input type="url" id="whatnow-moreinfo" class="form-control" required value="{{data.webUrl}}" placeholder="http://www.linktomore.info">
				<p class="help-block" data-i18n="whatnow.edit.moreinfohelp"></p>
			</div>
		</div>
	</div>

	<div class="col-sm-4">
		<legend data-i18n="whatnow.edit.languages"></legend>
		<p class="help-block" data-i18n="whatnow.edit.languageshelp"></p>
		{{#each data.translations}}
			<div class="row">
				<p class="col-sm-3">{{getWhatnowLocaleName lang ../languages}}</p>
				<button type="button" class="btn btn-default btn-xs delete-language" data-id= {{@index}}><i class="icon-trash"></i></button>
			</div>
			{{else}}
				<p data-i18n="whatnow.edit.addALang"></p>
		{{/each}}
		<div class="row">
			<hr>
			<div class="col-sm-5">
				<select id="whatnow-langselect" class="form-control">
					{{#each languages}}
						<option value="{{iso2}}" {{enabledLang iso2 ../data.translations}}>{{getWhatnowLocaleName iso2 ../languages}} ({{iso2}})</option>
					{{/each}}
				</select>
			</div>
			<button class="button btn btn-primary add-language-button col-xs-2"><i class="icon icon-plus"></i> {{t "common.add"}}</button>
		</div>
	</div>
</div>

<div class="row">
<div class="col-sm-12">
<legend data-i18n="whatnow.edit.translations"></legend>
</div>
</div>

{{#if data.translations.length}}
<!-- Current translations -->
<form id="newlangForm" class="container-fluid form-horizontal">
<fieldset class="edit-form">
	<!-- Add new language -->
	<div class="row">
		<div class="form-group">
			<div class="col-sm-3">
				<h4 data-i18n="whatnow.edit.new"></h4>
			</div>
		</div>
	</div>

	<!-- Title -->
	<div class="row">
		<div class="form-group">
			<div class="col-sm-7">
				<h4 class="title" data-i18n="whatnow.edit.title"></h4>
				<div class="whatnow-help">
						<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.titleHelp"></p></div>
						<div style="display: inline-block;" class="more-info" data-info="title"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
						<div class="whatnow-more alert alert-info" role="alert" id="more-info-title" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
				</div>
				<table>
					<tbody>
						{{#each data.translations}}
							<tr>
								<td class="col-md-2">
									{{getWhatnowLocaleName lang ../languages}}
								</td>
								<td>
									<input type="text" id="whatnow-new-title-{{lang}}" class="form-control" required value="{{title}}">
								</td>
							</tr>
						{{/each}}
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<!-- Description -->
	<div class="row">
		<div class="form-group">
			<div class="col-sm-12">
				<h4 data-i18n="whatnow.edit.description"></h4>
				<div class="whatnow-help">
						<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.descriptionHelp"></p></div>
						<div style="display: inline-block;" class="more-info" data-info="description"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
						<div class="whatnow-more alert alert-info" role="alert" id="more-info-description" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
				</div>
				<table>
					<tbody>
						{{#each data.translations}}
							<tr>
								<td class="col-md-2">
									{{getWhatnowLocaleName lang ../languages}}
								</td>
								<td>
									<textarea id="whatnow-new-description-{{lang}}" class="form-control" required>{{description}}</textarea>
								</td>
							</tr>
						{{/each}}
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<!-- Mid-Forecast -->
	<div class="row">
		<div class="col-sm-12">
			<h4 data-i18n="whatnow.edit.midterm"></h4>
			<div class="whatnow-help">
					<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.midtermHelp"></p></div>
					<div style="display: inline-block;" class="more-info" data-info="midterm"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
					<div class="whatnow-more alert alert-info" role="alert" id="more-info-midterm" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
			</div>
			<!-- Collapsable table -->
			<div class="storm-list-group">
				<ul class="list-content list-group">
					{{#each data.translations}}
						<li class="list-group-item">
							<span>{{getWhatnowLocaleName lang ../languages}}</span>
							<!-- <span class="badge pull-right" data-i18n="whatnow.edit.missing"></span> -->
							<ul>
								<table id="midterm-{{lang}}">
									<tbody>
										<tr>
											<td></td>
											<td><button class="button btn btn-primary pull-right add-row"><i class="icon icon-plus"></i>{{t "common.add"}}</button></td>
										</tr>
										{{#each midTerm}}
											<tr>
												<td>
													<input type="text" class="form-control" value="{{this}}">
												</td>
												<td class="tools">
													<i class="icon-trash delete-row"></i>
												</td>
											</tr>
										{{else}}
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
										{{/each}}
									</tbody>
								</table>
							</ul>
						</li>
					{{/each}}
				</ul>
			</div>
		</div>
	</div>

	<!-- Forecast -->
	<div class="row">
		<div class="col-sm-12">
			<h4 data-i18n="whatnow.edit.forecast"></h4>
			<div class="whatnow-help">
					<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.forecastHelp"></p></div>
					<div style="display: inline-block;" class="more-info" data-info="forecast"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
					<div class="whatnow-more alert alert-info" role="alert" id="more-info-forecast" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
			</div>
			<!-- Collapsable table -->
			<div class="storm-list-group">
				<ul class="list-content list-group">
					{{#each data.translations}}
						<li class="list-group-item">
							<span>{{getWhatnowLocaleName lang ../languages}}</span>
							<!-- <span class="badge pull-right" data-i18n="whatnow.edit.missing"></span> -->
							<ul>
								<table id="forecast-{{lang}}">
									<tbody>
										<tr>
											<td></td>
											<td>
												<button class="button btn btn-primary pull-right add-row"><i class="icon icon-plus"></i>{{t "common.add"}}</button>
											</td>
										</tr>
										{{#each forecast}}
											<tr>
												<td>
													<input type="text" class="form-control" value="{{this}}">
												</td>
												<td class="tools">
													<i class="icon-trash delete-row"></i>
												</td>
											</tr>
										{{else}}
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
										{{/each}}
									</tbody>
								</table>
							</ul>
						</li>
					{{/each}}
				</ul>
			</div>
		</div>
	</div>

	<!-- Watch -->
	<div class="row">
		<div class="col-sm-12">
			<h4 data-i18n="whatnow.edit.watch"></h4>
			<div class="whatnow-help">
					<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.watchHelp"></p></div>
					<div style="display: inline-block;" class="more-info" data-info="watch"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
					<div class="whatnow-more alert alert-info" role="alert" id="more-info-watch" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
			</div>
			<!-- Collapsable table -->
			<div class="storm-list-group">
				<ul class="list-content list-group">
					{{#each data.translations}}
						<li class="list-group-item">
							<span>{{getWhatnowLocaleName lang ../languages}}</span>
							<!-- <span class="badge pull-right" data-i18n="whatnow.edit.missing"></span> -->
							<ul>
								<table id="watch-{{lang}}">
									<tbody>
										<tr>
											<td></td>
											<td>
												<button class="button btn btn-primary pull-right add-row"><i class="icon icon-plus"></i>{{t "common.add"}}</button>
											</td>
										</tr>
										{{#each watch}}
											<tr>
												<td>
													<input type="text" class="form-control" value="{{this}}">
												</td>
												<td class="tools">
													<i class="icon-trash delete-row"></i>
												</td>
											</tr>
										{{else}}
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
										{{/each}}
									</tbody>
								</table>
							</ul>
						</li>
					{{/each}}
				</ul>
			</div>
		</div>
	</div>

	<!-- Warning -->
	<div class="row">
		<div class="col-sm-12">
			<h4 data-i18n="whatnow.edit.warning"></h4>
			<div class="whatnow-help">
					<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.warningHelp"></p></div>
					<div style="display: inline-block;" class="more-info" data-info="warning"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
					<div class="whatnow-more alert alert-info" role="alert" id="more-info-warning" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
			</div>
			<!-- Collapsable table -->
			<div class="storm-list-group">
				<ul class="list-content list-group">
					{{#each data.translations}}
						<li class="list-group-item">
							<span>{{getWhatnowLocaleName lang ../languages}}</span>
							<!-- <span class="badge pull-right" data-i18n="whatnow.edit.missing"></span> -->
							<ul>
								<table id="warning-{{lang}}">
									<tbody>
										<tr>
											<td></td>
											<td><button class="button btn btn-primary pull-right add-row"><i class="icon icon-plus"></i>{{t "common.add"}}</button></td>
										</tr>
										{{#each warning}}
											<tr>
												<td>
													<input type="text" class="form-control" value="{{this}}">
												</td>
												<td class="tools">
													<i class="icon-trash delete-row"></i>
												</td>
											</tr>
										{{else}}
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
										{{/each}}
									</tbody>
								</table>
							</ul>
						</li>
					{{/each}}
				</ul>
			</div>
		</div>
	</div>

	<!-- Immediate -->
	<div class="row">
		<div class="col-sm-12">
			<h4 data-i18n="whatnow.edit.immediate"></h4>
			<div class="whatnow-help">
					<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.immediateHelp"></p></div>
					<div style="display: inline-block;" class="more-info" data-info="immediate"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
					<div class="whatnow-more alert alert-info" role="alert" id="more-info-immediate" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
			</div>
			<!-- Collapsable table -->
			<div class="storm-list-group">
				<ul class="list-content list-group">
					{{#each data.translations}}
						<li class="list-group-item">
							<span>{{getWhatnowLocaleName lang ../languages}}</span>
							<!-- <span class="badge pull-right" data-i18n="whatnow.edit.missing"></span> -->
							<ul>
								<table id="immediate-{{lang}}">
									<tbody>
										<tr>
											<td></td>
											<td>
												<button class="button btn btn-primary pull-right add-row"><i class="icon icon-plus"></i>{{t "common.add"}}</button>
											</td>
										</tr>
										{{#each immediate}}
											<tr>
												<td>
													<input type="text" class="form-control" value="{{this}}">
												</td>
												<td class="tools">
													<i class="icon-trash delete-row"></i>
												</td>
											</tr>
										{{else}}
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
										{{/each}}
									</tbody>
								</table>
							</ul>
						</li>
					{{/each}}
				</ul>
			</div>
		</div>
	</div>

	<!-- Recover -->
	<div class="row">
		<div class="col-sm-12">
			<h4 data-i18n="whatnow.edit.recover"></h4>
			<div class="whatnow-help">
					<div style="display: inline-block;"><p class="help-block" data-i18n="whatnow.edit.recoverHelp"></p></div>
					<div style="display: inline-block;" class="more-info" data-info="recover"><a href="#"><span class="icon icon-question-sign" aria-hidden="true"></span></a></div>
					<div class="whatnow-more alert alert-info" role="alert" id="more-info-recover" data-i18n="whatnow.edit.moreinfoHelp.placeholder"></div>
			</div>
			<!-- Collapsable table -->
			<div class="storm-list-group">
				<ul class="list-content list-group">
					{{#each data.translations}}
						<li class="list-group-item">
							<span>{{getWhatnowLocaleName lang ../languages}}</span>
							<!-- <span class="badge pull-right" data-i18n="whatnow.edit.missing"></span> -->
							<ul>
								<table id="recover-{{lang}}">
									<tbody>
										<tr>
											<td></td>
											<td>
												<button class="button btn btn-primary pull-right add-row"><i class="icon icon-plus"></i>{{t "common.add"}}</button>
											</td>
										</tr>
										{{#each recover}}
											<tr>
												<td>
													<input type="text" class="form-control" value="{{this}}">
												</td>
												<td class="tools">
													<i class="icon-trash delete-row"></i>
												</td>
											</tr>
										{{else}}
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
											<tr><td><input type="text" class="form-control"></td>
											<td class="tools"><i class="icon-trash delete-row"></i></td></tr>
										{{/each}}
									</tbody>
								</table>
							</ul>
						</li>
					{{/each}}
				</ul>
			</div>
		</div>
	</div>
{{else}}
<h4 data-i18n="whatnow.edit.empty"></h4>
{{/if}}
<!-- End new translation -->
