var	HazardsSectionView = require('../hazards-section-view'),
	DisasterEventsEditItemView = require('./disaster-event-edit-item-view'),
	EventsConfig = require('./eventsConfig'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	utils = require('lib/utils')

module.exports = HazardsSectionView.extend({
	template: require('./disaster-events-edit-template'),

	tutorial: 'disasters',

	events: {
		'click .save-button': 'save',
		'click .event-open': 'toggleOpenEvent',
		'click .update-icon': 'updateEventMetaIcon',
		'change .update-page': 'updateEventMetaSrc',
		'change .update-default': 'updateEventMetaDefault',
		'input .search-input': 'filterEvents',
		'change .feed-check': 'toggleFeedForEvent'
	},

	initialize: function(options) {
		App.startLoad()
		this.app = App.appList.get(options.appId)
		var requests = []

		this.pageList = this.app.pageList
		requests.push(this.pageList.fetch())

		this.eventsConfig = new EventsConfig({app: this.app, retrieveCategories: false})
		requests.push(this.eventsConfig.fetch())

		// this.eventMetas = []

		Promise.all(requests).then(function() {
			this.eventsConfig.parseMeta()
			this.events = this.eventsConfig.getEventsWithFeeds()
			this.getPageList()
			this.renderEvents()
		}.bind(this))
	},

	getPageList: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		this.pageSelectOptions = pageListDropdownOptions
	},

	filterEvents: function() {
		var text = $('.search-input').val().toLowerCase()

		$('.events-box').each(function(index, eventBox) {
			var $eventBox = $(eventBox)

			var name = $eventBox.data('name').toLowerCase()
			if (!name.includes(text)) {
				$eventBox.hide()
			} else {
				$eventBox.show()
			}
			if (!text) {
				$eventBox.show()
			}
		})
	},

	toggleFeedForEvent: function(e) {
		var checkbox = $(e.currentTarget)
		var eventId = checkbox.data('eventid')
		var feedId = checkbox.data('feedid')
		var feedCode = checkbox.data('feedcode')

		// Does the feed for the Event already exist?
		var feed = this.eventsConfig.feedList.findWhere({id: feedId})
		if (feed) {
			var appFeed = this.eventsConfig.appFeedList.findWhere({code: feedCode})
			if (appFeed) {
				var events = appFeed.get('events')
				appFeed.needsSaving = true

				// Are we enabling or disabling?
				if (_.contains(events, eventId)) {
					// Remove
					var index = events.indexOf(eventId)
					if (index > -1) {
						events.splice(index, 1)
					}
					if (events.length === 0) {
						// No More events here... list for deletion
						appFeed.needsDeleting = true
						if (appFeed.needsSaving) {
							delete appFeed.needsSaving
						}

						if (appFeed.isNew()) {
							appFeed.destroy() // New so doesn't need a network request.
						}
					}
				} else {
					// Add
					appFeed.get('events').push(eventId)

					if (appFeed.needsDeleting) {
						delete appFeed.needsDeleting
					}
				}
			} else {
				// Create one...
				var model = StandaloneStormObject.fromClassName('Feed')
				// Make sure that there is no page id being set.
				model.unset('pageId')
				model.set('code', feed.get('code'))
				// Add event to it...
				model.set('events', [eventId])
				model.set('languages', feed.get('languages'))
				model.set('name', feed.get('name'))

				this.eventsConfig.appFeedList.push(model)
				//
			}
		}
	},

	getEventMeta: function(eventId) {
		var metaHaz = this.eventsConfig.metaHaz

		// Is there a valid meta object?
		var eventMeta = metaHaz.findWhere({identifier: eventId})
		// No? Create one...
		if (!eventMeta) {
			eventMeta = StandaloneStormObject.fromClassName('MetaHazardsEvent')
			eventMeta.set('identifier', eventId)
			metaHaz.add(eventMeta)
		}
		// if (!this.eventMetas) {
		// 	this.eventMetas = []
		// }
		// var index = this.eventMetas.indexOf(eventMeta)
		// if (index > -1) {
		// 	// It exists doesn't need re-adding
		// } else {
		// 	this.eventMetas.push(eventMeta)
		// }

		// Return it...
		return eventMeta
	},

	updateEventMetaIcon: function(e) {
		// Get event id...
		var id = parseInt($(e.currentTarget).data('eventid'), 10)
		var eventMeta = this.getEventMeta(id)
		this.chooseImage(eventMeta, $(e.currentTarget))
		this.eventsConfig.meta.needsSaving = true
	},

	updateEventMetaSrc: function(e) {
		// Get event id...
		var id = parseInt($(e.currentTarget).data('eventid'), 10)
		var val = $(e.currentTarget).val()
		var eventMeta = this.getEventMeta(id)
		if (val !== "-") {
			eventMeta.set('src', val)
			this.eventsConfig.meta.needsSaving = true
		}
	},

	updateEventMetaDefault: function(e) {
		// Get event id...
		var id = parseInt($(e.currentTarget).data('eventid'), 10)
		var val = $(e.currentTarget).is(':checked')
		var eventMeta = this.getEventMeta(id)
		eventMeta.set('default', val)
		this.eventsConfig.meta.needsSaving = true
	},

	chooseImage: function(eventMeta, updateTarget) {
		var icon = eventMeta.get('icon')

		this.listenTo(eventMeta, 'change:icon', function() {
			this.updateIcon(eventMeta, updateTarget)
		}, this)

		this.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: icon,
			mediaType: MediaLibrary.types.ICON
		})

		this.mediaLibrary.on('change', function() {
			eventMeta.trigger('change:icon', icon)
		}, this)

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	updateIcon: function(eventMeta, updateTarget) {
		updateTarget.attr('style', 'background-image: url("' + utils.getImagePreviewUrl(eventMeta.get('icon')) + '"); background-color: #fff;')
	},

	save: function() {
		App.startLoad()
		var requests = [],
			appId = this.app.id

		// Save each changed disaster
		this.eventsConfig.appFeedList.forEach(function(feed) {
			if (feed.isNew()) {
				requests.push(feed.save(null, {appId: appId}))
			} else if (feed.needsSaving) {
				feed.requestLock(function() {
					feed.save().then(function() {
						feed.requestUnlock()
					})
				})
			} else if (feed.needsDeleting) {
				feed.requestLock(function() {
					feed.destroy()
				})
			}
			delete feed.needsSaving
		})

		var meta = this.eventsConfig.meta.at(0)
		if (this.eventsConfig.meta.needsSaving === true) {
			if (meta.isNew()) {
				requests.push(meta.save(null, {appId: appId}))
			} else {
				meta.requestLock(function() {
					meta.save().then(function() {
						meta.requestUnlock()
					})
				})
			}
			delete this.eventsConfig.meta.needsSaving
		}

		Promise.all(requests).then(function() {
			App.stopLoad()
			setTimeout(function() {
				App.router.navigate('/apps/' + this.app.id + '/hazards', {trigger: true})
			}.bind(this), 1000)
		}.bind(this))
	},

	getPageTitle: function() {
		return $.t('hazards.disasters.title')
	},

	getRenderData: function() {
		return {appId: this.app.id}
	},

	afterRender: function() {
		// Hide features based on permissions.
		if (!App.acl.hasWritePermission('Hazards', 'Disasters')) {
			this.$('.save-button').remove()
		}
		$('.app-select').remove()
	},

	renderEvents: function() {
		App.startLoad()
		if (this.events.length) {
			this.events.forEach(function(theEvent) {
				var view = new DisasterEventsEditItemView({model: theEvent, pages: this.pageSelectOptions})
				$('.events-list').append(view.render().el)
				view.setPageSelection()
			}.bind(this))
		}
		App.stopLoad()
	},

	ready: function() {
	},

	toggleOpenEvent: function(e) {
		$(e.currentTarget).parent().parent().parent().toggleClass('events-box-open')
	}

})
