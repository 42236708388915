<div class="add-form add-page-form zero-height row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-xs-12">
				<h4 data-i18n="editor.addPage"></h4>
			</div>
		</div>
		<form class="row">
			<div class="form-group col-sm-4">
				<div class="input-group">
					<span class="input-group-addon" data-i18n="editor.pageName"></span>
					<input type="text" class="form-control new-page-name">
				</div>
				<p class="js-no-page-name-error error-message" style="display: none">
					{{t "editor.pageNameError"}}
				</p>
			</div>
			<div class="form-group col-sm-3">
				<div class="input-group">
					<span class="input-group-addon" data-i18n="editor.pageType"></span>
					<select class="form-control new-page-type"></select>
				</div>
			</div>
			<div class="form-group col-sm-3">
				<div class="input-group">
					<span class="input-group-addon" data-i18n="editor.pageTag"></span>
					<select class="form-control new-page-tag">
						{{#each pageTags}}
							<option>{{name}}</option>
						{{/each}}
					</select>
				</div>
			</div>
			<div class="form-group col-sm-5 new-page-native">
				<div class="input-group">
					<span class="input-group-addon" data-i18n="editor.inspector.properties.name.description"></span>
					<input type="text" class="form-control new-page-native-name">
				</div>
			</div>
			<div class="col-sm-2">
				<button type="submit" class="button form-control save-new-page-button" data-i18n="editor.addPage"></button>
			</div>
			<div class="col-xs-12">
				<h4 data-i18n="common.pageTags.other"></h4>
				<button type="button" class="button new-from-template-button" data-i18n="editor.newFromTemplate"></button>
				<button type="button" class="button wide paste-page-button" data-i18n="editor.inspector.paste"></button>
				<button type="button" class="button white wide cancel-new-page-button pull-right" data-i18n="common.cancel"></button>
			</div>
		</form>
	</div>
</div>
<div class="templates-form"></div>
<div class="row child-views">
	<div class="page-list-container col-lg-3 col-md-3 col-sm-4"></div>
	<div class="canvas-container col-lg-9 col-md-9 col-sm-8"></div>
</div>
