<div class="images">
  {{#each frames}}
    {{#ifExists sponsorship}}
      <div class="spotlight-image" style="background-image: url({{getSponsorImage sponsorship.[0] ../sponsorList}});" data-frame="{{@index}}">

      </div>
    {{else}}
      <div class="spotlight-image" style="background-image: url({{getPreviewUrl src}}); {{#if ../slimSpotlight}}height: 129px;{{/if}}" data-frame="{{@index}}">
        <span class="spotlight-text">{{{getLocaleTextHTML text}}}</span>
      </div>
    {{/ifExists}}
  {{/each}}
</div>
