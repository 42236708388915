var API_VERSION        = 'latest',
	AUTH_VERSION       = 'v1.6',
	BUGS_VERSION       = 'v1.3',
	ROBOTO_VERSION     = 'v1.0',
	NEW_ROBOTO_VERSION = 'v1',
	ALERTS_VERSION     = 'v1.1',
	BLOOD_VERSION      = 'v1.0',
	WHATNOW_VERSION		 = 'v1'

var Utils = require('lib/utils')

// Application bootstrapper.
window.App = {
	initialize: function() {
		var Router = require('lib/router'),
			Login  = require('auth/login-view')

		this.router = new Router()
		this.login = new Login()
	},

	getIdFromCacheUrl: function(url) {
		if (!url) {
			return 0
		}

		return Number(url.match(/cache:\/\/.+\/(\d+)\.json/)[1])
	},

	getSubclasses: function(name) {
		var deferred = new jQuery.Deferred(),
			existing = App.subclasses[name]

		var resolve = function(subclasses) {
			// Clone array before we start mutating it.
			subclasses = subclasses.slice(0)

			// Remove any classes not defined in the app class list.
			for (var i = 0; i < subclasses.length; i++) {
				var subclass = subclasses[i]

				if (!App.classes.get(subclass)) {
					subclasses.splice(i--, 1)
				}
			}

			// Hacky way to hide the PokemonListItemView and ChunkyListItemView
			['PokemonListItemView', 'ChunkyListItemView'].forEach(function(subclass) {
				var subclassIndex = subclasses.indexOf(subclass)
				if (subclassIndex > -1) {
					subclasses.splice(subclasses.indexOf(subclass), 1)
				}
			})

			deferred.resolve(subclasses)
		}

		if (existing) {
			resolve(existing)
		} else {
			$.ajax({
				url: App.apiRoot + 'classes/' + name + '/valid',
				dataType: 'json',
				success: function(data) {
					App.subclasses[name] = data
					resolve(data)
				}
			})
		}

		return deferred
	},

	// App.classes only contains shallow structure. Get the proper nested one
	getClassStructure: function(className, pageId) {
		// Return default values for 'primitive types'
		switch (className) {
			case 'Number':
				return 0
			case 'String':
				return ''
			case 'Boolean':
				return false
			case 'Language':
				return {}

			// Don't return generic types - give a specific one instead
			case 'Link':
			case 'DestinationLink':
				className = 'ExternalLink'
				break

			case 'GridView':
				className = 'StandardGridView'
				break
		}

		// Get shallow structure
		var shallowStructure = App.classes.get(className),
			deepStructure    = {},
			classRegex       = /\{.+\}/,
			valueRegex       = /<.+>/,
			arrayRegex       = /\[.+\]/

		// Replace each key with its respective structure
		_.each(shallowStructure, function(value, key) {
			// Ignore any ID fields - they have to be generated by the server
			if (key === 'id' || value === null) {
				return
			}

			var childType = value.substring(1, value.length - 1)

			if (classRegex.test(value) || valueRegex.test(value)) {
				deepStructure[key] = App.getClassStructure(childType, pageId)
			} else if (arrayRegex.test(value)) {
				deepStructure[key] = []
			} else {
				deepStructure[key] = value
			}
		})

		// Set page ID if given (new pages and direct children don't have page
		// ID initially)
		if (pageId !== undefined) {
			deepStructure.pageId = pageId
		}

		return deepStructure
	},

	startLoad: function() {
		$('#loading').show().find('img').addClass('spin')
	},

	stopLoad: function() {
		$('#loading')
			.fadeOut(250)
			.queue(function() {
				$(this).find('img').removeClass('spin')
				$(this).dequeue()
			})
	},

	// Temporary long loading on requests that take a long time
	startLongLoad: function() {
		$('#long-loading h4').text(this.il8next.t("common.longLoad")) // Load translation
		$('#long-loading').show().find('img').addClass('spin')
	},

	stopLongLoad: function() {
		$('#long-loading')
			.fadeOut(250)
			.queue(function() {
				$(this).find('img').removeClass('spin')
				$(this).dequeue()
			})
	},

	showLoginOverlay: function() {
		localStorage.removeItem('auth')

		// Show login overlay, if the app's been initialized
		if (App.login) {
			App.login.show()
		}
	},

	showToast: function(text) {
		// Add message to queue
		App._toastQueue.splice(0, 0, text)

		// Start queue if not running
		if (App._toastQueue.length === 1) {
			App._processNextToast()
		}
	},

	_processNextToast: function() {
		var text = App._toastQueue[0]

		if (text === undefined) {
			return
		}

		$('.toast-text').text(text)
		$('.toast-container').fadeIn(500)

		setTimeout(function() {
			// Remove toast from queue
			App._toastQueue.pop()
			$('.toast-container').fadeOut(500)

			// Show next toast
			setTimeout(App._processNextToast, 500)
		}, App.toastTimeout)
	},

	// Get last accessed app, or first available.
	getCurrentApp: function() {
		var lastApp = Number(localStorage.getItem('lastApp'))

		return App.appList.get(lastApp) || App.appList.first()
	},

	generateAppSelect: function() {
		var optgroups = '',
			l         = Utils.getBrowserLocaleText

		var societies = App.societiesList.sortBy(function(society) {
			return society.get('name')
		})

		societies.forEach(function(society) {
			var apps     = App.appList.where({societyId: society.id})

			var $optgroup = $('<optgroup>')
				.attr('label', society.get('name'))

			apps.forEach(function(app) {
				// Don't show template-type apps in the dropdown.
				if (app.isTemplateApp()) {
					return
				}

				var $option = $('<option>')
					.val(app.id)
					.text(l(app.get('name')))

				$optgroup.append($option)
			})

			if (apps.length) {
				optgroups += $optgroup.prop('outerHTML')
			}
		})

		return optgroups
	},

	l: Utils.getViewLocaleText,

	_toastQueue: [],
	toastTimeout: 5000, // ms

	classes: {},
	subclasses: {},
	clipboard: {className: '', payload: null},

	apiVersion: API_VERSION,
	bugsVersion: BUGS_VERSION,

	apiRoot: '//storm.cubeapis.com/' + API_VERSION + '/',
	authRoot: 'https://auth.cubeapis.com/' + AUTH_VERSION + '/',
	bugsRoot: 'https://bugs.cubeapis.com/' + BUGS_VERSION + '/',
	robotoRoot: 'https://roboto.cubeapis.com/' + ROBOTO_VERSION + '/',
	newRobotoRoot: 'https://api.roboto.build/' + NEW_ROBOTO_VERSION + '/',
	alertsRoot: 'https://alerts.cubeapis.com/' + ALERTS_VERSION + '/',
	bloodRoot: 'https://blood.arc.cubeapis.com/' + BLOOD_VERSION + '/',
	mindRoot: 'https://v1-api-mind.cubeapis.com/',
	deploymentRoot: 'https://paradrop.3sidedcube.com/',
	whatnowRoot: 'https://api.preparecenter.org/' + WHATNOW_VERSION + '/',

	session: null,
	system: null,

	developerMode: false,
	version: '{!version!}',

	MAX_FILESIZE: '2.5' // MB
}

module.exports = App
