<table>
	<thead>
		<tr>
			<th>{{t "frame.name"}}</th>
			<th style="max-width:100px">{{t "frame.src"}}</th>
			<th>{{t "frame.order"}}</th>
			<th>{{t "frame.type"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
