<table>
	<thead>
		<tr>
			<th style="width:80px">{{t "editor.inspector.properties.badge.icon"}}</th>
			<th style="display:none; min-width: 120px;" id="date-range-column">
				<div>
					{{t "editor.inspector.properties.badge.dateRange"}}
				</div>
			</th>
			<th>{{t "editor.inspector.properties.title.title"}}</th>
			<th>
				<div>{{t "editor.inspector.properties.badge.completion.title"}}</div>
				<div class="sub-text">{{t "editor.inspector.properties.badge.completion.description"}}</div>
			</th>
			<th>
				<div>{{t "editor.inspector.properties.shareMessage.title"}}</div>
				<div class="sub-text">{{t "editor.inspector.properties.shareMessage.description"}}</div>
			</th>
			<th>
				<div>{{t "editor.inspector.properties.badge.how.title"}}</div>
				<div class="sub-text">{{t "editor.inspector.properties.badge.how.description"}}</div>
			</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
