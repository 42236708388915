var StormView = require('lib/storm-view'),
	/** @type {StormGlobals} */
	globals = require('globals')

/**
 * Exports {@link AppSetupSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends AppSetupSectionView.prototype */{
	/**
	 * @constructs AppSetupSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('appSetup.title')
	},

	/** @override */
	getPages: function() {
		var acl = globals.getAcl()

		return [
			{
				name: $.t('appSetup.title'),
				url: 'settings',
				isVisible: function() {
					return acl.hasAccess('App Setup', 'App setup')
				},
				views: [
					require('app-setup/app-setup-view')
				]
			},
			{
				name: $.t('plugins.title'),
				url: 'plugins',
				isVisible: function() {
					return acl.hasAccess('App Setup', 'Plugins')
				},
				views: [
					require('app-setup/plugins-view')
				]
			},
			{
				name: $.t('appSetup.properties'),
				url: 'properties',
				isVisible: function() {
					return acl.hasAccess('App Setup', 'App properties')
				},
				views: [
					require('app-setup/properties-view')
				]
			},
			{
				name: $.t('metadata.title'),
				url: 'metadata',
				isVisible: function() {
					return App.system.apiCode === 'GDPC'
				},
				views: [
					require('metadata/metadata-view')
				]
			},
			{
				name: $.t('appSetup.translation'),
				url: 'settings/translations',
				isVisible: function() {
					return acl.hasAccess('App Setup', 'Translation')
				},
				views: [
					require('app-setup/translation-table/localisation-table-view')
				]
			}
		]
	}
})
