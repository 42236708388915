var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./journey-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId
		this.pageList = options.app.pageList
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.prettyLink = this.getLinkPreview(this.model.get('link'))
		data.appId = this.appId
		return data
	},

	/**
	 * Constructs a preview for a Link object.
	 * @param {Object} link A Storm Link object.
	 * @returns {string} An HTML string with the preview content.
	 */
	getLinkPreview: function(link) {
		var url = link.destination
		if (!url) {
			return ''
		}

		var pageId = App.getIdFromCacheUrl(url),
			page   = this.pageList.get(pageId)
		if (!page) {
			return ''
		}
		return {pageTitle: '[' + page.get('tag') + '] ' + App.l(page.get('title')), id: link.id, title: link.title}
	}
})
