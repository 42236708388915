<div class="inspector-mode segmented-control">
	<button class="active" value="properties" data-i18n="appSetup.properties"></button>
	<button value="swap" data-i18n="editor.inspector.swapItem"></button>
</div>

<div class="properties">
	{{#ifIsPage}}
		<hr>
		<h5 data-i18n="editor.pageTag"></h5>
		<select class="form-control page-tag-picker" data-property="tag">
			{{#each pageTags}}
				<option>{{this.name}}</option>
			{{/each}}
		</select>
		<hr>

		<div class="page-style-inspector">
			<h5 data-i18n="editor.pageStyle"></h5>
			<select class="form-control page-style">
				<option value="">{{t "push.types.default"}}</option>
				<option value="STYLE_PAPER">Paper</option>
			</select>
		</div>
	{{else}}
		<span class="object-controls">
			<button class="button move-up-button" title="{{t "editor.inspector.moveUp"}}"><i class="icon-arrow-up"></i></button>
			<button class="button move-down-button" title="{{t "editor.inspector.moveDown"}}"><i class="icon-arrow-down"></i></button>
		</span>
	{{/ifIsPage}}
	<hr>

	{{! ListItem properties }}

	{{#ifExists volatile}}
		<h5 data-i18n="editor.inspector.properties.volatile"></h5>
		<input type="checkbox" data-property="volatile" {{#if volatile}}checked{{/if}}>
		<hr>
	{{/ifExists}}

	{{#ifExists name}}
		{{#ifEqual class "AppCollectionItem"}}
		{{else}}
			<div class="developer-mode">
				<h5 data-i18n="editor.inspector.properties.name.title"></h5>
				<input type="text" class="form-control" data-property="name" value="{{name}}" data-i18n="[placeholder]editor.inspector.properties.name.description">
				<hr>
			</div>
		{{/ifEqual}}
	{{/ifExists}}

	{{#ifExists identifier}}
		<h5 data-i18n="apps.app"></h5>
		<select class="app-identifier-select form-control" data-property="identifier">
			<option>-</option>
		</select>
		<hr>
	{{/ifExists}}

	{{#ifExists quiz}}
		<h5 data-i18n="editor.inspector.properties.quiz"></h5>
		<select class="quiz-select form-control" data-property="quiz..destination">
			<option>-</option>
		</select>
		<hr>
	{{/ifExists}}

	{{#ifExists image}}
		<h5 data-i18n="editor.inspector.properties.image.title"></h5>
		<a href="{{getDownloadUrl image}}" target="_blank" download><img src="{{getPreviewUrl image}}" data-property="image"></a>

		<!-- ARC only -->
		{{#ifEqual systemId 9}}
			<div class="row">
				<div class="col-xs-12">
					<h6 data-i18n="editor.inspector.properties.accessibilityLabel"></h6>
				</div>
				<div class="col-xs-12">
					<input type="text" class="form-control accessibility-label" value="{{getLocaleText image.accessibilityLabel}}" data-accessibility="image">
				</div>
			</div>
		{{/ifEqual}}

		<div class="row">
			<div class="col-xs-6">
				<button class="button block image-select-button" data-property="image" data-i18n="editor.inspector.properties.image.chooseButton"></button>
			</div>
			<div class="col-xs-6">
				<button class="button block red col-xs-6 image-remove-button" data-property="image">
					<i class="icon-remove"></i>&nbsp;<span data-i18n="editor.inspector.properties.image.removeButton"></span>
				</button>
			</div>
		</div>
		<hr>
	{{/ifExists}}

	{{#ifExists icon}}
		<h5 data-i18n="editor.inspector.properties.badge.icon"></h5>
		<a href="{{getDownloadUrl icon.src.x1}}" target="_blank" download><img src="{{getPreviewUrl icon}}" data-property="icon"></a>
		<div class="row">
			<div class="col-xs-6">
				<button class="button block image-select-button" data-i18n="editor.inspector.properties.image.chooseButton" data-property="icon"></button>
			</div>
			<div class="col-xs-6">
				<button class="button block red image-remove-button" data-property="icon">
					<i class="icon-remove"></i>&nbsp;<span data-i18n="editor.inspector.properties.image.removeButton"></span>
				</button>
			</div>
		</div>
		<hr>
	{{/ifExists}}

	{{#ifExists audio}}
		<h5 data-i18n="editor.inspector.properties.audio.title"></h5>
		<div class="audio-selector"></div>
		<hr>
	{{/ifExists}}

	<!-- ARC Blood only -->
	{{#ifEqual systemId 9}}
		{{#ifEqual appId 7}}
			{{#ifExists header}}
				<div class="row">
					<div class="col-xs-12">
						<h6 data-i18n="editor.inspector.properties.header.title"></h6>
					</div>
					<div class="col-xs-12">
						<input type="text" class="form-control" data-property="header" value="{{getLocaleText header}}">
					</div>
				</div>
			{{/ifExists}}
			{{#ifExists footer}}
				<div class="row">
					<div class="col-xs-12">
						<h6 data-i18n="editor.inspector.properties.footer.title"></h6>
					</div>
					<div class="col-xs-12">
						<input type="text" class="form-control" data-property="footer" value="{{getLocaleText footer}}">
					</div>
				</div>
			{{/ifExists}}
		{{/ifEqual}}
	{{/ifEqual}}

	{{#if link}}
		<h5 data-i18n="editor.inspector.properties.link.title" class="link-title"></h5>
		<div class="link-selector"></div>
		<hr>
	{{/if}}

	{{#ifExists videos}}
		<h5 data-i18n="editor.inspector.properties.video.title"></h5>
		<div class="multi-video-selector"></div>
		<hr>
	{{/ifExists}}

	{{#ifExists attributes}}
		<h5 data-i18n="editor.inspector.properties.video.attributes"></h5>
		<label class="checkbox"><input type="checkbox" class="video-attr-loopable"> {{t "editor.inspector.properties.video.loop"}}</label>
		<hr>
	{{/ifExists}}

	{{#if button}}
		<h5 data-i18n="editor.inspector.properties.button"></h5>
		<div class="link-selector"></div>
		<hr>
	{{/if}}

	{{#if overlay}}
		<h5 data-i18n="editor.inspector.properties.overlay"></h5>
		<input type="text" class="form-control" data-property="overlay" value="{{getLocaleText overlay}}">
		<hr>
	{{/if}}

	{{#ifExists quizzes}}
		<h5 data-i18n="editor.inspector.properties.quizzes"></h5>
		<div class="quiz-selector"></div>
		<hr>
	{{/ifExists}}

	{{#ifExists progressMessage}}
		<h5>Progress message</h5>
		<input type="text" class="form-control" data-property="progressMessage" value="{{getLocaleText progressMessage}}" placeholder="Next">
		<hr>
	{{/ifExists}}

	{{#ifExists finishMessage}}
		<h5>Finish message</h5>
		<input type="text" class="form-control" data-property="finishMessage" value="{{getLocaleText finishMessage}}" placeholder="Completed">
		<hr>
	{{/ifExists}}

	{{#ifExists annotation}}
		<h5>Annotation</h5>
		<input type="text" class="form-control" data-property="annotation" value="{{annotation}}" placeholder="1">
		<hr>
	{{/ifExists}}

	{{#ifExists title}}
		<h5>Title</h5>
		<input type="text" class="form-control" data-property="title" value="{{getLocaleText title}}" placeholder="Title">
		<hr>
	{{/ifExists}}

	{{#ifExists description}}
		<h5>Description</h5>
		<input type="text" class="form-control" data-property="description" value="{{getLocaleText description}}" placeholder="Description">
		<hr>
	{{/ifExists}}

	{{#ifExists pageRef}}
		<div class="pageRef">
			<h5 data-i18n="editor.inspector.properties.pageRef.title"></h5>
			<input type="text" class="form-control" data-property="pageRef" value="{{pageRef}}" placeholder="Page Reference ID"/>
		</div>
	{{/ifExists}}

	{{#if animation}}
		<div class="image-list"></div>
		<button class="button choose-animation-button block" data-i18n="editor.inspector.properties.animation.chooseButton"></button>
		<div class="col-xs-4">
			<h5 data-i18n="editor.inspector.properties.animation.delay"></h5>
		</div>
		<div class="col-xs-8">
			<input type="number" class="form-control animation-duration" min="0" value="1">
		</div>
		<label><input type="checkbox" class="animation-looped" {{#if animation.looped}}checked{{/if}}>&nbsp;<span data-i18n="editor.inspector.properties.video.loop"></span></label>
		<hr>
	{{/if}}

	{{! Deprecated. Compatibility for old format images. }}
	{{#ifClass "AnimatedImageListItemView"}}
		<div class="image-list"></div>
		<button class="button choose-animation-button block" data-i18n="editor.inspector.properties.animation.chooseButton"></button>
		<div class="row">
			<div class="col-xs-4">
				<h5 data-i18n="editor.inspector.properties.animation.delay"></h5>
			</div>
			<div class="col-xs-8">
				<input type="number" class="form-control animation-duration" min="0" value="1">
			</div>
		</div>

		<!-- ARC only -->
		{{#ifEqual systemId 9}}
			<div class="row">
				<div class="col-xs-4">
					<h5 data-i18n="editor.inspector.properties.accessibilityLabel"></h5>
				</div>
				<div class="col-xs-8">
					<input type="text" class="form-control accessibility-label" value="{{getLocaleText accessibilityLabel}}" data-accessibility="animation">
				</div>
			</div>
		{{/ifEqual}}

		<hr>
	{{/ifClass}}


	{{#ifExists spotlights}}
		<div class="image-list"></div>
		<button class="button add-image-button input-block-level neutral">
			<i class="icon-plus"></i>&nbsp;<span data-i18n="editor.inspector.properties.images.add"></span>
		</button>
	{{/ifExists}}

	{{! Deprecated. Compatibility for old format images. }}
	{{#ifClass "SpotlightImageListItemView"}}
		<div class="image-list"></div>
		<button class="button add-image-button input-block-level neutral">
			<i class="icon-plus"></i>&nbsp;<span data-i18n="editor.inspector.properties.images.add"></span>
		</button>
		<button class="button add-sponsor-button input-block-level neutral">
			<i class="icon-plus"></i>&nbsp;<span data-i18n="sponsorship.edit.add"></span>
		</button>
	{{/ifClass}}


	{{! Sponsorship }}
	{{#ifExists sponsorship}}
		<h5 data-i18n="editor.inspector.sponsorship"></h5>
			<div class="sponsorship-selector"></div>
		<hr>
	{{/ifExists}}

	{{#ifExists products}}
		<h5 data-i18n="editor.inspector.product"></h5>
			<div class="product-selector"></div>
		<hr>
		<button class="button add-product-button input-block-level neutral">
			<i class="icon-plus"></i>&nbsp;<span data-i18n="products.add"></span>
		</button>
	{{/ifExists}}

	{{#ifExists embeddedMedia}}
		<h5 data-i18n="editor.inspector.properties.embeddedMedia.title"></h5>
		<div class="embedded-media-selector"></div>
		<hr>
	{{/ifExists}}

	{{#ifExists embeddedLinks}}
		<h5 data-i18n="editor.inspector.properties.embeddedLinks.title"></h5>
		<div class="embedded-link-list"></div>
		<hr>
	{{/ifExists}}

	{{! QuizPage }}

	{{#ifExists badgeId}}
		<h5 data-i18n="editor.inspector.properties.badge.title"></h5>
		<select class="form-control badge-select" data-property="badgeId">
			<option value="">Loading...</option>
		</select>
		<hr>
	{{/ifExists}}

	{{#if content}}
		<h5 data-i18n="editor.inspector.properties.textContent">Content</h5>
		<input type="text" class="form-control" data-property="content" value="{{getLocaleText this}}">
		<hr>
	{{/if}}

	{{#ifExists answer}}
		{{#ifEqual class "SliderQuizItem"}}
			<h5 data-i18n="editor.inspector.properties.answer"></h5>
			<input type="text" class="form-control" data-property="answer" value="{{answer}}">
			<hr>
		{{/ifEqual}}
	{{/ifExists}}

	{{#ifExists limit}}
		<h5 data-i18n="editor.inspector.properties.selectionLimit"></h5>
		<input type="number" class="form-control" data-property="limit" value="{{limit}}">
		<hr>
	{{/ifExists}}

	{{#ifExists isCorrect}}
		<h5 data-i18n="editor.inspector.properties.correctAnswer"></h5>
		<input type="checkbox" data-property="isCorrect" {{#if isCorrect}}checked{{/if}}>
	{{/ifExists}}

	{{#if unit}}
		<h5 data-i18n="editor.inspector.properties.units.title"></h5>
		<input type="text" class="form-control" data-property="unit" value="{{getLocaleText unit}}" data-i18n="[placeholder]editor.inspector.properties.units.description">
		<hr>
	{{/if}}

	{{#if range}}
		<h5 data-i18n="editor.inspector.properties.range.title"></h5>
		<div class="row">
			<div class="col-xs-4">
				<h6 data-i18n="editor.inspector.properties.range.start"></h6>
			</div>
			<div class="col-xs-8">
				<input type="number" class="form-control" data-property="range..start" value="{{range.start}}">
			</div>
		</div>
		<div class="row">
			<div class="col-xs-4">
				<h6 data-i18n="editor.inspector.properties.range.length"></h6>
			</div>
			<div class="col-xs-8">
				<input type="number" class="form-control" data-property="range..length" value="{{range.length}}" min="1">
			</div>
		</div>
		{{#valExistsInClass this.classes "Range" "increment"}}
				<div class="row">
					<div class="col-xs-4">
						<h6 data-i18n="editor.inspector.properties.range.increment"></h6>
					</div>
					<div class="col-xs-8">
						<input type="number" class="form-control" data-property="range..increment" value="{{range.increment}}" min="1">
					</div>
				</div>
			{{/valExistsInClass}}
		<hr>
	{{/if}}

	{{#ifExists initialPosition}}
		<h5 data-i18n="editor.inspector.properties.initialPosition"></h5>
		<input type="number" class="form-control" data-property="initialPosition" value="{{initialPosition}}">
		<hr>
	{{/ifExists}}

    {{#ifExists answer}}
        <h5 data-i18n="editor.inspector.properties.answer"></h5>
        <input type="number" class="form-control" data-property="answer" value="{{answer}}">
        <hr>
    {{/ifExists}}

	{{#if completion}}
		<h5 data-i18n="editor.inspector.properties.completionText.title"></h5>
		<input type="text" class="form-control" data-property="completion" value="{{getLocaleText completion}}" data-i18n="[placeholder]editor.inspector.properties.completionText.description">
		<hr>
	{{/if}}

	{{#if failure}}
		<h5 data-i18n="editor.inspector.properties.failureText.title"></h5>
		<input type="text" class="form-control" data-property="failure" value="{{getLocaleText failure}}" data-i18n="[placeholder]editor.inspector.properties.failureText.description">
		<hr>
	{{/if}}

	{{#ifExists winThreshold}}
		<h5 data-i18n="editor.inspector.properties.winThreshold.title"></h5>
		<input type="number" class="form-control" data-property="winThreshold" value="{{#if winThreshold}}{{winThreshold}}{{else}}80{{/if}}" min="0" max="100" data-i18n="[placeholder]editor.inspector.properties.winThreshold.description">
		<hr>
	{{/ifExists}}

	{{#if winMessage}}
		<h5 data-i18n="editor.inspector.properties.winMessage.title"></h5>
		<input type="text" class="form-control" data-property="winMessage" value="{{getLocaleText winMessage}}" data-i18n="[placeholder]editor.inspector.properties.winMessage.description">
		<hr>
	{{/if}}

	{{#if loseMessage}}
		<h5 data-i18n="editor.inspector.properties.loseMessage.title"></h5>
		<input type="text" class="form-control" data-property="loseMessage" value="{{getLocaleText loseMessage}}" data-i18n="[placeholder]editor.inspector.properties.loseMessage.description">
		<hr>
	{{/if}}

	{{#if shareMessage}}
		<h5 data-i18n="editor.inspector.properties.shareMessage.title"></h5>
		<input type="text" data-property="shareMessage" class="form-control" value="{{getLocaleText shareMessage}}" data-i18n="[placeholder]editor.inspector.properties.shareMessage.description">
		<hr>
	{{/if}}

	{{#ifExists winRelatedLinks}}
		<h5 data-i18n="editor.inspector.properties.winRelatedLinks"></h5>
		<hr>
		<div class="win-link-list"></div>
		<hr>
	{{/ifExists}}

	{{#ifExists loseRelatedLinks}}
		<h5 data-i18n="editor.inspector.properties.loseRelatedLinks"></h5>
		<hr>
		<div class="lose-link-list"></div>
		<hr>
	{{/ifExists}}

	{{#if certificateButton}}
		<h5 data-i18n="editor.inspector.properties.certificateButton"></h5>
		<div class="certificate-link-selector"></div>
		<hr>
	{{/if}}

	{{#if courseButton}}
		<h5 data-i18n="editor.inspector.properties.courseButton"></h5>
		<div class="course-link-selector"></div>
		<hr>
	{{/if}}

	{{! TabPageDescriptor }}

	{{#if tabBarItem}}
		<h5 data-i18n="editor.inspector.properties.image.title"></h5>
		<a href="{{getDownloadUrl tabBarItem.image.src.x1}}" target="_blank" download><img src="{{getPreviewUrl tabBarItem.image}}" class="tab-image-preview"></a>
		<div class="row">
			<div class="col-xs-6">
				<button class="button block tab-image-select-button" data-i18n="editor.inspector.properties.image.chooseButton"></button>
			</div>
			<div class="col-xs-6">
				<button class="button block red tab-image-remove-button"><i class="icon-remove"></i>&nbsp;<span data-i18n="editor.inspector.properties.image.removeButton"></span></button>
			</div>
		</div>
		<hr>

		<h5 data-i18n="editor.inspector.properties.placeholderImage"></h5>
		<a href="{{getDownloadUrl tabBarItem.placeholderImage.src.x1}}" target="_blank" download><img src="{{getPreviewUrl tabBarItem.placeholderImage}}" class="tab-placeholder-preview"></a>
		<div class="row">
			<div class="col-xs-6">
				<button class="button block tab-placeholder-image-select-button" data-i18n="editor.inspector.properties.image.chooseButton"></button>
			</div>
			<div class="col-xs-6">
				<button class="button block red tab-placeholder-image-remove-button"><i class="icon-remove"></i>&nbsp;<span data-i18n="editor.inspector.properties.image.removeButton"></span></button>
			</div>
		</div>
		<hr>

		<h5 data-i18n="common.description"></h5>
		<input type="text" class="form-control tab-description" value="{{getLocaleText tabBarItem.description}}">
		<hr>

		<h5 data-i18n="common.page"></h5>
		<select class="tab-destination form-control" data-property="src">
			<option>-</option>
		</select>
		<hr>
	{{/if}}

	{{! GridPage properties }}

	{{#ifExists grid}}
		<div class="grid-view-inspector"></div>
	{{/ifExists}}

	{{! Object restrictions }}

	{{#ifExists restrictions}}
		<div class="restrictions" style="display:none">
			<h5>Restrictions</h5>
			<label><input type="checkbox" value="edit"> No edit</label>
			<label><input type="checkbox" value="delete"> No delete</label>
			<label><input type="checkbox" value="move"> No move</label>
			<label><input type="checkbox" value="add"> No add</label>
		</div>
	{{/ifExists}}

	<div class="controls add-bullet-row">
		<button class="button block add-bullet-button"><i class="icon-plus"></i> {{t "editor.inspector.addBulletItem"}}</button>
	</div>
	<div class="row controls">
		<div class="col-xs-6">
			<button class="button block another-button"><i class="icon-plus"></i> {{t "editor.inspector.addAnother"}}</button>
		</div>
		<div class="col-xs-6">
			<button class="button block copy-button"><i class="icon-copy"></i> {{t "editor.inspector.copy"}}</button>
		</div>
	</div>
	<div class="row controls">
		<div class="col-xs-6">
			<button class="button block green save-button"><i class="icon-ok"></i> {{t "common.done"}}</button>
		</div>
		<div class="col-xs-6">
			<button class="button block red delete-button"><i class="icon-trash"></i> {{t "mediaLibrary.delete"}}</button>
		</div>
	</div>

	<div>
		<hr>
		<h6>{{class}} ({{id}})</h6>
		{{#ifIsPage}}
			<button class="button developer-mode input-block-level make-template-button">Make template</button>
		{{/ifIsPage}}
	</div>
</div>
<div class="swap"></div>
