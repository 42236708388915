<td>
	{{#ifExists pageTitle}}
	<dl>
		{{#key_value pageTitle.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
	{{else}}
		{{page.destination}}
	{{/ifExists}}
</td>
<td class="tools">
	<a href="/apps/{{appId}}/search-terms/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
