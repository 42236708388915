var questionTemplate = require('./quiz-item-view-template'),
	AnswerView       = require('./quiz-answer-view'),
	CanvasItemView   = require('./canvas-item-view'),
	StormObject      = require('editor/storm-object'),
	APICompat        = require('lib/api-compat'),
	StormObjectUtils = require('lib/storm-object-utils')

var pageTemplates = {}

_.each([
	'TextQuizItem',
	'ImageQuizItem',
	'AreaQuizItem',
	'SliderQuizItem'
], function(name) {
	pageTemplates[name] = require('./quiz-pages/' + name)
})

module.exports = CanvasItemView.extend({
	template: function(context) {
		var className = APICompat.normaliseClassName(this.model.get('class'))

		return questionTemplate(context) + pageTemplates[className](context)
	},

	className: function() {
		return 'editable QuizItem ' + this.model.get('class')
	},

	events: function() {
		var events = CanvasItemView.prototype.events()
		events['click .add'] = 'addOption'
		events['mousedown .selected-area'] = 'mousedown'
		events.mousemove = 'mousemove'
		events.mouseup = 'mouseup'
		events['change input[type=radio]'] = 'radioChange'
		events['click .selected-area, table'] = 'ignoreClick'
		return events
	},

	initialize: function() {
		CanvasItemView.prototype.initialize.apply(this, arguments)

		this.listViews = []
		this.listenTo(this.model, 'change', this.render)

		this.quizOptionDrake_ = null
	},

	afterRender: function() {
		CanvasItemView.prototype.afterRender.apply(this, arguments)

		// Destroy any previous list views
		_.each(this.listViews, function(view) {
			view.destroy()
		})

		this.listViews = []

		var options = this.model.get('options')

		if (options !== undefined) {
			options.forEach(function(option) {
				this.addOptionPreview(option, true)
			}, this)

			this.stopListening(options)
			this.listenTo(options, 'add', this.render)
			this.listenTo(options, 'remove reset', this.render)
		}

		var className = APICompat.normaliseClassName(this.model.get('class'))

		if (className === 'AreaQuizItem') {
			this.setAreaPreview()
		}

		// Show area selector if the inspector's visible
		if (this.model.editing) {
			this.$('.selected-area').show()
		}
	},

	click: function(e) {
		if ($('.preview').hasClass('preview-mode')) {
			return
		}

		Storm.view.views.canvas.setInspector(this.model)

		this.$el.addClass('editing')
		this.model.editing = true

		var className = APICompat.normaliseClassName(this.model.get('class'))

		if (className === 'AreaQuizItem') {
			// Show area selector for AreaQuizItem
			this.setAreaPreview()
			this.$('.selected-area').show()
		}

		e.stopPropagation()
	},

	// Set every child model to edit mode.
	startEditing: function() {
		CanvasItemView.prototype.startEditing.apply(this, arguments)

		var children = this.model.get('options') || []

		children.forEach(function(child) {
			child.trigger('startEditing')
		})

		this.startDragAndDrop_()
	},

	// Stop editing all child models.
	stopEditing: function() {
		CanvasItemView.prototype.stopEditing.apply(this, arguments)

		var children = this.model.get('options') || []

		children.forEach(function(child) {
			child.trigger('stopEditing')
		})

		this.stopDragAndDrop_()
	},

	/**
	 * Initialises the drag and drop plugin for reordering quiz options in the
	 * question.
	 * @private
	 */
	startDragAndDrop_: function() {
		var quizAnswers = this.$('.answers').get()

		this.quizOptionDrake_ = dragula(quizAnswers, {
			mirrorContainer: quizAnswers[0]
		})
		this.quizOptionDrake_.on('drop', this.handleOptionDrop_.bind(this))
	},

	/**
	 * Tears down the drag and drop plugin for reordering items on a page.
	 * @private
	 */
	stopDragAndDrop_: function() {
		if (this.quizOptionDrake_) {
			this.quizOptionDrake_.destroy()
			this.quizOptionDrake_ = null
		}
	},

	/**
	 * Handles a drop event from the drag and drop plugin. Reorders models on
	 * the page to match the updated DOM state.
	 * @param {HTMLElement} el The DOM element being dropped.
	 * @param {HTMLElement} target The DOM element in which {@link el} is being
	 *     dropped.
	 * @param {HTMLElement} source The DOM element from which {@link el} has
	 *     been removed.
	 * @param {HTMLElement} sibling The DOM element which {@link el} has been
	 *     dropped before, or {@code null} if {@link el} has been dropped at
	 *     the end of the list.
	 * @private
	 */
	handleOptionDrop_: function(el, target, source, sibling) {
		var itemModel = el.model

		/* HACK - to be removed when Project Storm #230 has been resolved. */
		/* Performs a copy and paste instead of moving an existing model. */
		var itemData = itemModel.toJSON()

		StormObjectUtils.stripIDs(itemData, itemData.pageId)
		itemModel.destroy()

		itemModel = StormObject.fromProperties(itemData)
		/* END HACK */

		var options = this.model.get('options')

		// Remove old reference to this model.
		options.remove(itemModel)

		// Get index to add new model into target.
		var targetIndex

		if (sibling === null) {
			targetIndex = options.length
		} else {
			targetIndex = options.indexOf(sibling.model)
		}

		options.add(itemModel, {at: targetIndex})

		// Save model with new orderings.
		this.model.save()
	},

	setAreaPreview: function() {
		var zones = this.model.get('answer')
		var zone = (zones.at) ? zones.at(0) : zones[0]

		if (zone) {
			var coordinates   = (zone.get) ? zone.get('coordinates') : zone.coordinates,
				imagePosition = this.$('img').position(),
				width         = this.$el.width(),
				height        = this.$el.height(),
				imageWidth    = this.$('img').width(),
				imageHeight   = this.$('img').height()

			var topLeft     = coordinates.at(0),
				bottomRight = coordinates.at(2)

			if (topLeft.get) {
				topLeft = [topLeft.get('x'), topLeft.get('y')]
			} else {
				topLeft = [topLeft.x, topLeft.y]
			}

			if (bottomRight.get) {
				bottomRight = [bottomRight.get('x'), bottomRight.get('y')]
			} else {
				bottomRight = [bottomRight.x, bottomRight.y]
			}

			topLeft = topLeft.map(function(coord) {
				return (coord > 1) ? 0.25 : coord
			})

			bottomRight = bottomRight.map(function(coord) {
				return (coord > 1) ? 1 : coord
			})

			var position = {
				left: topLeft[0] * imageWidth + imagePosition.left,
				top: topLeft[1] * imageHeight + imagePosition.top,
				right: width - bottomRight[0] * imageWidth - imagePosition.left,
				bottom: height - bottomRight[1] * imageHeight - imagePosition.top
			}

			this.$('.selected-area').css(position)
		}
	},

	addOption: function(e) {
		var pageId      = this.model.get('pageId'),
			className   = APICompat.normaliseClassName(this.model.get('class')),
			optionType  = className.match(/^(Text|Image)QuizItem$/)[1],
			optionClass = optionType + 'QuizItemOption'

		var option = StormObject.fromClassName(optionClass, pageId)

		this.model.get('options').add(option)
		this.model.save()

		e.stopPropagation()
	},

	addOptionPreview: function(option, suppressInspector) {
		var answer = new AnswerView({model: option})
		this.listViews.push(answer)

		// Set new views to editing mode, if we're in it.
		if (this.model.editing) {
			answer.startEditing()
		}

		this.$('.answers').append(answer.render().el)

		// Show inspector
		if (suppressInspector !== true) {
			answer.$el.trigger('click')
		}
	},

	mousedown: function(e) {
		if ($(e.target).hasClass('edit-handle')) {
			// Resizing the area
			var handles = [
				'top-left',
				'top',
				'top-right',
				'left',
				'right',
				'bottom-left',
				'bottom',
				'bottom-right'
			]

			_.each(handles, function(handle) {
				if ($(e.target).hasClass(handle)) {
					this.dragHandle = handle
				}
			}, this)
		} else if ($(e.target).hasClass('selected-area')) {
			// Dragging the area
			var position = this.$('.selected-area').position()

			this.dragging = true
			this.dragHandle = false
			this.dragAnchor = {
				x: e.pageX - position.left,
				y: e.pageY - position.top
			}
		} else {
			return
		}

		// If there's an existing zone for the answer, do a DELETE request to
		// remove it first
		var answer = this.model.get('answer')
		var zone

		// Odd behjaviour where answer might not come back as a backbone collection,
		// so try to remove by different means.
		if (answer.at(0)) {
			try {
				while ((zone = answer.at(0))) {
					if (zone.isNew && !zone.isNew()) {
						zone.destroy()
					} else if (answer.remove) {
						answer.remove(zone)
					} else {
						answer.shift()
					}
				}
			} catch (err) {
				console.log("Couldn't remove answer")
			}
			this.model.set('answer', answer, {silent: true})
		}
		e.stopPropagation()
	},

	mousemove: function(e) {
		var area        = this.$('.selected-area'),
			w           = this.$el.width(),
			h           = this.$el.height(),
			imageWidth  = this.$('img').width(),
			imageHeight = this.$('img').height()

		var position = {
			top: parseFloat(area.css('top')),
			right: parseFloat(area.css('right')),
			bottom: parseFloat(area.css('bottom')),
			left: parseFloat(area.css('left'))
		}

		if (this.dragging) {
			// Translating box
			position.newLeft = e.pageX - this.dragAnchor.x
			position.newTop = e.pageY - this.dragAnchor.y
			position.newRight = position.right - (position.newLeft - position.left)
			position.newBottom = position.bottom - (position.newTop - position.top)
		} else if (this.dragHandle) {
			// Resizing box
			var pageOffset = this.$el.offset(),
				x          = e.pageX - pageOffset.left,
				y          = e.pageY - pageOffset.top

			switch (this.dragHandle) {
				case 'top-left':
				case 'top':
				case 'top-right':
					position.newTop = y
					break

				case 'bottom-left':
				case 'bottom':
				case 'bottom-right':
					position.newBottom = h - y - 1
					break
			}

			switch (this.dragHandle) {
				case 'top-left':
				case 'left':
				case 'bottom-left':
					position.newLeft = x
					break

				case 'top-right':
				case 'right':
				case 'bottom-right':
					position.newRight = w - x - 1
					break
			}
		} else {
			return false
		}

		// Set preview positioning (relative to cursor, scaled)
		position.top = position.newTop || position.top
		position.right = position.newRight || position.right
		position.bottom = position.newBottom || position.bottom
		position.left = position.newLeft || position.left

		delete position.newTop
		delete position.newRight
		delete position.newBottom
		delete position.newLeft

		area.css(position)

		// Get percentage coordinates to store in API
		var imagePosition = this.$('img').position()
		var zone = App.getClassStructure('Zone', this.model.get('pageId'))

		zone.coordinates = [
			{
				class: 'Coordinate',
				x: (position.left - imagePosition.left) / imageWidth,
				y: (position.top - imagePosition.top) / imageHeight
			},
			{
				class: 'Coordinate',
				x: (w - position.right - imagePosition.left) / imageWidth,
				y: (position.top - imagePosition.top) / imageHeight
			},
			{
				class: 'Coordinate',
				x: (w - position.right - imagePosition.left) / imageWidth,
				y: (h - position.bottom - imagePosition.top) / imageHeight
			},
			{
				class: 'Coordinate',
				x: (position.left - imagePosition.left) / imageWidth,
				y: (h - position.bottom - imagePosition.top) / imageHeight
			}
		]

		this.model.set('answer', [zone], {silent: true})
		this.model.needsSaving = true

		return false
	},

	mouseup: function() {
		this.dragging = this.dragHandle = false

		if (this.model.hasChanged('answer')) {
			this.model.needsSaving = true
		}
	},

	ignoreClick: function(e) {
		e.stopPropagation()
	},

	radioChange: function(e) {
		if (e.currentTarget.checked) {
			var el       = $(e.currentTarget),
				option   = el.data('option'),
				category = el.data('category'),
				answer   = this.model.get('answer'),
				options  = this.model.get('options')

			// Pad answer array to correct length
			while (answer.length < options.length) {
				answer.push(0)
			}

			// Update changed answer
			answer[option] = category
			this.model.save()
		}
	}
})
