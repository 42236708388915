<!-- Advanced Logic-->
<img width="25px" style="margin-bottom:5px;" class="developer-mode" src="/images/atlas/bear-icon.png"> <b
	class="developer-mode">Atlas Says:</b> <i class="developer-mode">"Be careful here, this field could break the app!"
</i></img>
<input type="text" class="developer-mode logic form-control" placeholder="logic syntax here..." maxlength="12288"
	data-selection={{selectorId}} style="width:100%" value="{{model.logic}}"></input>

{{#ifGreaterThan model.logic.length 12288 }}
<div class="mt-10 mb-10">
	<img width="25px" style="margin-bottom:5px;" src="/images/atlas/bear-icon.png"> <b>Atlas Says:</b> <i>Warning: Maximum
		logic string length has been met (12288 characters)</i></img>
</div>
{{/ifGreaterThan}}

<!-- Fancy Ui -->
<div class="js-logic-builder"></div>