var StormObject = require('editor/storm-object')

module.exports = Backbone.View.extend({
	className: 'moduleBlock',
	template: require('./question-block-view-template'),

	attributes: function() {
		return {
			id: this.model.get('id')
		}
	},

	/** @override */
	events: {
		'input .text-content': 'updateText',
		'input .value-content': 'updateResponse',
		'input .response-content': 'updateResponse',
		'click .add-response': 'addResponse',
		'click .delete-response': 'deleteResponse',
		'input .identifier': 'updateIdentifier',
		'change .select-section': 'updateResponse'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.id = options.id
		this.selectorId = options.selectorId
		this.parent = options.parent
		this.language = options.language
		this.index = options.index
		this.sectionIndex = options.sectionIndex
	},

	ready: function() {
	},

	getRenderData: function() {
		return {
			model: this.model.toJSON(),
			selectorId: this.selectorId,
			numberOfSections: this.parent.parent.sections.length,
			sectionNumber: this.parent.sectionNumber,
			index: this.index,
			sectionIndex: this.sectionIndex,
			language: this.language
		}
	},

	afterRender: function() {
		this.model.get('options').forEach(function(option, key) {
			if (!option.get('section')) {
				option.set('section', $('.select-section[data-key=' + key + '][data-selector=' + this.selectorId + ']').val())
			}
		})
	},

	updateText: function(e) {
		var value = $(e.currentTarget).html()
		var data = this.model.get('text')
		data.content[this.language] = value
		this.model.trigger('change')
	},

	addResponse: function() {
		var newOption = StormObject.fromClassName('R4b_AssessmentOption', this.parent.parent.id)
		this.model.get('options').push(newOption)
		this.render()
		this.model.trigger('change')
	},

	updateResponse: function(e) {
		// Get the key for the currentTarget's place in the options array
		var key = $(e.currentTarget).data('key')

		// Get objects that need stripping
		var $value = $('.value-content[data-key=' + key + '][data-selector=' + this.selectorId + ']')
		var $response = $('.response-content[data-key=' + key + '][data-selector=' + this.selectorId + ']')

		// Get all the values for the response
		var value = this.stripTags($value[0])
		var response = this.stripTags($response[0])
		// $value.html(value)
		// $response.html(response)
		var section = parseInt($('.select-section[data-key=' + key + '][data-selector=' + this.selectorId + ']').val(), 10)
		// Get the options
		var options = this.model.get('options').toJSON()

		// Set the values
		options[key].value.content[this.language] = value
		options[key].response.content[this.language] = response
		options[key].section = section

		this.model.set('options', options)
		this.model.trigger('change')
	},

	deleteResponse: function(e) {
		var key = $(e.currentTarget).data('key')
		var id = $(e.currentTarget).data('id')

		// Get the options
		var options = this.model.get('options').toJSON()

		// Delete object if it exists
		if (id) {
			App.startLoad()
			var model = this.model.get('options').get(id)
			this.destroyOption(model)
		} else {
			// Splice
			options.splice(key, 1)
			this.model.set('options', options)
			this.model.trigger('change')
			this.render()
		}
	},

	destroyOption: function(option) {
		option.destroy({success: function() {
			App.stopLoad()
			this.render()
		}.bind(this)})
	},

	updateIdentifier: function(e) {
		var value = $(e.currentTarget).val()
		this.model.set('identifier', value)
		this.model.trigger('change')
	},

	stripTags: function(element) {
		// inner Text does not work in firefox so...
		if (element.innerText) {
			return element.innerText
		} else if (element.html) {
			return element.html().replace(/<br>/g, '\n')
		}
		return ''
	}
})
