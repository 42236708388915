var DevPool = require('./dev-pool'),
	LocaleList = require('editor/locale-list'),
	AppList = require('editor/app-list'),
	GDPCSectionView = require('./gdpc-section-view')

module.exports = GDPCSectionView.extend({
	template: require('./dev-pool-edit-view-template'),

	events: {
		'change #dev-pool-name': 'updateName',
		'change .stage-date': 'dateChange',
		'click .remove': 'removeStage',
		'change .new-app-country': 'changeAppCountry',
		'click .add-app': 'addApp',
		'click .save': 'save'
	},

	initialize: function(options) {
		this._readyCount = 0
		this._totalReadyCount = 3
		this.datePickers = []

		this.appId = options.appId

		if (options.id === 'new') {
			this.model = new DevPool()
		} else {
			this.model = new DevPool({id: options.id})
		}

		this.details = this.model.details

		if (options.id !== 'new') {
			this._totalReadyCount += 2

			this.details.once('sync', this.ready, this)
			this.details.fetch()

			this.model.once('sync', this.ready, this)
			this.model.fetch()
		}

		this.stageList = this.model.stageList
		this.stageList.once('sync', this.ready, this)
		this.stageList.fetch()

		this.localeList = new LocaleList()
		this.localeList.once('sync', this.ready, this)
		this.localeList.fetch()

		this.appList = new AppList({id: 'all'})
		this.appList.once('sync', this.ready, this)
		this.appList.fetch()
	},

	getPageTitle: function() {
		return $.t('gdpcConsole.devPools.editPool')
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.stages = this.stageList.toJSON()

		// Sort language list by code for dropdown
		var languages = this.localeList.toJSON().sort(function(a, b) {
			if (a.code < b.code) {
				return -1
			}

			if (a.code > b.code) {
				return 1
			}

			return 0
		})

		data.apps = this.appList.toJSON()
		data.languages = languages
		data.details = this.model.details.toJSON()
		data.societies = App.societiesList.sortBy(function(society) {
			return society.get('description')
		}).map(function(society) {
			return society.toJSON()
		})

		// Cross-reference App
		data.details.forEach(function(detail) {
			var app = this.appList.get(detail.appId)
			detail.appName = (app) ? App.l(app.get('name')) : '-'
		}, this)

		data.appId = this.appId

		return data
	},

	afterRender: function() {
		// Initialise datepickers
		this.stageList.each(function(stage, i) {
			var date = new Date(stage.get('timestamp') * 1000)

			this.datePickers[i] = new Pikaday({
				field: this.$('tr[data-index=' + i + '] .stage-date')[0],
				bound: true
			})

			this.datePickers[i].setDate(date)
		}, this)
	},

	ready: function() {
		if (++this._readyCount === this._totalReadyCount) {
			App.stopLoad()

			// For new pools, need to transform global list of stages into local
			if (this.model.isNew()) {
				this.stageList.each(function(stage) {
					stage.set('stageId', stage.id)
					stage.set('timestamp', parseInt(Date.now() / 1000, 10))

					stage.unset('id')
					stage.unset('description')
					stage.unset('position')
					stage.unset('status')
				})
			}

			this.render()
		}
	},

	updateName: function() {
		var name = this.$('#dev-pool-name').val()
		this.model.set('name', name)
	},

	changeAppCountry: function() {
		var country = parseInt(this.$('.new-app-country').val(), 10)
		var apps = this.appList.where({societyId: country})

		var options = ''

		apps.forEach(function(app) {
			options += '<option value="' + app.id + '">' + App.l({content: app.get('name')}) + '</option>'
		})

		this.$('.new-app-app').html(options)
	},

	addApp: function() {
		var appId = parseInt(this.$('.new-app-app').val(), 10)
		var societyId = parseInt(this.$('.new-app-country').val(), 10)
		var languageId = parseInt(this.$('.new-app-lang').val(), 10)

		var language = this.localeList.get(languageId)

		if (!isNaN(appId)) {
			var languages = {}
			languages[language.get('code')] = {}

			// Push onto details model to update preview
			this.model.details.push({
				appId: appId,
				societyId: societyId,
				languageId: languageId,
				languages: languages
			})

			this.render()
		}
	},

	dateChange: function(e) {
		var index = $(e.currentTarget).parent().parent().data('index')
		var date = this.datePickers[index].getDate()
		var stage = this.stageList.at(index)
		stage.set('timestamp', date.getTime() / 1000)
	},

	save: function() {
		App.startLoad()

		var requests = []

		// Disable controls
		this.$('button, select, input').attr('disabled', true)

		// Update pool name, if changed
		this.model.save()
		this.model.once('sync', function() {
			// Update stages
			this.stageList.save()
			this.stageList.once('sync', function() {
				// Add new apps to the dev pool, if needed
				this.model.details.forEach(function(app) {
					if (!app.has('code')) {
						var data = {
							appId: app.get('appId'),
							languageId: app.get('languageId')
						}

						var request = Backbone.sync('update', new Backbone.Model(), {
							url: this.model.url() + '/app',
							data: JSON.stringify(data)
						})

						requests.push(request)
					}
				}, this)

				$.when.apply($, requests).then(saveDone)
			}, this)
		}, this)

		var appId = this.appId

		function saveDone() {
			App.stopLoad()
			App.router.navigate('/apps/' + appId + '/pools', {trigger: true})
		}
	}
})
