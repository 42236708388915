var StormView = require('lib/storm-view')

/**
 * New App copy view. No longer using the legacy app copy.
 * This page Retrieves the audit if the selected app is being copied. Shows the status.
 * @module
 */
module.exports = StormView.extend(/** @lends DashboardSectionView.prototype */{
	template: require('./app-copy-view-template'),

	initialize: function(options) {
		this.auditId = options.auditId | 'No ID'
		var requests = []
		requests.push(this.getAudit())
		Promise.all(requests).then(this.render.bind(this)).catch(this.render.bind(this)).then(this.setPolling.bind(this))
	},

	// Get Audit Data, returns all the stages with amount done.
	getAudit: function() {
		return new Promise(
			function(resolve, reject) {
				$.ajax({
					url: App.apiRoot + 'audit/' + this.auditId,

					headers: App.session.getHeadersObject(),

					success: function(data) {
						this.auditData = data
						resolve()
					}.bind(this),

					error: function(jqXHR) {
						this.error = jqXHR.status
						reject(jqXHR)
					}.bind(this)
				})
			}.bind(this)
		)
	},

	getRenderData: function() {
		return {
			auditId: this.auditId,
			audit: this.auditData,
			error: this.error
		}
	},

	afterRender: function() {
		App.stopLoad()
	},

	// Setup polling of 5 seconds. Cancelled when the view gets desyr
	setPolling: function() {
		if (!this.error) {
			this.updateProgressBars()
			this.auditInterval = setInterval(function() {
				this.getAudit().then(this.updateProgressBars.bind(this)).then(function() {
					// If we've finished stop the polling.
					if (this.isAuditFinished()) {
						this.finished()
					}
				}.bind(this))
			}.bind(this), 5000)
		}
	},

	updateProgressBars: function() {
		var self = this
		$('.app-copy-progress').each(function() {
			var $progress = $(this)
			var stage = self.getAuditProgressByName($progress.data('name'))
			$progress.attr('value', stage.completed)
			$progress.attr('max', stage.total)
			var percent
			if (stage.total !== 0) {
				percent = Math.round((stage.completed / stage.total) * 100)
			} else {
				// If total is 0 it means there's nothing to copy.. just show user 100%;
				$progress.attr('value', 1)
				$progress.attr('max', 1)
				percent = 100
			}
			$progress.siblings('label').children('span').text(percent + '%')
			if (percent === 100) {
				$progress.siblings('label').children('img').hide()
			}
		})
	},

	// When the app copy is finished.
	finished: function() {
		clearInterval(this.auditInterval)

		swal({
			title: $.t('login.success.title'),
			text: 'App copy complete',
			type: 'success'
		}, function(didConfirm) {
			if (didConfirm) {
				location.href = '/apps/' + Storm.app.id + '/settings'
			}
		})
	},

	getAuditProgressByName: function(name) {
		var returnStage = null
		if (this.auditData) {
			this.auditData.details.stages.forEach(function(stage) {
				if (stage.name === name) {
					returnStage = stage
				}
			})
		}
		return returnStage
	},

	isAuditFinished: function() {
		var finished = true
		if (this.auditData) {
			this.auditData.details.stages.forEach(function(stage) {
				if (stage.completed !== stage.total) {
					finished = false
				}
			})
		}
		return finished
	},

	remove: function() {
		clearInterval(this.auditInterval)
		Backbone.View.prototype.remove.apply(this, arguments)
	}
})
