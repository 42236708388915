var PasswordResetView            = require('auth/password-reset-view'),
	UserAgreementView            = require('user-agreement/user-agreement-view'),
	UsersView                    = require('users/users-view'),
	UserAppsView                 = require('users/user-apps-view'),
	RolesView                    = require('permissions/roles-view'),
	RolePermissionsView          = require('permissions/role-permissions-view'),
	ManageAppsView               = require('manage-apps/manage-apps-view'),
	LegacyAppCreateView          = require('app-setup/app-create-view'),
	AppCopyView                  = require('app-setup/app-copy-view'),
	SetupView                    = require('app-setup/app-setup-view'),
	MetadataView                 = require('metadata/metadata-view'),
	LocalisationView             = require('app-setup/localisation-view'),
	PropertiesView               = require('app-setup/properties-view'),
	PluginsView                  = require('app-setup/plugins-view'),
	EditorView                   = require('editor/editor-view'),
	NoAppsView                   = require('editor/no-apps-view'),
	MediaLibraryWrapperView      = require('media-library/media-library-wrapper-view'),
	StormQLListView              = require('stormql/stormql-list-view'),
	StormQLBloodListView         = require('journey/stormql-blood-list-view'),
	StormQLPostListView          = require('jg-posts/stormql-post-list-view'),
	PublishView                  = require('publish/publish-view'),
	BugsView                     = require('bugs/bugs-view'),
	BugView                      = require('bugs/bug-view'),
	BugCreateView                = require('bugs/bug-create-view'),
	DecisionTreeView             = require('decision-tree/decision-tree-view'),
	MailingListView              = require('mailing-list/mailing-list-view'),
	StoriesView                  = require('stories/stories-view'),
	BadgesView                   = require('badges/badges-view'),
	BadgeEditView                = require('badges/badge-edit-view'),
	LevelsView                   = require('levels/levels-view'),
	LevelEditView                = require('levels/level-edit-view'),
	PushView                     = require('push/push-view'),
	PushEditView                 = require('push/push-edit-view'),
	PushDetailsView              = require('push/push-details-view'),
	SprintView                   = require('sprint/sprint-view'),
	SocietiesView                = require('gdpc-console/societies-view'),
	SocietiesEditView            = require('gdpc-console/societies-edit-view'),
	DevPoolListView              = require('gdpc-console/dev-pool-list-view'),
	DevPoolEditView              = require('gdpc-console/dev-pool-edit-view'),
	DevPoolCountryView           = require('gdpc-console/dev-pool-country-view'),
	NationalSocietyStatusView    = require('gdpc-console/society-status-view'),
	AlertsView                   = require('hazards/alerts-view'),
	AlertEditView                = require('hazards/alert-edit-view'),
	AlertTestView                = require('hazards/alert-test-view'),
	GeneralNotificationsView     = require('hazards/general-notifications-view'),
	GeneralNotificationsEditView = require('hazards/general-notifications-edit-view'),
	LegacyDisastersView          = require('hazards/disasters-view'),
	DisastersView                = require('hazards/disasters/disaster-view'),
	DisastersEventEditView       = require('hazards/disasters/disaster-events-edit-view'),
	DeploymentView               = require('deployment/deployment-view'),
	ServerDeploymentView         = require('deployment/server-deployment-view'),
	ServerDeploymentEditView     = require('deployment/server-deployment-edit-view'),
	OrganizationView             = require('admin/organization-view'),
	ImageModerationView          = require('moderation/image-moderation-view'),
	CrossBordersView             = require('cross-borders/cross-borders-view'),
	CrossBordersEditView         = require('cross-borders/cross-borders-edit-view'),
	CrossBordersEditContactView  = require('cross-borders/contact/contact-edit-view'),
	CrossBordersEditPhraseView   = require('cross-borders/phrase/phrase-edit-view'),
	AchievementsView             = require('achievements/achievements-view'),
	AchievementEditView          = require('achievements/achievement-edit-view'),
	AlertZonesView               = require('hazards/alert-zones-view'),
	AlertZonesEditView           = require('hazards/alert-zones-edit-view'),
	FeedsView                    = require('hazards/feeds/feeds-view'),
	ArproCodeGroupView           = require('arpro/code-groups/code-group-view'),
	ArproCodeGroupEditView       = require('arpro/code-groups/code-group-edit-view'),
	ArproProductSearchView       = require('arpro/product-search/product-search-view'),
	ArproProductSearchEditView   = require('arpro/product-search/product-search-edit-view'),
	ArproColourView					 		 = require('arpro/product-colour/product-colour-view'),
	ArproProductView             = require('arpro/products/product-view'),
	ArproProductEditView         = require('arpro/products/product-edit-view'),
	LocalisationTableView        = require('app-setup/translation-table/localisation-table-view'),
	ImportExportTranslationsView = require('app-setup/translation-table/import-export/import-export-view'),
	AtlasAdminView               = require('atlas/admin-view'),
	AtlasModuleEditView          = require('atlas/module-edit-view'),
	RspbFactFileView             = require('rspb/fact-files/fact-file-view'),
	RspbFactFileEditView         = require('rspb/fact-files/fact-file-edit-view'),
	RspbPlanItemView             = require('rspb/plan-items/plan-item-view'),
	RspbPlanItemEditView         = require('rspb/plan-items/plan-item-edit-view'),
	ProductsView                 = require('products/products-view'),
	ProductEditView              = require('products/product-edit-view'),
	SponsorsView                 = require('sponsorship/sponsor-view'),
	SponsorEditView              = require('sponsorship/sponsor-edit-view'),
	FacilityView                 = require('facilities/facility-view'),
	FacilityEditView             = require('facilities/facility-edit-view'),
	BloodStories                 = require('blood-stories/stories-view'),
	BloodStoriesEditView         = require('blood-stories/story-edit-view'),
	JourneyView                  = require('journies/journey-view'),
	JourneyEditView              = require('journies/journey-edit-view'),
	FrameView                    = require('frames/frames-view'),
	FrameEditView                = require('frames/frames-edit-view'),
	SwimView                     = require('swim-levels/level-view'),
	SwimEditView                 = require('swim-levels/level-edit-view'),
	QuizCompletionView           = require('quiz-completion/quiz-completion-view'),
	RichStoriesView              = require('rich-stories/rich-stories-view'),
	RichStoriesEditView          = require('rich-stories/rich-stories-edit-view'),
	FindCoursesView              = require('find-courses/find-courses-view'),
	FindCoursesEditView          = require('find-courses/find-courses-edit-view'),
	SearchTermsView              = require('search-terms/search-terms-view'),
	SearchTermsEditView          = require('search-terms/search-terms-edit-view'),
	CategoryEditView             = require('atlas/category-edit-view')

module.exports = Backbone.Router.extend({
	routes: {
		/* Content editor */
		'': 'redirectToContent',
		'apps': 'manageApps',
		'apps/:id/pages(/:pageId)(/)': 'content',
		'apps/new': 'appCreate',
		'apps/:id/copy(/:auditId)(/)': 'appCopy',

		/* Standalone media library */
		'apps/:id/media': 'mediaLibrary',

		/* StormQL editing */
		// 'apps/:id/stories': 'stormqlStories',
		// 'apps/:id/journey': 'stormqlBlood',
		// 'apps/:id/frames': 'stormqlFrames',

		/* Admin */
		'organization/new': 'newOrganization',
		'apps/:id/organization': 'organization',
		'apps/:id/users': 'users',
		'apps/:id/users/:id/apps': 'userApps',
		'roles': 'roles',
		'roles/:id/permissions': 'rolePermissions',

		/* App setup */
		'apps/:id/settings': 'setup',
		'apps/:id/metadata': 'metadata',
		'apps/:id/localizations': 'localisation',
		'apps/:id/properties': 'properties',
		'apps/:id/plugins': 'plugins',
		'apps/:id/settings/translations': 'localisationTable',
		'apps/:id/settings/translations/import': 'importTranslations',

		/* Publishing */
		'apps/:id/publish': 'publish',

		/* Badges */
		'apps/:id/badges': 'badges',
		'apps/:id/badges/:id': 'badgeEdit',

		/* Achievements */
		'apps/:id/achievements': 'achievements',
		'apps/:id/achievements/:id': 'achievementsEdit',

		/* Push notifications */
		'apps/:id/push': 'push',
		'apps/:id/push/:id': 'pushEdit',
		'apps/:id/push/:id/details': 'pushDetails',

		/* Hazards */
		'apps/:id/hazards': 'hazardsDisasters',
		'apps/:id/hazards/events': 'hazardsDisastersEventsEdit',
		'apps/:id/alerts': 'hazardsAlerts',
		'apps/:id/alerts/new': 'hazardsAlertsEdit',
		'apps/:id/alerts/test': 'hazardsAlertsTest',
		'apps/:id/general-notifications': 'hazardsGeneralNotifications',
		'apps/:id/general-notifications/new': 'hazardsGeneralNotificationsEdit',
		'apps/:id/alert-zones': 'hazardsAlertZones',
		'apps/:id/alert-zones/:id': 'hazardsAlertZonesEdit',
		'apps/:id/feeds': 'hazardsFeeds',

		/* Crowd-sourced data moderation */
		'apps/:id/moderation': 'imageModeration',

		/* Authentication */
		'login': 'login',
		'logout': 'logout',
		'activation/:flag': 'passwordReset',
		'password/:flag': 'passwordReset',

		/* User agreement */
		'apps/:id/user-agreement': 'userAgreement',

		/* Bug tracking */
		'bugs': 'bugs',
		'bugs/new': 'bugCreate',
		'bugs/:id': 'bugView',

		/* ASPCA-specific */
		'apps/:appId/decision-tree(/:id)': 'decisionTree',
		'apps/:id/mailing-list': 'mailingList',
		'stories': 'stories',

		/* ARC-specific */
		'sprint': 'sprintView',

		/* ARC-Swim specific */
		'apps/:id/levels': 'levels',
		'apps/:id/levels/:id': 'levelEdit',
		'apps/:id/levels/:parentId/:id': 'levelEdit',

		/* GDPC-specific */
		'apps/:id/societies': 'societies',
		'apps/:id/societies/:id': 'societiesEdit',
		'apps/:id/pools': 'devPools',
		'apps/:id/pools/me': 'devPoolsMe',
		'apps/:id/pools/:pid': 'devPoolEdit',
		'apps/:id/pools/:pid/details/:sc': 'devPoolCountry',

		/* Just Giving-specific */
		'apps/:id/posts': 'posts',

		/* Deployment */
		'(apps//)deployment': 'deployment',
		'(apps//)deployment/server': 'serverDeployment',
		'(apps//)deployment/server/:id': 'serverDeploymentEdit',

		/* Temporary StormCorp app creation page for Apps World */
		'apps-world': 'appsWorld',

		/* Cross Borders */
		'apps/:id/cross-borders': 'crossBorders',
		'apps/:id/cross-borders/:id': 'crossBordersEdit',
		'apps/:id/cross-borders/contact/:parentId': 'crossBordersEditContact',
		'apps/:id/cross-borders/contact/:parentId/:id': 'crossBordersEditContact',
		'apps/:id/cross-borders/phrase/:parentId': 'crossBordersEditPhrase',
		'apps/:id/cross-borders/phrase/:parentId/:id': 'crossBordersEditPhrase',

		/* Arpro */
		'apps/:appId/code-groups': 'codeGroups',
		'apps/:appId/code-groups/new': 'codeGroupsNew',
		'apps/:appId/code-groups/:id': 'codeGroupsEdit',
		'apps/:appId/product-search': 'productSearch',
		'apps/:appId/product-search/new': 'productSearchNew',
		'apps/:appId/product-search/:id': 'productSearchEdit',
		'apps/:appId/products': 'products',
		'apps/:appId/products/new': 'productsNew',
		'apps/:appId/products/:id': 'productsEdit',
		'apps/:appId/product-colours': 'productColours',
		'apps/:appId/product-colours/:id': 'productEditColours',

		/* Atlas */
		'apps/:appId/atlas_admin': 'atlasAdmin',
		'apps/:appId/atlas_admin/:id': 'atlasModuleEditView',
		'apps/:appId/atlas_admin/category/:id': 'atlasCategoryEditView',

		/* Rspb */
		'apps/:appId/fact-files': 'factFiles',
		'apps/:appId/fact-files/new': 'factFilesNew',
		'apps/:appId/fact-files/:id': 'factFilesEdit',
		'apps/:appId/plan-items': 'planItems',
		'apps/:appId/plan-items/new': 'planItemsNew',
		'apps/:appId/plan-items/:id': 'planItemsEdit',

		/* Facilities (Red Cross Offices) */
		'apps/:id/facilities/:search': 'facilities',
		'apps/:id/facilities/edit/:id': 'facilityEdit',

		/* Sponsorship */
		'apps/:id/sponsors': 'sponsors',
		'apps/:id/sponsors/:id': 'sponsorEdit',

		/* Stories */
		'apps/:id/stories': 'bloodStories',
		'apps/:id/stories/:id': 'bloodStoriesEdit',

		/* Journey */
		'apps/:id/journey': 'journeyView',
		'apps/:id/journey/:id': 'journeyEdit',

		/* Frames */
		'apps/:id/frames': 'framesView',
		'apps/:id/frames/:id': 'frameEdit',

		/* Swim */
		'apps/:id/swim-levels': 'swimLevels',
		'apps/:id/swim-levels/:id': 'swimLevelsEdit',
		'apps/:id/swim-levels/:parentId/:id': 'swimLevelsEdit',

		/* ARC Products */
		'apps/:id/products-shop': 'productShop',
		'apps/:id/products-shop/:id': 'productShopItemEdit',

		/* Quiz Completion */
		'apps/:id/quiz-completion': 'quizCompletion',

		/* Rich Stories */
		'apps/:id/rich-stories': 'richStoriesView',
		'apps/:id/rich-stories/:id': 'richStoriesEdit',

		/* Find Courses */
		'apps/:id/find-courses': 'findACourseView',
		'apps/:id/find-courses/:id': 'findACourseEdit',

		/* Search Terms */
		'apps/:id/search-terms': 'searchTermsView',
		'apps/:id/search-terms/:id': 'searchTermsEdit',

		/* Misc */
		'developer': 'developerMode',
		'*nomatch': 'notFound'
	},

	setPage: function(view) {
		console.warn('WARNING: setting page using old router.setPage() method. View will not render correctly into the navigation. Update the view and switch to Storm.setPage()')

		// Clear old view and all attached events
		if (App.view) {
			App.view.destroy()
		}

		// Check login status
		if (!App.session.isAuthenticated()) {
			// Redirect to login
			this.navigate('login', {
				trigger: true,
				replace: true
			})

			view.destroy()
			return false
		}

		// Show loading indicator
		App.startLoad()

		// Insert new view
		App.view = view
		$('#container').html(App.view.render().el)

		// If we have a modal backdrop left over, clear it.
		$('.modal-backdrop').remove()
	},

	login: function() {
		// Show login overlay, hide nav
		App.login.show()
		$('#navigation').hide()
	},

	logout: function() {
		if (App.session.isAuthenticated()) {
			localStorage.removeItem('auth')
			document.location = '/'
		} else {
			this.navigate('', {trigger: true})
		}
	},

	passwordReset: function(flag) {
		var view = new PasswordResetView({flag: flag})

		view.render()
	},

	userAgreement: function(appId) {
		Storm.setApp(appId)
		var view = new UserAgreementView({appId: appId})

		Storm.setPage(view)
	},

	organization: function(appId) {
		var view = new OrganizationView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	users: function(appId) {
		Storm.setApp(appId)
		var view = new UsersView({app: Storm.app})

		Storm.setPage(view)
	},

	userApps: function(appId, id) {
		Storm.setApp(appId)
		var view = new UserAppsView({
			id: id,
			App: Storm.app
		})

		Storm.setPage(view)
	},

	roles: function() {
		var view = new RolesView()

		this.setPage(view)
	},

	rolePermissions: function(id) {
		var view = new RolePermissionsView({id: id})

		this.setPage(view)
	},

	manageApps: function() {
		var view = new ManageAppsView({appList: App.appList})

		Storm.setPage(view, true)
	},

	appCreate: function() {
		var view = new LegacyAppCreateView()

		Storm.setPage(view, true)
	},

	appCopy: function(appId, auditId) {
		Storm.setApp(appId)
		var view = new AppCopyView({auditId: auditId})

		Storm.setPage(view, true)
	},

	setup: function(appId) {
		Storm.setApp(appId)
		var view = new SetupView({app: Storm.app})

		Storm.setPage(view)
	},

	metadata: function(appId) {
		Storm.setApp(appId)
		var view = new MetadataView({appId: appId})

		Storm.setPage(view)
	},

	localisation: function(appId) {
		var view = new LocalisationView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	properties: function(appId) {
		Storm.setApp(appId)
		var view = new PropertiesView({appId: appId})

		Storm.setPage(view)
	},

	plugins: function(appId) {
		Storm.setApp(appId)
		var view = new PluginsView({appId: appId})

		Storm.setPage(view)
	},

	content: function(id, pageId) {
		var previousAppId = Storm.app && Storm.app.id ? Storm.app.id : null

		if (!Storm.setApp(id)) {
			// Unable to set app - doesn't exist. Let it redirect.
			return
		}

		if (!pageId) {
			pageId = Storm.app.get('objectId')
		}

		if (Storm.view instanceof EditorView && Storm.app.id === previousAppId) {
			Storm.view.setPageId(pageId)
		} else {
			var view = new EditorView({
				appId: id,
				pageId: pageId
			})

			Storm.setPage(view)
		}
	},

	stormqlStories: function(appId) {
		Storm.setApp(appId)
		var view = new StormQLListView({
			app: Storm.app,
			slug: 'stories',
			className: 'StormQLStory',
			fetchData: {
				data: {
					class: 'StormQLStory'
				}
			}
		})

		this.setPage(view)
	},

	stormqlBlood: function(appId) {
		Storm.setApp(appId)
		var view = new StormQLBloodListView({
			app: Storm.app,
			slug: 'journey',
			className: 'StormQLBloodStage',
			fetchData: {
				data: {
					class: 'StormQLBloodStage'
				}
			}
		})

		this.setPage(view)
	},

	stormqlFrames: function(appId) {
		Storm.setApp(appId)
		var view = new StormQLListView({
			app: Storm.app,
			slug: 'frames',
			className: 'StormQLFrame',
			fetchData: {
				data: {
					class: 'StormQLFrame'
				}
			}
		})

		view.template = require('frames/stormql-frames-list-view-template')

		this.setPage(view)
	},

	mediaLibrary: function(appId) {
		var view = new MediaLibraryWrapperView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	publish: function(appId) {
		var view = new PublishView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	decisionTree: function(appId, id) {
		Storm.setApp(appId)
		var view = new DecisionTreeView({
			app: Storm.app,
			id: id
		})

		Storm.setPage(view)
	},

	mailingList: function(appId) {
		var view = new MailingListView({
			appId: appId
		})

		this.setPage(view)
		Storm.setApp(appId)
	},

	stories: function() {
		var view = new StoriesView()

		this.setPage(view)
	},

	badges: function(appId) {
		var view = new BadgesView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	badgeEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new BadgeEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	achievements: function(appId) {
		var view = new AchievementsView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	achievementsEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new AchievementEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	levels: function(appId) {
		// Don't allow levels to be accessed outside of SWIM
		if (App.system.id !== 9) {
			App.router.navigate('/', {trigger: true, replace: true})
			return
		}

		var view = new LevelsView({appId: appId})

		this.setPage(view)
		Storm.setApp(appId)
	},

	levelEdit: function(appId, parentId, id) {
		// Don't allow levels to be accessed outside of SWIM
		if (App.system.id !== 9) {
			this.navigate('/', {trigger: true, replace: true})
			return
		}

		if (!id) {
			id = parentId
			parentId = 0
		}

		var view = new LevelEditView({
			appId: appId,
			id: id,
			parentId: parentId
		})

		this.setPage(view)
		Storm.setApp(appId)
	},

	push: function(appId, showAll) {
		Storm.setApp(appId)

		var view = new PushView({
			app: Storm.app,
			showAll: showAll
		})

		Storm.setPage(view)
	},

	pushEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new PushEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	pushDetails: function(appId, id) {
		Storm.setApp(appId)
		var view = new PushDetailsView({id: id, appId: appId})

		Storm.setPage(view)
	},

	sprintView: function() {
		var view = new SprintView()

		this.setPage(view)
	},

	societies: function(appId) {
		Storm.setApp(appId)
		var view = new SocietiesView({appId: appId})

		Storm.setPage(view)
	},

	societiesEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new SocietiesEditView({id: id, appId: appId})

		Storm.setPage(view)
	},

	newOrganization: function() {
		var view = new SocietiesEditView({id: 'new'})

		Storm.setPage(view)
	},

	devPools: function(appId) {
		Storm.setApp(appId)
		var view = new DevPoolListView({appId: appId})

		Storm.setPage(view)
	},

	devPoolsMe: function(appId) {
		Storm.setApp(appId)
		var view = new NationalSocietyStatusView({appId: appId})

		Storm.setPage(view)
	},

	devPoolEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new DevPoolEditView({id: id, appId: appId})

		Storm.setPage(view)
	},

	devPoolCountry: function(appId, id, societyId) {
		Storm.setApp(appId)
		var view = new DevPoolCountryView({
			id: id,
			societyId: societyId,
			appId: appId
		})

		Storm.setPage(view)
	},

	posts: function(appId) {
		Storm.setApp(appId)
		var view = new StormQLPostListView({
			app: Storm.app,
			slug: 'posts',
			className: 'Post',
			fetchData: {
				data: {
					class: 'Post'
				}
			}
		})

		Storm.setPage(view)
	},

	bugs: function() {
		var view = new BugsView()

		this.setPage(view)
	},

	bugView: function(id) {
		var view = new BugView({id: id})

		this.setPage(view)
	},

	bugCreate: function() {
		var view = new BugCreateView()

		this.setPage(view)
	},

	hazardsDisasters: function(appId) {
		Storm.setApp(appId)
		var view
		if (App && App.system && App.system.id === 3) { // GDPC
			view = new DisastersView({appId: appId})
		} else {
			view = new LegacyDisastersView({appId: appId})
		}

		Storm.setPage(view)
	},

	hazardsDisastersEventsEdit: function(appId) {
		Storm.setApp(appId)
		var view = new DisastersEventEditView({appId: appId})

		Storm.setPage(view)
	},

	hazardsAlerts: function(appId) {
		Storm.setApp(appId)
		var view = new AlertsView({appId: appId})

		Storm.setPage(view)
	},

	hazardsAlertsEdit: function(appId) {
		Storm.setApp(appId)
		var view = new AlertEditView({app: Storm.app})

		Storm.setPage(view)
	},

	hazardsAlertsTest: function(appId) {
		Storm.setApp(appId)
		var view = new AlertTestView({appId: appId})

		Storm.setPage(view)
	},

	hazardsGeneralNotifications: function(appId) {
		Storm.setApp(appId)
		var view = new GeneralNotificationsView({appId: appId})

		Storm.setPage(view)
	},

	hazardsGeneralNotificationsEdit: function(appId) {
		Storm.setApp(appId)

		var view = new GeneralNotificationsEditView({app: Storm.app})
		Storm.setPage(view)
	},

	hazardsAlertZones: function(appId) {
		Storm.setApp(appId)
		Storm.setPage(new AlertZonesView({app: Storm.app}))
	},

	hazardsAlertZonesEdit: function(appId, id) {
		Storm.setApp(appId)
		Storm.setPage(new AlertZonesEditView({
			app: Storm.app,
			id: id
		}))
	},

	hazardsFeeds: function(appId) {
		Storm.setApp(appId)
		Storm.setPage(new FeedsView({
			app: Storm.app
		}))
	},

	deployment: function() {
		var view = new DeploymentView()

		Storm.setPage(view)
	},

	serverDeployment: function() {
		var view = new ServerDeploymentView()

		Storm.setPage(view)
	},

	serverDeploymentEdit: function(name) {
		var view = new ServerDeploymentEditView({name: name})

		Storm.setPage(view)
	},

	imageModeration: function(appId) {
		Storm.setApp(appId)
		var view = new ImageModerationView({appId: appId})

		Storm.setPage(view)
	},

	notFound: function() {
		// Route not found - redirect to root
		this.navigate('/', {trigger: true})
	},

	developerMode: function() {
		localStorage.setItem('developerMode', true)
		swal('Success', 'Developer mode activated!', 'success')
		document.location = '/'
	},

	redirectToContent: function() {
		var app = App.getCurrentApp()

		if (app) {
			Storm.setApp(app.id)

			var url

			// Redirect to App Setup for legacy-type apps.
			if (app.isLegacyApp()) {
				url = 'setup'
			} else {
				url = 'pages'
			}

			// Redirect to alerts for "Hazards (Alerts)" permission on GDPC.
			var acl        = App.acl,
				hasContent = acl.hasAccess('Content'),
				hasAlerts  = acl.hasAccess('Hazards', 'Alerts')

			if (!hasContent && hasAlerts) {
				url = 'alerts'
			}

			this.navigate('/apps/' + app.id + '/' + url, {
				trigger: true,
				replace: true
			})
		} else {
			var view = new NoAppsView()
			Storm.setPage(view)
		}
	},

	appsWorld: function() {
		// App creation frontend only available on password protected internal
		// server.
		document.location = 'https://internal.stormcorp.co/apps-world'
	},

	crossBorders: function(appId) {
		var view = new CrossBordersView({appId: appId})
		Storm.setApp(appId)
		Storm.setPage(view)
	},

	crossBordersEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new CrossBordersEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},
	crossBordersEditContact: function(appId, parentId, id) {
		Storm.setApp(appId)

		var view = new CrossBordersEditContactView({
			parentId: parentId,
			id: id,
			app: Storm.app
		})

		Storm.setPage(view)
	},
	crossBordersEditPhrase: function(appId, parentId, id) {
		Storm.setApp(appId)
		var view = new CrossBordersEditPhraseView({
			parentId: parentId,
			id: id,
			app: Storm.app
		})

		Storm.setPage(view)
	},

	/* ARPRO Routing here **/
	codeGroups: function(appId) {
		Storm.setApp(appId)
		var view = new ArproCodeGroupView({
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	codeGroupsEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new ArproCodeGroupEditView({
			id: id,
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	codeGroupsNew: function(appId) {
		Storm.setApp(appId)
		var view = new ArproCodeGroupEditView({
			id: 'new',
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	productSearch: function(appId) {
		Storm.setApp(appId)
		var view = new ArproProductSearchView({
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	productSearchEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new ArproProductSearchEditView({
			id: id,
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	productSearchNew: function(appId) {
		Storm.setApp(appId)
		var view = new ArproProductSearchEditView({
			id: 'new',
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	products: function(appId) {
		Storm.setApp(appId)
		var view = new ArproProductView({
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	productColours: function(appId) {
		Storm.setApp(appId)
		var view = new ArproColourView({
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	productsEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new ArproProductEditView({
			id: id,
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	productsNew: function(appId) {
		Storm.setApp(appId)
		var view = new ArproProductEditView({
			id: 'new',
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	localisationTable: function(id) {
		Storm.setApp(id)
		var view = new LocalisationTableView({appId: id})
		Storm.setPage(view)
	},

	importTranslations: function(id) {
		Storm.setApp(id)
		var view = new ImportExportTranslationsView({appId: id})
		Storm.setPage(view)
	},

	atlasAdmin: function(appId) {
		var view = new AtlasAdminView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	atlasModuleEditView: function(appId, id) {
		Storm.setApp(appId)
		var view = new AtlasModuleEditView({
			id: id,
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	atlasCategoryEditView: function(appId, id) {
		Storm.setApp(appId)
		var view = new CategoryEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	/* RSPB Routing here **/
	factFiles: function(appId) {
		Storm.setApp(appId)
		var view = new RspbFactFileView({
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	factFilesEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new RspbFactFileEditView({
			id: id,
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	factFilesNew: function(appId) {
		Storm.setApp(appId)
		var view = new RspbFactFileEditView({
			id: 'new',
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	/* RSPB Routing here **/
	planItems: function(appId) {
		Storm.setApp(appId)
		var view = new RspbPlanItemView({
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	planItemsEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new RspbPlanItemEditView({
			id: id,
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	planItemsNew: function(appId) {
		Storm.setApp(appId)
		var view = new RspbPlanItemEditView({
			id: 'new',
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	facilities: function(appId, type) {
		Storm.setApp(appId)

		var view = new FacilityView({
			appId: appId,
			app: Storm.app,
			searchData: {
				class: "Saf_Facility",
				properties: {
					type: type
				}
			}
		})

		Storm.setPage(view)
	},

	facilityEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new FacilityEditView({id: id, appId: appId})
		Storm.setPage(view)
	},

	sponsors: function(appId) {
		Storm.setApp(appId)

		var view = new SponsorsView({
			app: Storm.app,
			appId: appId,
			fetchData: {data: {class: 'StormQLSponsorship'}}
		})

		Storm.setPage(view)
	},

	sponsorEdit: function(appId, id) {
		var view = new SponsorEditView({id: id, appId: appId})
		Storm.setApp(appId)
		Storm.setPage(view)
	},

	bloodStories: function(appId) {
		var view = new BloodStories({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	bloodStoriesEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new BloodStoriesEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	journeyView: function(appId) {
		var view = new JourneyView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	journeyEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new JourneyEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	framesView: function(appId) {
		var view = new FrameView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	frameEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new FrameEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	swimLevels: function(appId) {
		// Don't allow levels to be accessed outside of SWIM
		if (App.system.id !== 9) {
			this.navigate('/', {trigger: true, replace: true})
			return
		}
		var view = new SwimView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	swimLevelsEdit: function(appId, parentId, id) {
		// Don't allow levels to be accessed outside of SWIM
		if (App.system.id !== 9) {
			this.navigate('/', {trigger: true, replace: true})
			return
		}

		if (!id) {
			id = parentId
			parentId = 0
		}

		Storm.setApp(appId)
		var view = new SwimEditView({
			id: id,
			app: Storm.app,
			parentId: parentId
		})

		Storm.setPage(view)
		Storm.setApp(appId)
	},

	productShop: function(appId) {
		var view = new ProductsView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	productShopItemEdit: function(appId, id) {
		Storm.setApp(appId)
		var view = new ProductEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	quizCompletion: function(appId) {
		Storm.setApp(appId)
		var view = new QuizCompletionView({
			app: Storm.app,
			appId: appId
		})
		Storm.setPage(view)
	},

	findACourseView: function(appId) {
		var view = new FindCoursesView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	findACourseEdit: function(appId, id) {
		Storm.setApp(appId)

		var view = new FindCoursesEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	richStoriesView: function(appId) {
		var view = new RichStoriesView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	richStoriesEdit: function(appId, id) {
		Storm.setApp(appId)

		var view = new RichStoriesEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	},

	searchTermsView: function(appId) {
		var view = new SearchTermsView({appId: appId})

		Storm.setApp(appId)
		Storm.setPage(view)
	},

	searchTermsEdit: function(appId, id) {
		Storm.setApp(appId)

		var view = new SearchTermsEditView({id: id, app: Storm.app})

		Storm.setPage(view)
	}
})
