{
	"error": {
		"noAccess": "ليست لديك صلاحية للوصول إلى هذا النظام. يرجى التحدث مع مسؤولك.",
		"noAccessJoin": "ليست لديك صلاحية للوصول إلى هذا النظام حاليًا. هل تود الانضمام؟",
		"noPermission": "ليس لديك إذن لتنفيذ هذا الإجراء.",
		"oops": "وجه الفتاة!",
		"rootPage": "لا يمكن العثور على صفحة الجذر.",
		"lockedBy": "هذا الكائن مقفل حاليًا بواسطة",
		"locked": "التعديل في هذا التطبيق مغلق حاليًا",
		"exitWarning": "لا تزال بيانات التطبيق محفوظة. إذا غادرت الصفحة الآن ، فستفقد البيانات.",
		"allFields": "يرجى تعبئة جميع الحقول.",
		"noApps": "لا يمكنك الوصول إلى أي تطبيقات",
		"generic": "حدث خطأ.",
		"cantPaste": "لا يمكن لصق هذا النوع من الكائنات هنا.",
		"saveFailed": "فشل عملية الحفظ. يرجى تحديث الصفحة.",
		"connect": "تعذر الاتصال بخادم واجهة برمجة التطبيقات."
	},
	"common": {
		"filter": "تصفية",
		"page": "صفحة",
		"language": "لغة",
		"linkTypes": {
			"email": "البريد الإلكتروني",
			"internal": "داخلي",
			"external": "خارجي",
			"share": "شارك",
			"sms": "رسالة قصيرة",
			"timer": "مؤقت",
			"call": "مكالمة",
			"native": "محلي",
			"emergency": "حالة طوارئ",
			"appLink": "بين التطبيق",
			"uri": "URI"
		},
		"linkDescriptions": {
			"email": "رابط لإرسال بريد إلكتروني إلى العنوان المحدد.",
			"call": "رابط لطلب رقم هاتف من جهاز المستخدم.",
			"sms": "رابط لإرسال رسالة SMS من جهاز المستخدم.",
			"external": "رابط إلى صفحة ويب خارجية. ستفتح داخل التطبيق.",
			"appLink": "رابط إلى صفحة في تطبيق آخر.",
			"native": "رابط لجزء من الوظائف الأصلية داخل التطبيق.",
			"emergency": "رابط لطلب رقم هاتف الطوارئ المحدد في إعدادات المستخدم.",
			"share": "رابط لمشاركة التطبيق عبر مربع حوار \"المشاركة\" الأصلي على الجهاز.",
			"internal": "رابط إلى صفحة مختلفة داخل التطبيق.",
			"uri": "رابط إلى صفحة ويب أو تطبيق خارجي. سيؤدي إلى مغادرة المستخدم للتطبيق.",
			"timer": "رابط يبدأ جهاز ضبط وقت العد التنازلي على الجهاز."
		},
		"pageTags": {
			"emergency": "حالة طوارئ",
			"tests": "اختبارات",
			"learn": "تعلم",
			"other": "آخر",
			"prepare": "إعداد",
			"info": "معلومات"
		},
		"save": "حفظ",
		"longLoad": "استرجاع كمية كبيرة من البيانات ، قد يستغرق هذا الأمر ما يصل إلى دقيقة.",
		"back": "الى الخلف",
		"submit": "خضع",
		"done": "فعله",
		"upload": "تحميل",
		"cancel": "إلغاء",
		"email": "عنوان البريد الإلكتروني",
		"tag": "بطاقة",
		"publish": "نشر",
		"finish": "إنهاء",
		"search": "بحث",
		"tools": "أدوات",
		"next": "التالى",
		"position": "موضع",
		"settings": "إعدادات",
		"clickToBrowseMap": "انقر لتصفح الخريطة",
		"description": "وصف",
		"edit": "تصحيح",
		"close": "قريب",
		"username": "اسم المستخدم",
		"view": "رأي",
		"add": "إضافة",
		"sections": "الأقسام",
		"approve": "يوافق",
		"reject": "رفض",
		"warning": "تحذير",
		"pleaseWait": "أرجو الإنتظار",
		"closeThisMessage": "أغلق هذه الرسالة",
		"preview": "معاينة",
		"approved": "وافق"
	},
	"login": {
		"title": "تسجيل الدخول",
		"inputs": {
			"password": "كلمه السر"
		},
		"error": {
			"title": "Oopsie!",
			"linkExpired": "انتهت صلاحية رابط إعادة تعيين كلمة المرور هذا.",
			"body": "لقد أدخلت اسم المستخدم أو كلمة المرور غير الصحيحة.",
			"emailNotFound": "عنوان البريد الإلكتروني غير موجود."
		},
		"forgot": {
			"body": "أدخل عنوان البريد الإلكتروني المرتبط بحسابك.",
			"title": "كلنا ننسى في بعض الأحيان!"
		},
		"success": {
			"title": "نجاح!",
			"loginBody": "لقد قمت بتسجيل الدخول بنجاح ، ولكن لا يوجد مكان تذهب إليه! أنت على auth.3sidedcube.com. يرجى الذهاب إلى Storm CMS في مؤسستك.",
			"body": "يجب أن تتلقى رسالة إلكترونية تحتوي على تعليمات لإعادة تعيين كلمة المرور الخاصة بك."
		},
		"links": {
			"return": "العودة لتسجيل الدخول",
			"forgot": "انا نسيت..."
		},
		"reset": {
			"confirm": "تؤكد",
			"submit": "خضع",
			"success": "تم تعيين كلمة المرور الخاصة بك ، يمكنك الآن إغلاق هذه النافذة.",
			"newPassword": "كلمة السر الجديدة"
		}
	},
	"localisations": {
		"title": "التطويعات المحلية",
		"string": "سلسلة",
		"key": "مفتاح",
		"addNew": "إضافة تطويعات محلية جديدة",
		"addMultiple": "إضافة تطويعات محلية متعددة",
		"addMultipleInstructions": "إضافة أو تحديث تطويعات محلية بكميات كبيرة. استخدم CSV في التنسيق",
		"uploadCsv": "تحميل CSV",
		"copyFromAnotherApp": "نسخ من تطبيق آخر",
		"confirmCopy": "هل تريد بالفعل نسخ التطويعات المحلية من التطبيق المحدد؟",
		"overwriteExisting": "استبدال التطويعات المحلية الحالية",
		"overwriteWarning": "سيتم استبدال الترجمة التي ترجمتها.",
		"all": "<b>كل</b> التطويعات المحلية",
		"errors": {
			"invalidKey": "مفتاح تطويع محلي غير صالح. يجب أن تبدأ المفاتيح بحرف ذي تسطير سفلي وأن تحتوي فقط على أحرف كبيرة وذات تسطيرات سفلية. يجب أن تحتوي المفاتيح على قسمين على الأقل (الخانات مقيدة حسب الأحرف ذات التسطيرات السفلية).",
			"csvTooManyColumns": "يوجد الكثير من الأعمدة في العنوان.",
			"csvInvalidHeader": "عنوان غير صالح.",
			"csvInvalidKeyOnLine": "مفتاح تطويع محلي غير صالح على الإنترنت",
			"csvInvalidColumnCountOnLine": "عدد غير صالح من الأعمدة على الإنترنت",
			"expected": "متوقع",
			"got": "تحقق"
		}
	},
	"appSetup": {
		"expandAll": "توسيع الكل",
		"addCsv": "إضافة CSV",
		"publishable": "Publishable إلى:",
		"startPage": "أبدأ الصفحة",
		"translationError": "فشل تحميل ملف الترجمة. يرجى التحقق من أن التطبيق والتنظيم بهما لغة واحدة على الأقل ممكّنة وقابلة للنشر.",
		"default": "افتراضي",
		"translation": "ترجمة",
		"appName": "اسم التطبيق",
		"activeHelp": "هذا التطبيق مباشر",
		"regional": "إقليمي",
		"publish": "حالة النشر",
		"regionalHelp": "هل يمثل هذا التطبيق عدة بلدان؟",
		"key": "مفتاح",
		"released": "صدر",
		"addMoreLanguages": "فقط اللغات المعينة لهذه المنظمة متوفرة هنا. تعيين المزيد في قسم المسؤول.",
		"next": "التالى",
		"individualHelp": "هذا التطبيق هو تطبيق فردي",
		"title": "إعداد التطبيق",
		"dev": "ديف",
		"locale": "أماكن التطبيق",
		"localisation": "توطين التطبيق",
		"deleted": "تم الحذف",
		"live": "حي",
		"active": "الحالة",
		"languages": "لغات التطبيق",
		"multiContainerHelp": "هل هذا التطبيق التطبيق حاوية؟",
		"individualapp": "فرد",
		"publishableLanguages": "لغات قابلة للنشر",
		"activeapp": "نشيط",
		"deactivated": "إبطال مفعولها",
		"prev": "السابق",
		"value": "القيمة",
		"wip": "أعمال جارية",
		"type": "اكتب",
		"appStatus": "حالة التطبيق",
		"multiHelp": "هل هذا التطبيق جزء من تطبيق متعدد؟",
		"inactiveHelp": "هذا التطبيق متاح فقط في وضع المطور",
		"bundleIdentifiers": "معرفات حزمة SDK",
		"properties": "الخصائص",
		"multi": "تطبيق متعدد",
		"multiapp": "تطبيق متعدد",
		"multiContainer": "تطبيق الحاوية"
	},
	"metadata": {
		"addScreenshot": "إضافة لقطة شاشة",
		"appIconHint": "ينبغي أن تكون الصورة بجودة 1024 x 1024 مع عدم وجود شفافية. يجب أن تكون بتنسيق JPG أو PNG، مع دقة بحد أدنى لا تقل على 72 نقطة لكل بوصة وفي مساحة ألوان RGB. ويجب أن تحتوي على طبقات أو زوايا مستديرة.",
		"appPreviewVideo": "فيديو نظرة عامة على التطبيق",
		"appPreviewVideoHint": "يجب أن تكون مقاطع فيديو الخاصة بالنظرة العامة على التطبيق بتنسيق M4V أو MP4 أو MOV ولا يمكن أن يتجاوز حجم المقطع 500 ميجا بايت.",
		"appStoreTitle": "العنوان بمتجر التطبيقات",
		"delete": "حذف",
		"emailHint": "أدخل عنوان بريد إلكتروني في حالة ما إذا كان يرغب متجر التطبيقات أو المستخدمين بأن يكونوا على اتصال",
		"featureGraphic": "الرسم المميز",
		"featureGraphicHint": "يتطلب متجر جوجل بلاي أن يكون للتطبيقات رسم مميز أو \"لافتة دعائية\" – يجب أن تكون أبعادها بعرض 1024 × ارتفاع 500. يمكن أن تكون هذه اللافتة صورة مستقلة أو صورة خلفية لفيديو ترويجي (في حالة تقديمه)",
		"languages": "اللغات",
		"nameOnDevice": "اسم التطبيق على الجهاز",
		"phoneNumberHint": "يجب أن يشتمل على رمز الاتصال بالدولة",
		"platformSpecific": "بيانات تعريف خاصة بالمنصة",
		"price": "السعر",
		"rating": "التصنيف",
		"screenshotsHint": "يشتمل على لقطات من الشاشة لكل حجم شاشة. يجب أن تكون لقطات الشاشة بتنسيق JPG أو PNG، وفي مساحة ألوان RGB",
		"shared": "بيانات التعريف عبر منصات متعددة",
		"shortAppDescription": "وصف موجز للتطبيق",
		"supportUrlHint": "رابط على الإنترنت بمعلومات داعمة لتطبيقك. يجب أن يكون هذا الرابط ظاهرًا على متجر التطبيقات.",
		"websiteHint": "رابط على الإنترنت بمعلومات تسويقية لتطبيقك. يجب أن يكون هذا الرابط ظاهرًا على متجر التطبيقات.",
		"iconSize": "يجب أن يكون حجم الرمز الأصلي 1024x1024.",
		"title": "البيانات الوصفية",
		"website": "عنوان التسويق",
		"launchDate": "موعد غداء",
		"name": "اسم التطبيق",
		"secondaryCategory": "الفئة الثانوية",
		"features": "الميزات",
		"privacyPolicyUrl": "URL سياسة الخصوصية",
		"appIcon": "رمز التطبيق",
		"description": "وصف",
		"appStore": "تفاصيل متجر التطبيقات",
		"primaryCategory": "الفئة الأساسية",
		"noBuildPlatforms": "لا توجد منصات إنشاء لهذا التطبيق",
		"phoneNumber": "رقم الهاتف",
		"keywords": "الكلمات الدالة",
		"supportUrl": "عنوان URL الدعم",
		"copyright": "حقوق النشر"
	},
	"plugins": {
		"title": "البرامج الإضافية",
		"availablePlugins": "البرامج الإضافية المتاحة"
	},
	"editor": {
		"inspector": {
			"swapItem": "عنصر التبادل",
			"properties": {
				"progressMessage": "رسالة التقدم",
				"annotation": {
					"description": "رقم الخطوة",
					"title": "حاشية"
				},
				"title": {
					"description": "عنوان عنصر القائمة",
					"title": "عنوان"
				},
				"name": {
					"title": "اسم",
					"description": "اسم النظام الداخلي"
				},
				"hint": {
					"description": "تعليمات للمستخدم",
					"title": "ملحوظة"
				},
				"image": {
					"chooseButton": "اختر صورة",
					"title": "صورة",
					"removeButton": "إزالة الصورة"
				},
				"video": {
					"addLanguage": "إضافة لغة",
					"attributes": "سمات",
					"chooseButton": "اختر الفيديو",
					"title": "فيديو",
					"loop": "عقدة"
				},
				"link": {
					"type": "اكتب",
					"title": "حلقة الوصل",
					"recipients": "المستلمون (واحد لكل سطر)",
					"duration": "مدة الموقت (بالدقائق)",
					"linkDestination": "وصلة الوجهة",
					"destination": "المكان المقصود",
					"body": "نص أساسي",
					"linkTitle": "عنوان الرابط",
					"testLink": "اختبار الارتباط"
				},
				"images": {
					"add": "إضافة صورة"
				},
				"animation": {
					"chooseButton": "اختر الرسوم المتحركة",
					"delay": "تأخير الإطار (بالثواني)"
				},
				"embeddedLinks": {
					"title": "روابط مضمنة",
					"add": "إضافة رابط"
				},
				"embeddedMedia": {
					"title": "الوسائط المدمجة"
				},
				"header": {
					"description": "يظهر فوق مجموعة من العناصر",
					"title": "رأس"
				},
				"footer": {
					"title": "تذييل",
					"description": "يظهر أدناه مجموعة من العناصر"
				},
				"badge": {
					"completion": {
						"title": "نص الانتهاء",
						"description": "معروض للمستخدم على اكتمال الاختبار"
					},
					"how": {
						"title": "كيف تحصل على",
						"description": "نص يصف كيفية تحقيق هذه الشارة"
					},
					"icon": "أيقونة",
					"title": "شارة"
				},
				"units": {
					"description": "ثواني / متر إلخ.",
					"title": "وحدات"
				},
				"range": {
					"title": "نطاق",
					"length": "الطول",
					"start": "بداية",
					"increment": "زيادة راتب"
				},
				"completionText": {
					"title": "نص الانتهاء",
					"description": "عرض للمستخدم على الإجابة بشكل صحيح"
				},
				"failureText": {
					"description": "عرض للمستخدم على الإجابة بشكل غير صحيح",
					"title": "فشل النص"
				},
				"winThreshold": {
					"title": "عتبة الفوز (٪)",
					"description": "النسبة المئوية للأجوبة الصحيحة المطلوبة لاجتياز الاختبار"
				},
				"winMessage": {
					"title": "اربح رسالة",
					"description": "عرض للمستخدم على اجتياز الاختبار"
				},
				"loseMessage": {
					"title": "افقد الرسالة",
					"description": "عرض للمستخدم عند الإخفاق في الاختبار"
				},
				"shareMessage": {
					"title": "مشاركة الرسالة",
					"description": "النص الذي ستتم مشاركته مع الشارة على الشبكات الاجتماعية"
				},
				"caption": "شرح",
				"gridType": "نوع الشبكة",
				"loseRelatedLinks": "تفقد الروابط ذات الصلة",
				"textContent": "محتوى النص",
				"quizzes": "مسابقات",
				"answer": "إجابة",
				"delay": "التأخير)",
				"volatile": "متقلب (لا تستمر الدولة)",
				"quiz": "لغز",
				"confirmGridTypeChange": "هل أنت متأكد من أنك تريد تغيير نوع الشبكة؟ سيؤدي هذا إلى تغيير كافة عناصر الشبكة.",
				"columns": "أعمدة",
				"initialPosition": "الوضعية الأولية",
				"placeholderImage": "صورة مكان النائب",
				"correctAnswer": "اجابة صحيحة",
				"overlay": "تراكب النص",
				"button": "زر",
				"standard": "اساسي",
				"selectionLimit": "حد الاختيار",
				"relatedLinks": "روابط ذات علاقة",
				"winRelatedLinks": "اربح الروابط ذات الصلة"
			},
			"confirmDelete": "هل أنت متأكد من أنك تريد حذف هذا العنصر؟",
			"moveUp": "تحرك",
			"addAnother": "أضف آخر",
			"newType": "نوع جديد",
			"addBulletItem": "إضافة بند رصاصة",
			"copy": "نسخ",
			"copiedItem": "العنصر المنسوخ",
			"paste": "معجون",
			"confirmApproval": "هل أنت متأكد من أنك تريد الموافقة على هذا؟",
			"sponsorship": "حدد الراعي",
			"confirmPageDelete": "تأكيد حذف الصفحة",
			"confirmPageDeleteWarning": "تحذير: أنت تقوم بحذف كائن صفحة. ستتم إزالة كل محتوى الصفحة. هل أنت متأكد من أنك تريد المتابعة؟",
			"moveDown": "تحرك لأسفل",
			"copySuccess": "تم نسخ الكائن إلى الحافظة",
			"rootPageDelete": "هذه هي الصفحة الجذرية للتطبيق. لا يمكن حذفها.",
			"changeType": "تغيير النوع",
			"title": "تحرير الخصائص",
			"areYouSure": "هل أنت واثق؟"
		},
		"objectPreviews": {
			"TextListItem": "استخدم هذا البند لنص افتتاحي، عادة ما يكون في أعلى الصفحة",
			"TitleListItem": "استخدم هذا البند لسطر واحد في النص",
			"DescriptionListItem": {
				"title": "يحتوي هذا البند على عنوان ووصف",
				"description": "استخدم هذا البند عندما يكون لديك الكثير لتقوله"
			},
			"StandardListItem": {
				"title": "استخدم هذا البند أكثر من مرة",
				"description": "يشتمل هذا البند على عنوان ووصف وأيقونة يمكنك ترك خانات فارغة – مثل الأيقونة"
			},
			"ToggleableListItem": {
				"title": "استخدم ذلك عندما تريد إخفاء شيء ما",
				"description": "يمكن عرض وإخفاء هذا النص بواسطة المستخدم. هذا البند جيدًا في حالة ما إذا كان يتوفر لديك الكثير من التفاصيل - من الممكن الأسئلة الأكثر شيوعًا"
			},
			"OrderedListItem": {
				"title": "قائمة مرتبة",
				"description": "استخدم هذه الميزة عندما تريد ترتيب القائمة ترتيبًا رقميًا"
			},
			"UnorderedListItem": {
				"title": "قائمة غير مرتبة",
				"description": "استخدم هذه الميزة عندما تريد وضع الأشياء في قائمة بنقاط فقط"
			},
			"CheckableListItem": "استخدم هذه الميزة لقوائم الفحص",
			"ButtonListItem": "استخدم هذه الميزة إن كنت تريد زرًا",
			"HeaderListItem": {
				"title": "استخدم هذه الميزة في أعلى الصفحة",
				"description": "يمكن أن تشتمل على عنوان ووصف مطول"
			},
			"LogoListItem": "استخدم هذه الميزة لشيء ما مثل شعار أو عندما تريد صورة وتسمية توضيحية ورابط",
			"SpotlightListItem": {
				"first": "التدوير",
				"second": "ارتباطات بارزة"
			},
			"ChunkyListItemView": {
				"title": "هذا مثل عنصر القائمة القياسية - مع حشو إضافي.",
				"description": "يشتمل هذا البند على عنوان ووصف وأيقونة. يمكنك ترك خانات فارغة – مثل الأيقونة"
			},
			"ListItemView": "يشتمل هذا البند على عنوان فقط",
			"SponsorshipListItem": "يستخدم هذا لعرض الرعاية"
		},
		"pageList": {
			"title": "صفحات",
			"allPages": "كل الصفحات",
			"filter": "تصفية الصفحات"
		},
		"confirmRequestNewLock": "لقد تم إبطال قفل هذه الصفحة. هل ترغب في طلب قفل جديد؟",
		"title": "محرر",
		"addObject": "إضافة كائن هنا",
		"never": "أبدا",
		"pageTag": "علامة الصفحة",
		"newFromTemplate": "جديد من قالب",
		"changedYourMind": "غيرت رأيك؟",
		"savesQueued": "يحفظ في قائمة الانتظار",
		"createWithoutTemplate": "قم بإنشاء صفحة جديدة بدون قالب",
		"addQuiz": "إضافة مسابقة",
		"addPage": "إضافة صفحة",
		"confirmRelock": "هذا الكائن مقفل من قبلك. هل ترغب في قفله مرة أخرى؟ سيتم إلغاء قفلك السابق.",
		"pageNameError": "يرجى إدخال اسم الصفحة",
		"addApp": "إضافة التطبيق",
		"pageName": "اسم الصفحة",
		"addLink": "إضافة رابط",
		"lastSaved": "آخر حفظ:",
		"saving": "إنقاذ...",
		"pageStyle": "نمط الصفحة",
		"pageType": "نوع الصفحة"
	},
	"mediaLibrary": {
		"chooseExisting": "اختيار الحالي",
		"upload": {
			"instructions": "انقر هنا أو اسحب ملفًا للبدء",
			"cropType": "نوع التقطيع",
			"noCrop": "بدون تقطيع",
			"square": "مربع",
			"squareDesc": "استخدم هذه الميزة لتقطيع الأيقونات وصور المسابقات. ينبغي أن تكون صور المسابقات بعرض <b>640</b> وبارتفاع <b>640</b> بكسل.",
			"errors": {
				"tooManyFiles": "يرجى اختيار ملف واحد"
			},
			"requiresTransparency": "يتطلب خلفية شفافة",
			"title": "تحميل الوسائط",
			"genericError": "فشل تحميل الملف. خطا بالكود:",
			"tags": "الكلمات",
			"imageDesc": "يوصى باستخدام صور من <b>640 (w)</b> بمقدار <b>320 (h)</b> بكسل للصور من الحافة إلى الحافة ، وصور عرض الشرائح وصور الفيديو. يجب أن تكون صور الاختبار <b>640 (w)</b> بواسطة <b>640 (h)</b> بكسل.",
			"filesizeError": "التحميل فشل. الملف كبير جدًا. الحد الأقصى المسموح به هو",
			"gdpcSpotlightDesc": "استخدم هذا المحصول للحصول على رؤوس الرسائل في تطبيقات First Aid ، وأضواء كاشفة ، وحافة إلى حافة ، وشرائح ، وصور فيديو. يجب أن تكون الصور <b>640 (w)</b> بمقدار <b>320 (h)</b> بكسل في الحجم.",
			"gdpcHeaderDesc": "استخدم هذا الاقتصاص لصور الرأس الكبيرة في تطبيقات Hazards. يجب أن تكون الصور بحجم <b>640 (w)</b> بمقدار <b>568 (h)</b> بكسل.",
			"agreementText": "يخضع كل المحتوى الذي تم تحميله لهذا النظام لشروط",
			"invalidTag": "علامات غير صالحة. لا يجوز أن تحتوي العلامات على رمز حرف البدل (*).",
			"headerDesc": "استخدم هذا الاقتصاص لصور العنوان الكبيرة التي تظهر في أعلى الصفحة. يجب أن تكون الصور بحجم <b>640 (w)</b> بمقدار <b>568 (h)</b> بكسل.",
			"selectType": "يرجى تحديد نوع الصورة التي تقوم بتحميلها",
			"formatError": "التحميل فشل. تنسيق ملف غير صالح. تنسيقات الصور المسموح بها: .jpeg ، .png ، .gif تنسيقات الفيديو المسموح بها: .mp4 ، .m4v",
			"spotlightDesc": "استخدم هذا الاقتصاص في الأضواء ، والحافة إلى الحافة ، وعرض الشرائح ، وصور الفيديو. يجب أن تكون الصور <b>640 (w)</b> بمقدار <b>320 (h)</b> بكسل في الحجم.",
			"newTag": "علامة جديدة"
		},
		"title": "مكتبة الوسائط",
		"clearFilters": "مسح الفلاتر",
		"general": "جنرال لواء",
		"commas": "افصل بفواصل",
		"delete": "حذف",
		"additionalTags": "علامات إضافية",
		"image": "صورة",
		"dragToReorder": "اسحب لإعادة ترتيب",
		"video": "فيديو",
		"type": "نوع من وسائل الإعلام",
		"editMedia": "تحرير الوسائط",
		"icon": "أيقونة",
		"filterByTags": "تصفية حسب العلامات",
		"refine": "صقل المكتبة",
		"loadMore": "تحميل المزيد",
		"society": "المجتمع",
		"section": "الجزء",
		"confirmTagRemoval": "هل أنت متأكد من أنك تريد إزالة هذه العلامة؟",
		"animation": "الرسوم المتحركة"
	},
	"viewPicker": {
		"TextListItem": "نص من طرف إلى طرف بين المجموعات",
		"TitleListItem": "سطر نصي واحد",
		"StandardListItem": "عنوان ورابط وأيقونة",
		"DescriptionListItem": "عنوان ووصف بنص متعدد السطور",
		"ToggleableListItem": "عنوان يمكن النقر عليه والذي يظهر محتوى نصي أطول",
		"OrderedListItem": "عنوان ومحتوى نصي متعدد السطور يحتوي على تعليق توضيحي ورقم خطوة",
		"UnorderedListItem": "بند قائمة ذات نقاط تشتمل على عنوان ووصف",
		"CheckableListItem": "عنوان وخانة اختيار. يمكن أن تستمر الحالة بين مستخدمي التطبيق",
		"ButtonListItem": "عنوان وزر.",
		"ImageListItem": "صورة واحدة، من طرف إلى آخر",
		"HeaderListItem": "صورة كبيرة بعنوان ووصف متراكب عليها",
		"VideoListItem": "عنصر فيديو يدعم مصادر متعددة",
		"AnimatedImageListItem": "سلسلة من الصور التي يتم تشغيلها على شكل عرض شرائح توضح معلومات",
		"SpotlightImageListItem": "صورة أو أكثر ذات ارتباط مميز تحتوي على صورة وتسمية توضيحية ورابط",
		"LogoListItem": "تستخدم لعرض العلامة التجارية للشركة",
		"SliderQuizItem": "سؤال اختيار مُمر الصور",
		"TextQuizItem": "سؤال اختيار النص",
		"ImageQuizItem": "سؤال اختيار الصور",
		"AreaQuizItem": "سؤال اختيار المنطقة",
		"CollectionListItem": "عرض \"جمع الكل معًا\" للتطبيقات والشارات والروابط",
		"leadInText": "نص الرصاص",
		"title": "شاء",
		"filter": "تصفية الكائنات",
		"hiddenText": "النص المخفي",
		"mediaObjects": "كائنات الوسائط",
		"basicObjects": "كائنات أساسية",
		"spotlight": "ضوء كشاف",
		"sponsorshipObjects": "أغراض الرعاية",
		"SponsorshipLogoListItem": "تستخدم لعرض العلامات التجارية الرعاية",
		"multiLineObjects": "كائنات متعددة الخطوط",
		"interactiveObjects": "كائنات تفاعلية",
		"text": "نص",
		"dragAndDrop": "سحب وإفلات الكائنات لإضافتها إلى الصفحة",
		"subText": "النص الفرعي",
		"SponsorshipListItem": "عنصر رعاية بسيط"
	},
	"users": {
		"removeUserConfirm": "هل تريد بالفعل إزالة بيانات هذا المستخدم؟",
		"failedToRemove": "فشل إزالة المستخدم",
		"role": "وظيفة",
		"error": "لا يمكن إنشاء المستخدم. تحقق من عدم تسجيل اسم المستخدم والبريد الإلكتروني.",
		"organisation": "منظمة",
		"name": "اسم",
		"apps": "التطبيقات",
		"usernameHelp": "لا يجوز أن تحتوي أسماء المستخدمين إلا على أحرف وأرقام وشرطات سفلية وشرطات ونقط.",
		"firstName": "الاسم الاول",
		"complete": "تم إنشاء المستخدم بنجاح",
		"createUser": "انشاء المستخدم",
		"lastActive": "الماضي نشط",
		"title": "المستخدمين",
		"success": "نجاح! سيتلقى المستخدم الجديد رسالة إلكترونية تحتوي على تعليمات لتنشيط حسابه.",
		"roles": "الأدوار",
		"list": "قائمة المستخدمين",
		"lastName": "الكنية"
	},
	"userApps": {
		"title": "تطبيقات المستخدم"
	},
	"push": {
		"messageDescription": "رسالة إشعارات لحظية",
		"message": "الرسالة",
		"categories": {
			"region": "الإقليم",
			"custom": "العادة",
			"standard": "اساسي",
			"future": "مستقبل"
		},
		"custom": {
			"area": "المنطقة المستهدفة",
			"areaHelp": "انقر على الخريطة لتحديد المنطقة المستهدفة لهذا الإشعار"
		},
		"error": {
			"length": "رسالة الإشعار اللحظي طويلة للغاية",
			"messageEmpty": "يرجى إدخال رسالة",
			"missingRegion": "يرجى اختيار إقليم"
		},
		"what": "ما الذي تريده إرساله؟",
		"when": "متى تريد إرسالها؟",
		"addToQueue": "إضافة إلى قائمة الانتظار",
		"types": {
			"default": "افتراضي"
		},
		"priorities": {
			"high": "متوسط",
			"low": "منخفض"
		},
		"sending": "إرسال",
		"payload": "الحمولة",
		"existing": "إخطارات الدفع الحالية",
		"approve": "يوافق",
		"type": "اكتب",
		"saveAndSend": "حفظ وإرسال",
		"reject": "رفض",
		"priority": "أفضلية",
		"numberCompleted": "منجز",
		"category": "الفئة",
		"editPush": "تعديل إشعار الدفع",
		"createdBy": "صنع من قبل",
		"noPageSpecified": "لا توجد صفحة محددة",
		"numberSent": "مجموع",
		"notes": "ملاحظات",
		"showAllApps": "عرض جميع التطبيقات",
		"noNotifications": "لا إشعارات",
		"sent": "دفع ارسل",
		"assignments": "تعيينات",
		"pushDetails": "تفاصيل الإخطارات"
	},
	"hazards": {
		"title": "المخاطر",
		"alerts": {
			"custom": {
				"types": {
					"announcement": "إعلان",
					"pin": "الصفحة معلقة",
					"announcementDesc": "تستخدم الإعلانات ل إنشاء تنبيهات للأحداث التي لم يتم من مقدمي تنبيه الآخرين .",
					"pinDesc": "ستخدم صفحات معلقة على ميزة الصفحات المهمة التي هي الوقت والمكان الحساس."
				},
				"errors": {
					"pleaseSelectRegion": "يرجى تحديد المنطقة ل هذا التنبيه .",
					"pleaseProvideMessage": "يرجى تقديم رسالة ل هذا الإعلان .",
					"pleaseSelectPage": "يرجى تحديد الصفحة التي سيتم معلقة .",
					"pleaseProvideEvent": "يرجى تقديم حدث ل هذا الإعلان .",
					"pleaseProvideHeadline": "يرجى تقديم عنوان ل هذا الإعلان .",
					"pleaseProvideAreaDescription": "يرجى تقديم وصف المنطقة ل هذا الإعلان .",
					"effectiveWrong": "التاريخ الفعلي لا يمكن أن يكون بعد تاريخ انتهاء الصلاحية"
				},
				"announcementPage": "يتم عرض الصفحة في قسم ما الآن .",
				"pinPage": "الصفحة ل تكون واردة .",
				"message": "الرسالة المرسلة مع هذا التنبيه .",
				"area": "منطقة التنبيه",
				"areaHelp": "سيتم إرسال تنبيه لجميع المستخدمين رصد موقع داخل المنطقة المحددة .",
				"expiry": "تاريخ الانتهاء",
				"expiryHelp": "تاريخ سيتم إزالة هذا التنبيه .",
				"effective": "تاريخ مؤثر",
				"effectiveHelp": "تاريخ هذا التنبيه لن تكون فعالة من .",
				"onset": "تاريخ بداية",
				"onsetHelp": "التاريخ سوف الخطر يحدث.",
				"severity": "خطورة",
				"severityHelp": "ما مدى أهمية هذا التنبيه ؟",
				"alertQueued": "في قائمة الانتظار في حالة تأهب ليتم إرسالها .",
				"drawRegion": "رسم حول المناطق يجب أن ترسل هذا التنبيه",
				"notify": "أبلغ",
				"sendNotification": "إرسال إشعار دفع ل هؤلاء المستخدمين .",
				"pushes": "الدفعات",
				"cap_create": "قبعة",
				"cap_send": "قبعة",
				"languageHelp": "لغة مكتوبة في حالة تأهب في .",
				"polygon": "إضافة بيانات مضلع مخصص هنا .",
				"showPolyData": "وتظهر بيانات المضلع",
				"updateMap": "خريطة التحديث"
			},
			"times": {
				"day": "يوم",
				"month": "شهر",
				"allTime": "كل الوقت",
				"hour": "ساعة",
				"week": "أسبوع"
			},
			"alert": "محزر",
			"title": "التنبيهات",
			"show": "تبين",
			"specifySinglePolygon": "المضلعات المتعددة غير مدعومة للتنبيهات. يرجى رسم مضلع واحد.",
			"effective": "فعال",
			"inTheLast": "في الاخير",
			"expired": "منتهية الصلاحية",
			"allAlerts": "كل التنبيهات",
			"pinned": "دبس",
			"noAlerts": "لا تنبيهات",
			"issuedBy": "أصدرت من قبل",
			"createNewAlert": "إنشاء تنبيه جديد",
			"testAlert": "إنشاء تنبيه اختبار",
			"pin": "دبوس",
			"testAlertDesc": "سيتم إرسال تنبيه الاختبار هذا إلى جميع المستخدمين الذين يراقبون موقعًا في أنتاركتيكا.",
			"alertActive": "تنبيه نشط"
		},
		"generalNotifications": {
			"title": "الإخطارات عامة",
			"createNotification": "إنشاء إعلام",
			"event": "حدث",
			"headline": "عنوان رئيسي",
			"area_description": "وصف منطقة",
			"type": "اكتب",
			"status": "الحالة",
			"scope": "نطاق",
			"category": "فئة",
			"urgency": "إلحاح",
			"severity": "خطورة",
			"certainty": "يقين",
			"customMapData": {
				"placeholder": "انقر على الخريطة أعلاه أو إضافة ما لا يقل عن ثلاثة إحداثيات هنا ل إنشاء مضلع . i.e. \n[longitude,latitude],\n[longitude,latitude],\n[longitude,latitude]",
				"moreCoords": "يرجى توفير أكثر من ثلاثة إحداثيات",
				"firstAndLast": "يجب أن تكون الإحداثيات الأولى والأخيرة هي نفسها",
				"notFound": "لا توجد بيانات مضلع وجدت ، يرجى إضافة ما لا يقل عن ثلاثة الإحداثيات. i.e. [longitude,latitude],[longitude,latitude],[longitude,latitude]",
				"fisrtFoundNotLast": "الإحداثي الأول وجدت داخل الإحداثيات ، فقط لأول مرة ، وينبغي أن الإحداثيات مشاركة تكون هي نفسها"
			},
			"dropdown": {
				"alert": "إنذار",
				"update": "تحديث",
				"cancel": "إلغاء",
				"error": "خطأ",
				"actual": "فعلي",
				"system": "نظام",
				"test": "اختبار",
				"draft": "مسودة",
				"public": "عامة",
				"restricted": "مقيد",
				"private": "خاص",
				"safety": "سلامة",
				"security": "أمن",
				"rescue": "إنقاذ",
				"fire": "نار",
				"health": "الصحة",
				"transport": "المواصلات",
				"other": "آخر",
				"immediate": "فوري",
				"expected": "متوقع",
				"future": "مستقبل",
				"past": "الماضي",
				"unknown": "غير معروف",
				"extreme": "أقصى",
				"severe": "شديدة",
				"moderate": "معتدل",
				"minor": "قاصر",
				"observed": "ملاحظ",
				"likely": "على الأرجح",
				"possible": "ممكن",
				"unlikely": "من غير المرجح"
			}
		},
		"disasters": {
			"categories": {
				"heatwave": "موجة الحر",
				"winter": "شتاء",
				"storm": "عاصفة",
				"hurricane": "اعصار",
				"wind": "ينفخ",
				"wildfire": "حريق هائل",
				"tornado": "إعصار",
				"volcano": "بركان",
				"flood": "فيضان",
				"tsunami": "تسونامي",
				"earthquake": "زلزال",
				"nuclear": "نووي",
				"coastal": "ساحلي"
			},
			"tutorial": {
				"heading": "ماذا تعني الدول المختلفة؟",
				"body": "- تمكين تعني أن الصفحة ستظهر في محرر المحتوى لكي تبدأ في العمل عليها. <br> - يعني Dev أن الصفحة ستظهر في إصدارات التطوير لهذا التطبيق. <br> - مباشر يعني أن الصفحة ستظهر في إصدارات متجر التطبيقات لهذا التطبيق."
			},
			"rootPage": "صفحة الجذر",
			"alertSettings": "إعدادات التنبيه",
			"configure": "تهيئة",
			"pleaseSpecifyPages": "يرجى تحديد صفحة لكل نوع من أنواع الكوارث المحددة.",
			"whatNow": "صفحة \"ماذا الآن\"",
			"saveError": "أخفق حفظ تهيئة التطبيق.",
			"disable": "تعطيل",
			"enable": "مكن",
			"titleStr": "الكوارث",
			"saveSuccess": "تم حفظ تكوين التطبيق.",
			"active": "أحداث نشطة",
			"saveClose": "حفظ وإغلاق",
			"default": "افتراضي",
			"icon": "أيقونة",
			"addCategory": "إضافة فئة",
			"enabled": "تمكين",
			"event": "هدف",
			"order": "طلب",
			"code": "الشفرة",
			"page": "صفحة",
			"categoriesStr": "الاقسام",
			"title": "الكوارث"
		},
		"alertZones": {
			"editAlertZone": "تحرير منطقة التنبيه",
			"addAlertZone": "إضافة منطقة التنبيه",
			"area": "منطقة",
			"title": "مناطق التنبيه",
			"events": "أحداث",
			"name": "اسم",
			"areaSelectModalTitle": "ارسم حول المناطق التي تنطبق عليها هذه المنطقة",
			"blacklist": "القائمة السوداء",
			"type": "اكتب",
			"whitelist": "القائمة البيضاء"
		},
		"feeds": {
			"tutorial": {
				"body": "قد تأتي بعض أنواع التنبيه من مصادر تنبيه متعددة. يتيح لك هذا القسم تمكين أو تعطيل خلاصات وأنواع أحداث معينة من خلاصات فردية لهذا التطبيق.",
				"heading": "استخدام الخلاصات",
				"steps": "<ol style=\";text-align:right;direction:rtl\"><li style=\";text-align:right;direction:rtl\"> تأكد من تمكين أنواع الكوارث والفئات المختارة لهذا التطبيق. سيحدد ذلك أي الخلاصات والأحداث قد يتم تمكينها أدناه. </li><li style=\";text-align:right;direction:rtl\"> حدد الخلاصات وأنواع الأحداث التي ترغب في المساهمة بها في التنبيهات الخاصة بهذا التطبيق. </li></ol>"
			},
			"delete": {
				"confirm": "هل أنت واثق؟",
				"events": "سيتم تعطيل جميع الأحداث الممكّنة لهذه الخلاصة.",
				"yes": "نعم ، احذفها"
			},
			"title": "يغذي",
			"feed": "تغذية",
			"disable": "تعطيل",
			"events": "أحداث",
			"code": "الشفرة",
			"languages": "اللغات المتوفرة",
			"type": "اكتب",
			"enable": "مكن",
			"configure": "تهيئة",
			"scope": "نطاق"
		}
	},
	"appCreate": {
		"createNewOrganization": "إنشاء مؤسسة جديدة",
		"appCopy": {
			"newAppCopying": "يتم نسخ تطبيقك الجديد حاليًا",
			"appReady": "تطبيقك الجديد جاهز الآن",
			"couldNotFind": "تعذر العثور على مهمة نسخ التطبيق المطلوبة"
		},
		"emptyApp": "التطبيق فارغ",
		"copyFrom": "نسخ المحتوى من",
		"title": "إنشاء تطبيق جديد",
		"details": "تفاصيل التطبيق"
	},
	"manageApps": {
		"title": "إدارة التطبيقات",
		"yourNextApp": "تطبيقك الجديد",
		"easilySetUpYourNextApp": "إعداد تطبيقك الجديد بسهولة"
	},
	"dashboard": {
		"title": "لوحة المعلومات"
	},
	"manage": {
		"title": "إدارة"
	},
	"crossBorders": {
		"title": "عبر حدود",
		"list": "قائمة الوجهات",
		"links": "الروابط",
		"link": {
			"title": "عنوان",
			"destination": "المكان المقصود",
			"placeholder": "عنوان الوجهة"
		},
		"editItem": "تحرير الوجهة البلد",
		"contacts": "جهات الاتصال",
		"translations": "ترجمات العبارة",
		"contact": {
			"editItem": "حرر معلومات الاتصال",
			"title": "عنوان",
			"number": "رقم الهاتف"
		},
		"phrases": "العبارات",
		"phrase": {
			"editItem": "تحرير العبارة",
			"phrase": "العبارة",
			"translation": "ترجمة"
		},
		"country": {
			"code": "رقم البلد",
			"name": "اسم الدولة",
			"description": "بلد المقصد"
		}
	},
	"whatnow": {
		"whatnow": "ماذا الان؟",
		"eventType": "نوع الحدث",
		"title": "عنوان",
		"description": "وصف",
		"before": "قبل",
		"during": "أثناء",
		"after": "بعد",
		"content-not-set": "المحتوى لم يتم تعيين",
		"empty": "لا يوجد ما نوس، انقر فوق إضافة إلى إنشاء واحدة",
		"selectSoc": "حدد المجتمع",
		"edit": {
			"editWhatnow": "تعديل ماذا الآن",
			"event": "نوع الحدث",
			"eventhelp": "نوع الحدث ل ماذا الآن .",
			"moreinfo": "URL المعلومات",
			"moreinfohelp": "URL ل مزيد من المعلومات حول ما الآن",
			"translations": "ترجمة",
			"new": "إضافة لغة جديدة",
			"selectLang": "اختر اللغة",
			"title": "عنوان",
			"titleHelp": "عنوان ل ماذا الآن .",
			"description": "وصف",
			"descriptionHelp": "وصف ل ماذا الآن .",
			"midterm": "منتصف المدة المتوقعة",
			"midtermHelp": "الحد من المخاطر (إجراءات على المدى الطويل )",
			"forecast": "توقعات",
			"forecastHelp": "تقييم و خطة (إجراءات المدى القصير )",
			"warning": "تحذير",
			"warningHelp": "الاستعداد ل لرد",
			"watch": "راقب",
			"watchHelp": "الاستعداد ل لرد",
			"immediate": "فوري",
			"immediateHelp": "رد",
			"recover": "استعادة",
			"recoverHelp": "بعد",
			"before": "قبل",
			"beforeHelp": "كيفية تحضير قبل وقوع الحدث.",
			"during": "أثناء",
			"duringHelp": "ما يجب القيام به في حين أن الحدث يحدث",
			"after": "بعد",
			"afterHelp": "ما يجب القيام به بعد أن وقع الحدث",
			"languages": "اللغات",
			"missing": "محتوى مفقود",
			"empty": "الرجاء إضافة لغة إلى إنشاء ماذا الآن",
			"addALang": "إضافة لغة للبدء",
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet، consectetur adipiscing elit، sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam، quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
			}
		},
		"attribution": {
			"url": "عنوان الإحالة",
			"socName": "اسم جمعية الإسناد",
			"urlHelp": "عنوان URL الذي يربطه بمزيد من المعلومات حول المُساهم.",
			"attribution": "عزو",
			"socNameHelp": "اسم الجمعية التي يجب أن تنسب مع ما.",
			"imageHelp": "صورة / أيقونة تحميل.",
			"message": "رسالة الإحالة",
			"messageHelp": "الرسالة التي تترافق مع الإحالة.",
			"image": "صورة الإحالة"
		}
	},
	"nav": {
		"items": {
			"appSetup": {
				"title": "إعداد التطبيق",
				"description": "قم بتكوين اسم التطبيق والرمز وتوطين النص ومعلومات متجر التطبيقات"
			},
			"content": {
				"description": "تحرير المحتوى داخل التطبيق",
				"title": "يحتوى"
			},
			"publish": {
				"title": "نشر",
				"description": "نشر التغييرات على التطوير أو البث المباشر"
			},
			"stats": {
				"description": "شاهد كيف يقوم التطبيق",
				"title": "احصائيات"
			},
			"userAgreement": {
				"description": "عرض وتوقيع اتفاقية برنامج التطبيقات العامة",
				"title": "اتفاقية المستخدم"
			},
			"users": {
				"title": "المستخدمين",
				"description": "إدارة جميع المستخدمين"
			},
			"stories": {
				"description": "إدارة القصص",
				"title": "قصص"
			},
			"levels": {
				"title": "مستويات",
				"description": "إدارة مستويات التقدم في التطبيق"
			},
			"badges": {
				"description": "إدارة الشارات الممنوحة لاستكمال الأنشطة في التطبيق",
				"title": "شارات"
			},
			"push": {
				"description": "إدارة وإرسال إشعارات الدفع للمستخدمين",
				"title": "دفع الإخطارات"
			},
			"sprint": {
				"title": "لوحة الحالة",
				"description": "عرض الوضع الحالي تطور العدو"
			},
			"bugs": {
				"title": "الإبلاغ عن الأخطاء",
				"description": "أبلغ عن المشكلات المتعلقة بالمحرر أو التطبيق"
			},
			"gdpcConsole": {
				"description": "عرض حالات المجتمع وجمعية ديف التجمع",
				"title": "NS وحدة التحكم"
			},
			"hazards": {
				"description": "الأولويات وإدارة الكوارث والتنبيهات والاعتدال",
				"title": "الكوارث والتنبيهات"
			},
			"decisionTree": {
				"title": "شجرة القرار",
				"description": "عرض وتعديل شجرة القرار للتطبيق"
			},
			"admin": {
				"description": "عرض الإحصاءات وتحرير المستخدمين وتفاصيل المؤسسة",
				"title": "مشرف"
			}
		},
		"logout": "الخروج",
		"navigate": "التنقل"
	},
	"publish": {
		"user": "المستعمل",
		"publishToLive": "نشر للعيش",
		"publishToDev": "نشر إلى dev",
		"auditTrail": "درب المراجعة",
		"warning2": "يرجى مراجعة جميع التغييرات بشكل كامل قبل المتابعة والتأكد من اختيارك في أسفل الصفحة.",
		"success": "نشر ناجح.",
		"toApps": "النشر على التطبيقات",
		"publishConfirm": "أؤكد أنني قد راجعت جميع التغييرات المحددة وأن لدي إذنًا بدفعها مباشرةً",
		"selectAll": "اختر الكل",
		"details": "تفاصيل",
		"time": "زمن",
		"live": "حي",
		"startTime": "وقت البدء",
		"objectType": "نوع الكائن",
		"error": "يرجى تحديد صفحة واحدة أو أكثر لنشرها.",
		"endTime": "وقت النهاية",
		"publishLandmark": "لاندمارك تنشر؟",
		"comment": "تعليق",
		"publishType": "نوع النشر",
		"publishing": "نشر...",
		"progress3": "يبدو أن هذا يستغرق بعض الوقت. يمكنك العودة لاحقا.",
		"noChanges": "لا تغيرات",
		"history": "التاريخ",
		"lastModified": "آخر تعديل",
		"detail": "التفاصيل",
		"test": "اختبار",
		"warning1": "لقد حددت الصفحات التالية التي سيتم نشرها لتطبيقات iOS و Android من خلال تحديث دلتا.",
		"progress1": "يتم الآن نشر التغييرات التي اخترتها.",
		"fail": "فشل النشر.",
		"pleaseConfirm": "يرجى تحديد مربع الاختيار لتأكيد أنك قمت بمراجعة جميع التغييرات.",
		"title": "نشر",
		"progress2": "هذا قد يستغرق بعض الوقت. لا تتنقل بعيدًا عن هذه الصفحة."
	},
	"publishHistory": {
		"previousPublishes": "النشرات السابقة",
		"title": "نشر السجل"
	},
	"agreement": {
		"nationalSociety": "المجتمع الوطني",
		"signedBy": "موقعة من قبل",
		"details": "تفاصيل الاتفاقية",
		"signAgreement": "توقيع اتفاق",
		"signed": "وقعت",
		"fullName": "الاسم الكامل",
		"daysRemaining": "__count__ يوم متبقي",
		"iAgree": "أوافق على الشروط الواردة في",
		"viewThe": "عرض",
		"title": "اتفاقية برنامج التطبيق العالمي",
		"email": "عنوان البريد الإلكتروني",
		"checkToAgree": "يرجى تحديد المربع لتأكيد قراءة البنود والموافقة عليها.",
		"date": "تاريخ",
		"notSigned": "غير موقعة",
		"sign": "إشارة",
		"daysRemaining_plural": "__count__ أيام متبقية",
		"status": "الحالة",
		"hazardsTitle": "اتفاقية برنامج تطبيق المخاطر العالمية"
	},
	"roles": {
		"title": "أدوار المستخدمين",
		"list": "قائمة الأدوار",
		"permissions": "أذونات"
	},
	"permissions": {
		"write": "كتابة",
		"title": "أذونات الأدوار",
		"none": "لا شيء",
		"read": "اقرأ",
		"edit": "تحرير الأذونات",
		"delete": "إرسال / حذف"
	},
	"apps": {
		"subtitle": "تعيين التطبيقات للمستخدم",
		"title": "تعيين التطبيقات",
		"assign": "تعيين",
		"assignNew": "تعيين تطبيق جديد",
		"app": "التطبيق"
	},
	"stories": {
		"content": "يحتوى",
		"editStory": "تحرير القصة",
		"subtitle": "عنوان فرعي",
		"name": "اسم",
		"list": "قائمة القصص",
		"title": "قصص",
		"created": "خلقت",
		"story": "قصة"
	},
	"badges": {
		"title": "شارات",
		"editBadge": "تعديل الشارة",
		"list": "قائمة الشارات"
	},
	"levels": {
		"children": "مستوى الأطفال",
		"list": "قائمة المستويات",
		"editLevel": "تحرير المستوى",
		"title": "مستويات",
		"details": "تفاصيل المستوى"
	},
	"bugs": {
		"status": {
			"postponed": "مؤجل",
			"byDesign": "من تصمبم",
			"open": "افتح",
			"duplicate": "مكرر",
			"fixed": "ثابت",
			"wontFix": "لن إصلاح",
			"notReproducible": "لا استنساخه",
			"title": "الحالة",
			"closed": "مغلق",
			"deleted": "تم الحذف"
		},
		"noFiles": "لا ملفات",
		"platformDetails": "تفاصيل المنصة",
		"reopenIssue": "إعادة فتح القضية",
		"issueDetails": "تفاصيل القضية",
		"loadingDetails": "جارٍ تحميل تفاصيل الخطأ ...",
		"steps": "خطوات للتكاثر",
		"reportBug": "الإبلاغ عن خطأ",
		"listOpen": "قائمة تقارير الأخطاء المفتوحة",
		"report": "أبلغ عن",
		"device": "إصدار الجهاز / نظام التشغيل",
		"build": "بناء",
		"screenshots": "لقطات",
		"actually": "السلوك الفعلي",
		"title": "البق",
		"listPending": "قائمة تقارير الأخطاء المعلقة",
		"closeIssue": "إغلاق القضية",
		"bugReport": "تقرير الشوائب",
		"noReports": "لا تقارير",
		"version": "الإصدار",
		"addComment": "اضف تعليق",
		"comments": "تعليقات",
		"noComments": "بدون تعليقات",
		"loadingFiles": "تحميل ملفات...",
		"expected": "سلوك متوقع",
		"listClosed": "قائمة تقارير الأخطاء المغلقة"
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"pool": "حوض السباحة",
			"app": "اسم التطبيق",
			"oldIosDownloads": "تنزيلات iOS القديمة",
			"oldTotalDownloads": "التنزيلات القديمة القديمة",
			"oldAndroidDownloads": "تنزيلات Android القديمة",
			"newIosDownloads": "تنزيلات iOS جديدة",
			"newAndroidDownloads": "تنزيلات جديدة من Android",
			"goLive": "بث مباشر",
			"stage": "المسرح",
			"newTotalDownloads": "إجمالي التنزيلات الجديدة",
			"country": "بلد",
			"contact": "اتصل",
			"averageRating": "متوسط ​​تقييم",
			"details": "تفاصيل المجتمع",
			"totalDownloads": "إجمالي التنزيلات",
			"locale": "مكان",
			"edit": "تحرير المجتمع",
			"downloads": "التنزيلات",
			"title": "نظرة عامة",
			"countries": "بلدان"
		},
		"devPools": {
			"editPool": "تحرير تجمع ديف",
			"completed": "منجز",
			"title": "حالة تجمع Dev",
			"noApps": "لا توجد تطبيقات في هذا التجمع ديف",
			"stages": "مراحل",
			"expected": "متوقع",
			"responsibility": "المسئولية",
			"requiredBy": "مطلوب من قبل",
			"language": "لغة",
			"estimatedDate": "تاريخ التطبيق المقدّر الحالي"
		}
	},
	"organization": {
		"confirmLanguageAssignment": "هل أنت متأكد من أنك تريد تعيين هذه اللغة؟ لا يمكن إزالة التعيينات اللغوية.",
		"details": "تفاصيل المنظمة",
		"title": "منظمة",
		"noLanguages": "لم يتم تعيين لغات"
	},
	"mailingList": {
		"title": "القوائم البريدية",
		"export": "تصدير",
		"list": "القوائم المتاحة",
		"dateTo": "إلى",
		"dateFrom": "من عند"
	},
	"moderation": {
		"image": {
			"title": "الإشراف على الصورة",
			"location": "موقعك"
		},
		"title": "الاعتدال"
	},
	"posts": {
		"title": "المشاركات"
	},
	"achievements": {
		"completion": "إكمال",
		"name": "اسم",
		"title": "الإنجازات",
		"badge": "شارة",
		"edit": "تحرير الإنجاز",
		"image": "صورة"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "كمية",
				"singular": "المبلغ المطروح"
			}
		},
		"plural": "محفزات"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"title": "Emoodji الاعتدال",
		"empty": "لا Emoodjis على الاعتدال"
	},
	"arpro": {
		"codeGroup": {
			"description": "وصف",
			"codeGroup": "مجموعات الرموز",
			"name": "اسم",
			"codes": "رموز",
			"editItem": "تحرير مجموعة التعليمات البرمجية",
			"addCodes": "أدخل رمز هنا"
		},
		"colours": {
			"description": "وصف",
			"colours": "الألوان",
			"name": "اسم"
		},
		"search": {
			"burningRate": "معدل حرق",
			"tensileStrength": "قوة الشد",
			"tensileElongation": "استطالة الشد",
			"resilience75DynamicCompression": "القدرة الديناميكية 75 ضغط ديناميكي",
			"compressionStrength50": "قوة ضغط 50",
			"compressionStrength75": "قوة ضغط 75",
			"mouldedDensity": "كثافة مقولبة",
			"equivalentYoungsModulus": "معادلة يونغز معامل",
			"search": "بحث منتوج",
			"compressionStrength25": "قوة ضغط 25"
		},
		"product": {
			"properties": {
				"weight": "الوزن (ملغ)",
				"bulkLabel": "حجم",
				"food": "وافق الغذاء",
				"size": "الحجم (مم)",
				"moulded": "كثافة مقولبة (جم / لتر)",
				"bag": "حقيبة",
				"packaging": "التعبئة والتغليف",
				"bulk": "الكثافة الظاهرية (جم / لتر)"
			},
			"moulded": "مدى الكثافة المقولبة (g / l)",
			"colour": "اللون",
			"product": "منتجات",
			"specifications": "مواصفات",
			"notes": "ملاحظات",
			"grade": "درجة",
			"name": "اسم",
			"linked": "لديه رموز الوصول",
			"rawDataError": "بيانات JSON الخام - بيانات JSON غير صالحة",
			"access": "رموز الوصول",
			"editItem": "تحرير المنتج",
			"rawData": "بيانات JSON الخام",
			"noneLinked": "لا رموز الوصول",
			"physicalProps": "الخصائص الفيزيائية"
		},
		"noitems": "لا توجد عناصر لعرضها ، انقر فوق إضافة أعلاه.",
		"arpro": "arpro"
	},
	"r4b": {
		"level": {
			"colour": "اللون",
			"title": "مستوى",
			"green": "أخضر",
			"levels": "مستويات",
			"blue": "أزرق",
			"red": "أحمر",
			"alpha": "ألفا",
			"selectBadge": "يرجى اختيار شارة"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "قسم رؤية المنطق"
				},
				"slider": {
					"endLabel": "نهاية التسمية",
					"interval": "فترة",
					"startLabel": "بدء التسمية"
				},
				"question": {
					"title": "سؤال وجواب",
					"textHelp": "أدخل السؤال",
					"responseHelp": "أدخل الرد",
					"addResponse": "أضف استجابة",
					"addAnotherResponse": "أضف استجابة أخرى",
					"selectionHelp": "حدد قسمًا للمتابعة",
					"answerHelp": "أدخل إجابة"
				},
				"quote": {
					"citation": "أدخل الاقتباس هنا",
					"title": "اقتبس"
				},
				"checkbox": {
					"title": "مربعات"
				},
				"textInput": {
					"help": "أدخل تسمية الإدخال هنا",
					"title": "إدخال النص",
					"multi": "نص متعدد الخطوط؟"
				},
				"link": {
					"title": "الروابط"
				},
				"help": {
					"title": "كائن المساعدة"
				},
				"task": {
					"help": "أدخل هدف المهمة",
					"title": "كائن المهمة"
				},
				"text": {
					"title": "كائن نص"
				},
				"radio": {
					"title": "أزرار الراديو"
				},
				"list": {
					"add": "إضافة عنصر القائمة"
				},
				"options": "خيارات",
				"clickToEdit": "إضغط للتعديل",
				"enterTitle": "أدخل العنوان",
				"enterText": "أدخل النص هنا",
				"break": "فاصل صفحة",
				"addIdentifier": "إضافة معرف",
				"inputLabel": "نص إدخال التسمية",
				"addSection": "اسحب عنصرًا هنا لإنشاء قسم جديد",
				"videoLink": "رابط الفيديو الخارجي",
				"identifier": "معرف",
				"section": "الجزء"
			},
			"section": {
				"title": "الجزء",
				"settings": "إعدادات القسم",
				"delete": "حذف القسم",
				"add": "أضف القسم أدناه"
			},
			"blockTypes": {
				"task": "مهمة",
				"select": "تحديد",
				"link": "حلقة الوصل",
				"textInput": "مدخل النص",
				"media": "كتل الوسائط",
				"video": "فيديو",
				"radio": "راديو",
				"slider": "المنزلق",
				"check": "التحقق من",
				"image": "صورة",
				"quote": "اقتبس",
				"userInput": "USER INPUT BLOCKS",
				"qna": "Q & A",
				"help": "مساعدة",
				"text": "نص"
			},
			"version": "الإصدار",
			"module": "وحدة",
			"level": "مستوى",
			"levels": "مستويات",
			"saved": "يتم حفظ جميع التغييرات",
			"mins": "دقيقة",
			"people": "اشخاص",
			"title": "وحدات",
			"person": "شخص",
			"visibility": "رؤية",
			"unsaved": "هناك تغييرات غير محفوظة"
		},
		"assessments": {
			"pre": "قبل التقييم",
			"assessment": "تقدير"
		},
		"admin": "لوحة تحكم المشرف",
		"title": "أطلس",
		"contentBlocks": "كتل المحتوى",
		"delete": "حذف",
		"sections": "الأقسام",
		"languages": "اللغات",
		"isBonus": "علاوة:",
		"id": "معرف فريد",
		"empty": "لا توجد وحدات أو تقييمات هنا ، أضف واحدة إلى هذا المستوى للبدء.",
		"questions": "الأسئلة",
		"notTranslated": "غير مترجمة",
		"people": "اشخاص",
		"placeholder": "نائب",
		"inputBlocks": "USER INPUT BLOCKS",
		"copyModule": "وحدة مكررة",
		"minutes": "MINS",
		"noTranslationHelp": "تحرير الحقل + حفظ للتحديث",
		"languageVars": "تنويعات اللغة",
		"copyConfirmation": "هل أنت متأكد من أنك تريد تكرار هذه الوحدة؟",
		"mediaBlocks": "كتل الوسائط",
		"person": "شخص",
		"setBonus": "تعيين وحدة مكافأة:"
	},
	"rspb": {
		"factFile": {
			"commonName": "اسم شائع",
			"description": "وصف",
			"editItem": "تحرير ملف الحقائق",
			"activities": "أنشطة",
			"addImages": "اختر صورة",
			"tags": "الكلمات",
			"start": "بداية",
			"funFacts": "حقائق ممتعة",
			"addFact": "أدخل حقيقة هنا",
			"factFile": "ملفات حقيقية",
			"addFeatures": "أدخل ميزة هنا",
			"features": "الميزات",
			"images": "صور",
			"show": "تبين",
			"name": "اسم",
			"scientificName": "الاسم العلمي",
			"seen": "رأيت",
			"addActivities": "أدخل نشاطًا هنا",
			"addTags": "أدخل علامة هنا"
		},
		"planItem": {
			"difficulty": {
				"medium": "متوسط",
				"hard": "الصعب",
				"difficulty": "حدد الصعوبة",
				"easy": "سهل"
			},
			"garden": {
				"selectType": "اختر نوع / ق الحديقة",
				"large": "كبير",
				"public": "عامة",
				"small": "صغير",
				"medium": "متوسط",
				"balconyroof": "شرفة / سقف"
			},
			"description": "وصف",
			"months": "الشهور",
			"planItems": "عناصر الخطة",
			"duration": "المدة (ثانية)",
			"type": "اكتب",
			"weightingHelp": "أولوية المكان الذي سيظهر فيه العنصر في التطبيق",
			"to": "تاريخ الانتهاء",
			"regionHelp": "انقر لتحديد المنطقة لهذا البند من الخطة",
			"from": "متاح من",
			"tags": "الكلمات",
			"pageHelp": "هذه هي الصفحة التي سيرتبط بها عنصر خطتك",
			"title": "عنوان",
			"weighting": "ترجيح البند الخطة",
			"region": "منطقة",
			"activityCode": "رمز النشاط",
			"dates": "تواريخ",
			"mostImportant": "الأهم",
			"source": "مصدر",
			"identifier": "معرف",
			"visible": "مرئي من",
			"leastImportant": "أقل أهمية",
			"indefinite": "غير محدد؟",
			"image": "صورة",
			"solo": "لأجل العائلة؟"
		},
		"noitems": "لا توجد عناصر لعرضها ، انقر فوق إضافة أعلاه.",
		"rspb": "RSPB"
	},
	"facility": {
		"filter": {
			"select": "اختر منشأة",
			"title": "نوع منشأة التصفية:"
		},
		"edit": {
			"address": {
				"title": "عنوان",
				"country": "بلد",
				"line1": "خط 1",
				"state": "حالة",
				"locality": "مكان",
				"postalCode": "الرمز البريدي"
			},
			"contactdetails": {
				"fax": "فاكس",
				"add": "أضف رقم",
				"phone": "أرقام الهاتف"
			},
			"name": "اسم",
			"location": "موقعك",
			"url": "URL",
			"type": "اكتب",
			"division": "قطاع",
			"facilityid": "FacilityId",
			"contact": "اتصل",
			"region": "منطقة",
			"facility": "مرفق تحرير",
			"add": "إضافة منشأة"
		},
		"placeholder": "كائن منشأة",
		"title": "مرافق",
		"list": "قائمة المرافق"
	},
	"sponsorship": {
		"edit": {
			"inputLink": "أدخل عنوان URL للانتقال إليه",
			"nativeLink": "أدخل رابطًا إلى جزء من الوظائف الأصلية",
			"link": "حلقة الوصل",
			"sponsor": "تعديل الراعي",
			"tags": "الكلمات",
			"analytics": "رمز التحليلات",
			"description": "وصف",
			"featureHelp": "حدد صورة لاستخدامها كصورة الراعي الرئيسي.",
			"iconHelp": "حدد صورة لاستخدامها كرمز.",
			"tagsHelp": "تسمح هذه العلامات بإضافات رعاية متعددة إلى صفحة.",
			"add": "إضافة الراعي",
			"analyticsHelp": "أدخل رمز تحليلات الراعي الخاص بك هنا.",
			"icon": "أيقونة صورة",
			"textHelp": "يتم استخدام هذا النص كعنوان لعنصر الجهة الراعية.",
			"feature": "صورة مميزة",
			"pagesLink": "حدد صفحة للربط بها",
			"text": "الراعي الرئيسي للنص",
			"descriptionHelp": "وصف للرعاية."
		},
		"list": "قائمة الرعاة",
		"placeholder": "كائن الرعاية",
		"title": "رعاية"
	},
	"journey": {
		"pending": "قيد الانتظار",
		"stage": "المسرح",
		"link": "حلقة الوصل",
		"elapsed": "انقضت",
		"edit": "تحرير رحلة الدم",
		"active": "نشيط",
		"pageTitle": "رحلة الدم",
		"title": "عنوان"
	},
	"frame": {
		"name": "اسم",
		"pageTitle": "إطارات",
		"edit": "تحرير الإطار",
		"type": "اكتب",
		"src": "SRC",
		"order": "طلب"
	},
	"swim": {
		"edit": "تحرير مستوى السباحة",
		"pageTitle": "مستويات السباحة"
	}
}