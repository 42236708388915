{{#each data.sponsorship}}
	<div class="sponsorship-inspector-item">
		<div class="sponsorship-select-items">
			<select class="sponsorship-type input-block-level form-control" data-key={{@key}} data-id={{#if id}} {{id}} {{else}} '-1' {{/if}} data-cid={{../viewCid}}>
					<option value="id" {{selectedSimpleMatches type 'id'}}>Select single sponsorship</option>
					<option value="tag" {{selectedSimpleMatches type 'tag'}}>Select by tag</option>
			</select>

			<select class="sponsorship-tag-selection input-block-level form-control form-control-margin" data-key={{@key}} data-id={{#if id}} {{id}} {{else}} '-1' {{/if}} data-cid={{../viewCid}}>
					<option>-</option>
				{{#each ../tags}}
					<option value="{{this}}" {{selectedSimpleMatches this ../value}}>{{this}}</option>
				{{/each}}
			</select>

			<select class="sponsorship-selection input-block-level form-control form-control-margin" data-key={{@key}} data-id={{#if id}} {{id}} {{else}} '-1' {{/if}} data-cid={{../viewCid}}>
					<option>-</option>
				{{#each ../sponsorList}}
					<option value="{{id}}" {{selectedSimpleMatches id ../value}}>{{getLocaleText text}}</option>
				{{/each}}
			</select>
		</div>
		{{#if ../isListable}}
		<div class="sponsorship-actions">
			<button class="btn-link remove-sponsorship" data-id={{#if id}} {{id}} {{else}} '-1' {{/if}}><i class="icon-remove"></i></button>
		</div>
		{{/if}}
	</div>
	<hr>
{{/each}}
