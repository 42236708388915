<i class="icon-comments"></i>
<div class="block-content">
	<b><code><i class="icon-lightbulb"></i> {{model.class}}</code></b><br>
	<label>{{t "r4b.modules.blockContent.question.title"}}</label>
	<br>
	<div class="question">
		<i class="icon-question-sign"></i>
		<span class="text-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.question.textHelp'}}" contenteditable="true">{{getLocaleText model.text.content}}</span>
	</div>
	{{#isNotTranslated  model.text.content language}}
		<span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
	{{/isNotTranslated}}
	<div class="options">
		{{#each model.options}}
			<div class="answer">
				<span>{{@key}}:</span>
				{{#isNotTranslated this.value.content ../language}}
					<span class="text-danger tooltip-bottom" data-tooltip="{{t 'r4b.notTranslated'}}">
						<i class="icon-warning-sign"></i>
					</span>
				{{/isNotTranslated}}
				<i class="icon-comment"></i><span style="width: 50%;"class="value-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.question.answerHelp'}}" data-key={{@key}} contenteditable="true" data-selector="{{../selectorId}}">{{getLocaleText this.value.content}}</span>

				{{#isNotTranslated this.response.content ../language}}
					<span class="text-danger tooltip-bottom" data-tooltip="{{t 'r4b.notTranslated'}}">
						<i class="icon-warning-sign"></i>
					</span>
				{{/isNotTranslated}}
				<i class="icon-quote-left"></i><span class="response-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.question.responseHelp'}}" data-key={{@key}} contenteditable="true" data-selector="{{../selectorId}}">{{getLocaleText this.response.content}}</span>

				<i class="icon-circle-arrow-right"></i> <select data-key={{@key}} class="select-section" style="width: 50%;" class="form-control" data-selector="{{../selectorId}}">
						{{#times ../numberOfSections}}
								<option value="{{addOne this}}" {{selectDisableSection this ../../sectionNumber ../section}}>
										{{t "r4b.modules.blockContent.section"}} {{addOne this}}
								</option>
						{{/times}}
				</select>
				<i data-key={{@key}} {{#if id}} data-id={{id}} {{/if}} class="icon-trash delete-response red-delete"></i>
			</div>
			<hr>
		{{/each}}
		<h4 class="add-response"><i class="icon-plus-sign"></i>
			{{#if model.options}}
				{{t "r4b.modules.blockContent.question.addAnotherResponse"}}
			{{else}}
				{{t "r4b.modules.blockContent.question.addResponse"}}
			{{/if}}
		</h4>
	</div>

	<hr>
	<div class="bottom">
		<div class="identifier-form">
			<label for="{{selectorId}}-identifier">{{t "r4b.modules.blockContent.identifier"}}</label>
			<input type="text" class="identifier" id="{{selectorId}}-identifier" placeholder="{{t 'r4b.modules.blockContent.addIdentifier'}}" value="{{model.identifier}}" {{#if model.identifier}} disabled="true" {{/if}}>
		</div>
		<i{{#if model.id}} data-id={{model.id}} {{/if}} data-index={{index}} data-sectionIndex={{sectionIndex}} class="icon-trash delete-block red-delete"></i>
	</div>
</div>
