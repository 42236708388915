var termRegex = /(TERM)\(\'(\d+.[_a-zA-Z]+).([_a-zA-Z\d]+)\'([~!=><]{1,2})(.+)\)/g

function parseLogic(logicStr, units, termId, childId) {
	if (!termId) {
		termId = 1
	}

	if (!childId) {
		childId = 1
	}

	var parsed = {
		prefix: "",
		children: [],
		terms: [],
		id: childId
	}

	// Remove spaces
	var str = logicStr.replace(/\s/g, "")
	if (str.indexOf("OR") === 0) {
		parsed.prefix = "OR"
	}

	if (str.indexOf("TERM") === 0) {
		parsed.prefix = "TERM"
		parsed.terms = parseTermString(str, units)
		parsed.termStr = str
	}

	if (str.indexOf("AND") === 0) {
		parsed.prefix = "AND"
	}

	var logicStrs = getNestedLogicStrings(str)

	parsed.children = logicStrs.map(function(str) {
		childId++
		var resp = parseLogic(str, units, termId, childId)

		if (resp.prefix === "TERM") {
			for (var i = 0; i < resp.terms.length; i++) {
				var item = JSON.parse(JSON.stringify(resp.terms[i]))
				item.id = termId
				parsed.terms.push(item)
				termId++
			}
			return null
		}
		return resp
	}).filter(function(item) {
		return Boolean(item)
	})

	return parsed
}

function getNestedLogicStrings(str) {
	// Holds the logic strings to parse for returning.
	var nestedLogicStrings = []
	// Houses the characters that will make up each logic string in `nestedLogicStrings`.
	var charactersToAssemble = []
	// Tracks how deep into brackets we are as the for loop iterates through the characters of the string.
	var depth = 0
	var split = str.split("")

	split.forEach(function(char) {
		if (char === ")") {
			depth -= 1
		}

		// Exclude anything outside of (and including) the brackets containing the nested logic, and exclude the commas and spaces separating the nested logic.
		if (!(depth === 1 && [" ", ","].includes(char)) && depth > 0) {
			charactersToAssemble.push(char)
		}

		// If we come across a closing bracket that brings us to a depth of one, then we've come to the end of that piece of nested logic.
		if (depth === 1 && char === ")") {
			// Join the characters and append the resulting string to our outermostLogic array.
			nestedLogicStrings.push(charactersToAssemble.join(""))
			charactersToAssemble = []
		}

		// Increase depth when we reach an open bracket.
		if (char === "(") {
			depth += 1
		}
	})

	return nestedLogicStrings
}

function parseTermString(term, units) {
	var parsed = validateTermString(term)
	var terms = []

	if (parsed !== null && parsed !== undefined) {
		var blocks = getBlocks(parsed[2].trim(), units)
		var block = parsed[3].trim()
		terms.push({
			module: parsed[2].trim(),
			block: block,
			blocks: blocks,
			valueType: getValueType(block, blocks),
			operator: parsed[4].toLowerCase().trim(),
			value: parsed[5].trim().replace(/('|\))/g, "")
		})
	}

	return terms
}

function validateTermString(termLogic) {
	var termLogicCopy = JSON.parse(JSON.stringify(termLogic))
	var m
	var result

	while ((m = termRegex.exec(termLogicCopy)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === termRegex.lastIndex) {
			termRegex.lastIndex++
		}

		if (m) {
			result = m
		}
	}

	return result
}

function getBlocks(module, units) {
	var blocks = []
	if (units) {
		var moduleModel = units.where({identifierNoVersion: module})
		if (moduleModel[0]) {
			var children = moduleModel[0].get('children')
			if (children) {
				children.forEach(function(section) {
					section.children.forEach(function(block) {
						if (block.identifier) {
							var type = 'text'
							if (block.class === 'R4b_QuestionAssessmentBlock' ||
block.class === 'R4b_SliderInputModuleBlock' ||
block.class === 'R4b_CheckboxInputModuleBlock' ||
block.class === 'R4b_RadioInputModuleBlock' ||
block.class === 'R4b_SelectInputModuleBlock') {
								type = 'number'
							}
							blocks.push({
								identifier: block.identifier,
								type: type
							})
						}
					})
				})
			}
		}
	}
	return blocks
}

function getValueType(block, blocks) {
	var type = 'text'
	blocks.forEach(function(obj) {
		if (block === obj.identifier) {
			type = obj.type
		}
	})
	return type
}

module.exports = {parseLogic: parseLogic, getBlocks: getBlocks}
