var StandaloneStormObject = require('editor/standalone-storm-object'),
	StormObject = require('editor/storm-object'),
	EditorSectionView 			= require('editor/editor-section-view'),
	CodeGroups							= require('arpro/code-groups/code-group-list'),
	ColourList							= require('arpro/products/colour-list'),
	domtoimage							= require('dom-to-image'),
	UploadVariants					= require('media-library/upload-variants'),
	PhyisicalPropertyTable	= require('./physical-property-table'),
	Chart = require('chart.js'),
	numberRegex = /\d*\.?\d+/

/**
 * Exports {@link ProductEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends ProductEditView.prototype */ {
	/** @override */
	className: 'ProductEditView',
	/** @override */
	template: require('./product-edit-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .save': 'save',
		'click #addCode': 'addCode',
		'click .code-remove-button': 'removeCode',
		'change #colour-input': 'updateColour',
		'change #food-approved': 'foodAppovedToggle',
		'change .spec-input': 'setSpecifications',
		'change .js-arpro-density-data': 'updateDensityData',
		'change .js-update-note-specification': 'updateNoteSpecification',
		'click .js-add-new-row': 'addNewRow',
		'click .js-hide-row': 'hideChartRow',
		'click .js-hide-column': 'hideChartColumn',
		'change #category-input': 'setCategory',
		'input input[type=number]': 'formatNumber',
		'click .js-remove-note': 'deleteNote',
		'click .js-remove-lock': 'removeLock'
	},

	/**
	 * @constructs ProductEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		/** @private {App} */
		this.app_ = options.app

		this.model = new StandaloneStormObject()

		App.startLoad()

		var promises = []

		var modelPromise = Promise.resolve()

		promises.push(modelPromise)

		this.codeGroups = new CodeGroups({
			appId: this.app_.id
		})
		promises.push(this.codeGroups.fetch())

		this.colourList = new ColourList({
			appId: this.app_.id
		})
		promises.push(this.colourList.fetch())

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)

			promises[0] = this.model.fetch()
		} else {
			this.model = StandaloneStormObject.fromClassName('StormQLProduct')
			this.model.set('weight', null)
			this.model.set('size..from', null)
			this.model.set('size..to', null)
			this.model.set('density..bulk..to', null)
			this.model.set('density..bulk..from', null)
			this.model.set('density..moulded..to', null)
			this.model.set('density..moulded..from', null)
		}

		this.physicalPropsTableChanged = false

		this.listenTo(this.model, 'renderTable', this.renderPhysicalPropsTable)
		this.listenTo(this.model, 'tableChanged', this.physicalTableChanged)
		this.listenTo(this.model, 'change:data', this.setTestsTable)

		Promise.all(promises)
			.then(this.modelLocked.bind(this))
	},

	modelLocked: function() {
		if (this.model.get('pageId') > 0) {
			this.model.requestLock(function() {
				App.stopLoad()
				this.render()
			}.bind(this)).then(function() {
				App.stopLoad()
				this.render()
			}.bind(this))
		} else {
			App.stopLoad()
			this.render()
		}
	},

	/** @override */
	getRenderData: function() {
		var data = {}
		data.densityTableHeaders = this.getDensityTableHeaders()
		data.formattedDensityData = this.getDensityTableData()
		data.model = this.model.toJSON()
		data.codeGroups = this.codeGroups.toJSON()
		data.colourList = this.colourList.toJSON()
		data.appId = this.app_.id
		return data
	},

	getCategory: function() {
		var categories = this.model.get('category')
		if (categories) {
			if (categories.length === 1) {
				return categories[0]
			} else if (categories.length > 1) {
				return categories[1]
			}
		}
		return ''
	},

	removeLock: function() {
		if (this.model.get('pageId') > 0) {
			App.startLoad()
			this.model.requestUnlock().then(App.stopLoad())
		}
	},

	physicalTableChanged: function() {
		this.physicalPropsTableChanged = true
	},

	afterRender: function() {
		this.access = this.model.get('access')
		this.populateList()
		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
		this.renderPhysicalPropsTable()
		this.updateColour()
		this.updateNoteSpecification()
		this.renderMouldedDensityChart()
		this.updateDensityVisibilityButtons()
		this.$('#category-input').val(this.getCategory())
		this.$('#category-input').select2()

		if (this.model) {
			if (this.model.get('id') >= 0 && !this.model.lock.isLocked()) {
				var formInputs = $('.js-form-control')

				for (var i = 0; i < formInputs.length; i++) {
					$(formInputs[i]).attr('disabled', 'disabled')
				}
			}
		}
	},

	formatNumber: function(e) {
		var input = $(e.currentTarget)
		input.val(input.val().match(numberRegex))
	},

	setTestsTable: function() {
		this.tests = this.model.get('data..tests')
	},

	save: function() {
		App.startLoad()
		var promises = []
		var self = this

		if (this.physicalPropsTableChanged) {
			var table = $('#arpro-properties-table')
			table.addClass('boring-arpro-table')

			// Set empty attributes
			$('#arpro-properties-table  > tbody > tr').each(function() {
				self.physicalPropsView.setHiddenAttrs($(this).find('td > input'))
			})

			$('.boring-arpro-table td, .boring-arpro-table th').each(function() {
				var cell = $(this)
				var value = cell.find('.form-control:eq(0)').val()
				if (value !== undefined) {
					cell.html(value)
				}
			})

			$('#arpro-properties-table .table-row-section').each(function() {
				// if all of a table row section's rows are hidden, hide that table row
				if ($(this).nextUntil($('.table-row-section'), '.table-row-data:hidden').length === $(this).nextUntil($('.table-row-section'), '.table-row-data').length) {
					$(this).hide()
				}

				if ($(this).children('td').children('span:empty')) {
					var foundText = false
					$(this).children('td').children('span').each(function() {
						var empty = !$.trim($(this).html())
						if (!empty) {
							foundText = true
						}
					})
					if (!foundText) {
						$(this).next().children().css('border-top', '2px solid black')
						$(this).hide()
					}
				}
			})

			var tableContainer = $('#arpro-properties-table')
			var node = tableContainer[0]
			var imageUpload = new Promise(function(resolve, reject) {
				domtoimage.toPng(node)
					.then(function(dataUrl) {
						// Here is where we need to apply the image to the model.. (upload it first?)
						table.removeClass('boring-arpro-table')

						var formData = new FormData()
						var blob = this.dataURItoBlob(dataUrl)
						formData.append('files', blob, 'table.png')
						formData.append('Authorization', App.session.get('token'))
						formData.append('appId', this.app_.id)
						formData.append('transparent', false)
						formData.append('tags', 7) // This should be gathered from the tags list.. where name === 'image'

						$.ajax({
							url: App.apiRoot + 'uploads',

							data: formData,
							type: 'POST',
							contentType: false,
							processData: false,
							global: false,

							headers: App.session.getHeadersObject(),

							error: function(jqXHR) {
								console.log(jqXHR)
							},

							complete: function(data) {
								// Now we need to create an image based off of the response and add it to the model...
								this.setTableImage(data.responseJSON, self).then(function() {
									resolve()
								})
							}.bind(this)
						})
					}.bind(this))
					.catch(function(error) {
						console.error('oops, something went wrong!', error)
						table.removeClass('boring-arpro-table')
						reject()
					})
			}.bind(this))

			promises.push(imageUpload)
		}

		this.model.set('access', this.access)
		this.model.set('colour', $('#colour-input').val())
		this.model.set('name', $('#name-input').val().trim())
		this.model.set('grade', $('#grade-input').val().trim())

		this.setSpecifications()
		this.setNotes()
		this.setDensityData()
		this.setCategory()
		this.removeInvisibleTestData()
		this.removePhysicalPropertyTables()

		Promise.all(promises)
			.then(this.saveModel.bind(this))
		return false
	},

	removePhysicalPropertyTables: function() {
		var tests = this.model.get('data..tests')
		var self = this
		tests.forEach(function(test) {
			if (test.data) {
				test.data.forEach(function(series) {
					if (series.data) {
						series.data.forEach(function(seriesData, index) {
							if (seriesData.toDelete) {
								if (seriesData.id) {
									var seriesDataModel = new StormObject(seriesData)
									seriesDataModel.lock = self.model.lock
									seriesDataModel.destroy()
								} else {
									series.data.splice(index, 1)
								}
							}
						})
					}
				})
			}
		})
	},

	removeInvisibleTestData: function() {
		var tests = this.model.get('data..tests')

		tests.forEach(function(test, testIndex) {
			// We need to destroy anything that has a disabled = true agaisnt it..
			var removeCount = 0
			var testCount = test.data.length
			test.data.forEach(function(series, index) {
				if (series.disabled && series.id >= 0) {
					var seriesModel = new StormObject(series)
					seriesModel.lock = this.model.lock
					seriesModel.destroy()
					removeCount++
				} else if (series.disabled) {
					test.data.splice(index, 1)
					removeCount++
				}
				if (removeCount === testCount) {
					// Remove the test too...
					if (test.id >= 0) {
						var testModel = new StormObject(testModel)
						testModel.lock = this.model.lock
						testModel.destroy()
					} else {
						tests.splice(testIndex, 1)
					}
				}
			}.bind(this))
		}.bind(this))
	},

	renderPhysicalPropsTable: function() {
		this.physicalPropsView = new PhyisicalPropertyTable({model: this.model})
		this.$('#arpro-table-container').empty()
		this.$('#arpro-table-container').append(this.physicalPropsView.render().el)
	},

	setCategory: function() {
		var category = $('#category-input').val()
		var selected = $('#category-input option[value="' + category + '"]')
		var categories = []
		if (selected) {
			var group = selected.closest('optgroup').attr('label')
			if (group) {
				categories.push(group)
			}
			if (category) {
				if (category === 'On-site Expansion') {
					categories.push('ARPRO 1000')
				}
				categories.push(category)
			}
		}

		this.model.set('category', categories)
	},

	setTableImage: function(response, context) {
		return new Promise(function(resolve) {
			// If we get nothing back.. it's most likely because we've already uploaded the
			// same image before and "shouldn't" need to worry about resetting the image.
			if (response.id >= 0) {
				var table = this.model.get('table') || StormObject.fromClassName('Image', this.model.get('pageId'))

				if (table instanceof Backbone.Model) {
					table.set('pageId', this.model.get('pageId'))
				} else {
					table.pageId = this.model.get('pageId')
				}
				// Get variants of image...
				var variants = new UploadVariants({
					id: response.parentId
				})
				variants.fetch().then(function() {
					variants.sort()
					// Variants in order of size.
					variants.forEach(function(source, key) {
						switch (key) { // only concerned with x2 and x1
							case 1: // x2
								if (table instanceof Backbone.Model) {
									table.set('src..x2', 'cache://content/' + source.get('filename'))
								} else {
									table.src.x2 = 'cache://content/' + source.get('filename')
								}
								break
							case 2: // x1
								if (table instanceof Backbone.Model) {
									table.set('src..x1', 'cache://content/' + source.get('filename'))
								} else {
									table.src.x1 = 'cache://content/' + source.get('filename')
								}
								break
						}
					})
					context.table = table
					resolve()
				})
			}
		}.bind(this))
	},

	saveModel: function() {
		var promises = []
		// Somehow it gets overwritten by this point.. reset it if its set...
		if (this.tests) {
			this.model.set('data..tests', this.tests)
		}

		if (this.table) {
			this.model.set('table', this.table)
		}

		promises.push(this.model.save(null, {
			appId: this.app_.id
		}))

		Promise.all(promises).then(function() {
			if (this.model.get('pageId') > 0) {
				this.model.requestUnlock().then(App.stopLoad())
			} else {
				App.stopLoad()
			}
			App.router.navigate('/apps/' + this.app_.id + '/products', {trigger: true})
		}.bind(this))
	},

	dataURItoBlob: function(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString

		if (dataURI.split(',')[0].indexOf('base64') >= 0) {
			byteString = atob(dataURI.split(',')[1])
		} else {
			byteString = unescape(dataURI.split(',')[1])
		}

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length)

		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}

		return new Blob([ia], {
			type: mimeString
		})
	},

	setSpecifications: function() {
		this.model.set('weight', parseFloat($('#weight-input').val()))
		this.model.set('size..from', parseFloat($('#size-input-1').val()))
		this.model.set('size..to', parseFloat($('#size-input-2').val()))
		this.model.set('density..bulk..from', parseFloat($('#bulk-input-1').val()))
		this.model.set('density..bulk..to', parseFloat($('#bulk-input-2').val()))
		this.model.set('density..moulded..from', parseFloat($('#moulded-input-1').val()))
		this.model.set('density..moulded..to', parseFloat($('#moulded-input-2').val()))
		this.model.set('packaging..bag', $('#checkbox-bag').is(':checked'))
		this.model.set('packaging..bulk', $('#checkbox-bulk').is(':checked'))
		this.model.set('foodApproved', $('#food-appoved-toggle').is(':checked'))
	},

	setNotes: function() {
		var notes = this.model.get('notes') || []
		var newNotes = $('.js-new-note')

		notes.forEach(function(note) {
			if (note) {
				var notesField = $('.js-update-note[data-id=' + note.get('id') + ']').val()
				var specificationField = $('.js-update-field[data-id=' + note.get('id') + ']').val()
				var isNoteToBeDeleted = $('.js-remove-note[data-id=' + note.get('id') + ']').hasClass('is-deleted')

				if (isNoteToBeDeleted) {
					var noteId = note.get('id')
					if (note.id === noteId) {
						note.destroy()
					}
				} else {
					if (specificationField === "") {
						specificationField = null
					}
					note.set('details..content..en', notesField)
					note.set('field', specificationField)
				}
			}
		})

		for (var i = 0; i < newNotes.length; i++) {
			var isNoteToBeDeleted = $(newNotes[i]).hasClass('is-deleted')
			if ($(newNotes[i]).val() !== '' && !isNoteToBeDeleted) {
				var newNotesModel = StormObject.fromClassName('Jsp_ProductNote', this.model.get('pageId'))
				var specifications = $('.js-new-specification')
				var specValue = $(specifications[i]).val() || ''

				newNotesModel.set('details..content..en', $(newNotes[i]).val())
				newNotesModel.set('field', specValue)

				if (notes instanceof Backbone.Collection) {
					notes.add(newNotesModel)
				} else {
					notes.push(newNotesModel)
				}
				this.model.set('notes', notes)
			}

			if (isNoteToBeDeleted) {
				$(newNotes[i]).closest('tr').remove()
			}
		}
	},

	setDensityData: function() {
		var techniques = this.model.get('data..techniques')

		techniques.forEach(function(technique) {
			var techniqueLabel = technique.label
			var isRowDisabled = $('[data-technique="' + techniqueLabel + '"]').find('.tab__cta').hasClass('is-disabled')

			if (isRowDisabled) {
				if (technique.id) {
					var row = new StormObject(technique)
					row.lock = this.model.lock
					row.destroy()
				}
			} else {
				technique.data.forEach(function(data, i) {
					var value = $('[data-technique="' + techniqueLabel + '"]').find($('.js-arpro-density-data[data-index=' + i + ']')).val()
					var isColumnDisabled = $('[data-header-index="' + i + '"]').hasClass('is-disabled')

					if (isColumnDisabled) {
						if (data.id) {
							var cell = new StormObject(data)
							cell.lock = this.model.lock
							cell.destroy()
						}
					} else {
						data.value = value
					}
				}.bind(this))
			}
		}.bind(this))
	},

	updateDensityData: function() {
		this.renderMouldedDensityChart()
	},

	addCode: function() {
		var input = parseInt($('#code-input').val(), 10)

		if (input) {
			if (this.access.indexOf(input) > -1) {
				return
			}
			this.access.push(input)
			this.populateList()
		}
	},

	removeCode: function(e) {
		var id = $(e.target).data("id")
		this.access.splice(id, 1)
		this.populateList()
	},

	populateList: function() {
		if (this.codeGroups.length > 0) {
			$('#code-list > li').remove()
			_.each(this.access, function(code, key) {
				// Get the right code group
				var codeGroup = this.codeGroups.get(code).toJSON()
				$('#code-list').append('<li>' + codeGroup.name.content.en + ' <a href="" class="code-remove-button" data-id="' + key + '">×</a></li>')
			}, this)
		}
	},

	updateColour: function() {
		var id = $('#colour-input').val()
		var colour = this.colourList.get(id)
		if (colour) {
			var values = colour.toJSON().colour
			$('.input-group-addon').css({
				"background-color": "rgba(" + values.r + ", " + values.g + ", " + values.b + ", " + values.a + ")"
			})
		}
	},

	foodAppovedToggle: function() {
		$('#food-approved .toggle-label').first().toggleClass('enabled')
		$('#food-approved .toggle-label').last().toggleClass('enabled')
	},

	updateNoteSpecification: function(e) {
		var dropdowns = $('.js-update-note-specification')
		var selectedValue = null
		var stars = $('.js-asterisk')

		for (var k = 0; k < stars.length; k++) {
			$(stars[k]).text(' ')
		}
		var newValue
		if (e) {
			newValue = $(e.currentTarget).val()
		}

		for (var i = 0; i < dropdowns.length; i++) {
			selectedValue = $(dropdowns[i]).val()

			if ($(dropdowns[i]).val() !== '') {
				if (e) {
					$(dropdowns[i]).val('')
				}
			}
		}

		if (e) {
			if ($(e.currentTarget)) {
				$(e.currentTarget).val(newValue)
			}
		}

		for (var j = 0; j < dropdowns.length; j++) {
			selectedValue = $(dropdowns[j]).val()

			if ($(dropdowns[j]).val() !== '') {
				$('.js-asterisk.' + selectedValue).text('*')
			}
		}
	},

	addNewRow: function(e) {
		var btn = $(e.currentTarget)
		var parentRow = btn.closest('tr')

		parentRow.before('<tr><td><label class="visually-hidden" for="note-value">Please enter a note about the product</label><input type="text" class="form-control js-new-note" id="note-value" value="" placeholder="Add notes here"></td><td class="narrow"><div class="justify-flex"><label class="visually-hidden" for="specification-value">Please select a specification</label><select class="styled-select js-new-specification js-update-note-specification" for="specification-value"><option value="" selected>None</option><option value="weight">Weight (mg)</option><option value="size">Size (mm)</option><option value="density.bulk">Bulk</option><option value="density.moulded">Moulded density (g/l)</option><option value="packaging">Packaging</option><option value="foodApproved">Food approved</option></select><button type="button" class="delete-cta js-remove-note">&times;</button></div></td></tr>')
		this.updateNoteSpecification()
	},

	deleteNote: function(e) {
		$(e.currentTarget).toggleClass('is-deleted')
		$(e.currentTarget).closest('tr').css('display', 'none')
	},

	getDensityTableData: function() {
		var techniques = this.model.get('data..techniques')
		var pageId = this.model.get('pageId')

		var tableData = {
			'not': {
				label: 'not',
				data: {
					'CF PT': {
						type: 'CF PT',
						id: '',
						value: null
					},
					'CF NPT': {
						type: 'CF NPT',
						id: '',
						value: null
					},
					'PF PT': {
						type: 'PF PT',
						id: '',
						value: null
					},
					'PF NPT': {
						type: 'PF NPT',
						id: '',
						value: null
					}
				}
			},
			'Good Manufacturing Practice': {
				label: 'Good Manufacturing Practice',
				data: {
					'CF PT': {
						type: 'CF PT',
						id: '',
						value: null
					},
					'CF NPT': {
						type: 'CF NPT',
						id: '',
						value: null
					},
					'PF PT': {
						type: 'PF PT',
						id: '',
						value: null
					},
					'PF NPT': {
						type: 'PF NPT',
						id: '',
						value: null
					}
				}
			},
			'Top Surface': {
				label: 'Top Surface',
				data: {
					'CF PT': {
						type: 'CF PT',
						id: '',
						value: null
					},
					'CF NPT': {
						type: 'CF NPT',
						id: '',
						value: null
					},
					'PF PT': {
						type: 'PF PT',
						id: '',
						value: null
					},
					'PF NPT': {
						type: 'PF NPT',
						id: '',
						value: null
					}
				}
			}
		}

		if (techniques) {
			$.each(tableData, function(key, value) {
				var rowFound = false
				// loop through rows and check if the row data exists in the api
				$.each(techniques, function(tKey, technique) {
					var rowLabel = technique.label
					if (rowLabel === key) {
						rowFound = true
						// loop through row data and check if it exists in the api
						$.each(value.data, function(colKey) {
							var colFound = false
							technique.data.forEach(function(data) {
								if (colKey === data.label) {
									colFound = true
								}
							})

							if (!colFound) {
								// if a column data does not exist in the api
								// add a new object for it with the label
								technique.data.push({
									class: 'SeriesData',
									value: null,
									label: colKey,
									pageId: pageId,
									tag: ''
								})
							}
						})
					}

					// needs to be in this order: CF PT, CF NPT, PF PT, PF NPT
					var sortedObjs = technique.data

					$.each(sortedObjs, function(i) {
						if ((sortedObjs[i].label === 'CF PT') && (i !== 0)) {
							sortedObjs.splice(0, 0, sortedObjs[i])
						}
						if ((sortedObjs[i].label === 'CF NPT') && (i !== 1)) {
							sortedObjs.splice(1, 0, sortedObjs[i])
						}
						if ((sortedObjs[i].label === 'PF PT') && (i !== 2)) {
							sortedObjs.splice(2, 0, sortedObjs[i])
						}
						if ((sortedObjs[i].label === 'PF NPT') && (i !== 3)) {
							sortedObjs.splice(3, 0, sortedObjs[i])
						}
					})
				})
				if (!rowFound) {
					// if row data does not exist in api,
					// add object for the row with empty columns objects
					techniques.push({
						class: 'Series',
						label: key,
						data: [
							{
								class: 'SeriesData',
								label: 'CF PT',
								id: '',
								value: null,
								pageId: pageId
							},
							{
								class: 'SeriesData',
								label: 'CF NPT',
								id: '',
								value: null,
								pageId: pageId
							},
							{
								class: 'SeriesData',
								label: 'PF PT',
								id: '',
								value: null,
								pageId: pageId
							},
							{
								class: 'SeriesData',
								label: 'PF NPT',
								id: '',
								value: null,
								pageId: pageId
							}
						],
						pageId: pageId
					})
				}
			})
		}

		if (techniques) {
			var rows = techniques
			var rowHeaders = ['not', 'Good Manufacturing Practice', 'Top Surface']
			var sortedRows = new Array(rowHeaders.length)

			rows.forEach(function(row) {
				var sortedIndex = rowHeaders.indexOf(row.label)
				sortedRows[sortedIndex] = row

				var cols = row.data
				var colHeaders = ['CF PT', 'CF NPT', 'PF PT', 'PF NPT']
				var sortedCols = new Array(colHeaders.length)

				cols.forEach(function(data) {
					var sortedIndex = colHeaders.indexOf(data.label)
					sortedCols[sortedIndex] = data
				})

				sortedRows[sortedIndex].data = sortedCols
			})

			techniques = sortedRows
		}

		this.model.set('data..techniques', techniques)

		tableData = techniques

		return tableData
	},

	updateDensityVisibilityButtons: function() {
		var cells = $('.js-arpro-density-data')
		var rows = $('.js-density-row')

		for (var r = 0; r < rows.length; r++) {
			var rowCells = $(rows[r]).find('td').not('.density-row-header')
			var rowCounter = 0
			for (var rC = 0; rC < rowCells.length; rC++) {
				if ($(rowCells[rC]).find('input').val() === '') {
					rowCounter++
				}

				if (rowCounter === 4) {
					$(rows[r]).find('.tab__cta').addClass('is-disabled')
				}
			}
		}

		var col1 = 0
		var col2 = 0
		var col3 = 0
		var col4 = 0

		for (var c = 0; c < cells.length; c++) {
			if (cells[c].value === '') {
				if (cells[c].dataset.index === '0') {
					col1++
				} else if (cells[c].dataset.index === '1') {
					col2++
				} else if (cells[c].dataset.index === '2') {
					col3++
				} else if (cells[c].dataset.index === '3') {
					col4++
				}
			}

			if (col1 === 4) {
				$('.js-density-column[data-header-index="0"]').addClass('is-disabled')
			}

			if (col2 === 4) {
				$('.js-density-column[data-header-index="1"]').addClass('is-disabled')
			}

			if (col3 === 4) {
				$('.js-density-column[data-header-index="2"]').addClass('is-disabled')
			}

			if (col4 === 4) {
				$('.js-density-column[data-header-index="3"]').addClass('is-disabled')
			}
		}
	},

	getDensityTableHeaders: function() {
		var techniques = this.model.get('data..techniques')
		var headerLabels = []
		if (techniques) {
			if (techniques[0]) {
				if (techniques[0].data) {
					techniques[0].data.forEach(function(data) {
						headerLabels.push(data.label)
					})
				}
			}
		}

		return headerLabels
	},

	getGraphData: function(nV) {
		var techniques = $('.js-arpro-density-data')

		var dataValues = []
		if (techniques) {
			for (var i = 0; i < techniques.length; i++) {
				var techniqueLabel = $(techniques[i]).closest('tr').data('technique')
				var headerName = $(techniques[i]).data('type')
				var dataValue = $(techniques[i]).val()
				var isDisabled = $('[data-header="' + headerName + '"]').hasClass('is-disabled')

				if (techniqueLabel === nV && !isDisabled) {
					dataValues.push(dataValue)
				}
			}
		}

		return dataValues
	},

	renderMouldedDensityChart: function() {
		var techniques = this.model.get('data..techniques')

		if (techniques) {
			$('#mouldedDensityChart-wrapper').empty()
			$('#mouldedDensityChart-wrapper').append('<canvas id="mouldedDensityChart" class="density-chart" width="250" height="220"></canvas')

			var ctx = document.getElementById("mouldedDensityChart").getContext('2d')
			var headers = ['CF PT', 'CF NPT', 'PF PT', 'PF NPT']
			var notData = this.getGraphData('not')
			var goodData = this.getGraphData('Good Manufacturing Practice')
			var topData = this.getGraphData('Top Surface')
			var rows = $('.js-density-row')
			var dataArray = [
				{
					label: "not",
					hiddenLegend: true,
					data: notData,
					backgroundColor: "rgba(255, 255, 255, 0)",
					hoverBackgroundColor: "rgba(255, 255, 255, 0)"
				},
				{
					label: "Good Manufacturing Practice",
					data: goodData,
					backgroundColor: "rgba(133, 214, 39, 1)",
					hoverBackgroundColor: "rgba(133, 214, 39, 1)"
				}, {
					label: "Top Surface",
					data: topData,
					backgroundColor: "rgba(63,203,226,1)",
					hoverBackgroundColor: "rgba(63,203,226,1)"
				}
			]

			for (var l = 0; l < rows.length; l++) {
				var isRowDisabled = $(rows[l]).find('.tab__cta').hasClass('is-disabled')
				var rowName = $(rows[l]).data('technique')
				var listToDelete = null
				if (isRowDisabled) {
					switch (rowName) {
						case 'not':
							listToDelete = ['not']
							dataArray = this.removeRowFromArray(dataArray, listToDelete)
							break

						case 'Good Manufacturing Practice':
							listToDelete = ['Good Manufacturing Practice']
							dataArray = this.removeRowFromArray(dataArray, listToDelete)
							break

						case 'Top Surface':
							listToDelete = ['Top Surface']
							dataArray = this.removeRowFromArray(dataArray, listToDelete)
							break
					}
				}
			}

			var columns = $('.js-density-column')

			for (var i = 0; i < columns.length; i++) {
				var isColumnDisabled = $(columns[i]).hasClass('is-disabled')
				var columnName = $(columns[i]).data('header')

				if (isColumnDisabled) {
					headers = this.removeColFromArray(headers, columnName)
				}
			}

			this.mouldedDensityChart = new Chart(ctx, {
				type: 'horizontalBar',
				data: {
					labels: headers,
					datasets: dataArray
				},
				options: {
					maintainAspectRatio: false,
					responsive: false,
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true
							},
							gridLines: {
								display: false
							},
							stacked: true
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							stacked: true
						}]
					},
					legend: {
						position: 'bottom',
						onClick: function(e) {
							e.stopPropagation()
						}
					},
					tooltips: {
						enabled: false
					}
				}
			})
		}
	},

	removeRowFromArray: function(dataArray, listToDelete) {
		dataArray = dataArray.filter(function(obj) {
			return listToDelete.indexOf(obj.label)
		})

		return dataArray
	},

	removeColFromArray: function(headers, columnName) {
		headers = headers.filter(function(item) {
			return item !== columnName
		})

		return headers
	},

	hideChartRow: function(e) {
		e.preventDefault()
		e.currentTarget.classList.toggle('is-disabled')

		if (e.currentTarget.classList.contains('is-disabled')) {
			this.renderMouldedDensityChart('hide row')
		} else {
			this.renderMouldedDensityChart('show row')
		}
	},

	hideChartColumn: function(e) {
		e.preventDefault()
		e.currentTarget.classList.toggle('is-disabled')

		if (e.currentTarget.classList.contains('is-disabled')) {
			this.renderMouldedDensityChart('hide column')
		} else {
			this.renderMouldedDensityChart('show column')
		}
	}
})
