var StandaloneStormObject = require('editor/standalone-storm-object')
var PAGE_SIZE = 20
module.exports = Backbone.Collection.extend({
	model: StandaloneStormObject,

	initialize: function(options) {
		this.appId = options.appId
		this.searchData = options.searchData
		this.startCount = options.startCount
		this.objectsLoaded = 0
		this.paginate = options.paginate
	},

	url: function() {
		return App.apiRoot + 'objects/search/'
	},

	fetch: function() {
	// store reference for this collection
		var collection = this
		// Clear list if no start specified.
		if (this.startCount === undefined) {
			this.remove(this.models)
			this.startCount = 0
		}

		this.objectsLoaded = this.startCount + PAGE_SIZE

		var headers = {
			'Authorization': App.session.get('token'),
			'Content-Type': 'application/json'
		}

		if (this.paginate) {
			headers.Range = 'indices=' + this.startCount + '-' + (this.startCount + PAGE_SIZE - 1)
		}

		this.startCount = this.objectsLoaded

		return $.ajax({
			remove: false,
			type: 'POST',
			url: this.url(),
			dataType: 'json',
			headers: headers,
			data: JSON.stringify({
				class: this.searchData.class,
				appId: this.appId,
				properties: this.searchData.properties,
				type: "cms"
			}),
			success: function(data, status, options) {
				// set collection data (assuming you have retrieved a json object), view may need to listen to reset..
				var range = options.getResponseHeader('Content-Range')

				if (range) {
					this.totalObjects = parseInt(range.match(/.*\/(\d+)/)[1], 10)
				}
				collection.add(data)
			}.bind(this)
		})
	}
})
