var LocaleList = require('editor/locale-list'),
	LanguageList = require('editor/language-list'),
	CountryList = require('editor/countries-list')

module.exports = Backbone.View.extend({
	template: require('./locale-view-template'),

	events: {
		'click .save-locales': 'saveLocales',
		'click .add-locale': 'addLocale'
	},

	initialize: function(options) {
		// Get app details
		this.app = options.app
		// Fetch list of Locales enabled for this app
		this.appLocales = new LocaleList(null, {appId: this.app.id})
		var localeFetch = this.appLocales.fetch()

		// Fetch list of all society languages
		this.allLanguages = new LanguageList(null, {societyId: this.app.get('societyId')})
		var languageFetch = this.allLanguages.fetch()

		// Fetch list of all countries
		this.countries = new CountryList()
		var countryFetch = this.countries.fetch()

		Promise.all([localeFetch, languageFetch, countryFetch]).then(this.ready.bind(this))
	},

	getRenderData: function() {
		return {
			appId: this.app.id,
			appLocales: this.appLocales.toJSON(),
			allLanguages: this.allLanguages.toJSON(),
			countries: this.countries.toJSON(),
			defaultLocale: this.app.get('localeId'),
			app: this.app.toJSON(),
			societyLocaleId: this.getSocietyLocaleId()
		}
	},

	getSocietyLocaleId: function() {
		return App.societiesList.get(this.app.get('societyId')).get('countryId')
	},

	afterRender: function() {
		// Set current publishable values.
		this.appLocales.each(function(Locale) {
			this.$('.publish-option[value=' + Locale.id + '][data-type="live"]').attr("checked", Locale.get('publishable').live)
			this.$('.publish-option[value=' + Locale.id + '][data-type="test"]').attr("checked", Locale.get('publishable').test)
		}, this)

		$('.select2').select2()

		this.delegateEvents()
	},

	ready: function() {
		// Remove all selected Locales from the full Locale list.
		// this.appLocales.forEach(function(lang) {
		// 	this.allLocales.remove(this.appLocales.get(lang.id))
		// }, this)

		this.render()
	},

	addLocale: function() {
		// Get country id
		var countryId = Number($('.country-list').val())

		if (this.app.get('options').locales !== 'complex') {
			// Override locale/country id if the app is not modern-complex. For now this type of app should only have custom locales.
			countryId = this.getSocietyLocaleId()
		}

		// Get locale id
		var languageId = Number($('.language-list').val())

		// Does that combo already exist?
		var exists = false
		this.appLocales.forEach(function(locale) {
			if (!exists) {
				var countryExists = false
				if (locale.get('country')) {
					if (locale.get('country').id === countryId) {
						countryExists = true
					}
				} else if (countryId === '-') {
					// Country has not been set here... if countryId === '-' assume it exists because logic
					countryExists = true
				}
				var languageExists = false
				if (locale.get('language').id === languageId) {
					languageExists = true
				}

				exists = (countryExists && languageExists)
			}
		})
		if (exists) {
			// If so get out
			return
		}
		// Else Prefil as much content as possible and update (cannot set default as no id)
		var country = null
		var code = ''
		var language = this.allLanguages.get(languageId)
		if (countryId) {
			country = this.countries.get(countryId).toJSON()
			code = country.code.iso3 + '_' + language.get('code').iso3
		} else {
			code = language.get('code').iso3
		}
		var newLang = {
			country: country,
			language: language.toJSON(),
			code: code,
			publishable: {test: false, live: false}
		}
		this.appLocales.add(newLang)

		this.render()
	},

	saveLocales: function() {
		App.startLoad()

		// Update all publishable statuses
		this.appLocales.each(function(lang) {
			var live = this.$('.publish-option[value=' + lang.id + '][data-type="live"]').is(':checked')
			var test = this.$('.publish-option[value=' + lang.id + '][data-type="test"]').is(':checked')

			lang.set('publishable', {test: test, live: live})
		}, this)

		var data = this.appLocales.toJSON()
		var requests = []

		var newLocaleId = parseInt($('input[name=default]:checked').val(), 10)
		if (!isNaN(newLocaleId) && newLocaleId !== this.app.get('localeId')) {
			this.app.set('localeId', newLocaleId)
			requests.push(this.app.save())
		}

		requests.push(this.appLocales.save(null, {
			data: JSON.stringify(data)
		}))

		Promise.all(requests).then(this.saveComplete.bind(this), this.saveFailed.bind(this))
		return false
	},

	saveFailed: function() {
		App.stopLoad()
		this.render()

		// Update Locale list on app model.
		this.app.localeList.reset(this.appLocales.models)
	},

	saveComplete: function() {
		App.stopLoad()
		App.showToast($.t('hazards.disasters.saveSuccess'))
		this.render()

		// Update Locale list on app model.
		this.app.localeList.reset(this.appLocales.models)
	}
})
