var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./badge-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		data.systemId = App.system.id

		data.appId = this.appId
		return data
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('Badges')) {
			this.$('.tools').text('-')
		}
	}
})
