var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./search-terms-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId

		var page = this.model.get('page')

		if (page && page.destination && page.class === "InternalLink") {
			var pageId = App.getIdFromCacheUrl(this.model.get('page..destination'))
			this.page = options.app.pageList.get(pageId)
		}
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.appId

		if (this.page) {
			data.pageTitle = this.page.get('title')
		}

		return data
	}
})
