<div class="row">
	<div class="col-sm-6">
		<h3>{{t "agreement.details"}}</h3>
	</div>
	<div class="col-sm-6 actions-col">
		{{#if isGDPC}}{{t "agreement.title"}}{{/if}}
		{{#if isHazards}}{{t "agreement.hazardsTitle"}}{{/if}}
		{{#if isGDPC}}<a href="/GDPC-UAP-License-Agreement.pdf" target="_blank" data-bypass><button class="button">{{t "common.view"}}</button></a>{{/if}}
		{{#if isHazards}}<a href="/Hazard-app-agreement-CMS.pdf" target="_blank" data-bypass><button class="button">{{t "common.view"}}</button></a>{{/if}}
	</div>
</div>
<div class="row">
	<div class="col-xs-12">
		<table>
			<tr>
				<td>{{t "agreement.status"}}:</td>
				<td>{{#if signingUser}}{{t "agreement.signed"}}{{else}}{{t "agreement.notSigned"}}{{/if}}</td>
			</tr>
			<tr>
				<td>{{t "agreement.signedBy"}}:</td>
				<td>
					{{#if signingUser}}
						{{signingUser.firstName}} {{signingUser.lastName}}, {{signingUserExtra.jobTitle}}
					{{else}}-{{/if}}
				</td>
			</tr>
			<tr>
				<td>{{t "agreement.date"}}:</td>
				<td>{{#if timestamp}}
						{{formatDate timestamp}}
					{{else}}-{{/if}}
				</td>
			</tr>
			<tr>
				<td>{{t "agreement.nationalSociety"}}:</td>
				<td>{{society}}</td>
			</tr>
		</table>
	</div>
</div>

<div class="row">
	<div class="col-sm-12 actions-col">
		{{#if signingUser}}
			{{#if canReset}}
				<button class="button red reset-user-agreement">{{t "agreement.reset"}}</button>
			{{/if}}
		{{/if}}
	</div>
</div>

<div class="agreement-sign" style="display: none">
	<div class="row">
		<div class="col-xs-12">
			<form class="form-horizontal">
				<fieldset>
					<legend>{{t "agreement.signAgreement"}}</legend>

					<div class="form-group">
						<label class="control-label col-sm-2" for="name">{{t "agreement.fullName"}}</label>
						<div class="col-sm-10">
							<input id="name" name="name" type="text" class="form-control" disabled value="{{user.firstName}} {{user.lastName}}">
						</div>
					</div>

					<div class="form-group">
						<label class="control-label col-sm-2" for="title">{{t "users.organisation"}}</label>
						<div class="col-sm-10">
							<input id="title" name="title" type="text" class="form-control" disabled value="{{userExtra.jobTitle}}">
						</div>
					</div>

					<div class="form-group">
						<label class="control-label col-sm-2" for="society">{{t "agreement.nationalSociety"}}</label>
						<div class="col-sm-10">
							<input id="society" name="society" type="text" class="form-control" disabled value="{{society}}">
						</div>
					</div>

					<div class="form-group">
						<label class="control-label col-sm-2" for="email">{{t "common.email"}}</label>
						<div class="col-sm-10">
							<input id="email" name="email" type="text" class="form-control" disabled value="{{user.email}}">
						</div>
					</div>

					<div class="form-group">
						<label class="control-label col-sm-2" for="checkboxes"></label>
						<div class="col-sm-10">
							<label class="checkbox" for="agreement-checkbox">
								<input type="checkbox" name="checkboxes" id="agreement-checkbox">
								{{t "agreement.iAgree"}}&nbsp;
								{{#if isGDPC}}<a href="/GDPC-UAP-License-Agreement.pdf" target="_blank" data-bypass>{{t "agreement.title"}}</a>{{/if}}
								{{#if isHazards}}<a href="/Hazard-app-agreement-CMS.pdf" target="_blank" data-bypass>{{t "agreement.hazardsTitle"}}</a>{{/if}}
							</label>
						</div>
					</div>

					<div class="form-group">
						<div class="col-sm-10 col-sm-offset-2">
							<button type="button" class="button positive sign-button">{{t "agreement.sign"}}</button>
						</div>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</div>
