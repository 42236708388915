<table>
	<thead>
		<tr>
			<th style="max-width:100px">{{t "editor.inspector.properties.badge.title"}}</th>
			<th>{{t "journey.title"}}</th>
			<th>{{t "common.page"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
