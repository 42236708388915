var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view'),
	LevelListItemView = require('./level-list-item-view'),
	StormQL = require('models/stormql')

var SwimLevelsView = ListView.extend({
	template: require('./level-list-view-template'),
	list: require('./level-list'),
	listItem: LevelListItemView,
	afterInitialize: function() {
		// Shit to do, get badges...
		// LevelListItemView.badgeList = badgeList
	}
})

/**
 * Exports {@link SwimLevelsView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends SwimLevelsView.prototype */{
	/** @override */
	className: 'journies',
	/** @override */
	template: require('./level-view-template'),

	/**
	 * @constructs SwimLevelsView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		this.app = App.appList.get(options.appId)
		// Fetch enabled page list for this app.
		var pageFetch = this.app.pageList.fetch()

		// Fetch badges
		this.badgeList = new StormQL(null, {app: this.app})
		var badgeFetch = this.badgeList.fetch({data: {class: 'Badge'}})

		// Fetch the page list if there's a link selector in this class.
		Promise.all([pageFetch, badgeFetch]).then(this.loadViews.bind(this))
	},

	loadViews: function() {
		this.views = {
			list: new SwimLevelsView({
				app: this.app,
				listItemOptions: {
					badgeList: this.badgeList
				}
			})
		}
		this.render()
	},

	/** @override */
	getPageTitle: function() {
		return $.t('swim.pageTitle')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	}
})
