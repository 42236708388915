<div class="row">
	<div class="col-xs-6">
		<h3 data-i18n="facility.title"></h3>
		<div class="form-group">
			<label>{{t "facility.filter.title"}}</label>
			<select class="facility-filter-list form-control">
				<option value="-"> {{t "facility.filter.select"}}
				</option>
				{{#each types}}
					<option value="{{this}}" {{#ifEqual ../type this}} selected {{/ifEqual}}>
						{{this}}
					</option>
				{{/each}}
			</select>
		</div>

	</div>
	<div class="col-xs-6 actions-col">
		<a href="/apps/{{appId}}/facilities/edit/new"><button class="button add">{{t "common.add"}}</button></a>
	</div>

</div>
<div class="app-error" id="noItems" style="display:none;">{{t "common.noitems"}}</div>

<table class="header-float">
	<thead>
		<tr>
			<th>{{t "facility.edit.name"}}</th>
			<th>{{t "facility.edit.type"}}</th>
			<th>{{t "facility.edit.url"}}</th>
			<th>{{t "facility.edit.facilityid"}}</th>
			<th>{{t "facility.edit.division"}}</th>
			<th>{{t "facility.edit.region"}}</th>
			<th>{{t "facility.edit.location"}}</th>
			<th>{{t "facility.edit.address.title"}}</th>
			<th>{{t "facility.edit.contact"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
<button class="button load-more" style="margin-bottom:10px;">Load more</button>
