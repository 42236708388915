var	HazardsSectionView = require('../hazards-section-view'),
	DisasterCategoryView = require('./disaster-category-view'),
	EventsConfig = require('./eventsConfig'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	utils = require('lib/utils')

module.exports = HazardsSectionView.extend({
	template: require('./disaster-view-template'),

	tutorial: 'disasters',

	events: {
		'click .save-categories-button': 'save',
		'click .add-category': 'addCategory',
		'click .update-category-icon': 'updateCategoryMetaIcon',
		'change .update-page': 'updateCategoryMetaSrc',
		'input #language-options': 'updateLanguage'
	},

	initialize: function(options) {
		this.app = App.appList.get(options.appId)

		var requests = []
		this.eventsConfig = new EventsConfig({app: this.app, retrieveCategories: true})
		requests.push(this.eventsConfig.fetch())

		this.pageList = this.app.pageList
		requests.push(this.pageList.fetch())

		this.localeList = Storm.app.localeList
		requests.push(this.localeList.fetch())

		Promise.all(requests).then(function() {
			this.eventsConfig.parseMeta()
			this.events = this.eventsConfig.getEventsWithFeeds()
			this.categories = this.eventsConfig.getCategories()
			this.renderLanguages()
			this.getPageList()
			this.renderCategories()
			this.renderEvents()
			// Listener to re-render when categories has changed
			this.listenTo(this.categories, 'change:collection', function() {
				this.renderCategories()
			}, this)
		}.bind(this))
	},

	getPageList: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		this.pageSelectOptions = pageListDropdownOptions
	},

	renderLanguages: function() {
		var languageSelectOptions = this.localeList.map(function(locale) {
			var name = locale.get('language').name.native + ' (' + locale.get('code').toUpperCase() + ')'
			return '<option value="' + locale.get('code') + '">' + name + '</option>'
		}).join('')
		$('#language-options').html(languageSelectOptions)
		Storm.view.language = $('#language-options').val()
	},

	updateLanguage: function() {
		Storm.view.language = $('#language-options').val()
		this.renderCategories()
	},

	getPageTitle: function() {
		return $.t('hazards.disasters.title')
	},

	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	afterRender: function() {
		// Hide features based on permissions.
		if (!App.acl.hasWritePermission('Hazards', 'Disasters')) {
			this.$('.save-categories-button').remove()
		}
		$('.app-select').remove()
		App.stopLoad()
	},

	renderEvents: function() {
		if (this.events.length) {
			this.events.forEach(function(theEvent) {
				if (theEvent.get('enabled')) {
					$('.disasters-events-list').append(this.getEventHTML(theEvent))
				}
			}.bind(this))
		}
	},

	renderCategories: function() {
		App.startLoad()
		this.categories.sort() // Sort by order
		$('.disasters-categories-list').empty()
		if (this.categories.length) {
			this.categories.forEach(function(theCategory) {
				if (!theCategory.needsDeleting) {
					this.renderCategory(theCategory)
				}
			}.bind(this))
		} else {
			this.renderHelp()
		}
		App.stopLoad()
	},

	renderHelp: function() {
		$('.disasters-categories-list').append($('<h2 class="text-centre help-link-text add-category"> Add a new category </h2>'))
	},

	renderCategory: function(category) {
		if (!this.categoryViews) {
			this.categoryViews = []
		}
		this.categoryViews.forEach(function(catView) {
			if (catView.model === category) {
				if (!category.get('meta')) {
					category.set('meta', this.getCategoryMetaBySelection(category.selectorId))
				}
			}
		}.bind(this))

		var	view = new DisasterCategoryView({app: this.app, model: category, pages: this.pageSelectOptions})

		this.categoryViews.push(view)
		var el = view.render().el
		// Does the category already exist?
		if ($('.disaster-box[data-selection-id=' + category.selectorId + ']').length) {
			// If so just replace it...
			$('.disaster-box[data-selection-id=' + category.selectorId + ']').replaceWith(el)
		} else {
			// Else just append it..
			$('.disasters-categories-list').append(el)
			this.listenTo(view.model, 'change:events', function() {
				this.renderCategoryEvents(view)
			}, this)
		}

		view.setPageSelection()

		// Render each event inside the category
		this.renderCategoryEvents(view)

		category.selectorId = view.selectorId
	},

	renderCategoryEvents: function(categoryView) {
		var model = categoryView.model
		var selectorId = categoryView.selectorId
		$('.disaster-event-box[data-selection-id=' + selectorId + ']').empty()
		this.renderEventPicker(categoryView)

		model.get('events').forEach(function(theEventId) {
			var theEvent = this.getEventById(theEventId)
			$('.disaster-event-box[data-selection-id=' + selectorId + ']').append(this.getEventHTML(theEvent))
		}.bind(this))
		$(".event-dropdown").select2({
			templateResult: this.formatEventDropdown
		})
		$('.update-page').select2()
	},

	renderEventPicker: function(categoryView) {
		var html = "<select class='event-dropdown' data-selection-id='" + categoryView.selectorId + "'>"
		this.events.forEach(function(theEvent) {
			if (theEvent.get('enabled')) {
				var backgroundURL = ""
				if (theEvent.get('meta')) {
					backgroundURL = utils.getImagePreviewUrl(theEvent.get('meta').icon)
				}

				if (!categoryView.model.get('events')) {
					categoryView.model.set('events', [])
				}
				if (!categoryView.model.get('code')) {
					categoryView.model.set('code', '')
				}
				var disabled = categoryView.model.get('events').indexOf(theEvent.get('id')) === -1 ? '' : 'disabled'

				var eventName = ''

				if (!theEvent.get('friendlyName')) {
					eventName = theEvent.get('name')
				} else {
					eventName = utils.getViewLocaleText(theEvent.get('friendlyName'))
				}

				html += "<option value='" + theEvent.get('id') + "' thumbnail='" + backgroundURL + "' " + disabled + ">" + eventName + "</option>"
			}
		})
		html += "</select><button class='button category-add-new-event outlined' data-selection-id='" + categoryView.selectorId + "'> " + $.t('common.add') + " </button>"
		$('.disaster-event-box[data-selection-id=' + categoryView.selectorId + ']').html($(html))
	},

	getEventById: function(eventId) {
		return this.events.get(eventId)
	},

	getEventHTML: function(theEvent) {
		var backgroundURL = ""
		if (theEvent.get('meta')) {
			backgroundURL = utils.getImagePreviewUrl(theEvent.get('meta').icon)
		}

		var eventName = ''

		if (!theEvent.get('friendlyName')) {
			eventName = theEvent.get('name')
		} else {
			eventName = utils.getViewLocaleText(theEvent.get('friendlyName'))
		}

		return $("<div class=\"disaster-event-item\">" +
					"<div class=\"disaster-event-item-icon\" data-event-id='" + theEvent.get('id') + "' style=\"background-image: url('" + backgroundURL + "');\"></div>" +
					"<span>" + eventName + "</span>" +
					"<span class=\"disaster-event-item-remove\" data-eventid=\"" + theEvent.get('id') + "\">&times;</span>" +
					"</div>")
	},

	formatEventDropdown: function(state) {
		if (!state.id) {
			return state.text
		}
		var $state = $(
			'<span><img class="event-dropdown-thumb" src="' + $(state.element).attr('thumbnail') + '" /> ' + state.text + '</span>'
		)
		return $state
	},

	addCategory: function() {
		var category = StandaloneStormObject.fromClassName('Hazards_Category')
		category.set('order', this.categories.length)
		this.categories.add(category)
		this.renderCategory(category)
		$('.help-link-text').remove()
	},

	getCategoryMeta: function(categoryId, selectionId) {
		var metaCat = this.eventsConfig.metaHazCat

		// Is there a valid meta object?
		var categoryMeta = metaCat.findWhere({identifier: categoryId})
		if (!categoryMeta) {
			// Search by selection id
			categoryMeta = this.getCategoryMetaBySelection(selectionId)
		}
		// No? Create one...
		if (!categoryMeta) {
			categoryMeta = StandaloneStormObject.fromClassName('MetaHazardsCategory')
			categoryMeta.set('identifier', categoryId)
			categoryMeta.selectionId = selectionId // We set a selection id as if the category object is new it may have not been saved yet and will need saving! :)
			metaCat.add(categoryMeta)
		}

		// Return it...
		return categoryMeta
	},

	getCategoryMetaBySelection: function(selectionId) {
		var categoryMeta
		this.eventsConfig.metaHazCat.forEach(function(metaCat) {
			if (metaCat.selectionId === selectionId) {
				categoryMeta = metaCat
			}
		})
		return categoryMeta
	},

	updateCategoryMetaIcon: function(e) {
		// Get event id...
		var selectionId = $(e.currentTarget).data('selection-id')
		var id = parseInt($(e.currentTarget).data('category-id'), 10)

		var categoryMeta = this.getCategoryMeta(id, selectionId)
		this.chooseImage(categoryMeta, $(e.currentTarget))
		this.eventsConfig.meta.needsSaving = true
	},

	updateCategoryMetaSrc: function(e) {
		// Get event id...
		var selectionId = $(e.currentTarget).data('selection-id')
		var id = parseInt($(e.currentTarget).data('category-id'), 10)

		var val = $(e.currentTarget).val()
		var categoryMeta = this.getCategoryMeta(id, selectionId)
		if (val !== "-") {
			categoryMeta.set('src', val)
			this.eventsConfig.meta.needsSaving = true
		}
	},

	getCategoriesAndRender: function() {
		this.categories = this.eventsConfig.getCategories()
		this.renderCategories()
	},

	chooseImage: function(categoryMeta, updateTarget) {
		var icon = categoryMeta.get('icon')

		this.listenTo(categoryMeta, 'change:icon', function() {
			this.updateIcon(categoryMeta, updateTarget)
		}, this)

		this.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: icon,
			mediaType: MediaLibrary.types.ICON
		})

		this.mediaLibrary.on('change', function() {
			categoryMeta.trigger('change:icon', icon)
		}, this)

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	updateIcon: function(categoryMeta, updateTarget) {
		updateTarget.attr('style', 'background-image: url("' + utils.getImagePreviewUrl(categoryMeta.get('icon')) + '"); background-color: #fff;')
	},

	save: function() {
		App.startLoad()
		var requests = [],
			appId = this.app.id
		var self = this

		// Save each changed disaster
		this.categories.forEach(function(category) {
			category.unset('selectorId')
			if (category.isNew()) {
				requests.push(category.save(null, {appId: appId}))
			} else if (category.needsSaving) {
				category.requestLock(function() {
					category.save().then(function() {
						category.requestUnlock()
					})
				})
			} else if (category.needsDeleting) {
				category.requestLock(function() {
					category.destroy()
				})
			}
			delete category.needsSaving
		})

		Promise.all(requests).then(function() {
			var meta = self.eventsConfig.meta.at(0)
			if (!meta) {
				App.stopLoad()
				return
			}
			// Go through each category meta and see if the identifiers need saving...
			if (self.eventsConfig.meta.needsSaving === true) {
				self.eventsConfig.metaHazCat.forEach(function(catMeta) {
					var category = self.getCategoryBySelectionId(catMeta.selectionId)
					if (category) {
						if (catMeta.get('identifier') !== category.get('id')) {
							catMeta.set('identifier', category.get('id'))
						}
					}
					App.stopLoad()
				})
				self.saveMeta()
			} else {
				App.stopLoad()
			}
		})
	},

	saveMeta: function() {
		var meta = this.eventsConfig.meta.at(0),
			appId = this.app.id
		var self = this
		if (meta && !self.savingMeta) {
			self.savingMeta = true
			if (meta.isNew()) {
				meta.save(null, {appId: appId}).then(function() {
					delete self.savingMeta
					App.stopLoad()
				})
			} else {
				meta.requestLock(function() {
					// Do any children need deleting?
					meta.get('categories').forEach(function(catMeta) {
						delete catMeta.collection // Delete reference to parent object, screws up locking for object
						if (catMeta.needsDeleting) {
							catMeta.requestLock(function() {
								catMeta.destroy()
							})
						}
					})
					meta.save().then(function() {
						delete self.savingMeta
						meta.requestUnlock()
					})
				})
			}
			delete self.eventsConfig.meta.needsSaving
		} else {
			App.stopLoad()
		}
	},

	getCategoryBySelectionId: function(selectionId) {
		return this.categories.findWhere({selectorId: selectionId})
	}
})
