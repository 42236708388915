const TermListItemView = require('./term-list-item-view')

/**
 * Exports {@link TermListView}.
 * @module
 */
module.exports = Backbone.View.extend({
	/** @override */
	template: require('./terms-list-view-template'),

	/** @override */
	events: {
		'click .add-term': 'addTerm'
	},

	/**
	 * @constructs TermListView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		this.locales = options.locales

		this.model.get('terms').on('destroy', this.render, this)
	},

	/** @override */
	getRenderData: function() {
		return {}
	},

	/** @override */
	afterRender: function() {
		var list = this.$el.find('.list-items')
		this.model.get('terms').forEach(function(term, index) {
			var view = new TermListItemView({model: term, locales: this.locales, index: index})
			list.append(view.render().el)
		}.bind(this))

		if (this.model.get('terms').length === 0) {
			$('#noItems').show()
			$('table').hide()
		} else {
			$('#noItems').hide()
			$('table').show()
		}
	},

	addTerm: function() {
		var term = App.getClassStructure('Text', this.model.id)
		this.model.get('terms').add(term)
		this.model.trigger('change')
		this.render()
	}
})
