var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	utils = require('lib/utils'),
	LinkSelector = require('editor/inspector/link-selector')

/**
 * Exports {@link BadgeEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends RichStoriesEditView.prototype */{
	/** @override */
	className: 'rich-stories',
	/** @override */
	template: require('./rich-stories-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage',
		'click input[type=checkbox]': 'enableDisableFields'
	},

	/**
	 * @constructs BadgeEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var localePromise = this.app_.localeList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('StormQLRichStory')
		this.listenTo(this.model, 'change:richstory', this.updateRichStories, this)

		var modelPromise = Promise.resolve()

		if (options.id === 'new') {
			// Force to be Internal Link for page attribute
			this.model.set('storyPage..class', 'InternalLink')
		}

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, localePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.app_.id
		data.locales = this.app_.localeList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()
		this.renderLinkPicker()
		// Show current input values
		_.each(this.model.get('title..content'), function(val, key) {
			this.$('.title-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('subTitle..content'), function(val, key) {
			this.$('.subtitle-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('story..content'), function(val, key) {
			this.$('.story-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('fullStoryUrl..content'), function(val, key) {
			this.$('.fullStoryUrl-input[data-code=' + key + ']').val(val)
		})

		this.$('.videoUrl-input').val(this.model.get('videoUrl'))
		this.$('.videoDuration-input').val(this.model.get('videoDuration'))
	},

	renderLinkPicker: function() {
		var lockToken = null
		if (this.model.lock) {
			lockToken = this.model.lock.get('token')
			this.linkPicker = new LinkSelector({
				link: this.model.get('storyPage'),
				titleDisabled: true,
				pageList: Storm.app.pageList,
				appId: this.appId,
				lockToken: lockToken,
				hideType: true
			})
			// Show link picker
			$('.link-picker').html(this.linkPicker.render().el)
		}
	},

	updateRichStories: function() {
		// Support legacy image format.
		var image = this.model.get('image')

		if (!image.src) {
			image = image.toJSON()
		}

		this.$('img').attr('src', utils.getImagePreviewUrl(image))
	},

	chooseImage: function() {
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: this.model.get('image'),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:richstory', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/rich-stories', {trigger: true})
		}.bind(this))

		// Get all new input values
		var titles = {},
			subtitles = {},
			stories = {},
			storyUrls = {},
			videoUrl = this.$('.videoUrl-input').val(),
			videoDuration = this.$('.videoDuration-input').val(),
			category = this.$('.category-input').val(),
			featured = this.$('.featured-input').prop('checked')

		this.$('.title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				titles[code] = value
			}
		})

		this.$('.subtitle-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			subtitles[code] = value
		})

		this.$('.story-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			stories[code] = value
		})

		this.$('.fullStoryUrl-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			storyUrls[code] = value
		})

		this.model.set('title..content', titles)
		this.model.set('subTitle..content', subtitles)
		this.model.set('videoUrl..content', storyUrls)

		this.model.set('story..content', stories)
		this.model.set('fullStoryUrl..content', storyUrls)
		this.model.set('videoUrl', videoUrl)
		this.model.set('videoDuration', videoDuration)
		this.model.set('category', category)
		this.model.set('featured', featured)

		this.model.save(null, {appId: this.app_.id})
		return false
	}
})
