module.exports = Backbone.View.extend({
	template: require('./inspector-product-selector-template.hbs'),

	events: {
		'change .product-selection': 'productValueChange',
		'click .remove-product': 'removeProduct'
	},

	initialize: function(options) {
		this.views = {}

		this.noSave = options.noSave || false
		this.productList = options.productList
	},

	ready: function() {
		if (!this.model.get('products')) {
			this.model.set('products', [])
		}
		this.render()
	},

	removeProduct: function(e) {
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				App.startLoad()
				var key = $(e.currentTarget).data('key')
				this.model.get('products').at(key).destroy().then(function() {
					App.stopLoad()
					this.render()
					this.model.trigger('change:products')
				}.bind(this))
			}
		}.bind(this))
	},

	getRenderData: function() {
		var data = {
			data: this.model.toJSON(),
			productList: this.productList.toJSON(),
			viewCid: this.cid
		}
		return data
	},

	productValueChange: function(e) {
		var key = $(e.currentTarget).data('key'),
			value = $(e.currentTarget).val()
		var activeProducts = this.model.get('products')

		if (value !== '-') {
			activeProducts.at(key).set('value', value)
		}

		this.model.trigger('change:products')
		this.model.needsSaving = true
	}
})
