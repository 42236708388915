var TermsListView = require('./terms/terms-list-view'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view'),
	LinkSelector = require('editor/inspector/link-selector')

/**
 * Exports {@link BadgeEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends SearchTermsEditView.prototype */{
	/** @override */
	className: 'search-terms',
	/** @override */
	template: require('./search-terms-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage',
		'click input[type=checkbox]': 'enableDisableFields'
	},

	/**
	 * @constructs BadgeEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var localePromise = this.app_.localeList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('PageSearchTerms')

		if (options.id === 'new') {
			// Force to be Internal Link for page attribute
			this.model.set('page..class', 'InternalLink')
		}

		var modelPromise = Promise.resolve()

		this.termsToRemove = []

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, localePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		// Storm collection can't handle an array of text objects, it provides it as an empty collection
		// even if the backend returns a non empty array. This is a workaround until we have time to figure
		// out why it emptys the collection.
		if (this.model && this.model._response && this.model._response.terms) {
			this.model.set('terms', this.model._response.terms)
		}

		this.model.get('terms').on('destroy', function(model) {
			if (model.id) {
				this.termsToRemove.push(model)
			}
		}, this)

		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.app_.id
		data.locales = this.app_.localeList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.views.terms = new TermsListView({model: this.model, locales: this.app_.localeList})
		this.delegateEvents()
		this.renderLinkPicker()
	},

	renderLinkPicker: function() {
		var lockToken = null
		if (this.model.lock) {
			lockToken = this.model.lock.get('token')
			this.linkPicker = new LinkSelector({
				link: this.model.get('page'),
				titleDisabled: true,
				pageList: Storm.app.pageList,
				appId: this.appId,
				lockToken: lockToken,
				hideType: true,
				tagFilters: ["Emergency", "Learn"]
			})
			// Show link picker
			$('.link-picker').html(this.linkPicker.render().el)
		}
	},

	save: function(e) {
		e.preventDefault()
		App.startLoad()
		var self = this

		var promises = []

		this.termsToRemove.forEach(function(term) {
			term.lock = self.model.lock
			promises.push(term.destroy())
		})

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/search-terms', {trigger: true})
		}.bind(this))

		Promise.all(promises).then(function() {
			this.termsToRemove = []
			this.model.save(null, {appId: this.app_.id})
		}.bind(this))
		return false
	}
})
