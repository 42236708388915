
var logicParseHelper = require('./helpers/logic-parser'),
	LogicBuilder = require('./section-logic-view-builder')

module.exports = Backbone.View.extend({
	template: require('./section-logic-view-template'),

	/** @override */
	events: {
		'input .logic': 'updateLogic',
		'click .add-new-child': 'addChild'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.selectorId = options.selectorId
		this.model = options.model
		this.language = options.language
		this.isModuleLogic = options.isModuleLogic
		this.newTerms = []
		if (this.isModuleLogic) {
			this.parent = options.parent
		} else {
			this.parent = options.parent.parent
		}
		this.newTerm = {
			operator: "="
		} // Bool to identify if we have started creating a new term or not.

		this.on("update:module", this.updateModule, this)
		this.on("update:block", this.updateBlock, this)
		this.on("update:operator", this.updateOperator, this)
		this.on("update:value", this.updateValue, this)
		this.on("remove:parent", this.removeParent, this)
		this.on("force:render", this.forceRender, this)
	},

	getRenderData: function() {
		return {
			model: this.model.toJSON(),
			selectorId: this.selectorId
		}
	},

	parseModuleIdentifiers: function() {
		this.parent.units.forEach(function(module) {
			module.set('identifierNoVersion', this.getIdentifierWithoutVersion(module.get('identifier')))
		}.bind(this))
	},

	afterRender: function() {
		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}

		this.parseModuleIdentifiers()
		this.parsed = logicParseHelper.parseLogic(this.model.get('logic'), this.parent.units)
		this.parent.units.sort()

		var logicBuilder = new LogicBuilder({
			parsed: this.parsed,
			modules: this.parent.units.toJSON(),
			parent: this,
			selectorId: this.selectorId
		})

		this.$('.js-logic-builder').html(logicBuilder.render().el)
	},

	updateLogic: function(e) {
		var value = $(e.currentTarget).val()
		this.model.set('logic', value)
		this.model.trigger('change')
	},

	findParentCb: function(parent, parentId, context) {
		if (parent.id === parentId) {
			return true
		}

		for (var i = 0; i < parent.children.length; i++) {
			// If not on parent top level, loop through any children recursively.
			var parentFound = context.findParentCb(parent.children[i], parentId, context)

			if (parentFound) {
				return true
			}
		}

		return false
	},

	getParent: function(options) {
		if (options.parentId === this.parsed.id) {
			return this.parsed
		}

		var context = this
		var parent = this.parsed.children.find(function(parent) {
			return context.findParentCb(parent, options.parentId, context)
		})
		return parent
	},

	getTerm: function(options) {
		var parent = this.getParent(options)

		if (parent) {
			if (options.termId !== undefined) {
				// Find the term
				var term = parent.terms.find(function(term) {
					return term.id === options.termId
				})

				return term
			}
		}
	},

	updateModule: function(options) {
		var term = this.getTerm(options)
		if (term && options.value !== '-') {
			term.module = options.value
			term.blocks = logicParseHelper.getBlocks(options.value.trim(), this.parent.units)
		}

		this.updateLogicString()
		this.render()
	},

	updateBlock: function(options) {
		var term = this.getTerm(options)
		if (term && options.value !== '-') {
			term.block = options.value
			term.valueType = options.type
		}
		this.updateLogicString()
		this.render()
	},

	updateOperator: function(options) {
		var term = this.getTerm(options)
		if (term && options.value !== '-') {
			term.operator = options.value
		}
		this.updateLogicString()
	},

	updateValue: function(options) {
		var term = this.getTerm(options)
		var value = options.value

		if (term && options.type === 'number') {
			value = parseInt(value, 10)
		}

		if (options.value !== '-') {
			term.value = options.value
		}
		this.updateLogicString()
	},

	removeParent: function(parentId) {
		this.findAndDeleteParent(parentId, this.parsed, this)
		this.forceRender()
	},

	findAndDeleteParent: function(parentId, parsed, context) {
		for (var i = 0; i < parsed.children.length; i++) {
			if (parsed.children[i].id === parentId) {
				parsed.children.splice(i, 1)
			} else {
				context.findAndDeleteParent(parentId, parsed.children[i], context)
			}
		}
	},

	forceRender: function() {
		this.updateLogicString()
		this.render()
	},

	addChild: function() {
		var newChild = {
			prefix: this.parsed.prefix === 'AND' ? 'OR' : 'AND',
			terms: [],
			children: []
		}
		this.parsed.children.push(newChild)
		this.forceRender()
	},

	formatLogicString: function() {
		var logicStr = this.parsed.prefix + '('

		if (this.parsed.prefix !== null) {
			logicStr = this.parsed.prefix + '('
			this.parsed.terms.forEach(function(term, index) {
				var valueCopy = JSON.parse(JSON.stringify(term.value))
				if (term.valueType === 'text') {
					// Surround with single quotes
					valueCopy = "'" + valueCopy + "'"
				}
				logicStr += "TERM('" + term.module + "." + term.block + "' " + term.operator + " " + valueCopy + ")"
				if (index < this.parsed.terms.length - 1) {
					logicStr += ", "
				}
			}.bind(this))

			// Loop through any children
			this.parsed.children.forEach(function(term, childIndex) {
				// wrap any children terms with the correct prefix
				logicStr += ',' + this.parsed.children[childIndex].prefix + '('

				// loop through the terms for each of the children
				this.parsed.children[childIndex].terms.forEach(function(term, index) {
					var valueCopy = JSON.parse(JSON.stringify(term.value))
					if (term.valueType === 'text') {
						// Surround with single quotes
						valueCopy = "'" + valueCopy + "'"
					}
					logicStr += "TERM('" + term.module + "." + term.block + "' " + term.operator + " " + valueCopy + ")"
					if (index < this.parsed.children[childIndex].terms.length - 1) {
						logicStr += ", "
					}
				}.bind(this))
				logicStr += ')' // end children prefix
			}.bind(this))

			logicStr += ')' // end parent prefix
		} else {
			logicStr = null
		}

		return logicStr
	},

	// Format this.parsed.terms into something like: `OR(TERM('1.BCP.staffdetails' = 1), TERM('1.BCP.staffemergencycontact' = 1), TERM('1.BCP.updateprocess' != 1))`
	updateLogicString: function() {
		var logicStr = this.formatLogicString()
		this.$(".logic").val(logicStr)

		this.model.set('logic', logicStr)
		this.model.trigger('change')
	},

	getIdentifierWithoutVersion: function(identifier) {
		var splitIdentifier = identifier.split('.')
		splitIdentifier.pop()
		return splitIdentifier.join('.')
	}
})
