<div class="col-sm-6 col-lg-4 col-lg-offset-2">
			<legend data-i18n="appSetup.publishableLanguages"></legend>
			{{#if appLocales}}
			<table class="col-xs-12">
				<thead>
					<th>
						{{t "appSetup.locale"}}
					</th>
					<th>
						{{t "appSetup.publish"}}
					</th>
					<th>
						{{t "appSetup.default"}}
					</th>
				</thead>
				<tbody>
					{{#each appLocales}}
					<tr>
						<td style="text-align: right;">
							{{#if country}}{{country.code.iso3}} - {{/if}} {{language.name.native}}
						</td>
						<td style="text-align: center;">
							<div data-id="{{id}}">
								<label class="checkbox-inline">
									<input type="checkbox" class="publish-option" value="{{id}}" data-type="test" {{#unless id}} disabled {{/unless}}> {{t "appSetup.dev"}}
								</label>
								<label class="checkbox-inline">
									<input type="checkbox" class="publish-option" value="{{id}}" data-type="live" {{#unless id}} disabled {{/unless}}> {{t "appSetup.live"}}
								</label>
							</div>
						</td>
						<td style="text-align: center;">
							<input type="radio" class="default-option" name="default" value="{{id}}" data-type="default" {{#ifEqual id ../defaultLocale}}checked="checked"{{/ifEqual}} {{#unless id}} disabled {{/unless}}>
						</td>
					</tr>
					{{/each}}
				</tbody>
			</table>
			{{/if}}

			{{#if allLanguages}}
				<div class="row">
					<div class="form col-xs-12">
						<div class="form-group">
							<label>Country</label>
							<select class="form-control select2 country-list" {{#ifNotEqual app.options.locales 'complex'}}disabled='true'{{/ifNotEqual}} style="width: 100%;">
								<option value="-">
									No country
								</option>
							{{#each countries}}
								<option value="{{id}}" {{#ifEqual id ../societyLocaleId}}selected="selected"{{/ifEqual}}>{{name}} ({{toUpperCase code.iso3}})</option>
							{{/each}}
							</select>
						</div>
						<div class="form-group">
							<label>Language</label>
							<select class="form-control select2 language-list" style="width: 100%;">
							{{#each allLanguages}}
								<option value="{{id}}">{{name.native}} ({{toUpperCase code.iso3}})</option>
							{{/each}}
							</select>

							<button type="button" class="button add-locale" style="margin-top:5px;">{{t "common.add"}}</button>
						</div>
						<hr />
					</div>
				</div>
			{{/if}}

			<div class="row">
				<div class="col-xs-12">
					<button class="button green save-locales">Save locales</button>
				</div>
			</div>

			<p class="help-block">{{t "appSetup.addMoreLanguages"}}</p>
</div>
