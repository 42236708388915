module.exports = Backbone.Collection.extend({
	comparator: 'name',

	url: function() {
		if (this.appId) {
			return App.apiRoot + 'apps/' + this.appId + '/locales'
		}

		return App.apiRoot + 'locales'
	},

	initialize: function(models, options) {
		options = options || {}
		this.appId = options.appId
	}
})
