<div class="blood-sponsorship-preview">
	{{#ifExists sponsor.feature}}
		<p class="blood-sponsorship-preview__sponsored-content">
			{{getLocaleText sponsor.header}}
		</p>
		<p class="blood-sponsorship-preview__header">
			{{getLocaleText sponsor.text}}
		</p>
		<p class="blood-sponsorship-preview__subtitle">
			{{getLocaleText sponsor.description}}
		</p>
		<img class="blood-sponsorship-preview__image" src="{{getPreviewUrl sponsor.feature}}" alt=""/>

		<button class="js-button button blood-sponsorship-preview__link " href="{{sponsor.link.destination}}" target="_blank">
			{{getLocaleText sponsor.link.title}}
		</button>
	{{/ifExists}}
	<div class="text-center">
		{{#ifExists sponsor.type}}
			{{#ifEqual sponsor.type 'tag'}}
				Sponsorship tag: {{sponsor.value}}
			{{/ifEqual}}
		{{/ifExists}}
	</div>
</div>
