var EditorSectionView = require('./editor-section-view'),
	StormObject       = require('./storm-object'),
	Page              = require('./page-list/page'),
	PageListView      = require('./page-list/page-list-view'),
	CanvasView        = require('./canvas/canvas-view'),
	TemplatesView     = require('./templates/templates-view'),
	User              = require('users/user'),
	TokenRenewView    = require('./token-renew-view'),
	SaveStateView     = require('./save-state-view'),
	ViewPicker        = require('./view-picker'),
	pageTags          = require('editor/page-tags'),
	APICompat         = require('lib/api-compat')

/** @type {StormGlobals} */
var globals = require('globals')

var EditorActionsView = Backbone.View.extend(/** @lends EditorActionsView.prototype */{
	/** @override */
	template: require('./editor-actions-view-template'),

	/** @override */
	events: {
		'click .edit-button': 'editButtonClick',
		'click .finish-button': 'finishButtonClick',
		'change .language-select': 'languageSelectChange'
	},

	/**
	 * @constructs EditorActionsView
	 * @extends Backbone.View
	 * @override
	 * @classdesc Defines an actions view for {@link EditorView}. Contains the
	 *     "Edit"/"Finish" buttons for (un)locking pages, and the language
	 *     dropdown.
	 */
	initialize: function(options) {
		options = options || {}

		if (!options.localeList) {
			throw new Error('No locale list specified')
		}

		if (options.appId === undefined) {
			throw new Error('No app ID specified')
		}

		this.localeList = options.localeList
		this.appId = options.appId
		this.language = options.language

		this.listenTo(this.localeList, 'add remove reset', this.render, this)
	},

	/** @override */
	getRenderData: function() {
		var languageSelectOptions = this.localeList.map(function(locale) {
			var name = locale.get('language').name.native + ' (' + locale.get('code').toUpperCase() + ')'
			return '<option value="' + locale.get('code') + '">' + name + '</option>'
		}).join('')

		return {
			languageSelectOptions: languageSelectOptions,
			isEditing: this.editState,
			editButtonVisible: this.editButtonVisible
		}
	},

	/** @override */
	render: function() {
		Backbone.View.prototype.render.apply(this, arguments)
		this.$('.app-select').val(this.appId)
		this.$('.language-select').val(this.language)

		if (this.localeList.length < 2) {
			this.$('.language-section').hide()
		}

		return this
	},

	editButtonClick: function() {
		this.setEditState(true)
	},

	finishButtonClick: function() {
		this.setEditState(false)
	},

	languageSelectChange: function() {
		this.language = this.$('.language-select').val()
		this.trigger('languageChange', this.language)
	},

	setEditState: function(isEditing) {
		if (this.editState === isEditing) {
			return
		}

		this.editState = isEditing

		if (isEditing) {
			this.editButtonVisible = true
		}

		this.trigger('editStateChange', isEditing)
		this.render()
	},

	setLanguage: function(language) {
		this.language = language
		this.trigger('languageChange', language)
		this.render()
	},

	hideEditButton: function() {
		this.editButtonVisible = false
		this.render()
	},

	showEditButton: function() {
		this.editButtonVisible = true
		this.render()
	}
})

/**
 * Exports {@link EditorView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends EditorView.prototype */{
	/** @override */
	className: 'editor',
	/** @override */
	template: require('./editor-view-template'),

	/** @override */
	events: {
		'click .page-list .add-page-button': 'addPageButtonClick',
		'click .cancel-new-page-button': 'cancelNewPage',
		'click .save-new-page-button': 'saveNewPageButtonClick',
		'click .paste-page-button': 'pastePageButtonClick',
		'click .new-from-template-button': 'templates',
		'change .new-page-type': 'newPageTypeChange',
		'click .js-hide-page-from-template-form': 'showNewPageForm'
	},

	/**
	 * @constructs EditorView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		options = options || {}

		if (options.appId === undefined) {
			throw new Error('No app ID specified')
		}

		/** @private {AppList} */
		this.appList_ = globals.getAppList()

		this.listViews = []

		// Set current app and fetch class list.
		this.appChange(options.appId)

		this.actionsView = new EditorActionsView({
			appId: options.appId,
			localeList: this.app.localeList
		})

		this.listenTo(this.actionsView, 'languageChange', this.languageChange)
		this.listenTo(this.actionsView, 'editStateChange', function(isEditing) {
			if (isEditing) {
				this.requestLock()
			} else if (this.views.canvas.model) {
				this.leaveEditMode()
			}
		})

		this.views = {
			pageList: new PageListView({app: this.app}),
			canvas: new CanvasView({app: this.app}),
			saveState: new SaveStateView(),
			tokenRenew: new TokenRenewView()
		}

		// Get page tags for RSPB only
		if (App && App.system && App.system.id === 24) {
			Storm.setPageTags().then(this.render.bind(this))
		}

		this.initialPageId = options.pageId

		// Fetch list of applicable languages for this app.
		var localeList = this.app.localeList

		localeList.fetchOnce()
			.then(this.addLanguages.bind(this))

		// Fetch list of page templates.
		var templateList = this.app.templateList

		templateList.fetchOnce()

		// Stop loading animation once ready event triggered
		this.views.pageList.once('ready', this.viewReady, this)

		// Enter preview mode when told to by the canvas.
		this.listenTo(this.views.canvas, 'enterPreviewMode', this.enterPreviewMode)

		// Listen for app changes.
		this.on('change:app', this.appChange, this)

		// Update save state when saves are queued/started.
		this.on('saving', this.savingTriggered, this)
		this.on('savingComplete', this.savingComplete, this)
	},

	/** @override */
	getPageTitle: function() {
		return $.t('editor.title')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id,
			pageTags: pageTags.get(App.system.id)
		}
	},

	viewReady: function() {
		// Go to the initial page, if set
		if (this.initialPageId !== undefined) {
			this.setPageId(this.initialPageId)
		}
	},

	setPageId: function(pageId) {
		App.startLoad()

		// Close add page form (it could be open).
		this.cancelNewPage()

		var canvas = this.views.canvas

		// Stop listening to any previous page unlock events.
		if (canvas.model) {
			this.stopListening(canvas.model.lock)
		}

		var page = this.app.pageList.get(pageId)

		if (page) {
			page.fetch().then(canvas.setPage.bind(canvas, page))

			// Set new URL
			App.router.navigate('/apps/' + this.app.id + '/pages/' + pageId)

			// Listen for page unlocking.
			this.listenTo(page.lock, 'change:isLocked', function(lock, isLocked) {
				if (!isLocked) {
					App.startLoad()
					this.enterPreviewMode()
					this.actionsView.setEditState(false)
					this.views.tokenRenew.$el.hide()

					// Fetch the page again to clear any unsaved changes.
					canvas.reloadPage().then(App.stopLoad)
				}

				this.actionsView.setEditState(isLocked)
			}, this)

			// Show token renew dialogue before the lock expires.
			this.listenTo(page.lock, 'change:isExpiring', function(lock, isExpiring) {
				var isLocked = lock.get('isLocked')

				if (isExpiring && isLocked) {
					this.tokenExpireCheck()
				}
			}, this)

			this.actionsView.showEditButton()
		} else {
			// Page not found - remove the ID from the URL
			this.views.canvas.setPage(null)
			App.router.navigate('/apps/' + this.app.id + '/pages')

			// Hide edit button from actions view.
			this.actionsView.setEditState(false)
			this.actionsView.hideEditButton()
		}

		App.stopLoad()
	},

	/** @override */
	afterRender: function() {
		// Render out child views.
		this.$('.page-list-container').html(this.views.pageList.render().el)
		this.$('.canvas-container').html(this.views.canvas.render().el)
		this.$el.append(this.views.saveState.render().el)
		this.$el.append(this.views.tokenRenew.render().el)

		// Enter read-only mode if we don't have write permission
		var permission = App.acl.getPermission('Content')

		if (permission !== 'Write' && permission !== 'Delete') {
			this.$('.edit-button').remove()
			this.$('.media-library-link').hide()
		}

		// Get list of page types from API
		this.app.classes.fetchOnce().then(function() {
			App.getSubclasses('Page').then(this.setPageTypes.bind(this))
			App.getSubclasses('PageCollection')
				.then(this.setPageTypes.bind(this))
		}.bind(this))
	},

	appChange: function(appId) {
		var app = this.app = this.appList_.get(appId)

		app.classes.fetchOnce()
		App.classes = app.classes

		// TODO need to re-render section so that the navigation gets updated
		// (i.e. plugins get shown/hidden).
	},

	// Show add page form
	addPageButtonClick: function() {
		this.$('.add-page-form').removeClass('zero-height')

		// Remove any previous TemplatesView instances.
		this.$('.templates-form').empty()

		// Show/hide NativePage name field.
		this.newPageTypeChange()

		// Show paste button, if applicable.
		var className  = APICompat.normaliseClassName(App.clipboard.className),
			subclasses = App.subclasses,
			isPage     = subclasses.Page.indexOf(App.clipboard.className) > -1,
			canPaste   = isPage || className === 'TabbedPageCollection'

		this.$('.paste-page-button').toggleClass('hidden', !canPaste)

		// Focus first input field
		this.$('.new-page-name').focus()
	},

	setPageTypes: function(pageTypes) {
		var $newPageType = this.$('.new-page-type')

		_.each(pageTypes, function(type) {
			// Don't list the generic class
			if (type === 'Page' || type === 'PageCollection') {
				return
			}

			// Only allow NativePage creation in developer mode.
			if (type === 'NativePage' && !App.developerMode) {
				return
			}

			var $option = $('<option>').val(type).text(type)

			$newPageType.append($option)
		})
	},

	// Dismiss add page form
	cancelNewPage: function() {
		var $addForm = this.$('.add-page-form')

		// Clear input values and re-enable
		$addForm.find('input').val('')
		$addForm.find('input, select, button').removeAttr('disabled').removeClass('has-error')
		$addForm.find('.error-message').hide()

		// Remove canvas mask, hide form
		$addForm.addClass('zero-height')
	},

	// Create new page
	saveNewPageButtonClick: function(e) {
		// Get given properties
		var name     = $(e.currentTarget).closest('.add-form').find('.new-page-name').val(),
			type       = this.$('.new-page-type').val(),
			tag        = $(e.currentTarget).closest('.add-form').find('.new-page-tag').val(),
			systemName = $(e.currentTarget).closest('.add-form').find('.new-page-native-name').val()

		// Always require a name, require system name for NativePage objects.
		if (name === '' || type === 'NativePage' && systemName === '') {
			if (name === '') {
				$('.new-page-name').addClass('has-error')
				$('.js-no-page-name-error').show()
			}
			return false
		}

		// Disable buttons and inputs
		var $addForm = this.$('.add-page-form')

		$addForm.find('input, select, button').attr('disabled', true).removeClass('has-error')
		$addForm.find('.error-message').hide()
		// Get class structure and create page
		var classObject = App.getClassStructure(type)
		if (classObject.title) {
			classObject.title.content[this.language] = name
		}
		if (classObject.tag) {
			classObject.tag = tag
		}

		if (type === 'NativePage') {
			classObject.name = systemName
		}

		// Set default page style attribute on Hazards app pages.
		// Massive hack for Phill. Needs removing in the future.
		if (this.app.isHazardsApp() && App.system.id === 3) {
			if ('attributes' in classObject) {
				classObject.attributes.push('STYLE_PAPER')
			}
		}

		var newPage = new Page(classObject)

		this.savePage(newPage)
		return false
	},

	// Save a new Page object.
	savePage: function(page) {
		App.startLoad()

		page.save(null, {appId: this.app.id})
		page.once('sync', function() {
			// Add to page list and set as current page
			this.app.pageList.add(page)
			this.setPageId(page.id)
			this.cancelNewPage()
			App.stopLoad()
		}, this)
	},

	// Create a new page using Storm clipboard data.
	pastePageButtonClick: function() {
		// Strip IDs and page IDs from clipboard payload.
		var data = $.extend(true, {}, App.clipboard.payload)

		ViewPicker.prototype._stripIDs(data)
		var page = new Page(data)

		this.savePage(page)
	},

	// Initialise language selector with available languages for app
	addLanguages: function() {
		var localeList    = this.app.localeList,
			$languageSelect = this.$('.language-select')

		localeList.forEach(function(lang) {
			var $option = $('<option>')
				.val(lang.get('code'))
				.text(lang.get('name'))

			$languageSelect.append($option)
		})

		// Set the initial language.
		var language = 'en'

		if (localeList.length > 0) {
			language = localeList.at(0).get('code')
		}

		this.actionsView.setLanguage(language)
	},

	// Change editor language
	languageChange: function(language) {
		// Set new selected language code.
		this.language = language

		// Trigger language change event on each child view.
		_.each(this.views, function(view) {
			view.trigger('change:language', language)
		})
	},

	// Attempt to lock the current page for editing
	requestLock: function() {
		var page = this.views.canvas.model

		if (!page) {
			return
		}

		App.startLoad()

		return page.lock.lock()
			.then(this.pageLocked.bind(this), this.pageLockFailed.bind(this))
	},

	pageLocked: function() {
		// Page locked successfully. Re-fetch page and enter edit mode
		this.views.canvas.reloadPage().then(this.enterEditMode.bind(this))
	},

	pageLockFailed: function() {
		var page = this.views.canvas.model

		// Check if the current user owns this lock
		if (page.lock.get('owner')) {
			var relock = confirm($.t('editor.confirmRelock'))

			if (relock) {
				var resolve = this.pageLocked.bind(this),
					reject  = this.pageLockFailed.bind(this)

				page.lock.lock().then(resolve, reject)
			} else {
				App.stopLoad()
			}
		} else {
			// Fetch name of locking user
			var user = new User({id: page.lock.get('userId')})

			user.fetch().then(function() {
				App.stopLoad()
				App.showToast($.t('error.lockedBy') +
					user.get('firstName') + ' ' + user.get('lastName'))
			})
		}
	},

	// Check if the user wishes to extend their lock.
	tokenExpireCheck: function() {
		var tokenRenewView = this.views.tokenRenew,
			lock           = this.views.canvas.model.lock

		tokenRenewView.off('tokenRenew')
		tokenRenewView.once('tokenRenew', function(renew) {
			if (renew) {
				lock.lock()
			} else {
				lock.unlock()
			}
		}, this)

		tokenRenewView.$el.show()
	},

	enterEditMode: function() {
		App.stopLoad()
		this.$('.preview').removeClass('preview-mode')
		this.views.canvas.startEditing()
	},

	// Ensure all changes saved before revoking lock.
	leaveEditMode: function() {
		App.startLoad()
		this.views.canvas.stopEditing()
		StormObject.then(function() {
			this.releaseLock().then(App.stopLoad)
		}.bind(this))
	},

	// Unlock the page and return to preview mode
	releaseLock: function() {
		var model = this.views.canvas.model

		if (!model) {
			return Promise.resolve()
		}

		return this.views.canvas.model.unlock()
	},

	// Toggle edit mode
	enterPreviewMode: function() {
		// Hide inspector and stop editing
		this.views.canvas.setInspector(null)
		this.actionsView.setEditState(false)
	},

	// Display view to add page from template.
	templates: function() {
		// Hide regular page creation bar
		this.$('.add-page-form').addClass('zero-height')

		// Create and display templates view
		var templatesView = new TemplatesView({app: this.app})

		this.listViews.push(templatesView)
		this.$('.templates-form').html(templatesView.render().el)
	},

	showNewPageForm: function() {
		this.$('.add-page-form').removeClass('zero-height')

		// Remove any previous TemplatesView instances.
		this.$('.templates-form').empty()
	},

	// Show NativePage name field where appropriate.
	newPageTypeChange: function() {
		var isNativePage = this.$('.new-page-type').val() === 'NativePage'

		this.$('.new-page-native').toggle(isNativePage)
	},

	// Called on save request running/queued.
	savingTriggered: function() {
		this.views.saveState.startSaving()
	},

	// Called once queue has emptied, all saves complete.
	savingComplete: function() {
		this.views.saveState.stopSaving()
	}
})
