var StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EditorSectionView = require('editor/editor-section-view'),
	utils = require('lib/utils')

var dateFormatRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/

/**
 * Exports {@link BadgeEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends BadgeEditView.prototype */{
	/** @override */
	className: 'badges',
	/** @override */
	template: require('./badge-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage',
		'keydown .valid-for-input': 'validForKeydown',
		'change .js-date-toggle': 'toggleDates',
		'change .js-dates': 'updateDateFields',
		'change .js-colour-picker': 'updateColourPreview'
	},

	/**
	 * @constructs BadgeEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var localePromise = this.app_.localeList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('Badge')

		this.listenTo(this.model, 'change:badge', this.updateBadge, this)

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				// Add in any missing text objects to the model.
				['title', 'shareMessage', 'completion', 'how'].forEach(function(key) {
					if (!this.model.has(key)) {
						var text = App.getClassStructure('Text', 0)
						this.model.set(key, text)
					}
				}, this)

				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, localePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.systemId = App.system.id
		data.appId = this.app_.id
		data.locales = this.app_.localeList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()
		var colourPickerEl = this.$('.js-colour-picker')

		// Colour picker for ARC Blood
		if (colourPickerEl) {
			colourPickerEl.val(this.model.get('backgroundImageColor'))
			this.updateColourPreview()
		}

		// Show current input values
		_.each(this.model.get('title..content'), function(val, key) {
			this.$('.title-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('completion..content'), function(val, key) {
			this.$('.completion-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('shareMessage..content'), function(val, key) {
			this.$('.share-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('how..content'), function(val, key) {
			this.$('.how-input[data-code=' + key + ']').val(val)
		})

		var campaignEl = this.$('#campaign')
		var validForEl = this.$('.valid-for-input')

		if (validForEl) {
			validForEl.val(this.model.get('validFor'))
		}

		// Hide for non First aid apps
		if (Storm.app.get('type') !== 0) {
			this.$('.js-valid-for-container').remove()
		}

		if (campaignEl) {
			var campaignStatus = this.model.get('campaign')
			campaignEl.attr('checked', campaignStatus)
			if (campaignStatus) {
				this.toggleDates()
			}
		}
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/badges', {trigger: true})
		}.bind(this))

		// Get all new input values
		var titles = {},
			completions = {},
			messages = {},
			hows = {},
			validFor = 0

		this.$('.title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				titles[code] = value
			}
		})

		this.$('.completion-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				completions[code] = value
			}
		})

		this.$('.share-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				messages[code] = value
			}
		})

		this.$('.how-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				hows[code] = value
			}
		})

		var campaignEl = this.$('#campaign')[0]
		var fromDateEl = this.$("#date-from")[0]
		var untilDateEl = this.$("#date-until")[0]
		var validForEl = this.$('.valid-for-input')[0]
		var colourPickerEl = this.$('.js-colour-picker')[0]

		if (validForEl) {
			validFor = Number(validForEl.value)
			this.model.set('validFor', validFor)
		}

		if (campaignEl && fromDateEl && untilDateEl) {
			var campaign = campaignEl.checked
			var fromDate = fromDateEl.value
			var untilDate = untilDateEl.value

			if (campaign && fromDate && untilDate) {
				// check that both start and end dates are valid
				if (dateFormatRegex.test(fromDate) && dateFormatRegex.test(untilDate)) {
					// get dates and convert so can easily compare dates
					var dateFromPickerVal = new Date(fromDate).toISOString()
					var dateUntilPickerVal = new Date(untilDate).toISOString()
					// ensure that the start date is not after the end date
					if (dateFromPickerVal > dateUntilPickerVal) {
						App.showToast($.t('editor.inspector.properties.badge.dateUntil.errorDateLessThan'))
						this.$("#date-until").addClass('has-error')
						App.stopLoad()
						return false
					}
				}
			}

			if (campaign && fromDate) {
				if (dateFormatRegex.test(fromDate)) {
					this.model.set('dateFrom', fromDate)
					this.$("#date-from").removeClass('has-error')
				} else {
					App.showToast($.t('editor.inspector.properties.badge.dateFrom.error'))
					this.$("#date-from").addClass('has-error')
					App.stopLoad()
					return false
				}
			}

			if (campaign && untilDate) {
				if (dateFormatRegex.test(untilDate)) {
					this.model.set('dateUntil', untilDate)
					this.$("#date-until").removeClass('has-error')
				} else {
					App.showToast($.t('editor.inspector.properties.badge.dateFrom.error'))
					this.$("#date-until").addClass('has-error')
					App.stopLoad()
					return false
				}
			}

			if (!campaign) {
				// dates are to be wiped if the campaign is not set as true.
				if (untilDate) {
					this.model.set('dateUntil', "")
				}
				if (fromDate) {
					this.model.set('dateFrom', "")
				}
			}

			this.model.set('campaign', campaign)
		}

		if (colourPickerEl) {
			this.model.set('backgroundImageColor', colourPickerEl.value)
		}

		this.model.set('title..content', titles)
		this.model.set('completion..content', completions)
		this.model.set('shareMessage..content', messages)
		this.model.set('how..content', hows)
		this.model.save(null, {appId: this.app_.id})
		return false
	},

	chooseImage: function() {
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: this.model.get('icon'),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:badge', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateColourPreview: function() {
		var previewEl = this.$('.colour-dropdown span')
		var colorVal = this.$('.js-colour-picker').val()

		previewEl.css("background-color", colorVal)
	},

	updateBadge: function() {
		// Support legacy image format.
		var icon = this.model.get('icon')

		if (!icon.src) {
			icon = icon.toJSON()
		}

		this.$('img').attr('src', utils.getImagePreviewUrl(icon))
	},

	toggleDates: function() {
		/**
			* @desc toggle date range pickers depending on the campaign checkbox status.
		*/
		this.$('.js-date-range').toggle()
	},

	updateDateFields: function(e) {
		/**
		  * @desc displays error styling to the user if dates are invalid.
		  * @param e - the input to be checked
		*/
		if (dateFormatRegex.test($(e.target).val())) {
			$(e.target).removeClass('has-error')
		} else {
			$(e.target).addClass('has-error')
		}
	},

	// Allow only numerical input for validFor input (e.g. no e/E - = +)
	validForKeydown: function(event) {
		return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))
	}
})
