
<div class="row">

	<!-- Push compose view -->
	<div class="add-vew add-form zero-height">
		<form>
			<div class="push-edit-container col-xs-12"></div>
			<div class="col-xs-12 actions-col">
				<p>
					<button class="button add-to-queue-button" data-i18n="push.addToQueue"></button>
				</p>
			</div>
		</form>
	</div>

	<div class="col-xs-6">
		<h3>{{t "push.existing"}}</h3>
	</div>
	<div class="col-sm-6 actions-col">
		<label>{{t "push.showAllApps"}} <input type="checkbox" class="show-all-checkbox"></label>
		<p>
			<button class="button wide pull-right add-button" data-i18n="common.add"></button>
			<button class="button wide white pull-right hidden close-add-button" data-i18n="common.close"></button>
		</p>
	</div>
	<div class="col-xs-12">
		<div class="empty-table-text" style="display:none">{{t "push.noNotifications"}}</div>
		<table>
			<thead>
				<tr>
					<th></th>
					<th class="ns-name">{{t "agreement.nationalSociety"}}</th>
					<th class="sort-button" data-property="category">{{t "push.category"}}</th>
					<th class="sort-button" data-property="schedule..start">{{t "publish.startTime"}}</th>
					<th class="sort-button" data-property="schedule..end">{{t "publish.endTime"}}</th>
					<th>{{t "push.notes"}}</th>
					<th>{{t "push.type"}}</th>
					<th>{{t "push.numberSent"}}</th>
					<th class="push-count-col">{{t "push.numberCompleted"}}</th>
					<th>{{t "push.createdBy"}}</th>
					<th>{{t "common.tools"}}</th>
				</tr>
			</thead>
			<tbody class="list-items"></tbody>
		</table>
	</div>
</div>
