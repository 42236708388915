var GDPCSectionView = require('./gdpc-section-view')

module.exports = GDPCSectionView.extend({
	template: require('./societies-view-template'),

	getPageTitle: function() {
		return $.t('gdpcConsole.nationalSocieties.title')
	},
	initialize: function() {
		App.stopLoad()
	}
})
