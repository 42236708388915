var AppSetupSectionView = require('app-setup/app-setup-section-view'),
	AppLocalesView    = require('./locale-view'),
	AppProperties       = require('./app-properties')

var IOS_BUNDLE_IDENTIFIER = 'bundleIdentifier',
	ANDROID_PACKAGE_NAME  = 'packageName'

/**
 * Exports {@link AppSetupView}.
 * @module
 */
module.exports = AppSetupSectionView.extend(/** @lends AppSetupView.prototype */{
	/** @override */
	template: require('./app-setup-view-template'),

	/** @override */
	getPageTitle: function() {
		return $.t('appSetup.title')
	},

	/** @override */
	events: {
		'click .save-app': 'saveApp',
		'click .download-json-button': 'downloadJSON',
		'click .download-csv-button': 'downloadCSV',
		'click .translation-upload-button': 'uploadButtonClick',
		'change .translation-upload-input': 'uploadInputChange',
		'change #app-status': 'updateAppStatus',
		'change #app-type': 'updateAppStatus',
		'change #app-type-container': 'updateAppStatus',
		'change #app-regional-container': 'updateAppStatus'
	},

	/**
	 * @constructs AppSetupView
	 * @extends AppSetupSectionView
	 * @override
	 */
	initialize: function(options) {
		options = options || {}

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.readyCount = 0
		this.app = options.app

		/** @private {AppProperties} */
		this.appProperties_ = new AppProperties(null, {appId: this.app.id})

		/** @private {AppLocalesView} */
		this.appLanguagesView_ = new AppLocalesView({app: this.app})

		/** @private {PageList} */
		this.pageList = this.app.pageList

		Promise.all([
			this.pageList.fetch(),
			this.appProperties_.fetch()
		]).then(App.stopLoad).then(this.render.bind(this))
	},

	/** @override */
	getRenderData: function() {
		var data = {
			appId: this.app.id,
			app: this.app.toJSON(),
			pageList: this.pageList.toJSON(),
			appStormId: App.system.apiCode + '-' + this.app.get('societyId') + '-' + this.app.id,
			bundleIdentifiers: {}
		}

		var iOSIdent = this.appProperties_.findWhere({
			idiom: 'ios',
			key: IOS_BUNDLE_IDENTIFIER
		})

		var androidIdent = this.appProperties_.findWhere({
			idiom: 'android',
			key: ANDROID_PACKAGE_NAME
		})

		if (iOSIdent) {
			data.bundleIdentifiers.ios = iOSIdent.get('value')
		}

		if (androidIdent) {
			data.bundleIdentifiers.android = androidIdent.get('value')
		}

		return data
	},

	/** @override */
	afterRender: function() {
		this.$('.language-list-container').append(this.appLanguagesView_.el)
		this.appLanguagesView_.render()

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="' + page.id + '">' + App.l(page.get('title')) + '</option>'
			})

			$('#start-page').append('<optgroup label="' + tag + '">' + options + '</optgroup>')
		}, this)

		// Set current start page ID
		$('#start-page').val(this.app.get('objectId'))

		if (!App.acl.hasWritePermission('App Setup', 'App setup')) {
			this.$('input, select, button').prop('disabled', true)
		}

		if (!App.acl.hasWritePermission('App Setup', 'Translation')) {
			this.$('.translation-upload').remove()

			if (!App.acl.hasReadPermission('App Setup', 'Translation')) {
				this.$('.translation-download').remove()
			}
		}

		this.setUIAppStatus()

		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	setUIAppStatus: function() {
		var status = this.app.get('status')
		this.$('#app-status').val(status)

		var regional = this.app.get('options').regional
		this.$('#app-regional-container').prop('checked', regional)

		var multi = this.app.get('options').multi

		switch (multi) {
			case 'wrapper':
				this.$('#app-type').prop('checked', true)
				this.$('#app-type-container').prop('checked', true)
				this.$('#container-app-group').show()

				break
			case 'content':
				this.$('#app-type').prop('checked', true)
				this.$('#app-type-container').prop('checked', false)
				this.$('#container-app-group').show()
				break
			case null:
				this.$('#app-type').prop('checked', false)
				this.$('#app-type-container').prop('checked', false)
				this.$('#container-app-group').hide()
				break
		}
	},

	updateAppStatus: function() {
		// Get values
		var appStatus = this.$('#app-status').val()
		var multiApp = this.$('#app-type').is(':checked')
		var containerApp = this.$('#app-type-container').is(':checked')
		var regional = this.$('#app-regional-container').is(':checked')
		if (multiApp && containerApp) {
			this.app.get('options').multi = 'wrapper'
			this.$('#container-app-group').show()
		} else if (multiApp && !containerApp) {
			this.app.get('options').multi = 'content'
			this.$('#container-app-group').show()
		} else {
			this.app.get('options').multi = null
			this.$('#container-app-group').hide()
		}
		this.app.get('options').regional = regional
		this.app.set('status', Number.parseInt(appStatus, 10))
	},

	saveApp: function() {
		App.startLoad()

		var self = this

		this.$('.app-name').each(function() {
			var code = $(this).data('code')
			self.app.get('name')[code] = this.value
		})

		this.app.once('sync', function() {
			// Rebuild page cache
			this.saveComplete()
		}, this)

		this.app.save({
			objectId: $('#start-page').val()
		})

		return false
	},

	saveComplete: function() {
		App.stopLoad()
		App.showToast($.t('hazards.disasters.saveSuccess'))
	},

	downloadJSON: function() {
		this.downloadTranslation('json')
	},

	downloadCSV: function() {
		this.downloadTranslation('csv')
	},

	downloadTranslation: function(format) {
		App.startLoad()

		var type = (format === 'json') ? 'application/json' : 'text/csv'

		Backbone.sync('fetch', new Backbone.Model(), {
			url: this.app.url() + '/translation?type=' + format,

			dataType: 'text',

			success: function(data) {
				window.URL = window.URL || window.webkitURL

				var file = new Blob([data], {type: type}),
					url  = window.URL.createObjectURL(file),
					link = document.createElement('a')

				link.href = url
				link.download = 'translation'
				link.target = '_blank'

				link.click()

				App.stopLoad()
			},

			error: function() {
				App.stopLoad()
				swal($.t('error.oops'), $.t('appSetup.translationError'), 'error')
			}
		})
	},

	uploadButtonClick: function() {
		this.$('.translation-upload-input').click()
	},

	uploadInputChange: function(e) {
		// Check selected file is either CSV or JSON.
		var file = e.target.files[0]

		if (!file) {
			return
		}

		var filenameMatch = file.name.match(/.(json|csv)$/)

		if (filenameMatch === null) {
			return
		}

		// Do the upload.
		App.startLoad()

		var formData = new FormData()

		formData.append('files', file)

		$.ajax({
			url: this.app.url() + '/translation',

			data: formData,
			type: 'POST',
			contentType: false,
			processData: false,
			global: false,

			headers: App.session.getHeadersObject(),

			success: function() {
				App.showToast('Translations imported successfully.')
			},

			error: function(jqXHR) {
				App.showToast('Failed to import translations. Failed with error ' + jqXHR.status)
				console.log(jqXHR)
			},

			complete: App.stopLoad
		})
	},

	/** @override */
	beforeDestroy: function() {
		this.appLanguagesView_.destroy()
	}
})
