<div class="app-copy">
<legend> App copy - Job: {{auditId}} </legend>
<div class="container">
	{{#if error}}
		<h2 data-i18n="appCreate.appCopy.couldNotFind"></h2>
	{{else}}
		<h2 data-i18n="appCreate.appCopy.newAppCopying"></h2>
	{{/if}}
	{{#each audit.details.stages}}
	<div class="row">
		<div class="col-sm-12">
			<label>{{name}} <span class="percent"></span><img height="25px" src="/images/icon/loading.svg" /></label>
			<progress class="app-copy-progress" data-name="{{name}}" value="50" max="100"></progress>
		</div>
	</div>
	{{/each}}
</div>
