<div class="product-preview-list-item">
	<div class="product-preview-content-wrapper">
		<div class="product-preview-image" style="background-image: url('{{getPreviewUrl image}}')"></div>
		<div class="product-preview-details">
			<p class="product-preview-title is-bold mb-2">{{{getLocaleTextHTML name}}}</p>
			{{#if description }} <p class="mb-0">{{{getLocaleTextHTML description}}}</p> {{/if}}
			{{#if price }} <p class="product-preview-price mb-0">${{price}}</p>{{/if}}
			{{#if priceText }} <p class="product-preview-price mb-0">{{getLocaleTextHTML priceText}}</p> {{/if}}
		</div>
	</div>
	<div class="product-preview-actions">
		<button class="button alt-red button-curved">{{{getLocaleTextHTML cta.title}}}</button>
	</div>
</div>
<div class="product-preview-counters">
	{{#ifEqual productCount 1}}
	{{else}}
		{{#times productCount}}
			<span {{#ifEqual this ../activeIndex}}class="active-counter" {{/ifEqual}}></span>
		{{/times}}
	{{/ifEqual}}
</div>
