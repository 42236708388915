{{partial "levels/levels-nav"}}

<div class="title-bar">
	<div class="header">
		<a href="javascript:history.go(-1)"><span class="back-button"></span></a>
		<span>{{t "swim.edit"}}</span>
		<button class="button positive save">{{t "common.save"}}</button>
	</div>
</div>

<div class="container pad-top">
	<div class="row">
		<div class="span12">

			<form class="form-horizontal">
				<fieldset>

					<legend>{{t "levels.details"}}</legend>

					<div class="control-group">
						<label class="control-label" for="level-title">{{t "editor.inspector.properties.title.title"}}</label>
						<div class="controls">
							<input type="text" id="level-title" value="{{name}}" class="input-xlarge">
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="level-description">{{t "common.description"}}</label>
						<div class="controls">
							<textarea type="text" id="level-description" class="input-xxlarge" style="height:100px">{{description}}</textarea>
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="page-select">{{t "common.page"}}</label>
						<div class="controls">
							<select id="page-select" class="input-xlarge"></select>
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="badge-select">{{t "editor.inspector.properties.badge.title"}}</label>
						<div class="controls">
							<select id="badge-select" class="input-xlarge">
								{{#each @badges}}
									<option value="{{id}}">{{getLocaleText title}}</option>
								{{/each}}
							</select>
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="position-select">{{t "common.position"}}</label>
						<div class="controls">
							<select id="position-select" class="input-xlarge">
								{{#each @levels}}
									<option value="{{position}}">{{t "common.position"}} {{addOne position}}</option>
								{{/each}}
							</select>
						</div>
					</div>

					{{#if id}}
						<legend>{{t "levels.children"}}</legend>

						<table class="table-striped">
							{{#each @children}}
								{{#if status}}
									<tr>
										<td>
											<a href="/apps/{{../appId}}/levels/{{id}}">{{name}}</a>
											<button type="button" class="button negative remove-child-button right" data-id="{{id}}" title="Remove child"><i class="icon-trash"></i>
										</td>
									</tr>
								{{/if}}
							{{/each}}
						</table>

						<div class="form-actions">
							<a href="/apps/{{appId}}/levels/{{id}}/new"><button type="button" class="button">{{t "common.add"}}</button></a>
						</div>
					{{/if}}

				</fieldset>
			</form>

		</div>
	</div>

</div>
