<table>
	<thead>
		<tr>
			<th style="max-width:100px">{{t "editor.inspector.properties.image.title"}}</th>
			{{!-- <th style="max-width:100px">{{t "editor.inspector.properties.imageInactive.title"}}</th> --}}
			<th>{{t "journey.stage"}}</th>
			<th>{{t "journey.title"}}</th>
			<th>{{t "journey.link"}}</th>
			<th>{{t "journey.pending"}}</th>
			<th>{{t "journey.active"}}</th>
			<th>{{t "journey.elapsed"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
