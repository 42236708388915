<div class="container-fluid">
	<div class="row">
		<div class="col-sm-6"> <legend>{{t 'appSetup.translation'}}</legend></div>
		<!-- <div class="form-group translation-download col-sm-4">

		</div> -->
		<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save">{{t "common.save"}}</button>
				{{#ifEqual systemId 3}}
					<!-- Translations import / export GDPC only -->
					<a href="/apps/{{appId}}/settings/translations/import" class="button wide outlined is-link ml-10">{{t "translations.importExport.importExport"}}</a>
				{{/ifEqual}}
		</div>
	</div>
	<div class="row">
		<!-- Type Plugin / Native String -->
		<div class="col-sm-6 form-group">
			<label>{{t "common.filter"}}</label>
			<select class="form-control" id="type-selector">
				<option value="plugins" {{#ifEqual type 'plugins'}} selected {{/ifEqual}}>Plugins</option>
				<option value="native" {{#ifEqual type 'native'}} selected {{/ifEqual}}>Native</option>
				<option value="pages" {{#ifEqual type 'pages'}} selected {{/ifEqual}}>Pages</option>
				<option value="all" {{#ifEqual type 'all'}} selected {{/ifEqual}}>All</option>
			</select>
		</div>

		<div class="col-sm-6">
			<label for="search_field">{{t "common.search"}}</label>

			<div class="row">
				<div class="col-sm-9 search-translations-wrapper">
					<input id="search_field" name="search_field" type="search" placeholder="{{t 'common.search'}}" class="form-control search-translations-wrapper__input">
					<p id="search-results" class="search-translations-wrapper__number">
						<span id="current-number"></span> / <span id="results-number"></span>
					</p>
				</div>

				<div class="col-sm-3">
					<button id="search_prev_button" type="button" class="button">{{t 'appSetup.prev'}}</button>
					<button id="search_next_button" type="button" class="button">{{t 'appSetup.next'}}</button>
				</div>
			</div>
		</div>

		<!-- Plugins -->
		<div class="col-sm-6 form-group" id="plugins" style="display:none;">
			<label>{{t "plugins.title"}}</label>
			<select class="form-control" id="plugin-selector">
				<option value="-">-</option>
				{{#each plugins}}<option value="{{id}}">{{name}}</option>{{/each}}
			</select>
		</div>

		<!-- Classes -->
		<div class="col-sm-3 form-group" id="classes" style="display:none;">
			<label>{{t "plugins.title"}}</label>
			<select class="form-control">
				{{#each classes}}<option value="{{class}}">{{class}}</option>{{/each}}
			</select>
		</div>

		<!-- Pages -->
		<div class="col-sm-6 form-group" id="pages" style="display:none;">
			<label>{{t "editor.pageList.title"}}</label>
			<select class="form-control" id="page-selector">
				{{{pages}}}
			</select>

		</div>

	</div>
	<div class="filter-langs">
		<label>Filter languages</label>
		{{#each languages}}
			{{#isLocaleEnabled this}}
			<input type="checkbox" class="check-lang-option" value="{{code}}" checked>{{language.name.native}} ({{toUpperCase code}})</input>
			{{/isLocaleEnabled}}
		{{/each}}
	</div>
	<div class="row">
		<div id="handsontable"></div>
	</div>
</div>
