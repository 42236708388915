var StormQL = require('models/stormql')

module.exports = Backbone.View.extend({
	template: require('./inspector-sponsor-selector-template.hbs'),

	events: {
		'change .sponsorship-type': 'sponsorTypeChange',
		'change .sponsorship-selection': 'sponsorValueChange',
		'change .sponsorship-tag-selection': 'sponsorValueChange',
		'click .remove-sponsorship': 'removeSponsorship'
	},

	initialize: function(options) {
		this.views = {}

		this.noSave = options.noSave || false
		this.sponsorList = new StormQL(null, {app: options.app})
		this.sponsorList.once('sync', this.ready, this)
		this.sponsorList.fetch({
			data: {
				class: 'StormQLSponsorship'
			}
		})

		this.on('change', function() {
			var parent = this.model.collection.parent
			parent.trigger('change', parent)
			parent.needsSaving = true
		}, this)
	},

	ready: function() {
		if (!this.model.get('sponsorship')) {
			this.model.set('sponsorship', [])
		}
		this.sponsorSelectors = this.model.get('sponsorship')
		if (this.sponsorSelectors.length === 0) {
			var sponsorSelector = App.getClassStructure('SponsorshipSelector', 0)
			this.sponsorSelectors.push(sponsorSelector)
		}
		this.render()
	},

	isListable: function() {
		var className = this.model.get('class')
		return className !== 'SponsorshipLogoListItem' && className !== 'SponsorshipListItem'
	},

	removeSponsorship: function() {
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				App.startLoad()
				this.model.destroy().then(App.stopLoad)
				this.remove()
			}
		}.bind(this))
	},

	getRenderData: function() {
		var tags = []
		_.each(this.sponsorList.models, function(sponsor) {
			var newTags = sponsor.get('tags')
			tags = tags.concat(newTags)
		}, this)

		var uniqueArray = tags.filter(function(elem, pos) {
			return tags.indexOf(elem) === pos
		})
		var data = {
			data: this.model.toJSON(),
			tags: uniqueArray,
			sponsorList: this.sponsorList.toJSON(),
			viewCid: this.cid,
			isListable: this.isListable()
		}
		return data
	},

	afterRender: function() {
		this.setDropdowns()
	},

	textChange: function(e) {
		var self = this

		setTimeout(function() {
			var val = $(e.currentTarget).val()
			self.model.set('text..content..' + App.view.language, val)
			self.trigger('change')
		})

		e.stopPropagation()
	},

	delayChange: function(e) {
		var val = Math.abs(parseFloat($(e.currentTarget).val(), 10) || 0)

		// Don't allow delays of < 0.5, unless this is the only spotlight image
		if (val < 0.5 && this.model.collection.length > 1) {
			val = 0.5
		}

		this.model.set('delay', val * 1000)
		this.trigger('change')
		e.stopPropagation()
	},

	sponsorTypeChange: function() {
		this.setDropdowns()
		this.sponsorValueChange()
	},

	sponsorValueChange: function() {
		var cid,
			key,
			type,
			value
		var parentCid = this.cid
		var sponsorSelectors = this.sponsorSelectors
		$(".sponsorship-type").each(function() {
			cid = $(this).data('cid')
			key = $(this).data('key')

			// Ensure we're dealing with controls only from this view
			if (cid === parentCid) {
				type = $(this).val()
				value = '-'
				switch (type) {
					case 'id':
						$('.sponsorship-selection').each(function() {
							var itemcid = $(this).data('cid')
							if (itemcid === parentCid) {
								value = $(this).val()
							}
						})
						break
					case 'tag':
						$('.sponsorship-tag-selection').each(function() {
							var itemcid = $(this).data('cid')
							if (itemcid === parentCid) {
								value = $(this).val()
							}
						})
						break
				}
				if (value !== '-') {
					sponsorSelectors.at(key).set('value', value)
				}
				if (type) {
					sponsorSelectors.at(key).set('type', type)
				}
			}
		})
		this.trigger('change')
		if (!this.noSave) {
			this.model.save()
		}
	},

	setDropdowns: function() {
		$(".sponsorship-type").each(function() {
			var cid = $(this).data('cid')
			var value = $(this).val()
			switch (value) {
				case 'id':
					$('.sponsorship-tag-selection').each(function() {
						var itemcid = $(this).data('cid')
						if (itemcid === cid) {
							$(this).hide()
						}
					})
					$('.sponsorship-selection').each(function() {
						var itemcid = $(this).data('cid')
						if (itemcid === cid) {
							$(this).show()
						}
					})
					break
				case 'tag':
					$('.sponsorship-tag-selection').each(function() {
						var itemcid = $(this).data('cid')
						if (itemcid === cid) {
							$(this).show()
						}
					})
					$('.sponsorship-selection').each(function() {
						var itemcid = $(this).data('cid')
						if (itemcid === cid) {
							$(this).hide()
						}
					})
					break
			}
		})
	}
})
