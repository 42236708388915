<b><code><i class="icon-lightbulb"></i> {{model.id}} - {{model.class}}</code></b><br>
<div class="tool-icon tooltip-left" data-tooltip="{{t 'r4b.modules.section.delete'}}">
	<i data-id="{{model.id}}" data-index="{{index}}" class="icon-trash remove-section red-delete"></i>
</div>
{{#ifEqual model.class "R4b_ModuleSection"}}
<div class="tool-icon tooltip-left" data-tooltip="{{t 'r4b.modules.section.settings'}}">
	<i class="icon-cog toggle-tools blue-settings"></i>
</div>
{{/ifEqual}}
<div class="tool-icon tooltip-left" data-tooltip="{{t 'r4b.modules.section.add'}}">
	<i data-index="{{index}}" class="icon-plus add-section-below blue-settings"></i>
</div>
<div class="section-header">
	<h5 class="section-text"> <i class="icon-circle-arrow-right"></i> {{t 'r4b.modules.section.title'}} {{sectionNumber}}, {{t "r4b.id" }} - {{model.id}}</h5>
	{{#ifBorCorD model.class 'R4b_ModuleSection' 'R4b_TaskModuleSection' 'R4b_BcpModuleSection'}}
	<label id="task-label" for="taskcheck-task-{{selectorId}}">Task?</label>
	<div class="onoffswitch">
	    <input type="checkbox" name="onoffswitch-task" class="onoffswitch-checkbox task-check" id="taskcheck-task-{{selectorId}}" {{#ifBorC model.class 'R4b_TaskModuleSection' 'R4b_BcpModuleSection'}}checked="true"{{/ifBorC}}>
	    <label class="onoffswitch-label" for="taskcheck-task-{{selectorId}}"></label>
	</div>
	<label id="bcp-label" for="taskcheck-bcp-{{selectorId}}">BCP Task?</label>
	<div class="onoffswitch">
			<input type="checkbox" name="onoffswitch-bcp" class="onoffswitch-checkbox bcp-check" id="taskcheck-bcp-{{selectorId}}" {{#ifEqual model.class 'R4b_BcpModuleSection'}}checked="true"{{/ifEqual}}>
			<label class="onoffswitch-label" for="taskcheck-bcp-{{selectorId}}"></label>
	</div>

	{{/ifBorCorD}}
	{{#ifOr taskModuleSection bcpModuleSection}}
		{{#isNotTranslated model.intro.data.content language}}
			<span class="text-danger tooltip-bottom" data-tooltip="{{t 'r4b.notTranslated'}}">
				<i class="icon-warning-sign"></i>
			</span>
		{{/isNotTranslated}}
	{{/ifOr}}
</div>

<div class="task-content-wrapper">
	<span class="task-content enter-text" data-selection={{selectorId}} data-placeholder="{{t 'r4b.modules.blockContent.task.help'}}" {{#ifOr taskModuleSection bcpModuleSection}} style="display:none;" {{/ifOr}} contenteditable="true">{{getLocaleText model.intro.data.content}}</span>
</div>

<div class="section-tools" data-selection={{selectorId}} style="display:none;">
	{{#ifBorCorD model.class 'R4b_ModuleSection' 'R4b_TaskModuleSection' 'R4b_BcpModuleSection'}}
	<h5>{{t 'r4b.modules.blockContent.logic.title'}}</h5>
	<div class="logic-view" data-selection={{selectorId}}></div>
	{{/ifBorCorD}}
</div>
<div class="section-blocks" data-selection={{selectorId}} data-id="{{model.id}}" data-index="{{index}}"></div>
