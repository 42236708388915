var EditorSectionView = require('editor/editor-section-view'),
	Level = require('./level'),
	LevelList = require('./level-list'),
	StormQL = require('models/stormql'),
	PageList = require('editor/page-list/page-list')

/**
 * Exports {@link JourneyEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends JourneyEditView.prototype */{
	/** @override */
	className: 'edit-level',
	/** @override */
	template: require('./level-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .remove-child-button': 'removeChild'
	},

	/**
	 * @constructs JourneyEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.app = options.app
		this.appId = options.app.id
		var promises = []
		// Don't allow levels to be accessed outside of SWIM
		if (App.system.id !== 9 || this.appId !== 3) {
			App.router.navigate('/', {trigger: true, replace: true})
			return
		}

		// Fetch current level list for setting position
		this.levelList = new LevelList(null, {app: this.app})
		// this.levelList.on('sync', this.ready, this)
		promises.push(this.levelList.fetch())

		// Fetch badges to set badge ID
		this.badgeList = new StormQL(null, {app: this.app})
		// this.badgeList.once('sync', this.ready, this)
		promises.push(this.badgeList.fetch({data: {class: 'Badge'}}))

		// Fetch page list to set page URL
		this.pageList = new PageList(null, {appId: this.appId})
		// this.pageList.once('sync', this.ready, this)
		promises.push(this.pageList.fetch())

		this.model = new Level({appId: this.appId})

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			this.model.children.once('sync', this.ready, this)
			promises.push(this.model.fetch())
			promises.push(this.model.children.fetch())
		} else {
			this.model.set('parentId', options.parentId)
			this.ready()
		}

		Promise.all(promises)
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		// Set initial position to bottom, if new model
		if (this.model.isNew()) {
			this.model.set('position', this.levelList.length)
			this.levelList.add(this.model)
		}
		this.render()
	},

	/** @override */
	getRenderData: function() {
		return {
			model: this.model.toJSON(),
			badges: this.badgeList.toJSON(),
			levels: this.levelList.toJSON(),
			pages: this.pageList.toJSON(),
			children: this.model.children.toJSON(),
			appId: this.appId
		}
	},

	/** @override */
	afterRender: function() {
		// Populate page selector
		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		})

		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			_.each(taggedPages, function(page) {
				if (page.get('class') === 'NativePage') {
					options += '<option value="app://native/pages/' + page.get('name') + '">' + App.l(page.get('title')) + '</option>'
				} else {
					options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
				}
			})

			this.$('#page-select').append('<optgroup label="' + tag + '">' + options + '</optgroup>')
		}, this)

		// Set current select values
		this.$('#position-select').val(this.model.get('position'))
		this.$('#badge-select').val(this.model.get('badgeId'))
		this.$('#page-select').val(this.model.get('pageUrl'))
	},

	removeChild: function(e) {
		var id = parseInt($(e.currentTarget).data('id'), 10)

		if (confirm($.t('editor.inspector.confirmDelete'))) {
			var tempLevel = new Level({id: id})

			tempLevel.once('sync', function() {
				// Remove table row from DOM
				$(e.currentTarget).parent().parent().remove()
				App.stopLoad()
			})

			App.startLoad()
			tempLevel.destroy()
		}
	},

	save: function() {
		App.startLoad()
		this.model.once('sync', function() {
			App.stopLoad()
			App.router.navigate('/apps/' + this.app.id + '/swim-levels', {trigger: true})
		}.bind(this))

		this.model.save({
			name: this.$('#level-title').val(),
			description: this.$('#level-description').val(),
			badgeId: parseInt(this.$('#badge-select').val(), 10),
			pageUrl: this.$('#page-select').val(),
			position: parseInt(this.$('#position-select').val(), 10)
		})
	}
})
