var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view')

var ProductListView = ListView.extend({
	template: require('./product-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./product-list-item-view')
})

/**
 * Exports {@link ProductsView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends ProductsView.prototype */{
	/** @override */
	className: 'products',
	/** @override */
	template: require('./products-view-template'),

	/**
	 * @constructs ProductsView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()
		this.app = App.appList.get(options.appId)

		this.views = {
			list: new ProductListView({
				fetchData: {data: {class: 'Product'}},
				app: this.app
			})
		}
		this.views.list.on('ready', this.listFetched.bind(this))
	},

	listFetched: function() {
		if (this.views.list.collection.length === 0) {
			$('#noItems').show()
			$('table').hide()
		} else {
			$('#noItems').hide()
			$('table').show()
		}
	},

	/** @override */
	getPageTitle: function() {
		return $.t('products.products')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	}
})
