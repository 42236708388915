var Maps = require('lib/maps'),
	RegionDrawView = require('./region-draw-view'),
	CustomAlert = require('./custom-alert'),
	CanvasView = require('editor/canvas/canvas-view'),
	Page = require('editor/page-list/page'),
	LanguageList = require('editor/language-list'),
	HazardsSectionView = require('./hazards-section-view'),
	DisasterList = require('./disaster-list'),
	CountryList = require('editor/countries-list')

const HIDDEN_CLASS = 'hidden'
// var LAT_LONG_REGEX = /[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/g
const LAT_LONG_REGEX = /(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)/g

module.exports = HazardsSectionView.extend({
	template: require('./general-notifications-edit-view-template'),

	events: {
		'click .map-button': 'mapClick',
		'click .preview-button': 'showPreview',
		'click .save-button': 'save',
		'click .preview-back-button': 'back',
		'click #alert-cap': 'capToggle',
		'click #show-poly-data': 'polyDataToggle',
		'click .update-map': 'populateFromCustom',
		'change .js-mandatory-field': 'updateMandatoryFields'
	},

	initialize: function(options) {
		this.views = {}
		this.overlay = null
		this.app = options.app
		this.language = App.il8next.language
		// Fetch page list to construct root page dropdowns.
		this.pageList = this.app.pageList
		this.pageList.fetchOnce().then(function() {
			// Ready touches DOM, so can't happen in constructor.
			setTimeout(this.ready.bind(this))
		}.bind(this))

		this.cap = true // Used to signify if a cap is being enabled.

		// Fetch full disasters list.
		this.disasterList = new DisasterList()
		var disasterFetch = this.disasterList.fetch()

		this.model = new CustomAlert()
		this.listenTo(this.model, 'change:area', this.renderRegionPreview)

		// Get society for the currently selected app.
		var societyId = this.app.get('societyId')

		this.languageList = new LanguageList()
		var langFetch = this.languageList.fetch()

		this.societyLanguageList = new LanguageList(null, {societyId: societyId})
		var societyLangFetch = this.societyLanguageList.fetch()

		// Get society for the currently selected app.
		this.society = App.societiesList.get(societyId)
		this.countryId = this.society.get('countryId')

		// Fetch list of all countries
		this.countries = new CountryList()
		var countryFetch = this.countries.fetch()

		this.countryCode = this.society.get('locale')

		$.when(langFetch, societyLangFetch, disasterFetch, countryFetch).then(this.ready.bind(this))
	},

	getPageTitle: function() {
		return $.t('hazards.generalNotifications.createNotification')
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			pageSelectOptions: this.pageSelectOptions,
			societyLanguageList: this.societyLanguageList.toJSON(),
			disasterList: this.disasterList.toJSON(),
			currentLang: this.language
		}
	},

	ready: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		this.pageSelectOptions = pageListDropdownOptions

		this.render()

		// Initialise Google Maps API.
		Maps.init(this.initGoogleMap.bind(this))

		// Setup datepickers.
		this.expireDatepicker = new Pikaday({
			field: this.$('#alert-expiry')[0],
			bound: true,
			showTime: true
		})

		this.effectiveDatepicker = new Pikaday({
			field: this.$('#alert-effective')[0],
			bound: true,
			showTime: true
		})

		this.onsetDatepicker = new Pikaday({
			field: this.$('#alert-onset')[0],
			bound: true,
			showTime: true
		})

		var initialDate = null

		var expireDate = null

		var onsetDate = null

		this.effectiveDatepicker.setDate(initialDate)
		this.expireDatepicker.setDate(expireDate)
		this.onsetDatepicker.setDate(onsetDate)

		// Set country code
		this.country = this.countries.findWhere({id: this.countryId})
		this.countryCode = ''

		if (this.country) {
			this.countryCode = this.country.get('code').iso3
		}

		App.stopLoad()
	},

	initGoogleMap: function() {
		// Initialize Google map
		this.map = new google.maps.Map(this.$('.alert-preview-map')[0], {
			disableDefaultUI: true,
			center: new google.maps.LatLng(40, -93),
			zoom: 5
		})

		App.stopLoad()
	},

	// Show region draw modal on map click.
	mapClick: function() {
		if (this.views.regionDrawView) {
			this.views.regionDrawView.destroy()
		}

		this.views.regionDrawView = new RegionDrawView({model: this.model, appId: this.app.id})

		$('body').append(this.views.regionDrawView.render().el)
		this.views.regionDrawView.show()
	},

	// Draw polygons on map preview and fit bounds.
	renderRegionPreview: function() {
		// Clear previous polygons.
		if (this.overlay) {
			this.overlay.setMap(null)
		}

		// Draw new polygons
		var bounds = new google.maps.LatLngBounds(),
			region = this.model.get('area'),
			paths = []
		region.polygon.coordinates.forEach(function(polygon) {
			var latLngs = polygon.map(function(path) {
				return path.map(function(coord) {
					var latLng = new google.maps.LatLng(coord[1], coord[0])
					bounds.extend(latLng)
					return latLng
				})
			})

			paths = paths.concat(latLngs)
		})

		// Construct the polygon.
		this.overlay = new google.maps.Polygon({
			paths: paths,
			strokeColor: '#F93A2F',
			strokeOpacity: 1,
			strokeWeight: 2,
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			map: this.map
		})

		this.map.fitBounds(bounds)
		this.populateCustomPoly()
	},

	// Populate the custom polygon textarea
	populateCustomPoly: function() {
		var region = this.model.get('area'),
			coordinates = region.polygon.coordinates[0][0]
		$('#alert-custom-polygon').val('')
		coordinates.forEach(function(polygon) {
			$('#alert-custom-polygon').val($('#alert-custom-polygon').val() + '[' + polygon + '],\n')
		})
	},

	populateFromCustom: function(e) {
		e.preventDefault()
		var customPoly = $('#alert-custom-polygon').val()
		var strArray = customPoly.match(LAT_LONG_REGEX) // Regex to ensure we get the right format (i.e. -1.0,1.0)
		var polyArray = []
		if (!strArray) {
			// Incorrect data added.
			$('#polywrapper').addClass('has-error')
			$('#map-error').text($.t('hazards.generalNotifications.customMapData.notFound'))
			return
		}
		strArray.forEach(function(str) {
			// Split and convert to number
			polyArray.push(str.split(',').map(Number))
		})

		// Validate
		if (polyArray.length < 3) {
			// There must be at least three points
			// Set error and return
			$('#map-error').text($.t('hazards.generalNotifications.customMapData.moreCoords'))
			$('#polywrapper').addClass('has-error')
			return
		}

		// Get first and last in array and ensure they're the same.
		var first = polyArray[0]
		var error = false
		// Ensure that the first does not match with any other besides the last
		polyArray.forEach(function(coord, index) {
			if (index !== polyArray.length - 1 && index !== 0) {
				if (first[0] === coord[0] && first[1] === coord[1]) {
					// First matches something that isn't the last coord
					// Set error and return
					$('#map-error').text($.t('hazards.generalNotifications.customMapData.fisrtFoundNotLast'))
					error = true
				}
			} else if (first[0] !== coord[0] || first[1] !== coord[1]) {
				// Fist and last coordinates must be the same
				// Set error and return
				$('#map-error').text($.t('hazards.generalNotifications.customMapData.firstAndLast'))
				error = true
			}
		})
		if (error) {
			$('#polywrapper').addClass('has-error')
			return
		}
		$('#polywrapper').removeClass('has-error')
		$('#map-error').text('')
		var area = this.model.get('area') || {
			polygon: {
				type: 'Polygon',
				coordinates: []
			},
			description: 'Unset'
		}

		area.polygon.coordinates = [[polyArray]]
		this.model.set('area', area)
		this.renderRegionPreview()
	},

	updateMandatoryFields: function(e) {
		if ($(e.target).val() === null || $(e.target).val() === "") {
			$(e.target).addClass('has-error')
		} else {
			$(e.target).removeClass('has-error')
		}
	},

	// Hide form fields, show preview before send.
	showPreview: function() {
		var hasMissedMandatoryOption = false
		var hasMissedMandatoryDateField = false

		$('.js-mandatory-select').each(function(index, obj) {
			if ($(obj).val() === null) {
				$(obj).addClass('has-error')
				hasMissedMandatoryOption = true
			}
		})

		$('.js-mandatory-date').each(function(index, obj) {
			if ($(obj).val() === "") {
				$(obj).addClass('has-error')
				hasMissedMandatoryDateField = true
			}
		})

		if (hasMissedMandatoryOption) {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseSelectOption'))
			return
		}

		if (hasMissedMandatoryDateField) {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseSelectDates'))
			return
		}

		var expiryDate = this.expireDatepicker.getDate(),
			description = this.$('#alert-message').val()

		var jobTitle = '',
			user = Storm.user_

		if (user.extra !== null) {
			jobTitle = user.extra.get('jobTitle')
		}

		this.model.set('country', this.countryCode)
		this.model.set('language', this.$('#alert-language').val())
		this.model.set('to', parseInt(expiryDate / 1000, 10))
		this.model.set('description', description)
		this.model.set('page', this.$('#alert-page').val())
		this.model.set('notify', this.$('#alert-notify')[0].checked)
		this.model.set('event', this.$('#alert-event').val())
		this.model.set('headline', this.$('#alert-headline').val())
		this.model.set('type', this.$('#alert-type').val())
		this.model.set('status', this.$('#alert-status').val())
		// this.model.set('scope', this.$('#alert-scope').val()) Should be public by default.
		this.model.set('scope', 'public')
		this.model.set('category', this.$('#alert-category').val())
		this.model.set('urgency', this.$('#alert-urgency').val())
		this.model.set('severity', this.$('#alert-severity').val())
		this.model.set('certainty', this.$('#alert-certainty').val())
		this.model.set('rcn', this.$('#alert-cap')[0].checked)
		this.model.set('societyId', this.society.id)

		var onsetDate = this.onsetDatepicker.getDate()
		var effectiveDate = this.effectiveDatepicker.getDate()
		var expireDate = this.expireDatepicker.getDate()

		if (onsetDate && effectiveDate && expireDate) {
			// Dates
			var dates = {
				sent: new Date().toISOString(),
				onset: this.onsetDatepicker.getDate().toISOString(),
				effective: this.effectiveDatepicker.getDate().toISOString(),
				expires: this.expireDatepicker.getDate().toISOString()
			}

			if (effectiveDate.getTime() > expireDate.getTime()) {
				App.showToast($.t('hazards.alerts.custom.errors.effectiveWrong'))
				return
			}

			this.model.set('dates', dates)
		}

		if (!this.model.has('dates')) {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseSelectDates'))
			return
		}

		if (!this.model.has('area')) {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseSelectRegion'))
			return
		}

		var area = this.model.get('area')
		var paths = area.polygon.coordinates
		area.description = this.$('#alert-area_description').val()
		if (this.model.get('rcn') === true && $('#alert-area_description').val() === "") {
			// No description set...
			App.showToast($.t('hazards.alerts.custom.errors.pleaseProvideAreaDescription'))
			return
		}
		area.polygon.coordinates = paths[0] // Set to first one in array as RCN api does not accept multipolygons at the moment

		if (this.model.get('description') === '') {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseProvideMessage'))
			return
		}

		if (this.model.get('event') === '') {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseProvideEvent'))
			return
		}

		if (this.model.get('rcn') === true && this.model.get('headline') === '') {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseProvideHeadline'))
			return
		}

		// Format description for "Sent by" for GDPC users with the 'partner' role.
		var partnerRole = App.userRoles.findWhere({id: 76})

		if (partnerRole && jobTitle) {
			var formattedDescription = '[' + jobTitle + '] ' + description

			this.model.set('description', formattedDescription)
		}

		this.$('.save-button').removeClass(HIDDEN_CLASS)
		this.$('.preview-back-button').removeClass(HIDDEN_CLASS)
		this.$('.preview-button').addClass(HIDDEN_CLASS)

		this.$('.edit-form').addClass(HIDDEN_CLASS)
		this.$('.alert-preview').removeClass(HIDDEN_CLASS)

		this.$('.alert-preview-text').text(description)

		if (partnerRole && jobTitle) {
			var text = description + ' — sent by ' + jobTitle

			this.$('.alert-preview-text').text(text)
		}

		// Render page preview, if applicable.
		if (this.model.get('page')) {
			App.startLoad()

			if (!this.views.canvas) {
				this.views.canvas = new CanvasView({app: this.app})
			}

			this.$('.canvas-area').html(this.views.canvas.el)

			// Fetch page to be viewed.
			var pageId = App.getIdFromCacheUrl(this.model.get('page')),
				model = new Page({id: pageId})

			model.once('sync', function() {
				this.views.canvas.setPage(model, true)
				App.stopLoad()
			}, this)

			model.fetch()
		} else {
			this.$('.canvas-area').empty()
		}
	},

	save: function() {
		App.startLoad()
		this.validateArea()
		this.$('.save-button').attr('disabled', true)
		this.model.save(null, {suppressErrors: [400]})
			.then(this.alertSaved.bind(this), this.alertSaveError.bind(this))
	},

	back: function() {
		this.$('.save-button').addClass(HIDDEN_CLASS)
		this.$('.preview-back-button').addClass(HIDDEN_CLASS)
		this.$('.preview-button').removeClass(HIDDEN_CLASS)

		this.$('.edit-form').removeClass(HIDDEN_CLASS)
		this.$('.alert-preview').addClass(HIDDEN_CLASS)
	},

	alertSaved: function() {
		App.router.navigate('/apps/' + this.app.id + '/general-notifications', {trigger: true})
		App.showToast($.t('hazards.alerts.custom.alertQueued'))
	},

	alertSaveError: function(xhr) {
		var error = $.t('error.generic')

		try {
			var data = JSON.parse(xhr.responseText)

			if (data['client error'] && data['client error'].data) {
				error = data['client error'].data
			}
		} catch (e) {}

		App.stopLoad()
		swal($.t('error.oops'), error, 'error')
		this.$('.save-button').removeAttr('disabled')
	},

	validateArea: function() {
		var area = this.model.get('area')
		// Do we have the right depth?
		if (!area.polygon.coordinates[0][0][0]) {
			// Depth is incorrect. Add an extra level.
			// console.error('Polygon data is too shallow. Fixing.')
			area.polygon.coordinates = [area.polygon.coordinates]
			this.model.set('area', area)
		}
	},

	capToggle: function() {
		this.$('.cap').toggle()
		if (this.cap) {
			this.cap = false
		} else {
			this.cap = true
		}
	},

	polyDataToggle: function() {
		this.$('#polywrapper').toggle()
	}
})
