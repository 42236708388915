var StandaloneStormObject = require('editor/standalone-storm-object'),
	LinkSelector = require('editor/inspector/link-selector'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EditorSectionView = require('editor/editor-section-view'),
	utils = require('lib/utils')

/**
 * Exports {@link BadgeEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends ProductEditView.prototype */{
	/** @override */
	className: 'products',
	/** @override */
	template: require('./product-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage',
		'click input[type=checkbox]': 'enableDisableFields'
	},

	/**
	 * @constructs BadgeEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var localePromise = this.app_.localeList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('Product')
		this.listenTo(this.model, 'change:product', this.updateProduct, this)

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, localePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.app_.id
		data.locales = this.app_.localeList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()

		// Show current input values
		_.each(this.model.get('name..content'), function(val, key) {
			this.$('.title-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('description..content'), function(val, key) {
			this.$('.description-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('priceText..content'), function(val, key) {
			this.$('.priceText-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('cta..title..content'), function(val, key) {
			this.$('.cta-input[data-code=' + key + ']').val(val)
		})

		this.renderLinkPicker()
		this.setupDatePickers()
	},

	setupDatePickers: function() {
		this.datepickers = {};
		// Initialise datepicker
		['startTime', 'endTime'].forEach(function(value) {
			var datepicker = new Pikaday({
				field: this.$('#date-' + value)[0],
				bound: true,
				showTime: true
			})

			var date = new Date(),
				timestamp = this.model.get(value)
			if (timestamp !== undefined && timestamp !== '') {
				date = new Date(timestamp)
				$('#checkbox-' + value).prop('checked', true)
			} else {
				$('#date-' + value).prop('disabled', true)
				$('#checkbox-' + value).prop('checked', false)
			}

			datepicker.setDate(date)
			this.datepickers[value] = datepicker
		}, this)
	},

	renderLinkPicker: function() {
		var lockToken = null

		if (this.model.lock) {
			lockToken = this.model.lock.get('token')
			this.linkPicker = new LinkSelector({
				link: this.model.get('cta..link'),
				titleDisabled: true,
				pageList: Storm.app.pageList,
				appId: this.appId,
				lockToken: lockToken
			})
			// Show link picker
			$('.link-picker').html(this.linkPicker.render().el)
		}
	},

	enableDisableFields: function() {
		['startTime', 'endTime'].forEach(function(value) {
			var enabled = $('#checkbox-' + value).is(':checked')
			$('#date-' + value).prop('disabled', !enabled)
		}, this)
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/products-shop', {trigger: true})
		}.bind(this))

		// Get all new input values
		var names = {},
			ctas = {},
			descriptions = {},
			priceTexts = {},
			price = parseFloat(this.$('#price').val()),
			analyticsCode = this.$('#analyticsCode').val()

		for (var propertyName in this.datepickers) {
			if (this.datepickers[propertyName]) {
				var value = moment(this.datepickers[propertyName].getDate()).format()
				var enabled = $('#checkbox-' + propertyName).is(':checked')
				if (!enabled) {
					value = ""
				}
				this.model.set(propertyName, value)
			}
		}

		this.$('.title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				names[code] = value
			}
		})

		this.$('.description-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			descriptions[code] = value
		})

		this.$('.priceText-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			priceTexts[code] = value
		})

		this.$('.cta-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				ctas[code] = value
			}
		})

		const descriptionsAreEmpty = Object.values(descriptions).every(utils.isStringEmpty)

		const priceTextsAreEmpty = Object.values(priceTexts).every(utils.isStringEmpty)

		if (descriptionsAreEmpty) {
			this.model.set('description', null)
		} else {
			this.model.set('description..content', descriptions)
		}

		if (priceTextsAreEmpty) {
			this.model.set('priceText', null)
		} else {
			this.model.set('priceText..content', priceTexts)
		}

		this.model.set('name..content', names)
		this.model.set('cta..title..content', ctas)
		this.model.set('price', price)
		this.model.set('analyticsCode', analyticsCode)

		this.model.save(null, {appId: this.app_.id})
		return false
	},

	chooseImage: function() {
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: this.model.get('image'),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:product', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateProduct: function() {
		// Support legacy image format.
		var image = this.model.get('image')

		if (!image.src) {
			image = image.toJSON()
		}

		this.$('img').attr('src', utils.getImagePreviewUrl(image))
	}

})
