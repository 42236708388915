<div class="col-sm-1">
	<button class="button white upload-back-button form-control" data-i18n="common.back"></button>
</div>
<div class="col-sm-8">
	<div class="preview-area"></div>
	<div class="crop-area"></div>
</div>
<div class="col-sm-3">
	<div class="upload-edit">
		<h3 data-i18n="mediaLibrary.editMedia"></h3>
		<div class="upload-media-type-group">
			<label for="upload-media-type" data-i18n="mediaLibrary.type"></label>
			<select id="upload-media-type" class="form-control">
				{{#each mediaTypes}}
					<option value="{{id}}">{{name}}</option>
				{{/each}}
			</select>
		</div>
		<label for="upload-tags" data-i18n="mediaLibrary.upload.tags"></label>
		<input type="text" id="upload-tags" class="form-control">
		<div class="hint">{{t "mediaLibrary.commas"}}</div>
		<div class="upload-crop-type-group">
			<label data-i18n="mediaLibrary.upload.cropType"></label>
			<div class="crop-buttons">
				<div class="crop-type" data-type="none" data-ratio="0">
					<div class="crop-button"></div>
					<p data-i18n="mediaLibrary.upload.noCrop"></p>
				</div>

				{{#if isArcFA}}
					<div class="crop-type" data-type="spotlightslim" data-ratio="0.403030303030303">
						<div class="crop-button"></div>
						<p data-i18n="viewPicker.spotlightSlim"></p>
					</div>
					<div class="crop-type" data-type="spotlight" data-ratio="0.5">
						<div class="crop-button"></div>
						<p data-i18n="viewPicker.spotlight"></p>
					</div>
				{{else}}
					<div class="crop-type" data-type="spotlight" data-ratio="0.5">
						<div class="crop-button"></div>
						<p data-i18n="viewPicker.spotlight"></p>
					</div>
				{{/if}}

				<div class="crop-type" data-type="header" data-ratio="0.8875">
					<div class="crop-button"></div>
					<p data-i18n="editor.inspector.properties.header.title"></p>
				</div>
				<div class="crop-type" data-type="square" data-ratio="1">
					<div class="crop-button"></div>
					<p data-i18n="mediaLibrary.upload.square"></p>
				</div>
			</div>
		</div>
		<div class="crop-description">
			<p data-type="spotlight" data-generic data-i18n="[html]mediaLibrary.upload.spotlightDesc"></p>
			<p data-type="spotlight" data-gdpc data-i18n="[html]mediaLibrary.upload.gdpcSpotlightDesc"></p>
			<p data-type="spotlightslim" data-arcfa data-i18n="[html]mediaLibrary.upload.arcFASpotlightSlimDesc"></p>
			<p data-type="spotlight" data-arcfa data-i18n="[html]mediaLibrary.upload.arcFASpotlightDesc"></p>
			<p data-type="header" data-generic data-i18n="[html]mediaLibrary.upload.headerDesc"></p>
			<p data-type="header" data-gdpc data-i18n="[html]mediaLibrary.upload.gdpcHeaderDesc"></p>
			<p data-type="square" data-i18n="[html]mediaLibrary.upload.squareDesc"></p>
		</div>
		<div class="upload-transparent-group checkbox">
			<label>
				<input type="checkbox" id="upload-transparent"> {{t "mediaLibrary.upload.requiresTransparency"}}
			</label>
		</div>
		<div class="upload-control">
			<button class="button form-control finish-upload-button" data-i18n="common.upload"></button>
			<progress class="hidden" max="100"></progress>
		</div>
		<p class="user-agreement-disclaimer">
			<span data-i18n="mediaLibrary.upload.agreementText"></span>&nbsp;
			<a href="/GDPC-UAP-License-Agreement.pdf"
				 class="first-aid-agreement"
				 target="_blank"
				 data-bypass>
				{{t "agreement.title"}}
			</a>
			<a href="/Hazard-app-agreement-CMS.pdf"
				 class="hazards-agreement hidden"
				 target="_blank"
				 data-bypass>
				{{t "agreement.hazardsTitle"}}
			</a>
		</p>
	</div>
</div>

<input class="file-upload" type="file" name="files" data-url="{{url}}" accept="image/*,video/*" multiple>
