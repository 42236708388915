<form class="js-add-new-category">
	<div class="row">
		<div class="col-xs-12">
			<div class="row">
				<div class="col-sm-6">
					<h3 data-i18n="r4b.categories.editCategory"></h3>
				</div>
				<div class="col-sm-6 actions-col">
					<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
					<button class="button green wide js-save">{{t "common.save"}}</button>
				</div>
			</div>
		</div>
		<div class="col-xs-12">
			<label>{{t "r4b.categories.order"}} <input required type="number" class="js-order-input form-control" min="0" value="{{order}}" /></label>
		</div>
		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" data-i18n="r4b.categories.categoryName"></label>
			</div>
			{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<label class="input-group-addon" for="title-{{code}}">{{language.name.native}} ({{toUpperCase
						code}})</label>
					<input type="text" id="title-{{code}}" class="js-text-input form-control" data-code="{{code}}"
						data-prop="name" value="{{getTextForLang @root.name code}}" required>
				</div>
			</div>
			{{/each}}
		</div>
		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" data-i18n="r4b.categories.categoryDescription"></label>
			</div>
			{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<label class="input-group-addon" for="desc-{{code}}">{{language.name.native}} ({{toUpperCase
						code}})</label>
					<input type="text" id="desc-{{code}}" class="js-text-input form-control" data-prop="description"
						data-code="{{code}}" value="{{getTextForLang @root.name code}}" required>
				</div>
			</div>
			{{/each}}
		</div>
	</div>
</form>