<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="products.editProduct"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

	<div class="col-xs-12">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.product.image.title"}}</label>
		</div>
		<div class="form-group">
			<img class="product-image-preview" src="{{getPreviewUrl image}}">
		</div>
		<div class="form-group">
			<button type="button" class="button choose-image-button">{{t "editor.inspector.properties.image.chooseButton"}}</button>
		</div>
	</div>

	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<div class="form-group">
					<div class="link-picker form-group">

					</div>
				</div>
			</div>
		</div>
	</div>


	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.name.title"}}</label>
		</div>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<input type="text" class="title-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>


		<div class="col-sm-4">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.description"}}</label>
			</div>
			{{#each locales}}
				<div class="form-group">
					<div class="input-group">
						<span class="input-group-addon">{{language.description.native}} ({{toUpperCase code}})</span>
						<input type="text" class="description-input form-control" data-code="{{code}}">
					</div>
				</div>
			{{/each}}
		</div>

	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.product.cta.title"}}</label>
		</div>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<input type="text" class="cta-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	{{#ifExists priceText}}
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<div class="form-group">
					<label class="control-label">{{t "editor.inspector.properties.product.priceText.title"}}</label>
					<p class="help-block" data-i18n="editor.inspector.properties.product.priceText.description"></p>
				</div>
				{{#each locales}}
					<div class="form-group">
						<div class="input-group">
							<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
							<input type="text" class="priceText-input form-control" data-code="{{code}}">
						</div>
					</div>
				{{/each}}
			</div>
		</div>
	</div>
	{{/ifExists}}

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label" for="price">{{t "editor.inspector.properties.product.price.title"}}</label>
		</div>
		<div class="form-group">
			<input type="number" class="share-input form-control" value="{{price}}" id="price">
		</div>
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label" for="analyticsCode">{{t "editor.inspector.properties.product.analyticsCode.title"}}</label>
		</div>
		<div class="form-group">
			<input type="text" class="share-input form-control" value="{{analyticsCode}}" id="analyticsCode">
		</div>
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label"><input type="checkbox" id="checkbox-startTime"/> {{t "editor.inspector.properties.product.startTime.title"}}</label>
		</div>
		<div class="form-group">
			<input type="text" class="share-input form-control" id="date-startTime">
		</div>
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label"><input type="checkbox" id="checkbox-endTime"/> {{t "editor.inspector.properties.product.endTime.title"}}</label>
		</div>
		<div class="form-group">
			<input type="text" class="share-input form-control" id="date-endTime">
		</div>
	</div>

</form>
