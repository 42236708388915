module.exports = Backbone.Model.extend({
	urlRoot: function() {
		return App.apiRoot + 'societies'
	},

	validate: function(attrs) {
		if (!(attrs.countryId && attrs.name && attrs.description)) {
			return 'All attributes required'
		}
	}
})
