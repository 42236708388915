<form class="col-sm-offset-1 col-sm-4">
	<legend>{{t "organization.details"}}</legend>

	<div class="form-group">
		<label for="organization-name">{{t "users.name"}}</label>
		<input type="text" id="organization-name" class="form-control" value="{{name}}" required>
	</div>

	<div class="form-group">
		<label for="organization-description">{{t "common.description"}}</label>
		<input type="text" id="organization-description" class="form-control" value="{{description}}">
	</div>

	<div class="form-group">
		<label for="organization-locale">{{t "gdpcConsole.nationalSocieties.locale"}}</label>
		<select id="organization-locale" class="form-control select2">
			{{#each countries}}
				<option value="{{id}}" {{#ifEqual id ../countryId}}selected="selected"{{/ifEqual}}>{{name}} ({{toUpperCase code.iso3}})</option>
			{{/each}}
		</select>

	</div>

	<div class="form-actions">
		<button type="button" class="button green save-button">{{t "common.save"}}</button>
	</div>
</form>

<form class="col-sm-offset-2 col-sm-4">
	<legend>{{t "appSetup.languages"}}</legend>

	<div class="no-languages panel panel-default">{{t "organization.noLanguages"}}</div>
	<ul class="assigned-languages"></ul>

	<div class="form-group">
		<div class="row">
			<div class="col-xs-9">
				<select id="organization-language" class="form-control select2">
					{{#each languages}}
						<option value="{{id}}">{{name.native}} ({{toUpperCase code.iso3}})</option>
					{{/each}}
				</select>
			</div>
			<button type="button" class="button add-language-button col-xs-3"><i class="icon icon-plus"></i> {{t "common.add"}}</button>
		</div>
	</div>
</form>
