var StormQL = require('models/stormql'),
	EditorSectionView = require('editor/editor-section-view'),
	StandaloneStormObject = require('editor/standalone-storm-object')

module.exports = EditorSectionView.extend({
	className: 'QuizCompletion',
	template: require('./quiz-completion-view-template'),
	activeTabIndex: 4,

	events: {
		'change #page-selector': 'updatePage',
		'click .save': 'requestLockAndSave'
	},

	initialize: function(options) {
		App.startLoad()
		var requests = []
		this.app = App.appList.get(options.appId)
		this.appId = options.appId
		// Fetch Product searches assigned to this app.
		this.quizCompletions = new StormQL(null, {app: this.app})
		var pageListFetch = this.app.pageList.fetchOnce()
		this.model = StandaloneStormObject.fromClassName('QuizCompletion')

		var quizCompletionsFetch = this.quizCompletions.fetch({data: {class: 'QuizCompletion'}})
		requests.push(quizCompletionsFetch)
		requests.push(pageListFetch)

		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		if (this.quizCompletions.length > 0) {
			this.model = this.quizCompletions.models[0]
		}
		App.stopLoad()
		this.render()
	},

	getPageTitle: function() {
		return $.t('quizCompletion.title')
	},

	getRenderData: function() {
		return {
			appId: this.app.id,
			model: this.model.toJSON()
		}
	},

	afterRender: function() {
		var pageList = this.app.pageList
		// Populate page selector
		// Get array of all unique tag names
		var tags = _.filter(pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		})

		var src = this.model.get('destination..src')

		_.each(tags, function(tag) {
			var taggedPages = pageList.where({tag: tag})
			var options = ''

			_.each(taggedPages, function(page) {
				var pageName = ''
				if (page.get('class') === 'NativePage') {
					pageName = 'app://native/pages/' + page.get('name')
				} else {
					pageName = 'cache://pages/' + page.id + '.json'
				}
				var selected = src === pageName ? 'selected' : ''

				options += '<option data-type="' + page.get('class') + '" value="' + pageName + '" ' + selected + ' >' + App.l(page.get('title')) + '</option>'
			})

			this.$('#page-selector').append('<optgroup label="' + tag + '">' + options + '</optgroup>')
		}, this)
	},

	requestLockAndSave: function() {
		if (this.model.get('pageId') > 0) {
			this.$('.save').attr('disabled', true)
			this.model.requestLock(function() {
				this.save()
			}.bind(this))
		} else {
			this.save()
		}
	},

	save: function() {
		App.startLoad()

		this.model.once('sync', function() {
			if (this.model.get('pageId') > 0) {
				this.model.requestUnlock()
			}
			this.$('.save').attr('disabled', false)
		}.bind(this))

		this.model.save(null, {appId: this.app.id}).then(function() {
			App.stopLoad()
		})
		return false
	},

	updatePage: function() {
		var type = this.$('#page-selector').find(":selected").data('type')

		if (!type) {
			type = ""
		}

		this.model.set('destination..src', this.$('#page-selector')[0].value)
		this.model.set('destination..type', type)
		this.$('.save').attr('disabled', false)
	}
})
