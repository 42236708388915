var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view')

var SearchTermListView = ListView.extend({
	template: require('./search-terms-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./search-terms-list-item-view')
})

/**
 * Exports {@link SearchTermsView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends SearchTermsView.prototype */{
	/** @override */
	className: 'SearchTerms',
	/** @override */
	template: require('./search-terms-view-template'),

	events: {
		'click .js-import': 'onImportClick',
		'change .js-import-input': 'onFileChange'
	},

	/**
	 * @constructs SearchTermsView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()
		this.app = App.appList.get(options.appId)

		var pageFetch = this.app.pageList.fetch()

		this.app.pageList.once('sync', this.render, this)

		Promise.all([pageFetch]).then(this.loadViews.bind(this))
	},

	loadViews: function() {
		this.views = {
			list: new SearchTermListView({
				fetchData: {data: {class: 'PageSearchTerms'}},
				app: this.app
			})
		}

		this.views.list.on('ready', this.listFetched.bind(this))
		this.render()
	},

	listFetched: function() {
		if (this.views.list.collection.length === 0) {
			$('#noItems').show()
			$('table').hide()
		} else {
			$('#noItems').hide()
			$('table').show()
		}
	},

	/** @override */
	getPageTitle: function() {
		return $.t('searchTerms.searchTerms')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	onFileChange: function(e) {
		var importButton = $('.js-import')
		importButton.prop("disabled", true)

		this.isSuccess = false
		const input = e.currentTarget

		if (input.files && input.files[0]) {
			var self = this
			swal({
				title: $.t('editor.inspector.areYouSure'),
				text: $.t('searchTerms.import.confirmation', {name: input.files[0].name}),
				showCancelButton: true
			}, function(didConfirm) {
				if (didConfirm) {
					self.importTranslation(input.files[0])
					.then(function() {
						// Import success
						App.stopLoad()
						self.loadViews()
					})
					.fail(function(err) {
						$('.js-import-input').val("")
						importButton.prop("disabled", false)
						App.stopLoad()
						self.translationError(err)
					})
				} else {
					// clear input
					$('.js-import-input').val("")
					importButton.prop("disabled", false)
				}
			})
		}
	},

	translationError: function(err) {
		var error = ''
		if (err.responseJSON && err.responseJSON['client error'] && err.responseJSON['client error'].data) {
			error = err.responseJSON['client error'].data
		}
		swal($.t('error.oops'), $.t('searchTerms.import.error', {error: error}), 'error')
	},

	onImportClick: function() {
		$('.js-import-input').trigger('click')
	},

	importTranslation: function(file) {
		var formData = new FormData()

		formData.append('import', file)
		formData.append('appId', this.app.id)

		App.startLoad()
		return $.ajax({
			url: App.apiRoot + 'searchTerms/import',
			data: formData,
			type: 'POST',
			contentType: false,
			processData: false,
			global: false,
			headers: App.session.getHeadersObject(),
			complete: App.stopLoad
		})
	}
})
