
<div class="col-sm-12">
    <fieldset>
      <h4 data-i18n="crossBorders.links"></h4>
	  <table class="table-striped">
      <thead>
        <tr>
          <th>{{t "crossBorders.link.destination"}}</th>
          <th>{{t "crossBorders.link.title"}}</th>
          <th></th>
        </tr>
      </thead>
		<tbody>
      {{#each links}}
        <tr>
            <td class="center">{{this.pageTitle}}</td>
            <td>
              <dl>
            		{{#key_value title.content "lang" "text"}}
                        <dt>{{lang}}</dt>
                        <dd>{{text}}</dd>
            		{{/key_value}}
              </dl>
            </td>
            <td style="width:1px">
                <a href class="remove-row" data-id="{{this.id}}"><i class="icon-remove"></i></a>
            </td>
        </tr>
      {{/each}}
		<tr class="new-trans-row">
      <td>
        <select class="form-control new-link-destination">
          {{{pages}}}
        </select>
			</td>
      <td style="width: 60%;" class="titles-col">
        {{#each locales}}
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
              <input type="text" class="form-control" data-code="{{code}}">
            </div>
          </div>
        {{/each}}
      </td>
			<td style="width:1px">
				<a href class="add-row"><i class="icon-ok"></i></a>
			</td>
		</tr>
		</tbody>
	</table>
</fieldset>
</div>
