<form>
	<div class="row">
		<div class="col-sm-6">
			<h3 data-i18n="findCourses.editCourse"></h3>
		</div>
		<div class="col-sm-6 actions-col">
			<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
			<button class="button green wide save" type="submit">{{t "common.save"}}</button>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.image.title"}}</label>
			</div>
			<div class="form-group">
				<img class="course-image-preview" src="{{getPreviewUrl image}}">
			</div>
			<div class="form-group">
				<button type="button" class="button choose-image-button mb-10">{{t "editor.inspector.properties.image.chooseButton"}}</button>
			</div>
		</div>
	</div>

	<div class="row">
			<div class="col-sm-6">
				<div class="form-group">
					<label class="control-label">{{t "editor.inspector.properties.link.title"}}</label>
				</div>
				<div class="link-picker form-group"></div>
			</div>
		</div>

	<div class="row">
		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.title.title"}}</label>
			</div>
			{{#each locales}}
				<div class="form-group">
					<div class="input-group">
						<label for="title-{{code}}" class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</label>
						<input type="text" class="title-input form-control" data-code="{{code}}"  id="title-{{code}}" />
					</div>
				</div>
			{{/each}}
		</div>


			<div class="col-sm-6">
				<div class="form-group">
					<label class="control-label">{{t "editor.inspector.properties.subtitle.title"}}</label>
				</div>
				{{#each locales}}
					<div class="form-group">
						<div class="input-group">
							<label for="subtitle-{{code}}" class="input-group-addon">{{language.description.native}} ({{toUpperCase code}})</label>
							<input type="text" class="subtitle-input form-control" data-code="{{code}}" id="subtitle-{{code}}"/>
						</div>
					</div>
				{{/each}}
			</div>
	</div>
</form>
