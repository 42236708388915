<div class="add-view add-form zero-height row">
	<div class="col-xs-12">
		<div class="confirmation-form">
			<div class="col-sm-6">
				<label class="textarea" style="width:100%">
					<p data-i18n="publish.comment"></p>
					<textarea class="form-control comment"></textarea>
				</label>
				<label class="checkbox developer-mode confirm-landmark-label">
					<input type="checkbox" class="confirm-landmark">&nbsp;<span
					data-i18n="publish.publishLandmark"></span>
				</label>
				<label class="checkbox">
					<input type="checkbox" class="confirm-checkbox">&nbsp;<span
					data-i18n="publish.publishConfirm"></span>
				</label>
				<div class="form-actions publish-action-buttons">
					<button class="button green publish-confirm"
									data-i18n="publish.toApps"></button>
					<button class="button white cancel"
									data-i18n="common.cancel"></button>
				</div>
			</div>
		</div>
	</div>
</div>
{{!--  end add view  --}}
<div class="row too-long" style="display:none;">
	<div class="col-xs-12">
		<div class="alert alert-info fade in" role="alert" data-i18n="publish.progress3"></div>
	</div>
</div>

<div class="audit-view">
	<div class="alert alert-danger preview-show">
		<h2 data-i18n="common.warning"></h2>
		<p data-i18n="[html]publish.warning1"></p>
		<p data-i18n="publish.warning2"></p>
	</div>

	<div class="preview-show">
		<div class="confirmation-form">
			<label class="textarea">
				<p data-i18n="publish.comment"></p>
				<textarea class="form-control comment"></textarea>
			</label>
			<label class="checkbox">
				<input type="checkbox" class="confirm-checkbox">&nbsp;<span
				data-i18n="publish.publishConfirm"></span>
			</label>
			<div class="form-actions">
				<button class="button positive publish-confirm"
								data-i18n="publish.toApps"></button>
				<button class="button negative cancel"
								data-i18n="common.cancel"></button>
			</div>
		</div>
	</div>
</div>
<div class="loading-view">
	<div class="alert alert-block">
		<h2 data-i18n="publish.publishing"></h2>
		<p data-i18n="publish.progress1"></p>
		<p data-i18n="publish.progress2"></p>
		<i class="loader icon-refresh"></i>
	</div>
</div>

<div class="publish-changes-box-collection-view row">

    <div class="col-xs-12 text-right" style="margin-top: 10px;">
        <label><input id="toggle-published-to-test" type="checkbox" /> Show "Published to Test"</label>
				<label id="label-auto-refresh" style="display: none;"><input id="toggle-auto-refresh" type="checkbox" checked/> Auto Refresh</label>
    </div>

    <div class="publish-changes-box-view col-md-3">
        <div class="clearfix">
            <h3 class="pull-left">Recent Changes</h3>
            <button class="button green pull-right publish-button publish-button-test" data-i18n="publish.title"></button>
        </div>
        <div class="publish-list-items">
            <button class="button select-all publish-button-select-all" data-i18n="publish.selectAll" data-type="test"></button>
            {{#each audits}}{{#if unpublished.length}}<legend>{{pluginName}}</legend><table class="unpublished" id="{{codeName}}-unpublished"></table>{{/if}}{{/each}}
            <p class="no-changes text-center" data-i18n="publish.noChanges"></p>
        </div>
    </div>

    <div class="publish-changes-box-view col-md-3">
        <div class="clearfix">
            <h3 class="pull-left">Changes in Test</h3>
            <button class="button green pull-right publish-button publish-button-live" data-i18n="publish.title"></button>
        </div>
        <div class="publish-list-items">
            <button class="button select-all publish-button-select-all" data-i18n="publish.selectAll" data-type="live"></button>
            <table id="publishing-to-test"></table>
						{{#each audits}}{{#if publishToTest.length}}<legend>{{pluginName}}</legend><table class="published-in-test" id="{{codeName}}-in-test"></table>{{/if}}{{/each}}
            <table id="pages-in-test"></table>
            <p class="no-changes text-center" data-i18n="publish.noChanges"></p>
        </div>
    </div>

    <div class="publish-changes-box-view published-to-test col-md-3">
        <div class="clearfix">
            <h3 class="pull-left">Published to Test</h3>
        </div>
        <div class="publish-list-items">
            <table id="published-to-test"></table>
        </div>
    </div>

    <div class="publish-changes-box-view col-md-3">
        <div class="clearfix">
            <h3 class="pull-left">Published Live</h3>
        </div>
        <div class="publish-list-items">
            <table id="published-to-live"></table>
        </div>
    </div>
</div>
