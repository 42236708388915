<h5 data-i18n="editor.inspector.properties.image.title">Image</h5>
<span class="actions">
	<button class="btn-link up"><i class="icon-arrow-up"></i></button>
	<button class="btn-link down"><i class="icon-arrow-down"></i></button>
	<button class="btn-link remove"><i class="icon-remove"></i></button>
</span>
	<div class="span4">
		<a href="{{getDownloadUrl src.x1}}" target="_blank" download><img src="{{getPreviewUrl src}}"></a>
	</div>

	<!-- ARC only -->
	{{#ifEqual systemId 9}}
		<div class="span1">
			<h6 data-i18n="editor.inspector.properties.accessibilityLabel"></h6>
		</div>
		<div class="span3">
			<input type="text" class="form-control accessibility-label text" value="{{getLocaleText accessibilityLabel}}" data-field="accessibilityLabel">
		</div>

		<div class="span1">
			<h6 data-i18n="editor.inspector.properties.title.title"></h6>
		</div>
		<div class="span3">
			<input type="text" class="form-control title-label text" value="{{getLocaleText title}}" data-field="title">
		</div>

		<div class="span1">
			<h6 data-i18n="editor.inspector.properties.category"></h6>
		</div>
		<div class="span3">
			<input type="text" class="form-control category-label text" value="{{getLocaleText category}}" data-field="category">
		</div>

		<div class="span1">
			<h6 data-i18n="editor.inspector.properties.description.title"></h6>
		</div>
		<div class="span3">
			<input type="text" class="form-control description-label text" value="{{getLocaleText description}}" data-field="description">
		</div>
	{{/ifEqual}}

	{{#if text}}
		<div class="span1">
			<h6 data-i18n="editor.inspector.properties.caption"></h6>
		</div>
		<div class="span3">
			<input type="text" class="form-control text" value="{{getLocaleText text}}" data-field="text">
		</div>
	{{/if}}

	<div class="span1">
		<h6 data-i18n="editor.inspector.properties.delay"></h6>
	</div>

	<div class="span3">
		<input type="number" class="form-control delay" min="1" value="{{divide1000 delay}}">
	</div>
	<hr>
