var AuditList         = require('./audit-list'),
	AuditListItemView = require('./audit-list-item-view')

/**
 * Exports {@link PublishHistoryListItemView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends PublishHistoryListItemView */{
	/** @override */
	template: require('./publish-history-list-item-view-template'),
	/** @override */
	tagName: 'tr',
	/** @override */
	className: 'history-list-item',

	/** @override */
	events: {
		'click .pages-link': 'showPages'
	},

	/**
	 * @constructs PublishHistoryListItemView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		if (!options.pageList) {
			throw new Error('No page list specified')
		}

		if (!options.userList) {
			throw new Error('No user list specified')
		}

		this.listViews = []

		// If being used as a combination history/audit item, check for all
		// required properties.
		if (options.appAudit) {
			if (options.area === undefined) {
				throw new Error('No publish area specified')
			}

			if (options.appId === undefined) {
				throw new Error('No appId specified')
			}
		}

		/** @private {PageList} */
		this.pageList_ = options.pageList
		/** @private {UserList} */
		this.userList_ = options.userList
		/** @private {AppAudit} */
		this.appAudit_ = options.appAudit
		/** @private {number} */
		this.publishArea_ = options.area
		/** @private {number} */
		this.appId_ = options.appId
		/** @private {PluginList} */
		this.pluginList_ = options.pluginList
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON(),
			user = this.userList_.get(data.userId),
			name = ''

		if (user) {
			name = user.get('firstName') + ' ' + user.get('lastName')
		}

		data.user = name

		return data
	},

	/** @override */
	afterRender: function() {
		this.$el.addClass(this.model.get('type'))
		this.updateTimestamp_()
		this.renderDetail_()
	},

	/**
	 * Renders out list of page names or audit items, as appropriate.
	 * @private
	 */
	renderDetail_: function() {
		if (this.appAudit_) {
			this.renderPageAudits_()
		}
	},

	/**
	 * Renders out the list of page names in a publish history item with no
	 * pending audits.
	 * @private
	 */
	renderPageNames_: function() {
		var changedIds = []
		App.startLoad()

		this.model.get('pages').forEach(function(pageId) {
			changedIds.push(pageId)
		})
		// Get changed objects here...
		if (changedIds.length) {
			var data = {ids: changedIds}
			$.ajax({
				url: App.apiRoot + 'objects/search',
				type: 'POST',
				data: JSON.stringify(data),
				headers: App.session.getHeadersObject(),
				success: function(msg) {
					this.changedObjects_ = new Backbone.Collection(msg)
					this.appendPageNames()
					this.loaded = true
					App.stopLoad()
				}.bind(this)
			})
		}
	},

	/**
	 * Appends a list of page/object names to the more details list
	 * @private
	 */
	appendPageNames: function() {
		var names = this.model.get('pages').map(function(id) {
			var page = this.pageList_.get(id)

			if (page) {
				return page.get('tag') + ' - ' + App.l(page.get('title'))
			}
			if (this.changedObjects_) {
				var changedObject = this.changedObjects_.get(id)
				if (changedObject) {
					var labelProperty = this.pluginList_.getByClassName(changedObject.get('class')).get('labelProperty')
					var pluginName = this.pluginList_.getByClassName(changedObject.get('class')).get('name')
					var textObject = changedObject.get(labelProperty)
					// If type of object is a string no need to localise it.
					if (typeof textObject === 'string' || typeof textObject === 'number') {
						return pluginName + ' - ' + textObject
					}
					return pluginName + ' - ' + App.l(changedObject.get(labelProperty))
				}
			}

			if (id === 0) {
				return 'Non-page app content'
			}

			return id + ' (page removed)'
		}, this)

		names.forEach(function(name) {
			this.$('.pages-list').append('<div class="page">' + name + '</div>')
		}, this)
	},

	/**
	 * Renders out a list of any pending audits matching any page IDs from this
	 * publish history item, in the specified area.
	 * @private
	 */
	renderPageAudits_: function() {
		var pages          = this.appAudit_.get('pages'),
			app            = this.appAudit_.get('app'),
			appCollection  = new AuditList(app),
			pageCollection = new AuditList(pages),
			publishArea    = this.publishArea_

		var audits = new AuditList()

		this.model.get('pages').forEach(function(pageId) {
			// Add all 'app' content on pageId 0.
			if (pageId === 0) {
				var validAppItems = appCollection.find(function(item) {
					return (item.get('in') & publishArea) !== 0
				})

				audits.add(validAppItems)
				return
			}

			var pageAudits = pageCollection.find(function(item) {
				var isCorrectPage = Number(item.get('pageId')) === pageId,
					isCorrectArea = (item.get('in') & publishArea) !== 0

				return isCorrectPage && isCorrectArea
			})

			audits.add(pageAudits)
		})

		audits.forEach(this.addAuditItem_, this)
	},

	/**
	 * Adds a single audit item to the page.
	 * @param {Backbone.Model} item Model of the item to add.
	 * @private
	 */
	addAuditItem_: function(item) {
		var view = new AuditListItemView({
			model: item,
			area: this.publishArea_,
			appId: this.appId_,
			pageList: this.pageList_
		})

		this.listViews.push(view)
		this.$('.pages-list').append(view.render().el)
	},

	/**
	 *
	 * @private
	 */
	updateTimestamp_: function() {
		var timestamp = this.model.get('timestamp'),
			formattedTime = Handlebars.helpers.formatTime(timestamp)

		this.$('.timestamp').text(formattedTime)

		// Update timestamp at the appropriate time.
		var interval,
			diff = Date.now() / 1000 - timestamp

		if (diff < 60) {
			interval = 1000
		} else if (diff < 60 * 60) {
			interval = 60 * 1000
		} else {
			interval = 60 * 60 * 1000
		}

		this.timestampUpdateTimer = setTimeout(this.updateTimestamp_.bind(this), interval)
	},

	showPages: function() {
		this.$('.pages-list').toggleClass('hidden')
		this.$('.pages-link i').toggleClass('icon-sort-down icon-sort-up')
		if (!this.appAudit_ && !this.$('.pages-list').hasClass('hidden') && !this.loaded) {
			this.renderPageNames_()
		}
	},

	/** @override */
	beforeDestroy: function() {
		clearTimeout(this.timestampUpdateTimer)
	}
})
