<div class="app-error" id="noItems" style="display:none;">{{t "common.noItems"}}</div>
<table>
  <thead>
    <tr>
      <th>{{t "searchTerms.term"}}</th>
      <th>{{t "common.tools"}}</th>
    </tr>
  </thead>
  <tbody class="list-items"></tbody>
</table>

<div class="text-right">
  <button class="button add-term" type="button">{{t "common.add"}}</button>
</div>
