var StandaloneStormObject = require('editor/standalone-storm-object')

// Keys to strip IDs from when we need to create a copy of a model
var keysToStrip = ['attributes',
									'data',
									'src',
									'text',
									'title',
									'startLabel',
									'endLabel',
									'range',
									'options',
									'models',
									'children',
									'links',
									'response',
									'image',
									'name',
									'value',
									'intro',
									'citation',
									'properties',
									'selector',
									'placeholder']

module.exports = {
	/**
	 * [stripIds description]
	 * @param  {Object} object    Object to strip
	 * @param  {Boolean} stripPage Strip page id as well? off by default
	 * @param  {Collection} styles Collection of styles, if you want to copy styles as well...
	 * @param  {Boolean} stripStyles Strip Style id as well? off by default
	 */
	stripIds: function(object, stripPage, styles, stripStyles) {
		// Unset id if object
		if (object instanceof Backbone.Model) {
			// If styles has been specified then we need to check for styling objects too
			if (styles) {
				if (object.get('class') === 'R4b_TextMediaModuleBlock' || object.get('class') === 'R4b_TextAssessmentBlock') {
					var style = this.getStyle(object, styles)
					if (style) {
						// Strip Ids of style object....
						if (stripStyles) {
							this.stripIds(style, true, null, true)
						}
						object.style = style
						this.styledObjects.push(object)
					}
				}
			}
			object.unset('id')

			if (stripPage) {
				object.unset('pageId')
			}
		}
		if (object.id !== undefined) {
			delete object.id
		}
		if (object.pageId !== undefined && stripPage) {
			delete object.pageId
		}

		_.each(object, function(value, key) {
			if (_.contains(keysToStrip, key)) {
				if (value instanceof Array || value instanceof Backbone.Collection) {
					value.forEach(function(child) {
						this.stripIds(child, stripPage, styles, stripStyles)
					}.bind(this))
				} else {
					this.stripIds(value, stripPage, styles, stripStyles)
				}
			} else {
				return
			}
		}.bind(this))
	},

	/**
	 * Clone and strip ids from a model
	 * @param  {Object} model Model to clone and strip
	 * @param  {Boolean} stripPage Strip page id as well? off by default
	 * @param  {Collection} styles Collection of styles, if you want to copy styles as well...
	 * @param  {Boolean} stripStyles Strip style ids as well? off by default
	 * @returns {model}       Cloned stripped out model
	 */
	copyModel: function(model, stripPage, styles, stripStyles) {
		this.styledObjects = []
		// Create a copy
		var newModel = new StandaloneStormObject()
		var tempModel = model.clone()
		this.stripIds(tempModel, stripPage, styles, stripStyles)
		// Push all attributes from tempModel into newModel so it's a fresh StandaloneStormObject
		_.each(tempModel.attributes, function(value, key) {
			newModel.set(key, value)
		})

		return newModel
	},

	/**
	 * Finds the style if it exists for an object (for object ID only)
	 * @param  {Model} model Model to find style from
	 * @param  {Collection} styles Collection of styles, if you want to copy styles as well...
	 * @returns {Style} Styling object
	 */
	getStyle: function(model, styles) {
		var foundStyle = null
		if (model instanceof Backbone.Model) {
			styles.forEach(function(style) {
				if (style.get('selector').identifier === model.get('id').toString()) {
					foundStyle = style
				}
			})
		}
		return foundStyle
	},

	updateEditable: function(element) {
		var caretPosition = this.getCaretPosition(element)
		var value = this.stripTags(element)
		$(element).html(value)
		this.setCaretPosition(element, caretPosition)
		return value
	},

	stripTags: function(element) {
		// inner Text does not work in firefox so...
		if (element.innerText) {
			return element.innerText
		} else if (element.html) {
			return element.html().replace(/<br>/g, '\n')
		}
		return ''
	},

	getCaretPosition: function(element) {
		// http://stackoverflow.com/questions/16736680/get-caret-position-in-contenteditable-div-including-tags
		var caretOffset = 0
		if (typeof window.getSelection !== "undefined") {
			var range = window.getSelection().getRangeAt(0)
			var preCaretRange = range.cloneRange()
			preCaretRange.selectNodeContents(element)
			preCaretRange.setEnd(range.endContainer, range.endOffset)
			caretOffset = preCaretRange.toString().length
		} else if (typeof document.selection !== "undefined" && document.selection.type !== "Control") {
			var textRange = document.selection.createRange()
			var preCaretTextRange = document.body.createTextRange()
			preCaretTextRange.moveToElementText(element)
			preCaretTextRange.setEndPoint("EndToEnd", textRange)
			caretOffset = preCaretTextRange.text.length
		}
		return caretOffset
	},

	setCaretPosition: function(editable, position) {
		// http://stackoverflow.com/questions/40632975/set-caret-position-in-a-content-editable-element
		var range = document.createRange()
		var sel = window.getSelection()
		try {
			range.setStart(editable.childNodes[0], position)
			range.collapse(true)
			sel.removeAllRanges()
			sel.addRange(range)
			editable.focus()
		} catch (error) {
			// Empty childnode or pasting in unrecognisable stuff..
			return
		}
	}
}
