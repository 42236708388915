{{#ifBorC model.class "R4b_TextMediaModuleBlock" "R4b_TextAssessmentBlock"}}
  <!-- R4b_TextMediaModuleBlock -->
  <i class="icon-align-left"></i>
  <div class="block-content">
    <b><code><i class="icon-lightbulb"></i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.text.title"}}</label>
    {{#if model.id}}
    <!-- Styling -->
    <div class="storm-styling">
      <div class="style tooltip-bottom" data-tooltip="Bold" data-id="{{model.id}}" data-active="{{style.bold}}" data-style="bold">B</div>
      <div class="style tooltip-bottom" data-tooltip="Italic" data-id="{{model.id}}" data-active="{{style.italic}}" data-style="italic">I</div>
      <div class="style tooltip-bottom" data-tooltip="Underlined" data-id="{{model.id}}" data-active="{{style.underline}}" data-style="underline">U</div>
    </div>
    {{/if}}
    <br>
    <span class="text-content enter-text" style="{{getStyle style}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true" {{#if model.id}} data-id="model.id" {{/if}}>
      {{#ifEqual model.class "R4b_TextMediaModuleBlock"}}
        {{getLocaleText model.data.content}}
      {{else}}
        {{getLocaleText model.text.content}}
      {{/ifEqual}}
    </span>
    {{#ifEqual model.class "R4b_TextMediaModuleBlock"}}
      {{#isNotTranslated  model.data.content language}}
        <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
      {{/isNotTranslated}}
    {{else}}
      {{#isNotTranslated  model.text.content language}}
        <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
      {{/isNotTranslated}}
    {{/ifEqual}}

{{else ifBorC model.class "R4b_TextInputModuleBlock" "R4b_InputAssessmentBlock"}}
  <!-- R4b_TextMediaModuleBlock -->
  <i class="icon-pencil"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.textInput.title"}}</label>
    <br><span class="input-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.textInput.help'}}" contenteditable="true">{{getLocaleText model.text.content}}</span>
    {{#isNotTranslated  model.text.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}
    <div style="display: flex; margin-top: 5px;">
      <label id="task-label" for="input-area-{{selectorId}}" style="margin-right: 5px;"> {{t "r4b.modules.blockContent.textInput.multi"}} </label>
        <div class="onoffswitch">
          <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox text-input-area" id="input-area-{{selectorId}}" {{#if model.area}}checked{{/if}}>
          <label class="onoffswitch-label" for="input-area-{{selectorId}}"></label>
        </div>
    </div>
{{else ifEqual model.class "R4b_TaskMediaModuleBlock"}}
<!-- R4b_TextMediaModuleBlock -->
  <i class="icon-ok"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.task.title"}}</label>
    <br><span class="task-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.data.content}}</span>
    {{#isNotTranslated  model.data.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}
{{else ifEqual model.class "R4b_QuoteMediaModuleBlock"}}
<!-- R4b_TextMediaModuleBlock -->
  <i class="icon-quote-left"></i>
  <div class="block-content">
    <b><code><i class="icon-lightbulb"></i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.quote.title"}}</label>
    <br><span class="text-content quote-input enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.data.content}}</span>
    {{#isNotTranslated  model.data.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}
		<br><span class="citation-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.quote.citation'}}" contenteditable="true">{{getLocaleText model.citation.content}}</span>
    {{#isNotTranslated  model.citation.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}

{{else ifEqual model.class "R4b_BreakMediaModuleBlock"}}
  <!-- R4b_BreakMediaModuleBlock -->
  <i class="icon-minus"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <p style="text-align: center;">{{t "r4b.modules.blockContent.break"}}</p>


{{else ifBorC model.class "R4b_LocalisedImageLinkMediaModuleBlock" "R4b_LocalisedLinkMediaModuleBlock"}}
  <!-- R4b_LocalisedImageLinkMediaModuleBlock R4b_LocalisedLinkMediaModuleBlock -->
  <i class="icon-link"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <br>
    <label>{{#ifEqual model.class 'R4b_LocalisedImageLinkMediaModuleBlock'}} {{t "r4b.modules.blockContent.localisedImage.linkTitle"}} {{else}}  {{t "r4b.modules.blockContent.localisedLink.linkTitle"}} {{/ifEqual}}</label>
    <input type="text" class="form-control link-title-content" id="{{selectorId}}-Localised-Title" value="{{getLocaleText model.link.title.content}}">
    {{#isNotTranslated  model.link.title.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}
    <br>
    <label>{{#ifEqual model.class 'R4b_LocalisedImageLinkMediaModuleBlock'}} {{t "r4b.modules.blockContent.localisedImage.destination"}} {{else}}  {{t "r4b.modules.blockContent.localisedLink.destination"}} {{/ifEqual}}</label>
    <input type="text" class="form-control link-src-content" id="{{selectorId}}-Localised-Link" value="{{getLocaleText model.link.destination.content}}">
    {{#isNotTranslated  model.link.destination.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}
    <div class="link-selector" data-id="{{selectorId}}"></div>
    

{{else ifEqual model.class "R4b_VideoAssessmentBlock"}}
  <!-- R4b_VideoMediaModuleBlock -->
  <i class="icon-facetime-video"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.videoLink"}}</label>
    <br><input type="url" class="u-width-100 link-content" placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" value="{{model.data.src.destination}}"></input>

{{else ifBorC model.class "R4b_LinkMediaModuleBlock" "R4b_HelpMediaModuleBlock"}}
  <!-- R4b_LinkMediaModuleBlock -->
  <i class="{{#ifEqual model.class 'R4b_LinkMediaModuleBlock'}}icon-link{{else}}icon-question-sign{{/ifEqual}}"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <br>
    <label>{{#ifEqual model.class "R4b_LinkMediaModuleBlock"}} {{t "r4b.modules.blockContent.link.title"}} {{else}} {{t "r4b.modules.blockContent.help.title"}} {{/ifEqual}}</label>
    {{#ifEqual model.class "R4b_HelpMediaModuleBlock"}}
      <span class="help-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.data.content}}</span>
      {{#isNotTranslated  model.data.content language}}
        <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
      {{/isNotTranslated}}
    {{/ifEqual}}
    <div class="link-selector" data-id="{{selectorId}}"></div>

{{ else ifEqual model.class "R4b_LocalisedAudioLinkMediaModuleBlock"}}
  <!-- R4b_LocalisedAudioLinkMediaModuleBlock -->
  <i class="icon-volume-up"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>

    {{#isTranslated model.link.destination.content language}}
        <audio class="center-align u-d-block mb-10" controls="controls">{{t "r4b.modules.blockContent.audio.notSupported"}}
          <source src="{{getLocaleText model.link.destination.content}}" type="audio/mpeg">
          <source src="{{getLocaleText model.link.destination.content}}" type="audio/ogg">
          <source src="{{getLocaleText model.link.destination.content}}" type="audio/wav">
        </audio>
    {{/isTranslated}}

    <div class="form-group">
      <label for="{{selectorId}}-ListTitle">{{t "r4b.modules.blockContent.audio.title"}}</label>
      <input type="text" class="form-control link-title-content" id="{{selectorId}}-ListTitle"
        value="{{getLocaleText model.link.title.content}}">
    </div>
    <label>{{t "r4b.modules.blockContent.audio.destination"}}</label>
    <br><input type="url" class="u-width-100 link-src-content"
      placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" value="{{getLocaleText model.link.destination.content}}"></input>

{{ else ifEqual model.class "R4b_LocalisedVideoLinkMediaModuleBlock"}}
  <!-- R4b_LocalisedVideoLinkMediaModuleBlock -->
  <i class="icon-facetime-video"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>

    <div class="form-group">
      <label for="{{selectorId}}-ListTitle">{{t "r4b.modules.blockContent.video.title"}}</label>
      <input type="text" class="form-control link-title-content" id="{{selectorId}}-ListTitle"
        value="{{getLocaleText model.link.title.content}}">
    </div>
    <label>{{t "r4b.modules.blockContent.video.destination"}}</label>
    <br><input type="url" class="u-width-100 link-src-content"
      placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
      value="{{getLocaleText model.link.destination.content}}"></input>

{{ else ifEqual model.class "R4b_AnimatedMediaModuleBlock"}}
  <!-- R4b_AnimatedMediaModuleBlock -->
  <i class="icon-double-angle-right"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
      </i> {{model.class}}</code></b>
    <div class="add-block-gif" style="{{getBackgroundImageStyle model.data.images.[0].src 'contain'}}" data-property="data"
      data-id="{{selectorId}}"></div>

{{ else ifEqual model.class "R4b_ListMediaModuleBlock"}}
  <!-- R4b_ListMediaModuleBlock -->
  <i class="icon-list-ul"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <div class="form-group">
      <label for="{{selectorId}}-ListTitle">{{t "editor.inspector.properties.title.title"}}</label>
      <input type="text" class="form-control list-title" id="{{selectorId}}-ListTitle" value="{{getLocaleText model.title.content}}">
    </div>
    {{#isNotTranslated  model.title.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}
    <div class="form-group">
      <label for="{{selectorId}}-ListAdd">
        {{t "r4b.modules.blockContent.list.add"}}
      </label>
      <div class="input-group">
        <input type="text" class="form-control" id="{{selectorId}}-ListAdd">
        <span class="input-group-btn">
          <button class="btn btn-default add-list-item" type="button">{{t "common.add"}}</button>
        </span>
      </div><!-- /input-group -->
    </div>
    <ul>
      {{#each model.data}}
      <li id="{{../selectorId}}-ListItem" data-key="{{@key}}">
        <label><span class="enter-text update-list-item" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true" style="width: auto; margin-bottom: 0;">{{getLocaleText this.content}}</span></label>
        {{#isNotTranslated  this.content ../language}}
          <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
        {{/isNotTranslated}}
        <i data-index="{{@key}}" data-selection="{{../selectorId}}" class="icon-remove remove-list-item red-delete"></i>
      </li>
      {{/each}}
    </ul>


{{else ifBorC model.class "R4b_ImageMediaModuleBlock" "R4b_ImageAssessmentBlock"}}
  <!-- R4b_ImageMediaModuleBlock -->
  <i class="icon-picture"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <div class="add-block-image" style="{{getBackgroundImageStyle model.data.src 'contain'}}" data-property="data" data-id="{{selectorId}}"></div>

{{else ifEqual model.class "R4b_SliderInputModuleBlock"}}
  <!-- R4b_SliderInputModuleBlock -->
  <i class="icon-resize-horizontal"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b><br>
		<label>{{t "r4b.modules.blockContent.text.title"}}</label>
    <br><span class="input-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.text.content}}</span>
    {{#isNotTranslated  model.text.content language}}
      <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
    {{/isNotTranslated}}
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-start">{{t "editor.inspector.properties.range.start"}}</label>
      <input id="{{selectorId}}-start" type="number" class="form-control slider" value="{{model.range.start}}"></input>
      <label for="{{selectorId}}-length">{{t "editor.inspector.properties.range.length"}}</label>
      <input id="{{selectorId}}-length" type="number" class="form-control slider" value="{{model.range.length}}"></input>
    </div>
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-value">{{t "editor.inspector.properties.initialPosition"}}</label>
      <input id="{{selectorId}}-value" type="number" class="form-control slider" value="{{model.value}}"></input>
      <label for="{{selectorId}}-interval">{{t "r4b.modules.blockContent.slider.interval"}}</label>
      <input id="{{selectorId}}-interval" type="number" class="form-control slider" value="{{model.interval}}"></input>
    </div>
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-startLabel">{{t "r4b.modules.blockContent.slider.startLabel"}}</label>
      <input id="{{selectorId}}-startLabel" class="form-control slider" value="{{getLocaleText model.startLabel.content}}"></input>
      {{#isNotTranslated  model.startLabel.content language}}
        <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
      {{/isNotTranslated}}
    </div>
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-endLabel">{{t "r4b.modules.blockContent.slider.endLabel"}}</label>
      <input id="{{selectorId}}-endLabel" class="form-control slider" value="{{getLocaleText model.endLabel.content}}"></input>
      {{#isNotTranslated  model.endLabel.content language}}
        <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
      {{/isNotTranslated}}
    </div>
    <label>{{t "common.preview"}}</label>
    <input id="{{selectorId}}-slider" type="range" disabled></input>

{{else ifBorCorD model.class "R4b_CheckboxInputModuleBlock" "R4b_RadioInputModuleBlock" "R4b_SelectInputModuleBlock"}}
  <!-- R4b_CheckboxInputModuleBlock -->
  {{#ifEqual model.class "R4b_CheckboxInputModuleBlock"}}
    <i class="icon-check"></i>
  {{else ifEqual model.class "R4b_SelectInputModuleBlock"}}
    <i class="icon-align-justify"></i>
  {{else}}
    <i class="icon-circle-blank"></i>
  {{/ifEqual}}
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <div class="form-group">
      <label for="{{selectorId}}-CheckRadioText">{{t "editor.inspector.properties.title.title"}}</label>
      <input type="text" class="form-control checkRadio-title" id="{{selectorId}}-CheckRadioText" value="{{getLocaleText model.text.content}}">
      {{#isNotTranslated  model.text.content language}}
        <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
      {{/isNotTranslated}}
    </div>
    {{#ifEqual model.class "R4b_SelectInputModuleBlock"}}
    <div class="form-group">
      <label for="{{selectorId}}-SelectPlaceHolder">{{t "r4b.placeholder"}}</label>
      <input type="text" class="form-control select-placeholder" id="{{selectorId}}-SelectPlaceHolder" value="{{getLocaleText model.placeholder.content}}">
      {{#isNotTranslated  model.placeholder.content language}}
        <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
      {{/isNotTranslated}}
    </div>
    {{/ifEqual}}
    <div class="form-group">
      <label for="{{selectorId}}-CheckRadioAdd">
        {{#ifEqual model.class "R4b_CheckboxInputModuleBlock"}}
          {{t "r4b.modules.blockContent.checkbox.title"}}
        {{else ifEqual model.class "R4b_SelectInputModuleBlock"}}
          {{t "r4b.modules.blockContent.options"}}
        {{else}}
          {{t "r4b.modules.blockContent.radio.title"}}
        {{/ifEqual}}
      </label>
      <div class="input-group">
        <input type="text" class="form-control" id="{{selectorId}}-CheckRadioAdd">
        <span class="input-group-btn">
          <button class="btn btn-default add-checkbox-radio" type="button">{{t "common.add"}}</button>
        </span>
      </div><!-- /input-group -->
    </div>
    {{#each model.options}}
    <div id="{{../selectorId}}-CheckRadioItem" data-key="{{@key}}">
      {{#ifEqual ../model.class "R4b_CheckboxInputModuleBlock"}}
        <span class="radio-text-index">{{@key}}:</span>
        <input type="checkbox" data-key="{{@key}}" id="{{../selectorId}}-{{@key}}-checkbox">
          {{#ifNotEqual ../identifierArr "0.BP.0"}}
            <label><span class="enter-text update-check-radio" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true" style="width: auto;">{{getLocaleText this.text.content}}</span></label>
          {{else}}
            <label><span class="enter-text update-check-radio" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="false" style="width: auto;">{{getLocaleText this.text.content}}</span></label>
          {{/ifNotEqual}}
        </input>
        {{#isNotTranslated  this.text.content ../language}}
          <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
        {{/isNotTranslated}}
      {{else ifEqual ../model.class "R4b_SelectInputModuleBlock"}}
        <span class="radio-text-index">{{@key}}:</span>
        {{#ifEqualsAnd ../model.identifier "whatcountry" this.id}}
          <label><span class="enter-text update-check-radio" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="false" style="width: auto;">{{getLocaleText this.text.content}}</span></label>
           - <label><span class="enter-text update-meta" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.meta'}}" contenteditable="true" style="width: auto;">{{this.meta}}</span></label>
        {{else}}
          <label><span class="enter-text update-check-radio" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true" style="width: auto;">{{getLocaleText this.text.content}}</span></label>
        {{/ifEqualsAnd}}
        <!-- <label><span class="enter-text update-check-radio" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true" style="width: auto;">{{getLocaleText this.text.content}}</span></label> -->
        {{#isNotTranslated  this.text.content ../language}}
          <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
        {{/isNotTranslated}}
      {{else}}
        <span class="radio-text-index">{{@key}}:</span>
        <input type="radio" data-key="{{@key}}" id="{{../selectorId}}-{{@key}}-checkbox" name="{{../selectorId}}">
        {{#ifEqualsAnd ../model.identifier "whatcountry" this.id}}
        <label><span class="enter-text update-check-radio" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="false" style="width: auto;">{{getLocaleText this.text.content}}</span></label>
        {{else}}
        <label><span class="enter-text update-check-radio" data-index="{{@key}}" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true" style="width: auto;">{{getLocaleText this.text.content}}</span></label>
        {{/ifEqualsAnd}}
        </input>
        {{#isNotTranslated  this.text.content ../language}}
          <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t "r4b.notTranslated"}}</span>
        {{/isNotTranslated}}
      {{/ifEqual}}

      {{#ifEqual ../model.identifier "whatcountry"}}
        {{#ifDoesntExist this.id}}
          <i data-index="{{@key}}" data-selection="{{../selectorId}}" class="icon-remove remove-check-radio red-delete"></i>
        {{/ifDoesntExist}}
      {{else}}
        <i data-index="{{@key}}" data-selection="{{../selectorId}}" class="icon-remove remove-check-radio red-delete"></i>
      {{/ifEqual}}
      <br />
     </div>
    {{/each}}
{{else}}
<!-- Anything else -->
  <i class="icon-lightbulb"></i>
  <div class="block-content">
    <b><code><i class="icon-lightbulb"></i> {{model.class}}</code></b>
{{/ifBorC}}

  <hr>
	<div class="bottom">
		<div class="identifier-form">
			{{#if identifierExists}}
		  <label for="{{selectorId}}-identifier">{{t "r4b.modules.blockContent.identifier"}}</label>
		  <input type="text" class="identifier" id="{{selectorId}}-identifier" placeholder="{{t 'r4b.modules.blockContent.addIdentifier'}}" value="{{model.identifier}}" {{#if model.identifier}} disabled="true" {{/if}}>
			{{/if}}

      <div class="section-pdf" data-selection={{selectorId}}>
        <div class="is-bcp pdf-content mt-10" data-selection={{selectorId}} style="display:none;" >
          <img width="25px" style="margin-bottom:5px;" src="/images/atlas/bear-icon.png"> <b>Atlas Says:</b> <i>"{{t 'r4b.modules.blockContent.managePdfExplainerText'}}" </i></img>

          <p class="is-bold mt-10">{{t 'r4b.modules.blockContent.pdfTitle'}}</p>
          <span class="enter-text js-pdf-title" data-selection={{selectorId}} data-placeholder="{{t 'r4b.modules.blockContent.managePdfTitlePlaceholder'}}" contenteditable="true">{{getLocaleText model.pdf_title.content}}</span>

          <p class="is-bold ">{{t 'r4b.modules.blockContent.pdfSubtitle'}}</p>
          <span class="enter-text js-pdf-subtitle" data-selection={{selectorId}} data-placeholder="{{t 'r4b.modules.blockContent.managePdfSubtitlePlaceholder'}}" contenteditable="true">{{getLocaleText model.pdf_subtitle.content}}</span>

          <p class="is-bold ">{{t 'r4b.modules.blockContent.pdfContent'}}</p>
          <span class="enter-text js-pdf-content" data-selection={{selectorId}} data-placeholder="{{t 'r4b.modules.blockContent.managePdfContentPlaceholder'}}" contenteditable="true">{{getLocaleText model.pdf_content.content}}</span>
        </div>
      </div>
		</div>
    {{#ifBorCorDorEorF model.class "R4b_SliderInputModuleBlock" "R4b_TextInputModuleBlock" "R4b_CheckboxInputModuleBlock" "R4b_RadioInputModuleBlock" "R4b_SelectInputModuleBlock"}}
      <div class="tooltip-left" data-tooltip="{{t 'r4b.modules.blockContent.managePdfBtn'}}">
      	<i data-index="0" data-selection={{selectorId}} class="icon-plus js-pdf-content-btn blue-settings"></i>
      </div>
    {{/ifBorCorDorEorF}}
    <i{{#if model.id}} data-id={{model.id}} {{/if}} data-index={{index}} data-sectionIndex={{sectionIndex}} class="icon-trash delete-block red-delete"></i>
	</div>
</div>
