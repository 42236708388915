	<div class="row">
		<div class="col-xs-12">
			<div class="row">
				<div class="col-sm-6">
					<h3 data-i18n="swim.edit"></h3>
				</div>
				<div class="col-sm-6 actions-col">
					<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
					<button class="button green wide save" type="submit">{{t "common.save"}}</button>
				</div>
			</div>
		</div>

		<div class="col-xs-12">
			<legend>{{t "levels.details"}}</legend>
		</div>

		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" for="level-title">{{t "editor.inspector.properties.title.title"}}</label>
			</div>
			<div class="form-group">
				<input type="text" id="level-title" class="form-control" value="{{model.name}}">
			</div>
		</div>

		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" for="level-description">{{t "common.description"}}</label>
			</div>
			<div class="form-group">
				<textarea type="text" id="level-description" class="form-control">{{model.description}}</textarea>
			</div>
		</div>

		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" for="page-select">{{t "common.page"}}</label>
			</div>
			<div class="form-group">
				<select id="page-select" class="form-control"></select>
			</div>
		</div>

		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" for="badge-select">{{t "editor.inspector.properties.badge.title"}}</label>
			</div>
			<div class="form-group">
				<select id="badge-select" class="form-control">
					{{#each badges}}
						<option value="{{id}}">{{getLocaleText title}}</option>
					{{/each}}
				</select>
			</div>
		</div>

		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" for="position-select">{{t "common.position"}}</label>
			</div>
			<div class="form-group">
				<select id="position-select" class="form-control">
					{{#each levels}}
						<option value="{{position}}">{{t "common.position"}} {{addOne position}}</option>
					{{/each}}
				</select>
			</div>
		</div>
		<div class="col-sm-12">
		{{#if model.id}}
			<legend>{{t "levels.children"}}</legend>

			<table class="table-striped">
				{{#each children}}
					{{#if status}}
						<tr>
							<td>
								<a href="/apps/{{../appId}}/swim-levels/{{id}}">{{name}}</a>
								<button type="button" class="button negative remove-child-button" data-id="{{id}}" title="Remove child"><i class="icon-trash"></i>
							</td>
						</tr>
					{{/if}}
				{{/each}}
			</table>
			<div class="form-actions">
				<a href="/apps/{{appId}}/swim-levels/{{model.id}}/new"><button type="button" class="button">{{t "common.add"}}</button></a>
			</div>
		{{/if}}
		</div>
	</div>
</div>
