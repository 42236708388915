var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view')

var RichStoryListView = ListView.extend({
	template: require('./rich-stories-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./rich-stories-list-item-view')
})

/**
 * Exports {@link RichStoriesView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends RichStoriesView.prototype */{
	/** @override */
	className: 'RichStories',
	/** @override */
	template: require('./rich-stories-view-template'),

	/**
	 * @constructs RichStoriesView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()
		this.app = App.appList.get(options.appId)

		this.views = {
			list: new RichStoryListView({
				fetchData: {data: {class: 'StormQLRichStory'}},
				app: this.app
			})
		}
		this.views.list.on('ready', this.listFetched.bind(this))
	},

	listFetched: function() {
		if (this.views.list.collection.length === 0) {
			$('#noItems').show()
			$('table').hide()
		} else {
			$('#noItems').hide()
			$('table').show()
		}
	},

	/** @override */
	getPageTitle: function() {
		return $.t('richStories.richStories')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	}
})
