<div class="inner">
	<div class="image-backdrop" data-id="{{id}}"></div>
	<span class="image-edit-link" data-id="{{id}}"><i class="icon icon-cog"></i></span>
	<div class="image-tags">
		{{#each tags}}
			<span class="button tag-button"><span>{{name}}</span> <a href class="tag-remove-button" data-id="{{id}}">&times;</a></span>
		{{/each}}
		<button class="button light add-tag-button">{{t "common.add"}}</button>
		<form class="add-tag-form">
			<input type="text" class="add-tag-input">
			<button class="button positive add-tag-save-button">{{t "common.save"}}</button>
		</form>
	</div>
</div>
