var AppTypeList         = require('./app-type-list'),
	LanguageList          = require('editor/language-list'),
	LocaleList            = require('editor/locale-list'),
	ViewPicker            = require('editor/view-picker'),
	StormObject           = require('editor/storm-object'),
	Page                  = require('editor/page-list/page'),
	AppStrings            = require('./app-strings'),
	PageList              = require('editor/page-list/page-list'),
	StormQL               = require('models/stormql'),
	PluginList            = require('editor/plugin-list'),
	AppModel              = require('editor/app'),
	ManageAppsSectionView = require('manage-apps/manage-apps-section-view'),
	/** @type {StormGlobals} */
	globals               = require('globals')

var LegacyAppCreateView = ManageAppsSectionView.extend(/** @lends LegacyAppCreateView.prototype */{
	/** @override */
	template: require('./app-create-view-template'),

	/** @override */
	events: {
		'click .save-button': 'saveApp',
		'change #app-society': 'societyChange',
		'change #app-template': 'appTemplateChange',
		'change #app-language': 'appLanguageChange'
	},

	/**
	 * @constructs LegacyAppCreateView
	 * @extends ManageAppsSectionView
	 * @deprecated
	 * @override
	 */
	initialize: function() {
		ManageAppsSectionView.prototype.initialize.apply(this, arguments)

		this._readyCount = 0
		this._totalReadyCount = 2

		this.societyList = App.societiesList
		this.appList = App.appList

		// Fetch list of available app types.
		this.appTypes = new AppTypeList()
		this.appTypes.once('sync', this.ready, this)
		this.appTypes.fetch()

		// Fetch list of available languages.
		this.languages = new LanguageList()
		this.languages.once('sync', this.ready, this)
		this.languages.fetch()
	},

	getRenderData: function() {
		var societyList = this.societyList.sortBy(function(society) {
			return society.get('locale')
		}).map(function(obj) {
			return obj.toJSON()
		})

		return {
			societyList: societyList,
			appSelect: App.generateAppSelect(),
			appTypes: this.appTypes.toJSON(),
			languages: this.languages.toJSON()
		}
	},

	afterRender: function() {
		// Hide all subnav links - we don't have an app selected.
		this.$('#top-nav a').hide()
		// Asign all selects with the select2 plugin
		this.$('select').select2({
			matcher: this.matchCustom
		})
		// Ensure unselected langs get processed
		this.$('#app-language').on("select2:unselect", this.appLanguageChange.bind(this))
	},

	matchCustom: function(params, data) {
		// If there are no search terms, return all of the data
		if ($.trim(params.term) === '') {
			return data
		}

		// Do not display the item if there is no 'text' property
		if (typeof data.text === 'undefined') {
			return null
		}

		// If matches parent or children, return
		if (data.text.toLowerCase().trim().indexOf(params.term.toLowerCase().trim()) > -1) {
			return data
		}

		// Loop through children and see if any of those match
		if (data.children) {
			var children = []
			for (var i = 0; i < data.children.length; i++) {
				if (data.children[i].text.toLowerCase().trim().indexOf(params.term.toLowerCase().trim()) > -1) {
					children.push(data.children[i])
				}
			}
			if (children.length > 0) {
				var modifiedData = $.extend({}, data, true)
				modifiedData.children = children
				return modifiedData
			}
		}

		// Return `null` if the term should not be displayed
		return null
	},

	ready: function() {
		if (++this._readyCount === this._totalReadyCount) {
			this.render()
			App.stopLoad()
		}
	},

	societyChange: function() {
		// Society has changed load in the right languages... (if a new app)
		if (Number(this.$('#app-template').val()) === -1) {
			this.newLanguageList = new LanguageList(null, {societyId: $('#app-society').val()})
			this.newLanguageList.fetch().then(this.updateLanguages.bind(this))
		}
	},

	appTemplateChange: function() {
		var appTemplate = Number(this.$('#app-template').val())
		if (appTemplate === -1) {
			// No app template selected - enable app type selection.
			this.$('#app-type').prop('disabled', false)
			this.$('#app-language').prop('disabled', false)
			this.societyChange() // Fill in new langs with society langs
		} else {
			// Fill in languages available to that app...
			this.newLanguageList = new LocaleList(null, {appId: $('#app-template').val()})
			this.newLanguageList.fetch().then(this.updateLanguages.bind(this))

			var app = App.appList.get(appTemplate)
			this.$('#app-type').prop('disabled', true).val(app.get('type'))
			this.$('#app-language').prop('disabled', false)
		}
	},

	updateLanguages: function() {
		if ($('#app-language').data('select2')) {
			$('#app-language').select2('destroy')
		}
		$('#app-language').empty()
		if (this.newLanguageList) {
			this.newLanguageList.toJSON().forEach(function(lang) {
				var name = ''
				if (Number(this.$('#app-template').val()) === -1) {
					name = lang.name.native + ' (' + lang.code.iso3.toUpperCase() + ')'
				} else {
					name = lang.country ? lang.language.name.native + ' (' + lang.country.code.iso3 + ')' : lang.language.name.native
				}
				$('#app-language').append('<option value="' + lang.id + '">' + name + '</option>')
			})
			$('#app-language').select2()
		}
	},

	appLanguageChange: function() {
		$('#app-langs').empty()
		$('#name-label').hide()
		if (this.$('#app-language').val()) {
			var langIds = this.$('#app-language').val().map(function(str) {
				return Number(str)
			})

			// For each lang append an input for the name!
			$('#name-label').show()
			langIds.forEach(function(id) {
				if (Number(this.$('#app-template').val()) === -1) {
					var lang = this.languages.get(id)
					$('#app-langs').append('<div class="input-group"><span class="input-group-addon">' + lang.get('name').native + ' (' + lang.get('code').iso3.toUpperCase() + ')</span><input type="text" data-code="' + lang.get('code').iso3 + '" class="form-control app-name"></div><hr>')
				} else {
					var locale = this.newLanguageList.get(id).toJSON()
					console.log(locale)
					var	name = locale.country ? locale.language.name.native + ' (' + locale.country.code.iso3 + ')' : locale.language.name.native
					$('#app-langs').append('<div class="input-group"><span class="input-group-addon">' + name + '</span><input type="text" data-code="' + locale.code + '" class="form-control app-name"></div><hr>')
				}
			}.bind(this))
		}
	},

	saveApp: function() {
		$('button').attr('disabled', true)
		App.startLoad()
		this.appData = {
			societyId: Number(this.$('#app-society').val()),
			templateId: Number(this.$('#app-template').val()),
			languageId: this.$('#app-language').val().map(function(str) {
				return Number(str)
			}),
			type: Number(this.$('#app-type').val())
		}

		// Check app name specified.
		var error = false
		$('.app-name').each(function() {
			if ($(this).val() === '') {
				error = true
				$(this).parent().addClass('has-error')
			} else {
				$(this).parent().removeClass('has-error')
			}
		})
		if (error) {
			App.showToast($.t('error.allFields'))
			App.stopLoad()
			return false
		}

		// Create new app.
		if (this.appData.templateId === -1) {
			// Only create app if we're not copying.
			this.app = App.appList.create({
				societyId: this.appData.societyId,
				type: this.appData.type
			})

			this.app.once('sync', this.appCreated, this)
			this.listenToOnce(this.app, 'error', this.copyFailed.bind(this, 'Failed to create new app'))
		} else {
			this.initiateAppCopy()
			return false
		}

		return false
	},

	initiateAppCopy: function() {
		var locales = {}
		// Get locales for app name setiup
		$('.app-name').each(function() {
			var code = $(this).data('code')
			locales[code] = $(this).val()
		})

		var appCopyData = {
			societyId: this.appData.societyId,
			locales: this.appData.languageId,
			name: locales
		}

		var appCopyUrl = App.apiRoot + 'apps/' + this.appData.templateId + '/copy'

		var copyPromise = this.getCopyPromise(appCopyData, appCopyUrl)
		copyPromise.then(function() {
			// Done
			console.log(this.copiedApp)
			// /apps/1/copy/43
			location.href = '/apps/' + this.copiedApp.appId + '/copy/' + this.copiedApp.auditId
		}.bind(this)).catch(function() {
			App.showToast($.t('error.generic'))
		})
	},

	getCopyPromise: function(appCopyData, url) {
		return new Promise(
			function(resolve, reject) {
				$.ajax({
					url: url,
					type: 'POST',
					dataType: 'json',
					contentType: 'application/json; charset=utf-8',
					data: JSON.stringify(appCopyData),
					headers: App.session.getHeadersObject(),

					success: function(data) {
						this.copiedApp = data
						resolve()
					}.bind(this),

					error: function(jqXHR) {
						this.error = jqXHR.status
						reject(jqXHR)
					}.bind(this)
				})
			}.bind(this)
		)
	},

	appCreated: function() {
		var templateId   = this.appData.templateId,
			languageList

		if (templateId > -1) {
			// Legacy App copy has now been removed and the app copy is handled by the backend
		} else {
			// Fetch list of Locales enabled for this app
			languageList = new LocaleList(null, {appId: this.app.id})
			// No template specified. Assign language from dropdown.
			// var language = this.languages.get(this.appData.languageId)
			// language.set('publishable', 3)
			this.appData.languageId.forEach(function(langId) {
				var language = this.languages.get(langId)
				var newLang = {
					language: language.toJSON(),
					publishable: {test: true, live: true}
				}
				languageList.add(newLang)
			}.bind(this))

			var data = {
				languages: languageList.toJSON()
			}

			// Can't copy any content until languages are saved - storing
			// against language keys.
			languageList.save(null, {
				data: JSON.stringify(data)
			}).then(appCopied.bind(this), this.copyFailed.bind(this, 'Failed to set language on new app'))
		}

		function appCopied() {
			// Save app name to all languages.
			languageList.fetch().then(function() {
				var name = {}
				languageList.forEach(function(language) {
					name[language.get('code')] = $('.app-name[data-code=' + language.get('language').code.iso3 + ']').val()
				})
				this.app.set('name', name)

				var appModel = this.app.toJSON()
				appModel.name = name

				$.ajax({
					url: App.apiRoot + 'apps/' + this.app.id,
					type: 'PUT',
					dataType: 'json',
					contentType: 'application/json; charset=utf-8',
					data: JSON.stringify(appModel),
					headers: App.session.getHeadersObject(),

					success: function() {
						this.saveComplete()
					}.bind(this)
				})
			}.bind(this))
		}
	},

	saveComplete: function() {
		App.stopLoad()
		swal({
			title: $.t('login.success.title'),
			text: 'App copy complete',
			type: 'success'
		}, function() {
			// Don't use App.router.navigate here. We've created a new app and therefore should reload the page, which should refresh the appList
			App.startLoad()
			setTimeout(function() {
				window.location.href = '/apps/' + this.app.id + '/settings'
			}.bind(this), 500)
		}.bind(this))
	},

	copyFailed: function(msg) {
		$('button').attr('disabled', false)
		App.stopLoad()
		msg = msg || 'An error occurred'

		swal({
			title: 'App creation failed',
			text: msg,
			type: 'error'
		})
	}
})

LegacyAppCreateView.copyApp = function(fromId, destination) {
	var deferred = new $.Deferred(),
		fromApp  = new AppModel({id: fromId})

	// App template specified - fetch language assignments for the app.
	var languages   = new LanguageList(null, {appId: fromId}),
		strings     = new AppStrings({id: fromId}),
		pageList    = new PageList(null, {appId: fromId}),
		plugins     = new PluginList(null, {appId: fromId})

	// Will be used to map old badge IDs to new IDs
	var badgeIdMap = {}

	var fail = function(message) {
		return deferred.reject.bind(deferred, message)
	}

	var updateProgress = function(message, progress) {
		var html = false

		if (progress !== undefined) {
			html = true

			var $message  = $('<p>').text(message),
				$progress = $('<progress>')
					.attr('min', 0)
					.attr('max', 100)
					.val(progress),
				$title    = $('<div>')
					.append($message)
					.append($progress)

			message = $title.html()
		}

		swal({
			title: $.t('common.pleaseWait'),
			text: message,
			type: 'info',
			closeOnConfirm: false,
			showLoaderOnConfirm: true,
			html: html
		}, function() {
		})
	}

	fetchLanguages()
		.then(copyLanguages, fail('Failed to fetch language list'))
		.then(fetchStrings, fail('Failed to assign languages'))
		.then(copyStrings, fail('Failed to fetch localisations'))
		.then(fetchPlugins, fail('Failed to copy app localisations'))
		.then(copyPlugins, fail('Failed to fetch plugin data'))
		.then(copyPluginContent, fail('Failed to copy plugins'))
		.then(fetchPages, fail('Failed to copy plugin content'))
		.then(copyPages, fail('Failed to fetch page list'))

	function fetchLanguages() {
		updateProgress('Reading language data...')
		return Promise.resolve(languages.fetch())
	}

	function copyLanguages() {
		updateProgress('Copying language data...')
		var data = JSON.stringify({languages: languages.toJSON()})

		// Assign languages on new app.
		var newAppLanguages = new LanguageList(languages.models, {appId: destination.id})
		var appSave = Promise.resolve(newAppLanguages.save(null, {data: data}))

		// Assign languages on organisation.
		var orgLanguages = new LanguageList(languages.models, {societyId: destination.get('societyId')})
		var orgSave = Promise.resolve(orgLanguages.save(null, {data: data}))

		return Promise.all([appSave, orgSave])
	}

	function fetchStrings() {
		updateProgress('Reading localisations...')
		return Promise.resolve(strings.fetch())
	}

	function copyStrings() {
		updateProgress('Copying localisations...')

		// Save strings against new app.
		var data = {
			strings: strings.toJSON()
		}

		strings.set('id', destination.id)
		var save = strings.save(null, {
			data: JSON.stringify(data)
		})

		return Promise.resolve(save)
	}

	function fetchPlugins() {
		updateProgress('Reading plugins...')
		return Promise.resolve(plugins.fetch())
	}

	function copyPlugins() {
		updateProgress('Copying plugins...')

		if (!plugins.length) {
			return Promise.resolve()
		}

		// Save plugins against new app.
		plugins.appId = destination.id
		return Promise.resolve(plugins.save())
	}

	function copyPluginContent() {
		updateProgress('Copying plugin content...')

		var copies = plugins.map(function(plugin) {
			var content       = new StormQL(null, {app: fromApp}),
				className     = plugin.get('className'),
				fetch         = content.fetch({data: {class: className}}),
				completeCount = 0

			return Promise.resolve(fetch).then(function() {
				var saves = content.map(function(item) {
					var data = item.attributes
					ViewPicker.prototype._stripIDs(data)

					var newItem = StormObject.fromProperties(data)
					badgeIdMap[item.id] = newItem

					var save = newItem.save(null, {appId: destination.id})
						.then(function() {
							completeCount++

							var message  = 'Copying ' + plugin.get('name') + '...',
								progress = Math.round(completeCount / content.length * 100)

							updateProgress(message, progress)
						})

					return Promise.resolve(save)
				})

				return Promise.all(saves)
			})
		})

		return Promise.all(copies)
	}

	function fetchPages() {
		updateProgress('Fetching list of pages...')
		return Promise.resolve(pageList.fetch())
	}

	function copyPages() {
		updateProgress('Fetching page content...')
		var pageIdMap          = {},
			pageSaveCount      = 0,
			totalPageSaveCount = pageList.length * 3

		// Fetch each page and copy as-is to new app.
		var pageSaves = pageList.map(function(page) {
			return Promise.resolve(page.fetch()).then(function() {
				var pageData = page.attributes
				ViewPicker.prototype._stripIDs(pageData)

				pageData.appId = destination.id

				var pageModel = new Page(pageData)
				pageIdMap[page.id] = pageModel

				var save = pageModel.save(null, {appId: destination.id})
					.then(function() {
						pageSaveCount++

						var progress = Math.round(pageSaveCount / totalPageSaveCount * 100)
						updateProgress('Creating new pages...', progress)
					})

				return Promise.resolve(save)
			})
		})

		Promise.all(pageSaves).then(function() {
			// All pages saved - update page IDs of any links.
			pageSaves = pageList.map(function(oldPage) {
				var newPage = pageIdMap[oldPage.id],
					newJSON = JSON.stringify(newPage)

				newJSON = newJSON.replace(/cache:\/\/pages\/(\d+)\.json/g, function(match, oldId) {
					var oldPage = pageIdMap[oldId]

					if (!oldPage) {
						return ''
					}

					var newId = oldPage.id
					return 'cache://pages/' + newId + '.json'
				})

				newJSON = newJSON.replace(/"badgeId"\s*:\s*"?(\d+)"?/g, function(match, oldId) {
					var oldBadge = badgeIdMap[oldId],
						newId    = 0

					if (oldBadge) {
						newId = oldBadge.id
					}

					return match.replace(oldId, newId)
				})

				// Perform initial save with no changes to update ordering.
				// Hack because the API is slightly broken.
				return newPage.lockObject().then(function() {
					return Promise.resolve(newPage.save())
				}, deferred.reject.bind(deferred, 'Failed to lock page for update')).then(function() {
					pageSaveCount++

					var progress = Math.round(pageSaveCount / totalPageSaveCount * 100)
					updateProgress('Copying pages...', progress)

					return Promise.resolve(newPage.save(null, {data: newJSON}))
				}, deferred.reject.bind(deferred, 'Failed to save page')).then(function() {
					pageSaveCount++

					var progress = Math.round(pageSaveCount / totalPageSaveCount * 100)
					updateProgress('Copying pages...', progress)

					return newPage.unlock()
				}, deferred.reject.bind(deferred, 'Failed to re-save page'))
			})

			// Rebuild page cache.
			var newPageList = new PageList(null, {appId: destination.id}),
				cacheSave   = newPageList.rebuildCache()

			pageSaves.push(cacheSave)

			// Set root page for the app.
			var appList     = globals.getAppList(),
				oldApp      = appList.get(fromId),
				oldRootPage = oldApp.get('objectId')

			if (oldRootPage) {
				var newRootPage = pageIdMap[oldRootPage].id

				destination.set('objectId', newRootPage)
				var rootSave = destination.save()

				pageSaves.push(rootSave)
			}

			// Wait for all page update saves to complete before finishing.
			Promise.all(pageSaves).then(deferred.resolve, deferred.reject.bind(deferred, 'Failed to update new pages'))
		}, deferred.reject.bind(deferred, 'Failed to copy pages'))
	}

	return deferred
}

module.exports = LegacyAppCreateView
