<div class="row">
	<div class="col-sm-6">
		<h3>{{t "gdpcConsole.devPools.editPool"}}</h3>
	</div>
	<div class="col-sm-6 actions-col">
		<a href="javascript:history.go(-1)"><button class="button white">{{t "common.back"}}</button></a>
		<button class="button green save">{{t "common.save"}}</button>
	</div>
</div>
<div class="row">
	<div class="col-xs-12">
		<form class="form-horizontal">
			<fieldset>
				<legend>{{t "publish.details"}}</legend>

				<div class="form-group">
					<label class="control-label col-sm-2" for="dev-pool-name">{{t "users.name"}}</label>
					<div class="col-sm-10">
						<input type="text" id="dev-pool-name" class="form-control" value="{{name}}">
					</div>
				</div>

				<legend>{{t "gdpcConsole.nationalSocieties.countries"}}</legend>

				<table class="table-striped">
					<thead>
						<tr>
							<th>{{t "agreement.nationalSociety"}}</th>
							<th colspan="2">{{t "gdpcConsole.devPools.language"}}</th>
						</tr>
					</thead>
					<tbody>
						{{#each details}}
							{{#key_value languages "langCode" "val"}}
								<tr>
									<td class="center">{{getSocietyDescriptionById ../societyId}} - {{../appName}}</td>
									<td class="center" colspan="2">{{langCode}}</td>
								</tr>
							{{/key_value}}
						{{/each}}
						<tr>
							<td>
								<select class="form-control new-app-country">
									<option value="">-</option>
									{{#each societies}}<option value="{{id}}">{{description}}</option>{{/each}}
								</select>
								<select class="form-control new-app-app">
									<option value="">-</option>
								</select>
							</td>
							<td>
								<select class="form-control new-app-lang">
									{{#each languages}}<option value="{{id}}">{{code}} - {{language.name.native}}</option>{{/each}}
								</select>
							</td>
							<td style="width:1px">
								<a href class="dev-pool add-app"><i class="icon-ok"></i></a>
							</td>
						</tr>
					</tbody>
				</table>

				<legend>{{t "gdpcConsole.devPools.stages"}}</legend>

				<table class="table-striped">
					<thead>
						<th>{{t "gdpcConsole.nationalSocieties.stage"}}</th>
						<th>{{t "users.name"}}</th>
						<th>{{t "agreement.date"}}</th>
					<tbody>
						{{#each stages}}
							<tr data-index="{{@index}}">
								<td class="center">{{addOne @index}}</td>
								<td class="center">{{name}}</td>
								<td><input type="text" class="form-control stage-date"></td>
							</tr>
						{{/each}}
					</tbody>
				</table>
			</fieldset>
		</form>
	</div>
</div>
