/** @type {StormGlobals} */
var globals = require('globals')

// Override sync to add the auth header to all API requests
Backbone._sync = Backbone.sync

Backbone.sync = function(method, model, options) {
	options = options || {}
	options.contentType = 'application/json; charset=utf-8'

	// noAuth key set on specific models/collections to bypass auth
	var noAuth = (model) ? model.noAuth : false

	if (!noAuth) {
		var session = globals.getSession()

		// Abort if not authenticated
		if (!session.isAuthenticated()) {
			throw new Error('This action requires a valid auth token')
		}

		// Check if token is about to expire. Refresh if necessary.
		if (session.getTimeRemaining() < 5 * 60) {
			session.renew()
		}

		options.headers = options.headers || {}
		options.headers.Authorization = session.get('token')

		// Set custom auth options if applicable.
		if (options.globalAuth) {
			options.headers.Authorization = 'Global ' + options.headers.Authorization
		}
	}

	// Call the original sync method
	var jqXHR = Backbone._sync.apply(this, [
		method,
		model,
		options
	])

	// Mark model as fetched after loading for the first time.
	jqXHR.then(function() {
		model.fetched = true
	})

	return jqXHR
}

// Some more sane defaults for the Backbone objects
Backbone.View.prototype.initialize = function() {
	this.render = _.bind(this.render, this)
	this.destroy = _.bind(this.destroy, this)
}

Backbone.View.prototype.addView = function(view) {
	this.listViews.push(view)
	return view
}

Backbone.View.prototype.template = function() {}

Backbone.View.prototype.getRenderData = function() {
	return (this.model) ? this.model.toJSON() : {}
}

Backbone.View.prototype.render = function(data) {
	// Render from template
	this.$el.html(this.template(this.getRenderData(), {data: data}))

	// Render all subviews
	_.each(this.views, function(view) {
		this.$el.append(view.el)
		view.render()
		view.delegateEvents()
	}, this)

	if (this.tutorial) {
		var dismissed = Boolean(localStorage.getItem('tutorial-' + this.tutorial))

		if (dismissed) {
			this.$('.tutorial-box').remove()
		} else {
			this.events = this.events || {}
			this.events['click .close-tutorial-button'] = 'dismissTutorial'
			this.delegateEvents()
		}
	}

	if (this.model) {
		this.el.model = this.model
	}

	this.afterRender()
	this.$el.i18n()
	return this
}

Backbone.View.prototype.afterRender = function() {}

Backbone.View.prototype.beforeDestroy = function() {}

Backbone.View.prototype.destroy = function() {
	// Run any extra cleanup (saving etc)
	this.beforeDestroy()

	// Remove any child views first
	if (this.views) {
		_.each(this.views, function(view) {
			view.destroy()
		})
	}

	if (this.listViews) {
		_.each(this.listViews, function(view) {
			view.destroy()
		})
	}

	// Remove view
	this.remove() // remove from dom, clean up dom events, and also call stopListening() to remove any bound model events that the view has listenTo'd.
	this.unbind() // remove self triggered events
}

Backbone.View.prototype.dismissTutorial = function() {
	this.$('.tutorial-box').remove()
	localStorage.setItem('tutorial-' + this.tutorial, 1)
}

Backbone.Model.prototype.fetched = false
Backbone.Collection.prototype.fetched = false

// Fetch a model if it has never been fetched.
Backbone.Model.prototype.fetchOnce = function() {
	if (this.fetched) {
		return $.when()
	}

	return Backbone.Model.prototype.fetch.apply(this, arguments)
}

Backbone.Collection.prototype.fetchOnce = Backbone.Model.prototype.fetchOnce

Backbone.Collection.prototype.save = function(attributes, options) {
	var self = this
	options = options || {}
	options.success = function() {
		self.trigger('sync', self)
	}

	return Backbone.sync('update', this, options)
}
