<div class="row">
	<div class="col-xs-12 actions-col">
		<button class="button green save-app wide" type="button" data-i18n="common.save"></button>
	</div>
	<div class="col-xs-12 col-sm-6 col-lg-4 col-lg-offset-2">
		<form class="form-horizontal">
			<fieldset>
				<legend>{{t "appSetup.appName"}}</legend>

				{{#key_value app.name "key" "val"}}
					<div class="form-group">
						<label class="control-label col-xs-2" for="name-{{key}}">{{toUpperCase key}}</label>
						<div class="col-xs-10">
							<input type="text" value="{{val}}" class="form-control app-name" id="name-{{key}}" data-code="{{key}}">
						</div>
					</div>
				{{/key_value}}

				<div class="form-group developer-mode">
					<div class="col-xs-11 col-xs-offset-1">
						<p class="form-control-static">{{appStormId}}</p>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
	<div class="col-xs-12 col-sm-6 col-lg-4">
		<form class="form-horizontal">
			<fieldset>
				<legend>{{t "common.settings"}}</legend>

				<div class="form-group">
					<label class="control-label col-xs-6" for="start-page">{{t "appSetup.startPage"}}</label>
					<div class="col-xs-6">
						<select id="start-page" name="start-page" class="form-control"></select>
					</div>
				</div>

				<div class="form-group">
					<label class="control-label col-xs-6">Translations</label>
					<div class="col-xs-6">
						<a href="/apps/{{appId}}/settings/translations"><button class="button wide"><i class="icon-globe"></i> {{t 'common.edit'}}</button></a>
					</div>
				</div>
				<!-- <div class="form-group translation-download">
					<label class="control-label col-xs-6">Download translations</label>
					<div class="col-xs-6">
						<button type="button" class="download-json-button button"><i class="icon-download-alt"></i> JSON</button>
						<button type="button" class="download-csv-button button"><i class="icon-download-alt"></i> CSV</button>
					</div>
				</div>

				<div class="form-group translation-upload">
					<label class="control-label col-xs-6">Upload translations</label>
					<div class="col-xs-6">
						<button type="button" class="translation-upload-button button"><i class="icon-upload-alt"></i> CSV or JSON</button>
					</div>

					<input type="file" class="translation-upload-input" accept=".json,.csv">
				</div> -->
			</fieldset>
		</form>
	</div>
</div>
<div class="row">
	<div class="language-list-container"></div>
	<div class="col-sm-6 col-lg-4">
		<form class="form-horizontal">
			<fieldset>
				<legend data-i18n="appSetup.bundleIdentifiers"></legend>
				<div class="form-group">
					<label class="control-label col-xs-2">iOS</label>
					<div class="col-xs-10">
						<p class="form-control-static">{{bundleIdentifiers.ios}}</p>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-xs-2">Android</label>
					<div class="col-xs-10">
						<p class="form-control-static">{{bundleIdentifiers.android}}</p>
					</div>
				</div>
			</fieldset>
		</form>
		<form class="form-horizontal developer-mode">
			<fieldset>
				<legend data-i18n="appSetup.appStatus"></legend>
				<div class="form-group">
					<label class="control-label col-md-3" data-i18n="appSetup.active" for="app-status"></label>
					<div class="col-md-9">
						<select id="app-status" class="form-control">
							<option value="2" data-i18n="appSetup.wip" selected></option>
							<option value="3" data-i18n="appSetup.released"></option>
						</select>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-md-3" data-i18n="appSetup.multi" for="app-type"></label>
					<div class="col-md-9">
						<input type="checkbox" id="app-type"/>
						<p class="help-block" data-i18n="appSetup.multiHelp"></p>
					</div>
				</div>
				<div class="form-group" style="display:none;" id="container-app-group">
					<label class="control-label col-md-3" data-i18n="appSetup.multiContainer" for="app-type-container"></label>
					<div class="col-md-9">
						<input type="checkbox" id="app-type-container"/>
						<p class="help-block" data-i18n="appSetup.multiContainerHelp"></p>
					</div>
					<label class="control-label col-md-3" data-i18n="appSetup.regional" for="app-type-container"></label>
					<div class="col-md-9">
						<input type="checkbox" id="app-regional-container"/>
						<p class="help-block" data-i18n="appSetup.regionalHelp"></p>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>
