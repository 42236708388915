{
	"error": {
		"noPermission": "Vous n'avez pas l'autorisation d'effectuer cette action.",
		"noAccess": "Vous n'avez pas accès à ce système. Veuillez contacter votre administrateur.",
		"noAccessJoin": "Vous n'avez pas accès à ce système. Voulez-vous vous inscrire ?",
		"saveFailed": "La sauvegarde a échoué. Merci de rafraîchir la page.",
		"connect": "N'a pas pu se connecter au serveur APl",
		"noApps": "Vous n'avez accès à aucune app",
		"rootPage": "Le menu principal est introuvable.",
		"generic": "Une erreur s'est produite.",
		"exitWarning": "Les données de l'app sont en cours de sauvegarde. Si vous quittez la page maintenant des données pourraient être perdues.",
		"allFields": "Merci de renseigner tous les champs",
		"cantPaste": "Ce style d'objet ne peut être copié ici.",
		"lockedBy": "Cet objet est actuellement verrouillé par",
		"oops": "Oops!",
		"locked": "La modification dans cette application est actuellement verrouillée"
	},
	"common": {
		"save": "Sauvegarder",
		"submit": "Soumettre",
		"cancel": "Annuler",
		"next": "Suivant",
		"finish": "Terminer",
		"close": "Fermer",
		"back": "Retour",
		"preview": "Aperçu",
		"publish": "Publier",
		"upload": "Télécharger",
		"done": "Fait",
		"tag": "Etiquette",
		"page": "Page",
		"view": "Voir",
		"email": "Adresse email",
		"username": "Nom de l'utilisateur",
		"add": "Ajouter",
		"edit": "Modifier",
		"tools": "Outils",
		"description": "Description",
		"position": "Position",
		"warning": "ATTENTION",
		"settings": "Paramètres",
		"clickToBrowseMap": "Cliquer pour parcourir la carte",
		"closeThisMessage": "Fermer ce message",
		"pleaseWait": "S'il vous plaît attendre",
		"pageTags": {
			"learn": "Connaissances",
			"prepare": "Prévention",
			"emergency": "Urgence",
			"tests": "Test",
			"info": "Plus d'infos",
			"other": "Autre"
		},
		"linkTypes": {
			"external": "Externe",
			"internal": "Interne",
			"native": "Natif",
			"uri": "URI",
			"timer": "Chronomètre",
			"call": "Appel",
			"sms": "SMS",
			"share": "Partager",
			"emergency": "Urgence",
			"appLink": "Inter-app",
			"email": "Email"
		},
		"linkDescriptions": {
			"external": "Un lien vers une page internet externe. Elle s'ouvrira dans l'app.",
			"internal": "Un lien vers une autre page interne à l'app.",
			"native": "Un lien vers une fonctionnalité d'origine dans l'app.",
			"uri": "Un lien vers une page internet externe. Cela obligera l'utilisateur à quitter l'app.",
			"timer": "Un lien qui démarre un compte à rebours sur l'appareil.",
			"call": "Un lien pour composer un numéro depuis l'appareil de l'utilisateur.",
			"sms": "Un lien pour envoyer un SMS depuis l'appareil de l'utilisateur.",
			"share": "Un lien pour partager l'app grâce à la fonction \"partager\" intégrée dans l'appareil.",
			"emergency": "Un lien pour composer le numéro de téléphone d'urgence précisé dans les paramètres d'utilisateur.",
			"appLink": "Un lien vers une page dans une autre application",
			"email": "Un lien pour envoyer un email à l'adresse indiquée."
		},
		"filter": "Filtre",
		"reject": "Rejeter",
		"approve": "Approuver",
		"sections": "Sections",
		"language": "La langue",
		"longLoad": "En récupérant une grande quantité de données, cela peut prendre une minute.",
		"approved": "Approuvé",
		"search": "Chercher"
	},
	"login": {
		"inputs": {
			"password": "Mot de passe"
		},
		"error": {
			"title": "Oups!",
			"body": "Le nom d'utilisateur ou le mot de passe est incorrect",
			"emailNotFound": "L'adresse email n'a pas été trouvée",
			"linkExpired": "Le lien pour réinitialiser votre mot de passe a expiré"
		},
		"forgot": {
			"title": "Tout le monde peut oublier!",
			"body": "Entrer votre adresse email"
		},
		"success": {
			"title": "Réussi!",
			"body": "Vous allez bientôt recevoir un email avec les consignes pour réinitialiser votre mot de passe",
			"loginBody": "Vous vous êtes connecté avec succès, mais il n'y a nulle part où aller! Vous êtes sur auth.3sidedcube.com. Veuillez vous rendre sur le CMS Storm de votre organisation."
		},
		"links": {
			"forgot": "J'ai oublié...",
			"return": "Retour à l'identifiant"
		},
		"reset": {
			"newPassword": "Nouveau mot de passe",
			"confirm": "Confirmer",
			"submit": "Soumettre",
			"success": "Votre mot de passe a été défini, vous pouvez maintenant fermer cette fenêtre."
		},
		"title": "S'identifier"
	},
	"nav": {
		"navigate": "Naviguer",
		"logout": "Déconnexion",
		"items": {
			"appSetup": {
				"title": "Paramètres de l'App",
				"description": "Configurer le nom de l'application, l'icône, la contextualisation et les informations de l'App Store"
			},
			"content": {
				"title": "Contenu",
				"description": "Modifier le contenu à l'intérieur de l'app"
			},
			"publish": {
				"title": "Publier",
				"description": "Publier les modifications dans la version dev ou la version live "
			},
			"stats": {
				"title": "Statistiques",
				"description": "Voir comment se porte l'app"
			},
			"userAgreement": {
				"title": "Contrat d'utilisation",
				"description": "Visualiser et signer le contrat du Programme de l'Application Universelle"
			},
			"users": {
				"title": "Utilisateurs",
				"description": "Gérer tous les utilisateurs"
			},
			"stories": {
				"title": "Histoires",
				"description": "Gérer les histoires"
			},
			"levels": {
				"title": "Niveaux",
				"description": "Gérer les niveaux de progrès de l'app"
			},
			"badges": {
				"title": "Insignes",
				"description": "Gérer les insignes gagnés en finissant une activité dans l'app"
			},
			"push": {
				"title": "Notifications push",
				"description": "Gérer et envoyer les notifications push aux utilisateurs"
			},
			"sprint": {
				"title": "Tableau des statuts",
				"description": "Visualiser l'état actuel du développement sprint"
			},
			"bugs": {
				"title": "Rapport de bogues",
				"description": "Signaler les problèmes avec l'éditeur ou la version alpha de l'app"
			},
			"gdpcConsole": {
				"title": "Console des Sociétés nationales",
				"description": "Voir les Sociétés nationales et les groupes de développement"
			},
			"hazards": {
				"title": "Catastrophes et Alertes",
				"description": "Priorités, gestion de la catastrophe, alertes et modération"
			},
			"decisionTree": {
				"title": "L'arbre de décision",
				"description": "Visualiser et modifier l'arbre de décision pour l'app"
			},
			"admin": {
				"title": "Administrateur",
				"description": "Visualiser les statistiques, modifier les utilisateurs et les détails de la structure"
			}
		}
	},
	"localisations": {
		"title": "Localisations",
		"string": "Chaîne",
		"key": "Clé",
		"uploadCsv": "Télécharger en CSV",
		"addMultiple": "Ajouter des localisations multiples",
		"all": "<b>Toutes</b> les localisations",
		"overwriteExisting": "Remplacer les localisations existantes",
		"copyFromAnotherApp": "Copier depuis une autre application",
		"addNew": "Ajouter de nouvelles localisations",
		"addMultipleInstructions": "Ajouter ou mettre à jour plusieurs localisationsà la fois. Utiliser CSV pour le format",
		"overwriteWarning": "Toute traduction existante sera remplacée.",
		"errors": {
			"got": "obtenu",
			"expected": "Attendu",
			"csvInvalidHeader": "En-tête non valide.",
			"csvInvalidColumnCountOnLine": "Nombre de colonnes en ligne invalide",
			"csvTooManyColumns": "Trop de colonnes dans l'en-tête.",
			"csvInvalidKeyOnLine": "Clé de localisation en ligne invalide",
			"invalidKey": "Clé de localisation invalide. Les clés doivent commencer par un tiret bas et  ne contenir que des lettres majuscules et des tirets bas. Les clés doivent contenir au moins deux sections (bits délimités par des tirets bas)."
		},
		"confirmCopy": "Etes-vous sûr que vous voulez copier les localisations depuis l'application sélectionnée ?"
	},
	"appSetup": {
		"title": "Paramètres de l’App",
		"appName": "Nom de l'App",
		"startPage": "Page de démarrage",
		"languages": "Langues de l'App",
		"localisation": "Contextualisation de l'app",
		"publishable": "Publiable sur",
		"dev": "Développement",
		"live": "Live",
		"key": "Clé",
		"value": "Valeur",
		"properties": "Propriétés",
		"publishableLanguages": "Langues de publication",
		"addMoreLanguages": "Seules les langues attribuées à l'organisation sont disponibles ici. Pour en ajouter d'autres aller dans la section Admin.",
		"translationError": "Impossible de télécharger le fichier de traduction. Se il vous plaît vérifier l'application et de l'organisation ont au moins une langue activée et publiable.",
		"expandAll": "Tout agrandir",
		"addCsv": "Ajouter CSV",
		"bundleIdentifiers": "SDK Bundle Identifiers",
		"type": "Type",
		"default": "Défaut",
		"activeapp": "actif",
		"locale": "App locales",
		"multiContainerHelp": "Cette application est-elle l'application conteneur?",
		"regionalHelp": "Cette application représente-t-elle plusieurs pays?",
		"multiHelp": "Cette application fait-elle partie d'une multi-application?",
		"active": "Statut",
		"prev": "Prev",
		"released": "Libéré",
		"translation": "Traductions",
		"next": "Prochain",
		"wip": "Travaux en cours",
		"individualapp": "Individuel",
		"publish": "Statut de publication",
		"deleted": "Supprimé",
		"deactivated": "Désactivé",
		"activeHelp": "Cette application est en direct",
		"individualHelp": "Cette application est une application individuelle",
		"multiapp": "Multi app",
		"multiContainer": "Application conteneur",
		"appStatus": "Statut de l'application",
		"multi": "Multi app",
		"regional": "Régional",
		"inactiveHelp": "Cette application est uniquement disponible en mode développeur"
	},
	"metadata": {
		"title": "Metadata",
		"noBuildPlatforms": "Il n'existe pas de de plateforme pour cette app",
		"appStore": "Les détails de l'App Store",
		"name": "Nom de l'app",
		"description": "Description",
		"keywords": "Mots-clés",
		"primaryCategory": "Catégorie principale",
		"secondaryCategory": "Deuxième catégorie",
		"copyright": "Copyright",
		"website": "URL de marketing",
		"supportUrl": "Support URL",
		"privacyPolicyUrl": "Lien URl de la politique de confidentialité ",
		"phoneNumber": "Numéro de téléphone",
		"launchDate": "La date de lancement",
		"appIcon": "Icône de l'application",
		"iconSize": "L'icône d'origine doit être au format 1024x1024",
		"addScreenshot": "Ajouter capture d'écran",
		"appIconHint": "Doit être en 1024 x 1024 sans  transparence. Doit être au format JPG ou PNG avec une résolution minimale d'au moins 72 DPI, et dans l'espace de couleur RVB. Pas de couches u d'arrondis",
		"appPreviewVideo": "Vidéo d'aperçu appli",
		"appPreviewVideoHint": "Aperçus vidéo doivent être en formats MP4, MOV ou M4V et ne peuvent pas dépasser 500 Mo",
		"appStoreTitle": "Titre de l'appli",
		"delete": "Supprimer",
		"emailHint": "Fournir une adresse e-mail pour que l'App Store ou les utilisateurs puissent se mettre en contact",
		"featureGraphic": "Caractéristiques graphiques",
		"featureGraphicHint": "La boutique Google Play exige que les applications aient des caractéristiques graphiques, ou «bannière» - les dimensions doivent être de 1024x500 (l x h). Ce peut être une image autonome, ou bien ce sera l'image d'arrière-plan pour une vidéo de promo (le cas échéant)",
		"languages": "Langues",
		"nameOnDevice": "Nom de l'application sur le périphérique",
		"phoneNumberHint": "Doit inclure l'indicatif du pays",
		"platformSpecific": "Métadonnées spécifiques à la plateforme",
		"price": "Prix",
		"rating": "Évaluation",
		"screenshotsHint": "Inclure les captures d'écran pour chaque taille d'écran. Les captures d'écran doivent être au format JPG ou PNG, et dans l'espace de couleur RVB",
		"shared": "Métadonnées multi-plateformes",
		"shortAppDescription": "Brève description de l'application",
		"supportUrlHint": "Une URL contenant les informations de support pour votre application. Cette URL sera visible sur l'App Store",
		"websiteHint": "Une URL contenant des informations marketing de votre application. Cette URL sera visible sur l'App Store",
		"features": "Caractéristiques"
	},
	"plugins": {
		"title": "Plugins",
		"availablePlugins": "Plugins disponibles"
	},
	"editor": {
		"title": "Editeur",
		"pageName": "Nom de la page",
		"pageType": "Type de page",
		"pageStyle": "Style de page",
		"pageTag": "Marqueur de page",
		"addPage": "Ajouter une page",
		"saving": "Sauvegarde...",
		"lastSaved": "Dernière sauvegarde",
		"never": "Jamais",
		"newFromTemplate": "Nouveau à partir du modèle",
		"pageList": {
			"title": "Pages",
			"filter": "Filtrer les pages",
			"allPages": "Toutes les pages"
		},
		"addObject": "Ajouter un objet ici",
		"addQuiz": "Ajouter un test",
		"addApp": "Ajouter une app",
		"addLink": "Ajouter un lien",
		"confirmRelock": "Cet objet a déjà été verrouillé par vous-même. Souhaitez-vous le verrouiller de nouveau? Votre précédent verrouillage sera enlevé.",
		"confirmRequestNewLock": "Votre verrouillage pour cette page a été retiré. Souhaitez-vous demander un nouveau verrouillage?",
		"savesQueued": "enregistre en file d'attente",
		"inspector": {
			"title": "Modifier les propriétés",
			"addAnother": "Ajouter un autre",
			"addBulletItem": "Ajouter un nouveau point",
			"rootPageDelete": "Ceci est la page du menu principal. Elle ne peut être effacée.",
			"areYouSure": "Êtes-vous sûr?",
			"confirmDelete": "Etes-vous certain de vouloir effacer cet élément?",
			"confirmPageDelete": "Confirmer la suppression",
			"confirmPageDeleteWarning": "ATTENTION : Vous allez effacer une page. La totalité de la page sera supprimée. Etes-vous certain de vouloir continuer?",
			"moveUp": "Remonter",
			"moveDown": "Descendre",
			"copy": "Copier",
			"paste": "Copier",
			"copySuccess": "Objet copié dans le presse-papiers",
			"changeType": "Changer le type",
			"newType": "Nouveau type",
			"properties": {
				"annotation": {
					"title": "Annotation",
					"description": "Numéro d'étape"
				},
				"title": {
					"title": "Titre",
					"description": "Liste des titres d'articles"
				},
				"name": {
					"title": "Nom",
					"description": "Nom du système interne"
				},
				"hint": {
					"title": "Indice",
					"description": "Instructions pour l'utilisateur"
				},
				"image": {
					"title": "Image",
					"chooseButton": "Choisir l'image",
					"removeButton": "Enlever l'image"
				},
				"video": {
					"title": "Vidéo",
					"chooseButton": "Choisir une vidéo",
					"attributes": "Caractéristiques",
					"loop": "Boucle",
					"addLanguage": "Ajouter une langue"
				},
				"link": {
					"title": "Lien",
					"type": "Type",
					"destination": "Destination",
					"body": "Corps du texte",
					"duration": "Temps de la minuterie (minutes)",
					"recipients": "Destinataires (un par ligne)",
					"testLink": "Lien pour un test",
					"linkTitle": "Lien Titre",
					"linkDestination": "Lien Destinations"
				},
				"images": {
					"add": "Ajouter une image"
				},
				"animation": {
					"chooseButton": "Choisir l'animation",
					"delay": "Délai de la trame (secondes)"
				},
				"embeddedLinks": {
					"title": "Liens intégrés",
					"add": "Ajouter un lien"
				},
				"embeddedMedia": {
					"title": "Fichier média intégré"
				},
				"header": {
					"title": "Titre en-tête",
					"description": "Apparait au-dessus du groupe d'objets"
				},
				"footer": {
					"title": "Pied de page",
					"description": "Apparait sous le groupe d'objets"
				},
				"badge": {
					"title": "Insigne",
					"icon": "Image",
					"completion": {
						"title": "Message d’achèvement",
						"description": "Apparaît lorsque l’utilisateur termine le test avec succès"
					},
					"how": {
						"title": "Comment obtenir le badge",
						"description": "Texte explicatif pour réussir le test et obtenir l’insigne"
					}
				},
				"units": {
					"title": "Unités",
					"description": "secondes/metres etc..."
				},
				"range": {
					"title": "Ecart",
					"start": "Démarrer",
					"length": "Longueur",
					"increment": "Incrément"
				},
				"completionText": {
					"description": "S'affiche lorsque l'utilisateur répond correctement",
					"title": "Message d’achèvement"
				},
				"failureText": {
					"title": "Message d'échec",
					"description": "S'affiche lorsque l'utilisateur ne répond pas correctement"
				},
				"winMessage": {
					"title": "Message de réussite",
					"description": "S'affiche lorsque l'utilisateur réussit le test"
				},
				"loseMessage": {
					"title": "Perte d'un message",
					"description": "S'affiche lorsque l'utilisateur échoue au test"
				},
				"shareMessage": {
					"title": "Message de partage",
					"description": "Texte accompagnant l’insigne pour partager sur les réseaux sociaux"
				},
				"volatile": "instable (état non persistant)",
				"textContent": "Contenu texte",
				"quizzes": "Tests",
				"answer": "Réponse",
				"selectionLimit": "Limite de sélection",
				"correctAnswer": "Bonne réponse",
				"initialPosition": "Position de départ",
				"relatedLinks": "Liens connexes",
				"winRelatedLinks": "Gagner les liens connexes",
				"loseRelatedLinks": "Perte des liens connexes",
				"caption": "Légende",
				"delay": "Délai (s)",
				"placeholderImage": "Image de substitution",
				"button": "Bouton",
				"standard": "Standard",
				"columns": "Colonnes",
				"quiz": "Test",
				"overlay": "Texte en superposition",
				"progressMessage": "Message de progression",
				"gridType": "Type de grille",
				"confirmGridTypeChange": "Etes-vous sûr que vous voulez changer le type de grille? Cela modifiera tous les éléments de la grille.",
				"winThreshold": {
					"title": "Seuil Win (%)",
					"description": "Pourcentage de réponses correctes requises pour réussir le quiz"
				}
			},
			"swapItem": "élément Swap",
			"copiedItem": "élément copié",
			"confirmApproval": "Êtes-vous sûr de vouloir approuver cela?",
			"sponsorship": "Sélectionner un sponsor"
		},
		"objectPreviews": {
			"DescriptionListItem": {
				"title": "Cet élément a un titre et une description",
				"description": "Utiliser cet élément quand vous avez beaucoup à dire"
			},
			"StandardListItem": {
				"title": "Utiliser cet élément la plupart du temps",
				"description": "Cet élément a un titre, une description et une icône. Vous pouvez laisser les bits vides - comme l'icône"
			},
			"ToggleableListItem": {
				"title": "Utiliser cette option lorsque vous avez quelque chose à cacher",
				"description": "Ce texte peut être affiché ou caché par l'utilisateur. Pratique s'il y a beaucoup de détails - peut servir pour une FAQ"
			},
			"OrderedListItem": {
				"title": "Liste ordonnée",
				"description": "Utiliser cette option lorsque vous voulez une liste numérotée"
			},
			"UnorderedListItem": {
				"title": "Liste non ordonnée",
				"description": "Utiliser cette option lorsque vous voulez une liste, mais seulement pour une liste à puces"
			},
			"HeaderListItem": {
				"description": "Peut avoir un titre et une description plus longue",
				"title": "A utiliser en haut de la page"
			},
			"SpotlightListItem": {
				"first": "Pivoter",
				"second": "Spotlight"
			},
			"ButtonListItem": "A utiliser si vous voulez un bouton",
			"TextListItem": "Utiliser cet objet pour le texte d'introduction, en général en haut d'une page",
			"TitleListItem": "Utiliser cet élément pour une seule ligne de texte",
			"CheckableListItem": "A utiliser pour des listes de contrôle",
			"LogoListItem": "Utiliser cette option pour quelque chose comme un logo, là où il y aura une image, une légende et un lien",
			"ChunkyListItemView": {
				"description": "Cet élément a un titre, une description et une icône. Vous pouvez laisser les bits vides - comme l'icône",
				"title": "Ceci ressemble à un élément de liste standard - avec plus de padding"
			},
			"ListItemView": "Cet article a juste un titre",
			"SponsorshipListItem": "Ceci est utilisé pour afficher le parrainage"
		},
		"changedYourMind": "As changé d'avis?",
		"pageNameError": "Veuillez entrer un nom de page",
		"createWithoutTemplate": "Créer une nouvelle page sans modèle"
	},
	"mediaLibrary": {
		"title": "Bibliothèque de fichiers média",
		"refine": "Affiner la bibliothèque",
		"filterByTags": "Filtrer par étiquettes",
		"type": "Type de fichiers média",
		"image": "Image",
		"icon": "Icône",
		"video": "Vidéo",
		"animation": "Animation",
		"section": "Section",
		"society": "Société",
		"general": "Général",
		"additionalTags": "Etiquettes supplémentaires",
		"commas": "Séparer avec des virgules",
		"clearFilters": "Effacer les filtres",
		"editMedia": "Modifier les fichiers media",
		"delete": "Supprimer",
		"dragToReorder": "Faites glisser pour réorganiser",
		"confirmTagRemoval": "Êtes-vous certain de vouloir supprimer cette étiquette?",
		"upload": {
			"title": "Télécharger un fichier media",
			"tags": "Etiquettes",
			"newTag": "Nouvelle étiquette",
			"agreementText": "Tout contenu téléchargé ici est soumis au terme du",
			"filesizeError": "Le téléchargement a échoué. Le fichier est trop lourd.\n\nLa taille maximum autorisée pour un fichier est de",
			"formatError": "Le téléchargement a échoué. Le format de fichier est invalide.\n\nLes formats autorisés pour les images sont : .jep, .png, .gif\nLes formats autorisés pour les vidéos sont : .mp4, .m4v",
			"genericError": "Le téléchargement a échoué. Le code erreur est :",
			"selectType": "Merci de sélectionner le type d'image que vous allez télécharger",
			"imageDesc": "Le format 640(large) x 320(haut) pixels est recommandé pour les images de la largeur de l'écran, les diaporamas et les vidéos. Les images des tests doivent être au format 640x640 pixels.",
			"invalidTag": "Etiquettes non valides. Les noms des étiquettes ne peuvent pas contenir le symbole (*)",
			"instructions": "Pour commencer, cliquer ici ou faites glisser un fichier",
			"errors": {
				"tooManyFiles": "Veuillez sélectionner un seul fichier"
			},
			"square": "Carré",
			"noCrop": "Ne pas rogner",
			"cropType": "Type de rognage",
			"squareDesc": "Utiliser ce rognage pour les icônes et images de quiz. Les images de quiz doivent être de <b>640 (l)</b> par <b>640 (h)</b> pixels.",
			"spotlightDesc": "Utilisez cette culture pour les spots, bord à bord, diaporama et des images vidéo. Les images doivent être <b>640 (w)</b> par <b>320 (h)</b> de pixels de taille.",
			"headerDesc": "Utilisez cette culture pour les grandes images d'en-tête qui apparaissent en haut de la page. Les images doivent être <b>640 (w)</b> par <b>568 (h)</b> de pixels de taille.",
			"requiresTransparency": "Nécessite un fond transparent",
			"gdpcHeaderDesc": "Utilisez cette culture pour les grandes images d'en-tête des dangers applications. Les images doivent être <b>640 (w)</b> par <b>568 (h)</b> de pixels de taille.",
			"gdpcSpotlightDesc": "Utilisez cette culture pour les en-têtes dans les applications de secourisme, projecteurs, bord à bord, diaporama et des images vidéo. Les images doivent être <b>640 (w)</b> par <b>320 (h)</b> de pixels de taille."
		},
		"chooseExisting": "Choisir élément actuel",
		"loadMore": "Charger plus"
	},
	"viewPicker": {
		"title": "Objets",
		"filter": "Filtrer les objets",
		"basicObjects": "Objets de base",
		"multiLineObjects": "Objets sur plusieurs lignes",
		"mediaObjects": "Objets multimédia",
		"interactiveObjects": "Objets interactifs",
		"text": "Texte",
		"subText": "Sous-titre",
		"leadInText": "Texte d'introduction",
		"hiddenText": "Texte caché",
		"spotlight": "Photo étroite appelée \"spotlight\"",
		"TextListItem": "Texte centré bord à bord entre les groupes",
		"TitleListItem": "Une seule ligne de texte",
		"StandardListItem": "Un titre, un lien et une icône",
		"DescriptionListItem": "Un titre et un texte descriptif de plusieurs lignes",
		"ToggleableListItem": "Un titre sur lequel on peut cliquer pour faire apparaitre un contenu plus long",
		"OrderedListItem": "Un titre et un texte de plusieurs lignes avec une annotation ou un numéro",
		"UnorderedListItem": "Un élément de  liste à puce avec un titre et une description",
		"CheckableListItem": "Un titre et une case à cocher. Peut persister entre utilisateurs de l'appli",
		"ButtonListItem": "Un titre et un bouton",
		"ImageListItem": "Une seule image bord à bord",
		"HeaderListItem": "Une grande image superposée par un texte et une description",
		"VideoListItem": "Un objet vidéo compatible avec plusieurs sources",
		"AnimatedImageListItem": "Diaporama d'une série d'images, créant une animation",
		"SpotlightImageListItem": "Une ou plusieurs photos mises en avant contenant une image, une légende et un lien",
		"LogoListItem": "Permet d'afficher le logo d'une entreprise",
		"SliderQuizItem": "Question du choix du curseur de l'image",
		"TextQuizItem": "Question du choix du texte",
		"ImageQuizItem": "Question du choix de l'image",
		"AreaQuizItem": "Question du choix de la zone",
		"CollectionListItem": "Une vue \"\\collectez-les tous\\\" pour applis, badges et lien",
		"dragAndDrop": "Faites glisser et déposer des objets pour les ajouter à la page",
		"SponsorshipListItem": "Un simple article de sponsoring",
		"sponsorshipObjects": "Objets de parrainage",
		"SponsorshipLogoListItem": "Utilisé pour afficher la marque de sponsoring"
	},
	"publish": {
		"title": "Publier",
		"auditTrail": "Piste de vérification",
		"noChanges": "Aucune modification",
		"lastModified": "Dernières modifications",
		"publishToDev": "Publier dans la version dev",
		"publishToLive": "Publier dans la version live",
		"objectType": "Type d'objet",
		"detail": "Détail",
		"details": "Détails",
		"time": "Date",
		"startTime": "Heure de départ",
		"endTime": "Heure de fin",
		"user": "Utilisateur",
		"selectAll": "Tout sélectionner",
		"error": "Merci de sélectionner une ou plusieurs pages à publier.",
		"warning1": "Vous avez sélectionné les pages suivantes pour être publiées dans les versions IOS et Android de l'app à travers une mise à jour delta",
		"warning2": "Merci de vérifier minutieusement toutes les modifications avant de continuer et confirmez votre sélection en bas de la page.",
		"comment": "Commentaire",
		"publishConfirm": "Je certifie avoir vérifié toutes les modifications sélectionnées et être habilité à les publier dans la version live de l'app.",
		"toApps": "Publier dans les apps",
		"pleaseConfirm": "Merci de cocher la case pour confirmer que vous avez bien vérifié toutes les modifications",
		"publishing": "Publication en cours...",
		"progress1": "Les modifications sélectionnées sont en cours de publication.",
		"progress2": "Cela peut prendre un certain temps. Merci de rester sur cette page.",
		"success": "Publication réussie.",
		"fail": "La publication a échoué.",
		"history": "Historique",
		"publishType": "Type de publications",
		"test": "Test",
		"live": "Live",
		"publishLandmark": "Landmark Publish?",
		"progress3": "On dirait que cela prend du temps. Vous pouvez revenir plus tard."
	},
	"publishHistory": {
		"title": "Historique des publications",
		"previousPublishes": "Précédentes publications"
	},
	"agreement": {
		"title": "Contrat du Programme d’Application Universelle",
		"hazardsTitle": "Programme d'Application Hazard Universelle",
		"details": "Détails du contrat",
		"status": "Etat",
		"sign": "Signer",
		"signed": "Signé",
		"notSigned": "Non signé",
		"daysRemaining": "__décompte__ jour restant",
		"daysRemaining_plural": "__décompte__ jours restants",
		"signedBy": "Signé par",
		"date": "Date",
		"nationalSociety": "Société nationale",
		"viewThe": "Voir le",
		"signAgreement": "Signer le contrat",
		"fullName": "Nom complet",
		"email": "Adresse email",
		"iAgree": "J'accepte les conditions données dans le",
		"checkToAgree": "Veuillez cocher la case pour confirmer que vous avez lu et que vous acceptez les termes du contrat."
	},
	"users": {
		"title": "Utilisateurs",
		"list": "Liste des utilisateurs",
		"name": "Nom",
		"firstName": "Prénom",
		"lastName": "Nom de famille",
		"organisation": "Structure",
		"role": "Rôle",
		"roles": "Rôles",
		"apps": "Apps",
		"lastActive": "Dernière activité",
		"success": "Bravo! Le nouvel utilisateur recevra un email avec les instructions nécessaires pour activer son compte.",
		"complete": "Nouveau compte utilisateur créé avec succès",
		"error": "Impossible de créer un nouveau compte utilisateur. Vérifier que le nom et l'adresse email de l'utilisateur n'ont pas déjà été enregistrés.",
		"createUser": "Créer un nouveau compte utilisateur",
		"usernameHelp": "Les noms d'utilisateur ne peuvent contenir que des lettres, chiffres, underscores (tirets du bas), tirets et points.",
		"removeUserConfirm": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
		"failedToRemove": "Impossible de supprimer l'utilisateur"
	},
	"roles": {
		"title": "Rôles de l'utilisateur",
		"list": "Liste des rôles",
		"permissions": "Autorisations"
	},
	"permissions": {
		"title": "Accès à des fonctions",
		"edit": "Modifier les autorisations",
		"none": "Aucune",
		"read": "Lire",
		"write": "Ecrire",
		"delete": "Ecrire/Effacer"
	},
	"apps": {
		"title": "Attribuer les apps",
		"subtitle": "Attribuer les apps à un utilisateur",
		"app": "App",
		"assignNew": "Attribuer une nouvelle app",
		"assign": "Attribuer"
	},
	"stories": {
		"title": "Histoires",
		"list": "Liste des histoires",
		"created": "Créé",
		"subtitle": "Sous-titre",
		"content": "Contenu",
		"editStory": "Modifier l'histoire",
		"story": "Récit",
		"name": "prénom"
	},
	"badges": {
		"title": "Insignes",
		"list": "Liste des insignes",
		"editBadge": "Modifier les insignes"
	},
	"levels": {
		"title": "Niveaux",
		"list": "Liste des niveaux",
		"editLevel": "Modifier le niveau",
		"details": "Détails du niveau",
		"children": "Niveau enfants"
	},
	"push": {
		"showAllApps": "Voir toutes les apps",
		"existing": "Notifications push existantes",
		"category": "Catégorie",
		"notes": "Commentaires",
		"type": "Type",
		"numberSent": "Nombre d'envois",
		"numberCompleted": "Terminé",
		"createdBy": "Créée par",
		"editPush": "Modifier les notifications push",
		"pushDetails": "Détails de la notification",
		"assignments": "Missions",
		"noPageSpecified": "Pas de page précisée",
		"approve": "Accepter",
		"reject": "Rejeter",
		"priority": "Priorité",
		"message": "Message",
		"payload": "Charge utile",
		"saveAndSend": "Sauvegarder et envoyer",
		"noNotifications": "Aucune notification",
		"sending": "Envoi",
		"sent": "Poussez envoyé",
		"categories": {
			"standard": "Standard",
			"future": "Future",
			"region": "Région",
			"custom": "Coutume"
		},
		"types": {
			"default": "Par défaut"
		},
		"priorities": {
			"low": "Basse",
			"high": "Haute"
		},
		"custom": {
			"area": "Zone Cible",
			"areaHelp": "Cliquer sur la carte pour définir une zone cible pour cette notification"
		},
		"error": {
			"length": "Message de notification en push trop long",
			"missingRegion": "Veuillez choisir une région",
			"messageEmpty": "Veuillez entrer un message"
		},
		"messageDescription": "Message de notification en push",
		"addToQueue": "Ajouter à la liste",
		"what": "Que voulez-vous envoyer ?",
		"when": "Quand voulez-vous l'envoyer ?"
	},
	"bugs": {
		"title": "Bogues",
		"report": "Rapport",
		"listOpen": "Liste des nouveaux bogues",
		"listPending": "Liste des bogues en attente",
		"listClosed": "Liste des bogues résolus",
		"version": "Version",
		"build": "Version alpha",
		"device": "Appareil / Système d’exploitation",
		"screenshots": "Captures d'écran",
		"reportBug": "Signaler un bogue",
		"platformDetails": "Détails de la plateforme",
		"issueDetails": "Détails du problème",
		"steps": "Etapes pour reproduire",
		"expected": "Comportement souhaité",
		"actually": "Comportement réel",
		"noReports": "Aucun rapport",
		"bugReport": "Rapport de bogue",
		"comments": "Commentaires",
		"noComments": "Sans commentaires",
		"addComment": "Ajouter un commentaire",
		"loadingDetails": "Chargement des détails du bogue...",
		"closeIssue": "Clôre l'incident",
		"reopenIssue": "Problème réouvert",
		"loadingFiles": "Chargement de fichiers...",
		"noFiles": "Pas de fichier",
		"status": {
			"title": "Etats",
			"deleted": "Effacé",
			"open": "Ouvert",
			"fixed": "Résolu",
			"wontFix": "Ne sera pas résolu",
			"postponed": "Reporté",
			"duplicate": "Dupliquer",
			"notReproducible": "Non reproductible",
			"byDesign": "Par genre",
			"closed": "Fermé"
		}
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"title": "Vue d'ensemble",
			"countries": "Pays",
			"stage": "Etape",
			"averageRating": "Note moyenne",
			"downloads": "Téléchargements",
			"totalDownloads": "Nombre total de téléchargements",
			"contact": "Contact",
			"pool": "Groupe",
			"goLive": "Date de lancement officiel de l'app",
			"locale": "Lieu",
			"details": "Détails de la Société",
			"edit": "Modifier la Société",
			"country": "Pays",
			"newTotalDownloads": "Nouveau total de téléchargements",
			"app": "Nom de l'application",
			"newIosDownloads": "Nouveaux téléchargements iOS",
			"oldIosDownloads": "Anciens téléchargements iOS",
			"oldTotalDownloads": "Ancien total de téléchargements",
			"oldAndroidDownloads": "Anciens téléchargements Android",
			"newAndroidDownloads": "Nouveaux téléchargements Android"
		},
		"devPools": {
			"title": "Etat du groupe de développement",
			"completed": "Terminé",
			"noApps": "Aucune app dans ce groupe de développement",
			"editPool": "Modifier le groupe de développement",
			"stages": "Etapes",
			"language": "Langue",
			"expected": "Prévu",
			"requiredBy": "Requis par",
			"responsibility": "Responsabilité",
			"estimatedDate": "Date estimée de lancement de l'app"
		}
	},
	"hazards": {
		"disasters": {
			"title": "Catastrophes",
			"rootPage": "Menu principal",
			"alertSettings": "Paramètre des alertes",
			"configure": "Configurer",
			"enable": "Activer",
			"disable": "Désactiver",
			"saveSuccess": "La configuration de l'app a été sauvegardée",
			"saveError": "Impossible de sauvegarder la configuration de l'application",
			"pleaseSpecifyPages": "Merci de définir une page pour chaque type de catastrophe sélectionnée.",
			"whatNow": "Page \"Et maintenant\"",
			"categories": {
				"tsunami": "Tsunami",
				"hurricane": "Ouragan",
				"tornado": "Tornade",
				"flood": "Inondation",
				"wildfire": "Feu de forêt",
				"wind": "Vent",
				"storm": "Tempête",
				"earthquake": "Tremblement de terre",
				"heatwave": "Canicule",
				"winter": "Hiver",
				"volcano": "Volcan",
				"nuclear": "Nucléaire",
				"coastal": "Côtier"
			},
			"tutorial": {
				"heading": "Que signifient les différents états?",
				"body": "- Activé signifie que la page apparaitra dans l'éditeur pour que vous commenciez à travailler dessus.<br>- Dev signifie que la page apparaitra dans la version de développement de votre app.<br>- Live signifie que la page apparaitre dans la version présente dans l'App-Store."
			},
			"event": "un événement",
			"active": "Événements actifs",
			"saveClose": "Sauver et fermer",
			"default": "Défaut",
			"code": "Code",
			"page": "Page",
			"addCategory": "Ajouter une catégorie",
			"icon": "Icône",
			"order": "Commande",
			"enabled": "Activée",
			"categoriesStr": "Catégories",
			"titleStr": "Titre"
		},
		"alerts": {
			"title": "Alertes",
			"alert": "Alerte",
			"expired": "Expiré",
			"alertActive": "Alerte activée",
			"pin": "Epingle",
			"pinned": "Epinglée",
			"effective": "En vigueur",
			"issuedBy": "Emis par",
			"noAlerts": "Pas d'alerte",
			"show": "Montrer",
			"inTheLast": "Au cours des derniers",
			"allAlerts": "Toutes les alertes",
			"createNewAlert": "Créer une nouvelle alerte",
			"testAlert": "Créer l'alerte de test",
			"testAlertDesc": "Cette alerte de test sera envoyé à tous les utilisateurs un emplacement de surveillance dans l'Antarctique.",
			"specifySinglePolygon": "Polygones multiples ne sont pas pris en charge pour les alertes. S'il vous plaît dessiner un polygone unique.",
			"times": {
				"allTime": "Historique",
				"hour": "Heure",
				"day": "Jour",
				"week": "Semaine",
				"month": "Mois"
			},
			"custom": {
				"types": {
					"announcement": "Annonce",
					"pin": "Page épinglée",
					"announcementDesc": "Les annonces sont faites pour créer des alertes sur des évènements non données par d'autres fournisseurs d'alertes.",
					"pinDesc": "Les pages épinglées sont là pour présenter les pages importantes telles que la date et la zone sensible."
				},
				"errors": {
					"pleaseSelectRegion": "Merci de choisir une région pour cette alerte",
					"pleaseProvideMessage": "Merci de fournir un message pour faire cette annonce.",
					"pleaseSelectPage": "Merci de sélectionner une page à épingler",
					"pleaseProvideHeadline": "Veuillez fournir un titre pour cette annonce.",
					"pleaseProvideEvent": "Veuillez fournir un événement pour cette annonce.",
					"effectiveWrong": "La date d'entrée en vigueur ne peut être postérieure à la date d'expiration",
					"pleaseProvideAreaDescription": "Veuillez fournir une description de la zone pour cette annonce."
				},
				"announcementPage": "La page s'affiche dans la section \"et maintenant\"",
				"pinPage": "La page à présenter.",
				"message": "Le message envoyé avec cette alerte",
				"area": "Zone d'alerte",
				"areaHelp": "L'alerte sera envoyée à tous les utilisateurs surveillant un lieu dans la zone sélectionnée.",
				"expiry": "Date d'expiration",
				"expiryHelp": "La date où cette alerte sera supprimée",
				"severity": "Gravité",
				"severityHelp": "Quelle est l'importance de cette alerte?",
				"alertQueued": "Alerte en attente d'envoi",
				"drawRegion": "Dessiner le contour des zones où l'alerte doit être envoyée.",
				"notify": "Notifier",
				"sendNotification": "Envoyer une notification push à ces utilisateurs",
				"pushes": "pousse",
				"cap_create": "CAP",
				"cap_send": "Générer une alerte CAP",
				"languageHelp": "Langue de l'alerte est écrit dans",
				"polygon": "Ajouter des données personnalisées Polygon ici.",
				"showPolyData": "Afficher les données de polygone",
				"updateMap": "Mise à jour carte",
				"onsetHelp": "La date à laquelle le danger se produira.",
				"onset": "Date de début",
				"effective": "Date effective",
				"effectiveHelp": "La date à laquelle cette alerte sera effective"
			}
		},
		"title": "Dangers",
		"generalNotifications": {
			"title": "général Notifications",
			"createNotification": "Créer avis",
			"event": "un événement",
			"headline": "Gros titre",
			"area_description": "Description de la zone",
			"type": "Type",
			"status": "Statut",
			"scope": "Portée",
			"category": "Catégorie",
			"urgency": "Urgence",
			"severity": "Gravité",
			"certainty": "Certitude",
			"customMapData": {
				"placeholder": "Cliquez sur la carte ci-dessus ou ajouter un minimum de trois coordonnées ici pour créer un polygone. i.e. \n[longitude,latitude],\n[longitude,latitude],\n[longitude,latitude]",
				"moreCoords": "S'il vous plaît fournir plus de trois coordonnées",
				"firstAndLast": "Les coordonnées et prénoms doivent être les mêmes",
				"notFound": "Aucune donnée de Polygon trouvés , s'il vous plaît ajouter au moins trois coordonnées. i.e. [longitude,latitude],[longitude,latitude],[longitude,latitude]",
				"fisrtFoundNotLast": "Première coordonnée trouvent dans les coordonnées , seulement le premier et les coordonnées dernières devraient être les mêmes"
			},
			"dropdown": {
				"alert": "Alerte",
				"update": "Mettre à jour",
				"cancel": "Annuler",
				"error": "Erreur",
				"actual": "Réel",
				"system": "Système",
				"test": "Tester",
				"draft": "Brouillon",
				"public": "Public",
				"restricted": "Limité",
				"private": "Privé",
				"safety": "Sécurité",
				"security": "la sécurité",
				"rescue": "Porter secours",
				"fire": "Feu",
				"health": "Santé",
				"transport": "Transport",
				"other": "Autre",
				"immediate": "Immédiat",
				"expected": "Attendu",
				"future": "Avenir",
				"past": "Passé",
				"unknown": "Inconnu",
				"extreme": "Extrême",
				"severe": "Sévère",
				"moderate": "Modérer",
				"minor": "Mineur",
				"observed": "Observé",
				"likely": "Probable",
				"possible": "Possible",
				"unlikely": "Improbable"
			}
		},
		"alertZones": {
			"name": "prénom",
			"type": "Type",
			"title": "zones d'alerte",
			"addAlertZone": "Ajouter une alerte Zone",
			"editAlertZone": "Modifier Alert Zone",
			"events": "Événements",
			"areaSelectModalTitle": "Dessiner autour des zones de cette zone applique",
			"area": "Région",
			"whitelist": "Whitelist",
			"blacklist": "Blacklist"
		},
		"feeds": {
			"tutorial": {
				"heading": "Utiliser des flux",
				"body": "Certains types d'alerte peuvent provenir de plusieurs sources d'alerte. Cette section vous permet d'activer ou de désactiver des flux spécifiques et des types d'événements à partir de flux individuels pour cette application.",
				"steps": "<ol><li> Assurez-vous que les types de catastrophes et les catégories choisis sont activés pour cette application. Cela permettra de déterminer quels flux et événements peuvent être activés ci-dessous. </li><li> Sélectionnez les flux et les types d'événements que vous souhaitez contribuer aux alertes pour cette application. </li></ol>"
			},
			"delete": {
				"events": "Tous les événements activés seront désactivés pour ce flux.",
				"confirm": "Êtes-vous sûr?",
				"yes": "Oui, supprimez-le"
			},
			"enable": "Activer",
			"languages": "Langues disponibles",
			"configure": "Configurer",
			"events": "Événements",
			"title": "Nourrit",
			"scope": "Portée",
			"feed": "Alimentation",
			"type": "Type",
			"code": "Code",
			"disable": "Désactiver"
		}
	},
	"appCreate": {
		"title": "Créer une nouvelle app",
		"details": "Détails de l'App",
		"copyFrom": "Copier le contenu depuis",
		"emptyApp": "Vider l'app",
		"createNewOrganization": "Créer une nouvelle organisation",
		"appCopy": {
			"couldNotFind": "Impossible de trouver le travail de copie d'application demandé",
			"newAppCopying": "Votre nouvelle application est en cours de copie",
			"appReady": "Votre nouvelle application est maintenant prête"
		}
	},
	"organization": {
		"title": "Structure",
		"details": "Détails de la structure",
		"noLanguages": "Aucune langue affectée",
		"confirmLanguageAssignment": "Etes-vous certain de vouloir affecter cette langue? L'affectation d'une langue ne peut être supprimée"
	},
	"mailingList": {
		"title": "Listes de diffusion",
		"list": "Listes disponibles",
		"export": "Exporter",
		"dateFrom": "De",
		"dateTo": "A"
	},
	"moderation": {
		"title": "Modération",
		"image": {
			"title": "Image de modération",
			"location": "Localisation"
		}
	},
	"manageApps": {
		"title": "Gérer des applications",
		"yourNextApp": "Votre prochaine application",
		"easilySetUpYourNextApp": "Configurez votre prochaine application sans problème"
	},
	"dashboard": {
		"title": "Tableau de bord"
	},
	"manage": {
		"title": "Gérer"
	},
	"userApps": {
		"title": "Applications de l'utilisateur"
	},
	"posts": {
		"title": "Des postes"
	},
	"achievements": {
		"edit": "Modifier Achievement",
		"image": "image",
		"completion": "Achèvement",
		"name": "prénom",
		"title": "Exploits",
		"badge": "Badge"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "Montant",
				"singular": "Somme amassée"
			}
		},
		"plural": "Déclencheurs"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"empty": "Aucun Emoodjis à modéré",
		"title": "Emoodji modération"
	},
	"crossBorders": {
		"contact": {
			"title": "Titre",
			"number": "Numéro de téléphone",
			"editItem": "Modifier le contact"
		},
		"phrase": {
			"phrase": "Phrase",
			"editItem": "Modifier Phrase",
			"translation": "Traduction"
		},
		"country": {
			"code": "Code postal",
			"name": "Nom du pays",
			"description": "Le pays de destination"
		},
		"contacts": "Contacts",
		"editItem": "Modifier Destination Pays",
		"list": "Liste des destinations",
		"phrases": "Phrases",
		"translations": "phrase Traductions",
		"title": "Cross Borders",
		"link": {
			"title": "Titre",
			"destination": "Destination",
			"placeholder": "Titre de destination"
		},
		"links": "Liens"
	},
	"whatnow": {
		"attribution": {
			"image": "Image d'attribution",
			"message": "Message d'attribution",
			"url": "URL d'attribution",
			"attribution": "Attribution",
			"imageHelp": "Téléchargement d'image / icône.",
			"socName": "Nom de la société d'attribution",
			"messageHelp": "Message qui accompagne l'attribution.",
			"socNameHelp": "Nom de la société à attribuer aux choses",
			"urlHelp": "URL qui renvoie à plus d'informations sur l'attribut."
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor assis amet, consectetur adipiscing elit, sed do eiusmod temporel incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercice ullamco laboris nisi ut aliquip ex ea commodo conséquat."
			},
			"watch": "Regarder",
			"immediate": "Immédiat",
			"eventhelp": "Le type d'événement pour le What now.",
			"immediateHelp": "Répondre",
			"warningHelp": "Préparez-vous à répondre",
			"recover": "Récupérer",
			"description": "La description",
			"before": "Avant",
			"titleHelp": "Titre pour le quoi maintenant.",
			"event": "Danger",
			"selectLang": "Sélectionnez une langue",
			"editWhatnow": "Modifier quoi maintenant",
			"duringHelp": "Que faire pendant l'événement?",
			"addALang": "Ajouter une langue pour commencer",
			"translations": "Traductions",
			"during": "Pendant",
			"recoverHelp": "Après",
			"moreinfo": "URL d'information",
			"after": "Après",
			"afterHelp": "Que faire après l'événement?",
			"warning": "Attention",
			"forecast": "Prévoir",
			"forecastHelp": "Évaluer et planifier (actions à plus court terme)",
			"empty": "S'il vous plaît ajouter une langue pour créer un What Now",
			"missing": "Contenu manquant",
			"languages": "Langues",
			"beforeHelp": "Comment se préparer avant l'événement",
			"moreinfohelp": "URL pour plus d'informations sur le What now.",
			"title": "Titre",
			"midterm": "Prévision à moyen terme",
			"midtermHelp": "Réduire les risques (actions à plus long terme)",
			"watchHelp": "Préparez-vous à répondre",
			"new": "Ajouter une nouvelle langue",
			"descriptionHelp": "Une description pour le quoi maintenant."
		},
		"description": "La description",
		"after": "Après",
		"selectSoc": "Choisir une société",
		"before": "Avant",
		"during": "Pendant",
		"whatnow": "Et maintenant?",
		"eventType": "Danger",
		"empty": "Il n'y a pas de quoi maintenant entrées, cliquez sur ajouter pour en créer un",
		"content-not-set": "Contenu non défini",
		"title": "Titre"
	},
	"arpro": {
		"codeGroup": {
			"codeGroup": "Groupes de codes",
			"name": "prénom",
			"editItem": "Modifier le groupe de codes",
			"addCodes": "Entrez un code ici",
			"codes": "Codes",
			"description": "La description"
		},
		"colours": {
			"colours": "Couleurs",
			"name": "prénom",
			"description": "La description"
		},
		"search": {
			"burningRate": "Taux de combustion",
			"compressionStrength25": "Force de compression 25",
			"compressionStrength75": "Force de compression 75",
			"tensileElongation": "Allongement à la traction",
			"equivalentYoungsModulus": "Module de Young équivalent",
			"compressionStrength50": "Force de compression 50",
			"mouldedDensity": "Densité moulée",
			"tensileStrength": "Résistance à la traction",
			"resilience75DynamicCompression": "Résilience 75 Compression dynamique",
			"search": "Recherche de produit"
		},
		"product": {
			"properties": {
				"weight": "Poids (mg)",
				"bag": "Sac",
				"packaging": "Emballage",
				"bulk": "Densité apparente (g / l)",
				"food": "Nourriture approuvée",
				"size": "Taille (mm)",
				"moulded": "Densité moulée (g / l)",
				"bulkLabel": "Masse"
			},
			"name": "prénom",
			"noneLinked": "Aucun code d'accès",
			"notes": "Remarques",
			"rawData": "Données JSON brutes",
			"access": "Codes d'accès",
			"linked": "A des codes d'accès",
			"grade": "Qualité",
			"specifications": "Caractéristiques",
			"rawDataError": "Données JSON brutes - Données JSON non valides",
			"product": "Des produits",
			"physicalProps": "Propriétés physiques",
			"moulded": "Gamme de densité moulée (g / l)",
			"editItem": "Modifier le produit",
			"colour": "Couleur"
		},
		"noitems": "Il n'y a pas d'éléments à afficher, cliquez sur Ajouter ci-dessus.",
		"arpro": "arpro"
	},
	"r4b": {
		"level": {
			"colour": "Couleur",
			"selectBadge": "Veuillez sélectionner un badge",
			"alpha": "Alpha",
			"blue": "Bleu",
			"levels": "Les niveaux",
			"green": "vert",
			"title": "Niveau",
			"red": "rouge"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "Section Logique de la visibilité"
				},
				"slider": {
					"interval": "Intervalle",
					"endLabel": "Etiquette de fin",
					"startLabel": "Étiquette de départ"
				},
				"question": {
					"addAnotherResponse": "Ajouter une autre réponse",
					"answerHelp": "Entrez une réponse",
					"responseHelp": "Entrez une réponse",
					"selectionHelp": "Sélectionnez une section pour procéder à",
					"textHelp": "Entrer une question",
					"addResponse": "Ajouter une réponse",
					"title": "Question et réponse"
				},
				"quote": {
					"citation": "Entrez la citation ici",
					"title": "Citation"
				},
				"checkbox": {
					"title": "Cases à cocher"
				},
				"textInput": {
					"multi": "Texte multiligne?",
					"title": "Saisie de texte",
					"help": "Entrez l'étiquette de saisie ici"
				},
				"link": {
					"title": "Liens"
				},
				"help": {
					"title": "Objet d'aide"
				},
				"task": {
					"help": "Entrez un objectif de tâche",
					"title": "Objet de tâche"
				},
				"text": {
					"title": "Objet texte"
				},
				"radio": {
					"title": "Boutons radio"
				},
				"list": {
					"add": "Ajouter un élément de liste"
				},
				"section": "Section",
				"break": "Saut de page",
				"addIdentifier": "Ajouter un identifiant",
				"inputLabel": "Étiquette de saisie de texte",
				"options": "Les options",
				"addSection": "Faites glisser un élément ici pour créer une nouvelle section",
				"enterTitle": "Entrez un titre",
				"clickToEdit": "Cliquez pour éditer",
				"videoLink": "Lien vers une vidéo externe",
				"identifier": "Identifiant",
				"enterText": "Entrez le texte ici"
			},
			"section": {
				"title": "SECTION",
				"delete": "Supprimer la section",
				"settings": "Paramètres de section",
				"add": "Ajouter une section ci-dessous"
			},
			"blockTypes": {
				"check": "VÉRIFIER",
				"link": "LIEN",
				"image": "IMAGE",
				"text": "TEXTE",
				"video": "VIDÉO",
				"quote": "CITATION",
				"qna": "Q & A",
				"help": "AIDEZ-MOI",
				"select": "SÉLECTIONNER",
				"task": "TÂCHE",
				"radio": "RADIO",
				"textInput": "ENTRÉE DE TEXTE",
				"slider": "SLIDER",
				"userInput": "BLOCS D'ENTRÉE UTILISATEUR",
				"media": "BLOCS MÉDIAS"
			},
			"levels": "Les niveaux",
			"mins": "mins",
			"module": "Module",
			"version": "Version",
			"people": "gens",
			"level": "Niveau",
			"unsaved": "Il y a des modifications non enregistrées",
			"title": "Modules",
			"visibility": "Visibilité",
			"person": "la personne",
			"saved": "Toutes les modifications sont enregistrées"
		},
		"assessments": {
			"pre": "Pré évaluation",
			"assessment": "Évaluation"
		},
		"placeholder": "Placeholder",
		"minutes": "MINS",
		"delete": "Effacer",
		"languages": "Langues",
		"empty": "Il n'y a pas de modules ou d'évaluations ici, ajoutez-en un à ce niveau pour commencer.",
		"inputBlocks": "BLOCS D'ENTRÉE UTILISATEUR",
		"title": "ATLAS",
		"admin": "Tableau de bord d'administration",
		"questions": "Des questions",
		"id": "Identifiant unique",
		"mediaBlocks": "BLOCS MÉDIAS",
		"person": "LA PERSONNE",
		"sections": "SECTIONS",
		"copyModule": "Module dupliqué",
		"copyConfirmation": "Êtes-vous sûr de vouloir dupliquer ce module?",
		"isBonus": "Prime:",
		"notTranslated": "Pas traduit",
		"languageVars": "Variations de langue",
		"contentBlocks": "Blocs de contenu",
		"setBonus": "Définir comme module bonus:",
		"noTranslationHelp": "Champ d'édition + enregistrer pour mettre à jour",
		"people": "GENS"
	},
	"rspb": {
		"factFile": {
			"editItem": "Modifier le fichier de faits",
			"addActivities": "Entrez une activité ici",
			"funFacts": "Faits amusants",
			"activities": "Activités",
			"commonName": "Nom commun",
			"seen": "Vu",
			"name": "prénom",
			"addTags": "Entrez un tag ici",
			"addImages": "Sélectionner une image",
			"images": "Images",
			"factFile": "Fichiers d'information",
			"addFeatures": "Entrez une fonctionnalité ici",
			"tags": "Mots clés",
			"addFact": "Entrez un fait ici",
			"start": "Début",
			"features": "Caractéristiques",
			"show": "Montrer",
			"description": "La description",
			"scientificName": "Nom scientifique"
		},
		"planItem": {
			"difficulty": {
				"difficulty": "Sélectionnez la difficulté",
				"hard": "Difficile",
				"medium": "Moyen",
				"easy": "Facile"
			},
			"garden": {
				"medium": "Moyen",
				"selectType": "Sélectionnez le type / s de jardin",
				"public": "Publique",
				"large": "Grand",
				"small": "Petit",
				"balconyroof": "Balcon / Toit"
			},
			"title": "Titre",
			"dates": "Rendez-vous",
			"to": "Date de fin",
			"planItems": "Éléments du plan",
			"image": "Image",
			"tags": "Mots clés",
			"weightingHelp": "Priorité de l'endroit où l'article apparaîtra dans l'application",
			"from": "Disponible depuis",
			"region": "Région",
			"visible": "Visible de",
			"weighting": "Pondération des articles du plan",
			"identifier": "Identifiant",
			"regionHelp": "Cliquez pour sélectionner la région pour cet élément de plan",
			"type": "Type",
			"leastImportant": "Le moins important",
			"activityCode": "Code d'activité",
			"description": "La description",
			"duration": "Durée (secondes)",
			"solo": "Pour la famille?",
			"pageHelp": "Ceci est la page à laquelle votre élément de plan sera lié",
			"source": "La source",
			"months": "Mois",
			"mostImportant": "Le plus important",
			"indefinite": "Indéfini?"
		},
		"rspb": "RSPB",
		"noitems": "Il n'y a pas d'éléments à afficher, cliquez sur Ajouter ci-dessus."
	},
	"facility": {
		"filter": {
			"title": "Type d'installation de filtre:",
			"select": "Sélectionnez une installation"
		},
		"edit": {
			"address": {
				"title": "Adresse",
				"locality": "Localité",
				"state": "Etat",
				"postalCode": "code postal",
				"line1": "Ligne 1",
				"country": "Pays"
			},
			"contactdetails": {
				"add": "Ajouter un numéro",
				"phone": "Numéros de téléphone",
				"fax": "Fax"
			},
			"url": "URL",
			"name": "prénom",
			"location": "Emplacement",
			"division": "Division",
			"add": "Ajouter une facilité",
			"facilityid": "FacilityId",
			"contact": "Contact",
			"type": "Type",
			"facility": "Modifier la facilité",
			"region": "Région"
		},
		"placeholder": "Objet de la facilité",
		"list": "Liste des installations",
		"title": "Installations"
	},
	"sponsorship": {
		"edit": {
			"tags": "Mots clés",
			"description": "La description",
			"link": "Lien",
			"icon": "Image d'icône",
			"feature": "Image vedette",
			"sponsor": "Modifier le sponsor",
			"analytics": "Code analytique",
			"text": "Texte du sponsor principal",
			"inputLink": "Entrez une URL pour accéder à",
			"pagesLink": "Sélectionnez une page à relier à",
			"add": "Ajouter un sponsor",
			"descriptionHelp": "Une description du parrainage.",
			"nativeLink": "Entrez un lien vers une fonctionnalité native",
			"iconHelp": "Sélectionnez une image à utiliser comme icône.",
			"featureHelp": "Sélectionnez une image à utiliser comme image principale du sponsor.",
			"tagsHelp": "Ces balises permettent d'ajouter plusieurs parrainages à une page.",
			"textHelp": "Ce texte est utilisé comme titre pour l'article sponsor.",
			"analyticsHelp": "Entrez le code d'analyse de votre sponsor ici."
		},
		"placeholder": "Objet de parrainage",
		"title": "Parrainage",
		"list": "Liste des sponsors"
	},
	"journey": {
		"title": "Titre",
		"link": "Lien",
		"pending": "en attendant",
		"active": "actif",
		"elapsed": "S'est écoulé",
		"pageTitle": "Voyage du sang",
		"edit": "Modifier le parcours sanguin",
		"stage": "Étape"
	},
	"frame": {
		"src": "Src",
		"pageTitle": "Cadres",
		"order": "Commande",
		"edit": "Modifier le cadre",
		"name": "prénom",
		"type": "Type"
	},
	"swim": {
		"pageTitle": "Niveaux de natation",
		"edit": "Modifier le niveau de bain"
	}
}