var LinkSelector = require('./link-selector'),
	utils        = require('lib/utils')

module.exports = Backbone.View.extend({
	template: require('./inspector-image-list-item-view-template'),

	events: {
		'keypress .text': 'textChange',
		'keydown .text': 'textChange',
		'keyup .text': 'textChange',
		'paste .text': 'textChange',

		'change .delay': 'delayChange',

		'click .remove': 'removeImage',
		'click .up': 'moveUp',
		'click .down': 'moveDown'
	},

	initialize: function() {
		this.views = {}

		// initialise link selector
		if (this.model.has('link')) {
			this.views.linkSelector = new LinkSelector({
				link: this.model.get('link'),
				titleDisabled: true
			})

			this.listenTo(this.views.linkSelector, 'change', function() {
				this.model.collection.parent.needsSaving = true
				this.model.collection.parent.needsStructureUpdate = true
			}, this)
		}

		// Update preview on image change
		if (this.model.get('image')) {
			this.listenTo(this.model.get('image'), 'add remove reset', function() {
				this.$('img').attr('src', utils.getImagePreviewUrl(this.model.get('image').toJSON()))
				this.model.collection.parent.needsSaving = true
				this.trigger('change')
			}, this)
		}

		// Update preview on legacy image change.
		this.listenTo(this.model, 'change', function() {
			this.$('img').attr('src', utils.getImagePreviewUrl(this.model.get('src')))
			this.model.collection.parent.needsSaving = true
			this.trigger('change')
		})

		this.on('change', function() {
			var parent = this.model.collection.parent

			parent.trigger('change', parent)
			parent.needsSaving = true
		}, this)
	},

	getRenderData: function() {
		// Return model data with system id
		var data = this.model.toJSON()
		data.systemId = App.system.id
		return data
	},

	textChange: function(e) {
		var self = this
		setTimeout(function() {
			var val = $(e.currentTarget).val()
			var field = $(e.currentTarget).data('field')
			var model = self.model.get(field)
			var pageId = self.model.get('pageId')

			if (!model || !model.class) {
				self.model.set(field, App.getClassStructure('Text', pageId))
			}

			self.model.set(field + '..content..' + Storm.view.language, val)
			self.trigger('change')
		})

		e.stopPropagation()
	},

	delayChange: function(e) {
		var val = Math.abs(parseFloat($(e.currentTarget).val()) || 0)

		// Don't allow delays of < 0.5, unless this is the only spotlight image
		if (val < 0.5 && this.model.collection.length > 1) {
			val = 0.5
		}

		this.model.set('delay', val * 1000)
		this.trigger('change')
		e.stopPropagation()
	},

	removeImage: function() {
		this.remove()
		this.model.destroy()
	},

	moveUp: function() {
		// Move child down up space in order
		var model = this.model
		var collection = this.model.collection

		var index = collection.indexOf(model)
		if (index > 0) {
			// silence this to stop excess event triggers
			collection.remove(model, {silent: true})
			collection.add(model, {
				at: index - 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		model.collection.parent.needsSaving = true
	},

	moveDown: function() {
		// Move child down one space in order
		var model = this.model
		var collection = this.model.collection

		var index = collection.indexOf(model)
		if (index < collection.models.length) {
			// silence this to stop excess event triggers
			collection.remove(model, {silent: true})
			collection.add(model, {
				at: index + 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		model.collection.parent.needsSaving = true
	}
})
