module.exports = Backbone.View.extend({
	/** @override */
	tagName: 'tr',

	/** @override */
	events: {
		'click .edit': 'edit',
		'click .delete': 'delete',
		'input .term-input': 'textChange'
	},

	template: require('./term-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId
		this.locales = options.locales
		this.index = options.index
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.appId
		data.locales = this.locales.toJSON()
		data.index = this.index

		return data
	},

	delete: function() {
		this.model.trigger('destroy', this.model)
		this.remove()
	},

	afterRender: function() {
		_.each(this.model.get('content'), function(val, key) {
			this.$el.find('.term-input[data-code=' + key + ']').val(val)
		}.bind(this))
	},

	textChange: function(e) {
		var value = e.currentTarget.value
		var code = e.currentTarget.attributes["data-code"].value

		this.model.set('content..' + code, value)
	}
})
