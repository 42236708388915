var TYPES = ['Community Based Outpatient Clinic',
	'Community Service Programs',
	'District',
	'Domiciliary',
	'Information Resource Center',
	'Intake Site (Pre-Discharge Claims Assistance)',
	'Integrated Clinical Facility',
	'National Cemetery',
	'Outpatient Clinic',
	'Records Management Center',
	'Red Cross Offices',
	'Regional Benefit Office',
	'Regional Loan Center',
	'VA Central Offices',
	'VA Health Care System',
	'VA Medical Center',
	'VISN',
	'Vet Center'
]

var ObjectSearch = require('models/object-search'),
	EditorSectionView = require('editor/editor-section-view'),
	FacilityListItemView = require('./facility-list-item-view')

module.exports = EditorSectionView.extend({
	className: 'facilities',
	template: require('./facility-view-template'),

	events: {
		'change .facility-filter-list': 'changeFilter',
		'click .load-more': 'loadMore'
	},

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		App.startLoad()
		var requests = []
		this.searchData = options.searchData
		this.type = options.searchData.properties.type
		this.app = App.appList.get(options.appId)
		this.appId = options.appId
		// Fetch Products assigned to this app.
		this.facilities = new ObjectSearch({appId: this.appId, searchData: this.searchData, paginate: true})
		// this.listenTo(this.facilities, 'sync', this.ready)

		var facilitiesFetch = this.facilities.fetch()
		requests.push(facilitiesFetch)

		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	loadMore: function() {
		App.startLoad()
		var facilityCount = this.facilities.length
		this.facilities.fetch().then(function() {
			this.renderFacilities()
			if (this.facilities.length === facilityCount) {
				$('.load-more').prop('disabled', true)
			}
		}.bind(this))
	},

	ready: function() {
		if (this.facilities) {
			this.render()
			this.$('table').show()
			this.renderFacilities()
		}
	},

	getPageTitle: function() {
		return $.t('facility.title')
	},

	getRenderData: function() {
		return {
			appId: this.app.id,
			types: TYPES
		}
	},

	afterRender: function() {
		this.$('.facility-filter-list').val(this.type)
		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	renderFacilities: function() {
		this.$('.list-items').empty()
		this.facilities.forEach(this.renderFacility.bind(this))
		App.stopLoad()
	},

	renderFacility: function(facility) {
		var view = new FacilityListItemView({
			facility: facility,
			appId: this.appId
		})

		this.$('.list-items').append(view.render().el)
	},

	changeFilter: function() {
		if ($('.facility-filter-list').val() !== '-') {
			App.router.navigate('/apps/' + this.appId + '/facilities/' + $('.facility-filter-list').val(), {
				trigger: true
			})
		}
	}
})
