<div class="row">
	<div class="col-xs-12 actions-col">
		<button class="button green wide save" disabled>{{t "common.save"}}</button>
	</div>
	<div class="col-xs-6">
		<div class="form-group">
			<label class="control-label">{{t "blendedLearning.selectPage"}}</label>
		</div>
		<div class="form-group">
				<select id="page-selector" class="form-control">
					<option value="">{{t "permissions.none"}}</option>
				</select>
		</div>
	</div>
</div>
