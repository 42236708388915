var utils        = require('lib/utils'),
	CanvasItemView = require('./canvas-item-view')

module.exports = CanvasItemView.extend({
	className: 'tab editable',
	template: require('./tab-preview-template'),

	initialize: function() {
		CanvasItemView.prototype.initialize.apply(this, arguments)
		// After binding events to the correct model in the superclass, use the nested object for everything else.
		this.parent = this.model
		this.model = this.model.get('tabBarItem')
		this.pageList = App.getCurrentApp().pageList

		// Change events on the new model need to propagate up to the parent.
		this.listenTo(this.model, 'change', this.parent.trigger.bind(this.parent, 'change'))
	},

	getRenderData: function() {
		var imageUrl,
			model = this.model

		if (model.get('image..class') === 'NativeImage') {
			imageUrl = utils.getNativeImagePreviewUrl(model.get('image..src'))
		} else {
			var image = model.get('image')

			if (image.src) {
				imageUrl = utils.getImagePreviewUrl(image.src)
			} else {
				imageUrl = utils.getImagePreviewUrl(model.get('image').toJSON())
			}
		}

		return {
			imageUrl: imageUrl,
			title: model.get('title')
		}
	},

	afterRender: function() {
		if (this.model.editing) {
			this.startEditing()
		}
	},

	click: function(e) {
		// Navigate to the linked page in preview mode.
		if ($('.preview').hasClass('preview-mode')) {
			var tabDest = this.parent.get('src'),
				pageId = null

			if (this.getPageFromUrl(tabDest)) {
				pageId = this.getPageFromUrl(tabDest).get('id')
			}

			if (pageId) {
				var pathMatch = document.location.pathname.match(/^\/apps\/(\d+)\//),
					newPath = '/apps/' + pathMatch[1] + '/pages/' + pageId

				App.router.navigate(newPath, {trigger: true})
			}
		} else {
			Storm.view.views.canvas.setInspector(this.parent)
			this.$el.addClass('editing')

			// Don't bubble up to the page
			e.stopPropagation()
		}
	},

	getPageFromUrl: function(url) {
		var nativePageMatch = url.match(/^app:\/\/native\/pages\/(.+)$/),
			page

		if (nativePageMatch !== null) {
			page = this.pageList.findWhere({name: nativePageMatch[1]})
		} else {
			var id = App.getIdFromCacheUrl(url)

			page = this.pageList.get(id)
		}
		return page
	}
})
