<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="badges.editBadge"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

	<div class="col-xs-12">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.badge.icon"}}</label>
		</div>
		<div class="form-group">
			<img class="badge-icon-preview" src="{{getPreviewUrl icon}}">
		</div>
		<div class="form-group">
			<button type="button" class="button choose-image-button" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
		</div>
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.title.title"}}</label>
		</div>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<input type="text" class="title-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.badge.completion.title"}}</label>
		</div>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<input type="text" class="completion-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.shareMessage.title"}}</label>
		</div>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<input type="text" class="share-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.badge.how.title"}}</label>
		</div>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<input type="text" class="how-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	{{#ifExists validFor}}
	<div class="col-sm-6 js-valid-for-container">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.badge.validFor.title"}}</label>
			<input type="number" class="valid-for-input form-control" step="1" min="0"/>
			<p class="help-block">{{t "editor.inspector.properties.badge.validFor.description"}}</p>
		</div>
	</div>
	{{/ifExists}}

	<!-- if ARC Blood only: https://3sidedcube.atlassian.net/browse/ARCBLOOD-677 -->
	{{#ifEqual systemId 9}}
		{{#ifEqual appId 7}}
			{{#ifExists backgroundImageColor}}
			<div class="col-sm-6">
				<div class="form-group">
					<label class="control-label" for="colour-picker">{{t "editor.inspector.properties.badge.colour.title"}}</label>
					<div class="input-group colour-dropdown">
						<select id="colour-picker" class="form-control js-colour-picker">
							<option value="" selected disabled>{{t "hazards.generalNotifications.dropdown.defaultMessage"}}</option>
							<option value="#E71324">{{t "editor.inspector.properties.badge.colour.values.red"}} #E71324</option>
							<option value="#ECB731">{{t "editor.inspector.properties.badge.colour.values.yellow"}} #ECB731</option>
							<option value="#537B35">{{t "editor.inspector.properties.badge.colour.values.green"}} #537B35</option>
							<option value="#0091CD">{{t "editor.inspector.properties.badge.colour.values.blue"}} #0091CD</option>
							<option value="#000000">{{t "editor.inspector.properties.badge.colour.values.black"}} #000000</option>
						</select>
						<span class="input-group-addon" role="presentation"></span>
					</div>
				</div>
			</div>
			{{/ifExists}}

			{{#ifExists campaign}}
				<div class="col-sm-12">
					<div class="form-group">
						<input type="checkbox" class="js-date-toggle" id="campaign" name="campaign">
						<label class="control-label ml-10" for="campaign">{{t "editor.inspector.properties.badge.campaign.title"}}</label>
					</div>
				</div>
			{{/ifExists}}

			<div class="js-date-range" style="display: none">
				{{#ifExists dateFrom}}
					<div class="col-sm-6">
						<div class="form-group">
							<label class="control-label">{{t "editor.inspector.properties.badge.dateFrom.title"}}</label>
							<input type="date" value="{{dateFrom}}" class="form-control js-dates" id="date-from" placeholder="YYYY-MM-DD" autocomplete="off">
						</div>
					</div>
				{{/ifExists}}

				{{#ifExists dateUntil}}
					<div class="col-sm-6">
						<div class="form-group">
							<label class="control-label">{{t "editor.inspector.properties.badge.dateUntil.title"}}</label>
							<input type="date" value="{{dateUntil}}" class="form-control js-dates" id="date-until" placeholder="YYYY-MM-DD" autocomplete="off">
						</div>
					</div>
				{{/ifExists}}
			</div>
			{{/ifEqual}}
		{{/ifEqual}}
</form>
