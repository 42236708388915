var Facility = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view')

var TYPES = [
	'Community Based Outpatient Clinic',
	'Community Service Programs',
	'District',
	'Domiciliary',
	'Information Resource Center',
	'Intake Site (Pre-Discharge Claims Assistance)',
	'Integrated Clinical Facility',
	'National Cemetery',
	'Outpatient Clinic',
	'Records Management Center',
	'Red Cross Offices',
	'Regional Benefit Office',
	'Regional Loan Center',
	'VA Central Offices',
	'VA Health Care System',
	'VA Medical Center',
	'VISN',
	'Vet Center'
]

var phoneRegex = /\+?[0-9)]+/m // Match one or more of the characters `+` and `0-9`

module.exports = EditorSectionView.extend({
	template: require('./facility-edit-view-template'),
	activeTabIndex: 11,

	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage',
		'click .add-tag-button': 'addTag',
		'click .tag-remove-button': 'removeTag',
		'change .link-type-selector': 'linkTypeChange',
		'click #contact-add-telephone-button': 'addNewContactNumber',
		'click .remove-contact-number': 'deleteNumber',
		'click .goBack': 'goBack'
	},

	initialize: function(options) {
		this._readyCount = 0
		this.views = {}
		this.isLocking = false
		this.appId = options.appId

		var structure = App.getClassStructure('Saf_Facility')
		this.isNew = options.id === 'new'
		this.model = new Facility(structure)
		if (!this.isNew) {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			this.model.fetch().then(this.ready.bind(this))
		} else {
			this.ready()
		}
	},

	ready: function() {
		if (++this._readyCount >= 1) {
			if (!this.isNew && !this.isLocking) {
				this.isLocking = true
				this.model.requestLock()
			}
			this.render()
			App.stopLoad()
		}
	},

	goBack: function() {
		if (this.model.lock.get('isLocked')) {
			this.model.requestUnlock()
		}
		App.router.navigate('/apps/' + this.appId + '/facilities/' + this.model.get('type'), {trigger: true})
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		data.types = TYPES
		if (data.location && (data.location !== "" || data.location !== null)) {
			data.locationparsed = JSON.parse(data.location)

			if (data.locationparsed.coordinates.length === 2) {
				data.locationparsed.lng = data.locationparsed.coordinates[0]
				data.locationparsed.lat = data.locationparsed.coordinates[1]
			}
		}
		return data
	},

	afterRender: function() {
		if (this.model.isNew()) {
			this.$('#address-country-input').val('US') // Prefill US by default..
		}
		this.phoneNumbers = this.model.get('contact..phone')
		this.updateNumbers()
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			if (this.model.lock.get('isLocked')) {
				this.model.requestUnlock()
			}
			App.router.navigate('/apps/' + self.appId + '/facilities/' + this.model.get('type'), {trigger: true})
		}.bind(this))

		// name
		if (this.$('#name-input').val() !== '') {
			this.model.set('name', this.$('#name-input').val())
		}

		// type
		if (this.$('#type-input').val() !== '') {
			this.model.set('type', this.$('#type-input').val())
		} else {
			return
		}

		// url
		if (this.$('#url-input').val() !== '') {
			this.model.set('url', this.$('#url-input').val())
		}

		// facilityId
		if (this.$('#facilityid-input').val() !== '') {
			this.model.set('facilityId', this.$('#facilityid-input').val())
		}

		// division
		if (this.$('#division-input').val() !== '') {
			this.model.set('division', this.$('#division-input').val())
		}

		// region
		if (this.$('#region-input').val() !== '') {
			this.model.set('region', this.$('#region-input').val())
		}

		// location
		// Has to be in the format of: {"type":"Point","coordinates":[-71.110632,42.326988]}
		//
		var lngstr = this.$('#location-lng-input').val()
		var latstr = this.$('#location-lat-input').val()

		var lng = parseFloat(lngstr, 10)
		var lat = parseFloat(latstr, 10)

		// parseFloat returns NaN Not a Number if not a number!
		if (lng && lat) {
			var location = {
				type: "Point",
				coordinates: [lng, lat]
			}
			this.model.set('location', JSON.stringify(location))
		} else {
			// Validation error
			$('.location-group').addClass('alert-error')
			App.stopLoad()

			return
		}

		// address
		// line 1
		if (this.$('#address-line1-input').val() !== '') {
			this.model.set('address..line1', this.$('#address-line1-input').val())
		}
		// locality
		if (this.$('#address-locality-input').val() !== '') {
			this.model.set('address..locality', this.$('#address-locality-input').val())
		}
		// state
		if (this.$('#address-state-input').val() !== '') {
			this.model.set('address..state', this.$('#address-state-input').val())
		}
		// country
		if (this.$('#address-country-input').val() !== '') {
			this.model.set('address..country', this.$('#address-country-input').val())
		}
		// postal code
		if (this.$('#address-postalcode-input').val() !== '') {
			this.model.set('address..postalCode', this.$('#address-postalcode-input').val())
		}

		// Contact
		this.model.set('contact..phone', this.phoneNumbers)
		if (this.$('#contact-fax-input').val() !== '') {
			this.model.set('contact..fax', this.getPhoneNumber(this.$('#contact-fax-input').val()))
		}

		this.model.save(null, {appId: this.appId})
	},

	addNewContactNumber: function() {
		var str = this.$('#contact-add-telephone').val().trim()
		if (str) {
			var number = this.getPhoneNumber(str)
			if (number && this.phoneNumbers.indexOf(number) === -1) {
				this.phoneNumbers.push(number)
				this.updateNumbers()
			}
		} else {
			// Validation error...
		}
	},

	updateNumbers: function() {
		this.$('#contact-telephone-list').empty()
		this.phoneNumbers.forEach(function(number) {
			this.$('#contact-telephone-list').append("<li class='contact-telephone-number'>" + number + "<span class='remove-contact-number' data-number='" + number + "'>&times;</span></li>")
		})
	},

	deleteNumber: function(e) {
		var number = this.$(e.currentTarget).attr('data-number')
		if (this.phoneNumbers.indexOf(number) !== -1) {
			this.phoneNumbers.splice(this.phoneNumbers.indexOf(number), 1)
		}
		this.updateNumbers()
	},

	getPhoneNumber: function(str) {
		var m
		var number = null
		if ((m = phoneRegex.exec(str)) !== null) {
			// The result can be accessed through the `m`-variable.
			m.forEach(function(match) {
				number = match
			})
		}
		return number
	}

})
