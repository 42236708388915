var StandaloneStormObject = require('editor/standalone-storm-object'),
	LanguageList = require('editor/locale-list'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	PageList = require('editor/page-list/page-list'),
	EditorSectionView = require('editor/editor-section-view'),
	LinkSelector = require('editor/inspector/link-selector')

module.exports = EditorSectionView.extend({
	template: require('./sponsor-edit-view-template'),
	activeTabIndex: 10,

	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage',
		'click .add-tag-button': 'addTag',
		'click .tag-remove-button': 'removeTag',
		'click .goBack': 'goBack'
	},

	initialize: function(options) {
		App.startLoad()
		this._readyCount = 0
		this.views = {}
		this.isLocking = false
		this.appId = options.appId

		// Fetch enabled languages for this app.
		this.languageList = new LanguageList(null, {appId: this.appId})
		this.languageList.on('sync', this.ready, this)
		this.languageList.fetch()

		// Get page list
		this.pageList = new PageList(null, {appId: this.appId})
		this.pageList.once('sync', this.ready, this)
		this.pageList.fetch()

		this.isNew = options.id === 'new'
		this.model = StandaloneStormObject.fromClassName('StormQLSponsorship')

		this.listenTo(this.model, 'change:sponsorshipImg', this.updateSponsorImg, this) // Change Icon change.

		if (!this.isNew) {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			this.model.fetch()
		} else {
			this.ready()
		}
	},

	ready: function() {
		if (++this._readyCount > 2) {
			if (!this.isNew && !this.isLocking) {
				this.isLocking = true
				this.model.requestLock(function() {
					this.renderLinkPicker()
				}.bind(this))
			}

			this.render({languages: this.languageList.toJSON(), appId: this.appId})

			// Show current input values
			_.each(this.model.get('title..content'), function(val, key) {
				this.$('.title-input[data-code=' + key + ']').val(val)
			})

			_.each(this.model.get('completion..content'), function(val, key) {
				this.$('.completion-input[data-code=' + key + ']').val(val)
			})

			_.each(this.model.get('shareMessage..content'), function(val, key) {
				this.$('.share-input[data-code=' + key + ']').val(val)
			})

			_.each(this.model.get('how..content'), function(val, key) {
				this.$('.how-input[data-code=' + key + ']').val(val)
			})

			this.tags = this.model.get('tags')
		}
	},

	goBack: function() {
		if (this.model.lock.get('isLocked')) {
			this.model.requestUnlock()
		}
		App.router.navigate('/apps/' + this.appId + '/sponsors', {trigger: true})
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		return data
	},

	renderLinkPicker: function() {
		var lockToken = null

		if (this.model.lock) {
			lockToken = this.model.lock.get('token')
		}

		this.linkPicker = new LinkSelector({
			link: this.model.get('link'),
			titleDisabled: false,
			pageList: Storm.app.pageList,
			languageList: this.languageList,
			appId: this.appId,
			lockToken: lockToken
		})
		// Show link picker
		$('.link-picker').html(this.linkPicker.render().el)
	},

	afterRender: function() {
		if (this._readyCount > 2) {
			// Show current input values
			_.each(this.model.get('text..content'), function(val, key) {
				this.$('.text-input[data-code=' + key + ']').val(val)
			})

			_.each(this.model.get('description..content'), function(val, key) {
				this.$('.description-input[data-code=' + key + ']').val(val)
			})

			if (this.model.get('header')) {
				_.each(this.model.get('header..content'), function(val, key) {
					this.$('.header-input[data-code=' + key + ']').val(val)
				})
			}

			this.renderLinkPicker()
			App.stopLoad()
		}
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			if (this.model.lock.get('isLocked')) {
				this.model.requestUnlock()
			}
			App.router.navigate('/apps/' + self.appId + '/sponsors', {trigger: true})
		}.bind(this))

		// Get all new input values
		var analytics = '',
			sponsorText = {},
			description = {},
			header = {}

		// Analytics
		if (this.$('#analytics-input').val() !== '') {
			analytics = this.$('#analytics-input').val()
		}

		// Text
		this.$('.text-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				sponsorText[code] = value
			}
		})

		// Description
		this.$('.description-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				description[code] = value
			}
		})

		// Description
		this.$('.header-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				header[code] = value
			}
		})

		this.model.set('text..content', sponsorText)
		this.model.set('description..content', description)
		this.model.set('analytics', analytics)

		if (this.model.get('header')) {
			this.model.set('header..content', header)
		}

		this.model.save(null, {appId: this.appId})
	},

	chooseImage: function(e) {
		var icon = false
		if (e.target.id === 'icon-select') {
			icon = true
		}
		var name = icon ? "icon" : "feature"
		var type = icon ? MediaLibrary.types.ICON : MediaLibrary.types.IMAGE

		this.views.mediaLibrary = new MediaSelectorView({
			app: Storm.app,
			model: this.model.get(name),
			mediaType: type
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:sponsorshipImg')
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateSponsorImg: function() {
		this.$('#iconImg').attr('src', Handlebars.helpers.getPreviewUrl(this.model.get('icon..src..x1')))
		this.$('#featureImg').attr('src', Handlebars.helpers.getPreviewUrl(this.model.get('feature..src..x1')))
	},

	addTag: function(e) {
		e.preventDefault()
		if (this.$('#add-tag-input').val().trim()) {
			var value = this.$('#add-tag-input').val()

			// Add to model
			if (this.tags.indexOf(value) === -1) {
				this.tags.push(value)
				this.appendTag(value)
			}

			this.$('#add-tag-input').focus()
			this.$('#add-tag-input').val("")
		}
		e.stopPropagation()
	},

	removeTag: function(e) {
		e.preventDefault()
		var index = $(e.currentTarget).data("id")
		// Remove
		this.tags.splice(index, 1)
		// Empty
		$('#tags').empty()
		// Re-apply
		_.each(this.tags, function(val) {
			this.appendTag(val)
		}, this)
	},

	appendTag: function(value) {
		var index = this.tags.indexOf(value)
		$("#tags").append('<li>' + value + '<a href class="tag-remove-button" data-id="' + index + '">&times;</a></li>')
	},

	linkTypeChange: function() {
		var newType = $('#sponsor-link').val()
		if (newType === "InternalLink") {
			$('#pages-link').show()
			$('#pages-help').show()
			$('#link-input').hide()
			$('#input-help').hide()
			$('#link-native').hide()
			$('#native-help').hide()
		} else if (newType === "NativeLink") {
			$('#pages-link').hide()
			$('#pages-help').hide()
			$('#link-input').hide()
			$('#input-help').hide()
			$('#link-native').show()
			$('#native-help').show()
		} else {
			$('#pages-link').hide()
			$('#pages-help').hide()
			$('#link-input').show()
			$('#input-help').show()
			$('#link-native').hide()
			$('#native-help').hide()
		}
	},

	filterPages: function() {
		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		var nativeOptions = ''

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag}),
				options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				// Native pages go in their own selector
				if (page.get('class') === 'NativePage') {
					var option = '<option value="app://native/pages/' + page.get('name') + '">' + App.l(page.get('title')) + '</option>'
					nativeOptions += option
				} else {
					options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
				}
			})

			$('#pages-link').append('<optgroup label="' + tag + '">' + options + '</optgroup>')
		}, this)

		this.$('#link-native').append(nativeOptions)
	}
})
