var StormView = require('lib/storm-view'),
	LanguageList = require('./whatnow-languages'),
	countryCodes = require('./organisations'),
	Attribution = require('./attribution')

module.exports = StormView.extend({
	template: require('./whatnow-attribution-edit-view-template'),

	events: {
		'submit #toplevelForm': 'saveWhatnow',
		'click .delete-language': 'removeLanguage',
		'click .add-language-button': 'addLanguage',
		'click .more-info': 'toggleMoreInfo',
		'click #attribution-image': 'fileUpload',
		'click #attribution-image-remove': 'removeImage',
		'change #file-upload': 'fileReady'
	},

	initialize: function(options) {
		this.countryCode = options.countryCode
		this.countryCodes = countryCodes

		this.model = new Attribution({countryCode: this.countryCode})
		var modelFetch = this.model.fetch()

		// Get all the languages.
		this.languageList = LanguageList

		this.language = App.il8next.language
		this.translations = {}

		$.when(modelFetch).then(this.ready.bind(this))
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		if (data.translations === null) {
			data.translations = {}
		}
		data.languages = this.languageList
		data.currentLang = this.language
		data.countryCode = this.countryCode
		data.countryCodes = this.countryCodes
		data.image = this.image
		return data
	},

	/**
	 * Trigger native file upload.
	 */
	fileUpload: function() {
		$('.file-upload').trigger('click')
	},

	/*
	 * Triggered on file-upload change
	 */
	fileReady: function(e) {
		var preview = $('#img-preview') // selects the query named img
		var file    = e.currentTarget.files[0] // sames as here
		var reader  = new FileReader()
		reader.onloadend = function() {
			preview.attr("src", reader.result)
			this.image = reader.result
			$('#attribution-image-remove').prop('disabled', false)
			preview.show()
		}.bind(this)

		if (file) {
			reader.readAsDataURL(file) // reads the data as a URL
		} else {
			preview.src = ""
			preview.hide()
			$('#attribution-image-remove').prop('disabled', true)
		}
	},

	/**
	 * Remove image and preview
	 */
	removeImage: function() {
		$('#file-upload').val('')
		$('#img-preview').hide()
		$('#attribution-image-remove').prop('disabled', true)
		this.model.set('attributionImageUrl', null)
		$.ajax({
			dataType: 'json',
			url: App.whatnowRoot + 'org/' + this.countryCode + '/image',
			type: 'DELETE',
			headers: {
				authorization: App.session.get('token')
			},
			success: function() {
				this.render()
			}.bind(this)
		})
	},

	ready: function() {
		App.stopLoad()
		this.translations = this.model.get('translations')
		this.render()
	},

	afterRender: function() {
		this.initFileUploadObject()
	},

	addLanguage: function(event) {
		event.preventDefault()
		App.startLoad()
		// Get language
		var langCode = $('#whatnow-langselect').val()
		if (!this.translations) {
			this.translations = {}
		}
		// Add to translations
		if (!this.translations[langCode]) {
			this.translations[langCode] = {
				name: null,
				attributionMessage: null
			}
		} else {
			swal({
				title: 'Error',
				text: 'Translation for ' + langCode + ' already exists',
				type: 'error'
			})
		}
		// this.addNewTranslation(translation)

		this.setModel()
		App.stopLoad()
		this.render()
	},

	removeLanguage: function(event) {
		var index = event.currentTarget.getAttribute("data-id")
		App.startLoad()
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				// Remove from array.
				if (this.translations[index]) {
					delete this.translations[index]
					// Remove dom objects
					$('.form-group[data-code="' + index + '"]').remove()
					// Set model
					this.setModel()
					this.render()
				}
			}
		}.bind(this))
		console.log(this.model.get('translations'))
		App.stopLoad()
	},

	translationExists: function(lang) {
		if (this.translations) {
			for (var i = 0; i < this.translations.length; i++) {
				if (this.translations[i].lang === lang) {
					return true
				}
			}
		}
		return false
	},

	setModel: function() {
		// URL
		this.model.set('attributionUrl', $('#attribution-url').val())

		// Translations
		var self = this
		this.$('.soc-input').each(function() {
			var code = $(this).data('code')
			var value = this.value
			if (value !== '') {
				self.translations[code].name = value
			}
		})

		this.$('.message-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				self.translations[code].attributionMessage = value
			}
		})
		this.model.set('translations', this.translations)
	},

	saveWhatnow: function(event) {
		event.preventDefault()
		App.startLoad()
		this.setModel()

		this.model.save(null, {
			type: 'put',
			success: function() {
				App.stopLoad()
				history.back()
			}
		})
		App.startLoad()
	},

	toggleMoreInfo: function(event) {
		event.preventDefault()
		event.stopPropagation()
		var info = $(event.currentTarget).data('info')
		$('#more-info-' + info).toggle()
	},

	initFileUploadObject: function() {
		var self = this
		// Initialise file upload plugin
		this.$('.file-upload').fileupload({
			dataType: 'json',
			traditional: true,
			url: App.whatnowRoot + 'org/' + self.countryCode + '/image',

			submit: function() {
				self.$('#progress-bar').show().attr('value', 0)
				self.$('#attribution-image').hide()
			},

			beforeSend: function(xhr) {
				xhr.setRequestHeader("Authorization", App.session.get('token'))
			},

			error: function(jqXHR) {
				var oops  = $.t('error.oops'),
					error = 'error'

				switch (jqXHR.status) {
					case 413:
						swal(oops, $.t('mediaLibrary.upload.filesizeError') + ' ' + App.MAX_FILESIZE + 'MB', error)
						break

					case 415:
						swal(oops, $.t('mediaLibrary.upload.formatError'), error)
						break

					default:
						swal(oops, $.t('mediaLibrary.upload.genericError') + ' ' + jqXHR.status, error)
				}
				App.stopLoad()
				self.$('#progress-bar').hide()
				self.$('#attribution-image').show()
			},

			done: function(e, data) {
				App.stopLoad()
				if (data.url) {
					self.model.set('attributionImageUrl', data.result.url)
					self.render()
				}
			},

			add: function(e, data) {
				App.startLoad()
				self.$('#progress-bar').show().attr('value', 0)
				data.submit()
			},

			progressall: function(e, data) {
				var progress = parseInt(data.loaded / data.total * 100, 10)

				self.$('#progress-bar').attr('value', progress)
			}
		})
	}
})
