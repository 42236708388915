<table>
	<thead>
		<tr>
			<th>
				<div class="segmented-control key-view-control">
					<button value="string" class="active" data-i18n="localisations.string"></button>
					<button value="key" data-i18n="localisations.key"></button>
				</div>
			</th>
			<th>
				<span data-i18n="localisations.title"></span>
				<button class="button wide green save-localisations-button pull-right" data-i18n="common.save"></button>
			</th>
		</tr>
	</thead>
	<tbody>
		{{#each this}}
			<tr>
				<td class="key-col">
					<span class="key hidden">{{key}}</span>
					<span class="string">{{string}}</span>
				</td>
				<td>
					{{#each languages}}
						<div class="form-group">
							<div class="input-group">
								<span class="input-group-addon">{{name}} ({{toUpperCase code}})</span>
								<input type="text" value="{{value}}" class="form-control" data-key="{{../key}}"
									   data-code="{{code}}">
							</div>
						</div>
					{{/each}}
				</td>
			</tr>
		{{/each}}
	</tbody>
</table>
