<div class="container-fluid">
  <!-- Header -->
  <div class="row">
    <div class="col-md-12 module-edit-header">
      <div class="back-container">
        <a class="back-button" needsSaving='false' style="cursor:pointer;"><i class="icon-chevron-left"></i> {{t
          'common.back'}}</a>
        {{#ifEqual model.class "R4b_Module"}}<div class="visibility"><i class="icon-eye-open"></i></div>{{/ifEqual}}
      </div>

      <div class="title module-header-title">
        <div class="title-inputs">
          <h3>{{t "r4b.modules.level"}} {{model.level}} -
            {{#ifEqual model.class 'R4b_Module'}}
            <span class="span-title enter-text" style="width: inherit;"
              data-placeholder="{{t 'r4b.modules.blockContent.enterTitle'}}" contenteditable="true">{{getLocaleText
              model.name.content}}</span>
            {{#isNotTranslated model.name.content language}}
            <span class="badge alert-danger tooltip-bottom" data-tooltip="{{t 'r4b.noTranslationHelp'}}">{{t
              "r4b.notTranslated"}}</span>
            {{/isNotTranslated}}
            {{else}}
                {{getAtlasAssessmentTitle model}}
            {{/ifEqual}} - {{t "r4b.modules.version"}} {{model.version}}
          </h3>
        </div>

        <div class="module-options">
          <p class="mr-10 mb-5"><b>{{t "r4b.id" }} - </b> {{model.id}}</p>
          <h4 class="ml-10 mr-10 mt-1 mb-1">{{t 'r4b.modules.blockContent.identifier'}}: {{identifierArr.[1]}}.<span
              class="enter-text mb-1" id="module-identifier" style="width: inherit;"
              data-placeholder="{{t 'r4b.modules.blockContent.identifier'}}"
              contenteditable="true">{{identifierArr.[2]}}</span>.{{identifierArr.[3]}}</h4>
          {{#ifEqual model.class 'R4b_Module'}}
          <div class="ml-10">
            <label for="is-bonus" class="mb-2">{{t "r4b.setBonus" }}</label>
            <input type="checkbox" name="is-bonus" id="is-bonus" {{#if model.bonus}} checked {{/if}} />
          </div>
          {{/ifEqual}}
          <div class="ml-10 justify-flex">
            <label for="category">
              {{t 'r4b.categories.select'}}:
            </label>
            <select id="category" class="js-category form-control ml-5">
              <option value="-1">{{t 'r4b.categories.selectPlaceholder'}}</option>
              {{#ifExistsAndHasLength categories}}
              {{#each categories}}
              <option value="{{id}}" {{#ifEqual id ../model.categoryId}}selected{{/ifEqual}}>
                {{getLocaleText name.content }}
              </option>
              {{/each}}
              {{/ifExistsAndHasLength}}
            </select>
          </div>

        </div>

        {{#ifEqual model.class 'R4b_Module'}}
        {{#ifExistsAndHasLength languages}}
        <div>
          <h4 class="js-toggle-translations-checklist translations-toggle">{{t 'r4b.modules.translationsToggleText'}} <i
              class="collapse-link icon-large pr pr-l-8 icon-caret-right"></i> </h4>
          <div class="row js-translations-checklist translation-wrapper" style="display:none;">
            <div class="col-md-12 mb-5">
              <img width="25px" style="margin-bottom:5px;" class="developer-mode" src="/images/atlas/bear-icon.png"> <b
                class="developer-mode">Atlas Says:</b> <i class="developer-mode">"{{t
                'r4b.modules.translationsCheckboxAdvice'}}" </i></img>
            </div>
            {{#each languages}}
            <div class="col-md-3 col-sm-6 mb-5">
              <label for="{{code}}">{{language.name.native}} ({{toUpperCase code}})</label>
              <input type="checkbox" class="js-update-translations" value="{{code}}" name="{{code}}" id="{{code}}"
                {{#ifExistsInArray ../model.translations code}} checked {{/ifExistsInArray}} />
            </div>
            {{/each}}
          </div>
        </div>
        {{/ifExistsAndHasLength}}
        {{/ifEqual}}

        <div class="module-logic-builder" style="display:none;"></div>
      </div>
    </div>
  </div>
  <div class="edit-content">
    <div class="left-sidebar">
      <div class="sidebar-controls">
        <div class="lang-selection">
          <select class="main-language">
            {{#each languages}}
            <option value="{{code}}" {{#ifEqual code ../language}}selected="selected" {{/ifEqual}}>
              {{language.name.native}} ({{toUpperCase code}})</option>
            {{/each}}
          </select>
          <!-- <select class="">
					<option>UK</option>
					<option>US</option>
					<option>Welsh</option>
				</select> -->
        </div>
        <button class="button wide save">{{t "common.save"}}</button>
        <p class="save-status"><b><i>{{t "r4b.modules.saved"}}</i></b></p>
      </div>
      <div class="sidebar-blocks no-select">
        <!-- Left sidebar -->
        {{#ifEqual model.class 'R4b_Module'}}
        <h5>{{t 'r4b.inputBlocks'}}</h5>
        <div class="input-holder">
          {{#each blocks}}
          {{#ifEqual this "R4b_RadioInputModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-circle-blank"></i>
            <h4>{{t "r4b.modules.blockTypes.radio"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_CheckboxInputModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-check"></i>
            <h4>{{t "r4b.modules.blockTypes.check"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_SelectInputModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-align-justify"></i>
            <h4>{{t "r4b.modules.blockTypes.select"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_SliderInputModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-resize-horizontal"></i>
            <h4>{{t "r4b.modules.blockTypes.slider"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_TextInputModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-pencil"></i>
            <h4>{{t "r4b.modules.blockTypes.textInput"}}</h4>
          </div>
          {{/ifEqual}}
          {{/each}}
        </div>
        {{/ifEqual}}
        <h5>{{t 'r4b.mediaBlocks'}}</h5>
        <div class="input-holder">
          {{#each blocks}}
          {{#ifEqual this "R4b_TextMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-align-left"></i>
            <h4>{{t "r4b.modules.blockTypes.text"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_ImageMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-picture"></i>
            <h4>{{t "r4b.modules.blockTypes.image"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_LinkMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-link"></i>
            <h4>{{t "r4b.modules.blockTypes.link"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_LocalisedLinkMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-link"></i>
            <h4>{{t "r4b.modules.blockTypes.localisedLink"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_TaskMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-ok"></i>
            <h4>{{t "r4b.modules.blockTypes.task"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_HelpMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-question-sign"></i>
            <h4>{{t "r4b.modules.blockTypes.help"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_ListMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-list-ul"></i>
            <h4> List </h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_QuoteMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-quote-left"></i>
            <h4>{{t "r4b.modules.blockTypes.quote"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_QuestionAssessmentBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-comments"></i>
            <h4>{{t "r4b.modules.blockTypes.qna"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_InputAssessmentBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-pencil"></i>
            <h4>{{t "r4b.modules.blockTypes.textInput"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_TextAssessmentBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-align-left"></i>
            <h4>{{t "r4b.modules.blockTypes.text"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_VideoAssessmentBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-facetime-video"></i>
            <h4>{{t "r4b.modules.blockTypes.video"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_ImageAssessmentBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-picture"></i>
            <h4>{{t "r4b.modules.blockTypes.image"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_AnimatedMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-double-angle-right"></i>
            <h4>{{t "r4b.modules.blockTypes.gif"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_LocalisedAudioLinkMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-volume-up"></i>
            <h4>{{t "r4b.modules.blockTypes.audio"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_LocalisedVideoLinkMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-facetime-video"></i>
            <h4>{{t "r4b.modules.blockTypes.video"}}</h4>
          </div>
          {{/ifEqual}}

          {{#ifEqual this "R4b_LocalisedImageLinkMediaModuleBlock"}}
          <div class="left-block" data-className={{this}}><i class="icon-picture"></i>
            <h4>{{t "r4b.modules.blockTypes.localisedImage"}}</h4>
          </div>
          {{/ifEqual}}
          {{/each}}
        </div>
      </div>
    </div>
    <div class="section-area">
      <div class="ml-10 mr-10">
        <h4>{{t "r4b.pdfIntro"}}</h4>
        <span class="text-content enter-text js-pdf-intro" data-placeholder="{{t 'r4b.pdfIntroPlaceholder'}}"
          contenteditable="true">{{getLocaleText model.pdf_intro.content }}</span>
      </div>

      <div class="module-details">
        <div class="detail">
          <i class="icon-picture"></i>
          <div class="detail-content add-image" style="{{getBackgroundImageStyle model.image.src 'cover'}}"
            data-property="image"></div>
        </div>
        <div class="detail" id="time">
          <i class="icon-time"></i>
          <div class="detail-content">
            <h4 class="time" contenteditable="true">{{secondsToMinutes1dp model.time}}</h4><small>{{t
              'r4b.modules.mins'}}</small>
          </div>
        </div>
        <div class="detail" id="people">
          <i class="icon-group"></i>
          <div class="detail-content">
            <h4 class="people" contenteditable="true">{{model.people}}</h4><small>{{#ifEqual model.people 1}}{{t
              'r4b.modules.person'}}{{else}}{{t 'r4b.modules.people'}}{{/ifEqual}}</small>
          </div>
        </div>
      </div>
      <!-- Section area-->
      <div class="blocks"></div>
    </div>
  </div>
</div>