<td>
	<dl>
		{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<label for="{{../index}}-input-{{code}}" class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</label>
					<input type="text" class="term-input form-control" data-code="{{code}}" id="{{../index}}-input-{{code}}"/>
				</div>
			</div>
		{{/each}}
	</dl>
</td>
<td class="tools">
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
