var APICompat = require('lib/api-compat'),
	Lock = require('editor/page-list/lock'),
	User = require('users/user')

// Keys of arrays holding primitive values. Can't cast these to Collections.
var PRIMITIVE_KEYS = [
	'quizzes',
	'recipients',
	'restrictions',
	'attributes',
	'keywords',
	'countries',
	'languages',
	'blacklist',
	'whitelist',
	'codes',
	'access',
	'category',
	'value',
	'events',
	'tags',
	'activities',
	'garden',
	'translations'
]

// For when we have a generic primative key that is shared with another class that shouldn't be primative.
// Forgive me.
var PRIMATIVE_CLASS_IGNORE = [
	'MetaData',
	'StormQLDisaster',
	'CrossBorderPhrase'
]

// Keys of nested objects which should be stored as nested Models.
var NESTED_KEYS = [
	'grid',
	'animation',
	'tabBarItem',
	'badge'
]

var STANDALONE_STORM_OBJECT = './standalone-storm-object',
	PAGE                    = 'editor/page-list/page',
	QUIZ_ITEM_OPTION        = 'editor/canvas/quiz-item-option'

var SUBCLASSES = {
	Page: PAGE,
	ListPage: PAGE,
	QuizPage: PAGE,
	GridPage: PAGE,
	StormQL: STANDALONE_STORM_OBJECT,
	Badge: STANDALONE_STORM_OBJECT,
	Achievement: STANDALONE_STORM_OBJECT,
	TextQuizItemOption: QUIZ_ITEM_OPTION,
	ImageQuizItemOption: QUIZ_ITEM_OPTION
}

/**
 * Exports {@link StormObject}.
 * @module
 */
var StormObject = Backbone.DeepModel.extend(/** @lends StormObject.prototype */{
	/** @override */
	urlRoot: function() {
		return App.apiRoot + 'objects'
	},

	/** @override */
	parse: function(response) {
		var newModel = StormObject.fromProperties(response)
		this._response = response

		// Recursively update IDs/create new collections and models
		this.mergeModel(newModel)
		return null
	},

	// Temporarily copied from deep-model library, fixes bug with .length
	// serialisation.
	/* eslint-disable */
	constructor: function(attributes, options) {
		var defaults
		var attrs = attributes || {}
		this.cid = _.uniqueId('c')
		this.attributes = {}
		if (options && options.collection) this.collection = options.collection
		if (options && options.parse) attrs = this.parse(attrs, options) || {}
		if (defaults = _.result(this, 'defaults')) {
			// Modified line.
			this.set(defaults, {silent: true})
		}
		this.set(attrs, options)
		this.changed = {}
		this.initialize.apply(this, arguments)
	},
	/* eslint-enable */

	/**
	 * @constructs StormObject
	 * @extends Backbone.DeepModel
	 * @override
	 */
	initialize: function() {
		var lock = new Lock(),
			self = this

		// Only allow accessing lock object through a getter and setter. ID will always
		// match the model.
		Object.defineProperty(this, 'lock', {
			get: function() {
				lock.objectId = self.id
				return lock
			},
			set: function(aLock) {
				if (aLock) {
					if (aLock.id) {
						lock = aLock
					}
				}
			}
		})
		this.parseModels()
		this.on('change', this.parseModels, this)
	},

	parseModels: function() {
		this.reformatCustomModels_(this.attributes)

		// Add any null fields to the model (creating a template and using it causes the fields to be null)
		var modelStructure = App.getClassStructure(this.get('class'))
		// Loop through model attributes
		_.each(this.attributes, function(value, key, list) {
			// Don't store int/string arrays as collections. Backbone can't
			// handle it.
			if (value instanceof Array) {
				if (!StormObject.isPrimitiveArray(key, value, this.get('class')) && list.class !== 'Feed') {
					var StormCollection = require('./storm-collection')
					list[key] = StormCollection.fromArray(value)
					list[key].parent = this
				}
			} else if (NESTED_KEYS.indexOf(key) > -1 && !(value instanceof StormObject)) {
				var grid = StormObject.fromProperties(value)
				if (grid) {
					grid.collection = {parent: this}
					list[key] = grid
				}
			}

			if (value === null) {
				// null object has been found!
				// Set the model with the model structure's attribute (if it exists)
				if (modelStructure[key]) {
					this.set(key, modelStructure[key])
				}
			}
		}, this)
	},

	/**
	 * Replaces certain Storm objects with custom CMS types (e.g. {@code
	 * UriLink} instances with the {@code mailto} protocol are replaced with
	 * {@code EmailLink}). Recursive function.
	 * @param {Object} obj Raw object of properties to update. Any
	 *     {@link StormCollection}/{@link StormObject} children are ignored.
	 * @private
	 */
	reformatCustomModels_: function(obj) {
		var StormCollection = require('./storm-collection')

		var notStormObject = obj instanceof Object &&
			!(obj instanceof StormObject) &&
			!(obj instanceof StormCollection)

		if (notStormObject) {
			var className = APICompat.normaliseClassName(obj.class)

			if (className === 'UriLink') {
				var telLink = new RegExp(/(?:tel\:\/\/)+(.*)/)
				var mailtoLink = new RegExp(/(?:mailto\:)+(.*)/)

				if (telLink.test(obj.destination)) {
					obj.class = 'CallLink'
					obj.destination = telLink.exec(obj.destination)[1]
				} else if (mailtoLink.test(obj.destination)) {
					obj.class = 'EmailLink'
					obj.destination = mailtoLink.exec(obj.destination)[1]
				}
			} else if (/^(Text|Image)QuizItem$/.test(className)) {
				var type   = RegExp.$1,
					answer = obj.answer

				if (obj.options) {
					obj.options = obj.options.map(function(text, i) {
						if (text.class !== 'Text') {
							// Already transformed.
							return text
						}

						var option = {
							class: type + 'QuizItemOption',
							title: text,
							isCorrect: answer.indexOf(i) > -1,
							pageId: obj.pageId
						}

						if (obj.images !== undefined) {
							option.image = obj.images[i]
						}

						return option
					})
				}

				delete obj.answer
				delete obj.images
			}

			var reformatCustomModels = this.reformatCustomModels_.bind(this)

			Object.keys(obj).forEach(function(key) {
				reformatCustomModels(obj[key])
			})
		}
	},

	/**
	 * Replaces any custom CMS objects ({@code EmailLink}, {@code CallLink})
	 * with their original, API-compatible counterpart. Recursive function.
	 * @param {Object} obj Raw object of properties to restore. Must not
	 *     contain any {@Link StormObject}/{@link StormCollection} instances.
	 * @private
	 */
	removeCustomModels_: function(obj) {
		if (obj instanceof Object) {
			var className = APICompat.normaliseClassName(obj.class)

			if (className === 'CallLink') {
				obj.class = 'UriLink'
				obj.destination = 'tel://' + obj.destination
			} else if (className === 'EmailLink') {
				obj.class = 'UriLink'
				obj.destination = 'mailto:' + obj.destination
			} else if (/^(Text|Image)QuizItem$/.test(className)) {
				var hasImages          = className === 'ImageQuizItem',
					images             = [],
					answer             = [],
					alreadyTransformed = false
				if (obj.options) {
					obj.options = obj.options.map(function(option, i) {
						if (!(/^(Text|Image)QuizItemOption$/.test(option.class))) {
							// Already transformed.
							alreadyTransformed = true
							return option
						}

						if (option.isCorrect) {
							answer.push(i)
						}

						if (hasImages) {
							images.push(option.image)
						}

						return option.title
					})
				}

				if (!alreadyTransformed) {
					obj.answer = answer

					if (hasImages) {
						obj.images = images
					}
				}
			}

			var removeCustomModels = this.removeCustomModels_.bind(this)

			Object.keys(obj).forEach(function(key) {
				removeCustomModels(obj[key])
			})
		}
	},

	// Merge the specified model into this object.
	mergeModel: function(model) {
		if (model) {
			_.each(model.attributes, function(value, key) {
				var isBackboneEntity = value instanceof Backbone.Model || value instanceof Backbone.Collection

				// Copy new keys/primitives directly.
				if (!(key in this.attributes && isBackboneEntity)) {
					this.set(key, value)

					// Update parent reference if we're copying a Collection
					if (value instanceof Backbone.Collection) {
						value.parent = this
					}

					// Update nested model faux-collection parents.
					if (NESTED_KEYS.indexOf(key) > -1) {
						if (value.collection) {
							value.collection.parent = this
						}
					}
				} else if (this.attributes[key].mergeModel) {
					// Merge in nested Models/Collections.
					this.attributes[key].mergeModel(value)
				}
			}, this)
		}
	},

	// Custom toJSON method which recursively serialises using each child's
	// toJSON method.
	toJSON: function() {
		if (this._isSerializing) {
			return this.id || this.cid
		}

		this._isSerializing = true

		var json = deepClone(this.attributes)

		_.each(json, function(value, name) {
			if (value != null && _.isFunction(value.toJSON)) {
				json[name] = value.toJSON()
			}
		})

		this.removeCustomModels_(json)
		this._isSerializing = false
		return json
	},

	queueSync: function(sync) {
		var deferred = new jQuery.Deferred()

		var request = function() {
			console.log('Running request (' + StormObject._saveQueue.length + ' queued)')

			// If sync returns false, assume the request is done.
			// For example, calling destroy() on a new model won't perform a
			// request.
			var jqXHR = sync() || Promise.resolve()

			jqXHR.then(function() {
				deferred.resolve.apply(this, arguments)
				StormObject.processNextRequest()
			}, function() {
				deferred.reject.apply(this, arguments)
			})

			if (Storm && Storm.view) {
				Storm.view.trigger('saving')
			}
		}

		if (!App.saving) {
			request()
		} else {
			StormObject._saveQueue.splice(0, 0, request)
			console.log('Queued save at position ' + StormObject._saveQueue.length)
		}

		return deferred
	},

	// Async save method to queue requests for later processing
	save: function() {
		var args = arguments,
			self = this

		var request = function() {
			return self._doSave.apply(self, args)
		}

		var promise = this.queueSync(request)

		promise.fail(function() {
			swal('Oops...', 'Save failed. Any pending (queued) saves may not be able to complete.', 'error')
		})

		return promise
	},

	// Perform save request
	_doSave: function(key, val, options) {
		App.saving = true

		// Rearrange arguments if save was called with the (key, val, options)
		// syntax
		var attrs = key

		if (typeof key === 'string') {
			attrs = {}
			attrs[key] = val
		} else {
			options = val
		}

		// Ensure answer limit isn't smaller than number of correct answers
		var limit = this.get('limit')

		if (limit !== undefined) {
			var answerLength = this.get('options').reduce(function(total, option) {
				if (option.get('isCorrect')) {
					total++
				}

				return total
			}, 0)

			if (limit < answerLength) {
				this.set('limit', answerLength)
			}
		}

		this.updatePageIds()

		options = this.setLockHeader(options)

		// Perform actual save request using native Backbone method
		return Backbone.Model.prototype.save.call(this, attrs, options)
	},

	// Set the text content of the specified attribute for a given language.
	setTextContent: function(language, attribute, text) {
		if (this.get(attribute).class === 'Text') {
			attribute += '..content..' + language
		}

		this.set(attribute, text)
	},

	// Move model up one index in its parent collection
	moveUp: function() {
		var collection = this.collection

		var index = collection.indexOf(this)
		if (index > 0) {
			collection.remove(this, {silent: true}) // silence this to stop
													// excess event triggers
			collection.add(this, {
				at: index - 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		this.reordered = true
	},

	// Move model down one index in its parent collection
	moveDown: function() {
		var collection = this.collection

		var index = collection.indexOf(this)
		if (index < collection.models.length) {
			collection.remove(this, {silent: true}) // silence this to stop
													// excess event triggers
			collection.add(this, {
				at: index + 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		this.reordered = true
	},

	// Recursively set the page ID of all child objects to the page ID of this
	// object.
	updatePageIds: function() {
		// Don't update page IDs for StormQL objects (page ID == 0), or object
		// POSTs (no page ID).
		var pageId = this.attributes.pageId

		if (pageId) {
			this._updatePageIds(this.attributes, pageId)
		}
	},

	_updatePageIds: function(object, pageId) {
		if (object.pageId !== undefined) {
			object.pageId = pageId
		}

		_.each(object, function(value) {
			if (value instanceof Backbone.Model) {
				this._updatePageIds(value.attributes, pageId)
			} else if (value instanceof Object && !(value instanceof Array || value instanceof Backbone.Collection)) {
				this._updatePageIds(value, pageId)
			} else if (value instanceof Backbone.Collection) {
				value.each(function(child) {
					this._updatePageIds(child.attributes, pageId)
				}, this)
			}
		}, this)
	},

	// Proxy destroy method to add to queue.
	destroy: function() {
		var args = arguments,
			self = this

		var request = function() {
			return self._doDestroy.apply(self, args)
		}

		return this.queueSync(request)
	},

	// Insert lock data on destroy call.
	_doDestroy: function(options) {
		App.saving = true

		if (!this.isNew()) {
			options = this.setLockHeader(options)
		}

		// Perform actual DELETE request using native Backbone method
		return Backbone.Model.prototype.destroy.call(this, options)
	},

	// Append lock token to request headers, if present
	setLockHeader: function(options) {
		var pageId = this.get('pageId')

		if (pageId) {
			// Traverse up the tree to reach the parent page object.
			var page = this
			while (page && page.collection && page.id !== page.get('pageId')) {
				page = page.collection.parent
			}

			if (!page) {
				throw new Error('Failed to traverse up to parent page')
			}

			options = options || {}
			options.headers = options.headers || {}

			// Subclass must be required here to avoid circular reference.
			var Page = require('editor/page-list/page')

			// if (page instanceof Page) {
			if (!page.lock.isLocked()) {
				throw new Error('Page is not locked')
			}
			options.headers.Lock = page.lock.get('token')
			// }
			// else {
			// 	if (!this.lock.isLocked()) {
			// 		throw new Error('Object is not locked')
			// 	}
			// 	options.headers.Lock = this.lock.get('token')
			// }

			// Extract new lock expiry on success, leave edit mode on error
			var success = options.success
			var error = options.error

			options.success = function(model, response, options) {
				var expires = Number(options.xhr.getResponseHeader('Lock-Expires'))

				if (!isNaN(expires)) {
					if (page instanceof Page) {
						page.lock.setExpires(expires)
					} else {
						this.lock.setExpires(expires)
					}
				}

				if (success) {
					success(model, response, options)
				}
			}.bind(this)

			options.error = function(model, response, options) {
				var xhr = options.xhr

				// Catch invalid/expired/revoked lock token.
				if (xhr.status === 412) {
					var relock = false

					// Check page ID on the saved object is correct.
					if (model.get('pageId') === model.get('id') && model.collection && model.collection.parent) {
						// Object has same page ID as ID, and isn't a page -
						// lock token won't work.
						console.error('Invalid page IDs.')
						App.showToast($.t('error.generic'))
					} else {
						relock = confirm($.t('editor.confirmRequestNewLock'))
					}

					if (relock) {
						App.saving = false
						if (page instanceof Page) {
							page.lock.lock().then(function() {
								model.save()
							})
						} else {
							this.lock.lock().then(function() {
								model.save()
							})
						}
					} else {
						if (page instanceof Page) {
							page.lock.clearLock()
						} else {
							this.lock.clearLock()
						}

						// Reload the object so that we can revert the unsaved
						// changes
						model.fetch().then(function() {
							model.trigger('change', model)
							App.stopLoad()
						})

						// Clear the save queue. None of these changes are
						// going to save.
						StormObject._saveQueue = []

						App.startLoad()
					}
				}

				if (error) {
					error(model, response, options)
				}
			}.bind(this)
		}

		return options
	},

	// STORM OBJECT LOCKING (Replacing page locking and including non pages.)
	requestLock: function(success) {
		this.lockSuccessCallback = success || this.objectLocked
		return this.lock.lock()
			.then(this.lockSuccessCallback, this.objectLockFailed.bind(this))
	},

	objectLocked: function() {
		// Page locked successfully. Re-fetch page and enter edit mode
		// this.views.canvas.reloadPage().then(this.enterEditMode.bind(this))
	},

	requestUnlock: function() {
		return this.lock.unlock()
	},

	// On Lock failed, ask to reconfirm is needs relocking or soneone else has it locked.
	objectLockFailed: function() {
		// Check if the current user owns this lock
		if (this.lock.get('owner')) {
			swal({
				title: $.t('error.oops'),
				text: $.t('editor.confirmRelock'),
				showCancelButton: true
			}, function(didConfirm) {
				if (didConfirm) {
					var resolve = this.lockSuccessCallback,
						reject  = this.objectLockFailed.bind(this)
					this.lock.lock().then(resolve, reject)
				} else {
					App.stopLoad()
				}
			}.bind(this))
		} else {
			// Fetch name of locking user
			var user = new User({id: this.lock.get('userId')})

			user.fetch().then(function() {
				App.stopLoad()
				App.showToast($.t('error.lockedBy') +
					user.get('firstName') + ' ' + user.get('lastName'))
			})
		}
	}
})

// Check if array contains primitive types (strings/numbers)
// Primitive arrays cannot be stored as collections
StormObject.isPrimitiveArray = function(name, array, className) {
	// If array contains an object, definitely not a primitive array
	if (array[0] instanceof Object) {
		return false
	}

	// Whitelisted primitive-type keys
	return (PRIMITIVE_KEYS.indexOf(name) > -1 && PRIMATIVE_CLASS_IGNORE.indexOf(className) === -1)
}

StormObject._saveQueue = []

StormObject.processNextRequest = function() {
	if (StormObject._saveQueue.length > 0) {
		var request = StormObject._saveQueue.pop()
		request()
	} else {
		// Execute all completion handlers.
		StormObject._saveQueueCompletionHandlers.forEach(function(callback) {
			callback()
		})

		StormObject._saveQueueCompletionHandlers = []

		// Trigger event on the current root view.
		if (Storm && Storm.view) {
			Storm.view.trigger('savingComplete')
		}

		App.saving = false
	}
}

/**
 * Creates a new StormObject instance with structure defined by the specified
 * class name and page ID.
 *
 * Note: input class names will automatically be mapped back to versions
 * compatible with the active API.
 * @param {string} className Class name of the object to construct.
 * @param {number} pageId ID of the parent page object. Should be passed as 0
 *     for StormQL objects.
 * @returns {StormObject} Complete StormObject instance for the specified
 *     object.
 */
StormObject.fromClassName = function(className, pageId) {
	var apiClassName = APICompat.unNormaliseClassName(className),
		structure    = App.getClassStructure(apiClassName, pageId)

	return StormObject.fromProperties(structure)
}

/**
 * Creates a new StormObject instance from the specified raw StormObject data.
 * Will return an instance of the correct subclass where required (e.g. {@link
	* Page} instances for {@code ListPage} objects).
 * @param {Object} properties The raw properties of the StormObject.
 * @returns {StormObject} Complete StormObject instance for the specified
 *     object.
 */
StormObject.fromProperties = function(properties) {
	if (properties) {
		var Constructor = StormObject,
			className   = APICompat.normaliseClassName(properties.class),
			isStormQL   = className && (className.substr(0, 7) === 'StormQL' ||
				className.substr(0, 11) === 'CrossBorder'),
			subclass

		if (isStormQL) {
			subclass = SUBCLASSES.StormQL
		} else {
			subclass = SUBCLASSES[className]
		}

		if (subclass) {
			Constructor = require(subclass)
		}
		return new Constructor(properties)
	}
}

StormObject._saveQueueCompletionHandlers = []

// Add a completion handler to be executed on queue completion.
StormObject.then = function(callback) {
	// Execute now if nothing queued.
	if (App.saving) {
		StormObject._saveQueueCompletionHandlers.push(callback)
	} else {
		callback()
	}
}

function deepClone(obj) {
	if (obj instanceof Backbone.Model || obj instanceof Backbone.Collection) {
		return obj
	}

	obj = _.clone(obj)

	if (typeof obj === 'object' && obj !== null) {
		for (var i in obj) {
			if (obj.hasOwnProperty(i)) {
				obj[i] = deepClone(obj[i])
			}
		}
	}

	return obj
}

module.exports = StormObject
