	<div class="col-md-6">
		<h3 class="mt-0">Categories</h3>
	</div>
	<div class="col-md-6 actions-col">
		<a href="/apps/{{appId}}/atlas_admin/category/new" class="button wide is-link">{{t "common.add"}}</a>
	</div>
	<div class="col-md-12">
		{{#ifExistsAndHasLength categories}}
		<table>
			<thead>
				<tr>
					<th>
						{{t "r4b.categories.categoryName"}}
					</th>
					<th>{{t "r4b.categories.categoryDescription"}}</th>
					<th>
						{{t "r4b.categories.order"}}
					</th>
					<th>{{t "common.tools"}}</th>
				</tr>
			</thead>
			<tbody class="list-items">
				{{#each categories}}
				<tr>
					<td>
						<p class="mb-5">{{name.content.eng}}</p>
						<details>
							<summary style="display: list-item; cursor: pointer;">{{t "r4b.categories.viewTranslations"}}</summary>
							<dl>
								{{#key_value name.content "lang" "text"}}
								<dt>{{lang}}</dt>
								<dd>{{text}}</dd>
								{{/key_value}}
							</dl>
						</details>
					</td>
					<td>
						<p class="mb-5">{{description.content.eng}}</p>
						<details>
							<summary style="display: list-item; cursor: pointer;">{{t "r4b.categories.viewTranslations"}}</summary>
							<dl>
								{{#key_value description.content "lang" "text"}}
								<dt>{{lang}}</dt>
								<dd>{{text}}</dd>
								{{/key_value}}
							</dl>
						</details>
					</td>
					<td>{{order}}</td>
					<td class="tools">
						<a href="/apps/{{../appId}}/atlas_admin/category/{{id}}" aria-label="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
						<button class="js-delete button empty tool-button" data-id="{{id}}" aria-label="{{t "r4b.delete"}}"><i class="icon-trash"></i> </button>
					</td>
				</tr>
				{{/each}}
			</tbody>
		</table>
		{{else}}
		<p>No Categories</p>
		{{/ifExistsAndHasLength}}
		<hr />
	</div>
