<td><img src="{{getPreviewUrl image}}" width="80px" /></td>
<td>
	<dl>
		{{#key_value title.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value subTitle.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/rich-stories/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
