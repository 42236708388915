var UserListView = require('./user-list-view'),
	UserCreateView = require('./user-create-view'),
	ManageSectionView = require('manage/manage-section-view'),
	UsersMeList = require('./users-me-list'),
	SystemUsersList = require('./system-users-list'),
	GroupList = require('./group-list'),
	RoleList = require('permissions/role-list')

/**
 * Exports {@link UsersView}.
 * @module
 */
module.exports = ManageSectionView.extend(/** @lends UsersView.prototype */{
	/** @override */
	className: 'users',
	/** @override */
	template: require('./users-view-template'),

	/** @override */
	getPageTitle: function() {
		return $.t('users.title')
	},

	/** @override */
	events: {
		'click .add-user-button': 'addUserButtonClick'
	},

	/**
	 * @constructs UsersView
	 * @extends ManageSectionView
	 * @override
	 */
	initialize: function(options) {
		this.app = options.app
		this.views = {}

		// Support legacy (system) users list.
		if (App.acl.get('system').users.GETBYIDUSERS) {
			this.collection = new UsersMeList()
		} else {
			this.collection = new SystemUsersList(null, {systemId: App.system.id})
		}

		/** @private {GroupList} */
		this.groupList_ = new GroupList()
		/** @private {RoleList} */
		this.roleList_ = new RoleList()

		var fetches = [
			this.collection.fetch(),
			this.groupList_.fetch(),
			this.roleList_.fetch()
		]

		Promise.all(fetches).then(this.userIdsFetched.bind(this))
	},

	/** @override */
	getRenderData: function() {
		var currentApp = App.getCurrentApp(),
			appId

		if (currentApp) {
			appId = currentApp.id
		}

		return {
			appId: appId
		}
	},

	/** @override */
	afterRender: function() {
		if (!App.acl.hasWritePermission('Users', 'Users list')) {
			this.$('.add-user-button').hide()
		}

		if (!App.acl.hasAccess('Users', 'Role management')) {
			this.$('.roles-link').hide()
		}
	},

	userIdsFetched: function() {
		this.views.list = new UserListView({
			app: this.app,
			ids: this.collection.map(function(user) {
				return user.id
			}),
			listItemOptions: {
				groupList: this.groupList_,
				roleList: this.roleList_
			}
		})

		this.$el.append(this.views.list.render().el)
		this.views.list.collection.fetch()
		this.listenToOnce(this.views.list, 'ready', App.stopLoad)
	},

	/**
	 * Handles click events to the 'Add user' button.
	 */
	addUserButtonClick: function() {
		this.addModel()
	},

	/**
	 * Shows the user creation modal to begin the user creation process.
	 */
	addModel: function() {
		if (this.views.userCreate) {
			this.views.userCreate.destroy()
		}

		this.views.userCreate = new UserCreateView()
		$('body').append(this.views.userCreate.render().el)
		this.views.userCreate.$el.modal()

		this.views.userCreate.on('create', function() {
			this.collection.fetch()
		}, this)
	}
})
