var PageList = require('editor/page-list/page-list')

module.exports = Backbone.View.extend({
	template: require('./link-selector-template'),

	events: {
		'keypress .title': 'titleChange',
		'keydown .title': 'titleChange',
		'keyup .title': 'titleChange',
		'paste .title': 'titleChange',

		'change .link-type-selector': 'linkTypeChange',

		'keypress .link-destination-input': 'destinationChange',
		'keydown .link-destination-input': 'destinationChange',
		'keyup .link-destination-input': 'destinationChange',
		'paste .link-destination-input': 'destinationChange',

		'change .link-destination-input': 'finalDestinationChange',

		'change .duration': 'durationChange',
		'change .body': 'bodyChange',
		'change .recipients': 'recipientsChange',

		'change .link-app-selector': 'appChange',

		'change .link-destination-internal-selector, .link-destination-native-selector': 'destinationChange',

		'click .link-test-button': 'testLink'
	},

	initialize: function(options) {
		// Generate a random string for use in input IDs/labels.
		this.id = Math.random().toString(16).substr(2)
		this.link = options.link
		this.appId = options.appId
		if (!this.appId) {
			this.appId = Storm.app.id
		}
		this.pageId = (this.link.get) ? this.link.get('pageId') : this.link.pageId
		this.titleDisabled = options.titleDisabled
		this.languageList = options.languageList
		this.currentLang = options.language || Storm.view.language
		// Get page list for the current app.
		this.pageList = options.pageList || Storm.view.app.pageList
		this.hideType = options.hideType || false
		this.tagFilters = options.tagFilters || []

		this.lockToken = options.lockToken
		var promises = []

		// Fetch page list for the other app if this is an AppLink.
		var className = (this.link.get) ? this.link.get('class') : this.link.class

		promises.push(this.getValidLinks())

		if (className === 'AppLink') {
			App.startLoad()

			var ident = (this.link.get) ? this.link.get('identifier') : this.link.identifier
			var id = parseInt(ident.match(/\w+-\d+-(\d+)/)[1], 10)

			this.appPageList = new PageList(null, {appId: id})
			// this.appPageList.once('sync', this.render, this)
			promises.push(this.appPageList.fetch())
		} else if (className === 'InternalLink') {
			App.startLoad()
			this.appPageList = new PageList(null, {appId: this.appId})
			promises.push(this.appPageList.fetch())
		}

		Promise.all(promises).then(this.render.bind(this))
	},

	getValidLinks: function() {
		return new Promise(
			function(resolve, reject) {
				$.ajax({
					url: App.apiRoot + 'classes/Link/valid',
					type: 'GET',

					headers: App.session.getHeadersObject(),

					success: function(data) {
						this.validLinks = data
						resolve()
					}.bind(this),

					error: function(jqXHR) {
						reject(jqXHR)
					}
				})
			}.bind(this)
		)
	},

	getRenderData: function() {
		var renderData = {
			id: this.id,
			validLinks: this.validLinks,
			languageList: this.languageList && this.languageList.toJSON(),
			hideType: this.hideType
		}

		if (this.link instanceof Backbone.Model) {
			renderData.link = this.link.toJSON()

			// `CallLink` and `EmailLink` behave differently.
			// When calling `toJSON()`, on them, values suitable for returning to the api are provided.
			// We don't want these api values for displaying on the frontend, so copy the actual value instead.
			var className = this.link.get('class')
			if (['CallLink', 'EmailLink'].indexOf(className) > -1) {
				renderData.link.class = className
				renderData.link.destination = this.link.get('destination')
				renderData.link.overridePlaceholder = className === 'CallLink' ? '999' : 'user@email.com'
			}
		} else {
			renderData.link = this.link
		}
		return renderData
	},

	afterRender: function() {
		// Hide AppLink option outside of BRC
		if (App.system.id !== 10) {
			this.$('option[value=AppLink]').remove()
		}

		// Initialise link selector
		var pages = this.appPageList || this.pageList

		// Hide title input if not applicable
		if (this.titleDisabled) {
			// This link is part of the root model - don't let the user set a
			// title
			this.$('.link-title-select').remove()
		}

		// Get array of all unique tag names
		var tags = _.filter(pages.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		var nativeOptions = ''

		// Output an option group for each tag
		_.each(tags, function(tag) {
			if (this.tagFilters.includes(tag) || this.tagFilters.length === 0) {
				var taggedPages = pages.where({tag: tag}),
					options     = ''

				// Output an option for each page with this tag
				_.each(taggedPages, function(page) {
					// Native pages go in their own selector
					if (page.get('class') === 'NativePage') {
						var option = '<option value="app://native/pages/' + page.get('name') + '">' + App.l(page.get('title')) + '</option>'
						nativeOptions += option
					} else {
						options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
					}
				})

				this.$('.link-destination-internal-selector').append('<optgroup label="' + tag + '">' + options + '</optgroup>')
			}
		}, this)

		this.$('.link-destination-native-selector').append(nativeOptions)

		// Populate app selector, if present
		var appSelect = $('.link-app-selector')

		if (appSelect.length) {
			App.appList.each(function(app) {
				var ident = App.system.apiCode + '-' + app.get('societyId') + '-' + app.id
				appSelect.append('<option value="' + ident + '" data-id="' + app.id + '">' + App.l(app.get('name')) + '</option>')
			})

			// Set current value
			var ident = (this.link.get) ? this.link.get('identifier') : this.link.identifier
			appSelect.val(ident)
		}

		// Show correct inputs for this link type
		var type
		var destination

		if (this.link instanceof Backbone.Model) {
			type = this.link.get('class')
			destination = this.link.get('destination')
		} else {
			type = this.link.class
			destination = this.link.destination
		}

		// Hide other destination input
		if (type === 'InternalLink' || type === 'AppLink') {
			this.$('.link-destination-internal-selector').show()
			this.$('.link-destination-internal-selector').val(destination)
		} else if (type === 'NativeLink') {
			this.$('.link-destination-native-selector').show()
			this.$('.link-destination-native-selector').val(destination)
		} else if (type === 'TimerLink') {
			// Don't show any destination selectors
			this.$('.destination-label').hide()
		} else {
			this.$('.link-destination-input, .link-test-button').show()
		}

		// Set type selector to current value if the option value exists in the select
		var options = this.$('.link-type-selector option')

		for (var i = 0; i < options.length; i++) {
			if ($(options[i]).val() === type) {
				this.$('.link-type-selector').val(type)
				break
			}
		}

		// Show type description (if present) for this link type
		this.$('.type-descriptions [data-type=' + type + ']').show()

		App.stopLoad()
	},

	titleChange: function(e) {
		var self = this

		setTimeout(function() {
			var val = $(e.currentTarget).val()
			var lang = $(e.currentTarget).attr('data-code') || self.currentLang

			if (self.link instanceof Backbone.Model) {
				self.link.set('title..content..' + lang, val)
			} else {
				self.link.title.content[lang] = val
			}

			self.trigger('change')
		})

		e.stopPropagation()
	},

	linkTypeChange: function(e) {
		var newType = $(e.currentTarget).val()

		// Changing link type - need to destroy object and create new
		var newLink = App.getClassStructure(newType, this.pageId)
		newLink.title.content = (this.link instanceof Backbone.Model) ? this.link.get('title..content') : this.link.title.content

		// Copy over destination if we're going between external/URI.
		var oldType = (this.link.get) ? this.link.get('class') : this.link.class

		if ((newType === 'UriLink' && oldType === 'ExternalLink') || (newType === 'ExternalLink' && oldType === 'UriLink')) {
			newLink.destination = (this.link.get) ? this.link.get('destination') : this.link.destination
		}

		// Manually delete existing link object before we swap
		if (this.link.id) {
			var headers = App.session.getHeadersObject()

			// Pull in lock data from the parent page, if there is one.
			if (this.lockToken) {
				headers.Lock = this.lockToken
			} else if (Storm.view.views && Storm.view.views.canvas && Storm.view.views.canvas.model.lock.isLocked()) {
				headers.Lock = Storm.view.views.canvas.model.lock.get('token')
			}

			$.ajax({
				url: App.apiRoot + 'objects/' + this.link.id,
				type: 'DELETE',
				headers: headers
			})
		}

		// Replace link object on model
		// Remove ID so it's saved as a new object
		if (this.link instanceof Backbone.Model) {
			// Deep model unset doesn't work - use the regular Model method
			Backbone.Model.prototype.clear.call(this.link, {silent: true})
			this.link.set(newLink)
		} else {
			// Clear old properties first
			_.each(this.link, function(value, key) {
				delete this.link[key]
			}, this)

			// Copy new properties to model
			_.each(newLink, function(value, key) {
				this.link[key] = value
			}, this)

			this.trigger('change')
		}

		// Remove any existing app link page list, to fix page list rendering
		delete this.appPageList

		// Fetch page list for newly-selected app
		var id = this.appId
		this.appPageList = new PageList(null, {appId: id})
		this.appPageList.once('sync', this.render, this)
		this.appPageList.fetch()

		e.stopPropagation()
	},

	getDestinationInputValue: function() {
		var val  = this.$('.link-destination-input').val(),
			type = this.$('.link-type-selector').val()

		if (type === 'InternalLink' || type === 'AppLink') {
			val = this.$('.link-destination-internal-selector').val()
		} else if (type === 'NativeLink') {
			val = this.$('.link-destination-native-selector').val()
		}

		return val.trim()
	},

	destinationChange: function(e) {
		var self = this,
			val  = this.getDestinationInputValue()

		setTimeout(function() {
			if (self.link instanceof Backbone.Model) {
				self.link.set('destination', val)
			} else {
				self.link.destination = val
			}
			self.trigger('change')
		})

		e.stopPropagation()
	},

	durationChange: function(e) {
		var minutes = $(e.currentTarget).val()
		var milliseconds = minutes * 60 * 1000

		if (this.link instanceof Backbone.Model) {
			this.link.set('duration', milliseconds)
		} else {
			this.link.duration = milliseconds
		}

		this.trigger('change')
		e.stopPropagation()
	},

	bodyChange: function(e) {
		var body = this.$('.body').val()
		if (this.link instanceof Backbone.Model) {
			this.link.set('body..content..' + this.currentLang, body)
		} else {
			this.link.body.content[this.currentLang] = body
		}

		this.trigger('change')
		e.stopPropagation()
	},

	recipientsChange: function(e) {
		var recipients = this.$('.recipients').val().split('\n')
		if (this.link instanceof Backbone.Model) {
			this.link.set('recipients', recipients)
		} else {
			this.link.recipients = recipients
		}

		this.trigger('change')
		e.stopPropagation()
	},

	appChange: function() {
		App.startLoad()

		// Fetch page list for newly-selected app
		var id = parseInt(this.$('.link-app-selector option:selected').data('id'), 10)
		this.appPageList = new PageList(null, {appId: id})
		this.appPageList.once('sync', this.render, this)
		this.appPageList.fetch()

		// Set new identifier value
		var ident = this.$('.link-app-selector').val()

		if (this.link.set) {
			this.link.set('identifier', ident)
		} else {
			this.link.identifier = ident
		}
	},

	finalDestinationChange: function() {
		var url = this.getDestinationInputValue()

		if (!url) {
			return
		}

		var linkClass = this.link instanceof Backbone.Model ? this.link.get('class') : this.link.class

		// Append default protocol to links where required.
		if (!(linkClass === 'CallLink' || linkClass === 'EmailLink')) {
			if (!url.match(/^[a-zA-Z]+:/)) {
				url = 'http://' + url
				this.$('.link-destination-input').val(url)
			}
		}

		if (this.link instanceof Backbone.Model) {
			this.link.set('destination', url)
		} else {
			this.link.destination = url
		}

		this.trigger('change')
	},

	testLink: function() {
		var url = this.$('.link-destination-input').val()
		var destinationType = $('.link-type-selector').val()
		var internalPageLink = this.$('.link-destination-internal-selector').val()

		if (destinationType === "InternalLink" && internalPageLink !== null) {
			internalPageLink = internalPageLink.replace("cache://", "")
			internalPageLink = internalPageLink.replace(".json", "")
			url = window.location.origin + '/apps/' + App.getCurrentApp().id + '/' + internalPageLink
		}

		var win = window.open(url, '_blank')
		win.focus()
	},

	beforeDestroy: function() {
		this.$('.link-destination-input').blur()
	}
})
