<select class="form-control video-type mb-5">
	<option value="local">Local</option>
	<option value="streaming">Streaming</option>
</select>
<div class="internal mb-5">
	{{#if link}}
		<video controls src="{{remoteUrl link.destination}}"></video>
	{{/if}}
	{{#if src}}
		{{#ifEqual src.class 'InternalLink'}}
			<video controls src="{{remoteUrl src.destination}}"></video>
		{{/ifEqual}}
	{{/if}}
	<button class="button video-select-button neutral">{{#if isAudio}}{{t "editor.inspector.properties.audio.chooseButton"}}{{else}}{{t "editor.inspector.properties.video.chooseButton"}}{{/if}}</button>
</div>
<div class="external mb-5">
	<input type="text" class="form-control video-url" data-property="link..destination">
</div>
