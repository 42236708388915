var Society = require('models/society'),
	GDPCSectionView = require('./gdpc-section-view'),
	CountryList = require('editor/countries-list')

module.exports = GDPCSectionView.extend({
	template: require('./societies-edit-view-template'),

	events: {
		'click .save-button': 'save'
	},

	initialize: function(options) {
		this.appId = options.appId

		// Fetch list of all countries
		this.countries = new CountryList()
		var countryFetch = this.countries.fetch()

		if (options.id !== 'new') {
			this.model = App.societiesList.get(options.id)
		} else {
			this.model = new Society()
		}

		countryFetch.then(this.ready.bind(this))
	},

	ready: function() {
		this.render()
	},

	getPageTitle: function() {
		return $.t('gdpcConsole.nationalSocieties.edit')
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		data.countryList = this.countries.toJSON()
		return data
	},

	afterRender: function() {
		this.$('#society-name').val(this.model.get('name'))
		this.$('#society-description').val(this.model.get('description'))
		// this.$('#society-locale').val(this.model.get('locale'))
		this.$('#society-country').val(this.model.get('countryId'))

		// Hide subnav items if we're accessing this with no app.
		if (this.appId == null) {
			this.$('#top-nav a').hide()
		}

		App.stopLoad()
	},

	save: function() {
		this.model.set({
			name: this.$('#society-name').val(),
			description: this.$('#society-description').val(),
			countryId: this.$('#society-country').val()
		})

		if (!this.model.get('name')) {
			this.$('#society-name').addClass('has-error')
		} else {
			this.$('#society-name').removeClass('has-error')
		}

		if (!this.model.get('description')) {
			this.$('#society-description').addClass('has-error')
		} else {
			this.$('#society-description').removeClass('has-error')
		}

		if (!this.model.get('countryId')) {
			this.$('#society-country').addClass('has-error')
		} else {
			this.$('#society-country').removeClass('has-error')
		}

		if (this.model.isValid()) {
			App.startLoad()

			this.model.save().then(history.back.bind(history))

			if (this.model.isNew()) {
				App.societiesList.add(this.model)
			}
		}

		return false
	}
})
