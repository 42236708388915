<div class="container">
	<div class="row">
		<div class="col-sm-6">
			<h3 data-i18n="sponsorship.title"></h3>
		</div>
		<div class="col-sm-6 actions-col">
			<a href="/apps/{{appId}}/sponsors/new"><button class="button add">{{t "common.add"}}</button></a>
		</div>
	</div>
</div>
