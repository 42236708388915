var StormView = require('lib/storm-view')

/**
 * Import / Export page
 * @module
 */
module.exports = StormView.extend(/** @lends DashboardSectionView.prototype */{
	template: require('./import-export-template'),

	events: {
		'click .js-import': 'onImportClick',
		'click .js-export': 'onExportClick',
		'change .js-import-input': 'onFileChange'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.isFetchingPoll = false
		this.interval = null
		this.isSuccess = false
		this.error = null
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			isProcessing: this.interval !== null,
			isSuccess: this.isSuccess,
			error: this.error
		}
	},

	afterRender: function() {
		App.stopLoad()
	},

	onImportClick: function() {
		$('.js-import-input').click()
	},

	disableButtons: function(disable) {
		$('.js-import').prop("disabled", disable)
		$('.js-export').prop("disabled", disable)
	},

	onExportClick: function() {
		App.startLoad()
		this.disableButtons(true)

		Backbone.sync('fetch', new Backbone.Model(), {
			url: Storm.app.url() + '/translation?type=csv',

			dataType: 'text',

			success: function(data) {
				window.URL = window.URL || window.webkitURL

				var file = new Blob([data], {type: 'text/csv'}),
					url  = window.URL.createObjectURL(file),
					link = document.createElement('a')

				var name = Storm.app.get('name')
				var appName = name[Object.keys(name)[0]]
				var society = App.societiesList.get(Storm.app.get('societyId'))

				link.href = url
				link.download = 'translation-export-' + appName + (society ? '-' + society.get('name') : '')
				link.target = '_blank'

				link.click()

				App.stopLoad()
				this.disableButtons(false)
			}.bind(this),

			error: function() {
				App.stopLoad()
				swal($.t('error.oops'), $.t('appSetup.translationError'), 'error')
				this.disableButtons(false)
			}.bind(this)
		})
	},

	onFileChange: function(e) {
		this.disableButtons(true)
		this.isSuccess = false
		const input = e.currentTarget
		if (input.files && input.files[0]) {
			var self = this
			swal({
				title: $.t('editor.inspector.areYouSure'),
				text: $.t('translations.importExport.importConfirmation', {name: input.files[0].name}),
				showCancelButton: true
			}, function(didConfirm) {
				if (didConfirm) {
					self.importTranslation(input.files[0])
					.then(function(resp) {
						var id = resp.id
						self.beginPolling(id)
						self.updatePollUI(resp.status)
					})
					.fail(function(err) {
						console.log(err)
						$('.js-import-input').val("")
						self.disableButtons(false)
						self.translationError(err)
					})
				} else {
					// clear input
					$('.js-import-input').val("")
					self.disableButtons(false)
				}
			})
		}
	},

	translationError: function(err) {
		var error = ''
		if (err.responseJSON && err.responseJSON['client error'] && err.responseJSON['client error'].data) {
			error = err.responseJSON['client error'].data.details
		}
		swal($.t('error.oops'), $.t('appSetup.translationErrorParse', {error: error}), 'error')
	},

	importTranslation: function(file) {
		var formData = new FormData()

		formData.append('files', file)
		App.startLoad()
		return $.ajax({
			url: Storm.app.url() + '/TranslationsCSV',
			data: formData,
			type: 'POST',
			contentType: false,
			processData: false,
			global: false,
			headers: App.session.getHeadersObject(),
			complete: App.stopLoad
		})
	},

	beginPolling: function(id) {
		this.interval = setInterval(this.poll.bind(this), 5000, id)
	},

	poll: function(id) {
		if (!this.isFetchingPoll) {
			$.ajax({
				url: App.apiRoot + 'translation/' + id + '/poll',
				type: 'GET',
				contentType: false,
				processData: false,
				global: false,
				headers: App.session.getHeadersObject()
			})
			.then(this.processPoll.bind(this))
			.fail(function(err) {
				this.translationError(err)
				clearInterval(this.interval)
				this.interval = null
				this.render()
			}.bind(this))
		}
	},

	processPoll: function(resp) {
		/**
		 * resp.status
		 * 0 In Progress
		 * 2 Finished
		 * -1 Broken
		 */
		if (resp.status === 2) {
			console.log('Finished')
			clearInterval(this.interval)
			this.disableButtons(false)
			$('.js-import-input').val("")
			this.interval = null
			this.isSuccess = true
		}
		this.updatePollUI(resp.status)
	},

	updatePollUI: function() {
		this.render()
	}
})
