<div class="disaster-box" data-selection-id="{{selectorId}}">
  <div class="form-inline disaster-category-form">
    <div class="form-group mt-5">
      <label for="category-title">{{t "hazards.disasters.titleStr"}}</label>
      <input type="text" class="form-control category-title" id="category-title" data-selection-id="{{selectorId}}" placeholder="Add title" value="{{getTextForLang model.name currentLang}}">
    </div>
    <div class="form-group mt-5">
      <label for="category-code">{{t "hazards.disasters.code"}}</label>
      <input type="text" class="form-control category-code" id="category-code" data-selection-id="{{selectorId}}" placeholder="Add code" value="{{model.code}}">
    </div>
    <div class="form-group mt-5">
      <label>{{t "hazards.disasters.icon"}}</label>
      <button class="button circle update-category-icon" data-category-id="{{model.id}}" data-selection-id="{{selectorId}}" style="{{#if meta}} {{#if meta.icon.src.x1}} background-image: url('{{getPreviewUrl meta.icon}}'); background-color: #fff; {{else}}text-shadow: none; {{/if}} {{else}} text-shadow: none; {{/if}}">
        <i class="icon-plus"></i>
      </button>
    </div>
    <div class="form-group mt-5">
      <label>{{t "hazards.disasters.page"}}</label>
      <select class="update-page" data-category-id="{{model.id}}" data-selection-id="{{selectorId}}">
        {{{pages}}}
      </select>
    </div>
    <div class="form-group mt-5">
      <label>{{t "hazards.disasters.order"}}</label>
      <i class="icon-arrow-up move-category-up blue-settings"></i>
      <i class="icon-arrow-down move-category-down blue-settings"></i>
    </div>

    <div class="form-group mt-5">
      <i class="icon-trash delete-category red-delete"></i>
    </div>
  </div>
  <div class="disaster-event-box" data-selection-id="{{selectorId}}">

  </div>
</div>
