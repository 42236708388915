<td><img style="width:100px" src="{{getPreviewUrl image}}"></td>
<td>
	<dl>
		{{#key_value name.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value description.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value cta.title.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
	<dl>
		{{price}}
	</dl>
</td>
<td>
	<dl>
		{{#key_value priceText.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/products-shop/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
