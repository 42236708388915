<div class="row">
	<div class="col-xs-12">
		<h3>{{t "metadata.title"}}</h3>
	</div>
	<div class="col-xs-12">
		<form class="form-horizontal">
            <fieldset>
                <legend>{{t "metadata.shared"}}</legend>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-title">{{t "metadata.appStoreTitle"}}</label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <input class="form-control"
                                       name="title"
                                       data-lang="{{this.code}}"
                                       type="text"
                                       value="{{getTextForLang ../sharedMeta.title this.code}}"
                                       maxlength="30">
                            </div>
                        {{/each}}
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-date">{{t "metadata.launchDate"}}</label>
                    <div class="controls col-sm-9">
                        <input type="text" class="form-control" name="date" id="field-date" value="{{sharedMeta.date}}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-name">{{t "metadata.nameOnDevice"}}</label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <input class="form-control"
                                       name="name"
                                       data-lang="{{this.code}}"
                                       type="text"
                                       value="{{getTextForLang ../sharedMeta.name this.code}}"
                                       maxlength="30">
                            </div>
                        {{/each}}
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-descriptions.full">{{t "metadata.description"}}</label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <textarea class="form-control"
                                          name="descriptions.full"
                                          data-lang="{{this.code}}">{{getTextForLang ../sharedMeta.descriptions.full this.code}}</textarea>
                            </div>
                        {{/each}}
                    </div>
                </div>

								<div class="form-group">
										<label class="control-label col-sm-3" for="field-features">{{t "metadata.features"}}</label>
										<div class="controls col-sm-9">
												{{#each languages}}
														<div class="input-group">
																<span class="input-group-addon">{{this.code}}</span>
																<textarea class="form-control"
																					name="features"
																					data-lang="{{this.code}}">{{getTextForLang ../sharedMeta.features this.code}}</textarea>
														</div>
												{{/each}}
										</div>
								</div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-keywords">{{t "metadata.keywords"}}</label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <input class="form-control"
                                       name="keywords"
                                       data-lang="{{this.code}}"
                                       type="text"
                                       value="{{getTextForLang ../sharedMeta.keywords this.code}}"
                                       maxlength="30">
                            </div>
                        {{/each}}
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-categories.primary">{{t "metadata.primaryCategory"}}</label>
                    <div class="controls col-sm-9">
                        <select class="form-control" name="categories.primary" id="field-categories.primary">
                            {{#each categories}}
                                <option
                                        {{#ifEqual this ../sharedMeta.categories.primary}}selected{{/ifEqual}}
                                >{{this}}</option>
                            {{/each}}
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-categories.secondary">{{t "metadata.secondaryCategory"}}</label>
                    <div class="controls col-sm-9">
                        <select class="form-control" name="categories.secondary" id="field-categories.secondary">
                            {{#each categories}}
                                <option
                                        {{#ifEqual this ../sharedMeta.categories.secondary}}selected{{/ifEqual}}
                                >{{this}}</option>
                            {{/each}}
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-copyright">{{t "metadata.copyright"}}</label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <input class="form-control"
                                       name="copyright"
                                       data-lang="{{this.code}}"
                                       type="text"
                                       value="{{getTextForLang ../sharedMeta.copyright this.code}}"
                                       maxlength="30">
                            </div>
                        {{/each}}
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-email">
                        {{t "common.email"}}
                        <span class="icon-question-sign"
                              title="{{t "metadata.emailHint"}}"
                              data-toggle="tooltip"
                              data-placement="right"
                        ></span>
                    </label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <input class="form-control"
                                       name="email"
                                       data-lang="{{this.code}}"
                                       type="text"
                                       value="{{getTextForLang ../sharedMeta.email this.code}}"
                                       maxlength="30">
                            </div>
                        {{/each}}
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-urls.support">
                        {{t "metadata.supportUrl"}}
                        <span class="icon-question-sign"
                              title="{{t "metadata.supportUrlHint"}}"
                              data-toggle="tooltip"
                              data-placement="right"
                        ></span>
                    </label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <input class="form-control"
                                       name="urls.support"
                                       data-lang="{{this.code}}"
                                       type="text"
                                       value="{{getTextForLang ../sharedMeta.urls.support this.code}}"
                                       maxlength="30">
                            </div>
                        {{/each}}
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-urls.marketing">
                        {{t "metadata.website"}}
                        <span class="icon-question-sign"
                              title="{{t "metadata.websiteHint"}}"
                              data-toggle="tooltip"
                              data-placement="right"
                        ></span>
                    </label>
                    <div class="controls col-sm-9">
                        {{#each languages}}
                            <div class="input-group">
                                <span class="input-group-addon">{{this.code}}</span>
                                <input class="form-control"
                                       name="urls.marketing"
                                       data-lang="{{this.code}}"
                                       type="text"
                                       value="{{getTextForLang ../sharedMeta.urls.marketing this.code}}"
                                       maxlength="30">
                            </div>
                        {{/each}}
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-countries">{{t "gdpcConsole.nationalSocieties.countries"}}</label>
                    <div class="controls col-sm-9">
                        <select class="form-control" name="countries" id="field-countries" multiple>
                            {{#each countries}}
                                <option value="{{this.code}}"
                                        {{#if this.selected}}selected{{/if}}
                                >{{this.name}}</option>
                            {{/each}}
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-languages">{{t "metadata.languages"}}</label>
                    <div class="controls col-sm-9">
                        <select class="form-control" name="languages" id="field-languages" multiple>
                            {{#each languages}}
                                <option value="{{this.code}}"
                                        {{#if this.selected}}selected{{/if}}
                                >{{this.language.name.native}} ({{toUpperCase this.code}})</option>
                            {{/each}}
                        </select>
                    </div>
                </div>

                <div class="form-group" style="display: none;">
                    <label class="control-label col-sm-3" for="field-rating">{{t "metadata.rating"}}</label>
                    <div class="controls col-sm-9">
                        <input type="text" class="form-control" name="rating" id="field-rating" value="{{sharedMeta.rating}}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3" for="field-phone">
                        {{t "metadata.phoneNumber"}}
                        <span class="icon-question-sign"
                              title="{{t "metadata.phoneNumberHint"}}"
                              data-toggle="tooltip"
                              data-placement="right"
                        ></span>
                    </label>
                    <div class="controls col-sm-9">
                        <input type="text" class="form-control" name="phone" id="field-phone" value="{{sharedMeta.phone}}">
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <legend>{{t "metadata.platformSpecific"}}</legend>

                <div class="filter-bar">
                    {{#each platforms}}
                        <a href data-platform="{{platformName}}">{{platformName}}</a>
                    {{/each}}
                </div>

                {{#each platforms}}
                    <fieldset class="platform-details" data-platform="{{platformName}}">
                        <div class="form-group">
                            <label class="control-label col-sm-3" for="{{platformName}}_field-media.icon">
                                {{t "metadata.appIcon"}}
                                <span class="icon-question-sign"
                                      title="{{t "metadata.appIconHint"}}"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                ></span>
                            </label>
                            <div class="controls col-sm-9">
                                <div class="thumbnail metadata-thumbnail">
                                    <img id="{{platformName}}_field-media.icon-preview" src="{{getPreviewUrl platformMeta.media.attributes.icon}}" />
                                    <div class="caption">
                                        <button type="button" class="btn btn-primary choose-icon-button">{{t "editor.inspector.properties.image.chooseButton"}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group" style="display: none;">
                            <label class="control-label col-sm-3" for="{{platformName}}_field-price">{{t "metadata.price"}}</label>
                            <div class="controls col-sm-9">
                                <input type="text" class="form-control" name="{{platformName}}_price" id="{{platformName}}_field-price" value="{{platformMeta.price}}">
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-sm-3" for="{{platformName}}_field-media.screenshots">
                                {{t "bugs.screenshots"}}
                                <span class="icon-question-sign"
                                      title="{{t "metadata.screenshotsHint"}}"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                ></span>
                            </label>
                            <div class="controls col-sm-9">
                                <div class="row form-group col-sm-12">
                                    <button type="button" class="btn btn-primary add-screenshot-button">{{t "metadata.addScreenshot"}}</button>
                                </div>
                                <div id="{{platformName}}_field-media.screenshots-previews" class="row col-sm-12"></div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-sm-3" for="{{platformName}}_field-preview.preview">
                                {{t "metadata.appPreviewVideo"}}
                                <span class="icon-question-sign"
                                      title="{{t "metadata.appPreviewVideoHint"}}"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                ></span>
                            </label>
                            <div class="controls col-sm-9">
                                <div class="thumbnail metadata-thumbnail">
                                    <video id="{{platformName}}_field-media.preview-preview"
                                           src="{{#if platformMeta.media.attributes.preview.src.destination}}{{remoteUrl platformMeta.media.attributes.preview.src.destination}}{{/if}}"
                                           controls
                                           preload="metadata"
                                    ></video>
                                    <div class="caption">
                                        <button type="button" class="btn btn-primary choose-video-button">{{t "editor.inspector.properties.video.chooseButton"}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {{#ifEqual platformName 'Android'}}
                            <div class="form-group">
                                <label class="control-label col-sm-3" for="{{platformName}}_field-descriptions.short">{{t "metadata.shortAppDescription"}}</label>
                                <div class="controls col-sm-9">
                                    {{#each ../languages}}
                                        <div class="input-group">
                                            <span class="input-group-addon">{{this.code}}</span>
                                            <input class="form-control"
                                                   name="descriptions.short"
                                                   data-lang="{{this.code}}"
                                                   data-platform="{{../platformName}}"
                                                   type="text"
                                                   value="{{getTextForLang ../platformMeta.descriptions.short this.code}}"
                                                   maxlength="80">
                                        </div>
                                    {{/each}}
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="control-label col-sm-3" for="{{platformName}}_field-media.feature">
                                    {{t "metadata.featureGraphic"}}
                                    <span class="icon-question-sign"
                                          title="{{t "metadata.featureGraphicHint"}}"
                                          data-toggle="tooltip"
                                          data-placement="right"
                                    ></span>
                                </label>
                                <div class="controls col-sm-9">
                                    <div class="thumbnail metadata-thumbnail">
                                        <img id="{{platformName}}_field-media.feature-preview" src="{{getPreviewUrl platformMeta.media.attributes.feature}}" />
                                        <div class="caption">
                                            <button type="button" class="btn btn-primary choose-featured-graphic-button">{{t "editor.inspector.properties.image.chooseButton"}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {{/ifEqual}}
                    </fieldset>
                {{/each}}
            </fieldset>

            <button type="button" class="btn btn-primary save-button">{{t "common.save"}}</button>
		</form>
	</div>
</div>
