<form>
	<div class="row">
		<div class="col-sm-6">
			<h3 data-i18n="searchTerms.editSearchTerm"></h3>
		</div>
		<div class="col-sm-6 actions-col">
			<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
			<button class="button green wide save" type="submit">{{t "common.save"}}</button>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-6">
			<div class="form-group">
				<h4 data-i18n="common.page"></h4>
			</div>
			<div class="link-picker form-group"></div>
		</div>
	</div>

	<h4 data-i18n="searchTerms.tags"></h4>

</form>
