<div class="app-error" id="noItems" style="display:none;">{{t "common.noItems"}}</div>
<table>
	<thead>
		<tr>
			<th style="width:100px"></th>
			<th>{{t "editor.inspector.properties.product.name.title"}}</th>
			<th>{{t "editor.inspector.properties.product.description.title"}}</th>
			<th>{{t "editor.inspector.properties.product.cta.title"}}</th>
			<th>{{t "editor.inspector.properties.product.price.title"}}</th>
			<th>{{t "editor.inspector.properties.product.priceText.title"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
