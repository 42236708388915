var MediaLibrary          = require('media-library/media-library-view'),
	MediaSelectorView       = require('media-library/media-selector-view')

module.exports = Backbone.View.extend({
	className: 'moduleBlock',
	template: require('./module-block-view-template'),

	attributes: function() {
		return {
			id: this.model.get('id')
		}
	},

	/** @override */
	events: {
		'input .text-content': 'updateText',
		'change .text-input-area': 'toggleTextArea',
		'input .task-content': 'updateText',
		'input .citation-content': 'updateCitation',
		'input .checkRadio-title': 'updateTextFromInput',
		'input .select-placeholder': 'updatePlacholder',
		'input .list-title': 'updateTitle',
		'input .input-content': 'updateInput',
		'input .help-content': 'updateText',
		'input .identifier': 'updateIdentifier',
		'input .link-content': 'updateLink',
		'input .link-src-content': 'updateLinkSrc',
		'input .link-title-content': 'updateLinkTitle',
		'click .add-block-image': 'selectImage',
		'click .add-block-gif': 'selectGif',
		'click .add-checkbox-radio': 'addCheckboxRadio',
		'click .add-list-item': 'addListItem',
		'input .slider': 'updateSlider',
		'click .remove-check-radio': 'removeCheckRadio',
		'input .update-check-radio': 'updateCheckRadio',
		'input .update-meta': 'updateMeta',
		'click .remove-list-item': 'removeListItem',
		'input .update-list-item': 'updateListItem',
		'input .js-pdf-title': 'updatePdfTitle',
		'input .js-pdf-subtitle': 'updatePdfSubtitle',
		'input .js-pdf-content': 'updatePdfContent',
		'click .js-pdf-content-btn': 'togglePdfContentBtn'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.id = options.id
		this.selectorId = options.selectorId
		this.parent = options.parent
		this.language = options.language
		this.index = options.index
		this.sectionIndex = options.sectionIndex
		this.style = options.style
		if (this.parent) {
			if (this.parent.model) {
				this.pageId = this.parent.model.get('pageId')
			}
		}
	},

	ready: function() {
	},

	getRenderData: function() {
		var identifierExists = true
		if (this.model.get('identifier') === undefined) {
			identifierExists = false
		}
		return {
			model: this.model.toJSON(),
			identifierExists: identifierExists,
			selectorId: this.selectorId,
			index: this.index,
			sectionIndex: this.sectionIndex,
			style: this.parseStyle(),
			language: this.language
		}
	},

	parseStyle: function() {
		var properties = {}
		if (this.style) {
			this.style.get('properties').forEach(function(prop) {
				properties[prop.get('value')] = true
			})
		}
		return properties
	},

	updateTitle: function(e) {
		var value = $(e.currentTarget).val()
		var text = this.model.get('title')
		text.content[this.language] = value
		this.model.trigger('change')
	},

	updateText: function(e) {
		var value = this.updateEditable(e.currentTarget)
		var data
		if (this.model.get('class') === "R4b_TextAssessmentBlock") {
			data = this.model.get('text')
		} else {
			data = this.model.get('data')
		}

		data.content[this.language] = value
		this.model.trigger('change')
	},

	toggleTextArea: function() {
		var area = this.model.get('area')
		if (this.model.has('area')) {
			this.model.set('area', !area)
		} else {
			this.model.set('area', true)
		}
		this.model.trigger('change')
	},

	updateCitation: function(e) {
		var value = this.updateEditable(e.currentTarget)

		var text = this.model.get('citation')
		text.content[this.language] = value
		this.model.trigger('change')
	},

	updateInput: function(e) {
		var value = this.updateEditable(e.currentTarget)

		var text = this.model.get('text')
		text.content[this.language] = value
		this.model.trigger('change')
	},

	updateTextFromInput: function(e) {
		var value = $(e.currentTarget).val()
		var text = this.model.get('text')
		text.content[this.language] = value
		this.model.trigger('change')
	},

	updatePlacholder: function(e) {
		var value = $(e.currentTarget).val()
		var text = this.model.get('placeholder')
		if (!text) {
			text = App.getClassStructure('Text', this.pageId)
			this.model.set('placeholder', text)
		}
		text.content[this.language] = value
		this.model.trigger('change')
	},

	updateLink: function(e) {
		var value = $(e.currentTarget).val()
		var data = this.model.get('data')
		data.src.destination = value
		this.model.trigger('change')
	},

	updateLinkSrc: function(e) {
		var value = $(e.currentTarget).val()
		var data = this.model.get('link')
		data.destination.content[this.language] = value

		this.model.trigger('change')
	},

	updateLinkTitle: function(e) {
		var value = $(e.currentTarget).val()
		var data = this.model.get('link')
		data.title.content[this.language] = value

		this.model.trigger('change')
	},

	updateIdentifier: function(e) {
		var formattedIdentifier = $(e.currentTarget).val().replace(/[.,\s]/g, '')
		$(e.currentTarget).val(formattedIdentifier)
		var previousIdentifer = this.model.get('identifier')
		var newIdentifer = formattedIdentifier

		if (previousIdentifer !== newIdentifer) {
			this.model.set('identifier', formattedIdentifier)
			this.model.trigger('change')
		}
	},

	showMediaSelect: function(model, mediaType) {
		this.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: model,
			mediaType: mediaType
		})

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	showGifSelect: function(model, mediaType) {
		this.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: model,
			mediaType: mediaType
		})

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	selectImage: function(e) {
		var property = $(e.currentTarget).data('property')
		var model = property ? this.model.get(property) : this.model.get('image')

		var propertyComponents = property.split('..')
		var mediaType = MediaLibrary.types.IMAGE

		if (propertyComponents[propertyComponents.length - 1] === 'icon') {
			mediaType = MediaLibrary.types.ICON
		}

		// Show media library
		this.showMediaSelect(model, mediaType)

		this.mediaLibrary.on('change', function() {
			this.model.trigger('change')
			this.updateImagePreview()
		}, this)
	},

	selectGif: function() {
		var model

		// Support legacy class structure.
		if (this.model.has('animation')) {
			model = this.model.get('animation').get('frames')
		} else if (this.model.get('data..images') && this.model.get('data..images')[0] && this.model.get('data..images')[0].src) {
			model = new Backbone.Collection()
			model.parent = this.model
		} else {
			model = new Backbone.Collection(this.model.get('data..images'))
			model.parent = this.model
		}

		this.showMediaSelect(model, MediaLibrary.types.ANIMATION)

		this.mediaLibrary.on('change', function() {
			this.model.set("data..images", model.toJSON())

			this.model.trigger('change')
			this.updateGifPreview()
		}, this)
	},

	updateImagePreview: function() {
		var style = Handlebars.helpers.getBackgroundImageStyle(this.model.get('data').src, 'contain')
		$('.add-block-image[data-id=' + this.selectorId + ']').attr('style', style)
	},

	updateGifPreview: function() {
		var previewImage = this.model.get('data').src ? this.model.get('data').src : this.model.get('data..images')[0].src

		var style = Handlebars.helpers.getBackgroundImageStyle(previewImage, 'contain')
		$('.add-block-gif[data-id=' + this.selectorId + ']').attr('style', style)
	},

	updateSlider: function() {
		var start = $('#' + this.selectorId + '-start').val(),
			length = $('#' + this.selectorId + '-length').val(),
			value = $('#' + this.selectorId + '-value').val(),
			interval = $('#' + this.selectorId + '-interval').val(),
			startLabel = $('#' + this.selectorId + '-startLabel').val(),
			endLabel = $('#' + this.selectorId + '-endLabel').val()

		this.model.set('endLabel..content..' + this.language, endLabel)
		this.model.set('startLabel..content..' + this.language, startLabel)
		this.model.set('range..start', start)
		this.model.set('range..length', length)
		this.model.set('value', value)
		this.model.set('interval', interval)
	},

	removeCheckRadio: function(e) {
		var indexToDelete = $(e.currentTarget).data('index')
		if (indexToDelete >= 0) {
			var model = this.model.get('options').at(indexToDelete)
			this.parent.parent.addToDelete(model, 'fullModel')
			var modelParent = model.collection
			this.model.get('options').remove(model)
			this.model.trigger('change')
			this.render()
			model.collection = modelParent // Re-add parent so it can be referenced for deletion
		}
	},

	updateCheckRadio: function(e) {
		var value = this.updateEditable(e.currentTarget)
		var index = $(e.currentTarget).data('index')

		var text = this.model.get('options').at(index)
		text.get('text..content')[this.language] = value
		this.model.trigger('change')
	},

	updateMeta: function(e) {
		var value = this.updateEditable(e.currentTarget)
		var index = $(e.currentTarget).data('index')

		var text = this.model.get('options').at(index)
		text.set('meta', value)

		this.model.trigger('change')
	},

	addCheckboxRadio: function() {
		var toAdd = $('#' + this.selectorId + '-CheckRadioAdd').val().trim()
		var options = this.model.get('options')
		if (!this.existsInOptions(toAdd, options, true) && toAdd !== '') {
			var textObject = App.getClassStructure('R4b_InputOption', this.pageId)
			textObject.text.content[this.language] = toAdd
			options.push(textObject)
			this.model.trigger('change')
			this.render()
		}
	},

	addListItem: function() {
		var toAdd = $('#' + this.selectorId + '-ListAdd').val().trim()
		var data = this.model.get('data')
		if (!this.existsInOptions(toAdd, data, false) && toAdd !== '') {
			var textObject = App.getClassStructure('Text', this.pageId)
			textObject.content[this.language] = toAdd
			data.push(textObject)
			this.model.trigger('change')
			this.render()
		}
	},

	updateListItem: function(e) {
		var value = this.updateEditable(e.currentTarget)
		var index = $(e.currentTarget).data('index')

		var text = this.model.get('data').at(index)
		text.get('content')[this.language] = value
		this.model.trigger('change')
	},

	removeListItem: function(e) {
		var indexToDelete = $(e.currentTarget).data('index')
		if (indexToDelete >= 0) {
			var model = this.model.get('data').at(indexToDelete)
			this.parent.parent.addToDelete(model, 'fullModel')
			var modelParent = model.collection
			this.model.get('data').remove(model)
			this.model.trigger('change')
			this.render()
			model.collection = modelParent // Re-add parent so it can be referenced for deletion
		}
	},

	existsInOptions: function(toAdd, options, isInputOption) {
		var exists = false
		options.forEach(function(option) {
			var content = isInputOption ? option.get('text..content') : option.get('content')
			if (toAdd === content[this.language].trim()) {
				exists = true
			}
		}.bind(this))
		return exists
	},

	updateEditable: function(element) {
		var caretPosition = this.getCaretPosition(element)
		var value = this.stripTags(element)
		$(element).html(value)
		this.setCaretPosition(element, caretPosition)
		return value
	},

	stripTags: function(element) {
		// inner Text does not work in firefox so...
		if (element.innerText) {
			return element.innerText
		} else if (element.html) {
			return element.html().replace(/<br>/g, '\n')
		}
		return ''
	},

	getCaretPosition: function(element) {
		// http://stackoverflow.com/questions/16736680/get-caret-position-in-contenteditable-div-including-tags
		var caretOffset = 0
		if (typeof window.getSelection !== "undefined") {
			var range = window.getSelection().getRangeAt(0)
			var preCaretRange = range.cloneRange()
			preCaretRange.selectNodeContents(element)
			preCaretRange.setEnd(range.endContainer, range.endOffset)
			caretOffset = preCaretRange.toString().length
		} else if (typeof document.selection !== "undefined" && document.selection.type !== "Control") {
			var textRange = document.selection.createRange()
			var preCaretTextRange = document.body.createTextRange()
			preCaretTextRange.moveToElementText(element)
			preCaretTextRange.setEndPoint("EndToEnd", textRange)
			caretOffset = preCaretTextRange.text.length
		}
		return caretOffset
	},

	setCaretPosition: function(editable, position) {
		// http://stackoverflow.com/questions/40632975/set-caret-position-in-a-content-editable-element
		var range = document.createRange()
		var sel = window.getSelection()
		try {
			range.setStart(editable.childNodes[0], position)
			range.collapse(true)
			sel.removeAllRanges()
			sel.addRange(range)
			editable.focus()
		} catch (error) {
			// Empty childnode or pasting in unrecognisable stuff..
			return
		}
	},

	updatePdfTitle: function(e) {
		var content = this.updateEditable(e.currentTarget)
		if (!this.model.get('pdf_title') || this.model.get('pdf_title') === undefined) {
			var newModel = App.getClassStructure('Text', this.pageId)
			this.model.set('pdf_title', newModel)
		}
		this.model.set('pdf_title..content..' + this.language, content)
		this.model.trigger('change')
	},

	updatePdfSubtitle: function(e) {
		var content = this.updateEditable(e.currentTarget)
		if (!this.model.get('pdf_subtitle') || this.model.get('pdf_subtitle') === undefined) {
			var newModel = App.getClassStructure('Text', this.pageId)
			this.model.set('pdf_subtitle', newModel)
		}
		this.model.set('pdf_subtitle..content..' + this.language, content)
		this.model.trigger('change')
	},

	updatePdfContent: function(e) {
		var content = this.updateEditable(e.currentTarget)
		if (!this.model.get('pdf_content') || this.model.get('pdf_content') === undefined) {
			var newModel = App.getClassStructure('Text', this.pageId)
			this.model.set('pdf_content', newModel)
		}
		this.model.set('pdf_content..content..' + this.language, content)

		this.model.trigger('change')
	},

	togglePdfContentBtn: function() {
		$('.pdf-content[data-selection="' + this.selectorId + '"]').slideToggle()
	}
})
