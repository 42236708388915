var logicParseHelper = require('./helpers/logic-parser')

var LogicBuilder = Backbone.View.extend({
	template: require('./section-logic-view-builder-template'),

	/** @override */
	events: {
		'change .logic-module': 'updateModule',
		'change .logic-block': 'updateBlock',
		'change .logic-operator': 'updateOperator',
		'input .logic-value': 'updateValue',
		'change .logic-module-new': 'updateNewModule',
		'change .logic-block-new': 'updateNewBlock',
		'click .remove-term': 'removeTerm',
		'click .add-term': 'addTerm',
		'click .add-new-child': 'addChild',
		'change .logic-prefix': 'updatePrefix'
	},

	initialize: function(options) {
		this.parsed = options.parsed
		this.parent = options.parent
		this.modules = options.modules
		this.selectorId = options.selectorId
		this.newTerm = {
			operator: "="
		} // Bool to identify if we have started creating a new term or not.
	},

	getRenderData: function() {
		return {
			selectorId: this.selectorId + this.cid,
			parsed: this.parsed,
			modules: this.modules,
			newTerm: this.newTerm
		}
	},

	afterRender: function() {
		for (var i = 0; i < this.parsed.children.length; i++) {
			var logicBuilder = new LogicBuilder({
				parsed: this.parsed.children[i],
				modules: this.modules,
				parent: this.parent,
				selectorId: this.selectorId
			})
			this.$('.js-children').append(logicBuilder.render().el)
		}

		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	getValues: function(e) {
		// Get values
		var value = $(e.currentTarget).val()
		var index = $(e.currentTarget).data('index')
		var termId = $(e.currentTarget).data('id')
		var parentId = $(e.currentTarget).data('parent-id')
		var selected = $(e.currentTarget).find('option:selected')
		var type = null
		if (selected) {
			type = selected.data('type')
		}

		return {
			value: value,
			index: index,
			termId: termId,
			parentId: parentId,
			type: type
		}
	},

	updatePrefix: function(e) {
		var value = $(e.currentTarget).val()
		this.parsed.prefix = value
		this.parent.trigger("force:render")
	},

	// UPDATES to a term
	updateModule: function(e) {
		// Trigger event for parent to listen to
		this.parent.trigger("update:module", this.getValues(e))
	},

	updateBlock: function(e) {
		this.parent.trigger("update:block", this.getValues(e))
	},

	updateOperator: function(e) {
		this.parent.trigger("update:operator", this.getValues(e))
	},

	updateValue: function(e) {
		this.parent.trigger("update:value", this.getValues(e))
	},
	// END UPDATES to a term

	// UPDATES to a new term
	updateNewModule: function(e) {
		const values = this.getValues(e)
		this.newTerm.module = values.value
		this.newTerm.blocks = logicParseHelper.getBlocks(values.value.trim(), new Backbone.Collection(this.modules))
		this.render() // Updated modules, rerender to update the blocks
	},

	updateNewBlock: function(e) {
		const values = this.getValues(e)
		this.newTerm.block = values.value
		this.newTerm.valueType = values.type
		this.render() // Updated modules, rerender to update the blocks
	},

	addTerm: function() {
		var module = this.$(".logic-module-new").val()
		var block = this.$(".logic-block-new").val()
		var operator = this.$(".logic-operator-new").val()
		var value = this.$(".logic-value-new").val()

		if (module !== "-" && block !== "-" && operator && (value || this.newTerm.value === 0)) {
			this.parsed.terms.push({
				module: module,
				block: block,
				operator: operator,
				value: value
			})

			this.parent.trigger("force:render")
		}
	},
	// END UPDATES to a new term

	removeTerm: function(e) {
		var values = this.getValues(e)

		var index = this.parsed.terms.findIndex(function(term) {
			return term.id === values.termId
		})

		if (index > -1) {
			this.parsed.terms.splice(index, 1)
			if (this.parsed.terms.length === 0) {
				this.parent.trigger("remove:parent", values.parentId)
			} else {
				this.parent.trigger("force:render")
			}
		}
	}
})

module.exports = LogicBuilder
