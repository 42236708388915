module.exports = Backbone.View.extend({
	template: require('./grid-view-inspector-template'),

	events: {
		'change .grid-type-select': 'gridTypeChange',
		'change .grid-columns': 'columnsChange'
	},

	// WARNING: afterRender currently called on grid type change cancel
	afterRender: function() {
		if (this.model && this.model.get('class')) {
			this.$('.grid-type-select').val(this.model.get('class'))
		}
	},

	gridTypeChange: function() {
		if (confirm($.t('editor.inspector.properties.confirmGridTypeChange'))) {
			App.startLoad()

			var newClass = this.$('.grid-type-select').val()
			var newCellClass = newClass.replace('View', 'Cell')

			// Set new view class, update child cells
			this.model.set('class', newClass)
			this.model.get('children').each(function(cell) {
				// Create new cell, copy shared properties across
				var newCell = App.getClassStructure(newCellClass, cell.get('pageId'))

				// Copy title, if present on both types
				if ('title' in newCell && cell.has('title')) {
					newCell.title = cell.get('title')
					delete newCell.title.id
				}

				// Copy image, if present on both types
				if ('image' in newCell && cell.has('image')) {
					newCell.image = cell.get('image')
					delete newCell.image.id
					delete newCell.image.src.id
				}

				// All types have a link - copy
				newCell.link = cell.get('link')
				delete newCell.link.id
				delete newCell.link.title.id

				// Ensure link title is an empty object - no null keys
				newCell.link.title.content = {}

				// Replace old model with new in collection
				cell.unset('id')
				cell.attributes = newCell
			})

			// Save new model back to server once old model deleted
			this.model.once('sync', function() {
				this.model.unset('id')
				this.trigger('parentSave')
			}, this)

			// Delete old view on server
			this.model.destroy()
		} else {
			// Reset type
			this.afterRender()
		}
	},

	columnsChange: function() {
		var count = Number(this.$('.grid-columns').val())
		this.model.set('columns', count)
		this.trigger('change')
	}
})
