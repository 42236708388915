var StormObject    = require('editor/storm-object'),
	ViewItem       = require('./grid-view-item'),
	CanvasItemView = require('editor/canvas/canvas-item-view')

/**
 * Exports {@link GridView}.
 * @module
 */
module.exports = CanvasItemView.extend(/** @lends GridView.prototype */{
	/** @override */
	template: require('./grid-view-template'),

	/** @override */
	events: function() {
		var events = CanvasItemView.prototype.events()
		events['click .add'] = 'addItemClick'
		return events
	},

	/**
	 * @constructs GridView
	 * @extends CanvasItemView
	 * @override
	 */
	initialize: function() {
		CanvasItemView.prototype.initialize.apply(this, arguments)

		this.listViews = []
		this.collection = this.model.get('children')
		this.listenTo(this.collection, 'add remove reset', this.afterRender)
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		if (Storm.view && Storm.view.language) {
			data.language = Storm.view.language
		}
		return data
	},

	/** @override */
	afterRender: function() {
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []

		this.$('.view-children').empty()
		this.collection.each(this.addItem, this)

		// Hide add button if restricted
		var restrictions = this.model.get('restrictions')
		if (restrictions) {
			if (restrictions.indexOf('add') > -1) {
				this.$('.add').hide()
			}
		}

		if (this.model.editing) {
			this.startEditing()
		}
	},

	/**
	 * Propagate the startEditing event to all child cells.
	 * @override
	 */
	startEditing: function() {
		CanvasItemView.prototype.startEditing.apply(this, arguments)

		this.collection.forEach(function(child) {
			child.trigger('startEditing')
		})
	},

	/**
	 * Propagate the stopEditing event to all child cells.
	 * @override
	 */
	stopEditing: function() {
		CanvasItemView.prototype.stopEditing.apply(this, arguments)

		this.model.get('children').forEach(function(child) {
			child.trigger('stopEditing')
		})
	},

	addItem: function(model) {
		var view = new ViewItem({model: model})
		this.listViews.push(view)
		this.$('.view-children').append(view.render().el)
	},

	addItemClick: function(e) {
		var viewClass = this.model.get('class').replace('View', 'Cell')
		var view = StormObject.fromClassName(viewClass, this.model.get('pageId'))

		this.collection.push(view)
		this.model.save()

		// Show inspector for new element
		Storm.view.views.canvas.setInspector(view)

		e.stopPropagation()
	},

	click: function(e) {
		Storm.view.views.canvas.setInspector(this.model)

		this.$el.addClass('editing')
		e.stopPropagation()
	}
})
