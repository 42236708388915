<form class="mb-10">
	<div class="row">
		<div class="col-sm-6">
			<h3 data-i18n="richStories.editRichStory"></h3>
		</div>
		<div class="col-sm-6 actions-col">
			<a href="javascript:history.back()"><button class="button red wide" type="button">{{t
					"common.cancel"}}</button></a>
			<button class="button green wide save" type="submit">{{t "common.save"}}</button>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-6">
			<div class="form-group">
				<input type="checkbox" class="featured-input" id="featured" {{#if featured}}checked="checked" {{/if}} />
				<label class="control-label ml-10" for="featured">{{t "editor.inspector.properties.featured.title"}}</label>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.image.title"}}</label>
			</div>
			<div class="form-group">
				<img class="story-image-preview" src="{{getPreviewUrl image}}">
			</div>
			<div class="form-group">
				<button type="button" class="button choose-image-button mb-10">{{t
					"editor.inspector.properties.image.chooseButton"}}</button>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-6">
			<div class="form-group">
				<label for="category">{{t "editor.inspector.properties.category"}}</label>
				<select id="category" class="form-control category-input">
					<option value="lifesaving" {{#ifEqual category "lifesaving" }}selected="selected" {{/ifEqual}}>{{t
						"richStories.categories.lifesaving"}}</option>
					<option value="app" {{#ifEqual category "app" }}selected="selected" {{/ifEqual}}>{{t
						"richStories.categories.app"}}</option>
				</select>
			</div>
		</div>
		<div class="col-sm-6">
			<div class="link-picker form-group"></div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.title.title"}}</label>
			</div>
			{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.name.native}} ({{toUpperCase code}})</span>
					<input type="text" class="title-input form-control" data-code="{{code}}" />
				</div>
			</div>
			{{/each}}
		</div>


		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label">{{t "editor.inspector.properties.subtitle.title"}}</label>
			</div>
			{{#each locales}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{language.description.native}} ({{toUpperCase code}})</span>
					<input type="text" class="subtitle-input form-control" data-code="{{code}}" />
				</div>
			</div>
			{{/each}}
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" for="videoUrl">{{t "editor.inspector.properties.videoUrl.title"}}</label>
			</div>
			<div class="form-group">
				<input type="url" class="videoUrl-input form-control" id="videoUrl" placeholder="https://example.com" />
			</div>
		</div>
		<div class="col-sm-6">
			<div class="form-group">
				<label class="control-label" for="videoDuration">{{t "editor.inspector.properties.videoDuration.title"}}</label>
			</div>
			<div class="form-group">
				<input type="text" class="videoDuration-input form-control" placeholder="MM:SS" id="videoDuration" />
			</div>
		</div>
	</div>
</form>