<td>
	<div class="status-gem push-status-{{status}}" title="{{getPushStatus status}}"></div>
</td>
<td class="ns-name">{{getSocietyDescriptionById societyId}}</td>
<td>{{localisePushCategory category}}</td>
<td>{{getLocalDateTime schedule.start}}</td>
<td>{{getLocalDateTime schedule.end}}</td>
<td>{{notes}}</td>
<td>{{localisePushType payload.type}}</td>
<td class="center">{{counts.total}}</td>
<td class="center push-count-item">{{counts.sent}}</td>
<td class="center">{{user}}</td>
<td class="tools">
	<a href="/apps/{{appId}}/push/{{id}}/details">
		<button class="button" data-i18n="publish.details"></button>
	</a>
	<button class="button red delete" data-i18n="mediaLibrary.delete"></button>
</td>
