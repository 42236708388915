var AnimationPreviewView   = require('./animation-preview-view'),
	EmbeddedMediaPreviewView = require('./embedded-media-preview-view'),
	SponsorPreviewView = require('./sponsor-preview-view'),
	ProductPreviewView = require('./product-preview-view'),
	CanvasItemView           = require('./canvas-item-view'),
	APICompat                = require('lib/api-compat'),
	SponsorshipList = require('../../sponsorship/sponsorship-list'),
	utils                    = require('lib/utils'),
	StormQL                  = require('models/stormql')

/**
 * Exports {@link ListItemView}.
 * @module
 */
module.exports = CanvasItemView.extend(/** @lends ListItemView.prototype */{
	/** @override */
	tagName: 'li',
	/** @override */
	className: 'ListItem editable',
	/** @override */
	template: require('./list-item-view-template'),

	/**
	 * @constructs ListItemView
	 * @extends CanvasItemView
	 * @override
	 */
	initialize: function(options) {
		CanvasItemView.prototype.initialize.apply(this, arguments)

		this.views = {}
		this.slimSpotlight = options.slimSpotlight
		this.shouldWarnOnTranslation = options.shouldWarnOnTranslation === false ? options.shouldWarnOnTranslation : true

		var embeddedLinks = this.model.get('embeddedLinks')

		if (embeddedLinks) {
			this.listenTo(embeddedLinks, 'add remove reset', this.render)

			embeddedLinks.forEach(function(link) {
				this.listenTo(link, 'change', this.render, this)
			}, this)

			this.listenTo(embeddedLinks, 'add', function(link) {
				this.listenTo(link, 'change', this.render, this)
			})

			embeddedLinks.listenTo(embeddedLinks, 'remove', function(link) {
				this.stopListening(link, 'change')
			})
		}

		// If this contains an animation/spotlights, render a preview.
		var className = this.model.get('class')
		className = APICompat.normaliseClassName(className)

		var hasAnimation = className === 'SpotlightListItem' ||
			className === 'AnimationListItem'

		if (hasAnimation) {
			this.sponsorList = new SponsorshipList({appId: App.getCurrentApp().id})
			this.sponsorList.fetch().then(this.render.bind(this))
			// Use the slim spotlight if ARC
			this.views.animation = new AnimationPreviewView({model: this.model, sponsorList: this.sponsorList, slimSpotlight: App.system.id === 9})
		}

		if (className === 'SponsorshipListItem' || className === 'SponsorshipLogoListItem') {
			this.sponsorList = new SponsorshipList({appId: App.getCurrentApp().id})
			this.sponsorList.fetch().then(this.render.bind(this))
			this.views.sponsor = new SponsorPreviewView(this.model, this.sponsorList)
		}

		if (className === 'ProductListItemView') {
			this.listenTo(this.model, 'change:products', this.startProductCarousel, this)
			this.listenTo(this.model, 'stopTimeout', this.removeProductTimeout, this)

			this.productList = new StormQL(null, {app: App.getCurrentApp()})
			this.productList.once('sync', this.render, this)
			this.productList.fetch({
				data: {
					class: 'Product'
				}
			})
		}

		// Render embedded media preview.
		var embeddedMedia = this.model.get('embeddedMedia')

		if (embeddedMedia) {
			this.views.embeddedMedia = new EmbeddedMediaPreviewView({
				collection: embeddedMedia
			})
		}
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		data.language = Storm.view.language
		data.shouldWarnOnTranslation = this.shouldWarnOnTranslation

		if (data.images instanceof Backbone.Collection) {
			data.images = data.images.toJSON()
		}

		// Display link title for LogoListItem objects
		var className = this.model.get('class')
		if (className === 'LogoListItem') {
			data.showLinkTitle = true
		}

		return data
	},

	/** @override */
	afterRender: function() {
		CanvasItemView.prototype.afterRender.apply(this, arguments)

		// Render embedded links
		if (this.model.attributes.embeddedLinks !== undefined) {
			this.model.get('embeddedLinks').each(function(link) {
				var $button = $('<button>').addClass('button form-control')
					.text(App.l(link.get('title')))

				this.$('.text-container').append($button)
			}, this)
		}
		// Render embedded links
		if (this.views.sponsor) {
			this.$('.text-container').append(this.views.sponsor.render().el)
		}

		if (this.model.get('class') === 'ProductListItemView') {
			this.startProductCarousel()
		}
	},

	startProductCarousel: function() {
		if (this.productList.length > 0 && this.model.get('products').length > 0) {
			this.$('.text-container').remove()
			var index = 0
			var value = this.model.get('products').at(index).get('value')
			var model
			if (value === 'dummy') { // See list-item-preview-view, dummy product for inspector
				model = this.model.get('dummyProduct')
			} else {
				model = this.productList.get(value)
			}

			this.renderProductPreview(model, index)
		}
	},

	renderProductPreview: function(product, index) {
		this.removeProductTimeout()
		if (product) {
			this.views.product = new ProductPreviewView(product, {
				productCount: this.model.get('products').length,
				activeIndex: index
			})
			this.$('.product-container').empty()
			this.$('.product-container').append(this.views.product.render().el)

			var nextIndex = this.model.get('products').at(index + 1) ? index + 1 : 0
			var value = this.model.get('products').at(nextIndex).get('value')
			var nextModel = this.productList.get(value)
			var duration = 5

			if (product instanceof Backbone.Model) {
				duration = product.get('duration')
				duration = duration < 2 ? 2 : duration // Don't set it to less than 2 seconds
			}

			this.productTimeout = setTimeout(function() {
				this.renderProductPreview(nextModel, nextIndex)
			}.bind(this), duration * 1000)
		}
	},

	removeProductTimeout: function() {
		if (this.productTimeout) {
			clearTimeout(this.productTimeout)
		}
	},

	/** @override */
	beforeDestroy: function() {
		if (this.model.get('class') === 'ProductListItemView') {
			this.removeProductTimeout()
		}
	},

	click: function(e) {
		var apiClass        = this.model.get('class'),
			normalisedClass = APICompat.normaliseClassName(apiClass)

		if ($('.preview').hasClass('preview-mode')) {
			// Toggle content in ToggleableListItem objects.
			if (normalisedClass === 'ToggleableListItem') {
				this.$el.toggleClass('toggled')
			}

			// Navigate to the linked page if this object has one.
			var button = this.model.get('button')
			var target = $(e.target)

			var link = null
			if (this.model.get('class') === 'CertificateListItem') {
				if (target.hasClass('certificate-button')) {
					link = this.model.get('certificateButton').link
				}
				if (target.hasClass('course-button')) {
					link = this.model.get('courseButton').link
				}
			}

			if (button) {
				link = this.model.get('button').link
			}

			if (normalisedClass === 'StandardListItem') {
				link = this.model.get('link')
			}

			// QuizCollectionItem views link to a quiz under a 'quiz' key.
			if (normalisedClass === 'QuizCollectionItem') {
				link = this.model.get('quiz')
			}

			// Pick the correct language on a VideoListItem.
			var isVideoListItem = apiClass === 'VideoListItem' ||
				apiClass === 'MultiVideoListItemView'

			if (isVideoListItem) {
				var video = this.model.get('videos').first()

				this.model.get('videos').forEach(function(obj) {
					var locale = obj.get('locale')

					if (locale && Storm.view.language === locale.substr(4)) {
						video = obj
					}
				})

				if (video) {
					link = video.get('src')
				} else {
					// Fallback for old api
					link = this.model.get('link')
				}
			}

			if (link && link.destination) {
				var isExternal = link.class === 'ExternalLink' ||
					link.class === 'UriLink'
				if (isVideoListItem) {
					this.navigateToVideoLink(link)
				} else if (link.class === 'InternalLink') {
					var url = link.destination

					var internalPageLink = link.destination
					internalPageLink = internalPageLink.replace("cache://", "")
					internalPageLink = internalPageLink.replace(".json", "")
					url = '/apps/' + App.getCurrentApp().id + '/' + internalPageLink

					App.router.navigate(url, {trigger: true})
				} else if (isExternal) {
					window.open(link.destination, '_blank')
				}
			}
			// Don't do anything if we're already editing this element.
			// TODO don't read this from the DOM
		} else if (!this.$el.hasClass('editing')) {
			Storm.view.views.canvas.setInspector(this.model)
			this.$el.addClass('editing')
		}

		// Don't bubble up to the parent view.
		e.stopPropagation()
	},

	navigateToVideoLink: function(link) {
		if (link.destination && link.destination.match(/^cache:\/\/.*/) !== null) {
			var url = utils.getRemoteUrl(link.destination)
			window.open(url, '_blank')
		}
	}
})
